import { CopyLinkFlatIcon } from '@efacity/frontend-shared';
import { LightTooltip } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import { IconButton, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import React from 'react';

export interface SessionsWithTagLinkCopierProps {
  isEnabled: boolean;
  orgId: string;
  tagId: string;
}

const SessionsWithTagLinkCopier: React.FC<SessionsWithTagLinkCopierProps> = ({ isEnabled, orgId, tagId }) => {
  const handleCopyClick = (orgId: string, tagId: string) => {
    const registrationPageLink = toPath(PATHS.orgLanding, { orgId: orgId });
    copy(`${window.location.origin}${registrationPageLink}?tags=${tagId}`);
  };

  return isEnabled ? (
    <LightTooltip title={<Typography>Copy Tagged Session Link to share</Typography>}>
      <span>
        <div style={{ display: 'flex', marginRight: 5 }} data-testid="sessions-with-tag-copier">
          [
          <IconButton
            color="primary"
            aria-label="copy session link"
            component="span"
            style={{ marginTop: -4, padding: 5 }}
            onClick={() => handleCopyClick(orgId, tagId)}
          >
            <CopyLinkFlatIcon fontSize="small" />
          </IconButton>
          ]
        </div>
      </span>
    </LightTooltip>
  ) : null;
};

export default SessionsWithTagLinkCopier;
