import React, { FC } from 'react';
import { Box, FormControlLabel, Theme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { StudentOnSessionRegistrationInfo, User } from '@efacity/common';

const selectStudentCheckboxStyles = {
  itemStyle: {
    fontSize: 18,
    fontWeight: 'bold',
    display: 'inline-block'
  },
  helperTextSx: {
    fontSize: 14,
    fontWeight: 'normal',
    color: (theme: Theme) => theme.palette.secondary.main,
    margin: '-5 0',
    display: 'inline-block'
  }
};

export interface SelectStudentCheckboxProps {
  sessionId: string;
  student: User;
  registrationInfo: StudentOnSessionRegistrationInfo;
  isStudentSelectedOnSessionInShoppingCart: boolean;
  isRegistering: boolean;
  handleClickRegisterOnSession: (studentId: string, sessionId: string, isChecked: boolean) => void;
}

const SelectStudentCheckbox: FC<SelectStudentCheckboxProps> = ({
  sessionId,
  student,
  registrationInfo,
  isStudentSelectedOnSessionInShoppingCart,
  isRegistering,
  handleClickRegisterOnSession
}) => {
  return (
    <div style={{ fontSize: 22, fontWeight: 'bold', marginBottom: 15 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isStudentSelectedOnSessionInShoppingCart || registrationInfo.isRegistered}
            disabled={registrationInfo.isRegistered || isRegistering}
            onClick={(event: any) => handleClickRegisterOnSession(student._id, sessionId, event.target.checked)}
            color="primary"
            inputProps={{
              'aria-label': `selectStudent${student._id}OnSession${sessionId}Key`
            }}
          />
        }
        label={<div style={selectStudentCheckboxStyles.itemStyle}> {`${student.fullName}`}</div>}
      />
      {registrationInfo.isRegistered && <Box sx={selectStudentCheckboxStyles.helperTextSx}>already registered</Box>}
    </div>
  );
};

export default SelectStudentCheckbox;
