import React from 'react';
import { alpha, useTheme } from '@mui/material';

export const StripeInput = React.forwardRef(function StripeInput(props: any, ref) {
  const {
    component: Component,
    'aria-invalid': ariaInvalid,
    'aria-describedby': ariaDescribeBy,
    defaultValue,
    required,
    onKeyDown,
    onKeyUp,
    readOnly,
    autoComplete,
    autoFocus,
    type,
    name,
    rows,
    ...other
  } = props;
  const theme = useTheme();
  const [mountNode, setMountNode] = React.useState<HTMLInputElement | null>(null);

  React.useImperativeHandle(
    ref,
    () => ({
      focus: () => {
        if (mountNode) {
          mountNode.focus();
        }
      }
    }),
    [mountNode]
  );

  return (
    <Component
      onReady={setMountNode}
      options={{ hidePostalCode: true }}
      style={{
        base: {
          color: theme.palette.text.primary,
          fontSize: `${theme.typography.fontSize}px`,
          fontFamily: theme.typography.fontFamily,
          '::placeholder': {
            color: alpha(theme.palette.text.primary, 0.42)
          }
        },
        invalid: {
          color: theme.palette.text.primary
        }
      }}
      {...other}
    />
  );
});
