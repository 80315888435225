import { useEffect, useState } from 'react';
import { apiService } from '@efacity/frontend-shared';
import { CountryCode, CurrencyCode, OrganizationRegions, PaymentAcceptanceMethod } from '@efacity/common';

export const efacityLogoFullUrl = '/assets/images/EfacityLogoWhite_256_70.svg';
export const efacityMobileLogoFullUrl = '/assets/images/efacityLogoDensed_54x45.svg';

export const defaultOrganizationRegions: OrganizationRegions = {
  country: CountryCode.USA,
  timeZone: 'America/New_York',
  defaultCurrency: CurrencyCode.USD,
  isPaymentsAllowed: false,
  paymentAcceptanceMethod: PaymentAcceptanceMethod.Efacity,
  enrollmentRequired: false,
  logoFullUrl: efacityLogoFullUrl,
  mobileLogoFullUrl: efacityMobileLogoFullUrl
};

const defaultUseOrgRegionsState: OrganizationRegions & { isLoading: boolean } = {
  ...defaultOrganizationRegions,
  isLoading: true
};

export const useOrganizationRegionsOptions = (orgId: string) => {
  const [organizationRegions, setOrganizationRegions] = useState(defaultUseOrgRegionsState);

  useEffect(() => {
    const getOrganizationRegions = async () => {
      try {
        const { data } = await apiService.get<OrganizationRegions>(`/org/${orgId}/regions`);
        setOrganizationRegions({ ...data, isLoading: false });
      } catch (error) {
        setOrganizationRegions({ ...defaultOrganizationRegions, isLoading: false });
      }
    };
    if (orgId) {
      getOrganizationRegions();
    }
  }, [orgId]);

  return {
    organizationRegions,
    setOrganizationRegions
  };
};
