export interface PaymentCard {
  description: string;
  paymentCardId: string;
  last4: string;
  brand: string;
}

export const findPaymentCardByPaymentMethodId = (
  paymentCards: PaymentCard[],
  paymentCardId: string
) => {
  if (paymentCards && paymentCards.length > 0) {
    return paymentCards.find((card) => card.paymentCardId === paymentCardId);
  }
  return null;
};
