import {
  dateFormatter,
  Messages,
  startOfTheDay,
  UpdateAttendanceStatusResponse,
  PublicAttendanceInterface
} from '@efacity/common';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AttendanceStatusesLegend, {
  attendanceColors
} from '../../components/AttendanceStatusesLegend/AttendanceStatusesLegend';
import Table, { FetchDataOptions, initialFetchDataOptions, PaginationTypes } from '../../components/Table/Table';
import {
  FetchOptionsWithFromToDatesState,
  getQueryParamsForAttendanceFilters,
  useAttendanceLoader
} from './useAttendanceLoader';
import { IdOrgIdParamTypes } from '@efacity/frontend-next-shared/utils';
import { AttendanceActionsCellProps } from './AttendanceActionsCell';
import { apiService } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import StartEndDatesFilter from '../../components/StartEndDatesFilter/StartEndDatesFilter';
import { Box, Button, Grid } from '@mui/material';
import StudentContactInfoModal from './StudentContactInfoModal/StudentContactInfoModal';
import useAttendanceTableColumns from './useAttendanceTableColumns';
import { handleSaveFile } from '../../utils/handleSaveFileFromResponse';
import { ExportButton } from '../../components/Buttons/ExportButton';
import NotesModal from '../../components/NotesModal/NotesModal';
import { useSearchParams } from 'react-router-dom';
import { addMinutes, format } from 'date-fns';
import StartPublicAttendanceModal from './StartPublicAttendanceModal';
import { StudentAgreementsModal } from './StudentAgreementsModal';

const emptyStudentNoteModalState = { orgId: '', studentId: '', name: '' };
const emptyAgreementsModalState = {
  studentId: null,
  studentName: ''
};

const AttendanceContainer: React.FC = () => {
  const snackbarProps = useSnackbar();
  const { orgId } = useParams<IdOrgIdParamTypes>();
  const [selectedStudentId, setSelectedStudentId] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const now = new Date();
  const dateOffset = new Date(now).getTimezoneOffset();
  const acquiredFromDateParam = searchParams.get('fromDate');
  const initialFromDate = startOfTheDay(
    acquiredFromDateParam ? addMinutes(new Date(acquiredFromDateParam), dateOffset) : now
  );
  const acquiredToDateParam = searchParams.get('toDate');
  const initialToDate = startOfTheDay(
    acquiredToDateParam ? addMinutes(new Date(acquiredToDateParam), dateOffset) : now
  );
  const [attendanceFilters, setFilters] = useState<FetchOptionsWithFromToDatesState>({
    fromDate: new Date(initialFromDate),
    toDate: new Date(initialToDate),
    fetchDataOptions: initialFetchDataOptions
  });
  const [startPublicAttendanceModalState, setStartPublicAttendanceModalState] = useState<boolean>(false);

  useEffect(() => {
    if (!acquiredFromDateParam || !acquiredToDateParam) {
      setSearchParams({
        fromDate: format(new Date(attendanceFilters.fromDate), dateFormatter).substring(0, 10),
        toDate: format(new Date(attendanceFilters.toDate), dateFormatter).substring(0, 10)
      });
    }
  });

  const handleDatesChanged = (newFromDate: Date, newToDate: Date) => {
    setFilters({
      ...attendanceFilters,
      fromDate: newFromDate,
      toDate: newToDate
    });
    setSearchParams({
      fromDate: format(newFromDate, dateFormatter).substring(0, 10),
      toDate: format(newToDate, dateFormatter).substring(0, 10)
    });
  };

  const { attendanceState, setAttendanceState, getAttendanceForDate } = useAttendanceLoader(
    `/org/${orgId}/attendance`,
    snackbarProps,
    attendanceFilters
  );

  const [notesModalState, setNotesModalState] = useState(emptyStudentNoteModalState);
  const onOpenNotesModal = (studentId: string, name: string) => {
    setNotesModalState({ orgId: orgId, studentId: studentId, name: name });
  };
  const onCloseNotesModal = () => {
    setNotesModalState(emptyStudentNoteModalState);
  };

  const [agreementsModalState, setAgreementsModalState] = useState(emptyAgreementsModalState);
  const onOpenAgreementsModal = (studentId: string, studentName: string) => {
    setAgreementsModalState({
      studentId,
      studentName
    });
  };
  const onCloseAgreementsModal = () => {
    setAgreementsModalState(emptyAgreementsModalState);
  };

  const changeFetchOptionsFromTable = useCallback(
    (fetchDataOptions: FetchDataOptions): void => {
      setFilters((attendanceFilters) => {
        return {
          ...attendanceFilters,
          fetchDataOptions: fetchDataOptions
        };
      });
    },
    [setFilters]
  );

  const onChangeStudentAttendanceStatus: AttendanceActionsCellProps['onChangeStudentAttendanceStatus'] = useCallback(
    async (attendanceId, attendanceStatus, setActionInProgress) => {
      setActionInProgress({ rowId: attendanceId, button: attendanceStatus });
      try {
        const { data } = await apiService.patch<UpdateAttendanceStatusResponse>(
          `/org/${orgId}/attendance/${attendanceId}`,
          {
            attendanceStatus
          }
        );
        // use function to always get the latest state
        setAttendanceState((state) => {
          state.attendance.forEach((attendanceItem) => {
            if (attendanceItem._id === attendanceId) {
              attendanceItem.status = data.status;
            }
          });
          return {
            ...state,
            isLoading: false
          };
        });
      } catch (error) {
        showNotification(snackbarProps, false, error.message || Messages.CannotUpdateAttendanceStatus);
      } finally {
        setActionInProgress({ rowId: null, button: null });
      }
    },
    [orgId, setAttendanceState, snackbarProps]
  );

  const onShowStudentContactInfo = useCallback((studentId: string) => {
    setSelectedStudentId(studentId);
  }, []);

  const columns = useAttendanceTableColumns({
    onChangeStudentAttendanceStatus,
    onShowStudentContactInfo,
    onOpenNotesModal,
    onOpenAgreementsModal
  });

  const getRowStylesByAttendanceStatus = useCallback((status) => {
    if (attendanceColors[status]) {
      return attendanceColors[status];
    }
    return { backgroundColor: 'initial' };
  }, []);

  const onCloseContactInfoModal = () => {
    setSelectedStudentId(null);
  };

  const [isExporting, setIsExporting] = useState(false);

  const exportAttendance = () => {
    setIsExporting(true);

    apiService
      .get(`/org/${orgId}/attendance/export`, getQueryParamsForAttendanceFilters(attendanceFilters), {
        'content-disposition': 'attachment'
      })
      .then(
        async (result: any) => {
          setIsExporting(false);
          const contentTypeHeader = result.headers['content-type'];
          if (
            contentTypeHeader?.indexOf('text/csv') !== -1 ||
            contentTypeHeader?.indexOf('application/pdf') !== -1 ||
            contentTypeHeader?.indexOf('application/octet-stream') !== -1 ||
            contentTypeHeader?.indexOf('text/html') !== -1
          ) {
            handleSaveFile(result, snackbarProps);
          } else {
            showNotification(snackbarProps, false, Messages.UnsupportedFileType, true);
          }
        },
        (error) => {
          setIsExporting(false);

          showNotification(
            snackbarProps,
            false,
            (error.response.data.message as string) || Messages.FailedExportRegistrations,
            true
          );
        }
      );
  };

  const startAttendanceModal = async () => {
    setAttendanceState((state) => ({ ...state, isLoadingForDate: true }));
    setStartPublicAttendanceModalState(true);
  };

  const closeStartAttendanceModal = () => {
    setIsGettingAttendancePage(false);
    setStartPublicAttendanceModalState(false);
  };

  const [isGettingAttendancePage, setIsGettingAttendancePage] = useState(false);
  const getPublicAttendancePageUrl = async (date: Date, publicAttendanceInterface: PublicAttendanceInterface) => {
    setIsGettingAttendancePage(true);

    const queryParams = getQueryParamsForAttendanceFilters({
      ...attendanceFilters,
      fromDate: date,
      toDate: date,
      ...publicAttendanceInterface
    });

    try {
      const { data } = await apiService.post<any>(`/org/${orgId}/attendance/public/redirect`, queryParams);
      window.location.href = data.path;
    } catch (error) {
      setIsGettingAttendancePage(false);
      showNotification(
        snackbarProps,
        false,
        (error.response.data.message as string) || Messages.FailedEStartPublicAttendance,
        true
      );
    }
  };

  const progressMessage = isGettingAttendancePage
    ? 'Redirect to public attendance page...'
    : 'Loading attendance for date...';

  return (
    <div>
      <Grid container spacing={1} style={{ marginTop: 10, marginBottom: 10 }}>
        <Grid item xs={12} sm={6}>
          <StartEndDatesFilter
            fromDate={attendanceFilters.fromDate}
            toDate={attendanceFilters.toDate}
            handleDatesChanged={handleDatesChanged}
            validationErrors={attendanceState.validationErrors}
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          <ExportButton isExporting={isExporting} color={'primary'} onClick={exportAttendance} />
          <Button
            variant="outlined"
            color="primary"
            onClick={startAttendanceModal}
            data-testid="get-public-attendance-page"
            disabled={isGettingAttendancePage}
            style={{ marginLeft: 5, marginRight: 2, whiteSpace: 'nowrap' }}
          >
            Public Attendance
          </Button>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="space-between" marginY={2}>
        <div style={{ display: 'flex', justifyContent: 'end' }}></div>
      </Box>
      <AttendanceStatusesLegend containerStyles={{ justifyContent: 'center', marginBottom: 20 }} />
      <Table
        columns={columns}
        data={attendanceState.attendance}
        fetchData={changeFetchOptionsFromTable}
        loading={attendanceState.isLoading}
        noDataText="No attendance records..."
        getRowProps={(row) => {
          return { style: getRowStylesByAttendanceStatus(row.original.status) };
        }}
        paginationType={PaginationTypes.ClientSide}
      />
      {selectedStudentId && (
        <StudentContactInfoModal
          open={!!selectedStudentId}
          onClose={onCloseContactInfoModal}
          studentId={selectedStudentId}
        />
      )}

      {notesModalState.studentId && (
        <NotesModal
          orgId={notesModalState.orgId}
          customerId={notesModalState.studentId}
          name={notesModalState.name}
          url={`/org/${orgId}/users/students/${notesModalState.studentId}/notes`}
          onClose={onCloseNotesModal}
        />
      )}

      {startPublicAttendanceModalState && (
        <StartPublicAttendanceModal
          open={startPublicAttendanceModalState}
          dayAttendance={attendanceState.attendanceForDate}
          startDate={attendanceFilters.fromDate}
          getPublicAttendancePage={getPublicAttendancePageUrl}
          onClose={closeStartAttendanceModal}
          getAttendanceForDate={getAttendanceForDate}
          isLoading={isGettingAttendancePage || attendanceState.isLoadingForDate}
          progressMessage={progressMessage}
        />
      )}

      {agreementsModalState.studentId && (
        <StudentAgreementsModal
          open={true}
          studentId={agreementsModalState.studentId}
          studentName={agreementsModalState.studentName}
          orgId={orgId}
          onClose={onCloseAgreementsModal}
        />
      )}
    </div>
  );
};

export default AttendanceContainer;
