import { adminsTeachersAndIndependentRoles, hasAnyOfRoles, Roles } from './Roles';
import { PATHS, toPath } from '@efacity/routing';
import { getDefaultAccess } from './User';

export interface Access {
  role: Roles;
  isDefault: boolean;
  createdAt?: Date;
  deletedAt?: Date;
  deletedBy?: any;
  isMainAdmin?: boolean;
  createdBy?: any;
  organization?: any;
  session?: any;
  customer?: any;
}

export interface AdminAccess {
  role: Roles;
  orgId: string;
  orgName: string;
}

const getUserHomePageForSelectedAccess = (
  access: Access,
  id = '',
  startRegistrationURL: string = PATHS.landing // precalculated path that based on ShoppingCart items
) => {
  if (hasAnyOfRoles([access.role as Roles], rolesForSchoolsHomePage)) {
    return PATHS.schools;
  }
  if (hasAnyOfRoles([access.role as Roles], adminsTeachersAndIndependentRoles)) {
    const orgId = access.organization;
    return toPath(PATHS.sessions, { orgId: orgId });
  }
  if (hasAnyOfRoles([access.role as Roles], rolesForRegistrationsForUserPage)) {
    return startRegistrationURL;
  }
  if (hasAnyOfRoles([access.role as Roles], rolesForStudentSessionsHomePage)) {
    return toPath(PATHS.studentSessions, { id: id });
  }
  return PATHS.landing;
};

export const getLoggedInUserHomePagePathIfAny = (
  accesses: Access[] = [],
  id = '',
  startRegistrationURL: string | null = PATHS.landing
) => {
  const defaultAccess = getDefaultAccess(accesses);
  if (defaultAccess) {
    return getUserHomePageForSelectedAccess(defaultAccess, id, startRegistrationURL);
  }
  return PATHS.landing;
};

export const rolesForSchoolsHomePage = [Roles.SiteAdmin];
export const rolesForStudentSessionsHomePage = [Roles.Student];
export const rolesForRegistrationsForUserPage = [Roles.Customer, Roles.Student];
