import { Dialog, DialogContent } from '@mui/material';
import { apiService } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

import { FormProvider, useForm } from 'react-hook-form';
import React from 'react';
import { addServerErrors, FormTextInput } from '@efacity/react-hook-form-mui';
import { FormCancelSubmitButtons } from '@efacity/frontend-next-shared/forms';
import { FormMode, Messages } from '@efacity/common';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalDialogTitle } from '@efacity/react-next-sc';

const emptyFormValues = { fullName: '', email: '' };

export const addNewAdminFormValidationSchema = yup.object({
  fullName: yup.string().required(Messages.NameRequired),
  email: yup.string().required(Messages.EmailRequired).email(Messages.InvalidEmail)
});

const AddNewAdminModal = ({ isOpen, onClose, orgId }) => {
  const snackbarProps = useSnackbar();

  const methods = useForm({
    defaultValues: emptyFormValues,
    resolver: yupResolver(addNewAdminFormValidationSchema),
    mode: 'onBlur'
  });
  const { isValid, isSubmitting } = methods.formState;

  const submitForm = async (formValues) => {
    try {
      await apiService.post<any>(`/org/${orgId}/admins/add`, formValues);
      methods.reset(emptyFormValues);
      onClose();
    } catch (error) {
      if (error.response?.data?.message) {
        showNotification(snackbarProps, false, error.response.data.message || 'Cannot an admin', true);
      } else if (error.response?.data?.validationErrors) {
        addServerErrors(error.response.data.validationErrors, methods.setError);
      }
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm">
      <ModalDialogTitle text="Add an Admin" />
      <DialogContent>
        <FormProvider {...methods}>
          <form noValidate onSubmit={methods.handleSubmit(submitForm)}>
            <FormTextInput name="email" label="E-mail" required />
            <FormTextInput name="fullName" label="Full Name" required />
            <FormCancelSubmitButtons
              mode={FormMode.Add}
              onCancel={onClose}
              isFormValid={isValid}
              isSubmitting={isSubmitting}
            />
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewAdminModal;
