export const isTShirtSizeValue = (mayBeTShirtSizeValue: string): boolean => {
  const foundTShirtSize = tShirtSizeArray.find((tShirtSize: TShirtSize) => tShirtSize.value === mayBeTShirtSizeValue);
  return !!foundTShirtSize;
};

export const getTShirtSizeDisplayTextByValue = (tShirtSizeValue: string) => {
  const foundTShirtSize = tShirtSizeArray.find((grade: TShirtSize) => grade.value === tShirtSizeValue);
  if (foundTShirtSize) {
    return foundTShirtSize.displayText;
  }
  return TShirtSize.YouthM.displayText;
};

export class TShirtSize {
  static readonly YouthXS = new TShirtSize('youthXS', 'Youth XS');
  static readonly YouthS = new TShirtSize('youthS', 'Youth S');
  static readonly YouthM = new TShirtSize('youthM', 'Youth M');
  static readonly YouthL = new TShirtSize('youthL', 'Youth L');
  static readonly YouthXL = new TShirtSize('youthXL', 'Youth XL');
  static readonly AdultS = new TShirtSize('adultS', 'Adult S');
  static readonly AdultM = new TShirtSize('adultM', 'Adult M');
  static readonly AdultL = new TShirtSize('adultL', 'Adult L');
  static readonly AdultXL = new TShirtSize('adultXL', 'Adult XL');

  // private to disallow creating other instances of this type
  private constructor(public readonly value: string, public readonly displayText: string) {}

  toString() {
    return this.displayText;
  }
}

export enum TShirtSizeEnum {
  notSpecified = '',
  youthXS = 'youthXS',
  youthS = 'youthS',
  youthM = 'youthM',
  youthL = 'youthL',
  youthXL = 'youthXL',
  adultS = 'adultS',
  adultM = 'adultM',
  adultL = 'adultL',
  adultXL = 'adultXL'
}
export const tShirtSizeArray = [
  TShirtSize.YouthXS,
  TShirtSize.YouthS,
  TShirtSize.YouthM,
  TShirtSize.YouthL,
  TShirtSize.YouthXL,
  TShirtSize.AdultS,
  TShirtSize.AdultM,
  TShirtSize.AdultL,
  TShirtSize.AdultXL
];
