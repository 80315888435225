export const tableStyles = {
  table: {
    tableLayout: 'auto'
  },
  tableHead: {
    background: '#F5F6FA'
  },
  tableHeaderCellSx: {
    color: 'primary.dark',
    fontWeight: 600
  },
  tableContainer: {
    tableLayout: 'auto',
    borderRadius: 1
    //height: '100vh'
  }
};
