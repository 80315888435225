import React from 'react';
import EarlyBirdIcon from './EarlyBirdIcon';
import SiblingIcon from './SiblingIcon';
import FullDayIcon from './FullDayIcon';
import ConsecutiveIcon from './ConsecutiveIcon';

/*
  This config file connects discountClassValue from backend with necessary styling needed on frontend
  discountClassValue is used as a key
  E.g.
   {
    customerDescription:
      "Register multiple children at the same time to receive the discount.",
    discountClassValue: "2",
    discountPercentage: 10,
    currencyDisplayText: "",
    discountAmount: ""
  }
  will correspond to discountClassConnector[discountClassValue]
*/

export const discountClassConnector = {
  sibling: {
    title: 'Sibling Discount',
    headerBg: 'linear-gradient(22.27deg, #7752A0 3.23%, #ACE7FF 100%)',
    headerIcon: <SiblingIcon />
  },
  fullDay: {
    title: 'Full-Day Discount',
    headerBg: 'linear-gradient(58.42deg, #0A98D1 22.59%, #ACE7FF 100%)',
    headerIcon: <FullDayIcon />
  },
  earlyBird: {
    title: 'Early Bird Discount',
    headerBg: 'linear-gradient(58.42deg, #6AAA43 22.59%, #ACE7FF 100%)',
    headerIcon: <EarlyBirdIcon />
  },
  consecutive: {
    title: 'Consecutive Discount',
    headerBg: 'linear-gradient(58.42deg, #E19426 22.59%, #E19426 100%)',
    headerIcon: <ConsecutiveIcon />
  }
};
