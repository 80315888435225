import { Roles, getLoggedInUserHomePagePathIfAny, hasAnyOfRoles } from '@efacity/common';
import { EfacityActionLogo } from '@efacity/frontend-shared';
import { AuthStatus, useAuth } from '@efacity/react-next-sc';
import { PATHS } from '@efacity/routing';
import axios from 'axios';
import { useEffect } from 'react';
import { NavigateFunction, Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

const addAxiosInterceptors = (navigate: NavigateFunction, pathname: string) => {
  axios.interceptors.response.use(
    function (config) {
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      if (error.response.status === 404 && !pathname.includes(PATHS.notFound)) {
        navigate('/not-found', { replace: true });
      }
      return Promise.reject(error);
    }
  );
};

export const AuthStatusChecker = () => {
  const params = useParams();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const code = searchParams.get('code');
  const orgId = params['orgId'];

  const {
    authState: { isAuthenticated, user, isLogout },
    checkAuthenticationStatus
  } = useAuth();

  addAxiosInterceptors(navigate, pathname);

  useEffect(() => {
    /*
      If we have code query param it means we were
      redirected from Google sign in page and currently trying to sign in.
      Therefore, no need to check authentication status
    */
    if (!code && isAuthenticated === AuthStatus.Checking) {
      checkAuthenticationStatus(orgId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, code, isAuthenticated]);

  // do not allow start rendering for user with roles on Landing
  // replace path to goto another route
  if (
    pathname === PATHS.landing &&
    isAuthenticated === AuthStatus.Authenticated &&
    user?.roles?.length > 0 &&
    !hasAnyOfRoles([Roles.Customer, Roles.Student], user.roles)
  ) {
    const userHomePage = getLoggedInUserHomePagePathIfAny(user?.access);
    if (userHomePage) {
      navigate(userHomePage, { replace: true });
      return null;
    }
  }

  /*
    It indicates that we will check for auth status in useEffect
    and should wait for check to finish
  */
  return (isAuthenticated === AuthStatus.Checking && !code) || isLogout ? (
    <div>
      <EfacityActionLogo message={'Checking auth status...'} />
    </div>
  ) : (
    <Outlet />
  );
};
