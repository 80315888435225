import { Address, generateCompleteAddress, generateGoogleMapsLinkForAddress } from '@efacity/common';
import Link from '@mui/material/Link';
import { LocationMarkerIcon } from '../../../index';

export const AddressMapLink = ({ address }: { address: Address }) => {
  if (!address) return null;

  const mapLink = generateGoogleMapsLinkForAddress(generateCompleteAddress(address));

  return (
    <Link href={mapLink} target="_blank" rel="noreferrer" style={{ margin: '10px' }}>
      <LocationMarkerIcon />
      Open in Google Maps
    </Link>
  );
};
