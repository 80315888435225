import { DisplayOption } from './DisplayOption';
import { Descendant } from 'slate';
import { emptyRichEdit } from './markup';

export enum ActivityTypes {
  Camp = 'camp',
  Course = 'course',
  Event = 'event',
  Enrichment = 'enrichment',
  InstructorCertification = 'certification',
  Enrollment = 'enrollment'
}

export class ActivityTypesOptions {
  static readonly CAMP = new DisplayOption(ActivityTypes.Camp, 'Camp');
  static readonly COURSE = new DisplayOption(ActivityTypes.Course, 'Course');
  static readonly EVENT = new DisplayOption(ActivityTypes.Event, 'Event');
  static readonly ENRICHMENT = new DisplayOption(ActivityTypes.Enrichment, 'Enrichment');
  static readonly CERTIFICATION = new DisplayOption(ActivityTypes.InstructorCertification, 'Instructor Certification');
  static readonly ENROLLMENT = new DisplayOption(ActivityTypes.Enrollment, 'Enrollment');
}

export const activityLevels = Array.from(Array(5)).map((_, index) => {
  return new DisplayOption(index + 1, `Level ${index + 1}`);
});

export const activityTypesMapper = {
  [ActivityTypes.Camp]: 'Camp',
  [ActivityTypes.Course]: 'Course',
  [ActivityTypes.Event]: 'Event',
  [ActivityTypes.Enrichment]: 'Enrichment',
  [ActivityTypes.InstructorCertification]: 'Instructor Certification',
  [ActivityTypes.Enrollment]: 'Enrollment'
};

export const activityTypesArray = Object.entries(ActivityTypesOptions).map(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ([_, value]) => value.value
);

// used in filters on landing / orgLanding
export const activityTypes = [ActivityTypesOptions.CAMP, ActivityTypesOptions.COURSE, ActivityTypesOptions.EVENT];

export interface StudentLevelOptional {
  type: string;
  fromSchoolGradeNumeric?: number;
  toSchoolGradeNumeric?: number;
  fromAge?: number;
  toAge?: number;
}

export const activityColorsMapper = {
  [ActivityTypes.Camp]: '#6DB136',
  [ActivityTypes.Course]: '#009CFF',
  [ActivityTypes.Event]: '#878CEF',
  [ActivityTypes.Enrichment]: '#009CFF',
  [ActivityTypes.InstructorCertification]: '#6DB136'
};

export const activityTypesSelectOptions: DisplayOption[] = Object.entries(ActivityTypesOptions).map(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ([_, value]) => {
    return {
      value: value.value,
      displayText: value.displayText
    };
  }
);

export const parseRichText = (descriptionOnSessionSelection): Descendant[] => {
  try {
    return JSON.parse(descriptionOnSessionSelection as unknown as string);
  } catch {
    return emptyRichEdit;
  }
};

export enum DurationType {
  Fixed = 'fixed',
  selfPaced = 'selfPaced'
}

export class DurationTypeOptions {
  static readonly FIXED = new DisplayOption(DurationType.Fixed, 'Fixed');
  static readonly SELF_PACED = new DisplayOption(DurationType.selfPaced, 'Self-paced');
}
