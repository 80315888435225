import { getDay, getISODay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ScheduleInstance } from '../lib/Sessions';

export const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const getSessionStartDate = (instancesTimeStamps: number[]) => {
  const minDateTimestamp = Math.min(...instancesTimeStamps);
  return new Date(minDateTimestamp);
};

export const getSessionEndDate = (instancesTimeStamps: number[]) => {
  const maxDateTimestamp = Math.max(...instancesTimeStamps);
  return new Date(maxDateTimestamp);
};

export const mapInstancesToTimeStamps = (instancesList: ScheduleInstance[]) => {
  return instancesList.map((instance) => new Date(instance.startDateTime).getTime());
};
export const mapInstancesToTimeStampsForTimeZone = (
  instancesList: ScheduleInstance[],
  timeZone: string
) => {
  return instancesList.map((instance) =>
    utcToZonedTime(new Date(instance.startDateTime).toISOString(), timeZone).getTime()
  );
};

export const getWeekDayNames = (instancesTimeStamps: number[]) => {
  const weekDayNames = instancesTimeStamps
    .sort((curr, next) => {
      if (getISODay(curr) < getISODay(next)) {
        return -1;
      }
      if (getISODay(curr) > getISODay(next)) {
        return 1;
      }
      return 0;
    })
    .map((instanceTimestamp) => {
      const dayIndex = getDay(instanceTimestamp);
      return weekDays[dayIndex];
    });
  return Array.from(new Set(weekDayNames));
};
