import React, { useState } from 'react';
import { Card, LinearProgress, TableContainer } from '@mui/material';
import { tableStyles } from '../../../stylesheets/tableStyle';
import CustomerRegistrationMobileRow from './CustomerRegistrationMobileRow';
import { RegistrationsProps } from './CustomerRegistrationsDesktopTable';
import ReviewModal from '../../../components/ReviewModal';

const CustomerRegistrationsMobileTable: React.FC<RegistrationsProps> = ({
  registrations,
  isLoading,
  isCancelling,
  handleCancelRegistration
}) => {
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [reviewModalRegistration, setReviewModalRegistration] = useState(null);

  const openReviewModal = (registration) => {
    setReviewModalOpen(true);
    setReviewModalRegistration(registration);
  };
  const closeReviewModal = () => {
    setReviewModalOpen(false);
  };

  return (
    <>
      <TableContainer component={Card} sx={tableStyles.tableContainer}>
        {registrations.length > 0 &&
          !isLoading &&
          registrations.map((registration) => (
            <CustomerRegistrationMobileRow
              registration={registration}
              key={`${registration.invoiceId}`}
              handleCancelRegistration={handleCancelRegistration}
              isCancelling={isCancelling}
              openReviewModal={openReviewModal}
            />
          ))}
        {isLoading && <LinearProgress variant="indeterminate" color="primary" data-testid="loadingIndicator" />}
      </TableContainer>
      {reviewModalOpen && (
        <ReviewModal isOpen={reviewModalOpen} registration={reviewModalRegistration} onClose={closeReviewModal} />
      )}
    </>
  );
};

export default CustomerRegistrationsMobileTable;
