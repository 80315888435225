import React, { useMemo, useState } from 'react';
import { EnhancedColumn } from '../../components/Table/Table';
import { ReviewInfo, ReviewStatus, formatDateToMonthDayYearInLocale } from '@efacity/common';
import { IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DateColumnFilter from '../../components/Table/filters/DateColumnFilter';
import ReviewStatusColumnFilter from '../../components/ColumnFilters/ReviewStatusColumnFilter';
import ReviewStatusButton from '../../components/ReviewStatusButton/ReviewStatusButton';

const expandableStyle = (isExpanded: boolean) => {
  if (isExpanded) return {};
  return {
    overflow: 'hidden',
    maxHeight: '3em' //2 lines of text
  };
};

const useSiteAdminReviewsColumns = (
  changeReviewStatus: (reviewId: string, newStatus: ReviewStatus) => void,
  processingReviewId: string
) => {
  return useMemo((): EnhancedColumn<ReviewInfo>[] => {
    return [
      {
        Header: 'Session',
        accessor: 'reviewItemName',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
        columnStyles: { verticalAlign: 'top' }
      },
      {
        Header: 'Organization',
        accessor: 'organizationName',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
        columnStyles: { verticalAlign: 'top' }
      },
      {
        Header: 'Reviewer',
        accessor: 'reviewerDisplayName',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
        columnStyles: { verticalAlign: 'top' }
      },
      {
        Header: 'Stars',
        accessor: 'stars',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
        columnStyles: { verticalAlign: 'top' }
      },
      {
        Header: 'Comment',
        headerStyles: {
          textAlign: 'right'
        },
        accessor: 'comment',
        disableFilters: true,
        Cell: ({ value }) => {
          const [isExpanded, setIsExpanded] = useState(false);
          return (
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <div style={expandableStyle(isExpanded)}> {value}</div>
              <div>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setIsExpanded(!isExpanded)}
                  data-testid="collapseButton"
                >
                  {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
            </div>
          );
        },
        columnStyles: { verticalAlign: 'top' }
      },
      {
        Header: 'Created on (in UTC)',
        accessor: 'reviewedOn',
        Cell: ({ value }) => {
          return <div>{formatDateToMonthDayYearInLocale(value)}</div>;
        },
        Filter: DateColumnFilter,
        columnStyles: { verticalAlign: 'top' }
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
        Filter: ReviewStatusColumnFilter,
        columnStyles: { verticalAlign: 'top' }
      },
      {
        Header: '',
        id: 'actions-cell',
        Cell: ({ row }) => {
          return (
            <ReviewStatusButton
              reviewId={row.original._id}
              currentStatus={row.original.status}
              changeReviewStatus={changeReviewStatus}
              progress={processingReviewId === row.original._id}
            />
          );
        },
        columnStyles: { verticalAlign: 'top' }
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processingReviewId]);
};

export default useSiteAdminReviewsColumns;
