import React, { FC } from 'react';
import { Dialog, DialogContent, useMediaQuery, useTheme } from '@mui/material';
import { ModalDialogTitle } from '@efacity/react-next-sc';

const dialogContentSx = {
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingBottom: '12px',
  paddingTop: '12px',
  marginBottom: '20px',
  marginTop: '0px'
};

interface ThreeDConfirmationModalProps {
  open: boolean;
  redirectUrl: string;
}

export const ThreeDConfirmationModal: FC<ThreeDConfirmationModalProps> = ({ open, redirectUrl }) => {
  const theme = useTheme();
  const fullScreenModal = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open={open} fullScreen={fullScreenModal}>
      <ModalDialogTitle text="Bank authentication" />
      <DialogContent sx={dialogContentSx}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <iframe title={'3d secure'} src={redirectUrl} width={fullScreenModal ? 390 : 600} height={400}></iframe>
        </div>
      </DialogContent>
    </Dialog>
  );
};
