import {
  AppEnv,
  Roles,
  adminsTeachersAndIndependentRoles,
  hasAnyOfRoles,
  headerLogoStyle,
  headerSmallLogoStyle,
  displayMdUpSx,
  displaySmDownSx,
  displaySmUpSx,
  displayXsSx
} from '@efacity/common';
import { useFetch } from '@efacity/frontend-shared';
import { BranchTooltip, LightTooltip, RightUpperMenu, useAuth } from '@efacity/react-next-sc';
import { headerSx } from '@efacity/frontend-next-shared/header';
import { setKitPurchaseProgress, setRegistrationProgress } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import DownArrowIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, IconButton, Link as MuiLink, Skeleton, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ShoppingCartIconContainer } from '../ShoppingCartIconContainer/ShoppingCartIconContainer';
import { navigateToPage } from '../../utils/navigateToPage';
import { efacityLogoFullUrl, efacityMobileLogoFullUrl } from '../../hooks/useOrganizationRegionsOptions';

const BRANCH_NAME = process.env['REACT_APP_VERCEL_GIT_COMMIT_REF'];

export interface MainLayoutHeaderProps {
  handleDrawerToggle: (e: any) => void;
  logoFullUrl?: string;
  mobileLogoFullUrl?: string;
}

const MainLayoutHeader: FC<MainLayoutHeaderProps> = ({
  handleDrawerToggle,
  logoFullUrl = efacityLogoFullUrl,
  mobileLogoFullUrl = efacityMobileLogoFullUrl
}) => {
  const {
    authState: { user, extraAuthInfo, workingAccessId, workingOrganizationName },
    logOut,
    getAllowedCreateActivity
  } = useAuth();
  const startRegistrationURL = extraAuthInfo?.startRegistrationURL;

  const [{ data: appConfig, isLoading: loadingAppConfig }] = useFetch<{ appEnv: AppEnv }>(`/config`, {
    initialDataState: { appEnv: null, initialLoadingState: true }
  });
  const isTestMode = appConfig?.appEnv === AppEnv.TEST || appConfig?.appEnv === AppEnv.DEV;

  useEffect(() => {
    if (workingAccessId) {
      getAllowedCreateActivity(workingAccessId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workingAccessId]);

  const location = useLocation();
  const navigate = useNavigate();
  const isUserLoggedIn = !!user?._id;

  const onLogout = async (): Promise<void> => {
    await logOut();
    setRegistrationProgress(false);
    setKitPurchaseProgress(false);
    if (location.pathname !== PATHS.landing) {
      window.location.href = PATHS.landing;
      return;
    }
  };

  const showShoppingCart = hasAnyOfRoles([Roles.Customer, Roles.Student], user?.roles || []);

  const handleLogout = () => {
    onLogout();
  };

  const handleGotoOrgLanding = useCallback(
    (e) => {
      e.stopPropagation();
      window.open(toPath(PATHS.orgLanding, { orgId: workingAccessId }), '_blank').focus();
    },
    [workingAccessId]
  );

  const handleGotoLanding = (e) => {
    e.stopPropagation();
    window.location.href = startRegistrationURL;
  };

  const currentOrganizationName = useMemo(() => {
    if (loadingAppConfig) {
      return <Skeleton width={250} />;
    }
    if (workingOrganizationName && workingAccessId) {
      return (
        <>
          <LightTooltip title={isTestMode ? 'You are in a test environment. Changes will be saved as test data' : ''}>
            <MuiLink
              sx={isUserLoggedIn ? {} : displaySmUpSx}
              component={Link}
              onClick={handleGotoOrgLanding}
              to="#"
              color={isTestMode ? 'error' : 'inherit'}
            >
              {isTestMode && workingOrganizationName ? `TEST: ${workingOrganizationName}` : workingOrganizationName}
            </MuiLink>
          </LightTooltip>
          {isTestMode && !isUserLoggedIn && (
            <Typography sx={{ ...displayXsSx, fontSize: '20px' }} color="error">
              TEST
            </Typography>
          )}
        </>
      );
    }
    return <div></div>;
  }, [loadingAppConfig, workingOrganizationName, workingAccessId, handleGotoOrgLanding, isUserLoggedIn, isTestMode]);

  const gotoWithReload = (path: string) => {
    window.location.href = path;
  };

  const goToPath = navigateToPage(navigate);

  return (
    <AppBar color="default" position="relative" style={headerSx.header} data-testid="main-layout-header">
      <Box display="flex" sx={headerSx.appBarInner}>
        {hasAnyOfRoles([...adminsTeachersAndIndependentRoles, Roles.SiteAdmin], user.roles) && (
          <Box sx={headerSx.burgerWrapperSx}>
            <IconButton onClick={handleDrawerToggle} color="primary" data-testid="menu-toggler">
              <MenuIcon>
                <DownArrowIcon />
              </MenuIcon>
            </IconButton>
          </Box>
        )}
        <Box sx={displayMdUpSx}>
          <Link to="#" onClick={handleGotoLanding}>
            <img
              src={logoFullUrl || efacityLogoFullUrl}
              alt="Efacity logo"
              data-testid="menu-logo-desktop"
              style={headerLogoStyle}
            />
          </Link>
        </Box>
        <Box sx={displaySmDownSx}>
          <Link to="#" onClick={handleGotoLanding}>
            <img
              src={mobileLogoFullUrl || efacityMobileLogoFullUrl}
              alt="Efacity logo"
              data-testid="menu-logo-mobile"
              style={headerSmallLogoStyle}
            />
          </Link>
        </Box>
        <BranchTooltip isTestMode={isTestMode} branchName={BRANCH_NAME} />
        <Box sx={headerSx.orgNameContainerSx}>{currentOrganizationName}</Box>
        {showShoppingCart && <ShoppingCartIconContainer />}
        {isUserLoggedIn && (
          <>
            <Box sx={displayMdUpSx}>
              <Typography variant="body2" color="primary" data-testid="leftName">
                {user?.email}
              </Typography>
            </Box>
            <RightUpperMenu
              user={user}
              onLogoutClick={handleLogout}
              startRegistrationURL={startRegistrationURL}
              workingAccessId={workingAccessId}
              gotoToPath={goToPath}
              goToAdminSection={gotoWithReload}
            />
          </>
        )}
      </Box>
    </AppBar>
  );
};

export default MainLayoutHeader;
