import React, { FC, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Check from '@mui/icons-material/Check';
import NotesTable from './NotesTable';
import { apiService, useFetch, CircularProgressButton } from '@efacity/frontend-shared';
import { Note } from '@efacity/common';

export interface NotesTableWithAddFormProps {
  url: string;
}

const NotesTableWithAddForm: FC<NotesTableWithAddFormProps> = ({ url }) => {
  const [noteUnderEdit, setNoteUnderEdit] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [{ data: notes, isLoading, error: fetchError }, { setData }] = useFetch<Note[]>(url, {
    initialDataState: []
  });

  const onAddNote = async () => {
    setLoading(true);
    try {
      const { data: addedNote } = await apiService.post<Note>(url, { content: noteUnderEdit });
      setData([...notes, addedNote]);
      setNoteUnderEdit('');
    } catch (e) {
      setError(e.response.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginBottom: 15 }}>
      {fetchError ? (
        <Typography color="error">Unable to fetch notes</Typography>
      ) : (
        <NotesTable notes={notes} isLoading={isLoading} />
      )}
      <Box marginTop={3}>
        <Box display="flex" alignItems="center" height="36px">
          <TextField
            name="note"
            value={noteUnderEdit}
            onChange={(e) => setNoteUnderEdit(e.target.value)}
            variant="outlined"
            size="small"
            label="Write new note here"
            fullWidth
            style={{ marginTop: 4, marginRight: 8, marginBottom: 4 }}
          />

          {error && <Typography color="error">{error}</Typography>}
          <CircularProgressButton
            type="button"
            data-testid="add-note-btn"
            progress={loading}
            onClick={onAddNote}
            variant="contained"
            color="primary"
            startIcon={<Check />}
            disabled={noteUnderEdit?.length === 0}
            size="small"
            style={{ minWidth: 100, height: '100%' }}
          >
            Add
          </CircularProgressButton>
        </Box>
      </Box>
    </div>
  );
};

export default NotesTableWithAddForm;
