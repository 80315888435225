import React, { type FC } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { ContainerWithLogoAndOrganizationFooter } from './ContainerWithLogoAndOrganizationFooter';

export const paragraphStyle = {
  marginTop: 45,
  fontSize: 20,
  textAlign: 'center',
  fontWeight: '500'
} as React.CSSProperties;

export const FullPageMessage: FC<{ message?: string; isError?: boolean; showSpinner?: boolean }> = ({
  message = '',
  isError = false,
  showSpinner = false
}) => {
  return (
    <ContainerWithLogoAndOrganizationFooter>
      <Typography component="h1" color={isError ? 'error.main' : 'primary.main'} style={paragraphStyle}>
        {showSpinner && <CircularProgress size={20} color="primary" style={{ marginRight: 20 }} />}
        {message}
      </Typography>
    </ContainerWithLogoAndOrganizationFooter>
  );
};
