import {
  ConnectModel,
  ConnectStatus,
  CurrencyCode,
  OrganizationStatus,
  OrganizationTransfer,
  PaymentPlatformType,
  TaxTypes
} from '@efacity/common';
import { Administrator } from './Administrator';

export interface ConnectedAccount {
  isEfacity: boolean;
  name: string;
  stripeUserId: string;
  _id: string;
}
export interface StripePlatformAccount {
  currencyCode: CurrencyCode;
  isEfacity: boolean;
  stripeSecretKey: string;
  stripePublishableKey: string;
  stripeClientId?: string;
  connectedAccounts?: ConnectedAccount[];
}
export interface StripeExpressAccount {
  account: any;
  currencyCode: CurrencyCode;
  isConnectingNow: boolean;
  _id: string;
}

export interface PaymentPlatformSmallInfo {
  _id: string;
  name: string;
  paymentPlatformType: PaymentPlatformType;
  stripePlatformAccount?: StripePlatformAccount;
  stripeExpressAccount?: StripeExpressAccount;
}

class OrganizationAdminWithId extends Administrator {
  constructor(
    public _id: string,
    public override fullName: string,
    public callByName: string,
    public override email: string
  ) {
    super(fullName, email);
  }
}
export class Organization {
  constructor(
    public _id: string,
    public status: OrganizationStatus,
    public parentOrgId: string | null,
    public name: string,
    public timeZone: string,
    public administrators: OrganizationAdminWithId[],
    public hideNumberOfRegistrations: boolean,
    public connectModel: ConnectModel,
    public connectStatus: ConnectStatus,
    public organizationTransfers: OrganizationTransfer[],
    public description?: string,
    public logoFullUrl?: string,
    public mobileLogoFullUrl?: string,
    public ein?: string,
    public currency?: string,
    public website?: string,
    public customerLogoutUrl?: string,
    public schoolContactEmail?: string,
    public country?: string,
    public phone?: string,
    public phoneCountry?: string,
    public address?: string,
    public city?: string,
    public state?: string,
    public zip?: string,
    public taxType?: TaxTypes,
    public taxPercentage?: number,
    public paymentAccountId?: PaymentPlatformSmallInfo
  ) {}
}
