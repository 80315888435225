export interface StateOption {
  value: string;
  label: string;
}

export const UsStatesOptions: StateOption[] = [
  { value: 'AL', label: 'AL' },
  { value: 'AK', label: 'AK' },
  { value: 'AZ', label: 'AZ' },
  { value: 'AR', label: 'AR' },
  { value: 'CA', label: 'CA' },
  { value: 'CO', label: 'CO' },
  { value: 'CT', label: 'CT' },
  { value: 'DE', label: 'DE' },
  { value: 'FL', label: 'FL' },
  { value: 'GA', label: 'GA' },
  { value: 'HI', label: 'HI' },
  { value: 'ID', label: 'ID' },
  { value: 'IL', label: 'IL' },
  { value: 'IN', label: 'IN' },
  { value: 'IA', label: 'IA' },
  { value: 'KS', label: 'KS' },
  { value: 'KY', label: 'KY' },
  { value: 'LA', label: 'LA' },
  { value: 'ME', label: 'ME' },
  { value: 'MD', label: 'MD' },
  { value: 'MA', label: 'MA' },
  { value: 'MI', label: 'MI' },
  { value: 'MN', label: 'MN' },
  { value: 'MS', label: 'MS' },
  { value: 'MO', label: 'MO' },
  { value: 'MT', label: 'MT' },
  { value: 'NE', label: 'NE' },
  { value: 'NV', label: 'NV' },
  { value: 'NH', label: 'NH' },
  { value: 'NJ', label: 'NJ' },
  { value: 'NM', label: 'NM' },
  { value: 'NY', label: 'NY' },
  { value: 'NC', label: 'NC' },
  { value: 'ND', label: 'ND' },
  { value: 'OH', label: 'OH' },
  { value: 'OK', label: 'OK' },
  { value: 'OR', label: 'OR' },
  { value: 'PA', label: 'PA' },
  { value: 'RI', label: 'RI' },
  { value: 'SC', label: 'SC' },
  { value: 'SD', label: 'SD' },
  { value: 'TN', label: 'TN' },
  { value: 'TX', label: 'TX' },
  { value: 'UT', label: 'UT' },
  { value: 'VT', label: 'VT' },
  { value: 'VA', label: 'VA' },
  { value: 'WA', label: 'WA' },
  { value: 'WV', label: 'WV' },
  { value: 'WI', label: 'WI' },
  { value: 'WY', label: 'WY' }
];

export const CanadianProvincesOptions: StateOption[] = [
  { value: 'AB', label: 'AB' },
  { value: 'BC', label: 'BC' },
  { value: 'MB', label: 'MB' },
  { value: 'NB', label: 'NB' },
  { value: 'NL', label: 'NL' },
  { value: 'NT', label: 'NT' },
  { value: 'NS', label: 'NS' },
  { value: 'NU', label: 'NU' },
  { value: 'ON', label: 'ON' },
  { value: 'PE', label: 'PE' },
  { value: 'QC', label: 'QC' },
  { value: 'SK', label: 'SK' },
  { value: 'YT', label: 'YT' }
];

export const AustralianStateOptions: StateOption[] = [
  { value: 'NSW', label: 'NSW' },
  { value: 'QLD', label: 'QLD' },
  { value: 'SA', label: 'SA' },
  { value: 'TAS', label: 'TAS' },
  { value: 'VIC', label: 'VIC' },
  { value: 'WA', label: 'WA' }
];

// https://en.wikipedia.org/wiki/List_of_districts_in_India#:~:text=In%20some%20cases%2C%20districts%20are,the%202001%20Census%20of%20India.
export const IndiaStateOptions: StateOption[] = [
  { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
  { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
  { value: 'Assam', label: 'Assam' },
  { value: 'Bihar', label: 'Bihar' },
  { value: 'Chhattisgarh', label: 'Chhattisgarh' },
  { value: 'Goa', label: 'Goa' },
  { value: 'Gujarat', label: 'Gujarat' },
  { value: 'Haryana', label: 'Haryana' },
  { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
  { value: 'Jharkhand', label: 'Jharkhand' },
  { value: 'Karnataka', label: 'Karnataka' },
  { value: 'Kerala', label: 'Kerala' },
  { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
  { value: 'Maharashtra', label: 'Maharashtra' },
  { value: 'Manipur', label: 'Manipur' },
  { value: 'Meghalaya', label: 'Meghalaya' },
  { value: 'Mizoram', label: 'Mizoram' },
  { value: 'Nagaland', label: 'Nagaland' },
  { value: 'Odisha', label: 'Odisha' },
  { value: 'Punjab', label: 'Punjab' },
  { value: 'Rajasthan', label: 'Rajasthan' },
  { value: 'Sikkim', label: 'Sikkim' },
  { value: 'Tamil Nadu', label: 'Tamil Nadu' },
  { value: 'Telangana', label: 'Telangana' },
  { value: 'Tripura', label: 'Tripura' },
  { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
  { value: 'Uttarakhand', label: 'Uttarakhand' },
  { value: 'West Bengal', label: 'West Bengal' },
  { value: 'Andaman and Nicobar Islands', label: 'Andaman and Nicobar Islands' },
  { value: 'Chandigarh', label: 'Chandigarh' },
  { value: 'Dadra and Nagar Haveli and Daman and Diu', label: 'Dadra and Nagar Haveli and Daman and Diu' },
  { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' },
  { value: 'Ladakh', label: 'Ladakh' },
  { value: 'Lakshadweep', label: 'Lakshadweep' },
  { value: 'Delhi', label: 'Delhi' },
  { value: 'Puducherry', label: 'Puducherry' }
];

// https://en.wikipedia.org/wiki/Local_Government_in_Egypt
export const EgyptGovernorateOptions: StateOption[] = [
  { value: 'Alexandria', label: 'Alexandria' },
  { value: 'Aswan', label: 'Aswan' },
  { value: 'Asyut', label: 'Asyut' },
  { value: 'Beheira', label: 'Beheira' },
  { value: 'Beni Suef', label: 'Beni Suef' },
  { value: 'Cairo', label: 'Cairo' },
  { value: 'Dakahlia', label: 'Dakahlia' },
  { value: 'Damietta', label: 'Damietta' },
  { value: 'Faiyum', label: 'Faiyum' },
  { value: 'Gharbia', label: 'Gharbia' },
  { value: 'Giza', label: 'Giza' },
  { value: 'Ismailia', label: 'Ismailia' },
  { value: 'Kafr El Sheikh', label: 'Kafr El Sheikh' },
  { value: 'Luxor', label: 'Luxor' },
  { value: 'Matruh', label: 'Matruh' },
  { value: 'Minya', label: 'Minya' },
  { value: 'Monufia', label: 'Monufia' },
  { value: 'New Valley', label: 'New Valley' },
  { value: 'North Sinai', label: 'North Sinai' },
  { value: 'Port Said', label: 'Port Said' },
  { value: 'Qalyubia', label: 'Qalyubia' },
  { value: 'Qena', label: 'Qena' },
  { value: 'Red Sea', label: 'Red Sea' },
  { value: 'Sharqia', label: 'Sharqia' },
  { value: 'Sohag', label: 'Sohag' },
  { value: 'South Sinai', label: 'South Sinai' },
  { value: 'Suez', label: 'Suez' }
];
