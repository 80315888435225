import { CircularProgress } from '@mui/material';
import React from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export interface DeleteWithSpinnerIconProps {
  isUpdating: boolean;
}

export const DeleteWithSpinnerIcon: React.FC<DeleteWithSpinnerIconProps> = ({ isUpdating }) => {
  return isUpdating ? (
    <CircularProgress
      size={20}
      sx={{
        color: 'primary.main',
        top: '50%',
        left: '50%',
        marginTop: 0,
        marginLeft: 0
      }}
    />
  ) : (
    <DeleteForeverIcon />
  );
};

export default DeleteWithSpinnerIcon;
