import type {
  AdditionalSessionOption,
  DiscountInterface,
  ShoppingCart,
  ShoppingCartProduct,
  ShoppingCartRegistration
} from '@efacity/common';
import {
  CurrencyCode,
  calculateAmountWithTax,
  getAmountForShoppingCart,
  getTaxAmount,
  getTaxDescription,
  isShoppingCartEmpty,
  moneyWithCurrencyCodeFormatter
} from '@efacity/common';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import {
  smallTableCellSx,
  smallTableContainerSx,
  smallTableHeadCellSx,
  smallTableHeadSx,
  smallTableRowSx,
  smallTableTotalCellSx
} from '../../styles/stripedSmallTableStyles';

export interface SmallShoppingCartTableProps {
  shoppingCart: ShoppingCart;
}

export const ShoppingCartSmallTable: FC<SmallShoppingCartTableProps> = ({ shoppingCart }) => {
  const shoppingCartRegistrations =
    shoppingCart?.shoppingCartRegistrations?.length > 0 ? shoppingCart.shoppingCartRegistrations : [];
  const shoppingCartAmount = getAmountForShoppingCart(shoppingCart);
  const shoppingCartTaxAmount = getTaxAmount(shoppingCartAmount, shoppingCart?.taxType, shoppingCart?.taxPercentage);
  const shoppingCartAmountWithTax = calculateAmountWithTax(shoppingCartAmount, shoppingCartTaxAmount);
  const taxDescription = getTaxDescription(shoppingCart?.taxType, shoppingCart?.taxPercentage);
  const currencyCode = (shoppingCart?.currency as CurrencyCode) || CurrencyCode.USD;

  return (
    <>
      {shoppingCart?.orgName && (
        <div style={{ textAlign: 'left', fontSize: 14, fontWeight: 600, marginBottom: 10 }}>{shoppingCart.orgName}</div>
      )}
      {!isShoppingCartEmpty(shoppingCart) && (
        <TableContainer sx={smallTableContainerSx}>
          <Table size="small" aria-label="Shopping cart table">
            <TableHead sx={smallTableHeadSx}>
              <TableRow sx={smallTableRowSx}>
                <TableCell sx={{ ...smallTableHeadCellSx, px: 0.5 }} align="left">
                  Item
                </TableCell>
                <TableCell sx={{ ...smallTableHeadCellSx, width: '100px', px: 0.5 }} align="right">
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <React.Fragment>
                {shoppingCartRegistrations?.length > 0 &&
                  shoppingCartRegistrations.map((shoppingCartRegistration: ShoppingCartRegistration, index: number) => (
                    <React.Fragment key={`shoppingCartSessionItem${index}RowKey`}>
                      <TableRow sx={smallTableRowSx}>
                        <TableCell sx={{ ...smallTableCellSx, px: 0.5 }} align="left">
                          {shoppingCartRegistration.student?.name
                            ? `${shoppingCartRegistration.session.name} for ${shoppingCartRegistration.student.name}`
                            : `${shoppingCartRegistration.session?.name} (student not selected)`}
                        </TableCell>
                        <TableCell sx={{ ...smallTableCellSx, px: 0.5 }} align="right">
                          {moneyWithCurrencyCodeFormatter(
                            shoppingCartRegistration.session.price,
                            shoppingCartRegistration.session.currencyCode as CurrencyCode
                          )}
                        </TableCell>
                      </TableRow>

                      {(shoppingCartRegistration?.additionalSessionOptions ?? [])?.length > 0 &&
                        (shoppingCartRegistration?.additionalSessionOptions ?? []).map(
                          (additionalOption: AdditionalSessionOption, optionIndex) => (
                            <TableRow
                              sx={smallTableRowSx}
                              key={`shoppingCartAdditionalOptionItem${index}RowKey${optionIndex}`}
                            >
                              <TableCell
                                sx={{ ...smallTableCellSx, paddingLeft: '25px', paddingRight: '5px' }}
                                align="left"
                              >
                                {additionalOption.name}
                              </TableCell>
                              <TableCell sx={{ ...smallTableCellSx, px: 0.5 }} align="right">
                                {moneyWithCurrencyCodeFormatter(
                                  additionalOption.price,
                                  additionalOption.currencyCode as CurrencyCode
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        )}

                      {(shoppingCartRegistration?.discounts ?? [])?.length > 0 &&
                        (shoppingCartRegistration?.discounts ?? []).map(
                          (discount: DiscountInterface, discountIndex) => (
                            <TableRow
                              sx={smallTableRowSx}
                              key={`shoppingCartDiscountItem${index}RowKey${discountIndex}`}
                            >
                              <TableCell
                                sx={{ ...smallTableCellSx, paddingLeft: '25px', paddingRight: '5px' }}
                                align="left"
                              >
                                {discount.description}
                              </TableCell>
                              <TableCell sx={{ ...smallTableCellSx, px: 0.5 }} align="right">
                                {moneyWithCurrencyCodeFormatter(
                                  discount.calculatedAmount,
                                  discount.currencyCode as CurrencyCode
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                    </React.Fragment>
                  ))}
                {shoppingCart?.shoppingCartProducts?.length > 0 &&
                  shoppingCart.shoppingCartProducts.map((shoppingCartProduct: ShoppingCartProduct, index: number) => (
                    <React.Fragment key={`shoppingCartSessionItem${index}RowKey`}>
                      <TableRow sx={smallTableRowSx}>
                        <TableCell sx={{ ...smallTableCellSx, px: 0.5 }} align="left">
                          {shoppingCartProduct.product.name}
                        </TableCell>
                        <TableCell sx={{ ...smallTableCellSx, px: 0.5 }} align="right">
                          {moneyWithCurrencyCodeFormatter(
                            shoppingCartProduct.product.price,
                            shoppingCartProduct.product.currencyCode as CurrencyCode
                          )}
                        </TableCell>
                      </TableRow>

                      {(shoppingCartProduct?.discounts ?? [])?.length > 0 &&
                        (shoppingCartProduct?.discounts ?? []).map((discount: DiscountInterface, discountIndex) => (
                          <TableRow sx={smallTableRowSx} key={`shoppingCartDiscountItem${index}RowKey${discountIndex}`}>
                            <TableCell
                              sx={{ ...smallTableCellSx, paddingLeft: '25px', paddingRight: '5px' }}
                              align="left"
                            >
                              {discount.description}
                            </TableCell>
                            <TableCell sx={{ ...smallTableCellSx, px: 0.5 }} align="right">
                              {moneyWithCurrencyCodeFormatter(
                                discount.calculatedAmount,
                                discount.currencyCode as CurrencyCode
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </React.Fragment>
                  ))}
              </React.Fragment>
              {shoppingCartTaxAmount > 0 ? (
                <>
                  <TableRow sx={smallTableRowSx} key={`shoppingCartSubTotalItemRowKey`}>
                    <TableCell sx={{ ...smallTableTotalCellSx, px: 0.5 }} align="right">
                      Sub Total :
                    </TableCell>
                    <TableCell sx={{ ...smallTableTotalCellSx, px: 0.5 }} align="right">
                      {moneyWithCurrencyCodeFormatter(shoppingCartAmount, currencyCode)}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={smallTableRowSx} key={`shoppingCartTaxItemRowKey`}>
                    <TableCell sx={{ ...smallTableTotalCellSx, px: 0.5 }} align="right">
                      {taxDescription}
                    </TableCell>
                    <TableCell sx={{ ...smallTableTotalCellSx, px: 0.5 }} align="right">
                      {moneyWithCurrencyCodeFormatter(shoppingCartTaxAmount, currencyCode)}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={smallTableRowSx} key={`shoppingCartTotalItemRowKey`}>
                    <TableCell sx={{ ...smallTableTotalCellSx, px: 0.5 }} align="right">
                      Total :
                    </TableCell>
                    <TableCell sx={{ ...smallTableTotalCellSx, px: 0.5 }} align="right">
                      {moneyWithCurrencyCodeFormatter(shoppingCartAmountWithTax, currencyCode)}
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow sx={smallTableRowSx} key={`shoppingCartTotalItemRowKey`}>
                  <TableCell sx={{ ...smallTableTotalCellSx, px: 0.5 }} align="right">
                    Total :
                  </TableCell>
                  <TableCell sx={{ ...smallTableTotalCellSx, px: 0.5 }} align="right">
                    {moneyWithCurrencyCodeFormatter(shoppingCartAmount, currencyCode)}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
