import { adminSectionTableFontSize, minimalWidthToFitContent, SessionTimeCell } from '@efacity/frontend-shared';
import { LightTooltip } from '@efacity/react-next-sc';
import { EnhancedColumn } from '../../components/Table/Table';

import {
  ActivityTypes,
  convertUtcToZonedTime,
  DurationType,
  getSessionDuration,
  getStudentLevelDiapason,
  moneyWithCurrencyCodeFormatter,
  nowUTC,
  SessionStatus,
  formatStringToMonthDayYearDate
} from '@efacity/common';
import { Session as EfacitySession } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import Lock from '@mui/icons-material/Lock';
import { Box, Link, Typography } from '@mui/material';
import { Dispatch, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import LocationTypeColumnFilter from '../../components/ColumnFilters/LocationTypeColumnFilter';
import DateCell from '../../components/DateCell';
import SessionLocationCell from '../../components/SessionLocationCell/SessionLocationCell';
import SessionRegistrationLinkWithCopier from '../../components/SessionRegistrationLinkWithCopier/SessionRegistrationLinkWithCopier';
import SessionsTableActionsCell from './SessionsTableActionsCell';

export interface SessionActions {
  onCloneSession(session: EfacitySession): void;
  handleRestoreSession(sessionId: string): void;
  onShowCancellationModal(sessionId: string, setSessionRowUnderUpdate?: Dispatch<string>): void;
  onSessionStatusChanged(sessionId: string, setSessionRowUnderUpdate: Dispatch<string>, newStatus: SessionStatus): void;
}

const displaySessionDuration = (session: EfacitySession) => {
  if (session.durationType === DurationType.selfPaced) return 'self-paced';
  if (!session?.schedule?.instances) return 'N/A';
  return getSessionDuration(session.schedule.instances.length, session.type);
};

const displaySessionCapacity = (session: EfacitySession): [string, string] => {
  if (session.durationType === DurationType.selfPaced) return [`${session.registrationsNumber}`, 'primary'];

  const registrationsAmount = session.registrationsNumber;
  const capacity = session.capacity;

  const textColor = registrationsAmount >= capacity ? 'error' : 'primary';
  return [`${registrationsAmount} / ${capacity}`, textColor];
};

const useSessionsTableColumns = (
  accessId: string,
  isUserAllowedToPerformSessionActions: boolean,
  isUserIndependentTeacher: boolean,
  sessionActions: SessionActions,
  width,
  timeHeader: string
) => {
  const columns = useMemo(() => {
    const sessionNameColumn: EnhancedColumn<any> = {
      Header: 'Name',
      accessor: 'name',
      disableFilters: false,
      disableSortBy: true,
      columnStyles: { minWidth: 170 },
      Cell: ({ row }) => {
        return !row.original?.cancelledAt ? (
          <Box display="flex" alignItems="center" data-testid="session-name-copier-cell" style={{ marginRight: 5 }}>
            {row.original.status === SessionStatus.Private ? (
              <LightTooltip
                title={
                  <Typography>
                    Session is private and not visible to the public. To make it public click "Switch to public"
                  </Typography>
                }
              >
                <span>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ display: 'table', marginRight: 5 }}>
                      <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                        <Lock color="primary" />
                      </div>
                    </div>
                    <div>
                      <div style={{ display: 'table' }}>
                        <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                          <Link
                            component={NavLink}
                            to={toPath(PATHS.session, { orgId: accessId, id: row.original._id })}
                            underline={'none'}
                          >
                            {row.original.name} {row.original.section && <span>({row.original.section})</span>}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
              </LightTooltip>
            ) : (
              <Link
                component={NavLink}
                to={toPath(PATHS.session, { orgId: accessId, id: row.original._id })}
                underline={'none'}
              >
                {row.original.name} {row.original.section && <span>({row.original.section})</span>}
              </Link>
            )}
            &nbsp;
            <SessionRegistrationLinkWithCopier
              isEnabled={true}
              sessionId={row.original._id}
              owner={row.original.owner}
              type={row.original.type as ActivityTypes}
              activityId={row.original.activityId}
              locationId={row.original?.location?._id}
            />
          </Box>
        ) : (
          <Box data-testid="session-name-copier-cell" style={{ marginRight: 5 }}>
            <div style={{ color: 'red' }}>(cancelled: '{row.original.cancellationReason}')</div>
            {row.original.name} {row.original.section && <span>({row.original.section})</span>}
          </Box>
        );
      }
    };

    const sessionGradeColumn: EnhancedColumn<any> = {
      Header: 'Grade',
      id: 'session-grade',
      disableFilters: false,
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <Box data-testid="grade">
            {row.original?.studentLevel ? getStudentLevelDiapason(row.original.studentLevel) : '-'}
          </Box>
        );
      }
    };

    const timeDateColumn: EnhancedColumn<any> = {
      Header: timeHeader,
      headerStyles: { whiteSpace: 'pre-wrap' },
      disableFilters: true,
      Cell: ({ row }) => (
        <div data-testid="session-time-cell">
          {row.original?.schedule ? (
            <SessionTimeCell
              sessionSchedule={row.original.schedule}
              timeZone={row.original.owner.orgId.timeZone}
              displayTimeZone={false}
            />
          ) : (
            'N / A'
          )}
        </div>
      )
    };
    const startDateColumn: EnhancedColumn<any> = {
      Header: 'Starts On',
      disableFilters: true,
      Cell: ({ row }) => (
        <div data-testid="session-start-date-cell">
          <DateCell
            dateTime={
              row.original?.schedule?.startDateTime
                ? convertUtcToZonedTime(row.original?.schedule?.startDateTime, row.original.owner.orgId.timeZone)
                : undefined
            }
            showTime={false}
            wrap={true}
            style={{ ...adminSectionTableFontSize }}
          />
        </div>
      )
    };
    const endDateColumn: EnhancedColumn<any> = {
      Header: 'Ends On',
      id: 'end-date',
      disableFilters: true,
      Cell: ({ row }) => (
        <div data-testid="session-end-date-cell">
          <DateCell
            dateTime={
              row.original?.schedule?.endDateTime
                ? convertUtcToZonedTime(row.original?.schedule?.endDateTime, row.original.owner.orgId.timeZone)
                : undefined
            }
            showTime={false}
            style={{ ...adminSectionTableFontSize }}
          />
        </div>
      )
    };
    const daySColumn: EnhancedColumn<any> = {
      Header: 'Duration',
      id: 'days-amount',
      accessor: 'duration',
      disableFilters: true,
      disableSortBy: true,
      columnStyles: { ...adminSectionTableFontSize },
      Cell: ({ row }) => <div data-testid="session-days-cell">{displaySessionDuration(row.original)}</div>
    };
    const priceColumn: EnhancedColumn<any> = {
      Header: 'Price',
      id: 'formatted-price',
      disableFilters: true,
      headerStyles: {
        textAlign: 'right'
      },
      Cell: ({ row }) => (
        <div style={{ textAlign: 'right' }} data-testid="session-price-cell">
          {moneyWithCurrencyCodeFormatter(row.original.price, row.original.currency)}
        </div>
      )
    };
    const instructorColumn: EnhancedColumn<any> = {
      Header: 'Instructor',
      accessor: 'teacher',
      disableFilters: true,
      disableSortBy: true,
      columnStyles: { ...adminSectionTableFontSize, ...minimalWidthToFitContent },
      Cell: ({ value: teacher }) => <div data-testid="session-teacher-cell">{teacher ? teacher.fullName : 'N/A'}</div>
    };
    const registrationDeadlineColumn: EnhancedColumn<any> = {
      Header: 'Registration Deadline',
      accessor: 'schedule.deadlineForCustomerRegistration',
      disableFilters: true,
      disableSortBy: true,
      headerStyles: { whiteSpace: 'normal' },
      columnStyles: { ...adminSectionTableFontSize, ...minimalWidthToFitContent },
      Cell: ({ value, row }) => {
        const dataTestId = 'session-registration-deadline-cell';
        if (!value) return <div data-testid={dataTestId}>N/A</div>;

        const deadlineDate = convertUtcToZonedTime(value, row.original.owner.orgId.timeZone);
        const convertToTimeZone = deadlineDate.toISOString();

        const isExpired = new Date(value) < nowUTC();
        const divStyle = { color: isExpired ? '#FF0100' : 'initial', width: '7ch' };
        return (
          <div style={divStyle} data-testid={dataTestId}>
            {formatStringToMonthDayYearDate(convertToTimeZone)}
          </div>
        );
      }
    };
    const capacityColumn: EnhancedColumn<any> = {
      Header: 'Students',
      accessor: 'capacity',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => {
        const [displayValue, textColor] = displaySessionCapacity(row.original);
        return (
          <Typography style={adminSectionTableFontSize}>
            <Link
              component={NavLink}
              underline={'none'}
              to={`${toPath(PATHS.session, { orgId: accessId, id: row.original._id })}/students`}
              color={textColor}
            >
              {displayValue}
            </Link>
          </Typography>
        );
      }
    };
    const locationColumn: EnhancedColumn<any> = {
      Header: 'Location',
      id: 'location.type',
      accessor: 'location.type',
      disableFilters: false,
      disableSortBy: true,
      Cell: ({ row }) => (
        <div data-testid="session-location-cell">
          <SessionLocationCell session={row.original} />
        </div>
      ),
      Filter: LocationTypeColumnFilter
    };

    let columnsBase: EnhancedColumn<any>[];
    if (width === 'md') {
      columnsBase = [
        sessionNameColumn,
        sessionGradeColumn,
        timeDateColumn,
        startDateColumn,
        endDateColumn,
        daySColumn,
        priceColumn,
        ...(isUserIndependentTeacher ? [] : [instructorColumn]),
        registrationDeadlineColumn,
        capacityColumn,
        locationColumn
      ];
    } else if (width === 'sm') {
      columnsBase = [sessionNameColumn, startDateColumn, endDateColumn, daySColumn, priceColumn, locationColumn];
    } else {
      columnsBase = [sessionNameColumn, startDateColumn, priceColumn];
    }

    if (isUserAllowedToPerformSessionActions) {
      return [
        ...columnsBase,
        {
          Header: '',
          id: 'session-actions',
          disableFilters: true,
          columnStyles: { width: 195, maxWidth: 195 },
          Cell: ({ row }) => {
            return <SessionsTableActionsCell row={row} {...sessionActions} />;
          }
        }
      ];
    }

    return columnsBase;
  }, [accessId, sessionActions, isUserAllowedToPerformSessionActions, width, timeHeader, isUserIndependentTeacher]);

  return [{ columns }];
};

export default useSessionsTableColumns;
