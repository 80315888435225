import React from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/icons-material/Link';

const AddLinkButton = ({ isSelected, onInsertLink }) => {
  return (
    <Button
      style={{ minWidth: 34, borderRadius: 0, borderBottom: isSelected ? '2px solid gray' : '2px solid transparent' }}
      onMouseDown={event => {
        event.preventDefault();
        // TODO: Change to modal
        const url = window.prompt('Enter the URL of the link:');
        if (!url) return;
        onInsertLink(url);
      }}
    >
      <Link />
    </Button>
  );
};

export default AddLinkButton;
