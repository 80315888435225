export enum OwnerType {
  School = 'schoolOwner',
  Center = 'centerOwner',
  Independent = 'independentTeacherOwner',
  Efacity = 'efacity'
}

export const ownerTypeViewConnector = {
  [OwnerType.School]: 'School',
  [OwnerType.Center]: 'Center',
  [OwnerType.Independent]: 'Independent Teacher',
  [OwnerType.Efacity]: 'Efacity'
};

export interface OwnerInterface {
  orgId?: any;
  ownerType: OwnerType;
}
