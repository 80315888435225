import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { smallButtonHeight } from '@efacity/frontend-shared';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { useMatchRoutes } from '../../hooks/useMatchRoutes';

const navBarSx = {
  ...smallButtonHeight,
  '& a:first-of-type': {
    borderRadius: '2px 0 0 2px'
  },
  '& a:last-of-type': {
    borderRadius: '0 2px 2px 0'
  },
  '& a:not(:last-of-type)': {
    borderRight: 0
  }
};

export interface NavToggleButtonTab {
  label: string;
  to: string;
  matchPaths: string[];
}

export interface NavToggleButtonsProps {
  tabs: NavToggleButtonTab[];
}
export const NavToggleButtons = ({ tabs }: NavToggleButtonsProps) => {
  return (
    <ToggleButtonGroup color="primary" exclusive sx={navBarSx}>
      {tabs.map((tab) => (
        <NavToggleButton key={tab.to} tab={tab} />
      ))}
    </ToggleButtonGroup>
  );
};

const NavToggleButton = ({ tab }: { tab: NavToggleButtonTab }) => {
  const isLinkActive = useMatchRoutes(tab.matchPaths);

  return (
    <ToggleButton color="primary" value={tab.label} component={NavLink} to={tab.to} selected={isLinkActive}>
      {tab.label}
    </ToggleButton>
  );
};
