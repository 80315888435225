import { Messages, ProductPurchaseInterface } from '@efacity/common';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { FetchDataOptions, initialFetchDataOptions } from '../../components/Table/Table';
import { apiService } from '@efacity/frontend-shared';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';
import removeDateOffsetFromFilters from '../../utils/removeDateOffsetFromFilters';
import { cloneDeep } from 'lodash';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

export interface ProductPurchasesState {
  productPurchases: ProductPurchaseInterface[];
  total: number;
  timeZone: string;
  isLoading: boolean;
  fetchDataOptions: FetchDataOptions;
  processingPurchaseId: string | null;
}
export const defaultMessagesState: ProductPurchasesState = {
  productPurchases: [],
  total: 0,
  timeZone: '',
  isLoading: false,
  fetchDataOptions: initialFetchDataOptions,
  processingPurchaseId: null
};
export const useProductPurchases = (orgId: string) => {
  const snackbarProps = useSnackbar();

  const [productPurchasesState, setProductPurchasesState] = useState<ProductPurchasesState>(defaultMessagesState);

  const getProductPurchases = useCallback(
    async (fetchDataOptions: FetchDataOptions): Promise<void> => {
      setProductPurchasesState((prevState) => ({
        ...prevState,
        isLoading: true,
        fetchDataOptions: { ...fetchDataOptions }
      }));
      const clonedFetchDataOptions = cloneDeep(fetchDataOptions);
      const { filters, pageIndex, pageSize, sortBy } = clonedFetchDataOptions;
      const dateFilters = ['updatedAt'];
      removeDateOffsetFromFilters(filters, dateFilters);

      try {
        const { data } = await apiService.get<{ data: ProductPurchaseInterface[]; total: number; timeZone: string }>(
          `/org/${orgId}/product-purchases`,
          {
            page: pageIndex,
            perPage: pageSize,
            ...getFiltersQueryParameters(filters),
            sortBy: mapReactTableSortToApiSort(sortBy)
          }
        );

        setProductPurchasesState((prevState) => ({
          ...prevState,
          productPurchases: data.data,
          total: data.total,
          timeZone: data.timeZone,
          isLoading: false
        }));
      } catch (error) {
        setProductPurchasesState((state) => ({ ...state, isLoading: false }));
        showNotification(snackbarProps, false, error.response?.data?.message || Messages.FailedGetMessages);
      }
    },
    [orgId, snackbarProps]
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const changeStatus = async (productPurchaseId: string): Promise<void> => {};

  return { productPurchasesState, setProductPurchasesState, getProductPurchases, changeStatus };
};
