import type { Theme } from '@mui/material';
import type { FC } from 'react';
import { Box } from '@mui/material';
export const imagePreviewStyles = {
  root: (imageWidth: number, imageHeight: number) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: (theme: Theme) => theme.palette.grey[200],
    width: { xs: '100%', md: imageWidth },
    aspectRatio: imageHeight / imageWidth,
    maxHeight: imageHeight
  })
};

export const NoImagePreview: FC<{ imageHeight: number; imageWidth: number }> = ({ imageHeight, imageWidth }) => {
  return (
    <Box sx={imagePreviewStyles.root(imageWidth, imageHeight)} data-testid="media-preview-no-image">
      No image...
    </Box>
  );
};
