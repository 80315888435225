import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { FC } from 'react';

export const ZoomIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="#2196F3"
      />
      <path
        d="M14.5 15.5H7C6.1715 15.5 5.5 14.8285 5.5 14V8.5H13C13.8285 8.5 14.5 9.1715 14.5 10V15.5Z"
        fill="white"
      />
      <path d="M18.5 15.5L15.5 13.5V10.5L18.5 8.5V15.5Z" fill="white" />
    </SvgIcon>
  );
};
