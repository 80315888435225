import { EnhancedColumn } from '../../components/Table/Table';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import { PATHS, toPath } from '@efacity/routing';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import { activityTypesMapper, formatStartToEndDateInTimeZone } from '@efacity/common';
import SessionsWithTagLinkCopier from './SessionsWithTagLinkCopier';

const useTagsTableColumns = (accessId, additionalColumns: EnhancedColumn<any>[] = []) => {
  return useMemo(() => {
    const columns: EnhancedColumn<any>[] = [
      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,
        columnStyles: { ...adminSectionTableFontSize },
        Cell: ({ row, value }) => {
          return (
            <Box display="flex" alignItems="center" data-testid="session-tag-copier-cell" style={{ marginRight: 5 }}>
              <Link
                component={NavLink}
                to={toPath(PATHS.editTag, { orgId: accessId, tagId: row.original._id })}
                underline={'none'}
              >
                {value?.trim()?.length > 0 ? value : '[no name]'}
              </Link>
              &nbsp;
              <SessionsWithTagLinkCopier isEnabled={true} orgId={accessId} tagId={row.original._id} />
            </Box>
          );
        }
      },
      {
        Header: 'Activity Types',
        accessor: 'activityTypes',
        disableFilters: true,
        Cell: ({ value }) => {
          return value
            ? value.map((activityType, index) =>
                index === 0 ? activityTypesMapper[activityType] : `, ${activityTypesMapper[activityType]}`
              )
            : '-';
        }
      },
      {
        Header: 'Session Period',
        disableFilters: true,
        Cell: ({ row, value: typeDefinition }) => {
          return (
            <div>
              {row.original?.validFrom && row.original?.validTo && row.original?.timeZone
                ? `${formatStartToEndDateInTimeZone(
                    row.original.validFrom,
                    row.original.validTo,
                    row.original.timeZone
                  )}`
                : ''}
            </div>
          );
        }
      },
      {
        Header: 'Tagged Sessions',
        disableFilters: true,
        Cell: ({ row, value: typeDefinition }) => {
          return <div color="primary">{row.original?.sessions ? row.original?.sessions?.length : '-'}</div>;
        }
      },
      ...additionalColumns
    ];

    return columns;
  }, [accessId, additionalColumns]);
};

export default useTagsTableColumns;
