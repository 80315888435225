import { Box, FormHelperText, TextField } from '@mui/material';
import React, { useMemo } from 'react';
import { FilterProps, Renderer } from 'react-table';
import { moneyWithCurrencyCodeFormatter } from '@efacity/common';
import { CurrencyFormat } from '@efacity/react-hook-form-mui';

const MoneyRangeColumnFilter: Renderer<FilterProps<any>> = ({ column }) => {
  const { filterValue = [], preFilteredRows, setFilter, id } = column;
  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  const minValue = filterValue[0] ? filterValue[0] / 100 : '';
  const maxValue = filterValue[1] ? filterValue[1] / 100 : '';

  const handleMinValueChange = (e) => {
    const { value } = e.target;
    setFilter((columnFilter = []) => [value ? value : undefined, columnFilter[1]]);
  };

  const handleMaxValueChange = (e) => {
    const { value } = e.target;
    setFilter((columnFilter = []) => [columnFilter[0], value ? value : undefined]);
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <Box>
        <TextField
          name="minValue"
          value={minValue}
          size="small"
          variant="outlined"
          onChange={handleMinValueChange}
          inputProps={{
            style: { fontSize: 14, margin: 0, padding: 5 }
          }}
          InputProps={{
            inputComponent: CurrencyFormat
          }}
          placeholder="Min"
          style={{
            width: '75px',
            marginRight: '0.5rem'
          }}
        />
        <FormHelperText>{moneyWithCurrencyCodeFormatter(min)}</FormHelperText>
      </Box>

      <Box>
        <TextField
          name="maxValue"
          value={maxValue}
          size="small"
          variant="outlined"
          onChange={handleMaxValueChange}
          placeholder="Max"
          inputProps={{
            style: { fontSize: 14, margin: 0, padding: 5 }
          }}
          InputProps={{
            inputComponent: CurrencyFormat
          }}
          style={{
            width: '75px'
          }}
        />
        <FormHelperText>{moneyWithCurrencyCodeFormatter(max)}</FormHelperText>
      </Box>
    </Box>
  );
};

export default MoneyRangeColumnFilter;
