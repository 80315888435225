import React, { useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton, useTheme } from '@mui/material';

export interface SubjectWithMessageCellProps {
  subject: string;
  body: string;
}

const SubjectWithMessageCell: React.FC<SubjectWithMessageCellProps> = ({ subject, body }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%', float: 'left' }}>{subject}</div>
        <div style={{ float: 'right', verticalAlign: 'top' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            style={{ height: 26, paddingTop: 0, paddingBottom: 0 }}
            data-testid="collapseButton"
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </div>
      </div>
      {open && (
        <div
          dangerouslySetInnerHTML={{ __html: body }}
          style={{
            padding: 10,
            borderColor: theme.palette.primary.light,
            borderRadius: 5,
            borderWidth: 1,
            borderStyle: 'solid',
            width: 'inherit',
            overflowY: 'auto',
            maxWidth: '775'
          }}
        ></div>
      )}
    </>
  );
};

export default SubjectWithMessageCell;
