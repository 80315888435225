import { ModalDialogTitle } from '@efacity/react-next-sc';
import { Dialog, DialogContent } from '@mui/material';
import { StudentAgreementsTable } from '../../components/EditStudentWithCustomerInfoModal/StudentAgreementsTable';

export const StudentAgreementsModal = ({ studentId, studentName, orgId, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" id="edit-student-with-customer" fullWidth>
      <ModalDialogTitle text={`Agreements for student ${studentName}`} onClose={onClose} />

      <DialogContent>
        <StudentAgreementsTable studentId={studentId} orgId={orgId} />
      </DialogContent>
    </Dialog>
  );
};
