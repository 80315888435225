import { Box, Typography } from '@mui/material';
import { GenderViewConnector, HonorificsViewConnector, Parent, RelationshipViewConnector } from '@efacity/common';
import { enrollmentViewSx } from './enrollmentViewSx';
import React from 'react';
import { ViewItem, AddressMapLink } from '@efacity/frontend-shared';

interface ParentInfoSectionProps {
  parent: Parent;
  sectionTitle: string;
}

export const ParentInfoSection = ({ parent, sectionTitle }: ParentInfoSectionProps) => {
  return (
    <Box component="section" sx={enrollmentViewSx.section}>
      <Typography component="h2" sx={enrollmentViewSx.subtitle}>
        {sectionTitle}
      </Typography>
      <ViewItem title="Honorific">{HonorificsViewConnector[parent.honorific]}</ViewItem>

      <ViewItem title="First name">{parent.firstName}</ViewItem>
      <ViewItem title="Last name">{parent.lastName}</ViewItem>
      <ViewItem title="Email">{parent.email}</ViewItem>
      <ViewItem title="Phone">{parent.phone}</ViewItem>

      <ViewItem title="Gender">{GenderViewConnector[parent.gender]}</ViewItem>
      <ViewItem title="Relationship to applicant">{RelationshipViewConnector[parent.relationship]}</ViewItem>
      <ViewItem isOptional title="Professional background">
        {parent.professionalBackground}
      </ViewItem>
      <ViewItem isOptional title="Employer">
        {parent.employer}
      </ViewItem>
      <ViewItem title="Custody">{parent.custody}</ViewItem>

      <section>
        <Typography component="h3" style={{ fontWeight: 'bold', fontSize: '1.25em' }}>
          Address
        </Typography>
        {parent.isStudentsAddress ? (
          <div style={{ fontStyle: 'italic' }}>Mailing address same as candidate's</div>
        ) : (
          <>
            <ViewItem title="Country">{parent.address.country}</ViewItem>
            <ViewItem title="Address">{parent.address.address}</ViewItem>
            <ViewItem isOptional title="Address2">
              {parent.address.address2}
            </ViewItem>
            <ViewItem title="City, state">{parent.address.city + ', ' + parent.address.state}</ViewItem>
            <ViewItem title="Zip">{parent.address.zip}</ViewItem>

            <AddressMapLink address={parent.address} />
          </>
        )}
      </section>
    </Box>
  );
};
