import { PageTitle } from '@efacity/react-next-sc';
import React from 'react';
import {
  Activity,
  AgeStudentLevelTypeValue,
  CurrencyCode,
  DurationType,
  getStudentLevelDiapason,
  moneyWithCurrencyCodeFormatter,
  StudentLevel
} from '@efacity/common';
import { capitalize } from 'lodash';
import { ActivityDescriptions } from './ActivityDescriptions';
import { ViewItem, viewItemParagraphStyle } from '@efacity/frontend-shared';
import { Button } from '@mui/material';
import { PATHS, toPath } from '@efacity/routing';
import { useParams, Link } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';

const imageSize = '282px';
const durationTypeDisplayValue = {
  [DurationType.Fixed]: 'Fixed',
  [DurationType.selfPaced]: 'Self-paced'
};

export const ActivityView = ({ activity }: { activity: Activity }) => {
  const { orgId } = useParams<OrgIdParamType>();

  if (!activity) return null;
  const title = 'View ' + activity.type;
  const activityNameTitle = capitalize(activity.type) + ' name';
  const scheduleType = durationTypeDisplayValue[activity.durationType];
  const activityCategories = activity.categories.map((cat) => cat.name).join(', ');
  const defaultPrice = moneyWithCurrencyCodeFormatter(
    activity.defaultSessionPrice,
    activity.defaultSessionCurrency as CurrencyCode
  );

  const isAgeLevel = activity?.studentLevel.type === AgeStudentLevelTypeValue;
  const studentLevel = activity?.studentLevel
    ? getStudentLevelDiapason(activity.studentLevel as StudentLevel).split(':')[1]
    : '-';

  return (
    <div style={{ width: 'fit-content' }}>
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <PageTitle title={title} underlined />
      </div>
      <div style={{ display: 'flex', gap: '30px', marginBottom: '20px', flexWrap: 'wrap' }}>
        <img src={activity.imageFullUrl} alt="" style={{ width: imageSize, height: imageSize }} />
        <div>
          <ViewItem title={activityNameTitle}>
            <span style={{ color: '#2A517E', fontWeight: 600 }}>{activity.name}</span>
          </ViewItem>
          <ViewItem title="Schedule type">{scheduleType}</ViewItem>
          <ViewItem title="Level">{activity.level}</ViewItem>
          <ViewItem title="Category">{activityCategories}</ViewItem>
        </div>
      </div>
      <ActivityDescriptions activity={activity} />
      <ViewItem title="Teacher instructons">
        <p style={viewItemParagraphStyle}>{activity.teacherInstructions}</p>
      </ViewItem>
      <ViewItem title="Number of instances">{activity.numberOfInstances}</ViewItem>
      <ViewItem title={isAgeLevel ? 'Age' : 'Grade'}>{studentLevel}</ViewItem>
      <ViewItem title="Suggested session price">{defaultPrice}</ViewItem>
      <ViewItem title="Fee">{activity.fee} %</ViewItem>
      <div style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          style={{ width: '200px' }}
          component={Link}
          to={toPath(PATHS.addSession, { orgId: orgId })}
          state={{
            isCloning: false,
            activityId: activity._id
          }}
        >
          Create a session
        </Button>
      </div>
    </div>
  );
};
