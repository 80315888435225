import { Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import {
  ActivityTypeChecker,
  DiscountClassSelector,
  DiscountTypeSelector,
  FormCurrencyInput,
  FormDatePicker,
  FormTextInput,
  TextFieldWithCounter
} from '@efacity/react-hook-form-mui';
import {
  ActivityTypes,
  CurrencyCode,
  DiscountClass,
  DiscountType,
  DiscountTypeOptions,
  percentageDiscountTypeOption,
  randomString
} from '@efacity/common';
import copy from 'copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { defaultSiblingDiscountDescriptionForCustomer } from '../initialDiscountFormValues';

const DiscountFormFields = () => {
  const methods = useFormContext();

  const handleDiscountClassChanged = (discountClass: DiscountClass) => {
    methods.setValue('classDefinition.discountClass', discountClass, { shouldDirty: true });
    if (discountClass === DiscountClass.Sibling) {
      methods.setValue('code', `Sibling_${randomString(10)}`);
      methods.setValue('typeDefinition.discountType', DiscountType.Percentage);
      methods.setValue('descriptionForCustomer', defaultSiblingDiscountDescriptionForCustomer);
      methods.setValue('description', 'sibling discount');
    }
    if (discountClass === DiscountClass.Coupon) {
      methods.setValue('code', `Coupon_${randomString(10)}`);
      methods.setValue('descriptionForCustomer', 'coupon discount');
      methods.setValue('description', 'coupon discount');
    }
    if (discountClass === DiscountClass.FullDay) {
      methods.setValue('activityTypes', [ActivityTypes.Camp]);
    }
  };

  const disableNonCampSessionTypes = (activityType: ActivityTypes) => {
    return activityType !== ActivityTypes.Camp;
  };

  const isUnselectableSessionType =
    methods.getValues('classDefinition.discountClass') === DiscountClass.FullDay ? disableNonCampSessionTypes : null;

  const handleApplyToAllSessionsInShoppingCart = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      methods.setValue('applyToAllSessionsInShoppingCart', true, { shouldDirty: true });
    } else {
      methods.setValue('applyToAllSessionsInShoppingCart', false, { shouldDirty: true });
    }
  };

  const allowedDiscountTypes =
    methods.watch('classDefinition.discountClass') === DiscountClass.Sibling
      ? [percentageDiscountTypeOption]
      : DiscountTypeOptions;

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <DiscountClassSelector
            name="classDefinition.discountClass"
            onChange={(discountClass) => handleDiscountClassChanged(discountClass)}
          />
          <Grid item md={6} sm={12}>
            {methods.watch('classDefinition.discountClass') === DiscountClass.Consecutive && (
              <FormTextInput
                label="Consecutive Sessions Number"
                name="classDefinition.consecutiveSessionsNumber"
                type="number"
              />
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormTextInput label="Description in the invoice line" name="description" required />
        </Grid>

        {methods.watch('classDefinition.discountClass') !== DiscountClass.Coupon && (
          <Grid item xs={12}>
            <TextFieldWithCounter
              label="Description on a Landing page badge"
              name="descriptionForCustomer"
              maxLength={100}
            />
          </Grid>
        )}

        <Grid item sm={6} xs={12}>
          <DiscountTypeSelector name="typeDefinition.discountType" allowedTypes={allowedDiscountTypes} />
        </Grid>
        <Grid item sm={6} xs={12}>
          {methods.watch('typeDefinition.discountType') === DiscountType.Amount && (
            <FormCurrencyInput
              label="Amount *"
              name="typeDefinition.discountAmount"
              value={methods.getValues('typeDefinition.discountAmount') / 100}
              currency={methods.getValues('currencyCode') as CurrencyCode}
            />
          )}

          {methods.watch('typeDefinition.discountType') === DiscountType.Percentage && (
            <FormTextInput type="number" label="Percentage *" name="typeDefinition.discountPercentage" />
          )}
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormDatePicker name="expiresOn" id="fromDateFilter" label="Expires On" data-testid="expiresOn" />
        </Grid>
        {methods.watch('classDefinition.discountClass') === DiscountClass.Coupon && (
          <Grid item sm={6} xs={12} style={{ display: 'flex' }}>
            <FormTextInput label="Code" name="code" data-testid="discountCodeTextField" />

            <Button
              onClick={() => copy(methods.watch('code'))}
              color="primary"
              style={{ marginLeft: 5, marginRight: 5 }}
            >
              <ContentCopyIcon />
            </Button>
          </Grid>
        )}
      </Grid>

      {methods.watch('classDefinition.discountClass') === DiscountClass.Coupon && (
        <Grid item sm={12} style={{ textAlign: 'center' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={methods.watch('applyToAllSessionsInShoppingCart')}
                onChange={(event) => handleApplyToAllSessionsInShoppingCart(event)}
                name="applyToAllSessionsInShoppingCart"
                color="primary"
              />
            }
            label="Apply to all sessions in the shopping cart"
          />
        </Grid>
      )}

      <hr />

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          marginTop: 15,
          marginBottom: 20
        }}
      >
        <ActivityTypeChecker
          message={'Apply Discount to Sessions of the following types:'}
          checkItemDisabled={isUnselectableSessionType}
        />
      </div>
    </>
  );
};

export default DiscountFormFields;
