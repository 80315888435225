import { useCallback, useEffect, useState } from 'react';
import { apiService } from '../apiServices/apiService';
import { useSnackbar } from 'notistack';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

export interface UseFetchParams {
  initialDataState?: any;
  initialLoadingState?: boolean;
  errorMessage?: string;
  shouldFetch?: boolean;
}

export const useFetch = <D>(url: string, fetchParams: UseFetchParams = {}) => {
  const { initialDataState, errorMessage, shouldFetch = true } = fetchParams;
  const [data, setData] = useState<D | null>(initialDataState || null);
  const [isLoading, setLoading] = useState(fetchParams.initialLoadingState || false);
  const [error, setError] = useState(null);
  const snackbarProps = useSnackbar();

  const fetchData = useCallback(
    async (url: string, queryParams?: Record<string, any>) => {
      setLoading(true);
      try {
        const { data } = await apiService.get<D>(url, queryParams);
        setData(data);
      } catch (error) {
        if (errorMessage || error.response?.data?.message) {
          showNotification(
            snackbarProps,
            false,
            error.response?.data?.message ||
              errorMessage ||
              'Cannot get data from ' + url + 'url with queryParams ' + queryParams
          );
        }
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [errorMessage, snackbarProps]
  );

  useEffect(() => {
    if (shouldFetch) {
      fetchData(url);
    }
  }, [url, shouldFetch, fetchData]);

  // https://fettblog.eu/typescript-react-typeing-custom-hooks/#option-2%3A-as-const
  return [
    { data, isLoading, error, apiService },
    { setData, fetchData }
  ] as const;
};
