import { useNavigate, useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { useEnrollmentsLoader } from './useEnrollmentsLoader';
import React, { useEffect, useState } from 'react';
import { PATHS, toPath } from '@efacity/routing';
import { mediumCircularProgressButtonStyle, WithLoading, CircularProgressButton } from '@efacity/frontend-shared';
import { EnrollmentView, RequestChangesMsg } from '@efacity/frontend-next/enrollment';
import { Box, Typography } from '@mui/material';
import { PageTitle } from '@efacity/react-next-sc';
import { allowedStatusesToApprove, allowedStatusesToRequestChanges } from './allowedStatuses';
import { RequestChangesModal } from './RequestChangesModal';

const btnStyle = { ...mediumCircularProgressButtonStyle, width: 200 };

const EnrollmentViewContainerForAdmin = () => {
  const { orgId, enrollmentId } = useParams<OrgIdParamType & { enrollmentId: string }>();
  const navigate = useNavigate();
  const {
    enrollmentsState: { enrollmentApplication, isLoading, isUpdating, timeZone },
    getEnrollmentApplication,
    approveEnrollmentApplication,
    requestChangesToEnrollmentApplication
  } = useEnrollmentsLoader(orgId, false);
  useEffect(
    () => {
      getEnrollmentApplication(enrollmentId);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [enrollmentId]
  );

  const handleApproveEnrollmentApplication = async (enrollmentId: string) => {
    await approveEnrollmentApplication(enrollmentId);
    navigate(toPath(PATHS.studentEnrollments, { orgId: orgId }));
  };

  const defaultRequestChangesModalState = {
    isOpen: false,
    enrollmentId: null
  };
  const [requestChangesModalState, setRequestChangesModalState] = useState(defaultRequestChangesModalState);

  const openRequestChangesModal = async (enrollmentId: string) => {
    setRequestChangesModalState({
      isOpen: true,
      enrollmentId: enrollmentId
    });
  };

  const handleRequestChangesToEnrollmentApplication = async (enrollmentId: string, comment: string) => {
    await requestChangesToEnrollmentApplication(enrollmentId, comment);
    navigate(toPath(PATHS.studentEnrollments, { orgId: orgId }));
  };

  const showApproveBtn = allowedStatusesToApprove.includes(enrollmentApplication?.applicationStatus);
  const showRequestChangesBtn = allowedStatusesToRequestChanges.includes(enrollmentApplication?.applicationStatus);

  const requestChangesMsg = <RequestChangesMsg enrollmentApplication={enrollmentApplication} />;

  return (
    <WithLoading isLoading={isLoading} message={'Loading enrollment application...'} style={{ width: '50%' }}>
      {enrollmentApplication ? (
        <>
          <PageTitle title={'Enrollment Application'} />
          {requestChangesMsg}
          <EnrollmentView
            enrollmentForm={enrollmentApplication.enrollmentForm}
            timeZone={timeZone}
            submittedOn={enrollmentApplication.submittedOn}
          />
          {requestChangesMsg}
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              flexWrap: 'wrap',
              justifyContent: 'center',
              marginTop: '20px'
            }}
          >
            {showApproveBtn && (
              <CircularProgressButton
                variant="contained"
                color="primary"
                progress={isUpdating}
                style={btnStyle}
                onClick={() => handleApproveEnrollmentApplication(enrollmentApplication._id)}
              >
                Approve
              </CircularProgressButton>
            )}
            {showRequestChangesBtn && (
              <CircularProgressButton
                variant="outlined"
                color="primary"
                onClick={() => openRequestChangesModal(enrollmentApplication._id)}
                style={btnStyle}
                progress={isUpdating}
              >
                Request changes
              </CircularProgressButton>
            )}
          </Box>
          <RequestChangesModal
            isOpen={requestChangesModalState.isOpen}
            enrollmentId={requestChangesModalState.enrollmentId}
            onSubmit={handleRequestChangesToEnrollmentApplication}
            onClose={() => setRequestChangesModalState({ ...defaultRequestChangesModalState })}
          />
        </>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography color="primary" style={{ fontSize: 18, textAlign: 'center', marginTop: 15, marginBottom: 15 }}>
            No Enrollment Application.
          </Typography>
        </Box>
      )}
    </WithLoading>
  );
};

export default EnrollmentViewContainerForAdmin;
