import { useMemo } from 'react';
import { ActivityFormValues, hasJsonStructure, getMergedFormValues } from '@efacity/frontend-shared';
import {
  Activity,
  ActivityTypes,
  CurrencyCode,
  emptyRichEdit,
  FormMode,
  parseRichText,
  stringToRichEdit
} from '@efacity/common';
import { blankActivityFormValues } from './activityFormValues';

export const useActivityInitialFormValues = (
  activity: Activity | undefined,
  formMode: FormMode,
  currency: CurrencyCode,
  isCloning: boolean,
  priceAllowed: boolean
) => {
  const activityFormValues = useMemo<ActivityFormValues>(() => {
    const blankActivityFormValuesWithCurrency = { ...blankActivityFormValues, defaultSessionCurrency: currency };
    if (!activity) {
      return blankActivityFormValuesWithCurrency;
    }

    if (!isCloning && formMode === FormMode.Add) {
      return priceAllowed
        ? blankActivityFormValuesWithCurrency
        : { ...blankActivityFormValuesWithCurrency, defaultSessionPrice: 0 };
    }

    if (formMode === FormMode.Edit || isCloning) {
      const mergedValues = getMergedFormValues(blankActivityFormValuesWithCurrency, activity, ['resources']);
      if (activity.type === ActivityTypes.Enrichment) {
        mergedValues.descriptionOnSessionSelection = mergedValues?.descriptionOnSessionSelection
          ? parseRichText(mergedValues.descriptionOnSessionSelection)
          : emptyRichEdit;
      } else {
        delete mergedValues.descriptionOnSessionSelection;
      }

      if (hasJsonStructure(mergedValues.longDescription)) {
        mergedValues.longDescription = parseRichText(mergedValues.longDescription);
      } else {
        mergedValues.longDescription = stringToRichEdit(mergedValues.longDescription);
      }

      return priceAllowed ? mergedValues : { ...mergedValues, defaultSessionPrice: 0 };
    }
    return blankActivityFormValuesWithCurrency;
  }, [activity, formMode, isCloning, currency, priceAllowed]);

  return [activityFormValues];
};
