import type { AdminAccess } from '@efacity/common';
import SaveIcon from '@mui/icons-material/Check';
import { Autocomplete, Button, DialogContent, DialogTitle, Divider, Grid, TextField } from '@mui/material';
import type { Theme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import type { FC, SyntheticEvent } from 'react';
import React, { useCallback, useEffect } from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { CancelMediumButton } from '../CancelMediumButton';
import { WORKING_ACCESS_ID_ITEM_NAME } from '../../constants/constants';

const submitButtonStyle = {
  width: 171,
  height: 36,
  textTransform: 'uppercase'
} as React.CSSProperties;

export interface SelectAdminAccessModalProps {
  isOpen: boolean;
  schoolCenterAccesses: AdminAccess[];
  workingAccessId: string | null;
  onSelect: (selectedAccessId: AdminAccess['orgId']) => void;
  onClose: () => void;
}

export const SelectAdminAccessModal: FC<SelectAdminAccessModalProps> = ({
  isOpen,
  schoolCenterAccesses,
  workingAccessId,
  onSelect,
  onClose
}) => {
  const { setItem } = useLocalStorage();

  const getAutocompleteValue = useCallback(
    (workingAccessId: string | null) => {
      return schoolCenterAccesses.find((access) => access.orgId === workingAccessId) || schoolCenterAccesses[0];
    },
    [schoolCenterAccesses]
  );

  const [selectedAccess, setAccess] = React.useState<AdminAccess>(() => getAutocompleteValue(workingAccessId));
  useEffect(() => {
    setAccess(getAutocompleteValue(workingAccessId));
  }, [getAutocompleteValue, workingAccessId]);

  const onSelectAccess = (_: SyntheticEvent, access: AdminAccess) => {
    setItem(WORKING_ACCESS_ID_ITEM_NAME, access.orgId);
    setAccess(access);
  };

  const onSubmit = () => {
    onSelect(selectedAccess.orgId);
  };

  return (
    <Dialog
      onClose={(event, reason) => {
        // disableBackdropClick has been deprecated
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      disableEscapeKeyDown
      maxWidth="xs"
    >
      <DialogTitle>
        Select Organization:
        <Divider sx={{ background: (theme: Theme) => theme.palette.grey[500] }} />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} style={{ justifyContent: 'center' }}>
          <Grid item xs={12} sx={{ marginBottom: 4, marginTop: 2 }}>
            <Autocomplete
              sx={{ marginTop: 1 }}
              id="select-organization"
              disableClearable
              value={selectedAccess}
              onChange={onSelectAccess}
              options={schoolCenterAccesses}
              getOptionLabel={(org: AdminAccess) => {
                return org.orgName;
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Organization" variant="outlined" size="small" />
              )}
            />
          </Grid>

          <Grid item>
            <CancelMediumButton onCancel={onClose} />
          </Grid>
          <Grid item>
            <Button
              style={submitButtonStyle}
              color="primary"
              variant="contained"
              data-testid="submitBtn"
              onClick={onSubmit}
            >
              <SaveIcon style={{ marginRight: 20 }} />
              Switch
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
