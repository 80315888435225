import { useCallback, useEffect, useState } from 'react';
import { apiService, getMergedFormValues } from '@efacity/frontend-shared';
import { useSnackbar } from 'notistack';
import { isEmptyObject, Messages, OrganizationEmail } from '@efacity/common';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { organizationEmailSettingsInitialFormValues } from './organizationEmailSettingsFormValues';
import { cloneDeep } from 'lodash';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

export const useOrganizationEmailSettingsLoader = (orgId: string) => {
  const snackbarProps = useSnackbar();

  const [organizationEmailSettingsState, setOrganizationEmailSettingsState] = useState<{
    organizationEmailSettings: OrganizationEmail;
    isLoading: boolean;
  }>({
    organizationEmailSettings: organizationEmailSettingsInitialFormValues,
    isLoading: true
  });

  const getOrgEmailSettings = useCallback(async () => {
    setOrganizationEmailSettingsState({
      organizationEmailSettings: null,
      isLoading: true
    });
    try {
      const { data } = await apiService.get<{ data: OrganizationEmail }>(`/org/${orgId}/email-settings`);
      setOrganizationEmailSettingsState({
        organizationEmailSettings: {
          ...getMergedFormValues(organizationEmailSettingsInitialFormValues, cloneDeep(data))
        },
        isLoading: false
      });
    } catch (error) {
      showNotification(snackbarProps, false, error.message || 'Failed to get organization info.', true);
      setOrganizationEmailSettingsState({
        organizationEmailSettings: null,
        isLoading: true
      });
    }
  }, [orgId, snackbarProps]);

  useEffect(() => {
    getOrgEmailSettings();
  }, [orgId, getOrgEmailSettings]);

  const updateOrgEmailSettings = async (
    formValues: OrganizationEmail,
    setError: (name, error, options?) => void
  ): Promise<boolean> => {
    try {
      const { data } = await apiService.patch<{ message: string }>(`/org/${orgId}/email-settings`, formValues);
      showNotification(snackbarProps, true, data.message);
      return true;
    } catch (error) {
      const validationErrors = error.response?.data?.validationErrors;
      if (typeof validationErrors === 'object' && !isEmptyObject(validationErrors)) {
        addServerErrors<OrganizationEmail>(validationErrors, setError);
      } else {
        showNotification(
          snackbarProps,
          false,
          error.response?.data?.message || Messages.FailedToUpdateOrganizationEmailSettings,
          true
        );
      }
      return false;
    }
  };

  return [
    {
      organizationEmailSettingsState,
      setOrganizationEmailSettingsState
    },
    {
      updateOrgEmailSettings
    }
  ];
};
