import React from 'react';
import { Box, Grid } from '@mui/material';
import { FormTextInput, TaxTypeSelector } from '@efacity/react-hook-form-mui';
import { getNestedFormProperty } from '@efacity/frontend-shared';
import { TaxTypes } from '@efacity/common';
import { useFormContext } from 'react-hook-form';
import { FormGroupTitle } from '@efacity/frontend-next-shared/forms/server';

export interface TaxesFormProps {
  pathToFormObject?: string;
}

export const TaxesForm: React.FC<TaxesFormProps> = ({ pathToFormObject }) => {
  const methods = useFormContext();

  const taxTypeValue = methods.watch(getNestedFormProperty('taxType', pathToFormObject));

  return (
    <Box marginY={4}>
      <FormGroupTitle>Taxes</FormGroupTitle>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TaxTypeSelector name={getNestedFormProperty('taxType', pathToFormObject)} />
        </Grid>
        {taxTypeValue && taxTypeValue !== TaxTypes.None && (
          <Grid item xs={6}>
            <FormTextInput
              label="Tax Percentage"
              name={getNestedFormProperty('taxPercentage', pathToFormObject)}
              placeholder="Tax Percentage"
              type="number"
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
