import {
  getTimeDiapasonWithStartDateAndZone,
  getTimeDiapasonWithZone,
  Registration,
  isIntlSupported
} from '@efacity/common';
import React, { FC } from 'react';
import { adminSectionTableFontSize } from '../../styles/adminSectionTableStyles';

export interface SessionTimeCellProps {
  sessionSchedule: Registration['session']['schedule'];
  timeZone?: string | null;
  displayDate?: boolean;
  displayTimeZone?: boolean;
}

export const SessionTimeCell: FC<SessionTimeCellProps> = ({
  sessionSchedule,
  timeZone,
  displayDate,
  displayTimeZone = true
}) => {
  if (!sessionSchedule?.instances || sessionSchedule?.instances?.length === 0) {
    return <div style={{ ...adminSectionTableFontSize }}>-</div>;
  }

  let workingTimeZone = timeZone;
  if (!timeZone) {
    if (isIntlSupported) {
      workingTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  }

  const timePeriod = (displayDate ? getTimeDiapasonWithStartDateAndZone : getTimeDiapasonWithZone)(
    sessionSchedule.startDateTime,
    sessionSchedule.durationInMinutes,
    workingTimeZone,
    displayTimeZone
  );

  return <div style={{ ...adminSectionTableFontSize }}>{timePeriod}</div>;
};
