import type { CountryCode } from '@efacity/common';
import { getCountryNameByCode, supportedCountryCodes } from '@efacity/common';
import type { SelectChangeEvent } from '@mui/material';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import type { FC, ReactElement } from 'react';
import type { EmbeddedFlagProps } from 'react-phone-number-input';
import Flags from 'react-phone-number-input/flags';

interface CountrySelectProps {
  value: CountryCode;
  onCountryChange: (country: CountryCode) => void;
  disabled?: boolean;
}

export const getCountryFlag = (countryCode: CountryCode) => {
  if (Flags[countryCode]) {
    return Flags[countryCode] as (
      props: Partial<EmbeddedFlagProps> & { style?: React.CSSProperties; 'aria-label'?: string }
    ) => ReactElement;
  }
  return null;
};

export const CountrySelect: FC<CountrySelectProps> = ({ value, onCountryChange, disabled = false }) => {
  const onChange = (e: SelectChangeEvent) => {
    onCountryChange(e.target.value as CountryCode);
  };

  return (
    <>
      <InputLabel id="country-select" sx={visuallyHidden}>
        Country
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        disableUnderline
        disabled={disabled}
        renderValue={(country: CountryCode) => {
          const CountryFlag = getCountryFlag(country);
          return CountryFlag ? (
            <CountryFlag style={{ width: 24, height: 24, marginTop: 7 }} aria-label={getCountryNameByCode(country)} />
          ) : null;
        }}
        variant="standard"
        size="small"
        labelId="country-select"
      >
        {supportedCountryCodes.map((country) => {
          const CountryFlag = getCountryFlag(country);
          const countryName = getCountryNameByCode(country);

          return CountryFlag ? (
            <MenuItem value={country} key={country} style={{ display: 'flex' }}>
              <CountryFlag style={{ width: 16, height: 16 }} aria-label={countryName} />{' '}
              <span style={{ marginLeft: 10 }}>{countryName}</span>
            </MenuItem>
          ) : null;
        })}
      </Select>
    </>
  );
};
