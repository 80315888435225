import {
  calculateAmountWithTax,
  CurrencyCode,
  getAmountForShoppingCart,
  getTaxAmount,
  getTaxDescription,
  isShoppingCartAllowedToPay,
  isShoppingCartEmpty,
  Messages,
  PAYMENT_LIMIT_IN_CENTS,
  PaymentAcceptanceMethod,
  PaymentCard,
  PaymentDetails,
  StudentOnSessionRegistrationInfo,
  TaxTypes,
  displayMdUpSx,
  displaySmDownSx
} from '@efacity/common';
import { clearPurchaseStateAndGetAfterPaymentRedirect } from '@efacity/frontend-shared';
import { GoogleAnalytics } from '@efacity/google-analytics';
import { PATHS, toPath } from '@efacity/routing';
import { Box, Button, Grid } from '@mui/material';
import { CreatePaymentMethodData } from '@stripe/stripe-js';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  PaymentDoneModal,
  CartPaymentFormValues,
  useStripeWrapper,
  WithLoading,
  UnsignedCustomerAgreementsModal
} from '@efacity/frontend-shared';
import PaymentForm, { FormActions } from './PaymentForm/PaymentForm';
import ShoppingCartAsTable from './ShoppingCartTable/ShoppingCartAsTable';
import ShoppingCartMobileTable from './ShoppingCartTable/ShoppingCartMobileTable';
import useUnsignedAgreements from '../../hooks/useUnsignedAgreements';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { useShoppingCartPayment } from './useShoppingCartPayment';
import { PageTitle, useAuth } from '@efacity/react-next-sc';

const ShoppingCartContainer = () => {
  const navigate = useNavigate();
  const {
    authState: {
      extraAuthInfo: { shoppingCart, startRegistrationURL, message }
    },
    checkAuthenticationStatus
  } = useAuth();
  const startProductsURL = shoppingCart?.orgId
    ? startRegistrationURL.indexOf('certification') > -1
      ? null
      : `${PATHS.productsLanding}?owner=${shoppingCart.orgId}`
    : null;

  const [{ shoppingCartPaymentsState }, { paymentActions, registrationActions }] = useShoppingCartPayment();
  const { processShoppingCartPayment, setShoppingPaymentsState, getShoppingCart, verifyCouponDiscountForShoppingCart } =
    paymentActions;
  const {
    toggleAdditionalOptionSelection,
    removeSessionSelectionFromShoppingCart,
    removeStudentWithSessionSelectionFromShoppingCart,
    removeProductFromShoppingCart
  } = registrationActions;

  const {
    credit,
    stripePublishableKey,
    isLoading,
    isUpdating,
    paymentCards,
    paymentDoneModal,
    paymentAcceptanceMethod
  } = shoppingCartPaymentsState;

  const [
    { userAgreementsToSign, signedAgreements, isLoadingAgreements, allAgreementsViewed },
    { onCloseAgreementsModal, onSignAgreement, onDismissAgreement }
  ] = useUnsignedAgreements('/agreements/unsigned');

  const shoppingCartRegistrations =
    shoppingCart?.shoppingCartRegistrations?.length > 0 ? shoppingCart.shoppingCartRegistrations : [];
  const shoppingCartProducts = shoppingCart?.shoppingCartProducts?.length > 0 ? shoppingCart.shoppingCartProducts : [];
  const shoppingCartAmount = getAmountForShoppingCart(shoppingCart);
  const shoppingCartTaxAmount = getTaxAmount(
    shoppingCartAmount,
    shoppingCart?.taxType || TaxTypes.None,
    shoppingCart?.taxPercentage || 0
  );
  const shoppingCartAmountWithTax = calculateAmountWithTax(shoppingCartAmount, shoppingCartTaxAmount);
  const due = shoppingCartAmountWithTax - credit > PAYMENT_LIMIT_IN_CENTS ? shoppingCartAmountWithTax - credit : 0;
  const taxDescription = getTaxDescription(shoppingCart?.taxType || TaxTypes.None, shoppingCart?.taxPercentage || 0);
  const currencyCode = (shoppingCart?.currency as CurrencyCode) || CurrencyCode.USD;

  const [{ stripe, wrappedForm }] = useStripeWrapper(
    stripePublishableKey,
    isLoading,
    <PaymentForm
      handleSubmit={handleSubmit}
      isStripeLoading={false}
      paymentAllowed={!isLoadingAgreements && allAgreementsViewed}
      initialCountry={shoppingCart?.defaultCountry}
      showPaymentElements={
        due > PAYMENT_LIMIT_IN_CENTS &&
        !(
          paymentAcceptanceMethod === PaymentAcceptanceMethod.Alternative ||
          paymentAcceptanceMethod === PaymentAcceptanceMethod.None
        )
      }
      paymentCards={paymentCards}
    />
  );

  useEffect(() => {
    getShoppingCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GoogleAnalytics.shoppingCartEvent({
      eventName: 'begin_checkout',
      shoppingCartRegistrations: shoppingCartRegistrations,
      shoppingCartTotal: shoppingCartAmount,
      tax: shoppingCartTaxAmount
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModalAndGotoRegistrationsOrProducts = () => {
    setShoppingPaymentsState((state) => ({ ...state, paymentDoneModal: { ...state.paymentDoneModal, isOpen: false } }));
    checkAuthenticationStatus();
    navigate(clearPurchaseStateAndGetAfterPaymentRedirect());
  };

  const handleDiscountCodeChanged = (event) => {
    verifyCouponDiscountForShoppingCart(event.target.value);
  };

  const handleDeleteAdditionalOption = (studentId: string, sessionId: string, additionalOptionId: string) => {
    toggleAdditionalOptionSelection(studentId, sessionId, additionalOptionId, false);
  };

  const closeModalAndReloadShoppingCart = () => {
    setShoppingPaymentsState((state) => ({ ...state, paymentDoneModal: { ...state.paymentDoneModal, isOpen: false } }));
    getShoppingCart();
  };

  const handleDeleteShoppingCartItem = (studentId: string, sessionId: string) => {
    const selection: StudentOnSessionRegistrationInfo = {
      studentId: studentId,
      sessionId: sessionId,
      isRegistered: false,
      isSelectedToRegistration: false
    };
    if (studentId) {
      removeStudentWithSessionSelectionFromShoppingCart(selection);
    } else {
      removeSessionSelectionFromShoppingCart(selection);
    }
  };

  const clickGotoLanding = (startRegistrationURL) => {
    window.location.href = startRegistrationURL || PATHS.landing;
  };

  async function handleSubmit(
    fullPaymentInfo: CreatePaymentMethodData,
    userPaymentMethodId: string,
    usedPaymentCard: PaymentCard | null,
    formActions: FormActions
  ) {
    const callBackendProcessShoppingCartPayment = async (paymentInfo: PaymentDetails) => {
      const { linkToPaymentReceiptPDF, startRegistrationURL, redirectUrl, invoiceId, message, isError, ...other } =
        await processShoppingCartPayment({ ...paymentInfo, signedAgreements });

      if (isError) {
        if (other?.validationErrors) {
          formActions.setFormLoading(false);
          return addServerErrors<CartPaymentFormValues>(
            { address: other.validationErrors.address },
            formActions.setRHFFormErrors
          );
        }
        setShoppingPaymentsState((state) => ({
          ...state,
          paymentDoneModal: {
            isOpen: true,
            isError: true,
            message: message || Messages.FailedProcessPayment,
            onClose: closeModalAndReloadShoppingCart
          }
        }));
        return;
      }

      GoogleAnalytics.shoppingCartEvent({
        eventName: 'purchase',
        shoppingCartRegistrations: shoppingCartRegistrations,
        shoppingCartTotal: shoppingCartAmount,
        tax: shoppingCartTaxAmount
      });

      if (redirectUrl?.length > 0) {
        return navigate(`${toPath(PATHS.payment, { invoiceId: invoiceId })}?${redirectUrl}`);
      }

      setShoppingPaymentsState((state) => ({
        ...state,
        paymentDoneModal: {
          isOpen: true,
          isError: false,
          message: message || Messages.PaymentSuccess,
          onClose: closeModalAndGotoRegistrationsOrProducts
        }
      }));

      if (linkToPaymentReceiptPDF) {
        window.open(linkToPaymentReceiptPDF);
      }
    };

    if (due <= PAYMENT_LIMIT_IN_CENTS || paymentAcceptanceMethod === PaymentAcceptanceMethod.Alternative) {
      const zeroPaymentInfo: PaymentDetails = {
        paymentMethodId: '',
        last4: '',
        brand: '',
        address: {},
        isExistingCard: true
      };
      await callBackendProcessShoppingCartPayment(zeroPaymentInfo);
      formActions.setFormLoading(false);
    } else if (userPaymentMethodId && userPaymentMethodId.length > 0) {
      const paymentInfo: PaymentDetails = {
        paymentMethodId: userPaymentMethodId,
        last4: usedPaymentCard?.last4,
        brand: usedPaymentCard?.brand,
        address: fullPaymentInfo.billing_details.address,
        isExistingCard: true
      };
      await callBackendProcessShoppingCartPayment(paymentInfo);
    } else {
      const result = await stripe?.createPaymentMethod(fullPaymentInfo);
      if (result?.error) {
        formActions.setFormError(result?.error.message || 'Unable to process payment');
        formActions.setFormLoading(false);
      }
      if (result?.paymentMethod?.id) {
        const paymentInfo: PaymentDetails = {
          paymentMethodId: result.paymentMethod.id,
          last4: result.paymentMethod.card?.last4,
          brand: result.paymentMethod.card?.brand,
          address: fullPaymentInfo.billing_details.address,
          isExistingCard: false
        };
        await callBackendProcessShoppingCartPayment(paymentInfo);
      }
    }
  }

  return (
    <div>
      <PageTitle title="Shopping Cart Payment" />
      <WithLoading isLoading={isLoading} message={'Loading ShoppingCart...'} style={{ width: '25%' }}>
        <Box marginBottom={2} display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to={'#'}
            onClick={() => clickGotoLanding(startRegistrationURL)}
            style={{ width: '175' }}
          >
            Add Registration
          </Button>
          {startProductsURL && (
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={'#'}
              onClick={() => clickGotoLanding(startProductsURL)}
              style={{ width: '175', marginLeft: 20 }}
            >
              Add Kit
            </Button>
          )}
        </Box>
        {!isShoppingCartEmpty(shoppingCart) && (
          <div style={{ marginTop: 15, marginBottom: 15 }}>
            <Box sx={displayMdUpSx}>
              <ShoppingCartAsTable
                currencyCode={currencyCode}
                shoppingCartRegistrations={shoppingCartRegistrations}
                shoppingCartProducts={shoppingCartProducts}
                timeZone={shoppingCart?.timeZone}
                loading={false}
                handleDeleteShoppingCartItem={handleDeleteShoppingCartItem}
                handleDiscountCodeChanged={handleDiscountCodeChanged}
                handleDeleteAdditionalOption={handleDeleteAdditionalOption}
                handleDeleteProduct={removeProductFromShoppingCart}
                shoppingCartAmount={shoppingCartAmount}
                shoppingCartTaxAmount={shoppingCartTaxAmount}
                shoppingCartAmountWithTax={shoppingCartAmountWithTax}
                credit={credit}
                due={due}
                taxDescription={taxDescription}
                isUpdating={isUpdating}
              />
            </Box>
            <Box sx={displaySmDownSx}>
              <ShoppingCartMobileTable
                currencyCode={currencyCode}
                shoppingCartRegistrations={shoppingCartRegistrations}
                shoppingCartProducts={shoppingCartProducts}
                timeZone={shoppingCart?.timeZone}
                loading={false}
                handleDeleteShoppingCartItem={handleDeleteShoppingCartItem}
                handleDiscountCodeChanged={handleDiscountCodeChanged}
                handleDeleteAdditionalOption={handleDeleteAdditionalOption}
                handleDeleteProduct={removeProductFromShoppingCart}
                shoppingCartAmount={shoppingCartAmount}
                shoppingCartTaxAmount={shoppingCartTaxAmount}
                shoppingCartAmountWithTax={shoppingCartAmountWithTax}
                credit={credit}
                due={due}
                taxDescription={taxDescription}
                isUpdating={isUpdating}
              />
            </Box>
          </div>
        )}
      </WithLoading>

      {message ? (
        <div style={{ fontSize: 20, color: '#f44336', textAlign: 'center', margin: 30 }}>{message}</div>
      ) : isShoppingCartEmpty(shoppingCart) ? (
        <div style={{ fontSize: 20, color: '#f44336', textAlign: 'center', margin: 30 }}>Shopping Cart is empty.</div>
      ) : isShoppingCartAllowedToPay(shoppingCart) ? (
        <Grid container>
          <Grid item sm={2} md={3} lg={3} />
          <Grid item xs={12} sm={8} md={6} lg={6}>
            {wrappedForm}
          </Grid>
        </Grid>
      ) : (
        <div style={{ fontSize: 20, color: '#f44336', textAlign: 'center', margin: 30 }}>
          Select student to complete the registration.
        </div>
      )}
      <PaymentDoneModal paymentDoneModalState={paymentDoneModal} />
      {!allAgreementsViewed && (
        <UnsignedCustomerAgreementsModal
          onClose={onCloseAgreementsModal}
          onSignAgreement={onSignAgreement}
          onDismissAgreement={onDismissAgreement}
          open={!allAgreementsViewed}
          unsignedAgreements={userAgreementsToSign}
        />
      )}
    </div>
  );
};

export default ShoppingCartContainer;
