'use client';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Link } from '@mui/material';
import { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

interface PhoneNumberProps {
  phone?: string;
  mobileView?: boolean;
  defaultIfUndefined?: string;
}
export const PhoneNumber = ({ phone, mobileView = false, defaultIfUndefined = '-' }: PhoneNumberProps) => {
  if (!phone) return <div>{defaultIfUndefined}</div>;

  let displayPhoneNumber;
  if (mobileView) {
    displayPhoneNumber = <LocalPhoneIcon data-testid="phone-icon" />;
  } else {
    const parsedNumber = parsePhoneNumber(phone);
    displayPhoneNumber = parsedNumber ? formatPhoneNumber(parsedNumber.number) : phone;
  }

  return (
    <Link href={`tel:${phone}`} data-testid="phone-number-link" underline={'none'}>
      {displayPhoneNumber}
    </Link>
  );
};
