import { getGradeDisplayTextByNumericValue, Grades, gradesArray } from './Grades';
import { nowUTC } from './DateTime';

export interface StudentLevel {
  type: string;
  fromSchoolGradeNumeric: number;
  toSchoolGradeNumeric: number;
  fromAge: number;
  toAge: number;
}

export interface StudentLevelType {
  name: string;
  value: string;
}
export const AgeStudentLevelTypeValue = 'ageStudentLevelType';
export const AgeStudentLevelType: StudentLevelType = {
  name: 'Age',
  value: AgeStudentLevelTypeValue
};

export const SchoolGradeStudentLevelTypeValue = 'schoolGradeStudentLevelType';
export const SchoolGradeStudentLevelType: StudentLevelType = {
  name: 'Grade',
  value: SchoolGradeStudentLevelTypeValue
};

export const displayAge = (age: number) => {
  switch (age) {
    case null:
      return 'Any';
    case 4:
      return '4';
    case 5:
      return '5';
    case 6:
      return '6';
    case 7:
      return '7';
    case 8:
      return '8';
    case 9:
      return '9';
    case 10:
      return '10';
    case 11:
      return '11';
    case 12:
      return '12';
    case 13:
      return '13';
    case 14:
      return '14';
    case 15:
      return '15';
    case 16:
      return '16';
    case 17:
      return 'Adult';
    default:
      if (age <= 3) return '0-3';
      return 'Adult';
  }
};
export const agesViewDiapason = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
export const agesArrayWithNumericValues = agesViewDiapason.map((age) => ({
  value: age,
  displayText: displayAge(age)
}));
export const studentLevelTypes = [AgeStudentLevelType, SchoolGradeStudentLevelType];
export const studentLevelTypeValues = [AgeStudentLevelTypeValue, SchoolGradeStudentLevelTypeValue];

export const sanitizeStudentLevel = (studentLevel: Partial<StudentLevel>): StudentLevel => {
  if (studentLevel.type === AgeStudentLevelTypeValue) {
    return fillSchoolGrades(studentLevel);
  } else {
    return fillAges(studentLevel);
  }
};

export const fillSchoolGrades = (studentLevel: Partial<StudentLevel>): StudentLevel => {
  return {
    type: studentLevel.type,
    fromSchoolGradeNumeric: mapAgeToSchoolGrade(studentLevel.fromAge).sortValue,
    toSchoolGradeNumeric: mapAgeToSchoolGrade(studentLevel.toAge).sortValue,
    fromAge: studentLevel.fromAge,
    toAge: studentLevel.toAge
  };
};

export const mapAgeToSchoolGrade = (age: number | undefined): Grades => {
  if (age === undefined) return Grades.GRADE_ADULT;
  switch (age) {
    case 3:
      return Grades.PRE_K;
    case 4:
      return Grades.GRADE_K;
    case 5:
      return Grades.GRADE_1;
    case 6:
      return Grades.GRADE_2;
    case 7:
      return Grades.GRADE_3;
    case 8:
      return Grades.GRADE_4;
    case 9:
      return Grades.GRADE_5;
    case 10:
      return Grades.GRADE_6;
    case 11:
      return Grades.GRADE_7;
    case 12:
      return Grades.GRADE_8;
    case 13:
      return Grades.GRADE_9;
    case 14:
      return Grades.GRADE_10;
    case 15:
      return Grades.GRADE_11;
    case 16:
      return Grades.GRADE_12;
    default:
      if (age < 3) return Grades.PRE_K;
      return Grades.GRADE_ADULT;
  }
};
export const fillAges = (studentLevel: Partial<StudentLevel>): StudentLevel => {
  return {
    type: studentLevel.type,
    fromSchoolGradeNumeric: studentLevel.fromSchoolGradeNumeric,
    toSchoolGradeNumeric: studentLevel.toSchoolGradeNumeric,
    fromAge: mapSchoolGradeToAge(studentLevel.fromSchoolGradeNumeric),
    toAge: mapSchoolGradeToAge(studentLevel.toSchoolGradeNumeric)
  };
};

export const mapSchoolGradeToAge = (schoolGradeSortValue: number): number => {
  const schoolGrade = gradesArray.find((grade: Grades) => grade.sortValue === schoolGradeSortValue);

  switch (schoolGrade) {
    case Grades.PRE_K:
      return 3;
    case Grades.GRADE_K:
      return 4;
    case Grades.GRADE_1:
      return 5;
    case Grades.GRADE_2:
      return 6;
    case Grades.GRADE_3:
      return 7;
    case Grades.GRADE_4:
      return 8;
    case Grades.GRADE_5:
      return 9;
    case Grades.GRADE_6:
      return 10;
    case Grades.GRADE_7:
      return 11;
    case Grades.GRADE_8:
      return 12;
    case Grades.GRADE_9:
      return 13;
    case Grades.GRADE_10:
      return 14;
    case Grades.GRADE_11:
      return 15;
    case Grades.GRADE_12:
      return 16;
    case Grades.GRADE_ADULT:
      return 17;
    default:
      return 18;
  }
};

export const getGradesDiapasonString = (gradeFromValueSort: number, gradeToValueSort: number) => {
  if (gradeFromValueSort === Grades.PRE_K.sortValue && gradeToValueSort === Grades.GRADE_ADULT.sortValue)
    return 'All grades';
  if (gradeFromValueSort === gradeToValueSort) return `Grade: ${getGradeDisplayTextByNumericValue(gradeFromValueSort)}`;
  return `Grade: ${getGradeDisplayTextByNumericValue(gradeFromValueSort)} - ${getGradeDisplayTextByNumericValue(
    gradeToValueSort
  )}`;
};

export const getAgesDiapasonString = (fromAge: number, toAge: number) => {
  if (fromAge <= 3 && toAge >= 17) return 'All ages';
  if (fromAge === toAge) return `Age: ${displayAge(fromAge)}`;
  return `Age: ${displayAge(fromAge)} - ${displayAge(toAge)}`;
};

export const getStudentLevelDiapason = (studentLevel: StudentLevel) => {
  return studentLevel.type === SchoolGradeStudentLevelTypeValue
    ? getGradesDiapasonString(studentLevel.fromSchoolGradeNumeric, studentLevel.toSchoolGradeNumeric)
    : getAgesDiapasonString(studentLevel.fromAge, studentLevel.toAge);
};

export const calculateAge = (birthday: Date) => {
  return Math.abs(birthday.getUTCFullYear() - nowUTC().getUTCFullYear());
};

export const recalculateAgeForNow = (age: number, ageUpdatedOn: Date) => {
  const yearsPassed = nowUTC().getUTCFullYear() - ageUpdatedOn.getUTCFullYear();
  return age + yearsPassed;
};
