import { Button, Skeleton } from '@mui/material';
import React from 'react';
import { ReviewStatus } from '@efacity/common';

const reviewStatusButtonStyle = { width: '100%', fontWeight: 'bold', borderWidth: '2px', height: '36px' };

const statusToButtonAppearanceMap = {
  [ReviewStatus.Pending]: { label: 'reject', color: 'error', newStatus: ReviewStatus.Rejected },
  [ReviewStatus.Rejected]: { label: 'approve', color: 'success', newStatus: ReviewStatus.Approved },
  [ReviewStatus.Approved]: { label: 'reject', color: 'error', newStatus: ReviewStatus.Rejected }
};

const ReviewStatusToggleButton = ({ reviewId, currentStatus, changeReviewStatus, progress }) => {
  const { label, color, newStatus } = statusToButtonAppearanceMap[currentStatus];
  return (
    <Button
      style={reviewStatusButtonStyle}
      variant="outlined"
      color={color}
      onClick={() => changeReviewStatus(reviewId, newStatus)}
    >
      {progress ? <Skeleton variant="rectangular" data-testid="loading-skeleton" width={66} /> : label}
    </Button>
  );
};

export default ReviewStatusToggleButton;
