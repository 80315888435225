import { LocationType } from '@efacity/common';
import { steamworksAddress, teslaAddress } from './mockAddressesFixtures';

export const mendeleevLocationId = '5ea6de9f2f37a2465jh3d4a85';
export const mendeleevLocation = {
  _id: mendeleevLocationId,
  type: LocationType.InPerson,
  name: 'Mendeleev Location',
  address: steamworksAddress,
  orgId: '5ea6de9f2f37a246506d4a85',
  capacity: 10
};

export const faradayLocationId = '5ea6dksj2f37a2465jh3d4a85';
export const faradayLocation = {
  _id: faradayLocationId,
  type: LocationType.InPerson,
  name: 'Faraday Location',
  address: teslaAddress,
  orgId: '5ea6de9f2f37a246506d4a85',
  capacity: 10
};

export const teslaLocationId = '5ea6dksj2fh7b2465jh3d4a85';
export const teslaLocation = {
  _id: teslaLocationId,
  type: LocationType.InPerson,
  name: 'Tesla Location',
  address: teslaAddress,
  orgId: '5ea6de9f2f37a246506d4a85',
  capacity: 20
};

export const virtual1LocationId = '5ea6dksj2f37a2465jhjhca85';
export const virtual1Location = {
  _id: virtual1LocationId,
  type: LocationType.Virtual,
  name: 'Virtual 1 Location',
  virtualMeeting: { link: 'http://zoom.class/1' },
  orgId: '5ea6de9f2f37a246506d4a85'
};

export const locations = [mendeleevLocation, faradayLocation, teslaLocation, virtual1Location];
