import { CopyLinkFlatIcon } from '@efacity/frontend-shared';
import { LightTooltip } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import { IconButton, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import React from 'react';

export interface JobPostingLinkWithCopierProps {
  orgId: string;
  jobPostingId: string;
}

const JobPostingLinkWithCopier: React.FC<JobPostingLinkWithCopierProps> = ({ orgId, jobPostingId }) => {
  const handleCopyJobPostingClick = (orgId: string, jobPostingId: string) => {
    const registrationPageLink = toPath(PATHS.jobPostingDescription, { orgId, jobId: jobPostingId });
    copy(`${window.location.origin}${registrationPageLink}`);
  };

  return (
    <LightTooltip title={<Typography>Copy Job Posting Link to share</Typography>}>
      <span>
        <div style={{ display: 'flex', marginRight: 5 }} data-testid="registration-link-with-copier">
          [
          <IconButton
            color="primary"
            aria-label="copy to clipboard"
            component="span"
            style={{ marginTop: -4, padding: 5 }}
            onClick={() => handleCopyJobPostingClick(orgId, jobPostingId)}
          >
            <CopyLinkFlatIcon fontSize="small" />
          </IconButton>
          ]
        </div>
      </span>
    </LightTooltip>
  );
};

export default JobPostingLinkWithCopier;
