import React, { FC } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeInput } from './StripeInput';
import { TextField } from '@mui/material';

const rootSx = {
  '& label': {
    color: '#666',
    fontSize: 18
  },
  '& p': {
    position: 'absolute !important',
    bottom: '-18px',
    whiteSpace: 'noWrap'
  },
  '& .StripeElement': {
    paddingTop: '14px',
    paddingBottom: '8px',
    fontSize: 16
  }
};

/*
 * For more see: https://codesandbox.io/s/stripe-hwse8?file=/src/commonTextFields.js:1063-1213
 * */
export const StripeCombinedField: FC<any> = props => {
  const { InputLabelProps, InputProps, labelErrorMessage, ...other } = props;

  return (
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true
      }}
      InputProps={{
        ...InputProps,
        inputProps: {
          component: CardElement
        },
        inputComponent: StripeInput
      }}
      helperText={labelErrorMessage}
      {...other}
      sx={rootSx}
    />
  );
};
