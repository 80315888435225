import React, { useMemo } from 'react';
import { getRolesView, rolesSelectOptions, User, formatStringToMonthDayYearDate } from '@efacity/common';
import { FilterProps } from 'react-table';
import Table, { EnhancedColumn, PaginationTypes } from '../../components/Table/Table';
import SelectColumnFilter from '../../components/Table/filters/SelectColumnFilter';
import { NavLink } from 'react-router-dom';
import { PATHS, toPath } from '@efacity/routing';
import { Link } from '@mui/material';
import EmailAddress from '../../components/EmailAddress/EmailAddress';
import { useSiteAdminUsersLoader } from './useSiteAdminUsersLoader';
import { PageTitle, PhoneNumber } from '@efacity/react-next-sc';

const SiteAdminUsers: React.FC = () => {
  const {
    usersState: { users, total, isLoading },
    getUsers
  } = useSiteAdminUsersLoader('/users');
  const columns = useMemo((): EnhancedColumn<User>[] => {
    return [
      {
        Header: 'Name',
        accessor: 'fullName',
        Cell: ({ value, row }) => {
          return (
            <Link
              component={NavLink}
              to={toPath(PATHS.siteAdminEditUser, { userId: row.original._id })}
              underline={'none'}
            >
              {value}
            </Link>
          );
        }
      },
      {
        Header: 'Email',
        accessor: 'email',
        filter: 'text',
        Cell: ({ value }) => {
          return <EmailAddress email={value} />;
        }
      },
      {
        Header: 'Phone',
        accessor: 'defaultPhone',
        filter: 'text',
        Cell: ({ value }) => {
          return <PhoneNumber phone={value} />;
        }
      },
      {
        Header: 'Roles',
        accessor: 'roles',
        disableSortBy: true,
        Filter: (props: FilterProps<User>) => (
          <SelectColumnFilter columnFilterProps={props} options={rolesSelectOptions} />
        ),
        Cell: ({ value: roles }) => (roles.length > 0 ? <>{getRolesView(roles).join(', ')}</> : <>-</>)
      },
      {
        Header: 'Date joined',
        accessor: 'createdAt',
        Cell: ({ value: createdAt }) => <div>{createdAt ? formatStringToMonthDayYearDate(createdAt) : ''}</div>,
        disableFilters: true,
        headerStyles: {
          verticalAlign: 'top'
        }
      }
    ];
  }, []);

  const addUserLink = toPath(PATHS.siteAdminAddUser);

  return (
    <div>
      <PageTitle title="All Users" addButtonLink={addUserLink} />
      <Table
        loading={isLoading}
        columns={columns}
        data={users}
        pageCount={total}
        fetchData={getUsers}
        paginationType={PaginationTypes.ServerSide}
      />
    </div>
  );
};

export default SiteAdminUsers;
