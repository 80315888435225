import React, { FC } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent } from '@mui/material';
import { ModalDialogTitle } from '@efacity/react-next-sc';
import { RegistrationInfoModalState } from '../../PaymentContainer/useInvoicePayment';

export const mobileModalViewSizesStyle = {
  width: '100%',
  maxWidth: 600
};
const rootStyle = {
  margin: 0,
  fontSize: '20px'
};

export interface RegistrationInfoModalProps {
  registrationInfoModal: RegistrationInfoModalState;
}

export const RegistrationInfoModal: FC<RegistrationInfoModalProps> = ({ registrationInfoModal }) => {
  const { isOpen, message, onClose, redirectUrl } = registrationInfoModal;

  const handleClose = () => {
    onClose(redirectUrl);
  };

  return (
    <Dialog
      onClose={(event, reason) => {
        // disableBackdropClick has been deprecated
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      disableEscapeKeyDown
    >
      <div style={mobileModalViewSizesStyle}>
        <ModalDialogTitle text="Registration info" showUnderline={false} />
        <DialogContent dividers>
          <div style={rootStyle}>
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
