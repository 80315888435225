import { nowUTC } from './DateTime';
import { AgeStudentLevelTypeValue, mapAgeToSchoolGrade, SchoolGradeStudentLevelTypeValue } from './StudentLevel';
import { StudentLevelOptional } from './Activities';

export class Grades {
  static readonly PRE_K = new Grades('gradePreK', 'Pre-K', 0);
  static readonly GRADE_K = new Grades('gradeK', 'K', 1);
  static readonly GRADE_1 = new Grades('grade1', '1', 2);
  static readonly GRADE_2 = new Grades('grade2', '2', 3);
  static readonly GRADE_3 = new Grades('grade3', '3', 4);
  static readonly GRADE_4 = new Grades('grade4', '4', 5);
  static readonly GRADE_5 = new Grades('grade5', '5', 6);
  static readonly GRADE_6 = new Grades('grade6', '6', 7);
  static readonly GRADE_7 = new Grades('grade7', '7', 8);
  static readonly GRADE_8 = new Grades('grade8', '8', 9);
  static readonly GRADE_9 = new Grades('grade9', '9', 10);
  static readonly GRADE_10 = new Grades('grade10', '10', 11);
  static readonly GRADE_11 = new Grades('grade11', '11', 12);
  static readonly GRADE_12 = new Grades('grade12', '12', 13);
  static readonly GRADE_ADULT = new Grades('gradeAdult', 'Adult', 14);

  // private to disallow creating other instances of this type
  private constructor(
    public readonly value: string,
    public readonly displayText: string,
    public readonly sortValue: number
  ) {}

  toString() {
    return this.displayText;
  }
}

export const isGradeValue = (mayBeGrade: string): boolean => {
  const foundGrade = gradesArray.find((grade: Grades) => grade.value === mayBeGrade);
  return !!foundGrade;
};

export const isGradeNumericValue = (mayBeGradeSort: number): boolean => {
  const foundGrade = gradesArray.find((grade: Grades) => grade.sortValue === mayBeGradeSort);
  return !!foundGrade;
};

export const getGradeDisplayTextByNumericValue = (gradeValueSort: number) => {
  const foundGrade = gradesArray.find((grade: Grades) => grade.sortValue === gradeValueSort);
  if (foundGrade) {
    return foundGrade.displayText;
  }
  return Grades.PRE_K.displayText;
};

export const getGradeByNumericValue = (gradeNumericValue: number | undefined): Grades => {
  const foundGrade = gradesArray.find((grade: Grades) => grade.sortValue === gradeNumericValue);
  if (foundGrade) {
    return foundGrade;
  }
  return Grades.GRADE_ADULT;
};

export enum GradesEnum {
  preK = 'gradePreK',
  gradeK = 'gradeK',
  grade1 = 'grade1',
  grade2 = 'grade2',
  grade3 = 'grade3',
  grade4 = 'grade4',
  grade5 = 'grade5',
  grade6 = 'grade6',
  grade7 = 'grade7',
  grade8 = 'grade8',
  grade9 = 'grade9',
  grade10 = 'grade10',
  grade11 = 'grade11',
  grade12 = 'grade12',
  gradeAdult = 'gradeAdult'
}
export const gradesArray: Grades[] = [
  Grades.PRE_K,
  Grades.GRADE_K,
  Grades.GRADE_1,
  Grades.GRADE_2,
  Grades.GRADE_3,
  Grades.GRADE_4,
  Grades.GRADE_5,
  Grades.GRADE_6,
  Grades.GRADE_7,
  Grades.GRADE_8,
  Grades.GRADE_9,
  Grades.GRADE_10,
  Grades.GRADE_11,
  Grades.GRADE_12,
  Grades.GRADE_ADULT
];

export const gradesArrayWithoutEmpty = gradesArray.filter((grade) => grade.value !== '');
export const gradesArrayWithNumericValues = gradesArrayWithoutEmpty.map((grade) => ({
  value: grade.sortValue,
  displayText: grade.displayText
}));

export const convertGradeToCurrent = (gradeInSeptemberNumeric: number, gradeYear: number) => {
  if (gradeYear && isGradeNumericValue(gradeInSeptemberNumeric)) {
    const yearsDifference = nowUTC().getUTCFullYear() - gradeYear;
    if (yearsDifference > 0) {
      const currentGrade = gradeInSeptemberNumeric;
      return currentGrade + yearsDifference < gradesArray.length - 1
        ? currentGrade + yearsDifference
        : gradesArray.length - 1;
    }
    return gradeInSeptemberNumeric;
  }
  return null;
};

export const mapStudentLevelsToGrades = (studentLevels: StudentLevelOptional[]): Grades[] => {
  if (studentLevels?.length > 0) {
    const gradesFromSessions: Grades[] = [];
    studentLevels.forEach((studentLevel) => {
      let startGradeIndex = -1;
      let endGradeIndex = -1;
      if (studentLevel.type === AgeStudentLevelTypeValue) {
        startGradeIndex = gradesArray.indexOf(mapAgeToSchoolGrade(studentLevel.fromAge));
        endGradeIndex = gradesArray.indexOf(mapAgeToSchoolGrade(studentLevel.toAge));
      }
      if (studentLevel.type === SchoolGradeStudentLevelTypeValue) {
        startGradeIndex = gradesArray.indexOf(getGradeByNumericValue(studentLevel.fromSchoolGradeNumeric));
        endGradeIndex = gradesArray.indexOf(getGradeByNumericValue(studentLevel.toSchoolGradeNumeric));
      }

      const beginIndex = startGradeIndex > -1 ? startGradeIndex : 0;
      const endIndex = endGradeIndex > -1 ? endGradeIndex : 14;

      for (let i = beginIndex; i <= endIndex; i++) {
        gradesFromSessions.push(gradesArray[i]);
      }
    });
    return Array.from(new Set(gradesFromSessions)).sort(compareGrades);
  }
  return [];
};

export const compareGrades = (gradeA: { sortValue: number }, gradeB: { sortValue: number }) => {
  if (gradeA.sortValue < gradeB.sortValue) {
    return -1;
  }
  if (gradeA.sortValue > gradeB.sortValue) {
    return 1;
  }
  return 0;
};
