import React, { useMemo, useState } from 'react';
import { Box, IconButton, Link, Tooltip } from '@mui/material';
import { EnhancedColumn } from '../../components/Table/Table';
import {
  getInvoiceFileName,
  getPaymentReceiptFileName,
  moneyWithCurrencyCodeFormatter,
  ProductPurchaseInterface,
  PurchaseStatus,
  purchaseStatusViewConnector
} from '@efacity/common';
import DateColumnFilter from '../../components/Table/filters/DateColumnFilter';
import PurchaseStatusFilter from './PurchaseStatusFilter';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ColoredPurchaseStatusDate from './ColoredPurchaseStatusDate';
import ProductDescriptionLinkWithCopier from '../../components/ProductDescriptionLinkWithCopier/ProductDescriptionLinkWithCopier';

const marginTopCellStyles = { verticalAlign: 'top', paddingTop: 10 };

export const useProductPurchasesColumns = (
  orgId: string,
  timeZone: string,
  changeStatus: (processingPurchaseId: string) => void,
  processingPurchaseId: string | null
) => {
  return useMemo(() => {
    const columns: EnhancedColumn<ProductPurchaseInterface>[] = [
      {
        Header: 'Product Name',
        accessor: 'productName',
        disableFilters: false,
        columnStyles: marginTopCellStyles,
        Cell: ({ value, row }) => (
          <Box display="flex" alignItems="center" data-testid="product-name-copier-cell">
            {value}&nbsp;&nbsp;
            <ProductDescriptionLinkWithCopier
              orgId={row.original.orgId as string}
              productId={row.original.productId as string}
            />
          </Box>
        )
      },
      {
        Header: 'Customer Name',
        accessor: 'customerName',
        disableFilters: false,
        columnStyles: marginTopCellStyles,
        Cell: ({ value }) => <div>{value}</div>
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: false,
        columnStyles: { ...marginTopCellStyles, width: 280, maxWidth: 280 },
        Cell: ({ value, row }) => {
          const [isExpanded, setIsExpanded] = useState(false);
          return (
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              {isExpanded ? (
                <div style={{ width: '100%' }}>
                  {row.original?.orderedAt && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.orderedAt}
                      timeZone={timeZone}
                      purchaseStatus={PurchaseStatus.Ordered}
                    />
                  )}
                  {row.original?.paidAt && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.paidAt}
                      timeZone={timeZone}
                      purchaseStatus={PurchaseStatus.Paid}
                    />
                  )}
                  {row.original?.shippedAt && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.shippedAt}
                      timeZone={timeZone}
                      purchaseStatus={PurchaseStatus.Shipped}
                    />
                  )}
                  {(row.original?.deliveryConfirmedByCustomerAt || row.original?.deliveryConfirmedByAdminAt) && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.deliveryConfirmedByCustomerAt || row.original?.deliveryConfirmedByAdminAt}
                      timeZone={timeZone}
                      purchaseStatus={PurchaseStatus.Delivered}
                    />
                  )}
                  {row.original?.cancelledAt && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.cancelledAt}
                      timeZone={timeZone}
                      purchaseStatus={PurchaseStatus.Cancelled}
                    />
                  )}
                </div>
              ) : (
                <div style={{ width: '100%' }}>{purchaseStatusViewConnector[value]}</div>
              )}
              <div>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setIsExpanded(!isExpanded)}
                  data-testid="collapseButton"
                  style={{ marginTop: -10 }}
                >
                  {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
            </div>
          );
        },
        Filter: PurchaseStatusFilter
      },
      {
        Header: 'Status Updated',
        accessor: 'updatedAt',
        disableFilters: false,
        columnStyles: { ...marginTopCellStyles, width: 190, maxWidth: 190 },
        Cell: ({ value, row }) => (
          <div>
            <ColoredPurchaseStatusDate
              dateTime={value}
              timeZone={timeZone}
              purchaseStatus={row.original.status}
              showStatus={false}
            />
          </div>
        ),
        Filter: DateColumnFilter
      },
      {
        Header: 'Balance',
        disableFilters: false,
        headerStyles: { textAlign: 'right', paddingRight: 15 },
        columnStyles: { ...marginTopCellStyles, textAlign: 'right', width: 115, maxWidth: 115 },
        Cell: ({ value, row }) => {
          return (
            <div>
              {moneyWithCurrencyCodeFormatter(
                row.original.invoiceBalance?.balance ? row.original.invoiceBalance?.balance : 0,
                row.original?.invoiceBalance?.currencyCode
              )}
              <span {...row.getToggleRowExpandedProps()}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  data-testid="collapseButton"
                  style={{ marginTop: -10 }}
                >
                  {row.isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </span>
            </div>
          );
        }
      },
      {
        Header: 'Invoice',
        disableFilters: false,
        columnStyles: { ...marginTopCellStyles, width: 65, maxWidth: 65 },
        Cell: ({ value, row }) => {
          return (
            <div>
              {row.original.invoiceBalance ? (
                row.original.invoiceBalance?.isPaid ? (
                  <Tooltip title="Download Payment Receipt">
                    <Link href={getPaymentReceiptFileName(row.original.invoiceBalance?._id)}>Receipt</Link>
                  </Tooltip>
                ) : (
                  <Tooltip title="Download Invoice">
                    <Link href={getInvoiceFileName(row.original.invoiceBalance?._id)}>Invoice</Link>
                  </Tooltip>
                )
              ) : (
                ''
              )}
            </div>
          );
        }
      }
    ];

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, timeZone, processingPurchaseId]);
};
