import { CountryCode, PreferredPronoun } from '@efacity/common';
import { emptyDOB } from '@efacity/react-next-sc';

export interface StudentFormValues {
  fullName: string;
  email: string;
  defaultPhone: string;
  defaultPhoneCountry: CountryCode;
  phone: string;
  phoneCountry: CountryCode;
  studentId: string;
  preferredPronoun: string;
  gradeInSeptemberNumeric?: number;
  tShirtSize?: string;
  medicalInformation: string;
  emergencyInstructions: string;
  dateOfBirth: {
    year: string;
    month: string;
    day: string;
  };
  age?: number | string;
}

export const initialStudentFormValues: StudentFormValues = {
  fullName: '',
  email: '',
  defaultPhone: '',
  defaultPhoneCountry: CountryCode.USA,
  phone: '',
  phoneCountry: CountryCode.USA,
  studentId: '',
  preferredPronoun: PreferredPronoun.notSpecified,
  gradeInSeptemberNumeric: undefined,
  medicalInformation: '',
  emergencyInstructions: '',
  dateOfBirth: emptyDOB,
  tShirtSize: '',
  age: ''
};
