import { LinearProgress, Typography } from '@mui/material';
import React from 'react';

export const ConfirmConnectPrompt: React.FC = () => {
  return (
    <div style={{ margin: 30 }}>
      <Typography color="primary" style={{ fontSize: 18, textAlign: 'center', margin: 30 }}>
        Confirming Stripe Connect...
      </Typography>
      <div style={{ width: '50%', margin: 'auto' }}>
        <LinearProgress variant="indeterminate" color="primary" data-testid="loadingIndicator" />
      </div>
    </div>
  );
};
