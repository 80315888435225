import {
  AllocationPaymentSourceType,
  convertUtcToZonedTime,
  InvoiceBalance,
  moneyWithCurrencyCodeFormatter,
  formatDateWithFormatter,
  monthDayYearDateFormatHoursFormat
} from '@efacity/common';
import { LightTooltip } from '@efacity/react-next-sc';
import {
  smallTableCellSx,
  smallTableContainerSx,
  smallTableHeadCellSx,
  smallTableHeadSx,
  smallTableRowSx,
  smallTableTotalCellSx
} from '@efacity/react-next-sc';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';

const marginTopCellStyles = { verticalAlign: 'top', paddingTop: 6 };

export interface SmallInvoicePaymentsTableProps {
  orgId: string;
  invoiceBalance: InvoiceBalance;
  timeZone: string;
}

const SmallInvoicePaymentsTable: FC<SmallInvoicePaymentsTableProps> = ({ orgId, invoiceBalance, timeZone }) => {
  const theme = useTheme();
  const payoutColor = (amount: number) => {
    return amount < 0 ? theme.palette.secondary.dark : '';
  };

  return invoiceBalance?.payments?.length > 0 || invoiceBalance?.appliedCredit < 0 ? (
    <TableContainer sx={smallTableContainerSx}>
      <Table size="small" aria-label="small invoice payments table" style={{ verticalAlign: 'top', paddingTop: 10 }}>
        <TableHead sx={smallTableHeadSx}>
          <TableRow sx={smallTableRowSx} data-testid="table-row-header">
            <TableCell sx={smallTableHeadCellSx} align="left">
              Description
            </TableCell>
            <TableCell sx={smallTableHeadCellSx} align="left">
              Destination
            </TableCell>
            <TableCell sx={{ ...smallTableHeadCellSx }} align="left">
              Status
            </TableCell>
            <TableCell sx={{ ...smallTableHeadCellSx, width: '50' }} align="left">
              Amount
            </TableCell>
            <TableCell sx={{ ...smallTableHeadCellSx, width: '50' }} align="left">
              Your Payout
            </TableCell>
            <TableCell sx={{ ...smallTableHeadCellSx, width: '110' }} align="left">
              Third party Payout
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={smallTableRowSx}
            key={`payments-row-amount-with-tax`}
            data-testid="payments-row-amount-with-tax"
            style={marginTopCellStyles}
          >
            <TableCell sx={{ ...smallTableCellSx, paddingLeft: 1.0 }}>Total Amount with tax</TableCell>
            <TableCell sx={smallTableCellSx} />
            <TableCell sx={smallTableCellSx} />
            <TableCell sx={{ ...smallTableCellSx, width: '50px' }} align="right">
              {moneyWithCurrencyCodeFormatter(-invoiceBalance.amountWithTax, invoiceBalance?.currencyCode)}
            </TableCell>
            <TableCell sx={smallTableCellSx} />
            <TableCell sx={{ ...smallTableCellSx, width: '110' }} />
          </TableRow>
          {invoiceBalance?.payments?.length > 0 &&
            invoiceBalance.payments.map((payment, i) => {
              return (
                <React.Fragment key={`payment-${i}`}>
                  {(payment?.externalPaymentAmount || 0) < 0 && (
                    <TableRow
                      sx={smallTableRowSx}
                      key={`payment-row-amount-${i}`}
                      data-testid={`payment-row-amount-${i}`}
                      style={marginTopCellStyles}
                    >
                      <TableCell sx={{ ...smallTableCellSx, paddingLeft: '10px' }}>
                        {invoiceBalance?.cardInfo
                          ? `Credit card payment via Stripe, ${formatDateWithFormatter(
                              convertUtcToZonedTime(payment.paymentDate, timeZone),
                              monthDayYearDateFormatHoursFormat
                            )}. Source: ${invoiceBalance.cardInfo.cardType} (...${invoiceBalance.cardInfo.last4})`
                          : `Credit card payment via Stripe, ${formatDateWithFormatter(
                              convertUtcToZonedTime(payment.paymentDate, timeZone),
                              monthDayYearDateFormatHoursFormat
                            )}`}
                      </TableCell>
                      <TableCell sx={smallTableCellSx} />
                      <TableCell sx={smallTableCellSx} />
                      <TableCell sx={{ ...smallTableCellSx, width: '50px' }} align="right">
                        {moneyWithCurrencyCodeFormatter(-payment?.externalPaymentAmount, invoiceBalance?.currencyCode)}
                      </TableCell>
                      <TableCell sx={smallTableCellSx} />
                      <TableCell sx={{ ...smallTableCellSx, width: '110' }} />
                    </TableRow>
                  )}
                  {payment.allocations?.length > 0 &&
                    payment.allocations
                      .filter((item) => item.allocationPaymentSourceType === AllocationPaymentSourceType.Payment)
                      .map(
                        (allocation, j) =>
                          allocation &&
                          (allocation?.transferAmount || 0) - (allocation?.reversedAmount || 0) > 0 && (
                            <TableRow
                              sx={smallTableRowSx}
                              key={`payment-row-${i}-transfer-${j}`}
                              data-testid={`payment-row-${i}-transfer-${j}`}
                              style={marginTopCellStyles}
                            >
                              <TableCell sx={{ ...smallTableCellSx, paddingLeft: '50px' }}>
                                {allocation?.transferDefinition?.name}
                              </TableCell>
                              <TableCell sx={smallTableCellSx}>
                                {allocation?.transferDefinition?.destinationOrganizationName}
                              </TableCell>
                              <TableCell sx={smallTableCellSx} align="center">
                                {allocation.isSuccess ? (
                                  <CheckCircleOutlineIcon style={{ color: theme.palette.secondary.main }} />
                                ) : (
                                  <LightTooltip
                                    title={
                                      <Typography>
                                        {allocation?.stripeTransfer?.raw?.message ||
                                          'Failed to perform transaction to destination account'}
                                      </Typography>
                                    }
                                  >
                                    <HighlightOffIcon style={{ color: theme.palette.error.main }} />
                                  </LightTooltip>
                                )}
                              </TableCell>
                              <TableCell sx={smallTableCellSx} />
                              <TableCell sx={{ ...smallTableCellSx, width: '50px' }} align="right">
                                {allocation?.transferDefinition?.destinationOrganizationId === orgId &&
                                  moneyWithCurrencyCodeFormatter(
                                    (allocation?.transferAmount || 0) - (allocation?.reversedAmount || 0),
                                    invoiceBalance?.currencyCode
                                  )}
                              </TableCell>
                              <TableCell sx={{ ...smallTableCellSx, width: '110px' }} align="right">
                                {allocation?.transferDefinition?.destinationOrganizationId !== orgId &&
                                  moneyWithCurrencyCodeFormatter(
                                    (allocation?.transferAmount || 0) - (allocation?.reversedAmount || 0),
                                    invoiceBalance?.currencyCode
                                  )}
                              </TableCell>
                            </TableRow>
                          )
                      )}

                  {invoiceBalance?.appliedCredit !== 0 && (
                    <>
                      <TableRow
                        sx={smallTableRowSx}
                        key="payment-row-applied-credit"
                        data-testid="payment-row-applied-credit"
                        style={marginTopCellStyles}
                      >
                        <TableCell sx={{ ...smallTableCellSx, paddingLeft: '10px' }}>Applied Credit</TableCell>
                        <TableCell sx={smallTableCellSx} />
                        <TableCell sx={smallTableCellSx} />
                        <TableCell sx={{ ...smallTableCellSx, width: '50px' }} align="right">
                          {moneyWithCurrencyCodeFormatter(-invoiceBalance.appliedCredit, invoiceBalance?.currencyCode)}
                        </TableCell>
                        <TableCell sx={smallTableCellSx} />
                        <TableCell sx={smallTableCellSx} />
                      </TableRow>
                      {invoiceBalance?.payments?.length > 0 && (
                        <>
                          {invoiceBalance.payments.map((payment, i) => {
                            return (
                              <React.Fragment key={`payment-${i}`}>
                                {payment.allocations?.length > 0 &&
                                  payment.allocations
                                    .filter(
                                      (item) => item.allocationPaymentSourceType === AllocationPaymentSourceType.Credit
                                    )
                                    .map(
                                      (allocation, j) =>
                                        allocation &&
                                        (allocation?.transferAmount || 0) - (allocation?.reversedAmount || 0) > 0 && (
                                          <TableRow
                                            sx={smallTableRowSx}
                                            key={`payment-row-${i}-credit-transfer-${j}`}
                                            data-testid={`payment-row-${i}-credit-transfer-${j}`}
                                            style={marginTopCellStyles}
                                          >
                                            <TableCell sx={{ ...smallTableCellSx, paddingLeft: '50px' }}>
                                              {allocation?.transferDefinition?.name}
                                            </TableCell>
                                            <TableCell sx={smallTableCellSx}>
                                              {allocation?.transferDefinition?.destinationOrganizationName}
                                            </TableCell>
                                            <TableCell sx={smallTableCellSx} align="center">
                                              {allocation.isSuccess ? (
                                                <CheckCircleOutlineIcon
                                                  style={{ color: theme.palette.secondary.main }}
                                                />
                                              ) : (
                                                <LightTooltip
                                                  title={
                                                    <Typography>
                                                      {allocation?.stripeTransfer?.raw?.message ||
                                                        'Failed to perform transaction to destination account'}
                                                    </Typography>
                                                  }
                                                >
                                                  <HighlightOffIcon style={{ color: theme.palette.error.main }} />
                                                </LightTooltip>
                                              )}
                                            </TableCell>
                                            <TableCell sx={smallTableCellSx} />
                                            <TableCell sx={{ ...smallTableCellSx, width: '50px' }} align="right">
                                              {allocation?.transferDefinition?.destinationOrganizationId === orgId &&
                                                moneyWithCurrencyCodeFormatter(
                                                  (allocation?.transferAmount || 0) - (allocation?.reversedAmount || 0),
                                                  invoiceBalance?.currencyCode
                                                )}
                                            </TableCell>
                                            <TableCell sx={{ ...smallTableCellSx, width: '110px' }} align="right">
                                              {allocation?.transferDefinition?.destinationOrganizationId !== orgId &&
                                                moneyWithCurrencyCodeFormatter(
                                                  (allocation?.transferAmount || 0) - (allocation?.reversedAmount || 0),
                                                  invoiceBalance?.currencyCode
                                                )}
                                            </TableCell>
                                          </TableRow>
                                        )
                                    )}
                              </React.Fragment>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}

                  {payment?.issuedCreditAmount > 0 && (
                    <TableRow
                      sx={smallTableRowSx}
                      key={`payment-row-${i}-credited-amount`}
                      data-testid={`payment-row-${i}-credited-amount`}
                      style={marginTopCellStyles}
                    >
                      <TableCell sx={{ ...smallTableCellSx, paddingLeft: '10px' }}>Issued Credit</TableCell>
                      <TableCell sx={smallTableCellSx} />
                      <TableCell sx={smallTableCellSx} />
                      <TableCell sx={{ ...smallTableCellSx, width: '50px' }} align="right">
                        {moneyWithCurrencyCodeFormatter(-payment?.issuedCreditAmount, invoiceBalance?.currencyCode)}
                      </TableCell>
                      <TableCell sx={smallTableCellSx} />
                      <TableCell sx={smallTableCellSx} />
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}

          <TableRow key="payment-row-balance" data-testid="payment-row-balance">
            <TableCell sx={smallTableCellSx} />
            <TableCell sx={smallTableTotalCellSx} align="right">
              Balance :
            </TableCell>
            <TableCell sx={smallTableCellSx} />
            <TableCell sx={smallTableTotalCellSx} align="right">
              {moneyWithCurrencyCodeFormatter(-invoiceBalance?.balance, invoiceBalance?.currencyCode)}
            </TableCell>
            <TableCell
              sx={smallTableTotalCellSx}
              align="right"
              style={{ color: payoutColor(invoiceBalance?.payoutsAmount || 0) }}
            >
              {moneyWithCurrencyCodeFormatter(invoiceBalance.ownerPayoutsAmount, invoiceBalance?.currencyCode)}
            </TableCell>
            <TableCell
              sx={{ ...smallTableTotalCellSx, width: '110px' }}
              align="right"
              style={{ color: payoutColor(invoiceBalance?.payoutsAmount || 0) }}
            >
              {moneyWithCurrencyCodeFormatter(invoiceBalance.thirdPartyPayoutsAmount, invoiceBalance?.currencyCode)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <TableContainer sx={smallTableContainerSx}>
      <Table size="small" aria-label="a simple dense table">
        <TableBody>
          <TableRow key="payment-table-no=payments">
            <TableCell align="left">
              <Typography color={'error'}>This invoice has no payments.</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SmallInvoicePaymentsTable;
