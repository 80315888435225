import { RegistrationStatus, User } from '@efacity/common';
import { SessionWithLocationAndTags } from '@efacity/react-next-sc';

export class RegistrationStatusOption {
  constructor(public value: RegistrationStatus, public label: string) {}
}

export const registrationStatusWithLabels: RegistrationStatusOption[] = [
  {
    value: RegistrationStatus.Selected,
    label: 'Selected'
  },
  {
    value: RegistrationStatus.Registered,
    label: 'Registered'
  },
  {
    value: RegistrationStatus.RegisteredAndPaid,
    label: 'Registered and Paid'
  },
  {
    value: RegistrationStatus.Unregistered,
    label: 'Unregistered'
  }
];

export class SessionRegistrationWithSessionAndEducator {
  constructor(
    public _id: string,
    public userId: string,
    public efacitySessionId: string,
    public efacitySession: SessionWithLocationAndTags,
    public teacher: User,
    public registeredAt: string,
    public status: RegistrationStatus,
    public cancelledOn?: string,
    public cancelledBy?: string
  ) {}
}
