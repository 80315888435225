export enum ConnectStatus {
  NotConnected = 'notConnected',
  Connecting = 'Connecting',
  ConnectedStandard = 'ConnectedStandart',
  ConnectingExpress = 'ConnectingExpress',
  ConnectingExpressError = 'ConnectingExpressError',
  ConnectingExpressPending = 'ConnectingExpressPending',
  ConnectedExpress = 'ConnectedExpress'
}

export const connectStatusViews = {
  [ConnectStatus.NotConnected]: 'Not connected',
  [ConnectStatus.Connecting]: 'Connecting...',
  [ConnectStatus.ConnectedStandard]: 'Connected Standard',
  [ConnectStatus.ConnectingExpress]: 'Connecting Express...',
  [ConnectStatus.ConnectingExpressError]: 'Connect not finished',
  [ConnectStatus.ConnectingExpressPending]: 'Connecting is pending...',
  [ConnectStatus.ConnectedExpress]: 'Connected Express'
};

export enum ConnectModel {
  ConnectStandard = 'connectStandard',
  ConnectExpress = 'connectExpress',
  ConnectCustom = 'connectCustom'
}
export const connectModelViewConnector = {
  [ConnectModel.ConnectStandard]: 'Stripe Connect Standard',
  [ConnectModel.ConnectExpress]: 'Stripe Connect Express',
  [ConnectModel.ConnectCustom]: 'Stripe Connect Custom'
};
export const connectModelOptions = Object.entries(connectModelViewConnector).map(([value, label]) => {
  return {
    value,
    label
  };
});

export enum PaymentAcceptanceMethod {
  None = 'none',
  Self = 'selfProcessing',
  Efacity = 'viaEfacity',
  Alternative = 'alternative'
}
export const paymentAcceptanceMethodViewConnector = {
  [PaymentAcceptanceMethod.None]: 'None',
  [PaymentAcceptanceMethod.Self]: 'Self Processing',
  [PaymentAcceptanceMethod.Efacity]: 'Via Efacity',
  [PaymentAcceptanceMethod.Alternative]: 'Alternative'
};
export const paymentAcceptanceMethodOptions = Object.entries(paymentAcceptanceMethodViewConnector).map(
  ([value, label]) => {
    return {
      value,
      label
    };
  }
);

export enum AllocationDestinationType {
  ActivityOwner = 'toActivityOwner',
  SessionProvider = 'toSessionProvider',
  Efacity = 'toEfacity'
}
export enum AllocationPaymentSourceType {
  Credit = 'FromCredit',
  Payment = 'FromPayment'
}
export class OrganizationPaymentStatus {
  isPaymentAllowed: boolean;
  paymentAcceptanceMethod: PaymentAcceptanceMethod;
  connectModel: ConnectModel;
  connectStatus: ConnectStatus;
}

export class OrganizationTransfer {
  _id: string;
  paymentPlatformAccountId: string;
  stripeUserId: string;
  transferPercentage?: number;
  transferAmount?: number;
  fromAccountName?: string;
  toAccountName: string;
  name: string;
  destinationOrganizationId: string;
  destinationOrganizationName: string;
}
