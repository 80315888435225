import React, { FC } from 'react';
import {
  getAmountForRegistration,
  getBalanceForRegistration,
  getDateFormattedForUserTimeZone,
  getInvoiceFileName,
  getPaymentReceiptFileName,
  moneyWithCurrencyCodeFormatter,
  RegistrationStatus
} from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import { Collapse, Grid, IconButton, Link, Table, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import ColorRegistrationStatus from '../../../components/ColorRegistrationStatus';
import { NavLink } from 'react-router-dom';
import { SessionTimeCell } from '@efacity/frontend-shared';
import { RegistrationRowProps } from './CustomerRegistrationDesktopRow';
import { tableStyles } from '../../../stylesheets/tableStyle';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SmallInvoiceDataTable from '../../../components/SmallInvoiceDataTable/SmallInvoiceDataTable';
import RegistrationActionButtons from './RegistrationActionButtons';

const mobileRegistrationRowSx = {
  root: (theme) => ({
    marginBottom: '5px',
    marginTop: '5px',
    padding: '10px',
    border: `1px solid ${theme.palette.primary.dark}`
  }),
  sessionName: (theme) => ({
    marginBottom: '10px',
    paddingBottom: '5px',
    borderBottom: `1px solid ${theme.palette.primary.dark}`
  }),
  ...tableStyles,
  leftCellStyle: (theme) => ({
    color: theme.palette.primary.dark,
    fontWeight: 600,
    margin: 0,
    paddingLeft: 0,
    paddingRight: '5px',
    paddingTop: '5px',
    paddingBottom: '5px'
  }),
  cellStyle: {
    margin: 0,
    paddingLeft: '5px',
    paddingRight: 0,
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  cancelButton: {
    width: '90px',
    textTransform: 'uppercase',
    padding: 0,
    color: 'red'
  },
  payButton: {
    width: '90px',
    textTransform: 'uppercase',
    padding: 0
  }
};

const CustomerRegistrationMobileRow: FC<RegistrationRowProps> = ({
  registration,
  handleCancelRegistration,
  isCancelling,
  openReviewModal
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Grid sx={mobileRegistrationRowSx.root} container>
      <Grid item xs={12} sx={mobileRegistrationRowSx.sessionName}>
        <Link
          component={NavLink}
          to={toPath(PATHS.sessionRegistration, { sessionId: registration.sessionId ? registration.sessionId : '' })}
          underline="none"
          style={{ fontWeight: '600', color: registration.status === RegistrationStatus.Unregistered && 'red' }}
        >
          {registration.sessionName}
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={mobileRegistrationRowSx.leftCellStyle}>Student</TableCell>
              <TableCell style={mobileRegistrationRowSx.cellStyle}>{registration.studentName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={mobileRegistrationRowSx.leftCellStyle}>Start Date</TableCell>
              <TableCell style={mobileRegistrationRowSx.cellStyle}>
                {registration.schedule?.startDateTime
                  ? getDateFormattedForUserTimeZone(registration.schedule.startDateTime)
                  : '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={mobileRegistrationRowSx.leftCellStyle}>Time</TableCell>
              <TableCell style={mobileRegistrationRowSx.cellStyle}>
                {registration.schedule?.startDateTime ? (
                  <SessionTimeCell sessionSchedule={registration.schedule} displayDate={false} />
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={mobileRegistrationRowSx.leftCellStyle}>Price</TableCell>
              <TableCell style={mobileRegistrationRowSx.cellStyle} align="right">
                {moneyWithCurrencyCodeFormatter(
                  getAmountForRegistration(registration._id, registration?.invoiceBalance?.invoiceItems || []),
                  registration.invoiceBalance?.currencyCode || registration.currencyCode
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={mobileRegistrationRowSx.leftCellStyle}>Balance</TableCell>
              <TableCell style={mobileRegistrationRowSx.cellStyle} align="right">
                {moneyWithCurrencyCodeFormatter(
                  registration.status === RegistrationStatus.RegisteredAndPaid ||
                    registration.status === RegistrationStatus.Unregistered
                    ? 0
                    : getBalanceForRegistration(registration._id, registration?.invoiceBalance?.invoiceItems || []),
                  registration.invoiceBalance?.currencyCode || registration.currencyCode
                )}
                {registration.status !== RegistrationStatus.Unregistered && (
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                    data-testid="collapseButton"
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ padding: 0 }} colSpan={2}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <SmallInvoiceDataTable invoiceBalance={registration.invoiceBalance} />
                </Collapse>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={mobileRegistrationRowSx.leftCellStyle}>Status</TableCell>
              <TableCell style={mobileRegistrationRowSx.cellStyle}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <ColorRegistrationStatus registrationStatus={registration.status} />
                  {registration?.invoiceId ? (
                    registration?.invoiceBalance?.isPaid ? (
                      <Tooltip title="Download Payment Receipt">
                        <Link href={getPaymentReceiptFileName(registration?.invoiceBalance?._id)}>Receipt</Link>
                      </Tooltip>
                    ) : registration.status === RegistrationStatus.Registered ? (
                      <Tooltip title="Download Invoice">
                        <Link href={getInvoiceFileName(registration?.invoiceBalance?._id)}>Invoice</Link>
                      </Tooltip>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 10, marginBottom: 0, display: 'flex', justifyContent: 'space-around' }}>
        <RegistrationActionButtons
          registration={registration}
          openReviewModal={openReviewModal}
          isCancelling={isCancelling}
          handleCancelRegistration={handleCancelRegistration}
        />
      </Grid>
    </Grid>
  );
};

export default CustomerRegistrationMobileRow;
