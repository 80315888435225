import {
  CopyLinkFlatIcon,
  getRegistrationLnkForActivityDependingOnActivityAndOwnerType
} from '@efacity/frontend-shared';
import { LightTooltip } from '@efacity/react-next-sc';
import { IconButton, Typography } from '@mui/material';

import { ActivityTypes } from '@efacity/common';
import copy from 'copy-to-clipboard';
import { FC } from 'react';

export interface RegistrationLinkWithCopierProps {
  isEnabled: boolean;
  activityId: string;
  orgId: string;
  activityType: string;
}

const ActivityRegistrationLinkWithCopier: FC<RegistrationLinkWithCopierProps> = ({
  isEnabled,
  activityId,
  orgId,
  activityType
}) => {
  const handleCopyRegistrationClick = (activityId: string, orgId: string, activityType: string) => {
    const registrationPageLink = getRegistrationLnkForActivityDependingOnActivityAndOwnerType(
      activityId,
      orgId,
      activityType as ActivityTypes
    );
    copy(`${window.location.origin}${registrationPageLink}`);
  };

  return isEnabled && orgId ? (
    <LightTooltip title={<Typography>Copy Activity Link to share</Typography>}>
      <span>
        <div style={{ display: 'flex', marginRight: 5 }} data-testid="registration-link-with-copier">
          [
          <IconButton
            color="primary"
            aria-label="copy to clipboard"
            component="span"
            style={{ marginTop: -4, padding: 5 }}
            onClick={() => handleCopyRegistrationClick(activityId, orgId, activityType)}
          >
            <CopyLinkFlatIcon fontSize="small" />
          </IconButton>
          ]
        </div>
      </span>
    </LightTooltip>
  ) : null;
};

export default ActivityRegistrationLinkWithCopier;
