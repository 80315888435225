import { CopyLinkFlatIcon } from '@efacity/frontend-shared';
import { LightTooltip } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import { IconButton, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import React from 'react';

export interface ProductDescriptionLinkWithCopierProps {
  orgId: string;
  productId: string;
}

const ProductDescriptionLinkWithCopier: React.FC<ProductDescriptionLinkWithCopierProps> = ({ orgId, productId }) => {
  const handleCopyDescriptionClick = (orgId: string, productId: string) => {
    const registrationPageLink = toPath(PATHS.publicProductDescription, {
      orgId: orgId,
      productId: productId
    });
    copy(`${window.location.origin}${registrationPageLink}`);
  };

  return (
    <LightTooltip title={<Typography>Copy Product Link to share</Typography>}>
      <span>
        <div style={{ display: 'flex', marginRight: 5 }} data-testid="registration-link-with-copier">
          [
          <IconButton
            color="primary"
            aria-label="copy to clipboard"
            component="span"
            style={{ marginTop: -4, padding: 5 }}
            onClick={() => handleCopyDescriptionClick(orgId, productId)}
          >
            <CopyLinkFlatIcon fontSize="small" />
          </IconButton>
          ]
        </div>
      </span>
    </LightTooltip>
  );
};

export default ProductDescriptionLinkWithCopier;
