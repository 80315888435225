import React, { FC } from 'react';
import { ButtonProps } from '@mui/material';
import Button from '@mui/material/Button';
import AddCircleOutlined from '@mui/icons-material/AddCircleOutlined';

//todo @dupe: move buttons to a separate library

export const NewButton: FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      color="primary"
      variant="contained"
      {...props}
      startIcon={<AddCircleOutlined style={{ color: 'white', marginRight: 15 }} />}
    >
      {children}
    </Button>
  );
};
