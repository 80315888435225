'use client';

import React from 'react';
import {
  convertUtcToZonedTime,
  EnrollmentFormValues,
  GenderViewConnector,
  getGradeDisplayTextByNumericValue,
  SchoolTypeViewConnector
} from '@efacity/common';
import { ViewItem, AddressMapLink } from '@efacity/frontend-shared';
import { Box, Typography } from '@mui/material';
import { UploadedFileItems } from '@efacity/react-hook-form-mui';
import { isInteger } from 'lodash';
import { ParentInfoSection } from './ParentInfoSection';
import { enrollmentViewSx } from './enrollmentViewSx';

const formatDate = (date: string | Date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString();
};

interface EnrollmentViewProps {
  enrollmentForm: EnrollmentFormValues;
  submittedOn: string | Date | undefined;
  timeZone: string;
}

export const EnrollmentView: React.FC<EnrollmentViewProps> = ({ enrollmentForm, submittedOn, timeZone }) => {
  const secondParentInfoExists = !!enrollmentForm.secondParent;

  return (
    <>
      <ParentInfoSection sectionTitle="Parent / Guardian 1" parent={enrollmentForm.customer} />
      {secondParentInfoExists && (
        <ParentInfoSection sectionTitle="Parent / Guardian 2" parent={enrollmentForm.secondParent} />
      )}

      <Box component="section" sx={enrollmentViewSx.section}>
        <Typography component="h2" sx={enrollmentViewSx.subtitle}>
          Candidate information
        </Typography>
        <ViewItem title="First name">{enrollmentForm.student.firstName}</ViewItem>
        <ViewItem isOptional title="Middle name">
          {enrollmentForm.student.middleName}
        </ViewItem>
        <ViewItem title="Last name">{enrollmentForm.student.lastName}</ViewItem>
        <ViewItem isOptional title="Suffix">
          {enrollmentForm.student.suffix}
        </ViewItem>

        <ViewItem title="Gender">{GenderViewConnector[enrollmentForm.student.gender]}</ViewItem>
        <ViewItem title="Date of birth">
          {enrollmentForm?.student?.dateOfBirth
            ? formatDate(convertUtcToZonedTime(enrollmentForm.student.dateOfBirth, timeZone))
            : null}
        </ViewItem>

        <ViewItem title="Race/Ethnicity">{enrollmentForm.student.race.join('; ')}</ViewItem>

        <section>
          <Typography component="h3" sx={enrollmentViewSx.thirdLevelTitle}>
            Address
          </Typography>

          <ViewItem title="Country">{enrollmentForm.student.address.country}</ViewItem>
          <ViewItem title="Address">{enrollmentForm.student.address.address}</ViewItem>
          <ViewItem isOptional title="Address2">
            {enrollmentForm.student.address.address2}
          </ViewItem>
          <ViewItem title="City, state">
            {enrollmentForm.student.address.city + ', ' + enrollmentForm.student.address.state}
          </ViewItem>
          <ViewItem title="Zip">{enrollmentForm.student.address.zip}</ViewItem>
          <AddressMapLink address={enrollmentForm.student.address} />
        </section>

        <ViewItem isOptional title="If a non-English language is spoken at home, please indicate which language">
          {enrollmentForm.student.homeLanguage}
        </ViewItem>

        <section>
          <Typography component="h3" sx={enrollmentViewSx.thirdLevelTitle}>
            Current school
          </Typography>
          <ViewItem title="Type">{SchoolTypeViewConnector[enrollmentForm.student.currentSchool.schoolType]}</ViewItem>
          <ViewItem title="Name">{enrollmentForm.student.currentSchool.name}</ViewItem>
          <ViewItem title="State">{enrollmentForm.student.currentSchool.state}</ViewItem>

          <ViewItem title="Enrolled from">
            {enrollmentForm?.student?.currentSchool?.enrolledFrom
              ? formatDate(convertUtcToZonedTime(enrollmentForm.student.currentSchool.enrolledFrom, timeZone))
              : null}
          </ViewItem>

          {isInteger(enrollmentForm.student?.grade) ? (
            <ViewItem title="Current grade level">
              {getGradeDisplayTextByNumericValue(enrollmentForm.student.grade as number)}
            </ViewItem>
          ) : null}
        </section>

        {enrollmentForm.currentYearReportCard?.length > 0 && (
          <ViewItem title="Current year report card(s)">
            <UploadedFileItems
              uploadedFiles={enrollmentForm.currentYearReportCard as string[]}
              onDeleteUploadedFile={null}
              disabled={false}
            />
          </ViewItem>
        )}
        {enrollmentForm.iqTest?.length > 0 && (
          <ViewItem title="Assessments and Reports (if available): IQ test, standardized assessment reports, etc.">
            <UploadedFileItems
              uploadedFiles={enrollmentForm.iqTest as string[]}
              onDeleteUploadedFile={null}
              disabled={false}
            />
          </ViewItem>
        )}
        {enrollmentForm.teacherRecommendations?.length > 0 && (
          <ViewItem title="Teacher recommendation(s)">
            <UploadedFileItems
              uploadedFiles={enrollmentForm.teacherRecommendations as string[]}
              onDeleteUploadedFile={null}
              disabled={false}
            />
          </ViewItem>
        )}
      </Box>

      <Box component="section" sx={enrollmentViewSx.section}>
        <Typography component="h2" sx={enrollmentViewSx.subtitle}>
          Parent questionnaire
        </Typography>

        <ViewItem isParagraph title="How would you describe your child's particular strengths and special aptitudes?">
          {enrollmentForm.student.specialAptitudes}
        </ViewItem>
        <ViewItem isParagraph title="How would you describe your child's struggles and weaknesses?">
          {enrollmentForm.student.weaknesses}
        </ViewItem>
        <ViewItem
          isOptional
          isParagraph
          title="What topics or types of activities are your child most interested in? If your child has a favorite subject in school or individual hobby or interest, please share."
        >
          {enrollmentForm.student.hobby}
        </ViewItem>
        <ViewItem
          isParagraph
          title="When your child needs to be redirected or corrected, what strategies have you found to be most effective?"
        >
          {enrollmentForm.student.correction}
        </ViewItem>
        <ViewItem
          isParagraph
          title="In what ways do you see yourself contributing to your child’s school experiences? How can you contribute to/participate in the parent collaborative school community?"
        >
          {enrollmentForm.student.schoolCollaboration}
        </ViewItem>
        <ViewItem
          isParagraph
          title="Please expand upon any specialized supports your child needs at school. Please be specific about the social, emotional, motor, sensory etc. needs that your child may have."
        >
          {enrollmentForm.student.specialSupports}
        </ViewItem>
        <ViewItem
          isOptional
          isParagraph
          title="Does your child have any special concerns, issues, or twice exceptionality about which we should be aware? If so, please provide additional details."
        >
          {enrollmentForm.student.specialConcerns}
        </ViewItem>

        <ViewItem title="Student has an IEP or 504 plan">{enrollmentForm.student.plan504 ? 'yes' : 'no'}</ViewItem>

        <ViewItem title="Allergies or other medical information">{enrollmentForm.student.medicalInformation}</ViewItem>
        <ViewItem title="Emergency instructions">{enrollmentForm.student.emergencyInstructions}</ViewItem>

        {enrollmentForm.optionalSupplemental && (
          <ViewItem title="Optional supplemental upload">
            <UploadedFileItems
              uploadedFiles={enrollmentForm.optionalSupplemental as string[]}
              onDeleteUploadedFile={null}
              disabled={false}
            />
          </ViewItem>
        )}
      </Box>

      <ViewItem title="Signature">{enrollmentForm.customer.signature}</ViewItem>
      <ViewItem isOptional title="Date">
        {formatDate(submittedOn)}
      </ViewItem>
    </>
  );
};
