import React, { FC, useEffect } from 'react';
import { AddressForm, AddressFormValues } from './AddressForm';
import { CountryCode, FormMode } from '@efacity/common';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import SaveIcon from '@mui/icons-material/Check';
import { HorizontalField } from '@efacity/frontend-shared';
import { addressFormValidationSchema } from '../addressFormValidationSchema';

const buttonStyle = {
  width: 171,
  height: 36,
  textTransform: 'uppercase',
  marginTop: 5,
  marginBottom: 5
} as React.CSSProperties;

interface AddressFormContainerProps {
  onSubmit?: (values: AddressFormValues, setError: (name, error, options?) => void) => void;
  onCancel?: () => void;
  mode: FormMode;
  initialFormValues?: AddressFormValues;
  country?: CountryCode;
}

export const AddressFormContainer: FC<AddressFormContainerProps> = ({
  country,
  onSubmit,
  onCancel,
  initialFormValues,
  mode
}) => {
  const methods = useForm<AddressFormValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(addressFormValidationSchema),
    mode: 'onBlur'
  });
  const { isValid, isSubmitting } = methods.formState;

  const handleSubmit = async (values) => {
    await onSubmit(values, methods.setError);
  };

  useEffect(() => {
    methods.reset(initialFormValues);
  }, [methods, initialFormValues]);

  return (
    <FormProvider {...methods}>
      <form noValidate>
        <AddressForm country={country} />
        <HorizontalField justifyContent="center" wrapOnSmallScreen={false} style={{ marginTop: 15 }}>
          <Button
            style={buttonStyle}
            onClick={onCancel}
            color="primary"
            variant="outlined"
            disabled={isSubmitting}
            data-testid="cancelBtn"
          >
            <CancelOutlined style={{ color: 'primary', marginRight: 20 }} />
            Cancel
          </Button>
          <Button
            style={buttonStyle}
            color="primary"
            variant="contained"
            data-testid="submitBtn"
            disabled={!isValid || isSubmitting}
            onClick={methods.handleSubmit(handleSubmit)}
          >
            {isSubmitting ? (
              <CircularProgress
                variant="indeterminate"
                data-testid="loader"
                size={20}
                style={{ marginRight: 20, color: 'white' }}
              />
            ) : (
              <SaveIcon style={{ marginRight: 20 }} />
            )}
            {mode === FormMode.Add ? 'Create' : 'Save'}
          </Button>
        </HorizontalField>
      </form>
    </FormProvider>
  );
};
