import React from 'react';

export const spinnerStyle = {
  top: '50%',
  marginTop: 0,
  marginRight: 5,
  marginLeft: 5
} as React.CSSProperties;

export const navigationItemStyle = {
  color: '#234C7D',
  height: 32,
  paddingLeft: 5,
  paddingRight: 5,
  paddingTop: 0,
  paddingBottom: 0
} as React.CSSProperties;

export const navigationItemActiveStyle = {
  background: '#fff'
} as React.CSSProperties;
