declare global {
  interface Window {
    dataLayer: any;
  }
}
window.dataLayer = window.dataLayer || [];

export function gtag(...args) {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}
