import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { customTheme } from '@efacity/frontend-shared';

export const navigationTabsStyles = {
  rootStyle: {
    borderBottom: '1px solid #C4C4C4',
    overflowX: 'auto',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch'
  } as React.CSSProperties,
  disabledLinkSx: {
    padding: '0px 0px',
    marginRight: '40px',
    color: '#C4C4C4',
    textTransform: 'uppercase',
    '&:hover': {
      cursor: 'not-allowed'
    }
  } as React.CSSProperties,
  tabStyle: {
    color: '#777777',
    textDecoration: 'none',
    fontWeight: '400',
    marginRight: '40px',
    position: 'relative'
  } as React.CSSProperties,
  activeTabStyle: {
    color: customTheme.palette.primary.main,
    borderBottom: '3px solid',
    marginRight: '40px',
    borderColor: customTheme.palette.primary.main
  } as React.CSSProperties,
  hooverSx: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: 'color .4s, 0.15s box-shadow ease-in-out',
    flexShrink: 0,
    '&:hover': {
      color: 'primary.light'
    }
  }
};

export interface Tab {
  label: string;
  link: string;
  disabled?: boolean;
  // ensures that descendant links won't be matched
  // E.g. for two links sessions/ and sessions/completed
  // if sessions/ has end property, and path is sessions/completed
  // sessions/ link will not be active
  end?: boolean;
}

interface PageNavigationTabsProps {
  tabs: Tab[];
}

const PageNavigationTabs: FC<PageNavigationTabsProps> = ({ tabs }) => {
  return (
    <Box display="flex" style={navigationTabsStyles.rootStyle} data-testid="navTabs">
      {tabs.map((tab, i) =>
        tab.disabled ? (
          <Typography sx={navigationTabsStyles.disabledLinkSx} key={tab.link} data-testid={`tab_${i}`}>
            {tab.label}
          </Typography>
        ) : (
          <NavLink
            to={`${tab.link}`}
            key={tab.link}
            style={({ isActive }) => (isActive ? navigationTabsStyles.activeTabStyle : navigationTabsStyles.tabStyle)}
            end={tab.end}
            data-testid={`tab_${i}`}
          >
            <Box display="flex" sx={navigationTabsStyles.hooverSx} component="nav">
              {tab.label}
            </Box>
          </NavLink>
        )
      )}
    </Box>
  );
};

export default PageNavigationTabs;
