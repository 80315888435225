import { Box } from '@mui/material';
import React, { Dispatch, FC, useState } from 'react';
import { AttendanceStatus } from '@efacity/common';
import { CircularProgressButton } from '@efacity/frontend-shared';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';

export interface AttendanceActionsCellProps {
  attendanceId: string;
  attendanceStatus: AttendanceStatus;
  onChangeStudentAttendanceStatus: (
    attendanceId: string,
    attendanceStatus: AttendanceStatus,
    setActionInProgress: Dispatch<{ rowId: string; button: AttendanceStatus }>
  ) => void;
}

const AttendanceActionsCell: FC<AttendanceActionsCellProps> = ({
  attendanceId,
  attendanceStatus,
  onChangeStudentAttendanceStatus
}) => {
  const [actionInProgress, setActionInProgress] = useState({ rowId: null });
  const disableButtonStatuses = [AttendanceStatus.Expired, AttendanceStatus.Unregistered];

  return (
    <Box display="flex" data-testid="actionBtns" style={{ width: 110, maxWidth: 110 }}>
      <CircularProgressButton
        key={`attendanceStatusKey${attendanceId}`}
        color="primary"
        variant="contained"
        data-testid={`attendance-status-btn`}
        style={{ ...smallPublishButtonStyle, width: 100 }}
        progress={actionInProgress.rowId === attendanceId}
        disabled={disableButtonStatuses.includes(attendanceStatus) || actionInProgress.rowId === attendanceId}
        onClick={() => {
          const requiredStatus =
            attendanceStatus === AttendanceStatus.Present
              ? AttendanceStatus.Absent
              : attendanceStatus === AttendanceStatus.Absent
              ? AttendanceStatus.Registered
              : AttendanceStatus.Present;

          onChangeStudentAttendanceStatus(attendanceId, requiredStatus, setActionInProgress);
        }}
      >
        {attendanceStatus === AttendanceStatus.Present
          ? 'Set Absent'
          : attendanceStatus === AttendanceStatus.Absent
          ? 'Reset'
          : 'Set Present'}
      </CircularProgressButton>
    </Box>
  );
};

export default AttendanceActionsCell;
