import React, { ChangeEvent, useState } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { MediaList } from './MediaList';
import AddMediaUrlModal from './AddMediaUrlModal';
import {
  acceptableMimeTypes,
  allowedFileSizeInBytes,
  defaultImageHeight,
  defaultImageWidth,
  Media
} from '@efacity/common';
import CropImageModal from './CropImageModal';
import { mediumButtonHeight } from '../buttons/styles';

const addButtonDivStyle = {
  display: 'flex',
  justifyContent: 'center',
  margin: 0,
  padding: 5
};
const emptyCropModalState = {
  open: false,
  cropperPreview: null
};

export interface MediaUploadContainerProps {
  media: Media[];
  onVideoSelected;
  onImageSelected;
  onDeleteMedia: (index: number) => void;
}

export const MediaUploadContainer: React.FC<MediaUploadContainerProps> = ({
  media,
  onVideoSelected,
  onImageSelected,
  onDeleteMedia
}) => {
  const [addVideoUrlModalState, setAddVideoUrlModalState] = useState(false);
  const startAddMediaUrlModal = () => {
    setAddVideoUrlModalState(true);
  };
  const handleVideoUrlSelected = (videoUrl: string) => {
    setAddVideoUrlModalState(false);
    onVideoSelected(videoUrl);
  };

  const [cropModalState, setCropModalState] = useState(emptyCropModalState);
  const [cropError, setCropError] = useState('');
  const onImageCropError = (errorMessage: string) => {
    setCropModalState(emptyCropModalState);
    setCropError(errorMessage);
  };
  const handleCropModalClose = () => {
    setCropModalState(emptyCropModalState);
  };

  const handleImageSelect = (event: ChangeEvent<HTMLInputElement>) => {
    setCropError('');
    const file = event.target?.files[0];
    if (file?.size > allowedFileSizeInBytes) {
      return setCropError(`Image size should be up to ${allowedFileSizeInBytes / Math.pow(2, 20)} mb`);
    }
    const reader = new FileReader();
    reader.onloadend = function () {
      setCropModalState({ open: true, cropperPreview: reader.result });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    event.target.value = null;
  };

  const onCropImage = async (croppedImage: Blob) => {
    setCropModalState(emptyCropModalState);
    onImageSelected(croppedImage);
  };

  return (
    <div>
      <Grid container spacing={0} style={{ marginTop: 15, marginBottom: 15, marginLeft: 0, marginRight: 0 }}>
        <Paper
          style={{
            padding: 15,
            margin: 0,
            borderRadius: 2,
            borderWidth: '1px !important',
            width: 'inherit'
          }}
        >
          <Grid item xs={12}>
            <Typography style={{ fontSize: 20, fontWeight: 600 }}>Media</Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div style={addButtonDivStyle}>
                <input
                  accept={acceptableMimeTypes || 'image/*'}
                  id={'upload-image-btn'}
                  type="file"
                  onChange={handleImageSelect}
                  style={{ display: 'none' }}
                />
                <label htmlFor={'upload-image-btn'}>
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    data-testid={'upload-image-btn'}
                    style={{ ...mediumButtonHeight, width: 190, paddingLeft: 5, paddingRight: 5 }}
                  >
                    Upload Image
                  </Button>
                </label>
              </div>
              {cropError && (
                <Box sx={{ display: 'flex', justifyContent: 'center', color: 'error.main' }}>{cropError}</Box>
              )}
            </Grid>
            <Grid item xs={12} sm={6} style={addButtonDivStyle}>
              <Button
                variant={'contained'}
                style={{ ...mediumButtonHeight, width: 190, paddingLeft: 5, paddingRight: 5 }}
                onClick={startAddMediaUrlModal}
                data-testid={'upload-video-btn'}
              >
                Add YouTube video
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MediaList media={media} onDeleteMedia={onDeleteMedia} />
          </Grid>
        </Paper>
      </Grid>
      {addVideoUrlModalState && (
        <AddMediaUrlModal
          open={addVideoUrlModalState}
          isOperating={false}
          onClose={() => setAddVideoUrlModalState(false)}
          onConfirm={handleVideoUrlSelected}
        />
      )}
      {cropModalState.open && (
        <CropImageModal
          open={cropModalState.open}
          imageHeight={defaultImageHeight}
          imageWidth={defaultImageWidth}
          cropperPreview={cropModalState.cropperPreview}
          onCropFinished={onCropImage}
          onClose={handleCropModalClose}
          onCropError={onImageCropError}
        />
      )}
    </div>
  );
};
