import * as yup from 'yup';
import { LocationType, Messages } from '@efacity/common';

export const locationFormValidationSchema = yup.object({
  name: yup.string().required(Messages.LocationNameRequired),
  type: yup.string().required(Messages.LocationTypeRequired),
  capacity: yup.number().when('type', {
    is: LocationType.InPerson,
    then: yup
      .number()
      .required(Messages.LocationCapacityRequired)
      .test('Capacity', Messages.LocationCapacityInvalid, value => value >= 0),
    otherwise: yup.number().test('Capacity', Messages.LocationCapacityInvalid, value => value >= 0)
  }),
  virtualMeeting: yup.object().when('type', {
    is: LocationType.Virtual,
    then: yup.object().shape({
      link: yup.string(),
      meetingId: yup.string(),
      password: yup.string()
    })
  }),
  addressId: yup
    .string()
    .test(
      'address_id_validation',
      Messages.LocationAddressIdRequired,
      function (this: yup.TestContext, addressId: string): any {
        if (this.parent.type === LocationType.PublicSchool || this.parent.type === LocationType.InPerson) {
          return addressId?.length > 0;
        }
        return true;
      }
    )
});
