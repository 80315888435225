import { IconButton, InputAdornment } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useState } from 'react';
import { FilterProps, Renderer } from 'react-table';
import { monthDayYearDateFormat } from '@efacity/common';
import CloseIcon from '@mui/icons-material/Close';

const DateColumnFilter: Renderer<FilterProps<any>> = ({ column, gotoPage }): React.ReactElement => {
  const { setFilter } = column;
  // store temp value until calendar is closed
  // to prevent requests on every change
  const [tempState, setTempState] = useState(null);
  const handleChange = (date) => {
    setTempState(date);
  };

  const handleDateFilterSelect = () => {
    setFilter(tempState);
    gotoPage(0);
  };

  const handleClearDate = (e) => {
    e.stopPropagation();
    if (tempState) {
      setTempState(null);
      setFilter(null);
      gotoPage(0);
    }
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          value={tempState}
          data-testid="datePicker"
          format={monthDayYearDateFormat}
          onChange={handleChange}
          onClose={handleDateFilterSelect}
          slotProps={{
            textField: {
              variant: 'outlined',
              size: 'small',
              style: { fontSize: '14px' },
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="clear date" size="small" onClick={handleClearDate} edge="end">
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
                style: { height: 30, marginTop: 0, fontSize: 13 }
              }
            }
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DateColumnFilter;
