import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IdOrgIdParamTypes } from '@efacity/frontend-next-shared/utils';
import Table, { EnhancedColumn, PaginationTypes } from '../../../components/Table/Table';
import { useFetch } from '@efacity/frontend-shared';
import { Certificate } from '@efacity/frontend-shared';
import { Box, Button } from '@mui/material';
import AddCircleOutlined from '@mui/icons-material/AddCircleOutlined';
import CertificateInstructorModal from './CertificateInstructorModal';
import { certificateTypesMapper, formatStringToMonthDayYearDate } from '@efacity/common';
import EmailAddress from '../../../components/EmailAddress/EmailAddress';

export interface ActivityCertificatesTableProps {
  activityName: string;
}

const ActivityCertificatesTable: React.FC<ActivityCertificatesTableProps> = ({ activityName }) => {
  const { orgId, id } = useParams<IdOrgIdParamTypes>();
  const fetchDataUrl = `/org/${orgId}/activities/${id}/certificates`;
  const [{ data: activityCertificates, isLoading }, { fetchData }] = useFetch<Certificate[]>(fetchDataUrl, {
    initialDataState: [],
    initialLoadingState: true
  });
  const [certificateInstructorModalShown, setCertificateInstructorModalShown] = useState(false);

  const closeCertificationModal = (reload: boolean) => {
    setCertificateInstructorModalShown(false);
    if (reload) fetchData(fetchDataUrl);
  };

  const columns = useMemo(() => {
    const columns: EnhancedColumn<Certificate>[] = [
      {
        Header: 'Instructor Name',
        accessor: (data) => {
          return data?.user?.fullName || '[n/a]';
        },
        disableFilters: true
      },
      {
        Header: 'Email',
        accessor: (data) => {
          return data?.user?.email ? <EmailAddress email={data.user.email} /> : '[n/a]';
        },
        disableFilters: true
      },
      {
        Header: 'Certificate Type',
        accessor: (data) => {
          return certificateTypesMapper[data?.type];
        },
        Cell: ({ value }) => {
          return value;
        },
        disableFilters: true
      },
      {
        Header: 'Expires On',
        accessor: 'expiresOn',
        disableFilters: true,
        Cell: ({ value: expiresOn }) => <>{formatStringToMonthDayYearDate(expiresOn)}</>
      }
    ];

    return columns;
  }, []);

  const onsStartAddCertificateModal = () => {
    setCertificateInstructorModalShown(true);
  };

  return (
    <div>
      <Box display="flex" justifyContent="end" marginY={2}>
        <Button
          onClick={onsStartAddCertificateModal}
          color="primary"
          variant="contained"
          data-testid="addBtn"
          startIcon={<AddCircleOutlined style={{ color: 'white', marginRight: 15 }} />}
        >
          New Certificate
        </Button>
      </Box>
      <Table
        columns={columns}
        data={activityCertificates}
        loading={isLoading}
        paginationType={PaginationTypes.ClientSide}
        noDataText="No current activity certificates..."
      />
      {certificateInstructorModalShown && (
        <CertificateInstructorModal
          open={certificateInstructorModalShown}
          orgId={orgId}
          activityId={id}
          activityName={activityName}
          closeCertificationModal={closeCertificationModal}
        />
      )}
    </div>
  );
};

export default ActivityCertificatesTable;
