import {
  CurrencyCode,
  formatSessionWeekDays,
  getEndTime,
  moneyWithCurrencyCodeFormatter,
  formatDateToMonthDayYearInLocale,
  formatTimeToHhMmNonBreakableString
} from '@efacity/common';
import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import mobileTableStyle from '../../../stylesheets/mobileTableStyle';
import { RegistrationInvoiceItemTableRowProps } from './RegistrationInvoiceItemTableRow';
import DeleteIconButtonWithSpinner from '../../../components/DeleteIconButtonWithSpinner/DeleteIconButtonWithSpinner';

const RegistrationInvoiceItemMobileTableRow: FC<RegistrationInvoiceItemTableRowProps> = ({
  invoiceItem,
  handleDeleteRegistration,
  index,
  isUpdating,
  disabled,
  hasAdminRole,
  onStartAdjustmentModal
}) => {
  return (
    <Box sx={mobileTableStyle.rootSx}>
      <Box style={{ ...mobileTableStyle.sessionNameRow, borderBottom: '1px solid #C4C4C4' }}>
        <Box>
          <Typography sx={mobileTableStyle.titleSx}>Session</Typography>
          <Box fontWeight="bold" sx={mobileTableStyle.titleSx}>
            {invoiceItem.sessionName}
          </Box>
        </Box>
        <Box style={mobileTableStyle.deleteIcon}>
          <DeleteIconButtonWithSpinner
            onClick={() =>
              handleDeleteRegistration(invoiceItem.registrationId, invoiceItem.sessionId, invoiceItem.studentId)
            }
            isUpdating={isUpdating}
            data-testid={`deleteIcon${index}Mobile`}
            disabled={disabled}
          />
        </Box>
      </Box>
      <Box style={mobileTableStyle.invoiceItemRow}>
        <Box>
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Student:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>{invoiceItem.studentName}</Typography>
        </Box>
        <Box>
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Start Date:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>
            {invoiceItem?.startDate ? formatDateToMonthDayYearInLocale(invoiceItem.startDate) : '-'}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Duration:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>{invoiceItem.sessionDuration || '-'}</Typography>
        </Box>
      </Box>

      <Box style={mobileTableStyle.invoiceItemRow}>
        <Box>
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Time:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>
            {invoiceItem?.startDate
              ? `${formatTimeToHhMmNonBreakableString(invoiceItem.startDate)} - ${getEndTime(
                  invoiceItem.instanceDuration,
                  invoiceItem.startDate
                )}`
              : '-'}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Week Days:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>{formatSessionWeekDays(invoiceItem.weekDays)}</Typography>
        </Box>
        <Box>
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Amount:
          </Typography>
          {hasAdminRole ? (
            <Box
              sx={mobileTableStyle.linkCellSx}
              onClick={() => onStartAdjustmentModal(invoiceItem)}
              data-testid="edit-amount-link"
            >
              {moneyWithCurrencyCodeFormatter(invoiceItem.amount, invoiceItem.currencyCode as CurrencyCode)}
            </Box>
          ) : (
            moneyWithCurrencyCodeFormatter(invoiceItem.amount, invoiceItem.currencyCode as CurrencyCode)
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RegistrationInvoiceItemMobileTableRow;
