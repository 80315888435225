import { Box, Dialog, DialogContent, DialogProps, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { CircularProgressButton } from '@efacity/frontend-shared';
import { ModalDialogTitle } from '@efacity/react-next-sc';

export interface CancelActions {
  setCancellationReason: Dispatch<SetStateAction<string>>;
  setValidationError: Dispatch<SetStateAction<string>>;
}

export interface CancellationModalProps {
  isLoading: boolean;
  titleText: string;
  message?;
  submitCallback: (cancellationReason: string, submitActions: CancelActions) => void;
  onClose: () => void;
}

const CancellationModal: React.FC<DialogProps & CancellationModalProps> = ({
  submitCallback,
  message = null,
  onClose,
  open,
  isLoading,
  titleText
}) => {
  const [cancellationReason, setCancellationReason] = useState('');
  const [validationError, setValidationError] = useState(null);

  const onSubmitCancellationReason = () => {
    submitCallback(cancellationReason, { setCancellationReason, setValidationError });
  };

  const handleClose = (e?: any, reason?: 'backdropClick' | 'escapeKeyDown') => {
    setCancellationReason('');
    setValidationError(null);
    onClose(e, reason);
  };

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open} onClose={handleClose}>
      <ModalDialogTitle text={titleText} onClose={onClose} />
      <DialogContent>
        {message && message}
        <TextField
          autoFocus
          value={cancellationReason}
          onChange={(e) => setCancellationReason(e.target.value)}
          variant="outlined"
          size="small"
          name="cancellationReason"
          type="text"
          multiline
          maxRows={4}
          fullWidth
          error={!!validationError}
          helperText={validationError}
        />

        <Box display="flex" justifyContent="center" style={{ marginTop: 20, marginBottom: 10 }}>
          <CircularProgressButton
            onClick={onSubmitCancellationReason}
            color="primary"
            variant="contained"
            disabled={cancellationReason.length === 0}
            progress={isLoading}
            style={{ minWidth: 85 }}
            data-testid="cancel-submit-btn"
          >
            Submit
          </CircularProgressButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CancellationModal;
