import { Note, formatDateToTime, formatDateToMonthDayYear } from '@efacity/common';
import {
  smallTableCellSx,
  smallTableContainerSx,
  smallTableHeadCellSx,
  smallTableHeadSx,
  smallTableRowSx
} from '@efacity/react-next-sc';
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { FC } from 'react';

export interface NotesTableProps {
  notes: Note[];
  isLoading: boolean;
}

const NotesTable: FC<NotesTableProps> = ({ notes, isLoading }) => {
  return (
    <TableContainer sx={smallTableContainerSx}>
      <Table size="small" aria-label="a simple dense table">
        <TableHead sx={smallTableHeadSx}>
          <TableRow sx={smallTableRowSx}>
            <TableCell sx={smallTableHeadCellSx}>Note</TableCell>
            <TableCell sx={smallTableHeadCellSx}>Added&nbsp;by</TableCell>
            <TableCell sx={smallTableHeadCellSx} align="left">
              Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow sx={smallTableRowSx}>
              <TableCell align="center" colSpan={3}>
                <LinearProgress variant="indeterminate" color="primary" data-testid="loadingIndicator" />
              </TableCell>
            </TableRow>
          ) : (
            notes.map((note) => (
              <TableRow sx={smallTableRowSx} key={`notesTableRowForNote${note._id}`}>
                <TableCell sx={smallTableCellSx} align="left">
                  <Typography>{note.content}</Typography>
                </TableCell>
                <TableCell sx={smallTableCellSx} align="left">
                  {note.createdBy?.fullName || '[n/a]'}
                </TableCell>
                <TableCell sx={smallTableCellSx} align="left">
                  {`${formatDateToMonthDayYear(note.createdOn)}, ${formatDateToTime(note.createdOn)}`}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NotesTable;
