import React from 'react';

export default function ConsecutiveIcon() {
  return (
    <svg
      width="130"
      height="120"
      viewBox="0 0 165 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <circle opacity="0.5" cx="88.4643" cy="57.2857" r="84.4143" fill="white" />
        <circle opacity="0.5" cx="87.9819" cy="57.7681" r="68.4962" fill="white" />
        <circle opacity="0.5" cx="90.3937" cy="57.2858" r="54.5075" fill="white" />
        <rect x="63" y="35.5714" width="35.7143" height="32.1429" rx="10" fill="white" />
        <path
          d="M94.529 34.7902H92.8549V32H90.0647V34.7902H71.6496V32H68.8594V34.7902H67.1853C64.8774 34.7902 63 36.6676 63 38.9754V63.529C63 65.8369 64.8774 67.7143 67.1853 67.7143H94.529C96.8369 67.7143 98.7143 65.8369 98.7143 63.529V38.9754C98.7143 36.6676 96.8369 34.7902 94.529 34.7902ZM67.1853 37.5804H68.8594V40.3705H71.6496V37.5804H90.0647V40.3705H92.8549V37.5804H94.529C95.2982 37.5804 95.9241 38.2062 95.9241 38.9754V42.3237H65.7902V38.9754C65.7902 38.2062 66.4161 37.5804 67.1853 37.5804ZM94.529 64.9241H67.1853C66.4161 64.9241 65.7902 64.2982 65.7902 63.529V45.1138H95.9241V63.529C95.9241 64.2982 95.2982 64.9241 94.529 64.9241ZM85.0424 61.9944H93.4129V53.6239H85.0424V61.9944ZM87.8326 56.4141H90.6228V59.2042H87.8326V56.4141Z"
          fill="#E19426"
        />
        <rect x="70.1429" y="42.7143" width="35.7143" height="32.1429" rx="10" fill="white" />
        <path
          d="M101.672 41.9331H99.9978V39.1429H97.2076V41.9331H78.7924V39.1429H76.0022V41.9331H74.3281C72.0202 41.9331 70.1429 43.8104 70.1429 46.1183V70.6719C70.1429 72.9798 72.0202 74.8572 74.3281 74.8572H101.672C103.98 74.8572 105.857 72.9798 105.857 70.6719V46.1183C105.857 43.8104 103.98 41.9331 101.672 41.9331ZM74.3281 44.7232H76.0022V47.5134H78.7924V44.7232H97.2076V47.5134H99.9978V44.7232H101.672C102.441 44.7232 103.067 45.3491 103.067 46.1183V49.4665H72.933V46.1183C72.933 45.3491 73.5589 44.7232 74.3281 44.7232ZM101.672 72.067H74.3281C73.5589 72.067 72.933 71.4411 72.933 70.6719V52.2567H103.067V70.6719C103.067 71.4411 102.441 72.067 101.672 72.067ZM92.1853 69.1373H100.556V60.7668H92.1853V69.1373ZM94.9754 63.5569H97.7656V66.3471H94.9754V63.5569Z"
          fill="#E19426"
        />
        <rect x="77.2858" y="49.8571" width="35.7143" height="32.1429" rx="10" fill="white" />
        <path
          d="M108.815 49.0759H107.141V46.2857H104.351V49.0759H85.9354V46.2857H83.1452V49.0759H81.4711C79.1632 49.0759 77.2858 50.9533 77.2858 53.2612V77.8147C77.2858 80.1226 79.1632 82 81.4711 82H108.815C111.123 82 113 80.1226 113 77.8147V53.2612C113 50.9533 111.123 49.0759 108.815 49.0759ZM81.4711 51.8661H83.1452V54.6562H85.9354V51.8661H104.351V54.6562H107.141V51.8661H108.815C109.584 51.8661 110.21 52.4919 110.21 53.2612V56.6094H80.076V53.2612C80.076 52.4919 80.7019 51.8661 81.4711 51.8661ZM108.815 79.2098H81.4711C80.7019 79.2098 80.076 78.5839 80.076 77.8147V59.3995H110.21V77.8147C110.21 78.5839 109.584 79.2098 108.815 79.2098ZM99.3282 76.2801H107.699V67.9096H99.3282V76.2801ZM102.118 70.6998H104.909V73.4899H102.118V70.6998Z"
          fill="#E19426"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.0500488"
          y="-27.1285"
          width="176.829"
          height="176.829"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.835294 0 0 0 0 0.929412 0 0 0 0 0.929412 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
