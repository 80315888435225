import { Dialog, DialogContent, Rating } from '@mui/material';
import React from 'react';
import { CustomerRegistration } from '@efacity/common';
import { ModalDialogTitle } from '@efacity/react-next-sc';

interface ReviewModalProps {
  isOpen: boolean;
  registration: CustomerRegistration;
  onClose: () => void;
}

const ReviewModal = ({ isOpen, registration, onClose }: ReviewModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <ModalDialogTitle text={`Your review for ${registration?.sessionName}`} onClose={onClose} />
      <DialogContent>
        <Rating readOnly value={registration?.reviewStars} size="large" />
        <p>{registration?.reviewComment}</p>
      </DialogContent>
    </Dialog>
  );
};

export default ReviewModal;
