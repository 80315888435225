import React, { FC, useEffect, useState } from 'react';
import {
  CountryCode,
  generateCompleteAddress,
  generateGoogleMapsLinkForAddress,
  getCountryByCode
} from '@efacity/common';
import { CountrySelector, StateSelector, FormTextInput } from '@efacity/react-hook-form-mui';
import { getNestedFormProperty } from '@efacity/frontend-shared';
import { useFormContext } from 'react-hook-form';
export interface AddressFormValues {
  _id?: string;
  country: string;
  address: string;
  city: string;
  state: string;
  zip?: string;
  mapLink?: string;
}

const defaultAddressFormLabels = {
  country: 'Country',
  address: 'Address',
  city: 'City',
  state: 'State',
  zip: 'Zip',
  mapLink: 'Map Link'
};

interface AddressFormProps {
  pathToFormObject?: string;
  country?: CountryCode;
}

export const AddressForm: FC<AddressFormProps> = ({ country, pathToFormObject = '' }) => {
  const [labels, setLabels] = useState(
    country ? { ...defaultAddressFormLabels, ...getCountryByCode(country).formTitles } : defaultAddressFormLabels
  );

  useEffect(() => {
    if (country) {
      setLabels(
        country ? { ...defaultAddressFormLabels, ...getCountryByCode(country).formTitles } : defaultAddressFormLabels
      );

      methods.setValue(getNestedFormProperty('country', pathToFormObject), country);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [country, pathToFormObject]);

  const methods = useFormContext();

  const onCountryChange = (name, event) => {
    methods.setValue(getNestedFormProperty('country', pathToFormObject), event.target.value);
    setLabels({ ...defaultAddressFormLabels, ...getCountryByCode(event.target.value as CountryCode).formTitles });
  };

  const setMapLinkByAddress = (values) => {
    methods.setValue(
      getNestedFormProperty('mapLink', pathToFormObject),
      generateGoogleMapsLinkForAddress(generateCompleteAddress(values))
    );
  };

  return (
    <>
      {!country && (
        <CountrySelector
          name={getNestedFormProperty('country', pathToFormObject)}
          onValueChange={onCountryChange}
          label={labels.country}
        />
      )}
      <FormTextInput
        name={getNestedFormProperty('address', pathToFormObject)}
        label={labels.address}
        required
        placeholder="Street, PO Box, or company name"
        data-testid="addressFormAddress"
      />
      <FormTextInput
        name={getNestedFormProperty('city', pathToFormObject)}
        label={labels.city}
        required
        data-testid="addressFormCity"
      />

      {country !== CountryCode.UK && (
        <StateSelector
          name={getNestedFormProperty('state', pathToFormObject)}
          label={labels.state}
          required
          countryCode={(country || methods.watch(getNestedFormProperty('country', pathToFormObject))) as CountryCode}
        />
      )}

      <FormTextInput
        name={getNestedFormProperty('zip', pathToFormObject)}
        label={labels.zip}
        required
        data-testid="addressFormZip"
      />

      <FormTextInput
        name={getNestedFormProperty('mapLink', pathToFormObject)}
        multiline
        minRows={4}
        label={labels.mapLink}
        data-testid="addressFormMapLink"
        onFocus={(e) => {
          if (methods.watch(getNestedFormProperty('address', pathToFormObject))?.length) {
            const values = pathToFormObject ? methods.getValues(pathToFormObject) : methods.getValues();
            setMapLinkByAddress(values);
          }
        }}
      />
    </>
  );
};
