import { Product } from '@efacity/frontend-shared';
import {
  AgeStudentLevelTypeValue,
  CurrencyCode,
  emptyRichEdit,
  Grades,
  isGradeNumericValue,
  mapSchoolGradeToAge,
  Media,
  Messages,
  nonNegativeAmountValidator,
  richEditContainsText,
  SchoolGradeStudentLevelType,
  SchoolGradeStudentLevelTypeValue,
  StudentLevel,
  ValidationErrors
} from '@efacity/common';
import * as yup from 'yup';
import { isValidStudentLevelType } from '../SessionsContainer/SessionMainInfoForm/sessionMainInfoValidator';
import { Descendant } from 'slate';

export class ProductFormValues implements Partial<Product> {
  media: Media[];
  name: string;
  description: Descendant[];
  price: number;
  currencyCode: CurrencyCode;
  recommendedFor: StudentLevel;
  inventoryQuantity: number;
}

export type ProductPostValues = Omit<ProductFormValues, 'description'> & { description: string };

export class KitBackendValues implements Partial<Product> {
  media: string[];
  name: string;
  description: string;
  price: number;
  currencyCode: CurrencyCode;
  recommendedFor: StudentLevel;
  inventoryQuantity: number;
}

export const kitInitialFormValues: ProductFormValues = {
  media: [],
  name: '',
  description: emptyRichEdit,
  price: 0,
  currencyCode: CurrencyCode.USD,
  recommendedFor: {
    type: SchoolGradeStudentLevelType.value,
    fromSchoolGradeNumeric: Grades.PRE_K.sortValue,
    toSchoolGradeNumeric: Grades.GRADE_ADULT.sortValue,
    fromAge: mapSchoolGradeToAge(Grades.PRE_K.sortValue),
    toAge: mapSchoolGradeToAge(Grades.GRADE_ADULT.sortValue)
  },
  inventoryQuantity: 0
};

export const kitFormValidationSchema = yup.object().shape({
  name: yup.string().required(Messages.NameRequired),
  recommendedFor: yup.object().shape({
    type: yup.string().test('type', ValidationErrors.InvalidStudentLevelType, isValidStudentLevelType),
    fromSchoolGradeNumeric: yup
      .mixed()
      .test('fromSchoolGradeNumeric', null, function (this: yup.TestContext, fromSchoolGradeNumeric: any): any {
        if (this.parent?.type && this.parent.type === SchoolGradeStudentLevelTypeValue) {
          if (isGradeNumericValue(fromSchoolGradeNumeric)) {
            return true;
          }
          return this.createError({
            path: 'recommendedFor.fromSchoolGradeNumeric',
            message: ValidationErrors.InvalidGrade
          });
        }
        return true;
      }),
    toSchoolGradeNumeric: yup
      .mixed()
      .test('toSchoolGradeNumeric', null, function (this: yup.TestContext, toSchoolGradeNumeric: any): any {
        if (this.parent?.type && this.parent.type === SchoolGradeStudentLevelTypeValue) {
          if (isGradeNumericValue(toSchoolGradeNumeric)) {
            return true;
          }
          return this.createError({
            path: 'recommendedFor.toSchoolGradeNumeric',
            message: ValidationErrors.InvalidGrade
          });
        }
        return true;
      }),
    fromAge: yup.mixed().test('fromAge', null, function (this: yup.TestContext, fromAge: any): any {
      if (this.parent?.type && this.parent.type === AgeStudentLevelTypeValue) {
        if (fromAge > 0) {
          return true;
        }
        return this.createError({
          path: 'recommendedFor.fromAge',
          message: ValidationErrors.InvalidAge
        });
      }
      return true;
    }),
    toAge: yup.mixed().test('toAge', null, function (this: yup.TestContext, toAge: any): any {
      if (this.parent?.type && this.parent.type === AgeStudentLevelTypeValue) {
        if (toAge > 0) {
          return true;
        }
        return this.createError({
          path: 'recommendedFor.toAge',
          message: ValidationErrors.InvalidAge
        });
      }
      return true;
    })
  }),
  price: yup.string().test('test-name', 'Non-negative real expected', nonNegativeAmountValidator),
  currencyCode: yup.string().required(Messages.CurrencyRequired),
  inventoryQuantity: yup.number().min(0, Messages.QuantityShouldBePositive).required(Messages.QuantityRequired),
  description: yup.array().test('description-test', null, function (this: yup.TestContext, description): any {
    if (richEditContainsText(description)) {
      return true;
    }
    return this.createError({
      path: 'description',
      message: Messages.DescriptionRequired
    });
  })
});
