import { CustomerRegistration, displayMdUpSx, displaySmDownSx, Messages } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { apiService, useFetch } from '@efacity/frontend-shared';
import { PageTitle, useAuth } from '@efacity/react-next-sc';
import { PATHS } from '@efacity/routing';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import CancellationModal, { CancellationModalProps } from '../SessionsContainer/CancellationModal';
import CustomerRegistrationsDesktopTable from './Registrations/CustomerRegistrationsDesktopTable';
import CustomerRegistrationsMobileTable from './Registrations/CustomerRegistrationsMobileTable';

const CustomerRegistrationsContainer: React.FC = () => {
  const [{ data: registrations, isLoading }, { fetchData }] = useFetch<CustomerRegistration[]>('/registrations', {
    initialDataState: [],
    errorMessage: 'Cannot get registrations info'
  });
  const {
    authState: {
      extraAuthInfo: { startRegistrationURL }
    }
  } = useAuth();
  const [isCancelling, setRegistrationCancelling] = useState(false);
  const snackbarProps = useSnackbar();
  const [registrationToCancel, setRegistrationToCancel] = useState(null);

  const handleOpenCancellationModal = async (registrationId: string) => {
    setRegistrationToCancel(registrationId);
  };

  const onClose = () => {
    setRegistrationToCancel(null);
  };

  const handleCancelRegistration: CancellationModalProps['submitCallback'] = async (
    cancellationReason,
    submitActions
  ) => {
    setRegistrationCancelling(true);
    try {
      const { data } = await apiService.patch<{ message: string }>(`/registrations/${registrationToCancel}/cancel`, {
        cancellationReason
      });
      showNotification(snackbarProps, true, data.message, false);
      await fetchData('/registrations');
      setRegistrationToCancel(null);
    } catch (error) {
      if (error.response?.data?.validationErrors) {
        return submitActions.setValidationError(error.response?.data?.validationErrors.cancellationReason);
      }
      showNotification(
        snackbarProps,
        false,
        (error.response.data.message as string) || Messages.FailedCancelRegistration,
        true
      );
    } finally {
      setRegistrationCancelling(false);
    }
  };

  return (
    <div>
      <PageTitle title="Registrations" addButtonLink={startRegistrationURL ? startRegistrationURL : PATHS.landing} />
      <Box sx={displayMdUpSx}>
        <CustomerRegistrationsDesktopTable
          registrations={registrations}
          isLoading={isLoading}
          handleCancelRegistration={handleOpenCancellationModal}
          isCancelling={isCancelling}
        />
      </Box>
      <Box sx={displaySmDownSx}>
        <CustomerRegistrationsMobileTable
          registrations={registrations}
          isLoading={isLoading}
          handleCancelRegistration={handleOpenCancellationModal}
          isCancelling={isCancelling}
        />
      </Box>
      {!!registrationToCancel && (
        <CancellationModal
          open={!!registrationToCancel}
          isLoading={isCancelling}
          titleText="Registration cancellation"
          submitCallback={handleCancelRegistration}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export default CustomerRegistrationsContainer;
