import React, { useMemo } from 'react';
import { EnhancedColumn } from '../../components/Table/Table';
import {
  convertUtcToZonedTime,
  getInvoiceFileName,
  getPaymentReceiptFileName,
  InvoiceBalance,
  moneyWithCurrencyCodeFormatter
} from '@efacity/common';
import DateCell from '../../components/DateCell';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import DateColumnFilter from '../../components/Table/filters/DateColumnFilter';
import { IconButton, Link, Tooltip, useTheme } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const marginTopCellStyles = { verticalAlign: 'top', paddingTop: 10 };

export const usePaymentsColumns = (orgId: string, timeZone: string) => {
  const theme = useTheme();

  const paymentStatusColor = (isPaid: boolean) => {
    return isPaid ? theme.palette.primary.main : theme.palette.error.main;
  };
  const payoutColor = (amount: number) => {
    return amount < 0 ? theme.palette.secondary.dark : '';
  };

  return useMemo(() => {
    const columns: EnhancedColumn<InvoiceBalance>[] = [
      {
        Header: 'Invoice #',
        accessor: 'invoiceNo',
        disableFilters: false,
        columnStyles: marginTopCellStyles,
        Cell: ({ value }) => <div>{value}</div>
      },
      {
        Header: 'Customer',
        id: 'customer.fullName',
        accessor: (data) => {
          return data.customer.fullName;
        },
        disableFilters: false,
        columnStyles: marginTopCellStyles,
        Cell: ({ value }) => <div>{value}</div>
      },
      {
        Header: 'Date',
        accessor: 'invoiceDate',
        disableFilters: false,
        columnStyles: marginTopCellStyles,
        Cell: ({ value }) => (
          <div>
            {value ? (
              <DateCell
                dateTime={convertUtcToZonedTime(value, timeZone)}
                yearOnNewLine={false}
                wrap={true}
                style={{ ...adminSectionTableFontSize }}
              />
            ) : (
              '[n/a]'
            )}
          </div>
        ),
        Filter: DateColumnFilter
      },
      {
        Header: 'Balance',
        accessor: 'isPaid',
        disableFilters: true,
        headerStyles: { textAlign: 'right', paddingRight: 15 },
        columnStyles: { ...marginTopCellStyles, textAlign: 'right', width: 115, maxWidth: 115 },
        Cell: ({ value, row }) => {
          const onExpandClick = () => {
            row.setState((props) => ({ ...props, showBalance: true }));
            (row.getToggleRowExpandedProps() as any).onClick();
          };
          return (
            <div style={{ color: `${paymentStatusColor(value)}` }}>
              {moneyWithCurrencyCodeFormatter(row.original?.balance || 0, row.original?.currencyCode)}
              <span {...row.getToggleRowExpandedProps()} onClick={onExpandClick}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  data-testid="collapseButton"
                  style={{ marginTop: -10 }}
                >
                  {row.isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </span>
            </div>
          );
        }
      },
      {
        Header: 'Payout',
        id: 'payoutsAmount',
        accessor: 'payoutsAmount',
        disableFilters: true,
        headerStyles: { textAlign: 'right', paddingRight: 15 },
        columnStyles: { ...marginTopCellStyles, textAlign: 'right', width: 115, maxWidth: 115 },
        Cell: ({ value, row }) => {
          const onExpandClick = () => {
            row.setState((props) => ({ ...props, showBalance: false }));
            (row.getToggleRowExpandedProps() as any).onClick();
          };

          return (
            <div style={{ color: payoutColor(-row?.original?.ownerPayoutsAmount || 0) }}>
              {moneyWithCurrencyCodeFormatter(row?.original?.ownerPayoutsAmount || 0, row.original?.currencyCode)}
              <span {...row.getToggleRowExpandedProps()} onClick={onExpandClick}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  data-testid="collapseButton"
                  style={{ marginTop: -10 }}
                >
                  {row.isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </span>
            </div>
          );
        }
      },
      {
        Header: 'Invoice',
        disableFilters: true,
        columnStyles: { ...marginTopCellStyles, width: 65, maxWidth: 65 },
        Cell: ({ value, row }) => {
          return (
            <div>
              {row.original ? (
                row.original?.isPaid ? (
                  <Tooltip title="Download Payment Receipt">
                    <Link href={getPaymentReceiptFileName(row.original?._id)}>Receipt</Link>
                  </Tooltip>
                ) : (
                  <Tooltip title="Download Invoice">
                    <Link href={getInvoiceFileName(row.original?._id)}>Invoice</Link>
                  </Tooltip>
                )
              ) : (
                ''
              )}
            </div>
          );
        }
      }
    ];

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, timeZone]);
};
