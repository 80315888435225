import { createTheme } from '@mui/material/styles';
import { primaryColor, secondaryColor } from './colorPalette';

const customTheme = createTheme({
  palette: {
    primary: primaryColor,
    secondary: secondaryColor,
    error: {
      main: '#f44336'
    },
    grey: {
      '600': '#666666',
      '700': '#777777'
    }
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    body1: {
      color: '#34495e',
      fontSize: '1rem'
    },
    body2: {
      color: '#34495E',
      fontSize: '1rem'
    }
  },
  shape: {
    borderRadius: 2
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '14px'
        }
      }
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#F5F6FA',
          boxShadow: '5px 4px 4px rgba(0, 0, 0, 0.1)',
          color: '#fff'
        },
        paperAnchorDockedLeft: {
          borderRight: 0
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          height: 50,
          fontSize: 20
          /*[breakpoints.down('sm')]: {
            fontSize: 16
          }*/
        },
        root: {
          borderRadius: '2px'
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none'
          }
        },
        outlined: {
          borderWidth: 1
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: '2px'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#3365A7'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
          borderWidth: '1px !important'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
          '& fieldset': {
            borderRadius: '2px',
            borderWidth: '1px !important'
          }
        }
      }
    },
    MuiListItemText: {},
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          marginRight: 0,
          '& svg': {
            fontSize: 20
          }
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto, sans-serif'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          fontWeight: 'bold',
          fontSize: 18,
          color: '#666666'
        }
      }
    }
  },
  mixins: {
    toolbar: {
      minHeight: 48
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1600
    }
  }
});
export { customTheme };
