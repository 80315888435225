import React, { FC, ReactElement } from 'react';
import { Button, Tooltip } from '@mui/material';
import { ElementTypes, FormatTypes } from '../slate-rich-text-editor';

export interface EditorButtonProps {
  format: ElementTypes | FormatTypes;
  hint: string;
  icon: ReactElement;
  isSelected: boolean;
  onClick: (event: any) => void;
}

const EditorButton: FC<EditorButtonProps> = ({ format, hint, icon, isSelected, onClick }) => {
  return (
    <Tooltip title={hint}>
      <Button
        size="small"
        style={{
          minWidth: 34,
          borderRadius: 0,
          borderBottom: isSelected ? '2px solid gray' : '2px solid transparent'
        }}
        onMouseDown={(event) => {
          event.preventDefault();
          onClick(format);
        }}
      >
        {icon}
      </Button>
    </Tooltip>
  );
};

export default EditorButton;
