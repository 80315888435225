import React from 'react';

const headerIconStyle = {
  position: 'absolute',
  top: '-12px',
  right: '-12px'
} as React.CSSProperties;

export default function HeaderCircleIcon({ icon }) {
  return <div style={headerIconStyle}>{icon}</div>;
}
