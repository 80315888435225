import { IconButton, Typography } from '@mui/material';
import React, { FC } from 'react';
import { smallPublishButtonStyle } from '../../../components/Buttons/SessionPublishButton';
import DeleteIcon from '@mui/icons-material/DeleteForever';

export interface CenterNameWithDeleteButtonProps {
  userId: string;
  userFullName: string;
  centerId: string;
  centerName: string;
  tableState: any;
  onDeleteCenterAdminCLick;
}

const CenterNameWithDeleteButton: FC<CenterNameWithDeleteButtonProps> = ({
  userId,
  userFullName,
  centerId,
  centerName,
  tableState,
  onDeleteCenterAdminCLick
}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography color="primary" key={`centerIdKey${centerId}`}>
        {centerName}
      </Typography>
      <div>
        <IconButton
          color="primary"
          aria-label="delete-instructor"
          data-testid="delete-admin-btn"
          component="span"
          style={smallPublishButtonStyle}
          onClick={() => onDeleteCenterAdminCLick(centerId, userId, userFullName, tableState)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default CenterNameWithDeleteButton;
