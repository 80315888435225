import { compile } from 'path-to-regexp';

export enum PATHS {
  landing = '/',
  about = '/about',
  checkEmail = '/check-email',
  instructorRegistration = '/instructor-registration/:orgId',
  classes = '/classes',
  auth = '/auth',
  signIn = 'sign-in',
  signUp = 'sign-up',
  resetPassword = 'reset-password',
  emailValidation = 'email-validation',
  organizationRegistrationDone = '/org/registration-done',
  orgLanding = '/org/:orgId',
  enrollment = '/org/:orgId/enrollment',
  selectPublicSchool = '/org/:orgId/enrichment/:activityId',
  enrichmentRegistration = '/org/:orgId/enrichment/:activityId/registration',
  enrichmentDone = '/org/:orgId/enrichment/:activityId/done',
  orgActivityWithSessions = '/org/:orgId/activities/:activityType/:activityId',
  sessionRegistration = '/registration/session/:sessionId',
  instructorCertification = '/instructor-certification',
  instructorCertificationInOrganization = '/org/:orgId/instructor-certification',
  publicAttendance = '/org/:orgId/attendance/:hash',
  publicProductDescription = '/org/:orgId/products/:productId',
  productsLanding = '/products',
  jobPostingsList = '/org/:orgId/jobs',
  jobPostingDescription = '/org/:orgId/job/:jobId',
  jobPostingApplication = '/org/:orgId/job/:jobId/apply',
  registrationsForAdmin = '/admin/:orgId/registrations',
  siteUsers = '/admin/users',
  siteAdminAddUser = '/admin/user',
  siteAdminEditUser = '/admin/user/:userId',
  organizationStudents = '/admin/:orgId/students',
  studentEnrollments = '/admin/:orgId/enrollments',
  studentEnrollment = '/admin/:orgId/enrollment/:enrollmentId',
  organizationCustomers = '/admin/:orgId/customers',
  schools = '/admin/schools',
  sessions = '/admin/:orgId/sessions',
  sessionsCurrent = '/admin/:orgId/sessions/current',
  addSchool = '/admin/school',
  editSchool = '/admin/schools/:id',
  session = '/admin/:orgId/session/:id',
  addSession = '/admin/:orgId/session',
  sessionStudents = '/admin/:orgId/session/:sessionId/students',
  invoice = '/admin/:orgId/invoice/:invoiceId',
  paymentConfirmation = '/payment-confirmation',
  connectConfirmation = '/connect-confirmation',
  connectToPaymentPlatformConfirmation = '/connect-platform-confirmation',
  connectSchoolToEfacityConfirmation = '/connect-efacity-confirmation',
  invoiceAdditionalOptions = '/admin/:orgId/invoice/:invoiceId/additional-options',
  classroomsImport = '/sessions',
  studentSessions = '/student/:id/sessions',
  editActivity = '/admin/:orgId/activity/:id',
  addActivity = '/admin/:orgId/activity',
  activities = '/admin/:orgId/activities',
  paymentPlatforms = '/admin/payment-platforms/accounts',
  addPaymentPlatform = '/admin/payment-platform/account',
  editPaymentPlatform = '/admin/payment-platform/account/:id',
  editDiscount = '/admin/:orgId/discount/:id',
  addDiscount = '/admin/:orgId/discount',
  discounts = '/admin/:orgId/discounts',
  instructors = '/admin/:orgId/instructors',
  addInstructor = '/admin/:orgId/instructor',
  editInstructor = '/admin/:orgId/instructor/:id',
  organizationCredits = '/admin/:orgId/credits',
  centers = '/admin/:orgId/centers',
  addCenter = '/admin/:orgId/center',
  editCenter = '/admin/:orgId/centers/:id',
  organizationSettings = '/admin/:orgId/settings',
  additionalOptions = '/admin/:orgId/sessions/additionalOptions',
  additionalOptionsCurrent = '/admin/:orgId/sessions/additionalOptions/current',
  additionalOptionsExpired = '/admin/:orgId/sessions/additionalOptions/expired',
  addAdditionalOption = '/admin/:orgId/additionalOption',
  editAdditionalOption = '/admin/:orgId/additionalOption/:additionalOptionId',
  products = '/admin/:orgId/products',
  addProduct = '/admin/:orgId/product',
  editProduct = '/admin/:orgId/product/:productId',
  productPurchases = '/admin/:orgId/product-purchases',
  jobs = '/admin/:orgId/jobs',
  addJob = '/admin/:orgId/job',
  editJob = '/admin/:orgId/job/:jobId',
  messages = '/admin/:orgId/messages',
  tags = '/admin/:orgId/sessions/tags',
  tagsCurrent = '/admin/:orgId/sessions/tags/current',
  tagsExpired = '/admin/:orgId/sessions/tags/expired',
  addTag = '/admin/:orgId/tag',
  editTag = '/admin/:orgId/tag/:tagId',
  addLocation = '/admin/:orgId/settings/location',
  editLocation = '/admin/:orgId/settings/location/:locationId',
  locations = '/admin/:orgId/settings/locations',
  attendance = '/admin/:orgId/attendance',
  certificates = '/admin/:orgId/certificates',
  payments = '/admin/:orgId/payments',
  registrations = '/registrations',
  customerPurchases = '/purchases',
  customerCredits = '/credits',
  userSettings = '/settings',
  editStudent = '/students/:studentId',
  addStudent = '/student',
  students = '/students',
  selectStudentsForSessions = '/select-student',
  cart = '/cart',
  selectAdditionalOptionsByCustomer = '/select-additional-options',
  payment = '/payment/:invoiceId',
  notFound = '/not-found',
  loginAudit = '/admin/login-audit',
  userCertificates = '/certificates',
  reviews = '/admin/reviews',
  reviewSession = '/review/session/:sessionId/:registrationId',
  courses = '/admin/:orgId/courses',
  usersEnrollmentApplications = '/enrollment-applications',
  customerStudentAgreements = '/customer/student/:studentId/agreements'
}

export const NEXT_JS_PATHS = [
  PATHS.about,
  '/org',
  '/registration/session',
  PATHS.productsLanding,
  PATHS.publicProductDescription,
  PATHS.instructorCertification
];

const isNextJsRoute = (path: string): boolean => {
  // In tests there is no window.location.pathname so path would be undefined
  if (!path) {
    return false;
  }

  if (path === PATHS.landing) {
    return true;
  }

  return !!NEXT_JS_PATHS.find((landingPath: string) => path.startsWith(landingPath));
};

export const shouldUseReactRouter = (toPath: string) => {
  const isCurrentLocationFromReact = !isNextJsRoute(window.location.pathname);
  const isToLocationFromReact = !isNextJsRoute(toPath);
  return isCurrentLocationFromReact === isToLocationFromReact;
};

/**
 * @param path such as '/user/:id.
 * @param params such as {id: 1}
 * @returns string such as '/user/1'
 */
export const toPath = (path: string, params = {}) => compile(path)(params);
