import React, { FC, useEffect } from 'react';
import { Dialog, DialogContent, DialogProps, Typography } from '@mui/material';
import { apiService, useFetch } from '@efacity/frontend-shared';
import { FormMode, Messages } from '@efacity/common';
import AgreementForm from './AgreementForm';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { AgreementBackendValues, AgreementFormValues } from './agreementFormValues';
import { useSnackbar } from 'notistack';
import { ModalDialogTitle } from '@efacity/react-next-sc';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

export interface AgreementModalProps extends DialogProps {
  orgId: string;
  agreementId: string | null;
  onClose: (needReload: boolean) => void;
}

const AgreementModal: FC<AgreementModalProps> = ({ agreementId, orgId, onClose, open }) => {
  const snackbarProps = useSnackbar();
  const [{ data: agreement, error: getAgreementError }, { fetchData }] = useFetch<AgreementBackendValues>(
    `org/${orgId}/agreements/${agreementId}`,
    {
      shouldFetch: false
    }
  );

  useEffect(() => {
    const fetchAgreement = async () => {
      await fetchData(`org/${orgId}/agreements/${agreementId}`);
    };
    if (agreementId) {
      fetchAgreement();
    }
  }, [orgId, fetchData, agreementId]);

  const handleSaveAgreement = async (
    agreement: AgreementBackendValues,
    forceTheCreation: boolean,
    setError: (fieldName: keyof AgreementFormValues, error: { type: string; message: string }) => void
  ) => {
    try {
      if (agreementId) {
        await apiService.patch<AgreementBackendValues>(`/org/${orgId}/agreements/${agreementId}`, agreement);
      } else {
        await apiService.post<AgreementBackendValues>(`org/${orgId}/agreements`, agreement);
      }
      onClose(true);
      fetchData(`/org/${orgId}/agreements`);
    } catch (error) {
      const errorResponse = error.response.data;
      if (errorResponse.validationErrors) {
        addServerErrors<AgreementFormValues>(errorResponse.validationErrors, setError);
        return;
      } else {
        showNotification(snackbarProps, false, errorResponse?.message || Messages.UnableToSaveAgreement, true);
      }
    }
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="agreement-dialog" open={open}>
      <ModalDialogTitle text="Customer Agreement" onClose={onClose} />
      <DialogContent>
        <AgreementForm
          onSubmit={handleSaveAgreement}
          initialValues={agreement}
          formMode={agreementId ? FormMode.Edit : FormMode.Add}
          onCancel={() => onClose(false)}
        />
        {getAgreementError && <Typography color="error">{getAgreementError}</Typography>}
      </DialogContent>
    </Dialog>
  );
};

export default AgreementModal;
