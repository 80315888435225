import { MenuItem } from '@mui/material';
import React from 'react';
import { PaymentCard } from '@efacity/common';
import { FormTextInput } from './FormTextInput';
import { useFormContext } from 'react-hook-form';

export const getNestedFormProperty = (fieldName: string, pathToFormObject?: string) => {
  if (pathToFormObject) {
    return `${pathToFormObject}.${fieldName}`;
  } else {
    return fieldName;
  }
};

interface PaymentCardSelectorProps {
  paymentCards: PaymentCard[];
  pathToObject?: string;
}

export const PaymentCardSelector: React.FC<PaymentCardSelectorProps> = ({ paymentCards, pathToObject = '' }) => {
  const methods = useFormContext();
  const onValueChange = async () => {
    await methods.trigger(getNestedFormProperty('userPaymentMethodId', pathToObject));
  };

  return (
    <FormTextInput
      name={getNestedFormProperty('userPaymentMethodId', pathToObject)}
      label="Select Card"
      margin="dense"
      SelectProps={{ displayEmpty: true }}
      onValueChange={onValueChange}
      InputLabelProps={{ shrink: true }}
      data-testid="paymentCardSelector"
      select
    >
      <MenuItem key={`paymentCardKeyNew`} value="">
        New
      </MenuItem>
      {paymentCards.map((paymentCard: PaymentCard) => (
        <MenuItem key={`paymentCardKey${paymentCard.paymentCardId}`} value={paymentCard.paymentCardId}>
          {paymentCard.description}
        </MenuItem>
      ))}
    </FormTextInput>
  );
};
