import { Messages } from '@efacity/common';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useUnsignedAgreements from '../../hooks/useUnsignedAgreements';
import StripePaymentFormContainer, { PaymentDetails } from './StripePaymentFormContainer';
import { useInvoicePayment } from './useInvoicePayment';
import {
  clearPurchaseStateAndGetAfterPaymentRedirect,
  LinearProgressWithMessage,
  PaymentDoneModal,
  ThreeDConfirmationModal,
  UnsignedCustomerAgreementsModal
} from '@efacity/frontend-shared';
import { registerInvoicePaymentGtag } from './registerInvoicePaymentGtag';
import { useAuth } from '@efacity/react-next-sc';

type ParamTypes = Partial<{
  invoiceId: string;
}>;

const InvoicePaymentByCustomerContainer = () => {
  const { checkAuthenticationStatus } = useAuth();
  const { invoiceId } = useParams<ParamTypes>();
  const [substituteFormMessage, setSubstituteFormMessage] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);

  const [{ paymentState }, { paymentActions, registrationActions }] = useInvoicePayment(`/payments/${invoiceId}`);
  const {
    invoice,
    defaultCountry,
    isLoadingInvoices,
    isUpdatingInvoice,
    stripePublishableKey,
    credit,
    paymentAcceptanceMethod,
    paymentCards
  } = paymentState;

  const { setPaymentState, processServerSideInvoicePayment, verifyCouponDiscountForInvoice } = paymentActions;
  const { switchAdditionalOptionSelectionToInvoiceRegistration, deleteRegistrationFromInvoice } = registrationActions;

  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const queryRedirectUrl = search.slice(1);
  useEffect(() => {
    if (queryRedirectUrl) startThreeDConfirmationModal(queryRedirectUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryRedirectUrl]);

  const [
    { userAgreementsToSign, allAgreementsViewed, signedAgreements },
    { onSignAgreement, onDismissAgreement, onCloseAgreementsModal }
  ] = useUnsignedAgreements(`/agreements/unsigned/invoice/${invoiceId}`);

  const on3DSComplete = async (payment_intent) => {
    setSubstituteFormMessage(Messages.ConfirmPayment);
    setRedirectUrl(null);
    const paymentInfoWithPaymentIntent: PaymentDetails = {
      paymentIntent: payment_intent as string
    };

    await callBackendProcessInvoicePayment(paymentInfoWithPaymentIntent, invoiceId);
  };

  const handleDiscountCodeChanged = (event) => {
    verifyCouponDiscountForInvoice(`/discounts/invoice/${invoiceId}/coupon/verify`, event.target.value);
  };

  const closeModalAndGotoRegistrationsOrProducts = () => {
    setPaymentState((state) => ({ ...state, paymentDoneModal: { ...state.paymentDoneModal, isOpen: false } }));
    checkAuthenticationStatus();
    navigate(clearPurchaseStateAndGetAfterPaymentRedirect());
  };

  const closeModalAndReloadInvoice = (redirectUrl: string) => {
    setPaymentState((state) => ({ ...state, paymentDoneModal: { ...state.paymentDoneModal, isOpen: false } }));
    window.location.href = redirectUrl; // reload without query parameters
  };

  const startThreeDConfirmationModal = (redirectUrl: string) => {
    setSubstituteFormMessage(Messages.Stripe3DRedirect);
    window.addEventListener(
      'message',
      function (ev) {
        if (ev.data.message === '3DS-authentication-complete') {
          on3DSComplete(ev.data.payment_intent);
        }
      },
      false
    );
    setRedirectUrl(redirectUrl);
  };

  const callBackendProcessInvoicePayment = async (paymentInfo: PaymentDetails, invoiceId: string) => {
    const { linkToPaymentReceiptPDF, redirectUrl, message, isError } = await processServerSideInvoicePayment(
      `/payments/${invoiceId}`,
      { ...paymentInfo, signedAgreements }
    );

    if (redirectUrl?.length > 0) {
      startThreeDConfirmationModal(redirectUrl);
      return;
    } else {
      setSubstituteFormMessage(null);
    }

    if (isError) {
      setPaymentState((state) => ({
        ...state,
        paymentDoneModal: {
          isOpen: true,
          isError: true,
          message: message,
          onClose: () => closeModalAndReloadInvoice(pathname)
        }
      }));
      return;
    }

    await registerInvoicePaymentGtag(`/payments/${invoiceId}/item-details`);
    setSubstituteFormMessage('Invoice paid...');

    setPaymentState((state) => ({
      ...state,
      invoice: {
        ...state.invoice,
        isPaid: true
      },
      paymentDoneModal: {
        isOpen: true,
        isError: false,
        message: message || Messages.PaymentSuccess,
        onClose: closeModalAndGotoRegistrationsOrProducts
      }
    }));
    if (linkToPaymentReceiptPDF) {
      window.open(linkToPaymentReceiptPDF);
    }
  };

  const handleDeleteAdditionalOption = async (sessionId: string, studentId: string, additionalOptionId: string) => {
    await switchAdditionalOptionSelectionToInvoiceRegistration(
      `/registrations/invoice/${invoiceId}/session/${sessionId}/student/${studentId}/additionalOption/${additionalOptionId}/${false}`
    );
  };

  const handleDeleteRegistration = async (registrationId: string, sessionId: string, studentId: string) => {
    const result = await deleteRegistrationFromInvoice(
      `/registrations/invoice/${invoiceId}/registration/${registrationId}/session/${sessionId}/student/${studentId}`
    );
    if (!result?.invoice) {
      setPaymentState((state) => ({
        ...state,
        paymentDoneModal: {
          isOpen: true,
          isError: false,
          message: Messages.LastRegistrationInInvoiceDeleted,
          onClose: closeModalAndGotoRegistrationsOrProducts
        }
      }));
    }
  };

  return (
    <div>
      {substituteFormMessage ? (
        <LinearProgressWithMessage message={substituteFormMessage} key={substituteFormMessage} />
      ) : (
        <StripePaymentFormContainer
          invoice={invoice}
          credit={credit}
          isLoadingInvoices={isLoadingInvoices}
          stripePublishableKey={stripePublishableKey}
          paymentAcceptanceMethod={paymentAcceptanceMethod}
          processBackendInvoicePayment={callBackendProcessInvoicePayment}
          handleDiscountCodeChanged={handleDiscountCodeChanged}
          handleDeleteAdditionalOption={handleDeleteAdditionalOption}
          handleDeleteDiscount={null}
          handleDeleteRegistration={handleDeleteRegistration}
          isUpdating={isUpdatingInvoice}
          initialCountry={defaultCountry}
          hasAdminRole={false}
          onStartAdjustmentModal={null}
          paymentCards={paymentCards}
        />
      )}
      {!allAgreementsViewed && (
        <UnsignedCustomerAgreementsModal
          onClose={onCloseAgreementsModal}
          unsignedAgreements={userAgreementsToSign}
          onSignAgreement={onSignAgreement}
          onDismissAgreement={onDismissAgreement}
          open={!allAgreementsViewed}
        />
      )}
      <ThreeDConfirmationModal open={!!redirectUrl} redirectUrl={redirectUrl} />
      <PaymentDoneModal paymentDoneModalState={paymentState.paymentDoneModal} />
    </div>
  );
};

export default InvoicePaymentByCustomerContainer;
