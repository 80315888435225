import {
  adminAndIndependentRoles,
  adminsTeachersAndIndependentRoles,
  hasAnyOfRoles,
  headerHeight,
  Roles
} from '@efacity/common';
import { useBodyScrollLock } from '@efacity/frontend-shared';
import { hasFrontendAccessWithRoleForOrganization, useAuth } from '@efacity/react-next-sc';
import { Box, Theme, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import LeftNavigation from '../LeftNavigation/LeftNavigation';
import MainLayoutHeader from './MainLayoutHeader';
import { useOrganizationRegionsOptions } from '../../hooks/useOrganizationRegionsOptions';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';

export const contentSx = (theme: Theme) => ({
  padding: theme.spacing(0, 4),
  flexGrow: 1,
  height: `calc(100% - ${headerHeight}px)`,
  marginLeft: '260px',
  paddingLeft: '15px',
  paddingRight: '15px',
  paddingBottom: '30px',
  overflow: 'scroll',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '30px',
    marginLeft: '26px'
  },
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  })
});
const shiftedContentSx = (theme: Theme) => ({
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: '26px',
  [theme.breakpoints.down('md')]: {
    marginLeft: '0px'
  }
});

interface MainLayoutProps {
  shouldRedirectOnLogout?: boolean;
  children?: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { orgId: paramsOrgId } = useParams<OrgIdParamType>();
  const {
    authState: { user, extraAuthInfo, workingAccessId }
  } = useAuth();

  const orgId = useMemo(() => {
    if (paramsOrgId || workingAccessId) {
      return paramsOrgId || workingAccessId;
    } else if (extraAuthInfo?.startRegistrationURL) {
      const regUrl = extraAuthInfo.startRegistrationURL;
      const regMatch = regUrl.match(/^\/(org|center|school)\/(.{24})$/);

      if (regMatch) {
        return regMatch[2];
      }
      return null;
    }
    return null;
  }, [paramsOrgId, workingAccessId, extraAuthInfo]);

  const { organizationRegions } = useOrganizationRegionsOptions(orgId);
  const showLeftNavigation = hasAnyOfRoles([...adminsTeachersAndIndependentRoles, Roles.SiteAdmin], user.roles);
  const theme = useTheme();
  const showPermanentDrawer = useMediaQuery(theme.breakpoints.up('lg'));
  const [isDrawerExpanded, setDrawerExpanded] = useState(false);
  const mainContentRef = useRef(null);

  const allowHandleSession = hasFrontendAccessWithRoleForOrganization(
    workingAccessId,
    adminAndIndependentRoles,
    user?.adminAccesses || []
  );

  useBodyScrollLock(isDrawerExpanded && !showPermanentDrawer, mainContentRef);

  useEffect(() => {
    setDrawerExpanded(showPermanentDrawer);
  }, [showPermanentDrawer]);

  const onMenuItemClick = () => {
    if (!showPermanentDrawer) {
      setDrawerExpanded(false);
    }
  };

  const handleDrawerToggle = () => {
    setDrawerExpanded(!isDrawerExpanded);
  };

  const handleMainContentAreaClick = () => {
    if (isDrawerExpanded && !showPermanentDrawer) {
      setDrawerExpanded(false);
    }
  };

  return (
    <div data-testid="mainLayout">
      <MainLayoutHeader
        handleDrawerToggle={handleDrawerToggle}
        logoFullUrl={organizationRegions?.logoFullUrl?.length > 0 ? organizationRegions?.logoFullUrl : null}
        mobileLogoFullUrl={
          organizationRegions?.mobileLogoFullUrl?.length > 0 ? organizationRegions?.mobileLogoFullUrl : null
        }
      />
      {showLeftNavigation && (
        <LeftNavigation
          user={user}
          allowedCreateActivity={extraAuthInfo?.center?.allowedCreateActivity}
          allowedCreateSession={allowHandleSession}
          enrollmentRequired={organizationRegions.enrollmentRequired}
          startRegistrationURL={extraAuthInfo.startRegistrationURL}
          workingAccessId={workingAccessId}
          isDrawerExpanded={isDrawerExpanded}
          setDrawerExpanded={setDrawerExpanded}
          onMenuItemClick={onMenuItemClick}
        />
      )}
      <Box sx={!isDrawerExpanded || !showLeftNavigation ? [contentSx, shiftedContentSx] : contentSx}>
        <main ref={mainContentRef} onClick={handleMainContentAreaClick} data-test-id="mainContent">
          <Outlet />
          {children}
        </main>
      </Box>
    </div>
  );
};
export default MainLayout;
