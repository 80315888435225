import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { FC } from 'react';
import { addDays, differenceInDays, isBefore } from 'date-fns';
import { CommonDatePicker } from '@efacity/frontend-shared';
import { Grid } from '@mui/material';

interface AttendanceDateFiltersProps {
  fromDate: Date;
  toDate: Date;
  handleDatesChanged?: (fromDate: Date, toDate: Date) => void;
  validationErrors?: Record<'fromDate' | 'toDate', string>;
  adjustDatesInterval?: boolean;
}

const DEFAULT_DATES_INTERVAL = 5;

const StartEndDatesFilter: FC<AttendanceDateFiltersProps> = ({
  fromDate,
  toDate,
  handleDatesChanged,
  validationErrors,
  adjustDatesInterval = true
}) => {
  const handleStartDateChange = (newFromDate: Date) => {
    const newToDate = adjustDatesInterval
      ? isBefore(toDate, newFromDate)
        ? newFromDate
        : differenceInDays(toDate, newFromDate) > DEFAULT_DATES_INTERVAL
        ? addDays(newFromDate, DEFAULT_DATES_INTERVAL)
        : toDate
      : isBefore(toDate, newFromDate)
      ? newFromDate
      : toDate;

    handleDatesChanged(newFromDate, newToDate);
  };
  const handleEndDateChange = (newToDate: Date) => {
    const newFromDate = adjustDatesInterval
      ? isBefore(newToDate, fromDate)
        ? newToDate
        : differenceInDays(newToDate, fromDate) > DEFAULT_DATES_INTERVAL
        ? addDays(newToDate, -DEFAULT_DATES_INTERVAL)
        : fromDate
      : isBefore(newToDate, fromDate)
      ? newToDate
      : fromDate;
    handleDatesChanged(newFromDate, newToDate);
  };

  return (
    <Grid container spacing={1}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid item xs={6}>
          <CommonDatePicker
            value={fromDate}
            onChange={handleStartDateChange}
            id="fromDateFilter"
            label="From Date"
            error={!!validationErrors?.fromDate}
            helperText={validationErrors?.fromDate}
          />
        </Grid>
        <Grid item xs={6}>
          <CommonDatePicker
            value={toDate}
            onChange={handleEndDateChange}
            id="toDateFilter"
            label="To Date"
            error={!!validationErrors?.toDate}
            helperText={validationErrors?.toDate}
          />
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};

export default StartEndDatesFilter;
