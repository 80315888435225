import { DurationType, LocationType, generateShortAddress } from '@efacity/common';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import { LightTooltip, SessionWithLocationAndTags } from '@efacity/react-next-sc';
import { Typography } from '@mui/material';
import { FC } from 'react';

interface SessionLocationProps {
  session: SessionWithLocationAndTags;
}

const SessionLocationCell: FC<SessionLocationProps> = ({ session }) => {
  if (session.durationType === DurationType.selfPaced) return '-';
  if (session.location?.type === LocationType.InPerson || session.location?.type === LocationType.PublicSchool) {
    return (
      <LightTooltip
        title={session.location?.address ? generateShortAddress(session.location?.address) : session.location.name}
      >
        <Typography style={{ whiteSpace: 'nowrap', ...adminSectionTableFontSize }}>
          {session.location?.type === LocationType.InPerson ? 'In-Person' : 'Public School'}
        </Typography>
      </LightTooltip>
    );
  }
  if (session.location?.type === LocationType.Virtual) {
    return (
      <>
        <Typography style={{ ...adminSectionTableFontSize }}>Virtual</Typography>
        {session.location.virtualMeeting.meetingId && (
          <Typography style={{ whiteSpace: 'nowrap', ...adminSectionTableFontSize }}>
            Meeting Id: {session.location.virtualMeeting.meetingId}
          </Typography>
        )}
      </>
    );
  }

  return <>-</>;
};

export default SessionLocationCell;
