import { CurrencyCode } from './Currencies';
import { CountryCode } from './Countries';
import { DiscountInterface, DiscountType, DiscountTypeDefinitionInterface } from './Discount';
import { AdditionalSessionOption } from './AdditionalSessionOption';
import { TaxTypes } from './Taxes';
import { ProductInterface } from './ProductInterface';
import { DurationType } from './Activities';

export interface ShoppingCartItemSession {
  _id: string;
  name: string;
  orgName: string;
  type: string;
  price: number;
  currencyCode: CurrencyCode;
  durationType: DurationType;
  startDate: string;
  endDate: string;
  numberOfInstances: number;
  instanceDuration: number;
  duration: string;
  weekDays: string[];
}

export interface ShoppingCartItemStudent {
  _id: string;
  name: string;
}

export interface ShoppingCartRegistration {
  session: ShoppingCartItemSession;
  student?: ShoppingCartItemStudent;
  additionalSessionOptions?: AdditionalSessionOption[];
  discounts?: DiscountInterface[];
}

export interface ShoppingCartProduct {
  _id: string;
  product: ProductInterface;
  discounts?: DiscountInterface[];
}

export interface ShoppingCart {
  _id: string;
  orgId: string | null;
  orgName: string | null;
  userId: string | null;
  shoppingCartRegistrations: ShoppingCartRegistration[];
  shoppingCartProducts: ShoppingCartProduct[];
  currency: string | null;
  taxType: TaxTypes;
  taxPercentage: number;
  isValid: boolean;
  timeZone?: string;
  defaultCountry?: CountryCode;
}

export interface StudentToSessionSelection {
  studentId: string;
  sessionId: string;
}

export interface StudentSessionOptionId {
  studentId: string;
  sessionId: string;
  additionalOptionId: string;
}

export const isShoppingCartEmpty = (shoppingCart: ShoppingCart) => {
  return shoppingCart?.shoppingCartRegistrations?.length === 0 && shoppingCart?.shoppingCartProducts?.length === 0;
};

export const isStudentSelectedOnSessionInShoppingCart = (
  shoppingCart: ShoppingCart,
  studentId: string,
  sessionId: string
) => {
  if (shoppingCart?.shoppingCartRegistrations?.length > 0) {
    const foundShoppingCartItem = shoppingCart.shoppingCartRegistrations.find(
      (shoppingCartItem: ShoppingCartRegistration) =>
        shoppingCartItem.session._id === sessionId && shoppingCartItem.student?._id === studentId
    );
    return !!foundShoppingCartItem;
  }
  return false;
};

export interface StudentSessionId {
  studentId: string;
  studentName: string;
  sessionId: string;
}

export const getAllStudentSessionIdsFromShoppingCartRegistrations = (
  shoppingCartRegistrations: ShoppingCartRegistration[]
): StudentSessionId[] => {
  if (shoppingCartRegistrations && shoppingCartRegistrations.length > 0) {
    return shoppingCartRegistrations.reduce(
      (studentSessionIds: StudentSessionId[], shoppingCartRegistration: ShoppingCartRegistration) => {
        if (shoppingCartRegistration?.student?._id) {
          studentSessionIds.push({
            studentId: shoppingCartRegistration.student._id,
            studentName: shoppingCartRegistration.student.name,
            sessionId: shoppingCartRegistration.session._id
          });
        }
        return studentSessionIds;
      },
      []
    );
  }
  return [];
};

export const getStudentsForSession = (sessionId: string, allStudentSessionIds: StudentSessionId[]) => {
  if (allStudentSessionIds && allStudentSessionIds.length > 0) {
    return allStudentSessionIds.filter((studentSessionId) => studentSessionId.sessionId === sessionId);
  }
  return [];
};

export const isAdditionalOptionSelectedForStudent = (
  studentId: string,
  sessionId: string,
  additionalOptionId: string,
  studentSessionOptionIds: StudentSessionOptionId[]
) => {
  if (studentSessionOptionIds && studentSessionOptionIds.length > 0 && studentId && sessionId && additionalOptionId) {
    const foundItem = studentSessionOptionIds.find(
      (studentSessionOptionId: StudentSessionOptionId) =>
        studentSessionOptionId.studentId === studentId &&
        studentSessionOptionId.sessionId === sessionId &&
        studentSessionOptionId.additionalOptionId === additionalOptionId
    );

    return !!foundItem;
  }
  return false;
};

export const getDiscountsCalculatedAmount = (discounts: DiscountInterface[]) => {
  if (discounts?.length > 0) {
    return discounts.reduce((accumulator: number, discount: DiscountInterface) => {
      return accumulator + discount.calculatedAmount;
    }, 0);
  }
  return 0;
};

export const getAdditionalOptionsAmount = (additionalOptions: AdditionalSessionOption[]) => {
  if (additionalOptions && additionalOptions.length > 0) {
    return additionalOptions.reduce((accumulator: number, additionalOption: AdditionalSessionOption) => {
      return accumulator + additionalOption.price;
    }, 0);
  }
  return 0;
};

export const getAmountForShoppingCart = (shoppingCart: ShoppingCart) => {
  const registrationAmount = getAmountForShoppingCartRegistrations(shoppingCart?.shoppingCartRegistrations || []);
  const productAmount = getAmountForShoppingCartProducts(shoppingCart?.shoppingCartProducts || []);

  return registrationAmount + productAmount;
};

export const getAmountForShoppingCartProducts = (shoppingCartProducts: ShoppingCartProduct[] = []) => {
  if (shoppingCartProducts && shoppingCartProducts?.length > 0) {
    const calculatedAmount = shoppingCartProducts.reduce((accumulator, shoppingCartProduct: ShoppingCartProduct) => {
      const priceAmount = shoppingCartProduct.product.price;
      // TODO: add discounts calculation
      return accumulator + priceAmount;
    }, 0);
    return calculatedAmount < 0 ? 0 : calculatedAmount;
  }
  return 0;
};

export const getAmountForShoppingCartRegistrations = (shoppingCartRegistrations: ShoppingCartRegistration[] = []) => {
  if (shoppingCartRegistrations && shoppingCartRegistrations?.length > 0) {
    const calculatedAmount = shoppingCartRegistrations.reduce(
      (accumulator, shoppingCartRegistration: ShoppingCartRegistration) => {
        const sessionAmount = shoppingCartRegistration.session.price;
        const additionalChargesAmount = getAdditionalOptionsAmount(shoppingCartRegistration.additionalSessionOptions);
        const discountsAmount = getDiscountsCalculatedAmount(shoppingCartRegistration.discounts);
        return accumulator + sessionAmount + additionalChargesAmount + discountsAmount;
      },
      0
    );
    return calculatedAmount < 0 ? 0 : calculatedAmount;
  }
  return 0;
};

export const getTaxAmount = (subTotal: number, taxType: TaxTypes = TaxTypes.None, taxPercentage: number) => {
  if (taxType && taxType !== TaxTypes.None && taxPercentage >= 0) {
    const mayBeTaxAmount = (subTotal * taxPercentage) / 100;
    return mayBeTaxAmount >= 0 ? mayBeTaxAmount : 0;
  }
  return 0;
};

export const calculateAmountWithTax = (amount: number, taxAmount: number): number => {
  const mayBeAmountWithTax = amount + taxAmount;
  return mayBeAmountWithTax >= 0 ? mayBeAmountWithTax : 0;
};

export const percentageTypeDiscountAmount = (totalWithoutCodeDiscount, percentage) => {
  return (percentage * totalWithoutCodeDiscount) / 100;
};
export const amountTypeDiscountAmount = (totalWithoutCodeDiscount, discountAmount) => {
  return discountAmount;
};

export const codeDiscountAmount = (totalWithoutCodeDiscount, typeDefinition: DiscountTypeDefinitionInterface) => {
  return typeDefinition.discountType === DiscountType.Amount
    ? amountTypeDiscountAmount(totalWithoutCodeDiscount, typeDefinition.discountAmount)
    : percentageTypeDiscountAmount(totalWithoutCodeDiscount, typeDefinition.discountPercentage);
};

export const isShoppingCartAllowedToPay = (shoppingCart: ShoppingCart) => {
  if (isShoppingCartEmpty(shoppingCart)) return false;

  if (shoppingCart?.shoppingCartRegistrations?.length > 0) {
    return !isContainsSessionWithoutStudent(shoppingCart.shoppingCartRegistrations);
  }
  return shoppingCart?.shoppingCartProducts?.length > 0;
};

export const isContainsSessionWithoutStudent = (shoppingCartRegistrations: ShoppingCartRegistration[]) => {
  return shoppingCartRegistrations.find((shoppingCartRegistration: ShoppingCartRegistration) => {
    return !shoppingCartRegistration.student;
  });
};
