import { Link } from '@mui/material';

interface EmailAddressCellProps {
  email: string | undefined;
}

export const EmailAddressCell = ({ email }: EmailAddressCellProps) => {
  if (!email) return null;

  return (
    <Link href={`mailto:${email}`} data-testid="email-link" underline={'none'}>
      {email}
    </Link>
  );
};
