import { IconButton } from '@mui/material';
import React from 'react';
import DeleteWithSpinnerIcon from './DeleteWithSpinnerIcon';

export interface DeleteWithSpinnerIconProps {
  onClick: () => void;
  isUpdating: boolean;
  disabled?: boolean;
}

export const DeleteIconButtonWithSpinner: React.FC<DeleteWithSpinnerIconProps> = ({
  onClick,
  isUpdating,
  disabled = false,
  ...props
}) => {
  return (
    <IconButton
      color="primary"
      aria-label="delete-shopping-cart-item"
      component="span"
      onClick={onClick}
      style={{ paddingTop: 2, paddingBottom: 2 }}
      disabled={isUpdating || disabled}
      {...props}
    >
      <DeleteWithSpinnerIcon isUpdating={isUpdating} />
    </IconButton>
  );
};

export default DeleteIconButtonWithSpinner;
