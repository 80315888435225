import * as yup from 'yup';
import { CurrencyCode, nonNegativeAmountValidator, ValidationErrors } from '@efacity/common';

export const organizationEnrollmentFormValidationSchema = yup.object().shape({
  enrollmentRequired: yup.boolean(),
  enrollmentPrice: yup.string().test('test-name', ValidationErrors.InvalidPriceRange, nonNegativeAmountValidator)
});

export interface OrganizationEnrollmentsFormValues {
  enrollmentRequired: boolean;
  enrollmentPrice: number;
  currency: CurrencyCode;
}

export const initialOrganizationEnrollmentsFormValues: OrganizationEnrollmentsFormValues = {
  enrollmentRequired: false,
  enrollmentPrice: 0,
  currency: CurrencyCode.USD
};
