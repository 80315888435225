import { Box, Typography } from '@mui/material';
import { DiscountCard } from '@efacity/frontend-shared';
import React from 'react';

export const DiscountBadgePreview = ({ discount }) => {
  return (
    <Box sx={{ gridArea: 'preview', minWidth: '300px', mx: { xs: 'auto', md: '10%' }, my: { xs: '5%', md: '10%' } }}>
      <Typography style={{ marginBottom: '10px' }}>Preview of the Landing page badge: </Typography>
      <DiscountCard discount={discount} isDemo />
    </Box>
  );
};
