import React, { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import PageNavigationTabs, { Tab } from '../../components/PageNavigatonTabs/PageNavigationTabs';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { PageTitle } from '@efacity/react-next-sc';

const DiscountsContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();

  const tabs = useMemo<Tab[]>(() => {
    return [
      {
        label: 'Current',
        link: 'current'
      },
      {
        label: 'Expired',
        link: 'expired'
      }
    ];
  }, []);

  return (
    <div>
      <PageTitle title="Discounts" addButtonLink={`/admin/${orgId}/discount`} />
      <PageNavigationTabs tabs={tabs} />
      <Outlet />
    </div>
  );
};

export default DiscountsContainer;
