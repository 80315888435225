import FormatBold from '@mui/icons-material/FormatBold';
import FormatItalic from '@mui/icons-material/FormatItalic';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import FormatListNumbered from '@mui/icons-material/FormatListNumbered';
import FormatQuote from '@mui/icons-material/FormatQuote';
import FormatUnderlined from '@mui/icons-material/FormatUnderlined';
import React, { ReactElement } from 'react';
import { ElementTypes, FormatTypes } from './slate-rich-text-editor';

export interface EditorButtonConfig {
  format: FormatTypes | ElementTypes;
  hint: string;
  icon: ReactElement;
}

export const markButtons: EditorButtonConfig[] = [
  {
    format: 'bold',
    hint: 'Bold',
    icon: <FormatBold />
  },
  {
    format: 'italic',
    hint: 'Italic',
    icon: <FormatItalic />
  },
  {
    format: 'underline',
    hint: 'Underline',
    icon: <FormatUnderlined />
  }
];

export const headingOptions = [
  {
    format: 'paragraph',
    icon: <p style={{ margin: 0, padding: 0 }}>Paragraph</p>,
    hint: 'Paragraph'
  },
  {
    format: 'heading-one',
    icon: <h1 style={{ margin: 0, padding: 0 }}>Heading 1</h1>,
    hint: 'Heading 1'
  },
  {
    format: 'heading-two',
    icon: <h2 style={{ margin: 0, padding: 0 }}>Heading 2</h2>,
    hint: 'Heading 2'
  }
];

export const blockButtons: EditorButtonConfig[] = [
  {
    format: 'block-quote',
    hint: 'Quote',
    icon: <FormatQuote />
  },
  {
    format: 'numbered-list',
    hint: 'Ordered list',
    icon: <FormatListNumbered />
  },
  {
    format: 'bulleted-list',
    hint: 'Unordered list',
    icon: <FormatListBulleted />
  }
];
