import { LightTooltip } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import LinkOutlined from '@mui/icons-material/LinkOutlined';
import { IconButton, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import React from 'react';

const ProductsLinkWithCopier: React.FC = () => {
  const handleCopyJobPostingsClick = () => {
    copy(`${window.location.origin}${toPath(PATHS.productsLanding)}`);
  };

  return (
    <LightTooltip title={<Typography>Copy Products Landing Link to share</Typography>}>
      <span>
        <div style={{ display: 'flex', marginRight: 5 }} data-testid="registration-link-with-copier">
          [
          <IconButton
            color="primary"
            aria-label="copy to clipboard"
            component="span"
            style={{ marginTop: -4, padding: 5 }}
            onClick={() => handleCopyJobPostingsClick()}
          >
            <LinkOutlined fontSize="medium" />
          </IconButton>
          ]
        </div>
      </span>
    </LightTooltip>
  );
};

export default ProductsLinkWithCopier;
