import {
  InvoiceItemTypes,
  InvoicePresentation,
  moneyWithCurrencyCodeFormatter,
  splitInvoiceItemsToRegistrationsAndProducts
} from '@efacity/common';
import { LinearProgress, Table, TableBody, TableCell, TableRow, TableContainer, TableHead } from '@mui/material';
import React, { ChangeEvent, FC } from 'react';
import { tableContainerSx, tableHeadCellSx, tableHeadSx, totalCellSx } from '@efacity/frontend-shared';
import RegistrationInvoiceItemTableRow from './RegistrationInvoiceItemTableRow';
import AdditionalOptionInvoiceItemTableRow from './AdditionalOptionInvoiceItemTableRow';
import DiscountInvoiceItemTableRow from './DiscountInvoiceItemTableRow';
import CouponDiscountInputAsRow from '../../ShoppingCartContainer/ShoppingCartTable/CouponDiscountInputAsRow';
import ProductInvoiceItemTableRow from './ProductInvoiceItemTableRow';

export interface InvoiceTableProps {
  invoice: InvoicePresentation;
  due: number;
  showTaxRows: boolean;
  taxRowDescription: string;
  credit: number;
  loading: boolean;
  handleDiscountCodeChanged: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleDeleteAdditionalOption: (sessionId: string, studentId: string, additionalOptionId: string) => void;
  handleDeleteRegistration: (registrationId: string, sessionId: string, studentId: string) => void;
  handleDeleteDiscount: (invoiceItemId: string) => void | null;
  isUpdating: boolean;
  hasAdminRole: boolean;
  onStartAdjustmentModal: (invoiceItemId) => void | null;
}

const InvoiceTable: FC<InvoiceTableProps> = ({
  invoice,
  due,
  showTaxRows,
  taxRowDescription,
  credit,
  loading,
  handleDiscountCodeChanged,
  handleDeleteAdditionalOption,
  handleDeleteDiscount,
  handleDeleteRegistration,
  isUpdating,
  hasAdminRole,
  onStartAdjustmentModal
}) => {
  const { registrationInvoiceItems, productInvoiceItems } = splitInvoiceItemsToRegistrationsAndProducts(
    invoice?.invoiceItems
  );

  return (
    <TableContainer sx={tableContainerSx}>
      <Table size="small" aria-label="a simple dense table">
        {loading ? (
          <>
            <TableHead sx={tableHeadSx}>
              <TableRow>
                <TableCell sx={tableHeadCellSx} align="left">
                  Session
                </TableCell>
                <TableCell sx={tableHeadCellSx} align="left">
                  Student
                </TableCell>
                <TableCell sx={tableHeadCellSx} align="center" style={{ width: 166 }}>
                  Start Date
                </TableCell>
                <TableCell sx={tableHeadCellSx} align="center" style={{ width: 137 }}>
                  Week Days
                </TableCell>
                <TableCell sx={tableHeadCellSx} align="center" style={{ width: 137 }}>
                  Time
                </TableCell>
                <TableCell sx={tableHeadCellSx} align="center" style={{ width: 98 }}>
                  Duration
                </TableCell>
                <TableCell sx={tableHeadCellSx} align="right" style={{ width: 120 }}>
                  Amount
                </TableCell>
                <TableCell sx={tableHeadCellSx} />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  <LinearProgress variant="indeterminate" color="primary" data-testid="loadingIndicator" />
                </TableCell>
              </TableRow>
            </TableBody>
          </>
        ) : !invoice?.invoiceItems || invoice?.invoiceItems?.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={7}>
                No items...
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <>
            {registrationInvoiceItems?.length > 0 && (
              <>
                <TableHead sx={tableHeadSx}>
                  <TableRow>
                    <TableCell sx={tableHeadCellSx} align="left">
                      Session
                    </TableCell>
                    <TableCell sx={tableHeadCellSx} align="left">
                      Student
                    </TableCell>
                    <TableCell sx={tableHeadCellSx} align="center" style={{ width: 166 }}>
                      Start Date
                    </TableCell>
                    <TableCell sx={tableHeadCellSx} align="center" style={{ width: 137 }}>
                      Week Days
                    </TableCell>
                    <TableCell sx={tableHeadCellSx} align="center" style={{ width: 137 }}>
                      Time
                    </TableCell>
                    <TableCell sx={tableHeadCellSx} align="center" style={{ width: 98 }}>
                      Duration
                    </TableCell>
                    <TableCell sx={tableHeadCellSx} align="right" style={{ width: 120 }}>
                      Amount
                    </TableCell>
                    <TableCell sx={tableHeadCellSx} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {registrationInvoiceItems?.map((invoiceItem, index) => {
                    switch (invoiceItem.invoiceItemType) {
                      case InvoiceItemTypes.Registration:
                        return (
                          <RegistrationInvoiceItemTableRow
                            key={`invoiceItem${invoiceItem.invoiceItemId}PaymentItemRow`}
                            invoiceItem={invoiceItem}
                            handleDeleteRegistration={handleDeleteRegistration}
                            index={index}
                            isUpdating={isUpdating}
                            disabled={invoice?.isPaid}
                            hasAdminRole={hasAdminRole}
                            onStartAdjustmentModal={onStartAdjustmentModal}
                          />
                        );
                      case InvoiceItemTypes.AdditionalSessionOption:
                        return (
                          <AdditionalOptionInvoiceItemTableRow
                            key={`invoiceItem${invoiceItem.invoiceItemId}AdditionalOptionItemRow`}
                            invoiceItem={invoiceItem}
                            handleDeleteAdditionalOption={handleDeleteAdditionalOption}
                            index={index}
                            isUpdating={isUpdating}
                            disabled={invoice?.isPaid}
                          />
                        );
                      case InvoiceItemTypes.Discount:
                        return (
                          <DiscountInvoiceItemTableRow
                            key={`invoiceItem${invoiceItem.invoiceItemId}AdditionalOptionItemRow`}
                            invoiceItem={invoiceItem}
                            handleDeleteDiscount={handleDeleteDiscount}
                            index={index}
                            isUpdating={isUpdating}
                            disabled={invoice?.isPaid}
                          />
                        );
                      default:
                        return null;
                    }
                  })}
                </TableBody>
              </>
            )}
            {productInvoiceItems?.length > 0 && (
              <>
                <TableHead sx={tableHeadSx}>
                  <TableRow>
                    <TableCell sx={tableHeadCellSx} align="left" colSpan={6}>
                      Kit
                    </TableCell>
                    <TableCell sx={tableHeadCellSx} align="right" style={{ width: 120 }}>
                      Amount
                    </TableCell>
                    <TableCell sx={tableHeadCellSx} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productInvoiceItems?.map((invoiceItem, index) => {
                    switch (invoiceItem.invoiceItemType) {
                      case InvoiceItemTypes.Product:
                        return (
                          <ProductInvoiceItemTableRow
                            key={`invoiceItem${invoiceItem.invoiceItemId}ProductItemRow`}
                            invoiceItem={invoiceItem}
                            handleDeleteProduct={null}
                            index={index}
                            isUpdating={isUpdating}
                            disabled={invoice?.isPaid}
                          />
                        );
                      default:
                        return null;
                    }
                  })}
                </TableBody>
              </>
            )}
            <TableBody>
              <CouponDiscountInputAsRow
                handleDiscountCodeChanged={handleDiscountCodeChanged}
                disabled={invoice?.isPaid}
              />
              {showTaxRows ? (
                <>
                  <TableRow>
                    <TableCell sx={totalCellSx} colSpan={6} align="right">
                      Sub Total :
                    </TableCell>
                    <TableCell sx={totalCellSx} align="right">
                      {moneyWithCurrencyCodeFormatter(invoice?.amount, invoice?.currencyCode)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={totalCellSx} colSpan={6} align="right">
                      {taxRowDescription}
                    </TableCell>
                    <TableCell sx={totalCellSx} align="right">
                      {moneyWithCurrencyCodeFormatter(invoice?.taxAmount, invoice?.currencyCode)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={totalCellSx} colSpan={6} align="right">
                      Total :
                    </TableCell>
                    <TableCell sx={totalCellSx} align="right">
                      {moneyWithCurrencyCodeFormatter(invoice?.amountWithTax, invoice?.currencyCode)}
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <TableCell sx={totalCellSx} colSpan={6} align="right">
                    Total :
                  </TableCell>
                  <TableCell sx={totalCellSx} align="right">
                    {moneyWithCurrencyCodeFormatter(invoice?.amount, invoice?.currencyCode)}
                  </TableCell>
                </TableRow>
              )}
              {credit > 0 && (
                <TableRow>
                  <TableCell sx={totalCellSx} colSpan={6} align="right">
                    Credit :
                  </TableCell>
                  <TableCell sx={totalCellSx} align="right">
                    {moneyWithCurrencyCodeFormatter(-credit, invoice?.currencyCode)}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell sx={totalCellSx} colSpan={6} align="right">
                  Due :
                </TableCell>
                <TableCell sx={totalCellSx} align="right">
                  {moneyWithCurrencyCodeFormatter(due, invoice?.currencyCode)}
                </TableCell>
              </TableRow>
            </TableBody>
          </>
        )}
      </Table>
    </TableContainer>
  );
};

export default InvoiceTable;
