import { apiService } from '@efacity/frontend-shared';
import { useCallback, useEffect, useState } from 'react';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { WithSnackbarProps } from 'notistack';
import { Attendance, AttendanceExtended, Messages, PublicAttendanceInterface } from '@efacity/common';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';
import { FetchDataOptions } from '../../components/Table/Table';
import { addMinutes } from 'date-fns';

export interface AttendanceState {
  attendance: Attendance[];
  isLoading: boolean;
  isLoadingForDate: boolean;
  attendanceForDate: Attendance[];
  validationErrors: Record<'fromDate' | 'toDate', string> | null;
}

export interface FetchOptionsWithFromToDatesState {
  fromDate: Date;
  toDate: Date;
  fetchDataOptions: FetchDataOptions;
}

// eslint-disable-next-line @typescript-eslint/ban-types
// additionalSessionOptionsState, getAdditionalSessionOptions
// type of T will be set automatically
export const useAttendanceLoader = (url: string, snackbarProps: WithSnackbarProps, attendanceFilters) => {
  const [attendanceState, setAttendanceState] = useState<AttendanceState>({
    attendance: [],
    isLoading: true,
    isLoadingForDate: false,
    validationErrors: null,
    attendanceForDate: []
  });

  const getAttendance = useCallback(
    (attendanceFilters: FetchOptionsWithFromToDatesState) => {
      const setAttendance = (attendance: Attendance[], isLoading: boolean) => {
        setAttendanceState((attendanceState) => ({
          ...attendanceState,
          attendance: attendance,
          isLoading: isLoading
        }));
      };

      const showErrorNotification = (message: string) => {
        setAttendance([], false);
        showNotification(snackbarProps, false, message, true);
      };

      setAttendanceState((attendanceState) => ({
        ...attendanceState,
        isLoading: true
      }));
      apiService.get<Attendance[]>(url, getQueryParamsForAttendanceFilters(attendanceFilters)).then(
        (result) => {
          return setAttendance(result.data, false);
        },
        (error) => {
          const validationErrors = error?.response?.data?.validationErrors;

          setAttendanceState((attendanceState) => ({
            ...attendanceState,
            isLoading: false,
            validationErrors: validationErrors
          }));
          if (!validationErrors) {
            showErrorNotification((error.response?.data?.message as string) || Messages.FailedGetAttendance);
          }
        }
      );
    },
    [snackbarProps, url]
  );

  useEffect(
    () => {
      getAttendance(attendanceFilters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attendanceFilters.fromDate, attendanceFilters.toDate, getAttendance]
  );

  const getAttendanceForDate = useCallback(
    async (date: Date) => {
      const dateOffset = new Date(date).getTimezoneOffset();

      setAttendanceState((state) => ({ ...state, isLoadingForDate: true }));
      try {
        const { data } = await apiService.get<AttendanceExtended[]>(url, {
          fromDate: addMinutes(date, -dateOffset),
          toDate: addMinutes(date, -dateOffset)
        });

        setAttendanceState((state) => ({
          ...state,
          isLoadingForDate: false,
          attendanceForDate: data
        }));
      } catch (error) {
        setAttendanceState((state) => ({
          ...state,
          isLoadingForDate: false,
          attendanceForDate: []
        }));
        showNotification(snackbarProps, false, Messages.FailedGetAttendance, true);
      }
    },
    [snackbarProps, url]
  );

  return {
    attendanceState: attendanceState,
    setAttendanceState: setAttendanceState,
    getAttendance: getAttendance,
    getAttendanceForDate
  };
};

export const getQueryParamsForAttendanceFilters = (
  attendanceFilters: FetchOptionsWithFromToDatesState & PublicAttendanceInterface
) => {
  const { fromDate, toDate, fetchDataOptions, ...others } = attendanceFilters;
  const { filters, sortBy } = fetchDataOptions;
  const fromZoneOffset = new Date(fromDate).getTimezoneOffset();
  const toZoneOffset = new Date(toDate).getTimezoneOffset();

  return {
    fromDate: addMinutes(fromDate, -fromZoneOffset),
    toDate: addMinutes(toDate, -toZoneOffset),
    ...getFiltersQueryParameters(filters),
    sortBy: mapReactTableSortToApiSort(sortBy),
    ...others
  };
};
