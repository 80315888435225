import { MenuItem, TextFieldProps } from '@mui/material';
import React from 'react';
import { Grades } from '@efacity/common';
import { defaultRHFSetValueOptions, FormTextInput } from './FormTextInput';
import { useFormContext } from 'react-hook-form';

interface GradeSelectorProps {
  name: string;
  onGradeChanged?: (gradeSortValue: number) => void;
}

export const FormGradeSelector: React.FC<GradeSelectorProps & TextFieldProps> = ({
  name,
  required = false,
  onGradeChanged,
  ...props
}) => {
  const methods = useFormContext();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    methods.setValue(name, event.target.value, defaultRHFSetValueOptions);
    if (onGradeChanged) {
      onGradeChanged(parseInt(event.target.value));
    }
  };

  return (
    <FormTextInput select name={name} required={required} onChange={onChange} {...props}>
      {!required && (
        <MenuItem key={'gradeSelector_None'} value="">
          None
        </MenuItem>
      )}
      {Object.values(Grades).map((grade, index) => (
        <MenuItem key={`gradeSelector${grade.value}key${index}`} value={grade.sortValue}>
          {grade.displayText}
        </MenuItem>
      ))}
    </FormTextInput>
  );
};
