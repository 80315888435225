import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { PATHS, toPath } from '@efacity/routing';
import { efacityOrgContacts, OrganizationInformation } from '@efacity/react-next-sc';
import { Footer } from '@efacity/react-next-sc';
import { ApiService } from '../../apiServices/apiService';

const logoStyle = {
  maxWidth: '160px',
  width: '100%',
  height: 'auto'
};

const signUpMessage = (
  <div>
    <div>Check your email and confirm your email address by clicking on the verification link we sent you.</div>
    <br />
    <div>
      If there is no email, please wait, because it may take up to 5 minutes for the email to arrive. Also check your
      spam folder.
    </div>
  </div>
);
const instructorMessage = (
  <div>
    <div>We sent your request to Organization Administrator.</div>
    <br />
    After he confirms your request you will receive the email.
    <div></div>
  </div>
);

const apiService = new ApiService();

export const ConfirmationPage: React.FC = () => {
  const { search } = useLocation();
  const { messageId, orgId } = queryString.parse(search);

  const [organizationLogosState, setOrganizationLogosState] = useState<OrganizationInformation>(efacityOrgContacts);

  useEffect(() => {
    const getOrganizationLogos = async () => {
      const { data } = await apiService.get<OrganizationInformation>(`/org/${orgId}/logos`);
      setOrganizationLogosState(data);
    };
    if (orgId) getOrganizationLogos();
  }, [orgId]);

  const gotoLandingPage = () => {
    window.location.href = orgId ? toPath(PATHS.orgLanding, { orgId: orgId }) : PATHS.landing;
  };

  const message = messageId ? (messageId === '2' ? instructorMessage : signUpMessage) : signUpMessage;

  return (
    <div
      style={{
        textAlign: 'center',
        minHeight: '100vh',
        display: 'flex',
        alignContent: 'space-between',
        flexDirection: 'column',
        marginLeft: 15,
        marginRight: 15
      }}
      data-testid="no-match"
    >
      <div style={{ paddingTop: 40 }}>
        <a href={'/'} onClick={gotoLandingPage}>
          <img src={'/assets/images/EfacityLogoWhite_256_70.svg'} style={logoStyle} alt="Efacity Logo" />
        </a>
        <Typography
          component="h3"
          color="primary"
          style={{ fontSize: 20, textAlign: 'center', marginTop: 50, marginBottom: 15 }}
        >
          {message}
          <br />
          <br />
          <Button color="primary" variant="contained" data-testid="publishButtonId" onClick={gotoLandingPage}>
            Ok
          </Button>
        </Typography>
      </div>
      <Footer orgInfo={organizationLogosState} />
    </div>
  );
};
