import { apiService, Organization } from '@efacity/frontend-shared';
import { useCallback, useState } from 'react';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { useSnackbar } from 'notistack';
import { Messages } from '@efacity/common';
import { FetchDataOptions } from '../../components/Table/Table';

export interface OrganizationsResponse {
  data: Organization[];
  total?: number;
}

export interface OrganizationsState {
  list: Organization[];
  isLoading: boolean;
  total: number;
}
export const defaultOrganizationCreditsState: OrganizationsState = {
  list: [],
  total: 0,
  isLoading: false
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const useOrganizationsLoader = (url = '/organizations') => {
  const [organizationsState, setOrganizationsState] = useState<OrganizationsState>({
    list: [],
    total: 0,
    isLoading: true
  });
  const snackbarProps = useSnackbar();

  const getOrganizations = useCallback(
    async ({ pageIndex, pageSize, sortBy }: FetchDataOptions) => {
      const showErrorNotification = (message: string) => {
        setOrganizationsState(defaultOrganizationCreditsState);
        showNotification(snackbarProps, false, message, true);
      };

      try {
        const response = await apiService.get<OrganizationsResponse>(url, { page: pageIndex, perPage: pageSize });

        setOrganizationsState({ list: response.data.data, total: response.data.total, isLoading: false });
      } catch (error) {
        setOrganizationsState(defaultOrganizationCreditsState);
        showErrorNotification((error.response?.data?.message as string) || Messages.FailedOrganizationsList);
      }
    },
    [snackbarProps, url]
  );
  return { organizationsState, getOrganizations };
};
