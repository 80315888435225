import React, { useCallback, useState } from 'react';
import { apiService, ConfirmationModal } from '@efacity/frontend-shared';
import { PATHS } from '@efacity/routing';
import Table, { FetchDataOptions, initialFetchDataOptions, PaginationTypes } from '../../components/Table/Table';
import { useSnackbar } from 'notistack';
import SelectPaymentPlatformModal from './SelectPaymentPlatformModal/SelectPaymentPlatformModal';
import StartConnectModal from '../../components/StartConnectModal/StartConnectModal';
import OrganizationTransfersModal from './OrganizationTransfersModal/OrganizationTransfersModal';
import { useOrganizationColumns } from './useOrganizationColumns';
import { useOrganizationsLoader } from './useOrganizationsLoader';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { PageTitle } from '@efacity/react-next-sc';

export const OrganizationsContainer: React.FC = () => {
  const snackbarProps = useSnackbar();
  const [fetchOptions, setFetchOptions] = useState<FetchDataOptions>(initialFetchDataOptions);

  const {
    organizationsState: { list: organizations, total, isLoading },
    getOrganizations
  } = useOrganizationsLoader();
  const storeFetchOptionsAndGetOrganizations = useCallback(
    (fetchDataOptions: FetchDataOptions) => {
      setFetchOptions(fetchDataOptions);
      getOrganizations(fetchDataOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getOrganizations, setFetchOptions]
  );

  const [accountModalProps, setAccountModalProps] = useState({
    accountId: '',
    schoolId: '',
    schoolName: '',
    isOpen: false
  });
  const defaultConnectModalState = {
    isOpen: false,
    isOperating: false,
    id: null,
    message: '',
    connectName: ''
  };
  const [connectModalState, setConnectModalState] = useState(defaultConnectModalState);

  const handleStartAccountModal = (accountId: string, schoolId: string, schoolName: string) => {
    setAccountModalProps({
      accountId: accountId,
      schoolId: schoolId,
      schoolName: schoolName,
      isOpen: true
    });
  };
  const onCloseSelectStripeAccountModal = () => {
    setAccountModalProps({
      ...accountModalProps,
      isOpen: false
    });
    getOrganizations(fetchOptions);
  };

  const onConnectCLick = (schoolId: string) => {
    setConnectModalState({
      isOpen: true,
      isOperating: false,
      id: schoolId,
      message:
        'You are about to Connect Stripe Account of selected Organization to Efacity.<br/>Please, login into Organization Stripe account before clicking "Start" button.',
      connectName: ''
    });
  };

  const onDisconnectCLick = (schoolId: string) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        'You are about to Disconnect Organization from Efacity.<br/>' +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      name: '',
      orgId: schoolId,
      transferId: null,
      onConfirm: performStandardStripeDisconnect
    });
  };

  const performStandardStripeConnect = () => {
    localStorage.setItem('orgId', connectModalState.id);
    localStorage.setItem('connectName', connectModalState.connectName);
    apiService.post(`/stripe/connect/forEfacity/${connectModalState.id}`, {}).then(
      (result: any) => {
        window.location.href = result.data;
      },
      (error) => {
        showNotification(snackbarProps, false, error.response.data.message as string, true);
      }
    );
  };

  const performStandardStripeDisconnect = (currentConfirmationModalState) => {
    setConfirmationModalState({ ...currentConfirmationModalState, isOperating: true });

    apiService.post(`/stripe/connect/forEfacity/${currentConfirmationModalState.orgId}/disconnect`, {}).then(
      (result: any) => {
        setConfirmationModalState({ ...defaultConfirmationModalState });
        showNotification(snackbarProps, true, result.data.message as string, false);
        getOrganizations(fetchOptions);
      },
      (error) => {
        setConfirmationModalState({ ...currentConfirmationModalState, isOperating: false });
        showNotification(snackbarProps, false, error.response.data.message as string, true);
      }
    );
  };

  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    name: '',
    orgId: null,
    transferId: null,
    onConfirm: null
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);

  const defaultOrganizationTransferModalState = {
    open: false,
    school: null,
    onClose: null
  };
  const [organizationTransferModalState, setOrganizationTransferModalState] = useState(
    defaultOrganizationTransferModalState
  );
  const startOrganizationTransferModal = (school) => {
    setOrganizationTransferModalState({ open: true, school: school, onClose: closeOrganizationTransferModal });
  };
  const closeOrganizationTransferModal = (reloadData: boolean) => {
    setOrganizationTransferModalState({ ...defaultOrganizationTransferModalState });
    if (reloadData) {
      getOrganizations(fetchOptions);
    }
  };

  const columns = useOrganizationColumns(
    handleStartAccountModal,
    startOrganizationTransferModal,
    onConnectCLick,
    onDisconnectCLick,
    connectModalState.isOperating
  );

  return (
    <div>
      <PageTitle title="Organizations" addButtonLink={PATHS.addSchool} />
      <Table
        loadingSkeletonHeight={41}
        columns={columns}
        data={organizations}
        loading={isLoading}
        fetchData={storeFetchOptionsAndGetOrganizations}
        pageCount={total}
        paginationType={PaginationTypes.ServerSide}
        initialSortBy={[{ id: 'name', desc: false }]}
      />
      {accountModalProps.isOpen && (
        <SelectPaymentPlatformModal
          orgId={accountModalProps.schoolId}
          open={accountModalProps.isOpen}
          schoolName={accountModalProps.schoolName}
          organizationStripeAccountId={accountModalProps.accountId}
          onClose={onCloseSelectStripeAccountModal}
        />
      )}
      {connectModalState.isOpen && (
        <StartConnectModal
          open={connectModalState.isOpen}
          onClose={() => setConnectModalState({ ...defaultConnectModalState, isOpen: false })}
          onStart={performStandardStripeConnect}
          message={connectModalState.message}
          connectName={connectModalState.connectName}
          onNameChanged={(event) => setConnectModalState({ ...connectModalState, connectName: event.target.value })}
        />
      )}
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState({ ...defaultConfirmationModalState })}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
      {organizationTransferModalState.open && (
        <OrganizationTransfersModal
          open={organizationTransferModalState.open}
          orgId={organizationTransferModalState.school._id}
          schoolName={organizationTransferModalState.school.name}
          connectedAccounts={
            organizationTransferModalState.school?.paymentAccountId?.stripePlatformAccount?.connectedAccounts || []
          }
          organizationTransfers={organizationTransferModalState.school?.organizationTransfers || []}
          onClose={(reloadData) => {
            setOrganizationTransferModalState({ ...defaultOrganizationTransferModalState });
            if (reloadData) getOrganizations(fetchOptions);
          }}
        />
      )}
    </div>
  );
};
