import { headerHeight } from '@efacity/common';

export const headerSx = {
  header: {
    zIndex: 11,
    height: `${headerHeight}px`,
    left: '0px',
    right: '0px',
    top: '0px',
    position: 'sticky',
    overflowX: 'hidden',
    transition: 'transform 0.5s ease-in-out 0s'
  } as React.CSSProperties,
  burgerWrapperSx: {
    display: { xs: 'flex', md: 'none', marginRight: '5px' }
  },
  orgNameContainerSx: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    marginLeft: '5px',
    marginRight: '5px',
    fontSize: { xs: 14, sm: 16, md: 20 },
    '& > div': {
      justifyContent: 'center'
    }
  },
  appBarInner: {
    paddingTop: {
      xs: '5px',
      sm: '5px',
      md: '10px'
    },
    paddingBottom: {
      xs: '5px',
      sm: '5px',
      md: '10px'
    },
    height: `${headerHeight}px`,
    paddingLeft: '10px',
    paddingRight: '10px',
    alignItems: 'center'
  }
};
