import { Messages } from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { OrgIdInvoiceIdParamType } from '@efacity/frontend-next-shared/utils';
import StripePaymentFormContainer, { PaymentDetails } from './StripePaymentFormContainer';
import InvoiceItemAdjustmentModal from './InvoiceItemAdjustmentModal';
import { useInvoicePayment } from './useInvoicePayment';
import { LinearProgressWithMessage, PaymentDoneModal, ThreeDConfirmationModal } from '@efacity/frontend-shared';
import { registerInvoicePaymentGtag } from './registerInvoicePaymentGtag';

export const adjustmentModalDefaultState = {
  open: false,
  isAdjusting: false,
  invoiceItem: null
};

const InvoicePaymentByAdminContainer = () => {
  const { orgId, invoiceId } = useParams<OrgIdInvoiceIdParamType>();
  const [substituteFormMessage, setSubstituteFormMessage] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [adjustmentModalState, setAdjustmentModalState] = useState(adjustmentModalDefaultState);
  const onStartAdjustmentModal = (invoiceItem) => {
    setAdjustmentModalState({
      open: true,
      isAdjusting: false,
      invoiceItem: invoiceItem
    });
  };

  const handleAdjustInvoiceItem = async (invoiceItemId: string, newAmount: number) => {
    setAdjustmentModalState((state) => ({
      ...state,
      isAdjusting: true
    }));

    const isActionSuccess = await adjustInvoice(
      `/org/${orgId}/discounts/invoice/${invoiceId}/${invoiceItemId}/adjust`,
      newAmount
    );
    if (isActionSuccess) {
      setAdjustmentModalState(adjustmentModalDefaultState);
    } else {
      setAdjustmentModalState((state) => ({
        ...state,
        isAdjusting: false
      }));
    }
  };

  const [{ paymentState }, { paymentActions, registrationActions }] = useInvoicePayment(
    `/org/${orgId}/payments/${invoiceId}`
  );
  const {
    invoice,
    defaultCountry,
    isLoadingInvoices,
    isUpdatingInvoice,
    stripePublishableKey,
    credit,
    paymentAcceptanceMethod,
    paymentCards
  } = paymentState;

  const { setPaymentState, processServerSideInvoicePayment, verifyCouponDiscountForInvoice, adjustInvoice } =
    paymentActions;
  const { deleteDiscount, switchAdditionalOptionSelectionToInvoiceRegistration, deleteRegistrationFromInvoice } =
    registrationActions;

  const on3DSCompleteListenerFunction = (ev) => {
    if (ev.data.message === '3DS-authentication-complete') {
      on3DSComplete(ev.data.payment_intent, invoiceId);
    }
  };

  const on3DSComplete = async (payment_intent, invoiceId: string) => {
    window.removeEventListener('message', on3DSCompleteListenerFunction);

    setSubstituteFormMessage(Messages.ConfirmPayment);
    setRedirectUrl(null);
    const paymentInfoWithPaymentIntent: PaymentDetails = {
      paymentIntent: payment_intent as string
    };

    await callBackendProcessInvoicePayment(paymentInfoWithPaymentIntent, invoiceId);
  };
  const closeModalAndGotoSessions = (redirectUrl: string) => {
    setPaymentState((state) => ({ ...state, paymentDoneModal: { ...state.paymentDoneModal, open: false } }));
    navigate(redirectUrl);
  };

  const handleDiscountCodeChanged = (event) => {
    verifyCouponDiscountForInvoice(`/org/${orgId}/discounts/invoice/${invoiceId}/coupon/verify`, event.target.value);
  };

  const callBackendProcessInvoicePayment = async (paymentInfo: PaymentDetails, invoiceId: string) => {
    const { linkToPaymentReceiptPDF, redirectUrl, message, isError } =
      (await processServerSideInvoicePayment(`/org/${orgId}/payments/${invoiceId}`, paymentInfo)) || {};

    if (isError) {
      setPaymentState((state) => ({
        ...state,
        paymentDoneModal: {
          isOpen: true,
          isError: true,
          message: message,
          onClose: () => closeModalAndReloadInvoice(pathname)
        }
      }));
      return;
    }

    if (redirectUrl?.length > 0) {
      setSubstituteFormMessage(Messages.Stripe3DRedirect);
      window.addEventListener('message', on3DSCompleteListenerFunction, false);
      setRedirectUrl(redirectUrl);
      return;
    } else {
      setSubstituteFormMessage(null);
    }

    const sessionId = invoice?.invoiceItems[0]?.sessionId;
    const successPaymentRedirectUrl = sessionId
      ? `/admin/${orgId}/session/${sessionId}/students`
      : toPath(PATHS.sessions, { orgId: orgId });

    await registerInvoicePaymentGtag(`/org/${orgId}/payments/${invoice._id}/item-details`);
    setSubstituteFormMessage('Invoice paid...');

    setPaymentState((state) => ({
      ...state,
      invoice: {
        ...state.invoice,
        isPaid: true
      },
      paymentDoneModal: {
        isOpen: true,
        isError: false,
        message: message || Messages.PaymentSuccess,
        onClose: () => closeModalAndGotoSessions(successPaymentRedirectUrl)
      }
    }));

    if (linkToPaymentReceiptPDF) {
      window.open(linkToPaymentReceiptPDF);
    }
  };

  const closeModalAndReloadInvoice = (redirectUrl: string) => {
    setPaymentState((state) => ({ ...state, paymentDoneModal: { ...state.paymentDoneModal, isOpen: false } }));
    window.location.href = redirectUrl;
  };

  const handleDeleteAdditionalOption = async (sessionId: string, studentId: string, additionalOptionId: string) => {
    await switchAdditionalOptionSelectionToInvoiceRegistration(
      `/org/${orgId}/registrations/invoice/${invoiceId}/session/${sessionId}/student/${studentId}/additionalOption/${additionalOptionId}/${false}`
    );
  };

  const handleDeleteDiscount = async (invoiceItemId: string) => {
    await deleteDiscount(`/org/${orgId}/discounts/invoice/${invoiceId}/${invoiceItemId}/remove`);
  };

  const handleDeleteRegistration = async (registrationId: string, sessionId: string, studentId: string) => {
    const result = await deleteRegistrationFromInvoice(
      `/org/${orgId}/registrations/invoice/${invoiceId}/registration/${registrationId}/session/${sessionId}/student/${studentId}`
    );
    if (!result?.invoice) {
      setPaymentState((state) => ({
        ...state,
        paymentDoneModal: {
          isOpen: true,
          isError: false,
          message: Messages.LastRegistrationInInvoiceDeleted,
          onClose: () => closeModalAndGotoSessions(toPath(PATHS.sessions, { orgId: orgId }))
        }
      }));
    }
  };

  return (
    <div>
      {substituteFormMessage ? (
        <LinearProgressWithMessage message={substituteFormMessage} />
      ) : (
        <StripePaymentFormContainer
          invoice={invoice}
          credit={credit}
          isLoadingInvoices={isLoadingInvoices}
          stripePublishableKey={stripePublishableKey}
          paymentAcceptanceMethod={paymentAcceptanceMethod}
          processBackendInvoicePayment={callBackendProcessInvoicePayment}
          handleDiscountCodeChanged={handleDiscountCodeChanged}
          handleDeleteDiscount={handleDeleteDiscount}
          handleDeleteAdditionalOption={handleDeleteAdditionalOption}
          handleDeleteRegistration={handleDeleteRegistration}
          isUpdating={isUpdatingInvoice}
          initialCountry={defaultCountry}
          hasAdminRole={true}
          onStartAdjustmentModal={onStartAdjustmentModal}
          paymentCards={paymentCards}
        />
      )}
      <ThreeDConfirmationModal open={!!redirectUrl} redirectUrl={redirectUrl} />
      {adjustmentModalState.open && (
        <InvoiceItemAdjustmentModal
          adjustmentModalState={adjustmentModalState}
          handleClose={() => setAdjustmentModalState(adjustmentModalDefaultState)}
          onAdjust={handleAdjustInvoiceItem}
        />
      )}
      <PaymentDoneModal paymentDoneModalState={paymentState.paymentDoneModal} />
    </div>
  );
};

export default InvoicePaymentByAdminContainer;
