import React, { FC, FormEvent } from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { FormInputTextProps, groupWrapperStyle, textFieldStyle } from './FormTextInput';
import { CurrencyCode, getCurrencyDisplayByStringCode, getNestedValue } from '@efacity/common';
import { Controller, useFormContext } from 'react-hook-form';
import { CurrencyFormat } from './CurrencyFormat';

export const adornmentSx = {
  '& p': {
    position: 'absolute',
    bottom: '7px',
    margin: '0px',
    whiteSpace: 'noWrap',
    fontWeight: '600'
  }
};

const currencyTextFieldStyle = {
  ...textFieldStyle,
  '& p': {
    position: 'absolute',
    bottom: '-22px',
    marginLeft: '1px',
    whiteSpace: 'noWrap',
    margin: '3px'
  }
};

export type FormCurrencyInputProps = {
  name: string;
  currency: CurrencyCode;
  onValueChange?: (name: string, event: FormEvent) => void;
  forceErrorMessage?: string | null;
} & TextFieldProps;

export const FormCurrencyInput: FC<FormCurrencyInputProps & FormInputTextProps> = ({
  name,
  currency,
  forceErrorMessage,
  size,
  color,
  helperText = '',
  onValueChange = null,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    watch
  } = useFormContext();

  const value = watch(name);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field }) => {
        return (
          <div style={{ ...groupWrapperStyle }}>
            <TextField
              {...field}
              value={value / 100}
              variant="outlined"
              fullWidth={true}
              size={size || 'small'}
              color={color || 'primary'}
              sx={currencyTextFieldStyle}
              {...otherProps}
              error={forceErrorMessage ? true : !!getNestedValue(errors, name)}
              helperText={
                forceErrorMessage || (getNestedValue(errors, name)?.message as unknown as string) || helperText
              }
              InputProps={{
                inputComponent: CurrencyFormat,
                startAdornment: (
                  <InputAdornment position="start" sx={adornmentSx} data-testid="currency-icon">
                    {getCurrencyDisplayByStringCode(currency)}
                  </InputAdornment>
                )
              }}
              inputProps={{
                'data-testid': `${name}-form-amount-input`,
                onChange: (event) => onValueChange && onValueChange(name, event),
                style: { textAlign: 'right' }
              }}
            />
          </div>
        );
      }}
    />
  );
};
