import { apiService, getMergedFormValues } from '@efacity/frontend-shared';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { initialStudentFormValues } from '../../pages/CustomerStudents/StudentFormValues';
import { Messages, splitFullName } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

const initialStudentWithCustomersFormValues = {
  studentFormValues: initialStudentFormValues,
  customers: [],
  isLoading: false,
  requireStudentAge: false
};

export const useStudentFormValues = (orgId: string, studentId: string) => {
  const snackbarProps = useSnackbar();

  const [studentFormState, setStudentFormState] = useState(initialStudentWithCustomersFormValues);

  useEffect(() => {
    const getStudentWithCustomersById = async (orgId: string) => {
      setStudentFormState({ ...initialStudentWithCustomersFormValues, isLoading: true });

      try {
        const { data } = await apiService.get<any>(`/org/${orgId}/users/students/${studentId}`);
        const student = { ...data };

        setStudentFormState(() => ({
          studentFormValues: getMergedFormValues(initialStudentFormValues, {
            ...data.student,
            firstName: splitFullName(student.fullName).firstName,
            lastName: splitFullName(student.fullName).lastName
          }),
          customers: student.customers,
          isLoading: false,
          requireStudentAge: data.requireStudentAge
        }));
      } catch (error) {
        showNotification(snackbarProps, false, error.message || Messages.FailedGetStudent, true);
        setStudentFormState(initialStudentWithCustomersFormValues);
      }
    };

    getStudentWithCustomersById(orgId);
  }, [orgId, snackbarProps, studentId]);

  return {
    studentFormState
  };
};
