import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Slider, SliderProps } from '@mui/material';
import { DisplayOption, gradesArrayWithNumericValues } from '@efacity/common';

export interface GradesFiltersGroupProps {
  value: [string, string] | []; // array of all selected grades
  options: DisplayOption[];
  onChange: (gradesRange: [number, number]) => void;
}

/*
 * because slider takes only numeric values
 * need to convert grades to numbers
 * preK grade is -1
 * K grade is 0
 * adult is 13
 * and then as they are
 * */
export const GradesFiltersGroup: FC<GradesFiltersGroupProps> = ({ value = [], options = [], onChange }) => {
  const rangeMarks: SliderProps['marks'] = useMemo(
    () =>
      [...options.slice(0, 1), ...options.slice(gradesArrayWithNumericValues.length - 1)].map((displayOption) => ({
        value: displayOption.value as number,
        label: displayOption.displayText
      })),
    [options]
  );

  const [tempValue, setTempValue] = useState([]);
  // Because options may come from url as strings
  // need to parse them
  useEffect(() => {
    if (value.length === 0) {
      setTempValue([rangeMarks[0].value, rangeMarks[1].value]);
    } else {
      setTempValue([parseInt(value[0]), parseInt(value[1])]);
    }
  }, [rangeMarks, value]);

  const handleChange = (event, newValue) => {
    setTempValue(newValue);
  };

  const handleGradeSelected = (e, selectedGradesRange: [number, number]) => {
    onChange(selectedGradesRange);
  };

  function valueText(value) {
    return value;
  }

  function valueLabelFormat(value: number) {
    const gradeOption = options.find((grade) => grade.value === value);
    if (gradeOption) {
      return gradeOption.displayText;
    }
    return value;
  }

  return (
    <Box width="100%" px="26px">
      <Slider
        max={14}
        min={0}
        value={tempValue}
        // on change will fire for every move of slider
        // to prevent massive requests and overall state update in customer component
        // save value as temp value
        // and actually change filters only when change was commited
        // (user stopped to move slide i.e mouseup event was fired)
        onChange={handleChange}
        onChangeCommitted={handleGradeSelected}
        marks={rangeMarks}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valueText}
        // by default it will show the value
        // but we need to show grade name
        valueLabelFormat={valueLabelFormat}
      />
    </Box>
  );
};
