import React, { FC, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { Box, DialogActions, DialogContent, FormControlLabel, TextField } from '@mui/material';
import { CancelSubmitButtons } from '../../components/CancelSubmitButtond';
import { SessionWithNameAndSelection } from '@efacity/common';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';
import { ModalDialogTitle } from '@efacity/react-next-sc';

export interface SessionsInSessionPeriodModalProps {
  isOpen: boolean;
  isSubmitting: boolean;
  sessions: SessionWithNameAndSelection[];
  onSubmit: () => void;
  onCancel: () => void;
  onUpdate: (sessions: SessionWithNameAndSelection[], status: boolean) => void;
}

export const SessionsInTagPeriodModal: FC<SessionsInSessionPeriodModalProps> = ({
  isOpen,
  isSubmitting,
  sessions,
  onSubmit,
  onCancel,
  onUpdate
}) => {
  const [selectAllState, setSelectAllState] = useState(false);
  const [searchSessionNameString, setSearchSessionNameString] = useState('');

  const filteredSessions =
    searchSessionNameString.length > 0
      ? sessions.filter((session) => session.name.toLowerCase().indexOf(searchSessionNameString.toLowerCase()) > -1)
      : sessions;

  const onSearchChange = (event) => {
    setSearchSessionNameString(event.target.value);
  };

  const onSessionSelectionChange = (sessionId: string, newStatus: boolean) => {
    const targetSession = sessions.find((session) => session._id === sessionId);
    if (targetSession) {
      onUpdate([targetSession], newStatus);
    }
  };

  const onAllSessionSelectionChange = (newStatus: boolean) => {
    setSelectAllState(newStatus);
    onUpdate(filteredSessions, newStatus);
  };

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={isOpen} disableEscapeKeyDown maxWidth="md">
      <ModalDialogTitle text="Select sessions to be tagged with Session Period" showUnderline={false} />
      <DialogContent dividers>
        <div>
          <TextField
            value={searchSessionNameString}
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: <SearchIcon color="primary" />
            }}
            onChange={onSearchChange}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectAllState}
                onClick={(event: any) => onAllSessionSelectionChange(event.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={isSubmitting}
              />
            }
            label={<div style={{ fontSize: 16 }}> {selectAllState ? 'Reset All' : 'Select all'}</div>}
            aria-errormessage={'test'}
          />
        </div>
        <Box style={{ maxHeight: 250, overflowY: 'scroll', border: '1px solid #C4C4C4', padding: 10 }}>
          {filteredSessions.length > 0 ? (
            filteredSessions.map((session) => {
              return (
                <div key={`session${session._id}InTagSessionPeriod`} style={{ margin: 0 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={session.isSelected}
                        onClick={(event: any) => onSessionSelectionChange(session._id, event.target.checked)}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        style={{ margin: 0, padding: 5 }}
                        disabled={isSubmitting}
                      />
                    }
                    label={<div style={{ fontSize: 16 }}> {`${session.name}`}</div>}
                    style={{ margin: 0 }}
                    aria-errormessage={'test'}
                  />
                </div>
              );
            })
          ) : (
            <Typography>No match sessions</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <CancelSubmitButtons onCancel={onCancel} onSubmit={onSubmit} disabled={isSubmitting} />
      </DialogActions>
    </Dialog>
  );
};
