import React, { FC } from 'react';
import { ActivityTypes, activityTypesSelectOptions } from '@efacity/common';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export interface ActivityTypeCheckerProps {
  name?: string;
  message: string;
  onClick?: ((activityType: ActivityTypes) => void) | null;
  checkItemDisabled?: ((activityType: ActivityTypes) => boolean) | null;
  includeEnrollment?: boolean;
}

export const ActivityTypeChecker: FC<ActivityTypeCheckerProps> = ({
  name = 'activityTypes',
  message,
  onClick = null,
  checkItemDisabled = null,
  includeEnrollment = false
}) => {
  const methods = useFormContext();

  const toggleActivityType = (activityType: ActivityTypes) => {
    const chosenTypesArray = methods.getValues(name);
    const index = chosenTypesArray.indexOf(activityType as string);
    if (index > -1) {
      //remove an option if it already exists in the array
      chosenTypesArray.splice(index, 1);
      methods.setValue(name, chosenTypesArray, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true
      });
    } else {
      methods.setValue(name, [...chosenTypesArray, activityType], {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true
      });
    }
  };

  const options = includeEnrollment
    ? activityTypesSelectOptions
    : activityTypesSelectOptions.slice(0, activityTypesSelectOptions.length - 1);

  return (
    <div>
      <div style={{ fontSize: 18 }}>{message}</div>
      {options.map((activityType) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                value={activityType.value}
                checked={methods.watch(name).indexOf(activityType.value) > -1}
                onChange={(_) => {
                  onClick
                    ? onClick(activityType.value as ActivityTypes)
                    : toggleActivityType(activityType.value as ActivityTypes);
                }}
                disabled={checkItemDisabled ? checkItemDisabled(activityType.value as ActivityTypes) : false}
                name={name}
              />
            }
            label={activityType.displayText}
            key={activityType.value}
          />
        );
      })}
    </div>
  );
};

export default ActivityTypeChecker;
