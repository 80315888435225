import React, { useEffect } from 'react';
import {
  AdditionalSessionOptionFormValues,
  apiService,
  OrgAdminPaymentLink,
  WithLoading
} from '@efacity/frontend-shared';
import { Grid } from '@mui/material';
import FormPageContainer from '../../components/FormPageContainer/FormPageContainer';
import { FormCancelSubmitButtons } from '@efacity/frontend-next-shared/forms';
import { Activity, CurrencyCode, FormMode, ValidationErrors } from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useAdditionalSessionOptionLoader } from '../../hooks/useAdditionalSessionOptionLoader';
import { additionalSessionOptionFormValuesValidationSchema } from './additionalSessionOptionFormValuesValidationSchema';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addServerErrors, FormCurrencyInput, FormDatePicker, FormTextInput } from '@efacity/react-hook-form-mui';
import { useOrganizationRegionsOptions } from '../../hooks/useOrganizationRegionsOptions';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { PageTitle } from '@efacity/react-next-sc';

export interface AdditionalSessionOptionsFormProps {
  mode: FormMode;
}

export type AdditionalOptionIdParamType = Partial<{
  additionalOptionId: string;
  orgId: string;
}>;

const AdditionalSessionOptionForm: React.FC<AdditionalSessionOptionsFormProps> = ({ mode }) => {
  const navigate = useNavigate();
  const snackbarProps = useSnackbar();
  const { orgId, additionalOptionId } = useParams<AdditionalOptionIdParamType>();

  const { additionalSessionOptionFormState } = useAdditionalSessionOptionLoader(orgId, additionalOptionId, mode);

  const {
    organizationRegions: { isPaymentsAllowed }
  } = useOrganizationRegionsOptions(orgId);

  const handleCancelClick = () => {
    navigate(toPath(PATHS.additionalOptions, { orgId: orgId }));
  };

  const methods = useForm<AdditionalSessionOptionFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(additionalSessionOptionFormValuesValidationSchema),
    defaultValues: additionalSessionOptionFormState.additionalSessionOptionFormValue,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUseNativeValidation: false
  });
  const { isSubmitting, isValid, isDirty } = methods.formState;

  useEffect(
    () => {
      methods.reset(additionalSessionOptionFormState.additionalSessionOptionFormValue);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(additionalSessionOptionFormState.additionalSessionOptionFormValue)]
  );

  const handleSubmit = async (
    formValues: AdditionalSessionOptionFormValues,
    setError: (fieldName: keyof AdditionalSessionOptionFormValues, error: { type: string; message: string }) => void
  ) => {
    const actionSuccessful = () => {
      navigate(toPath(PATHS.additionalOptions, { orgId: orgId }));
    };
    const actionFailure = (error) => {
      const { data: errorData } = error.response;
      if (errorData.validationErrors) {
        addServerErrors<AdditionalSessionOptionFormValues>(errorData.validationErrors, setError);
      } else {
        showNotification(snackbarProps, false, errorData.message as string);
      }
    };

    if (mode === FormMode.Edit) {
      return apiService
        .patch<{ curriculum: Activity; message: string }>(
          `/org/${orgId}/additionalOption/${additionalOptionId}`,
          formValues
        )
        .then(({ data }) => {
          actionSuccessful();
        })
        .catch((error) => actionFailure(error));
    } else {
      return apiService
        .post<{ message: string }>(`/org/${orgId}/additionalOption`, formValues)
        .then(({ data }) => actionSuccessful())
        .catch((error) => actionFailure(error));
    }
  };

  const submitForm = (values: AdditionalSessionOptionFormValues) => {
    return handleSubmit(values, methods.setError);
  };

  return (
    <div>
      <PageTitle title={mode === FormMode.Edit ? 'Edit Additional Option' : 'Add Additional Option'} underlined />
      <FormPageContainer>
        <WithLoading
          isLoading={additionalSessionOptionFormState.isLoading}
          message={'Loading Additional Option...'}
          style={{ width: '50%' }}
        >
          <FormProvider {...methods}>
            <form noValidate onSubmit={methods.handleSubmit(submitForm)}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <FormTextInput name="name" label="Name *" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormDatePicker name="expiresOn" label="Available until" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput name="description" label="Description" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormCurrencyInput
                    name="price"
                    label="Price *"
                    value={methods.getValues('price') / 100}
                    currency={methods.getValues('currencyCode') as CurrencyCode}
                    disabled={!isPaymentsAllowed}
                    forceErrorMessage={isPaymentsAllowed ? null : ValidationErrors.ConnectRequired}
                  />
                  {!isPaymentsAllowed && <OrgAdminPaymentLink orgId={orgId} />}
                </Grid>
                <Grid item xs={12}>
                  <FormCancelSubmitButtons
                    isFormValid={isValid && isDirty}
                    mode={mode}
                    isSubmitting={isSubmitting}
                    onCancel={handleCancelClick}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </WithLoading>
      </FormPageContainer>
    </div>
  );
};

export default AdditionalSessionOptionForm;
