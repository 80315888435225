import { InstanceTemporality } from '@efacity/common';
import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import PageNavigationTabs from '../../components/PageNavigatonTabs/PageNavigationTabs';
import { PageTitle } from '@efacity/react-next-sc';

const OrganizationRegistrationsContainer: React.FC = () => {
  const tabsList = useMemo(() => {
    return [
      {
        label: 'Active',
        link: InstanceTemporality.Current,
        disabled: false
      },
      {
        label: 'Completed',
        link: InstanceTemporality.Historic,
        disabled: false
      },
      {
        label: 'Cancelled',
        link: InstanceTemporality.Canceled,
        disabled: false
      }
    ];
  }, []);

  return (
    <Box>
      <PageTitle title="Registrations" />
      <PageNavigationTabs tabs={tabsList} />
      <div style={{ marginTop: '15px' }}>
        <Outlet />
      </div>
    </Box>
  );
};

export default OrganizationRegistrationsContainer;
