import { FC } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { ModalDialogTitle } from '@efacity/react-next-sc';
import { EditStudentWithCustomerInfoForm } from './EditStudentWithCustomerInfoForm';
import { ContentTabs } from '../PageNavigatonTabs/ContentTabs';
import { StudentAgreementsTable } from './StudentAgreementsTable';

export enum StudentWithCustomerInfoTabs {
  MAIN = 'Main',
  AGREEMENTS = 'Agreements'
}

export interface EditStudentCustomerModalProps {
  open: boolean;
  orgId: string;
  studentId: string;
  studentName: string;
  onClose: (reloadData: boolean) => void;
  allowFullEditStudent?: boolean;
  tab: StudentWithCustomerInfoTabs;
  setTab: (tab: StudentWithCustomerInfoTabs) => void;
}

const EditStudentWithCustomerInfoModal: FC<EditStudentCustomerModalProps> = ({
  open,
  orgId,
  studentId,
  studentName,
  onClose,
  tab = StudentWithCustomerInfoTabs.MAIN,
  setTab,
  allowFullEditStudent = true
}) => {
  const handleCancel = (reloadData: boolean) => {
    onClose(reloadData);
  };

  const tabs = {
    [StudentWithCustomerInfoTabs.MAIN]: {
      label: 'Main',
      content: (
        <EditStudentWithCustomerInfoForm
          allowFullEditStudent={allowFullEditStudent}
          orgId={orgId}
          studentId={studentId}
          onClose={onClose}
        />
      )
    },
    [StudentWithCustomerInfoTabs.AGREEMENTS]: {
      label: 'Agreements',
      content: <StudentAgreementsTable studentId={studentId} orgId={orgId} />
    }
  };

  return (
    <Dialog open={open} onClose={() => handleCancel(false)} maxWidth="md" id="edit-student-with-customer" fullWidth>
      <ModalDialogTitle text={`Student: ${studentName}`} onClose={() => handleCancel(false)} />
      <DialogContent sx={{ minHeight: '70vh' }}>
        <ContentTabs currentTab={tab} tabs={tabs} setTab={setTab} />
      </DialogContent>
    </Dialog>
  );
};

export default EditStudentWithCustomerInfoModal;
