import { EnhancedColumn } from '../../components/Table/Table';
import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import MustAgreeCheckedIcon from './MustAgreeCheckedIcon';
import MustAgreeUnCheckedIcon from './MustAgreeUnCheckedIcon';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import CancelIconButton from '../../components/CancelIconButton';
import { formatDateToMonthDayYearInLocale } from '@efacity/common';

const agreementNameStyle = {
  marginLeft: 6,
  cursor: 'pointer'
};

const useCustomerAgreementsTableColumns = (onSelectAgreement, handleDeleteAgreement) => {
  return useMemo(() => {
    const columns: EnhancedColumn<any>[] = [
      {
        Header: 'Name',
        accessor: 'agreementName',
        disableFilters: true,
        columnStyles: { ...adminSectionTableFontSize },
        Cell: ({ row }) => {
          return (
            <div style={{ display: 'flex' }}>
              {row.original.customerMustSignAgreement ? (
                <MustAgreeCheckedIcon color="primary" />
              ) : (
                <MustAgreeUnCheckedIcon color="primary" />
              )}
              <Typography
                color="primary"
                style={agreementNameStyle}
                onClick={() => onSelectAgreement(row.original._id)}
              >
                {row.original.agreementName}
              </Typography>
            </div>
          );
        }
      },
      {
        Header: 'Created on',
        accessor: 'createdAt',
        disableFilters: true,
        columnStyles: { ...adminSectionTableFontSize },
        Cell: ({ row }) => <>{formatDateToMonthDayYearInLocale(row.original.createdAt)}</>
      },
      {
        Header: 'Valid From',
        accessor: 'validFrom',
        disableFilters: true,
        columnStyles: { ...adminSectionTableFontSize },
        Cell: ({ row }) => <>{formatDateToMonthDayYearInLocale(row.original.validFrom)}</>
      },
      {
        Header: 'Valid To',
        accessor: 'validTo',
        disableFilters: true,
        columnStyles: { ...adminSectionTableFontSize },
        Cell: ({ row }) => <>{formatDateToMonthDayYearInLocale(row.original.validTo)}</>
      },
      {
        Header: '',
        accessor: 'deleteAction',
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <CancelIconButton onClick={() => handleDeleteAgreement(row.original._id)} data-testid="delete-agreement" />
          );
        }
      }
    ];

    return columns;
  }, [handleDeleteAgreement, onSelectAgreement]);
};

export default useCustomerAgreementsTableColumns;
