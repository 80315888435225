import { ActivityFormValues } from '@efacity/frontend-shared';
import {
  activityLevels,
  ActivityTypes,
  CurrencyCode,
  DurationType,
  emptyRichEdit,
  Grades,
  mapSchoolGradeToAge,
  SchoolGradeStudentLevelType
} from '@efacity/common';

export const blankActivityFormValues: ActivityFormValues = {
  name: '',
  shortDescription: '',
  longDescription: emptyRichEdit,
  descriptionOnSessionSelection: emptyRichEdit,
  imageFullUrl: '',
  categories: [],
  tags: [],
  type: ActivityTypes.Course,
  durationType: DurationType.Fixed,
  level: activityLevels[0].value as number,
  teacherInstructions: '',
  defaultSessionCurrency: CurrencyCode.USD,
  studentLevel: {
    type: SchoolGradeStudentLevelType.value,
    fromSchoolGradeNumeric: Grades.PRE_K.sortValue,
    toSchoolGradeNumeric: Grades.GRADE_ADULT.sortValue,
    fromAge: mapSchoolGradeToAge(Grades.PRE_K.sortValue),
    toAge: mapSchoolGradeToAge(Grades.GRADE_ADULT.sortValue)
  },
  numberOfInstances: 1,
  resources: [],
  defaultSessionPrice: 0,
  fee: 10,
  additionalSessionOptions: [],
  sharedWith: [],
  version: '1.0'
};
