import {
  InstanceTemporality,
  Messages,
  Roles,
  SessionExportOption,
  adminAndIndependentRoles,
  nowUTC
} from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { apiService } from '@efacity/frontend-shared';
import { hasFrontendAccessWithRoleForOrganization, useAuth } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import { Box, Tooltip, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { addDays } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { ExportButton } from '../../components/Buttons/ExportButton';
import StartEndDatesFilter from '../../components/StartEndDatesFilter/StartEndDatesFilter';
import Table, { FetchDataOptions, PaginationTypes, initialFetchDataOptions } from '../../components/Table/Table';
import { handleSaveFile } from '../../utils/handleSaveFileFromResponse';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';
import { FetchOptionsWithFromToDatesState } from '../Attendance/useAttendanceLoader';
import CancellationModal from './CancellationModal';
import StudentExportOptionsModal from './StudentExportOptionsModal';
import { useSessionTabActions } from './useSessionTabActions';
import useSessionsTableColumns from './useSessionsTableColumns';

interface SessionsTableProps {
  sessionType: InstanceTemporality;
}

const SessionsTable: React.FC<SessionsTableProps> = ({ sessionType }) => {
  const { orgId } = useParams<OrgIdParamType>();
  const navigate = useNavigate();
  const {
    authState: { user }
  } = useAuth();
  const isUserIndependentTeacher = hasFrontendAccessWithRoleForOrganization(
    orgId,
    [Roles.IndependentTeacher],
    user.adminAccesses || []
  );

  const snackbarProps = useSnackbar();

  const isUserAllowedToPerformSessionActions = useMemo<boolean>(() => {
    return hasFrontendAccessWithRoleForOrganization(orgId, adminAndIndependentRoles, user?.adminAccesses || []);
  }, [orgId, user]);

  const [
    {
      sessionActionsState: { isUpdating: isSessionUpdating },
      cancellationModalOpened,
      modalCallbackAdditionalProps,
      sessionsListActionState: { list, isLoadingSessionsList, total, isImporting, isUpdating }
    },
    {
      fetchSessions,
      onChangeSessionStatus,
      onCancelSession,
      onRestoreSession,
      onOpenCancellationModal,
      onCloseCancellationModal,
      setModalCallbackAdditionalProps
    }
  ] = useSessionTabActions(orgId);

  const now = nowUTC();
  const [fetchDataOptionsWithDates, setFetchDataOptionsWithDates] = useState<FetchOptionsWithFromToDatesState>({
    fromDate: addDays(now, -30),
    toDate: now,
    fetchDataOptions: initialFetchDataOptions
  });

  const setFetchDataOptionsAndGetSessions = useCallback(
    (fetchDataOptions: FetchDataOptions) => {
      const newFetchOptionsWithDates = {
        fetchDataOptions: { ...fetchDataOptions },
        fromDate: fetchDataOptionsWithDates.fromDate,
        toDate: fetchDataOptionsWithDates.toDate
      };
      setFetchDataOptionsWithDates(newFetchOptionsWithDates);
      fetchSessions(sessionType, newFetchOptionsWithDates);
    },
    [fetchSessions, fetchDataOptionsWithDates.fromDate, fetchDataOptionsWithDates.toDate, sessionType]
  );

  const handleDatesChanged = useCallback(
    (newFromDate: Date, newToDate: Date) => {
      const newFetchOptionsWithDates = {
        ...fetchDataOptionsWithDates,
        fromDate: newFromDate,
        toDate: newToDate
      };
      setFetchDataOptionsWithDates(newFetchOptionsWithDates);
      fetchSessions(sessionType, newFetchOptionsWithDates);
    },
    [fetchSessions, fetchDataOptionsWithDates, sessionType]
  );

  const timeHeader = list?.length > 0 ? `Time (${list[0].timeZone})` : 'Time';
  const theme = useTheme();
  const widthMd = useMediaQuery(theme.breakpoints.up('md'));
  const widthSm = useMediaQuery(theme.breakpoints.up('sm'));
  const width = widthMd ? 'md' : widthSm ? 'sm' : 'xs';

  const handleRestoreSession = async (sessionId: string) => {
    await onRestoreSession(sessionId);
  };

  const onSessionStatusChanged = async (sessionId: string, setRowState, sessionStatus) => {
    await onChangeSessionStatus(sessionId, sessionStatus, setRowState);
  };

  const [{ columns }] = useSessionsTableColumns(
    orgId,
    isUserAllowedToPerformSessionActions,
    isUserIndependentTeacher,
    {
      onCloneSession,
      handleRestoreSession,
      onShowCancellationModal,
      onSessionStatusChanged
    },
    width,
    timeHeader
  );
  async function onCloneSession(session) {
    navigate(toPath(PATHS.addSession, { orgId: orgId }), {
      state: { isCloning: true, sessionId: session._id }
    });
  }

  async function handleCancelSession(cancellationReason, cancelSessionActions) {
    await onCancelSession(modalCallbackAdditionalProps.sessionId, cancellationReason, cancelSessionActions);
  }

  const getInitialRowState = useCallback((row) => {
    return {
      sendCalendarInvites: row.original.sendCalendarInvites
    };
  }, []);

  function onShowCancellationModal(sessionId: string) {
    onOpenCancellationModal();
    setModalCallbackAdditionalProps({ sessionId });
  }

  const [exportOptionsOpen, setExportOptionsOpen] = useState(false);
  const askStudentsExportOptions = () => {
    setExportOptionsOpen(true);
  };

  const [isExporting, setIsExporting] = useState(false);
  const exportSessionsList = (sessionExportOption: SessionExportOption) => {
    setIsExporting(true);
    const { fetchDataOptions, fromDate, toDate } = fetchDataOptionsWithDates;
    const { filters, pageIndex, pageSize, sortBy } = fetchDataOptions;
    const sessionsQueryParams = {
      page: pageIndex,
      perPage: pageSize,
      ...getFiltersQueryParameters(filters),
      sortBy: mapReactTableSortToApiSort(sortBy || []),
      sessionExportOption: sessionExportOption
    };
    if (sessionType === InstanceTemporality.Completed) {
      sessionsQueryParams['fromDate'] = fromDate;
      sessionsQueryParams['toDate'] = toDate;
    }

    apiService
      .get(`/org/${orgId}/sessions/${sessionType}/export`, sessionsQueryParams, {
        'content-disposition': 'attachment'
      })
      .then(
        async (result: any) => {
          setIsExporting(false);
          setExportOptionsOpen(false);
          const contentTypeHeader = result.headers['content-type'];
          if (
            contentTypeHeader?.indexOf('text/csv') !== -1 ||
            contentTypeHeader?.indexOf('application/pdf') !== -1 ||
            contentTypeHeader?.indexOf('application/octet-stream') !== -1 ||
            contentTypeHeader?.indexOf('text/html') !== -1
          ) {
            handleSaveFile(result, snackbarProps);
          } else {
            showNotification(snackbarProps, false, Messages.UnsupportedFileType, true);
          }
        },
        (error) => {
          setIsExporting(false);
          setExportOptionsOpen(false);
          showNotification(
            snackbarProps,
            false,
            (error.response.data.message as string) || Messages.FailedExportRegistrations,
            true
          );
        }
      );
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" marginY={2}>
        <div>
          {sessionType === InstanceTemporality.Completed && (
            <StartEndDatesFilter
              fromDate={fetchDataOptionsWithDates.fromDate}
              toDate={fetchDataOptionsWithDates.toDate}
              handleDatesChanged={handleDatesChanged}
              validationErrors={{ fromDate: null, toDate: null }}
              adjustDatesInterval={false}
            />
          )}
        </div>
        <Tooltip title="Only 500 first records will be exported">
          <div>
            <ExportButton isExporting={isExporting} color={'primary'} onClick={askStudentsExportOptions} />
          </div>
        </Tooltip>
      </Box>
      <Table
        columns={columns}
        getInitialRowState={getInitialRowState}
        fetchData={setFetchDataOptionsAndGetSessions}
        pageCount={total}
        data={list}
        paginationType={PaginationTypes.ServerSide}
        loading={isLoadingSessionsList || isImporting}
        noDataText="No sessions"
      />
      <CancellationModal
        titleText="Enter session cancellation reason"
        open={cancellationModalOpened}
        onClose={onCloseCancellationModal}
        submitCallback={handleCancelSession}
        isLoading={isUpdating || isSessionUpdating}
      />
      <StudentExportOptionsModal
        open={exportOptionsOpen}
        handleSubmit={exportSessionsList}
        onClose={() => setExportOptionsOpen(false)}
        progress={isExporting}
      />
    </>
  );
};

export default SessionsTable;
