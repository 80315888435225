import { CurrencyCode } from './Currencies';

export enum PaymentPlatformType {
  Stripe = 'Stripe'
}

export const paymentPlatformTypeViews = {
  [PaymentPlatformType.Stripe]: 'Stripe'
};

export interface PaymentPlatformValues {
  name: string;
  paymentPlatformType: PaymentPlatformType;
  stripePlatformAccount: {
    currencyCode: CurrencyCode;
    isEfacity: boolean;
    stripeSecretKey: string;
    stripePublishableKey: string;
    stripeClientId?: string;
  };
}

export class PaymentPlatform implements PaymentPlatformValues {
  _id: string;
  name: string;
  paymentPlatformType: PaymentPlatformType;
  stripePlatformAccount: {
    currencyCode: CurrencyCode;
    isEfacity: boolean;
    stripeSecretKey: string;
    stripePublishableKey: string;
    stripeClientId?: string;
    connectedAccounts?: any[];
  };
  deletedAt?: Date;
}

export interface OrganizationTransferFormValue {
  _id: string;
  stripeUserId: string;
  name: string;
  toAccountName: string;
  transferPercentage: number;
  isSelected: boolean;
}

export const splitByIdExistence = (organizationTransfers: OrganizationTransferFormValue[]) => {
  const withId: OrganizationTransferFormValue[] = [];
  const withoutId: OrganizationTransferFormValue[] = [];

  organizationTransfers.forEach((transfer) => {
    if (transfer._id) {
      withId.push(transfer);
    } else {
      withoutId.push(transfer);
    }
  });
  return { transfersWithId: [...withId], transfersWithoutId: [...withoutId] };
};

export interface OrganizationTransferFormValues {
  organizationTransferFormValues: OrganizationTransferFormValue[];
}
