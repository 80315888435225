import { Box, Dialog, DialogContent, DialogProps, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react';
import { CircularProgressButton } from '@efacity/frontend-shared';
import { ModalDialogTitle } from '@efacity/react-next-sc';
import { SessionExportOption } from '@efacity/common';

export interface StudentExportOptionsModalProps {
  open: boolean;
  handleSubmit: (sessionExportOption: SessionExportOption) => void;
  onClose: () => void;
  progress: boolean;
}

const StudentExportOptionsModal: React.FC<DialogProps & StudentExportOptionsModalProps> = ({
  open,
  handleSubmit,
  onClose,
  progress
}) => {
  const [sessionExportOption, setSessionExportOption] = useState<SessionExportOption>(SessionExportOption.sessions);

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <ModalDialogTitle text="Export options:" onClose={onClose} />
      <DialogContent>
        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female">
          <span onClick={() => setSessionExportOption(SessionExportOption.sessions)} style={{ cursor: 'pointer' }}>
            <Radio
              checked={sessionExportOption === SessionExportOption.sessions}
              onChange={() => setSessionExportOption(SessionExportOption.sessions)}
              value="a"
              name="radio-buttons-export-without-students"
              id="radio-buttons-export-without-students"
            />
            Only Sessions
          </span>
          <span
            onClick={() => setSessionExportOption(SessionExportOption.sessionsWithStudents)}
            style={{ cursor: 'pointer' }}
          >
            <Radio
              checked={sessionExportOption === SessionExportOption.sessionsWithStudents}
              onChange={() => setSessionExportOption(SessionExportOption.sessionsWithStudents)}
              value="b"
              name="radio-buttons-export-with-students"
            />
            Sessions with Students
          </span>
          <span
            onClick={() => setSessionExportOption(SessionExportOption.sessionsWithStudentsAndCustomers)}
            style={{ cursor: 'pointer' }}
          >
            <Radio
              checked={sessionExportOption === SessionExportOption.sessionsWithStudentsAndCustomers}
              onChange={() => setSessionExportOption(SessionExportOption.sessionsWithStudentsAndCustomers)}
              value="b"
              name="radio-buttons-export-with-students"
            />
            Sessions with Students and Customers
          </span>
        </RadioGroup>
        <Box display="flex" justifyContent="center" style={{ marginTop: 20, marginBottom: 10 }}>
          <CircularProgressButton
            onClick={() => handleSubmit(sessionExportOption)}
            color="primary"
            variant="contained"
            disabled={progress}
            progress={progress}
            style={{ minWidth: 85, width: 85, height: 37 }}
            data-testid="export-submit-btn"
          >
            Export
          </CircularProgressButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default StudentExportOptionsModal;
