import React, { FC } from 'react';
import { MenuItem } from '@mui/material';
import { taxTypesOptions } from '@efacity/common';
import { FormTextInput } from './FormTextInput';

interface TaxTypeSelectorProps {
  name: string;
}

export const TaxTypeSelector: FC<TaxTypeSelectorProps> = ({ name }) => {
  return (
    <FormTextInput name={name} label="Tax Type" select>
      {taxTypesOptions.map((option) => (
        <MenuItem key={`taxType${option.value}Key`} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </FormTextInput>
  );
};
