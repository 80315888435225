import { NavToggleButtons, NavToggleButtonTab } from '../NavToggleButtons/NavToggleButtons';
import { AddLinkMediumButton } from '@efacity/frontend-shared';
import React from 'react';

interface PageSubtitleProps {
  children?: React.ReactNode;
  addBtnLink?: string;
  tabs?: NavToggleButtonTab[];
}

export const PageSubtitle = ({ tabs, addBtnLink, children }: PageSubtitleProps) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {children && <h2>{children}</h2>}
        <NavToggleButtons tabs={tabs} />
      </div>
      {addBtnLink && <AddLinkMediumButton link={addBtnLink} />}
    </div>
  );
};
