import { hasRole, Roles } from './Roles';
import { Access } from './Access';

export interface User {
  _id: any;
  email?: string;
  fullName?: string;
  callByName?: string;
  defaultPhone?: string;
  defaultPhoneCountry?: string;
  access?: Access[];
  roles?: Roles[];
  createdAt?: string;
  updatedAt?: string;
}

export enum UserStatus {
  NonVerified = 'nonVerified',
  Active = 'active',
  Inactive = 'inactivated'
}
export enum UserValidationStatus {
  NonValidated = 'nonValidated',
  UnderValidation = 'underValidation',
  Validated = 'validated'
}
export enum ValidationType {
  Email = 'email',
  Phone = 'phone',
  EmailAndPhone = 'emailAndPhone'
}

const checkIfAccessForOrganization = (access: Access, orgId: string) => {
  return access?.organization?.toString() === orgId;
};

export const hasAnyAccessWithRoleForOrganization = (
  orgId: string,
  allowedRoles: Roles[] = [],
  accesses: Access[] = []
): boolean => {
  if (allowedRoles.length > 0 && accesses.length > 0) {
    const foundAccess = accesses.find((access: Access) => {
      return checkIfAccessForOrganization(access, orgId) && hasRole(allowedRoles, access.role) && !access.deletedAt;
    });
    return !!foundAccess;
  } else {
    return false;
  }
};

export const getDefaultAccess = (accesses: Access[]) => {
  if (accesses && accesses.length > 0) {
    const siteAdminAccess = accesses.find((access: Access) => access.role === Roles.SiteAdmin);
    if (siteAdminAccess) return siteAdminAccess;

    const schoolAdminAccess = accesses.find((access: Access) => access.role === Roles.SchoolAdmin);
    if (schoolAdminAccess) return schoolAdminAccess;

    const independentAccess = accesses.find((access: Access) => access.role === Roles.IndependentTeacher);
    if (independentAccess) return independentAccess;

    const centerAdminAccess = accesses.find((access: Access) => access.role === Roles.CenterAdmin);
    if (centerAdminAccess) return centerAdminAccess;

    const schoolTeacherAccess = accesses.find((access: Access) => access.role === Roles.SchoolTeacher);
    if (schoolTeacherAccess) return schoolTeacherAccess;

    const centerTeacherAccess = accesses.find((access: Access) => access.role === Roles.CenterTeacher);
    if (centerTeacherAccess) return centerTeacherAccess;

    // customer and student will not have multiple centers or school as teacher or center
    // so they may be redirected directly to their pages
    const customerAccess = accesses.find((access: Access) => access.role === Roles.Customer);
    if (customerAccess) return customerAccess;
    const studentAccess = accesses.find((access: Access) => access.role === Roles.Student);
    if (studentAccess) return studentAccess;

    const foundDefaultAccess = accesses.find((access: Access) => access.isDefault);
    return foundDefaultAccess ? foundDefaultAccess : accesses[0];
  }
  return null;
};

export const getDefaultAccessId = (accesses: Access[]) => {
  const foundDefaultAccess = getDefaultAccess(accesses);
  if (foundDefaultAccess) {
    return foundDefaultAccess.organization;
  }
  return null;
};
