import { isGradeNumericValue, isGradeValue } from './Grades';
import { ValidationErrors } from './ValidationErrors';
import { ActivityTypes, ActivityTypesOptions, DurationTypeOptions } from './Activities';
import { DisplayOption } from './DisplayOption';
import { isCurrencyCode } from './Currencies';
import { Messages } from './Messages';
import { isEmpty, trim } from 'lodash';
import { CountryCode } from './Countries';
import { AgeStudentLevelTypeValue, SchoolGradeStudentLevelTypeValue, studentLevelTypes } from './StudentLevel';
import { isEmail } from 'class-validator';
import { isPreferredPronounValue } from './PreferredPronoun';
import { isTShirtSizeValue } from './TShirtSize';

// eslint-disable-next-line @typescript-eslint/ban-types
export const isEmptyObject = (obj: Object): boolean => {
  return !Object.keys(obj).length;
};

export const nonNegativeAmountValidator = (value: string) => {
  const realValue = parseFloat(value);
  if (isNaN(realValue)) {
    return false;
  } else {
    return realValue >= -0.00000001;
  }
};

export const cleanEmpty = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => (v && typeof v === 'object' ? cleanEmpty(v) : v)).filter((v) => !(v == null || v === ''));
  } else {
    return Object.entries(obj)
      .map(([k, v]) => [k, v && typeof v === 'object' ? cleanEmpty(v) : v])
      .reduce((a, [k, v]) => (v == null || v === '' ? a : ((a[k] = v), a)), {});
  }
};

export const trimObjectStringValues = (obj) => {
  if (obj === null || typeof obj === 'undefined') return obj;
  if (!Array.isArray(obj) && typeof obj !== 'object') return obj;
  return Object.keys(obj).reduce(
    function (acc, key) {
      acc[key] = typeof obj[key] === 'string' ? obj[key].trim() : trimObjectStringValues(obj[key]);
      return acc;
    },
    Array.isArray(obj) ? [] : {}
  );
};

export const allObjectValuesEmpty = (object): boolean => {
  return Object.values(object).every((value) => {
    if (!!value && typeof value === 'string') {
      return value.trim().length === 0;
    }
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
    if (typeof value === 'number') {
      return false;
    }
    return !(!!value && typeof value !== 'number');
  });
};

export const doesObjectHaveNestedKey = (obj, key) => {
  if (obj === null || obj === undefined) {
    return false;
  }

  for (const k of Object.keys(obj)) {
    if (k === key) {
      /* Search keys of obj for match and return true if match found */
      return true;
    } else {
      const val = obj[k];

      /* If k not a match, try to search it's value. We can search through
      object value types, seeing they are capable of containing
      objects with keys that might be a match */
      if (typeof val === 'object') {
        /* Recursivly search for nested key match in nested val */
        if (doesObjectHaveNestedKey(val, key) === true) {
          return true;
        }
      }
    }
  }

  return false;
};

export const currencyValidator = (currencyString) => {
  if (isCurrencyCode(currencyString)) return true;
  throw new Error(Messages.CurrencyRequired);
};

export const preferredPronounValidator = (preferredPronoun) => {
  if (isPreferredPronounValue(preferredPronoun)) return true;
  throw new Error(Messages.PreferredPronounRequired);
};

export const gradeOptionalValidator = (mayBeGrade: string) => {
  if (mayBeGrade.length > 0) {
    if (isGradeValue(mayBeGrade)) return true;
    throw new Error(Messages.SelectGrade);
  }
  return true;
};

export const gradeOptionalSortValidator = (mayBeGradeSort: number) => {
  if (isGradeNumericValue(mayBeGradeSort)) return true;
  throw new Error(Messages.SelectGrade);
};

export const tShirtSizeOptionalValidator = (mayBeTShiirtSize: string) => {
  if (mayBeTShiirtSize.length > 0) {
    if (isTShirtSizeValue(mayBeTShiirtSize)) return true;
    throw new Error(Messages.WrongTShirtSizeValue);
  }
  return true;
};

export const activityLevelValidator = (value) => {
  if (value > 0 && value <= 5) {
    return true;
  }
  throw new Error(ValidationErrors.InvalidActivityLevelRange);
};

export const activityTypeValidator = (value) => {
  if (value) {
    const isSuppliedValueInOptionsRange = Object.values(ActivityTypesOptions)
      .filter((type) => type.value !== ActivityTypes.InstructorCertification)
      .find((activityType: DisplayOption) => activityType.value === value);
    if (isSuppliedValueInOptionsRange) {
      return true;
    }
  }
  throw new Error(ValidationErrors.InvalidActivityType);
};

export const durationTypeValidator = (value) => {
  if (value) {
    const isSuppliedValueInOptionsRange = Object.values(DurationTypeOptions).find(
      (durationType: DisplayOption) => durationType.value === value
    );
    if (isSuppliedValueInOptionsRange) {
      return true;
    }
  }
  throw new Error(ValidationErrors.InvalidDurationType);
};

export const studentLevelTypeValidator = (studentLevelType) => {
  if (!studentLevelTypes.find((type) => type.value === studentLevelType))
    throw new Error(ValidationErrors.InvalidStudentLevelType);
  return true;
};

export const studentLevelFromSchoolGradeValidator = (studentLevelFromSchoolGrade, { req }) => {
  if (!!req?.body?.studentLevel?.type && req?.body?.studentLevel?.type === SchoolGradeStudentLevelTypeValue) {
    if (isGradeNumericValue(studentLevelFromSchoolGrade)) {
      return true;
    }
    throw new Error(ValidationErrors.InvalidGrade);
  }
  return true;
};
export const studentLevelFromAgeValidator = (fromAge, { req }) => {
  if (!!req?.body?.studentLevel?.type && req?.body?.studentLevel?.type === AgeStudentLevelTypeValue) {
    if (fromAge > 0) {
      return true;
    }
    throw new Error(ValidationErrors.InvalidAge);
  }
  return true;
};
export const studentLevelToAgeValidator = (toAge, { req }) => {
  if (!!req?.body?.studentLevel?.type && req?.body?.studentLevel?.type === AgeStudentLevelTypeValue) {
    if (toAge > 0) {
      if (req?.body?.studentLevel?.fromAge) {
        if (toAge < req.body.studentLevel.fromAge) {
          throw new Error(ValidationErrors.ToAgeMustGreaterFrom);
        }
      }
      return true;
    }
    throw new Error(ValidationErrors.InvalidAge);
  }
  return true;
};

export const studentLevelValidator = (value) => {
  if (!value || typeof value !== 'object' || isEmptyObject(value)) {
    throw new Error(ValidationErrors.StudentLevelRequired);
  }
};

export function isDeepEmpty(input) {
  if (isEmpty(input)) {
    return true;
  }
  if (typeof input === 'object') {
    for (const item of Object.values(input)) {
      // if item is not undefined and is a primitive, return false
      // otherwise dig deeper
      if (
        (item !== undefined &&
          (typeof item !== 'object' || Object.prototype.toString.call(item) === '[object Date]')) ||
        !isDeepEmpty(item)
      ) {
        return false;
      }
    }
    return true;
  }
  return isEmpty(input);
}

export const isValidZipCode = (country: CountryCode, zipCode: string) => {
  if (zipCode?.length > 0) {
    if (country === CountryCode.USA) {
      return !!zipCode.match(/^\d{5}(?:-\d{4})?$/);
    }
    return true;
  }
  return false;
};

export const isValidUrl = (url: string) => {
  return /^(https):\/\/[^ "]+$/.test(url);
};

export const isListOfEmails = (emails: string): boolean => {
  const arrayOfEmails = getEmailsArray(emails);
  return isArrayOfEmails(arrayOfEmails);
};

export const isArrayOfEmails = (emails: string[]): boolean => {
  const hasNonEmail = emails.find((mayBeEmail) => !isEmail(mayBeEmail));
  return !hasNonEmail;
};

export const getEmailsArray = (emails: string) => {
  if (emails?.length > 0) {
    return emails
      .split(',')
      .map((email) => trim(email))
      .filter((item) => item?.length > 0);
  }
  return [];
};

export const isNonEmptyString = (value) => {
  return typeof value === 'string' && value.trim().length > 0;
};
