import type { AdminAccess, Roles } from '@efacity/common';
import { Messages, getDefaultAccessId } from '@efacity/common';
import { PATHS } from '@efacity/routing';
import { useContext } from 'react';
import { apiService } from '../../apiService';
import { isResponseError } from '../../apiUtils';
import { AuthContext } from '../../components/AuthContext';
import type { AuthUserResponse, AuthenticatedUserInfo, ExtraAuthInfo, SignUpCredentials } from '../../interfaces/Auth';
import { AuthStatus } from '../../interfaces/Auth';
import type { EmailFormValues, ResetPasswordFormValues, SignInFormValues } from '../../interfaces/AuthFormTypes';
import { REDIRECT_ITEM_NAME, useLocalStorage } from '../useLocalStorage';
import { emptyExtraAuthInfo, initialAuthState, nonAuthenticatedUserInfo } from './authDefaultStates';
import { WORKING_ACCESS_ID_ITEM_NAME } from '../../constants/constants';

const isResponseErrorWithExtraAuthInfo = isResponseError<{ extraAuthInfo: ExtraAuthInfo }>;

export const useAuth = () => {
  const { authState, setAuthState } = useContext(AuthContext);
  const { getItem, removeItem } = useLocalStorage();

  const checkAuthenticationStatus = async (orgId?: string) => {
    setAuthState({ ...authState, loading: true });

    try {
      // https://stackoverflow.com/questions/61224287/how-to-force-axios-to-not-cache-in-get-requests
      const { data } = await apiService().get<AuthUserResponse>(`/users/me?cb=${Date.now()}`);
      const { user, extraAuthInfo } = data;

      if (!user) {
        return setAuthState({
          ...initialAuthState,
          user: nonAuthenticatedUserInfo,
          extraAuthInfo: extraAuthInfo ? { ...extraAuthInfo } : emptyExtraAuthInfo,
          isAuthenticated: AuthStatus.Unauthenticated
        });
      }

      const savedAccessId = getItem(WORKING_ACCESS_ID_ITEM_NAME);
      const workingAccessId = orgId || savedAccessId || getDefaultAccessId(user?.access);
      let workingOrganizationName;
      if (data?.user?.adminAccesses) {
        const foundAccess = data.user.adminAccesses.find(
          (adminAccess: { orgId: string }) => adminAccess.orgId === workingAccessId
        );
        workingOrganizationName = foundAccess?.orgName;
      }

      setAuthState({
        user: { ...user } as AuthenticatedUserInfo,
        extraAuthInfo: { ...extraAuthInfo } as ExtraAuthInfo,
        workingAccessId: workingAccessId,
        workingOrganizationName: workingOrganizationName,
        isAuthenticated: AuthStatus.Authenticated,
        loading: false,
        isQueryingAccessUrl: false,
        isLogout: false
      });
    } catch (error) {
      const extraAuthInfo = isResponseErrorWithExtraAuthInfo(error)
        ? error.response?.data?.extraAuthInfo ?? emptyExtraAuthInfo
        : emptyExtraAuthInfo;

      setAuthState({
        ...initialAuthState,
        user: nonAuthenticatedUserInfo,
        extraAuthInfo,
        isAuthenticated: AuthStatus.Unauthenticated
      });
    }
  };

  const signIn = async (formValues: SignInFormValues) => {
    setAuthState({ ...authState, loading: true });

    try {
      const { data } = await apiService().post<AuthUserResponse, SignInFormValues>('/auth/login', formValues);
      const { user, extraAuthInfo } = data;
      const workingAccessId = getDefaultAccessId(user?.access);
      let workingOrganizationName;
      if (data?.user?.adminAccesses) {
        const foundAccess = data.user.adminAccesses.find(
          (adminAccess: { orgId: string }) => adminAccess.orgId === workingAccessId
        );
        workingOrganizationName = foundAccess?.orgName;
      }

      setAuthState({
        user: { ...data.user } as AuthenticatedUserInfo,
        extraAuthInfo: { ...extraAuthInfo } as ExtraAuthInfo,
        workingAccessId: workingAccessId,
        workingOrganizationName: workingOrganizationName,
        isAuthenticated: AuthStatus.Authenticated,
        loading: false,
        isQueryingAccessUrl: false,
        isLogout: false
      });
      return {
        loginSuccess: true,
        access: data.user.access,
        roles: data.user.roles,
        userId: data.user._id,
        startRegistrationUrl: data.extraAuthInfo?.startRegistrationURL
      };
    } catch (error) {
      const extraAuthInfo = isResponseErrorWithExtraAuthInfo(error)
        ? error.response?.data?.extraAuthInfo ?? emptyExtraAuthInfo
        : emptyExtraAuthInfo;
      setAuthState({
        ...initialAuthState,
        user: nonAuthenticatedUserInfo,
        extraAuthInfo: extraAuthInfo ? { ...extraAuthInfo } : emptyExtraAuthInfo,
        isAuthenticated: AuthStatus.Unauthenticated
      });

      const errorToShow = isResponseError(error)
        ? error?.response?.data?.message ?? 'Cannot sign in'
        : 'Cannot sign in';
      return { loginSuccess: false, error: errorToShow };
    }
  };

  const signInWithGoogle = async (googleCode: string) => {
    setAuthState({ ...authState, loading: true });

    try {
      const { data } = await apiService().post<AuthUserResponse>('/auth/google-signin', { googleCode });
      const { user, extraAuthInfo } = data;
      const workingAccessId = getDefaultAccessId(user?.access);
      let workingOrganizationName;
      if (data?.user?.adminAccesses) {
        const foundAccess = data.user.adminAccesses.find(
          (adminAccess: { orgId: string }) => adminAccess.orgId === workingAccessId
        );
        workingOrganizationName = foundAccess?.orgName;
      }

      setAuthState({
        user: { ...data.user } as AuthenticatedUserInfo,
        extraAuthInfo: { ...extraAuthInfo } as ExtraAuthInfo,
        workingAccessId: workingAccessId,
        workingOrganizationName: workingOrganizationName,
        isAuthenticated: AuthStatus.Authenticated,
        loading: false,
        isQueryingAccessUrl: false,
        isLogout: false
      });
      return {
        loginSuccess: true,
        access: data.user.access,
        roles: data.user.roles,
        userId: data.user._id,
        startRegistrationUrl: data.extraAuthInfo?.startRegistrationURL
      };
    } catch (error) {
      const extraAuthInfo = isResponseErrorWithExtraAuthInfo(error)
        ? error.response?.data?.extraAuthInfo ?? emptyExtraAuthInfo
        : emptyExtraAuthInfo;
      setAuthState({
        ...initialAuthState,
        user: nonAuthenticatedUserInfo,
        extraAuthInfo,
        isAuthenticated: AuthStatus.Unauthenticated
      });

      if (isResponseError(error)) {
        return { loginSuccess: false, message: error?.response?.data?.message || 'Cannot sign in' };
      }
      return { loginSuccess: false, error: 'Cannot sign in' };
    }
  };

  const logOut = async () => {
    setAuthState({ ...authState, isLogout: true });
    removeItem(WORKING_ACCESS_ID_ITEM_NAME);

    try {
      await apiService().post('/auth/google-logout', {});
      setAuthState({ ...initialAuthState, isAuthenticated: AuthStatus.Unauthenticated, isLogout: true });
      removeItem(REDIRECT_ITEM_NAME);
      return true;
    } catch (error) {
      setAuthState({ ...authState, isLogout: false });
      return false;
    }
  };

  const signUp = async (formValues: SignUpCredentials) => {
    setAuthState({ ...authState, loading: true });

    try {
      const { data } = await apiService().post<AuthUserResponse, SignUpCredentials>('/auth/signup', formValues);
      const { user, extraAuthInfo } = data;
      const workingAccessId = getDefaultAccessId(user?.access);
      let workingOrganizationName;
      if (data?.user?.adminAccesses) {
        const foundAccess = data.user.adminAccesses.find(
          (adminAccess: { orgId: string }) => adminAccess.orgId === workingAccessId
        );
        workingOrganizationName = foundAccess?.orgName;
      }

      setAuthState({
        user: { ...data.user } as AuthenticatedUserInfo,
        extraAuthInfo: { ...extraAuthInfo } as ExtraAuthInfo,
        workingAccessId: workingAccessId,
        workingOrganizationName: workingOrganizationName,
        isAuthenticated: AuthStatus.Authenticated,
        loading: false,
        isQueryingAccessUrl: false,
        isLogout: false
      });
      return {
        signUpSuccess: true
      };
    } catch (error) {
      const extraAuthInfo = isResponseErrorWithExtraAuthInfo(error)
        ? error.response?.data?.extraAuthInfo ?? emptyExtraAuthInfo
        : emptyExtraAuthInfo;
      setAuthState({
        ...initialAuthState,
        user: nonAuthenticatedUserInfo,
        extraAuthInfo,
        isAuthenticated: AuthStatus.Unauthenticated
      });

      return { signUpSuccess: false, error: error };
    }
  };

  const clearShoppingCartOnServer = async () => {
    setAuthState({
      ...authState,
      extraAuthInfo: {
        ...authState.extraAuthInfo,
        startRegistrationURL: PATHS.landing,
        shoppingCart: null
      }
    });
    try {
      await apiService().post<AuthUserResponse>('/shopping-cart/clear', {});
      return true;
    } catch (error) {
      return false;
    }
  };

  const getAllowedCreateActivity = async (orgId: string) => {
    setAuthState({
      ...authState,
      extraAuthInfo: {
        ...authState.extraAuthInfo,
        center: {
          allowedCreateActivity: false
        }
      }
    });
    try {
      await apiService().get<AuthUserResponse>(`/org/${orgId}/activities/access`, {});
      setAuthState({
        ...authState,
        extraAuthInfo: {
          ...authState.extraAuthInfo,
          center: {
            allowedCreateActivity: true
          }
        }
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  const sendResetPasswordEmail = async (values: EmailFormValues) => {
    setAuthState({
      ...authState,
      loading: true
    });
    try {
      const { data } = await apiService().post<{ message: string }, EmailFormValues>(
        '/auth/send-reset-password-email',
        values
      );
      return {
        isError: false,
        message: data?.message || Messages.PasswordResetSuccess
      };
    } catch (error) {
      setAuthState({
        ...authState,
        isAuthenticated: AuthStatus.Unauthenticated,
        loading: true
      });
      if (isResponseError(error)) {
        return { isError: true, message: error?.response?.data?.message || 'Cannot reset password' };
      }
      return { isError: true, message: 'Cannot reset password' };
    }
  };

  const resetPassword = async (values: ResetPasswordFormValues & { uuid: string }) => {
    setAuthState({
      ...authState,
      loading: true
    });
    try {
      const { data } = await apiService().post<{ message: string }, ResetPasswordFormValues & { uuid: string }>(
        '/auth/reset-password',
        values
      );
      return {
        isError: false,
        message: data?.message || Messages.PasswordResetSuccess
      };
    } catch (error) {
      setAuthState({
        ...authState,
        isAuthenticated: AuthStatus.Unauthenticated,
        loading: true
      });
      if (isResponseError(error)) {
        return { isError: true, message: error?.response?.data?.message || 'Cannot reset password' };
      }
      return { isError: true, message: 'Cannot reset password' };
    }
  };

  const updateAuthenticatedUserAccess = async (access: { access: { role: Roles }[] }) => {
    try {
      const { data } = await apiService().patch<AuthUserResponse, { access: { role: Roles }[] }>(
        '/users/me/access',
        access
      );
      setAuthState({
        ...authState,
        user: {
          ...authState.user,
          roles: data.user.roles,
          googleId: data.user.googleId
        }
      });
      return {
        isError: false,
        access: data.user.access
      };
    } catch (error) {
      if (isResponseError(error)) {
        return { isError: true, message: error?.response?.data?.message || 'Cannot update access' };
      }
      return { isError: true, message: 'Cannot update access' };
    }
  };

  const addAdminAccess = (newAdminAccess: AdminAccess) => {
    setAuthState({
      ...authState,
      user: {
        ...authState.user,
        adminAccesses: [...(authState.user.adminAccesses ?? []), newAdminAccess]
      }
    });
  };

  return {
    authState,
    setAuthState,
    checkAuthenticationStatus,
    signIn,
    signInWithGoogle,
    logOut,
    signUp,
    clearShoppingCartOnServer,
    getAllowedCreateActivity,
    resetPassword,
    sendResetPasswordEmail,
    updateAuthenticatedUserAccess,
    addAdminAccess
  };
};
