import { attendanceOptions, AttendanceStatus } from '@efacity/common';
import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';

interface AttendanceStatusesLegendProps {
  containerStyles?: React.CSSProperties;
}

export const attendanceContainerStyle = {
  width: 120,
  display: 'flex',
  justifyContent: 'center'
};

export const attendanceColors = {
  [AttendanceStatus.Expired]: {
    backgroundColor: '#878CEF'
  },
  [AttendanceStatus.Present]: {
    backgroundColor: '#66BB6A'
  },
  [AttendanceStatus.Absent]: {
    backgroundColor: '#FFF59D'
  },
  [AttendanceStatus.Unregistered]: {
    backgroundColor: '#E57373'
  },
  all: {
    backgroundColor: '#fff',
    border: '1px solid #C4C4C4'
  }
};

const AttendanceStatusesLegend: FC<AttendanceStatusesLegendProps> = ({ containerStyles }) => {
  return (
    <Box display="flex" style={containerStyles}>
      {attendanceOptions.map(attendanceOption => (
        <Box
          key={attendanceOption.value}
          display="flex"
          alignItems="center"
          marginRight={2}
          style={{ ...attendanceContainerStyle, ...attendanceColors[attendanceOption.value] }}
        >
          <Typography>{attendanceOption.label}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default AttendanceStatusesLegend;
