import {
  AdministratorInfo,
  ConnectModel,
  CountryCode,
  MailingServer,
  Organization,
  OrganizationStatus,
  OwnerType,
  PaymentAcceptanceMethod,
  SMTPSettings,
  TaxTypes,
  USD
} from '@efacity/common';

export const defaultSmtpSettings: SMTPSettings = {
  server: '',
  port: 465,
  ssl: false,
  user: '',
  password: ''
};
export const initialOrganizationSettingsFormValues = {
  orgType: OwnerType.School,
  status: OrganizationStatus.Active,
  name: '',
  description: '',
  timeZone: 'America/New_York',
  logoFullUrl: '',
  mobileLogoFullUrl: '',
  ein: '',
  contactEmail: '',
  website: '',
  phone: '',
  phoneCountry: CountryCode.USA,
  addresses: [],
  defaultAddress: {
    _id: '',
    country: CountryCode.USA,
    address: '',
    city: '',
    state: '',
    zip: '',
    mapLink: ''
  },
  country: CountryCode.USA,
  defaultCurrency: USD.currencyCode,
  customerLogoutUrl: '',
  schoolContactEmail: '',
  hideNumberOfRegistrations: false,
  validateUserOnRegistration: false,
  requireStudentAge: false,
  enrollmentRequired: false,
  enrollmentPrice: 0,
  connectModel: ConnectModel.ConnectExpress,
  efacityFee: 20,
  paymentAcceptanceMethod: PaymentAcceptanceMethod.Efacity,
  taxType: TaxTypes.None,
  taxPercentage: 0,
  sendFromEmail: '',
  mailingServer: MailingServer.awsSes,
  smtpSettings: defaultSmtpSettings,
  // Fields for school admin only
  schoolFee: 0,
  allowedCreateActivity: false,
  additionalNotificationEmails: '',
  additionalNotificationEmailsToPrimarySchool: ''
};

export interface OrganizationSettingsWithAdministratorsFormValues {
  organizationSettings: Omit<Organization, 'orgType'>;
  administrators: AdministratorInfo[];
}
