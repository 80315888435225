import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import {
  CurrencyCode,
  InvoiceItemPresentation,
  InvoiceItemTypes,
  moneyWithCurrencyCodeFormatter,
  splitInvoiceItemsToRegistrationsAndProducts
} from '@efacity/common';
import { InvoiceTableProps } from './InvoiceTable';
import RegistrationInvoiceItemMobileTableRow from './RegistrationInvoiceItemMobileTableRow';
import AdditionalOptionInvoiceItemMobileTableRow from './AdditionalOptionInvoiceItemMobileTableRow';
import DiscountInvoiceItemMobileTableRow from './DiscountInvoiceItemMobileTableRow';
import ProductInvoiceItemMobileTableRow from './ProductInvoiceItemMobileTableRow';
import CouponDiscountMobileInput from '../../ShoppingCartContainer/ShoppingCartTable/CouponDiscountMobileInput';

const invoiceMobileStyles = {
  total: {
    fontSize: 18,
    fontWeight: 'bold'
  }
};

const InvoiceMobileTable: FC<InvoiceTableProps> = ({
  invoice,
  due,
  showTaxRows,
  taxRowDescription,
  credit,
  loading,
  handleDiscountCodeChanged,
  handleDeleteAdditionalOption,
  handleDeleteDiscount,
  handleDeleteRegistration,
  isUpdating,
  hasAdminRole,
  onStartAdjustmentModal
}) => {
  const { registrationInvoiceItems, productInvoiceItems } = splitInvoiceItemsToRegistrationsAndProducts(
    invoice?.invoiceItems
  );

  return invoice?.invoiceItems && invoice?.invoiceItems.length > 0 ? (
    <Box marginBottom={2}>
      {registrationInvoiceItems.map((invoiceItem: InvoiceItemPresentation, index) => {
        switch (invoiceItem.invoiceItemType) {
          case InvoiceItemTypes.Registration:
            return (
              <RegistrationInvoiceItemMobileTableRow
                key={`invoiceItem${invoiceItem.invoiceItemId}PaymentItemRow`}
                invoiceItem={invoiceItem}
                handleDeleteRegistration={handleDeleteRegistration}
                index={index}
                isUpdating={isUpdating}
                disabled={invoice.isPaid}
                hasAdminRole={hasAdminRole}
                onStartAdjustmentModal={onStartAdjustmentModal}
              />
            );
          case InvoiceItemTypes.AdditionalSessionOption:
            return (
              <AdditionalOptionInvoiceItemMobileTableRow
                key={`invoiceItem${invoiceItem.invoiceItemId}AdditionalOptionItemRow`}
                invoiceItem={invoiceItem}
                handleDeleteAdditionalOption={handleDeleteAdditionalOption}
                index={index}
                isUpdating={isUpdating}
                disabled={invoice.isPaid}
              />
            );
          case InvoiceItemTypes.Discount:
            return (
              <DiscountInvoiceItemMobileTableRow
                key={`invoiceItem${invoiceItem.invoiceItemId}AdditionalOptionItemRow`}
                invoiceItem={invoiceItem}
                handleDeleteDiscount={handleDeleteDiscount}
                index={index}
                isUpdating={isUpdating}
                disabled={invoice?.isPaid}
              />
            );
          default:
            return null;
        }
      })}
      {productInvoiceItems.map((invoiceItem: InvoiceItemPresentation, index) => {
        switch (invoiceItem.invoiceItemType) {
          case InvoiceItemTypes.Product:
            return (
              <ProductInvoiceItemMobileTableRow
                key={`invoiceItem${invoiceItem.invoiceItemId}AdditionalOptionItemRow`}
                invoiceItem={invoiceItem}
                handleDeleteProduct={null}
                index={index}
                isUpdating={isUpdating}
                disabled={invoice?.isPaid}
              />
            );
          default:
            return null;
        }
      })}
      <CouponDiscountMobileInput handleDiscountCodeChanged={handleDiscountCodeChanged} disabled={invoice.isPaid} />
      {showTaxRows ? (
        <>
          <Box my={0}>
            <Typography color="primary" align="right" style={invoiceMobileStyles.total}>
              Sub Total :{' '}
              {moneyWithCurrencyCodeFormatter(
                invoice.amount,
                invoice?.currencyCode ? invoice.currencyCode : CurrencyCode.USD
              )}
            </Typography>
          </Box>
          <Box my={0}>
            <Typography color="primary" align="right" style={invoiceMobileStyles.total}>
              {taxRowDescription}{' '}
              {moneyWithCurrencyCodeFormatter(
                invoice.taxAmount,
                invoice?.currencyCode ? invoice.currencyCode : CurrencyCode.USD
              )}
            </Typography>
          </Box>
          <Box my={0}>
            <Typography color="primary" align="right" style={invoiceMobileStyles.total}>
              Total :{' '}
              {moneyWithCurrencyCodeFormatter(
                invoice.amountWithTax,
                invoice?.currencyCode ? invoice.currencyCode : CurrencyCode.USD
              )}
            </Typography>
          </Box>
        </>
      ) : (
        <Box my={0}>
          <Typography color="primary" align="right" style={invoiceMobileStyles.total}>
            Total :{' '}
            {moneyWithCurrencyCodeFormatter(
              invoice.amount,
              invoice?.currencyCode ? invoice.currencyCode : CurrencyCode.USD
            )}
          </Typography>
        </Box>
      )}
      {credit > 0 && (
        <Box my={0}>
          <Typography color="primary" align="right" style={invoiceMobileStyles.total}>
            Credit :{' '}
            {moneyWithCurrencyCodeFormatter(-credit, invoice?.currencyCode ? invoice.currencyCode : CurrencyCode.USD)}
          </Typography>
        </Box>
      )}
      <Box my={0}>
        <Typography color="primary" align="right" style={invoiceMobileStyles.total}>
          Due : {moneyWithCurrencyCodeFormatter(due, invoice?.currencyCode ? invoice.currencyCode : CurrencyCode.USD)}
        </Typography>
      </Box>
    </Box>
  ) : (
    <Box textAlign="center">
      <Typography style={{ fontSize: 18 }}>No Items</Typography>
    </Box>
  );
};

export default InvoiceMobileTable;
