import {
  ActivityTypes,
  getStudentLevelDiapason,
  LocationType,
  StudentLevel,
  UserPublicInfo,
  displayMdUpSx,
  displaySmDownSx
} from '@efacity/common';
import { PageTitle, Session, SessionWithLocationAndTags } from '@efacity/react-next-sc';
import PhoneIcon from '@mui/icons-material/Phone';
import { Box, Button, Grid, Theme, Typography, Link as MuiLink } from '@mui/material';
import Image from 'next/legacy/image';
import { FC } from 'react';
import InstructorInfo from '../../InstructorInfo';
import { hasJsonStructure } from '../../utils/hasJsonStructure';

import { getRegistrationLnkForActivityDependingOnActivityAndOwnerType } from '../../utils/sessionUtils';
import { InPersonLocation } from '../InPersonLocation';
import { ReviewSummary } from '../Reviews/ReviewSummary';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { SessionForRegistrationDensedItem } from '../SchoolActivityWithSessions/SessionsListDensed/SessionForRegistrationDensedItem';
import { SessionsForRegistrationsTable } from '../SchoolActivityWithSessions/SessionsTable/SessionsForRegistrationsTable';
import { ZoomLocation } from '../ZoomLocation';
import { PATHS, toPath } from '@efacity/routing';
import Link from 'next/link';

const sessionViewSx = {
  mainInfoContainer: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  }),
  name: (theme: Theme) => ({
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px'
    }
  }),
  imageContainer: { display: 'flex', justifyContent: 'center', alignSelf: 'start', margin: { xs: '10px', md: 0 } }
};

export interface SessionViewProps {
  session: SessionWithLocationAndTags;
  isActivityValid: boolean;
  hideNumberOfRegistrations: boolean;
  isRegistering: boolean;
  handleAddToCart: (session: Session) => void;
  teachersProfile?: UserPublicInfo | null;
}

export const DEFAULT_SESSION_IMAGE = '/assets/frontend-shared/defaultCourseImage_358x358.png';

export const SessionView: FC<SessionViewProps> = ({
  session,
  isActivityValid,
  hideNumberOfRegistrations,
  isRegistering,
  handleAddToCart,
  teachersProfile
}) => {
  const onAddToCart = () => {
    handleAddToCart(session);
  };

  const orgLandingLink = toPath(PATHS.orgLanding, { orgId: session.owner.orgId._id });

  const activityPageLink = getRegistrationLnkForActivityDependingOnActivityAndOwnerType(
    session.activityId,
    session.owner.orgId._id,
    session.type as ActivityTypes
  );

  const longDescription = hasJsonStructure(session.longDescription) ? (
    <RichTextEditor readOnly value={JSON.parse(session.longDescription as string)} />
  ) : (
    <Typography style={{ textAlign: 'justify' }}>{session.longDescription as string}</Typography>
  );

  const displayInstructorInfo = !!teachersProfile?.bio;
  const displaySchedule = !!session.schedule?.instances?.length;

  return (
    <>
      <Grid container sx={sessionViewSx.mainInfoContainer} spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <PageTitle title={session.name} dataTestId="session-view-name" />
          <ReviewSummary reviewCount={session.reviewCount} rating={session.rating} />
          <Typography data-testid="session-view-section">{session?.section}</Typography>
          <Typography data-testid="session-view-grade">
            {session?.studentLevel ? getStudentLevelDiapason(session.studentLevel as StudentLevel) : '-'}
          </Typography>
          <MuiLink component={Link} href={orgLandingLink} data-testid="session-view-org-name">
            by {session.owner.orgId.name}
          </MuiLink>
          {session.owner.orgId.phone && (
            <Box display="flex" alignItems="center">
              <PhoneIcon fontSize="small" style={{ marginRight: 6 }} />
              <MuiLink data-testid="session-view-org-phone" href={`tel:${session.owner.orgId.phone}`}>
                {session.owner.orgId.phone}
              </MuiLink>
            </Box>
          )}
          <Box marginY={1} data-testid="session-view-location">
            {session.location?.type === LocationType.InPerson && (
              <InPersonLocation address={session?.locationDisplayName} mapLink={session.locationDisplayLink} />
            )}
            {session.location?.type === LocationType.Virtual && <ZoomLocation />}
          </Box>

          <Box marginY={2} data-testid="session-view-description" style={{ maxWidth: '40em' }}>
            {longDescription}
          </Box>
          {displayInstructorInfo && <InstructorInfo teachersProfile={teachersProfile} />}
        </Grid>
        <Grid item xs={12} md={4} lg={4} sx={sessionViewSx.imageContainer}>
          <Image
            src={session.imageFullUrl || DEFAULT_SESSION_IMAGE}
            alt={session.name}
            priority={true}
            height="358"
            width="358"
          />
        </Grid>
      </Grid>

      <Box sx={displayMdUpSx}>
        <SessionsForRegistrationsTable
          sessions={[session]}
          loading={false}
          showNumberOfRegistrations={!hideNumberOfRegistrations}
          handleSessionRegistration={onAddToCart}
          isRegistering={isRegistering}
          showSchedule={displaySchedule}
        />
      </Box>
      <Box sx={displaySmDownSx}>
        <SessionForRegistrationDensedItem
          session={session}
          showNumberOfRegistrations={!hideNumberOfRegistrations}
          handleSessionRegistration={onAddToCart}
          isRegistering={isRegistering}
          showSchedule={displaySchedule}
        />
      </Box>
      {isActivityValid && (
        <Button variant="contained" style={{ margin: '10px 0 0 20px' }} href={activityPageLink}>
          View all sessions for this activity
        </Button>
      )}
    </>
  );
};
