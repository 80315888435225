import { apiService, getMergedFormValues } from '@efacity/frontend-shared';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Messages } from '@efacity/common';
import { CustomerFormValues, initialCustomerFormValues } from './customerFormValues';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

const initialCustomerFormStateValues = {
  customerFormValues: initialCustomerFormValues,
  isLoading: false
};
export const useCustomerFormValues = (orgId: string, customerId: string) => {
  const snackbarProps = useSnackbar();

  const [customerFormState, setCustomerFormState] = useState(initialCustomerFormStateValues);

  useEffect(() => {
    const getCustomerById = async (orgId: string) => {
      setCustomerFormState({ ...initialCustomerFormStateValues, isLoading: true });

      try {
        const { data } = await apiService.get<CustomerFormValues>(`/org/${orgId}/users/customers/${customerId}`);
        const customer = { ...data };

        setCustomerFormState(() => ({
          customerFormValues: getMergedFormValues(initialCustomerFormValues, {
            ...customer
          }),
          isLoading: false
        }));
      } catch (error) {
        showNotification(snackbarProps, false, error.message || Messages.FailedGetStudent, true);
        setCustomerFormState(initialCustomerFormStateValues);
      }
    };

    getCustomerById(orgId);
  }, [orgId, snackbarProps, customerId]);

  return {
    customerFormState
  };
};
