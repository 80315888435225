import Typography from '@mui/material/Typography';
import { FormGroupDivider } from '@efacity/frontend-shared';
import { PhoneNumber } from '@efacity/react-next-sc';
import EmailAddress from '../EmailAddress/EmailAddress';

export const CustomerInfo = ({ customer }) => {
  return (
    <div>
      <Typography gutterBottom>Customer information:</Typography>
      <Typography gutterBottom variant="subtitle2">
        Full name: {customer?.fullName || '-'}
      </Typography>
      <Typography gutterBottom variant="subtitle2">
        email:
        {customer?.email ? (
          <div style={{ marginTop: 0, marginBottom: 0, display: 'inline-block', fontSize: 16 }}>
            <EmailAddress email={customer.email} />
          </div>
        ) : (
          '-'
        )}
      </Typography>
      <Typography gutterBottom variant="subtitle2">
        phone: {<PhoneNumber phone={customer.phone} />}
      </Typography>
      <FormGroupDivider marginY={20} />
    </div>
  );
};
