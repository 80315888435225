import { InstanceActivity } from '@efacity/common';
import { PATHS } from '@efacity/routing';
import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import PageNavigationTabs, { Tab } from '../../components/PageNavigatonTabs/PageNavigationTabs';
import { PageTitle } from '@efacity/react-next-sc';

const PaymentPlatformsContainer: React.FC = () => {
  const tabs = useMemo<Tab[]>(() => {
    return [
      {
        label: 'Active',
        link: InstanceActivity.active
      },
      {
        label: 'Deleted',
        link: InstanceActivity.deleted
      }
    ];
  }, []);

  return (
    <div>
      <PageTitle title="Payment Accounts" addButtonLink={PATHS.addPaymentPlatform} />
      <PageNavigationTabs tabs={tabs} />
      <Outlet />
    </div>
  );
};

export default PaymentPlatformsContainer;
