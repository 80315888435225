import { apiService } from '@efacity/frontend-shared';
import { useCallback, useState } from 'react';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { useSnackbar } from 'notistack';
import { Activity, Messages } from '@efacity/common';
import { FetchDataOptions } from '../../components/Table/Table';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';

export interface ActivitiesResponse {
  data: Activity[];
  total?: number;
  timeZone: string;
}

export interface ActivitiesState {
  activities: Activity[];
  isLoading: boolean;
  total: number;
  timeZone: string;
}
export const defautActivitiesState = {
  activities: [],
  total: 0,
  isLoading: true,
  timeZone: 'America/New_York'
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const useActivitiesLoader = (url: string) => {
  const [activitiesState, setActivitiesState] = useState<ActivitiesState>(defautActivitiesState);
  const snackbarProps = useSnackbar();

  const getActivities = useCallback(
    async ({ pageIndex, pageSize, filters, sortBy }: FetchDataOptions) => {
      try {
        const result = await apiService.get<ActivitiesResponse>(url, {
          page: pageIndex,
          perPage: pageSize,
          ...getFiltersQueryParameters(filters),
          sortBy: mapReactTableSortToApiSort(sortBy)
        });
        setActivitiesState({
          activities: result.data.data,
          total: result.data.total,
          isLoading: false,
          timeZone: result.data.timeZone
        });
      } catch (error) {
        setActivitiesState((activitiesState) => ({
          ...activitiesState,
          isLoading: false
        }));
        showNotification(
          snackbarProps,
          false,
          (error.response?.data?.message as string) || Messages.FailedGetActivities,
          true
        );
      }
    },
    [snackbarProps, url]
  );
  return { activitiesState: activitiesState, getActivities: getActivities };
};
