import React, { useMemo } from 'react';
import { EnhancedColumn } from '../../components/Table/Table';
import { Link, Typography } from '@mui/material';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import { PhoneNumber } from '@efacity/react-next-sc';
import EmailAddress from '../../components/EmailAddress/EmailAddress';

const useCustomersTableColumns = (onEditCustomerInfo) => {
  return useMemo(() => {
    const columns: EnhancedColumn<any>[] = [
      {
        Header: 'Full Name',
        accessor: 'fullName',
        Cell: ({ value, row, state }) => {
          return (
            <Link
              component="button"
              align={'left'}
              aria-label="edit-customer-info"
              onClick={() =>
                onEditCustomerInfo(row.original._id, {
                  filters: state.filters,
                  pageIndex: state.pageIndex,
                  pageSize: state.pageSize,
                  sortBy: state.sortBy
                })
              }
              underline={'none'}
            >
              {value}
            </Link>
          );
        }
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ value }) => {
          return <EmailAddress email={value} />;
        }
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        Cell: ({ value }) => {
          return <PhoneNumber phone={value} />;
        }
      },
      {
        Header: 'Student(s)',
        accessor: 'students',
        disableFilters: true,
        Cell: ({ value: students }) => {
          return students.map((child, index) => (
            <div key={index}>
              <Typography style={{ ...adminSectionTableFontSize }}>{child.fullName}</Typography>
              {child.email && (
                <Typography style={{ ...adminSectionTableFontSize }}>
                  (<EmailAddress email={child.email} />)
                </Typography>
              )}
            </div>
          ));
        }
      }
    ];

    return columns;
  }, [onEditCustomerInfo]);
};

export default useCustomersTableColumns;
