import { InvoiceBalance, Messages } from '@efacity/common';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { FetchDataOptions, initialFetchDataOptions } from '../../components/Table/Table';
import { apiService } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';
import removeDateOffsetFromFilters from '../../utils/removeDateOffsetFromFilters';

export interface PaymentsState {
  payments: InvoiceBalance[];
  total: number;
  timeZone: string;
  isLoading: boolean;
  fetchDataOptions: FetchDataOptions;
}
export const defaultPaymentsState: PaymentsState = {
  payments: [],
  total: 0,
  timeZone: '',
  isLoading: false,
  fetchDataOptions: initialFetchDataOptions
};
export const usePayments = (orgId: string) => {
  const snackbarProps = useSnackbar();

  const [paymentsState, setPaymentsState] = useState<PaymentsState>(defaultPaymentsState);

  const getPayments = useCallback(
    async (fetchDataOptions: FetchDataOptions): Promise<void> => {
      setPaymentsState((prevState) => ({
        ...prevState,
        isLoading: true,
        fetchDataOptions: { ...fetchDataOptions }
      }));
      const { pageIndex, pageSize, filters, sortBy } = fetchDataOptions;
      const dateFilters = ['invoiceDate'];
      removeDateOffsetFromFilters(filters, dateFilters);

      try {
        const { data } = await apiService.get<{ data: InvoiceBalance[]; total: number; timeZone: string }>(
          `/org/${orgId}/invoices`,
          {
            page: pageIndex,
            perPage: pageSize,
            ...getFiltersQueryParameters(filters),
            sortBy: mapReactTableSortToApiSort(sortBy)
          }
        );

        setPaymentsState((prevState) => ({
          ...prevState,
          payments: data.data,
          total: data.total,
          timeZone: data.timeZone,
          isLoading: false
        }));
      } catch (error) {
        setPaymentsState((state) => ({ ...state, isLoading: false }));
        showNotification(snackbarProps, false, error.response?.data?.message || Messages.FailedGetPayments);
      }
    },
    [orgId, snackbarProps]
  );

  return { paymentsState, setPaymentsState, getPayments };
};
