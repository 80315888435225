export const textFieldSx = {
  textField: {
    marginBottom: '8px',
    marginTop: '12px',
    '& label': {
      color: '#666',
      fontSize: 16
    },
    '& fieldset': {
      borderRadius: '2px',
      borderWidth: '1px !important'
    },
    '& p': {
      position: 'absolute',
      top: '37px',
      marginLeft: '1px',
      whiteSpace: 'wrap',
      margin: '3px'
    }
  },
  formGroup: {
    width: '100%',
    position: 'relative',
    marginBottom: '10px'
  }
};
