import React from 'react';

const amountIconStyles = {
  amountIcon: {
    position: 'absolute',
    bottom: '-20px',
    right: '-10px'
  } as React.CSSProperties,
  amount: {
    position: 'absolute',
    width: '100%',
    top: '25px',
    left: '-10px',
    textAlign: 'left',
    color: '#ffffff',
    fontSize: '22px',
    fontWeight: 900,
    transform: 'translate(18px, -8%) rotate(-45deg)'
  } as React.CSSProperties
};

export const AmountIcon = ({ children }) => {
  return (
    <div style={amountIconStyles.amountIcon} data-testid="amount-discount-icon">
      <svg width="95" height="95" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M91.901 0H68.0676C62.8356 0 55.8386 2.89813 52.1397 6.59781L3.24075 55.4959C1.15045 57.5854 0 60.372 0 63.3432C0 66.3144 1.15124 69.101 3.24075 71.1905L31.8087 99.7592C33.8982 101.849 36.6856 103 39.656 103C42.6272 103 45.4138 101.849 47.5041 99.7592L96.4022 50.8603C100.102 47.1614 103 40.1644 103 34.9324V11.0975C102.999 4.97822 98.0202 0 91.901 0ZM86.1165 21.3242C85.5554 21.8877 84.7767 22.2091 83.9822 22.2091C83.1877 22.2091 82.4105 21.8869 81.8494 21.3242C81.286 20.7631 80.9646 19.986 80.9646 19.1915C80.9646 18.3962 81.2868 17.6183 81.8494 17.0572C82.409 16.4961 83.1877 16.1739 83.9822 16.1739C84.7767 16.1739 85.5554 16.4961 86.1165 17.0572C86.6776 17.6183 86.9998 18.397 86.9998 19.1915C86.9998 19.986 86.6776 20.7631 86.1165 21.3242Z"
          fill="#FF4949"
        />
        <path
          d="M86.1929 16.7615C86.7514 17.3245 87.0722 18.1059 87.0722 18.9031C87.0722 19.7003 86.7514 20.4802 86.1929 21.0432C85.6344 21.6086 84.8591 21.9311 84.0683 21.9311C83.2774 21.9311 82.5037 21.6078 81.9452 21.0432L18 85.5033L32.1309 99.7481C34.211 101.845 36.9857 102.999 39.9427 103C42.9005 103 45.6744 101.845 47.7552 99.7473L96.4321 50.6809C100.115 46.9685 103 39.9482 103 34.6982V10.7812C102.999 7.75563 101.794 5.00918 99.8443 3L86.1929 16.7615Z"
          fill="#FF2323"
        />
      </svg>
      <div style={amountIconStyles.amount}>{children}</div>
    </div>
  );
};
