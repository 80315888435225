import React from 'react';
import FormPageContainer from '../../components/FormPageContainer/FormPageContainer';
import { FormMode } from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import { useNavigate, useParams } from 'react-router-dom';
import { LocationIdOrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { useLocationsLoader } from './useLocationsLoader';
import LocationForm, { LocationFormValues } from './LocationForm';
import { ErrorOption, FieldPath } from 'react-hook-form';
import { PageTitle } from '@efacity/react-next-sc';

export interface LocationFormContainerProps {
  mode: FormMode;
}

const LocationFormContainer: React.FC<LocationFormContainerProps> = ({ mode }) => {
  const navigate = useNavigate();
  const { locationId, orgId } = useParams<LocationIdOrgIdParamType>();
  const [, { updateLocation, addLocation }] = useLocationsLoader(`/org/${orgId}/locations`);

  const handleCancelClick = () => {
    navigate(toPath(PATHS.locations, { orgId: orgId }));
  };

  const handleSubmit = async (
    formValues: LocationFormValues,
    setError: (name: FieldPath<LocationFormValues>, error: ErrorOption, options?: { shouldFocus: boolean }) => void,
    mobileLogoImage: Blob
  ) => {
    if (mode === FormMode.Edit) {
      const actionSuccess = await updateLocation(formValues, setError, mobileLogoImage, locationId);
      if (actionSuccess) {
        navigate(toPath(PATHS.locations, { orgId }));
      }
    } else {
      const actionSuccess = await addLocation(formValues, setError, mobileLogoImage);
      if (actionSuccess) {
        navigate(toPath(PATHS.locations, { orgId }));
      }
    }
  };

  return (
    <div>
      <PageTitle
        title={mode === FormMode.Edit ? 'Edit Location' : 'Add Location'}
        underlined
        titleSx={{ fontSize: '20px' }}
      />
      <FormPageContainer>
        <LocationForm
          mode={mode}
          locationId={locationId}
          handleCancelClick={handleCancelClick}
          handleSubmit={handleSubmit}
          orgId={orgId}
        />
      </FormPageContainer>
    </div>
  );
};

export default LocationFormContainer;
