import { Box } from '@mui/material';

export interface SocialMediaLink {
  title: string;
  imageSrc: string;
  link: string;
}

export const SocialMediaLinks = ({ links }: { links: SocialMediaLink[] }) => {
  return (
    <Box sx={{ display: 'flex', gap: '25px', position: 'absolute', top: 0, right: 0 }}>
      {links.map((item) => (
        <a href={item.link} key={item.title}>
          <img src={item.imageSrc} alt={item.title} style={{ width: 40, height: 40 }} />
        </a>
      ))}
    </Box>
  );
};
