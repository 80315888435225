import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useLeftSidebarStore } from '@efacity/frontend-next-shared/left-navigation';

export const HeaderLeftMenuToggleBtn = () => {
  const setIsExpanded = useLeftSidebarStore((state) => state.setLeftNavExpanded);
  const isExpanded = useLeftSidebarStore((state) => state.isExpanded);
  const btnAriaLabel = isExpanded ? 'Hide admin menu' : 'Expand admin menu';

  return (
    <IconButton
      onClick={() => {
        setIsExpanded(!isExpanded);
      }}
      color="primary"
      aria-label={btnAriaLabel}
      sx={{ display: { xs: 'flex', md: 'none', marginRight: '5px' } }}
    >
      <MenuIcon />
    </IconButton>
  );
};
