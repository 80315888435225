export enum TaxTypes {
  None = 'none',
  Tax = 'tax',
  VAT = 'vat',
  HST = 'hst',
  GB_VAT = 'gb_vat'
}

export const TaxTypesView = {
  [TaxTypes.None]: 'None',
  [TaxTypes.Tax]: 'Tax',
  [TaxTypes.VAT]: 'VAT',
  [TaxTypes.HST]: 'HST',
  [TaxTypes.GB_VAT]: 'GB VAT'
};

export const taxTypesOptions = Object.entries(TaxTypesView).map(([value, label]) => {
  return {
    value,
    label
  };
});
