import { TableCell, TableRow } from '@mui/material';
import React, { ChangeEvent, FC } from 'react';
import { totalCellSx } from '@efacity/frontend-shared';
import TextField from '@mui/material/TextField';

export interface CouponDiscountInputAsRowProps {
  handleDiscountCodeChanged: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  disabled: boolean;
}

const CouponDiscountInputAsRow: FC<CouponDiscountInputAsRowProps> = ({ handleDiscountCodeChanged, disabled }) => {
  return (
    <TableRow>
      <TableCell sx={totalCellSx} colSpan={6} align="right">
        Discount Code:
      </TableCell>
      <TableCell sx={totalCellSx} colSpan={2} align="right">
        <TextField
          name="discountCode"
          variant="outlined"
          size="small"
          fullWidth
          disabled={disabled}
          onChange={handleDiscountCodeChanged}
        />
      </TableCell>
    </TableRow>
  );
};

export default CouponDiscountInputAsRow;
