import React from 'react';
import { Dialog, DialogContent, DialogProps, LinearProgress } from '@mui/material';
import { InstanceTemporality } from '@efacity/common';
import { Session } from '@efacity/react-next-sc';
import { useFetch } from '@efacity/frontend-shared';
import SessionsListTable from './SessionsListTable';
import { ModalDialogTitle } from '@efacity/react-next-sc';

export interface SelectSessionModalProps extends DialogProps {
  orgId: string;
  studentId: string;
  onClose: () => void;
  onSessionSelected;
  isRegistering: boolean;
}

const SelectSessionModal: React.FC<SelectSessionModalProps> = ({
  open,
  orgId,
  studentId,
  onClose,
  onSessionSelected,
  isRegistering
}) => {
  const [{ data, isLoading }] = useFetch<{ data: Session[]; total: number }>(
    `/org/${orgId}/sessions/${InstanceTemporality.Upcoming}`,
    {
      initialDataState: []
    }
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <ModalDialogTitle text="Select session to register on:" onClose={onClose} />
      <DialogContent>
        {isRegistering ? (
          <LinearProgress variant="indeterminate" color="primary" data-testid="loadingIndicator" />
        ) : (
          <LinearProgress variant="determinate" color="primary" data-testid="loadingIndicator" value={100} />
        )}
        <SessionsListTable
          sessions={data.data}
          studentId={studentId}
          isLoading={isLoading}
          onSessionSelected={onSessionSelected}
          isRegistering={isRegistering}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SelectSessionModal;
