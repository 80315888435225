import React, { useEffect } from 'react';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { useParams } from 'react-router-dom';
import LocationsTable from './LocationsTable';
import { useLocationsLoader } from './useLocationsLoader';
import { PATHS, toPath } from '@efacity/routing';
import { PageTitle } from '@efacity/react-next-sc';

const LocationsContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const [{ locationsState }, { getLocations, deleteLocation }] = useLocationsLoader(`/org/${orgId}/locations`);

  useEffect(() => {
    getLocations();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <div style={{ marginTop: '15px' }}>
      <PageTitle title="" addButtonLink={toPath(PATHS.addLocation, { orgId: orgId })} />
      <LocationsTable
        handleDeleteLocation={deleteLocation}
        locations={locationsState.locations}
        isLoading={locationsState.isLoading}
      />
    </div>
  );
};

export default LocationsContainer;
