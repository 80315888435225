import { Grid, MenuItem } from '@mui/material';
import React from 'react';
import { SchoolGradeStudentLevelTypeValue, studentLevelTypes } from '@efacity/common';
import { FormTextInput } from './FormTextInput';
import { AgeSelector } from './AgeSelector';
import { FormGradeSelector } from './FormGradeSelector';
import { useFormContext } from 'react-hook-form';

export interface StudentLevelSelectorProps {
  namePrefix: string;
  label?: string;
  disabled?: boolean;
}

export const StudentLevelSelector: React.FC<StudentLevelSelectorProps> = ({
  namePrefix,
  label = 'Student level',
  disabled = false
}) => {
  const { watch } = useFormContext();

  return (
    <Grid container spacing={1}>
      <Grid item xs={4} sm={4} md={4}>
        <FormTextInput
          select
          label={label}
          name={`${namePrefix}.type`}
          data-testid="studentLevelType"
          disabled={disabled}
        >
          {studentLevelTypes.map((studentLevel) => (
            <MenuItem key={studentLevel.value} value={studentLevel.value}>
              {studentLevel.name}
            </MenuItem>
          ))}
        </FormTextInput>
      </Grid>
      {watch(`${namePrefix}.type`) === SchoolGradeStudentLevelTypeValue ? (
        <>
          <Grid item xs={4} sm={4} md={4}>
            <FormGradeSelector
              name={`${namePrefix}.fromSchoolGradeNumeric`}
              label={'From grade'}
              data-testid="studentLevelFromGradeSort"
              disabled={disabled}
              required={false}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <FormGradeSelector
              name={`${namePrefix}.toSchoolGradeNumeric`}
              label={'To grade'}
              data-testid="studentLevelToGradeSort"
              disabled={disabled}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={4} sm={4} md={4}>
            <AgeSelector
              name={`${namePrefix}.fromAge`}
              label={'From age'}
              data-testid="studentLevelFromAge"
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <AgeSelector
              name={`${namePrefix}.toAge`}
              label={'To age'}
              data-testid="studentLevelToAge"
              disabled={disabled}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
