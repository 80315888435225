import { CurrencyCode } from './Currencies';
import { TaxTypes, TaxTypesView } from './Taxes';
import { Types } from 'mongoose';
import { Customer } from './registrationsResponses';

export enum InvoiceItemTypes {
  Registration = 'registrationInvoiceItemType',
  AdditionalSessionOption = 'additionalChargeInvoiceItemType',
  Other = 'otherInvoiceItemType',
  Discount = 'discountInvoiceItemType',
  Product = 'productInvoiceItemType',
  EnrollmentApplication = 'enrollmentApplicationInvoiceItemType'
}

export class PaymentProcessingType {
  static readonly CREDIT_CARD = new PaymentProcessingType('creditCardPaymentProcessingType', 'Credit Card');
  static readonly GIFT_CARD = new PaymentProcessingType('giftCardPaymentProcessingType', 'Gift Card');
  static readonly CREDIT = new PaymentProcessingType('creditPaymentProcessingType', 'Credit');
  static readonly PAYPAL = new PaymentProcessingType('paypalPaymentProcessingType', 'Paypal');

  // private to disallow creating other instances of this type
  private constructor(public readonly value: string, public readonly displayText: string) {}

  toString() {
    return this.displayText;
  }
}

export interface UserRegistrationData {
  _id: string;
  email: string;
  fullName: string;
  callByName: string;
}

export interface InvoiceItem {
  _id: string;
  invoiceItemId: string;
  description: string;
  invoiceItemType: string;
  currencyCode: CurrencyCode;
  amount: number;
  paidAmount: number;
  registrationId?: string;
  studentId?: string;
  sessionId?: string;
  additionalOptionId?: string;
  enrollmentApplicationId?: string;
  centerPaymentId?: string;
}

export interface InvoiceItemPresentation {
  invoiceItemId: string;
  description: string;
  currencyCode: string;
  amount: number;
  paidAmount: number;
  invoiceItemType: InvoiceItemTypes;
  appliedAutomatically: boolean;
  registrationId?: string;
  studentId?: string;
  sessionId?: string;
  discountId?: string;
  productPurchaseId?: string;
  studentName?: string;
  sessionName?: string;
  additionalOptionId?: string;
  startDate?: Date;
  instanceDuration?: number;
  weekDays?: string[];
  sessionDuration?: string;
}

export interface InvoicePresentation {
  _id: string;
  invoiceNo: string;
  amount: number;
  taxAmount: number;
  amountWithTax: number;
  paidAmount: number;
  appliedCredit: number;
  issuedCredit: number;
  balance: number;
  taxType: TaxTypes;
  taxPercentage: number;
  currencyCode: CurrencyCode;
  isPaid: boolean;
  invoiceItems: InvoiceItemPresentation[];
}

export interface InvoiceBalance {
  _id: string;
  invoiceNo: string;
  invoiceDate: Date;
  notes?: string;
  amount: number;
  taxType: TaxTypes;
  taxAmount: number;
  taxPercentage: number;
  amountWithTax: number;
  paidAmount: number;
  payoutsAmount: number;
  ownerPayoutsAmount: number;
  thirdPartyPayoutsAmount: number;
  appliedCredit: number;
  issuedCredit: number;
  balance: number;
  isPaid: boolean;
  invoiceItems?: InvoiceItem[];
  payments?: PaymentInterface[];
  paymentWithCredit?: PaymentCreditInterface;
  invoiceFileName?: string;
  currencyCode: CurrencyCode;
  customer?: Partial<Customer> | null;
  cardInfo: CardInfo | null;
}

export const invoiceBalanceFrontendFields = [
  '_id',
  'invoiceNo',
  'invoiceDate',
  'notes',
  'amount',
  'taxType',
  'taxAmount',
  'taxPercentage',
  'amountWithTax',
  'paidAmount',
  'payoutsAmount',
  'appliedCredit',
  'issuedCredit',
  'balance',
  'isPaid',
  'invoiceItems',
  'payments',
  'currencyCode',
  'customer'
];

export interface PaymentInterface {
  _id?: Types.ObjectId | string;
  payer: Types.ObjectId | string;
  amount: number;
  externalPaymentAmount: number;
  appliedCreditAmount: number;
  issuedCreditAmount: number;
  currencyCode: string;
  paymentProcessingType: string;
  stripePaymentIntent: any;
  paymentDate: Date;
  notes?: string;
  allocations?: any;
}

export interface CreditFromPaymentInterface {
  paymentId?: Types.ObjectId | string;
  paidOn: Date;
  stripePaymentIntent: {
    id: string;
    amount: number;
    currency: string;
    created: number;
    status: string;
    charges: {
      data: {
        id: string;
        amount: number;
        currency: string;
        created: number;
        status: string;
      };
    };
  } | null;
  intentId?: string | null;
  creditedAmount: number;
  returnedAmount: number;
  creditedOn: Date;
  creditedBy: Types.ObjectId | string;
  usedOn?: Date;
  refundedOn?: Date;
  refundedBy?: Types.ObjectId | string;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  stripeRefund?: any;
}

export interface PaymentCreditInterface {
  amount: number;
  returnedAmount: number;
  currencyCode: CurrencyCode;
  fromCredit: Types.ObjectId | string;
  creditFromPayments: CreditFromPaymentInterface[];
}

export enum CardTypes {
  CreditCard = 'creditCard'
}

export const cardOptions = [
  {
    label: 'Credit card',
    value: CardTypes.CreditCard
  }
];

export interface CardInterface {
  cvc?: string;
  exp_month: number;
  exp_year: number;
  number: string;
}

export const getTaxDescription = (taxType: TaxTypes, taxPercentage?: number): string => {
  return taxType ? `${TaxTypesView[taxType]} (${taxPercentage || 0}%) :` : '';
};

export const PAYMENT_LIMIT_IN_CENTS = 50;
export const STRIPE_LOW_AMOUNT_CODE = 'amount_too_small';

export interface CardInfo {
  last4: string;
  cardType: string;
}
