import { useEffect, useState } from 'react';
import { CustomerWithCredits } from '@efacity/common';
import { apiService } from '@efacity/frontend-shared';

export interface UserCreditsState {
  data: CustomerWithCredits[];
  isLoading: boolean;
}
export const emptyStudentsListState = {
  data: [],
  isLoading: false
};

export const useUserCredits = userId => {
  const [userCreditsState, setUserCreditsState] = useState<UserCreditsState>(emptyStudentsListState);

  useEffect(() => {
    const getUserCredits = async () => {
      setUserCreditsState(state => {
        return { ...state, isLoading: true };
      });
      try {
        const { data } = await apiService.get<CustomerWithCredits[]>(`/users/${userId}/credits`);
        setUserCreditsState(() => ({
          data: data,
          isLoading: false
        }));
      } catch (error) {
        setUserCreditsState(emptyStudentsListState);
      }
    };
    if (userId) getUserCredits();
  }, [userId]);

  return userCreditsState;
};
