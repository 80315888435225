import { CountryCode } from '@efacity/common';

export const steamworksAddressId = '5ea6de9f2f37a98j5jh3d4a85';
export const steamworksAddress = {
  _id: steamworksAddressId,
  country: CountryCode.USA,
  address: `137, Village Blvd.`,
  city: 'New-Jersey',
  state: 'NJ',
  zip: '08540',
  mapLink: 'https://www.google.com/maps/place/137+Village+Blvd,+New-Jersey,+NJ+08540'
};

export const teslaAddressId = '5ea6deksjf37a98j5jh3d4a85';
export const teslaAddress = {
  _id: teslaAddressId,
  country: CountryCode.USA,
  address: `136, Village Blvd.`,
  city: 'New-Jersey',
  state: 'NJ',
  zip: '08540',
  mapLink: 'https://www.google.com/maps/place/136+Village+Blvd,+New-Jersey,+NJ+08540'
};
