import type {
  Activity,
  ActivityTypes,
  AdditionalSessionOption,
  CurrencyCode,
  DurationType,
  Location,
  LocationType,
  OwnerInterface,
  RegistrationResponse,
  SessionStatus,
  StudentLevelOptional,
  User,
  Tag
} from '@efacity/common';
import type { Descendant } from 'slate';
import type { StudentRegistration } from './UserProfile';
import type { Teacher } from './Teacher';

export enum SessionState {
  SessionStateUnspecified = 'SessionStateUnspecified',
  Active = 'Active',
  Provisioned = 'Provisioned',
  Declined = 'Declined',
  Suspended = 'Suspended'
}

export class SessionsStateOption {
  constructor(public value: SessionState, public label: string) {}
}

export const sessionStateWithLabels: SessionsStateOption[] = [
  {
    value: SessionState.SessionStateUnspecified,
    label: 'Unspecified'
  },
  {
    value: SessionState.Active,
    label: 'Active'
  },
  {
    value: SessionState.Provisioned,
    label: 'Provisioned'
  },
  {
    value: SessionState.Declined,
    label: 'Declined'
  },
  {
    value: SessionState.Suspended,
    label: 'Suspended'
  }
];

export class Instance {
  constructor(public startDateTime: Date, public durationInMinutes: number, public _id?: string) {}
}

export class EfacitySessionSchedule {
  constructor(
    public startDateTime: Date,
    public endDateTime: Date,
    public durationInMinutes: number,
    public numberOfInstances: number,
    public instances: Instance[],
    public deadlineForCustomerRegistration: Date,
    public lastCancellationDate: Date,
    public sendMessages?: boolean
  ) {}
}

export class Session {
  constructor(
    public _id: string,
    public name: string,
    public type: ActivityTypes,
    public durationType: DurationType,
    public activityId: string,
    public studentLevel: StudentLevelOptional,
    public price: number,
    public currency: CurrencyCode,
    public capacity: number,
    public shortDescription: string,
    public longDescription: string | Descendant[],
    public createdBy: string,
    public createdAt: Date,
    public owner: OwnerInterface,
    public teacherId: string,
    public sessionState: SessionState,
    public status: SessionStatus,
    public organizationName: string,
    public section?: string,
    public descriptionHeading?: string,
    public updatedAt?: Date,
    public enrollmentCode?: string,
    public registrationsNumber?: number,
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    public googleClassroom?: any,
    public publishedAt?: Date,
    public schedule?: EfacitySessionSchedule,
    public students?: StudentRegistration[],
    public registrations?: RegistrationResponse[],
    public teacher?: User,
    public imageFullUrl?: string,
    public weekDays?: string[],
    public additionalSessionOptions?: AdditionalSessionOption[],
    public sendCalendarInvites?: boolean,
    public cancelledAt?: Date,
    public cancelledBy?: string,
    public cancellationReason?: string,
    public timeZone?: string,
    public location?: Location,
    public locationId?: string,
    public locationType?: LocationType,
    public locationDisplayName?: string,
    public locationDisplayLink?: string,
    public certificationForActivity?: Activity,
    public rating?: number,
    public reviewCount?: number
  ) {}
}

export interface SessionWithLocationAndTags extends Session {
  location?: Location;
  tags?: Partial<Tag>;
}

export interface EfacitySessionMainInfo {
  _id: string;
  name: string;
  durationType: DurationType;
  activity: string;
  teacher: Teacher;
  price: number;
  capacity: number;
}

export interface InstanceInfo {
  startDateTime: Date;
  durationInMinutes: number;
}

export interface SessionScheduleInfo {
  startDateTime: Date;
  durationInMinutes: number;
  numberOfInstances: number;
  instances: InstanceInfo[];
}

export interface SessionInfo {
  _id: number;
  imageSrc?: string | null;
  studentLevel: StudentLevelOptional;
  name: string;
  description: string;
  time: string;
  schedule?: SessionScheduleInfo;
  capacity: number;
  price: number;
  currency: CurrencyCode;
}
