import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { PATHS } from '@efacity/routing';
import { PageSubtitle } from '../../components/PageSubtitle/PageSubtitle';
import { useMatchRoutes } from '../../hooks/useMatchRoutes';

const TagsContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();

  const tabs = [
    {
      label: 'Current',
      to: 'current',
      matchPaths: [PATHS.tagsCurrent]
    },
    { label: 'Expired', to: 'expired', matchPaths: [PATHS.tagsExpired] }
  ];
  const isCurrentTab = useMatchRoutes([PATHS.tagsCurrent]);
  const addButtonLink = isCurrentTab ? `/admin/${orgId}/tag` : null;

  return (
    <div style={{ marginTop: '15px' }}>
      <PageSubtitle tabs={tabs} addBtnLink={addButtonLink} />
      <Outlet />
    </div>
  );
};

export default TagsContainer;
