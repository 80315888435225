import { MenuItem, TextFieldProps } from '@mui/material';
import React from 'react';
import { TShirtSize } from '@efacity/common';
import { FormTextInput } from './FormTextInput';

interface TShirtSizeSelectorProps {
  name: string;
}

export const TShirtSizeSelector: React.FC<TShirtSizeSelectorProps & TextFieldProps> = ({ name, ...props }) => {
  return (
    <FormTextInput select name={name} {...props}>
      <MenuItem key={'tShirtSizeKey_None'} value="">
        None
      </MenuItem>
      {Object.values(TShirtSize).map((tShirtSize, index) => (
        <MenuItem key={`tShirtSizeKey${tShirtSize.value}_${index}`} value={tShirtSize.value}>
          {tShirtSize.displayText}
        </MenuItem>
      ))}
    </FormTextInput>
  );
};
