import { apiService } from '@efacity/frontend-shared';
import {
  smallTableCellSx,
  smallTableContainerSx,
  smallTableHeadCellSx,
  smallTableHeadSx,
  smallTableRowSx
} from '@efacity/react-next-sc';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react';

import {
  CreditFromPaymentInterface,
  CurrencyCode,
  Messages,
  capitalizeFirstLetter,
  convertUtcToZonedTime,
  moneyWithCurrencyCodeFormatter,
  formatDateWithFormatter,
  monthTimeFormat
} from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { smallButtonFontSize, smallButtonHeight, CircularProgressButton } from '@efacity/frontend-shared';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

export const smallRefundButtonStyle: React.CSSProperties = {
  width: 70,
  textTransform: 'uppercase',
  marginLeft: 5,
  marginRight: 5,
  padding: 0,
  paddingTop: 2,
  ...smallButtonHeight,
  ...smallButtonFontSize
};

interface CreditsToRefundTableProps {
  orgId: string;
  creditId: string;
  creditFromPayments: CreditFromPaymentInterface[];
  currencyCode: string;
  timeZone: string;
  allowRefund: boolean;
}

const CreditsToRefundTable: React.FC<CreditsToRefundTableProps> = ({
  orgId,
  creditId,
  creditFromPayments,
  currencyCode,
  timeZone,
  allowRefund
}) => {
  const snackbarProps = useSnackbar();
  const navigate = useNavigate();

  const [isRefunding, setIsRefunding] = useState(false);
  const handleRefundCredit = (intentId: string) => {
    setIsRefunding(true);
    return apiService
      .post<{ message: string }>(`/org/${orgId}/credits/${creditId}/${intentId}/refund`, {})
      .then(({ data }) => {
        setIsRefunding(false);
        showNotification(snackbarProps, true, data.message);
        navigate(0);
      })
      .catch((error) => {
        setIsRefunding(false);
        showNotification(
          snackbarProps,
          false,
          error.response?.data?.message || Messages.FailedRefundCredit,
          true,
          'Dismiss',
          () => navigate(0)
        );
      });
  };

  return (
    <TableContainer sx={smallTableContainerSx}>
      <Table size="small" aria-label="a simple dense table">
        <TableHead sx={smallTableHeadSx}>
          <TableRow sx={smallTableRowSx}>
            <TableCell sx={smallTableHeadCellSx}>Amount</TableCell>
            <TableCell sx={smallTableHeadCellSx}>Source</TableCell>
            <TableCell sx={smallTableHeadCellSx}>Credit Created On</TableCell>
            <TableCell sx={smallTableHeadCellSx}>Payment Processed On</TableCell>
            {allowRefund && <TableCell sx={smallTableHeadCellSx}></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {creditFromPayments.map((creditFromPayment, i) => {
            const intentId = creditFromPayment?.stripePaymentIntent?.id || creditFromPayment?.intentId;
            const cardInfo = creditFromPayment?.stripePaymentIntent
              ? {
                  last4:
                    creditFromPayment?.stripePaymentIntent?.charges?.data[0]?.payment_method_details?.card?.last4 || '',
                  cardType: capitalizeFirstLetter(
                    creditFromPayment?.stripePaymentIntent?.charges?.data[0]?.payment_method_details?.card?.brand || ''
                  )
                }
              : null;
            return (
              <TableRow sx={smallTableRowSx} key={`refundRowForCredit${intentId ?? i}Row${i}Key`}>
                <TableCell sx={smallTableCellSx} align="left">
                  {moneyWithCurrencyCodeFormatter(creditFromPayment.creditedAmount, currencyCode as CurrencyCode)}
                </TableCell>
                <TableCell sx={smallTableCellSx} align="left">
                  {cardInfo ? `${cardInfo.cardType} (...${cardInfo.last4})` : '[n/a]'}
                </TableCell>
                <TableCell sx={smallTableCellSx}>
                  {formatDateWithFormatter(
                    convertUtcToZonedTime(creditFromPayment.creditedOn, timeZone),
                    monthTimeFormat
                  )}
                </TableCell>
                <TableCell sx={smallTableCellSx}>
                  {creditFromPayment?.stripePaymentIntent?.created
                    ? formatDateWithFormatter(
                        convertUtcToZonedTime(new Date(creditFromPayment.stripePaymentIntent.created * 1000), timeZone),
                        monthTimeFormat
                      )
                    : '[n/a]'}
                </TableCell>
                {allowRefund && (
                  <TableCell sx={smallTableCellSx} align="right">
                    {intentId && (
                      <CircularProgressButton
                        progress={isRefunding}
                        color={'primary'}
                        disabled={isRefunding}
                        onClick={() => handleRefundCredit(intentId)}
                        style={smallRefundButtonStyle}
                      >
                        Refund
                      </CircularProgressButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CreditsToRefundTable;
