import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { StudentFormValues } from './StudentFormValues';
import { calculateAge, FormMode, mapSchoolGradeToAge } from '@efacity/common';
import {
  FormPhoneInput,
  FormTextInput,
  FormGradeSelector,
  DateOfBirthSelector,
  PronounSelector,
  TShirtSizeSelector,
  defaultRHFSetValueOptions
} from '@efacity/react-hook-form-mui';
import { yupResolver } from '@hookform/resolvers/yup';
import { studentFormValidationSchema } from './StudentFormValidationSchema';
import { FormCancelSubmitButtons } from '@efacity/frontend-next-shared/forms';
import { Grid } from '@mui/material';
import { AgeSelector } from '@efacity/react-hook-form-mui';

interface CustomerStudentFormProps {
  initialFormValues: StudentFormValues;
  mode: FormMode;
  handleSubmit: (
    values: StudentFormValues,
    setError: (fieldName: keyof StudentFormValues, error: { type: string; message: string }) => void
  ) => void;
  handleCancel?: () => void | null;
  isCancelSubmitDisabled: boolean;
  requireStudentAge: boolean;
}

const CustomerStudentForm: FC<CustomerStudentFormProps> = ({
  initialFormValues,
  mode,
  handleSubmit,
  handleCancel = null,
  isCancelSubmitDisabled,
  requireStudentAge = false
}) => {
  const methods = useForm<StudentFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(studentFormValidationSchema(requireStudentAge)),
    defaultValues: initialFormValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUseNativeValidation: false
  });
  useEffect(() => {
    methods.reset(initialFormValues);
  }, [methods, initialFormValues]);

  const { isSubmitting, isValid, isDirty } = methods.formState;

  const submitForm = (values: StudentFormValues) => {
    handleSubmit(values, methods.setError);
  };

  const onGradeChanged = (gradeSortValue: number) => {
    methods.setValue('age', mapSchoolGradeToAge(gradeSortValue), defaultRHFSetValueOptions);
  };

  const onDobChanged = (dob: Date) => {
    const age = calculateAge(dob);
    const efAge = age < 4 ? 3 : age > 16 ? 17 : age;
    methods.setValue('age', efAge, defaultRHFSetValueOptions);
  };

  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete="off" onSubmit={methods.handleSubmit(submitForm)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormTextInput name="fullName" label="Full Name" fullWidth required />
          </Grid>

          {mode === FormMode.Edit && (
            <>
              <Grid item xs={12}>
                <FormTextInput name="email" label="E-mail (used for login)" />
              </Grid>
              <Grid item xs={12}>
                <FormPhoneInput name="defaultPhone" label="Student Phone" required={false} disabled={false} />
              </Grid>
              <Grid item xs={12}>
                <FormTextInput name="studentId" label="Student ID" />
              </Grid>
              <Grid item xs={12}>
                <PronounSelector name="preferredPronoun" label="Pronoun" required={false} />
              </Grid>
              <Grid item xs={12}>
                <TShirtSizeSelector name="tShirtSize" label="T-Shirt Size" />
              </Grid>
              <Grid item xs={12}>
                <FormGradeSelector
                  name="gradeInSeptemberNumeric"
                  label={`Grade starts in September ${new Date().getUTCFullYear()}`}
                  disabled={isSubmitting}
                  onGradeChanged={onGradeChanged}
                />
              </Grid>
              <Grid item xs={12}>
                <DateOfBirthSelector onDobChanged={onDobChanged} />
              </Grid>
            </>
          )}
          {requireStudentAge && (
            <Grid item xs={12}>
              <AgeSelector name="age" label="Age *" />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormTextInput name="medicalInformation" label="Medical Information" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <FormTextInput name="emergencyInstructions" label="Emergency Instructions" fullWidth />
          </Grid>
        </Grid>
        <FormCancelSubmitButtons
          onCancel={handleCancel}
          mode={mode}
          isSubmitting={isCancelSubmitDisabled || isSubmitting}
          isFormValid={isValid}
          isDisabled={isSubmitting || !isDirty}
        />
      </form>
    </FormProvider>
  );
};

export default CustomerStudentForm;
