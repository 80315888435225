import React, { FC } from 'react';
import { MenuItem } from '@mui/material';
import { organizationStatusSelectOptions } from '@efacity/common';
import { FormInputTextProps, FormTextInput } from './FormTextInput';

interface OrganizationStatusSelectorProps {
  name: string;
}

export const OrganizationStatusSelector: FC<OrganizationStatusSelectorProps & FormInputTextProps> = ({ name }) => {
  return (
    <FormTextInput select name={name} label="Status">
      {organizationStatusSelectOptions.map((option) => (
        <MenuItem key={`orgStatus${option.value}Key`} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </FormTextInput>
  );
};
