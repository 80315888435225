import React, { FC, useCallback, useEffect, useState } from 'react';
import { Messages } from '@efacity/common';
import { Dialog, DialogContent } from '@mui/material';
import {
  WithLoading,
  initialUserFormValues,
  UserFormValues,
  apiService,
  getMergedFormValues
} from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { useSnackbar } from 'notistack';
import { ModalDialogTitle } from '@efacity/react-next-sc';
import { UserSettingsForm, userProfileFields } from '@efacity/frontend-next/site-admin/users';

export interface UserEditModalProps {
  open: boolean;
  orgId: string;
  userId: string;
  onClose: (reloadData: boolean) => void;
}

const UserEditModal: FC<UserEditModalProps> = ({ open, orgId, userId, onClose }) => {
  const snackbarProps = useSnackbar();
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [userFormValues, setUserFormValues] = useState(initialUserFormValues);

  const getUser = useCallback(async () => {
    try {
      setIsUserLoading(true);
      const { data } = await apiService.get<UserFormValues>(`/org/${orgId}/users/${userId}`);
      setUserFormValues(getMergedFormValues(initialUserFormValues, data));
      setIsUserLoading(false);
    } catch (error) {
      setIsUserLoading(false);
      showNotification(snackbarProps, false, error.message || Messages.FailedToGetUser);
    }
  }, [orgId, userId, snackbarProps]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const handleSubmit = async (
    formValues: UserFormValues,
    setError: (fieldName: keyof UserFormValues, error: { type: string; message: string }) => void
  ) => {
    return apiService
      .patch<{ message: string }>(`/org/${orgId}/users/${userId}`, formValues)
      .then(() => {
        onClose(true);
      })
      .catch((error) => {
        const { data: errorData } = error.response;
        if (errorData.validationErrors) {
          addServerErrors<UserFormValues>(errorData.validationErrors, setError);
        } else {
          showNotification(
            snackbarProps,
            false,
            (error.response.data.message as string) || Messages.FailedToUpdateUser,
            true
          );
        }
      });
  };

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="sm">
      <ModalDialogTitle text="Edit User" onClose={() => onClose(false)} />
      <DialogContent>
        <WithLoading isLoading={isUserLoading} message={'Loading user settings...'} style={{ width: '100%' }}>
          <UserSettingsForm
            initialFormValues={userFormValues}
            organizationOptions={[]}
            allowHandleAccess={false}
            allowChangeEmail={false}
            allowChangePassword={false}
            handleSubmit={handleSubmit}
            isSubmitDisabled={isUserLoading}
            handleCancel={handleCancel}
            showCancel={true}
            userModelFields={userProfileFields}
          />
        </WithLoading>
      </DialogContent>
    </Dialog>
  );
};

export default UserEditModal;
