import React from 'react';

export default function FullDayIcon() {
  return (
    <svg
      width="130"
      height="120"
      viewBox="0 0 165 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <circle opacity="0.5" cx="88.4643" cy="57.2857" r="84.4143" fill="white" />
        <circle opacity="0.5" cx="87.9819" cy="57.7681" r="68.4962" fill="white" />
        <circle opacity="0.5" cx="90.3937" cy="57.2857" r="54.5075" fill="white" />
        <path
          d="M111.535 55.5351C110.726 55.5351 110.07 56.191 110.07 56.9999C110.07 69.1695 100.169 79.0701 87.9999 79.0701C75.8304 79.0701 65.9298 69.1695 65.9298 56.9999C65.9298 44.8304 75.8304 34.9298 87.9999 34.9298C91.7107 34.9298 95.3203 35.8511 98.5427 37.6068L96.7113 39.4381C96.2924 39.8571 96.1671 40.4871 96.3938 41.0345C96.6205 41.5819 97.1546 41.9388 97.7471 41.9388H104.756C105.565 41.9388 106.221 41.2829 106.221 40.474V33.4649C106.221 32.8724 105.864 32.3384 105.317 32.1116C104.769 31.8848 104.139 32.0102 103.72 32.4292L100.691 35.4582C96.8545 33.1929 92.4922 32.0002 87.9998 32.0002C81.3221 32.0002 75.0441 34.6007 70.3222 39.3224C65.6004 44.0444 63 50.3223 63 57C63 63.6777 65.6004 69.9557 70.3222 74.6776C75.0441 79.3995 81.3221 82 87.9998 82C94.6775 82 100.955 79.3995 105.677 74.6776C110.399 69.9557 112.999 63.6777 112.999 57C113 56.191 112.344 55.5351 111.535 55.5351Z"
          fill="#0A98D1"
        />
        <path
          d="M97.4143 55.5351H89.4652V47.5859C89.4652 46.7769 88.8093 46.1211 88.0003 46.1211C87.1913 46.1211 86.5355 46.7769 86.5355 47.5859V56.9999C86.5355 57.8089 87.1913 58.4648 88.0003 58.4648H97.4143C98.2233 58.4648 98.8792 57.8089 98.8792 56.9999C98.8792 56.191 98.2233 55.5351 97.4143 55.5351Z"
          fill="#0A98D1"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.0500488"
          y="-27.1285"
          width="176.829"
          height="176.829"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.835294 0 0 0 0 0.929412 0 0 0 0 0.929412 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
