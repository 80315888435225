import { LinearProgress, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const PaymentConfirmation: React.FC = () => {
  const { search } = useLocation();
  const { payment_intent } = queryString.parse(search);
  useEffect(() => {
    if (payment_intent) {
      window.top.postMessage({ message: '3DS-authentication-complete', payment_intent: payment_intent });
    }
  });

  return (
    <div style={{ margin: 30 }}>
      <Typography color="primary" style={{ fontSize: 18, textAlign: 'center', margin: 30 }}>
        Redirect to payment page...
      </Typography>
      <div style={{ width: '50%', margin: 'auto' }}>
        <LinearProgress variant="indeterminate" color="primary" data-testid="loadingIndicator" />
      </div>
    </div>
  );
};
