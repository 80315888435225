import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { WithLoading } from '@efacity/frontend-shared';
import { useOrganizationEmailSettingsLoader } from './useOrganizationEmailSettingsLoader';
import { OrganizationEmail } from './organizationEmailSettingsFormValues';
import { OrganizationMailingForm } from '../Organization/OrganizationMailingForm';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { organizationEmailSettingsValidationObject } from '../../SiteAdminOrganizationsContainer/organizationFormValidationSchema';
import { FormMode } from '@efacity/common';
import { FormCancelSubmitButtons } from '@efacity/frontend-next-shared/forms';

export const EmailSettingsContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();

  const [{ organizationEmailSettingsState }, { updateOrgEmailSettings }] = useOrganizationEmailSettingsLoader(orgId);

  const methods = useForm<OrganizationEmail>({
    defaultValues: organizationEmailSettingsState.organizationEmailSettings,
    resolver: yupResolver(organizationEmailSettingsValidationObject),
    mode: 'onBlur'
  });
  const { isDirty, isValid, isSubmitting } = methods.formState;

  const onFormSubmit = async (formValues: OrganizationEmail) => {
    await updateOrgEmailSettings(formValues, methods.setError);
  };

  useEffect(
    () => {
      methods.reset(organizationEmailSettingsState.organizationEmailSettings);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationEmailSettingsState.isLoading, JSON.stringify(organizationEmailSettingsState.organizationEmailSettings)]
  );

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onFormSubmit)}>
        <WithLoading
          isLoading={organizationEmailSettingsState.isLoading}
          message={'Loading settings data...'}
          style={{ width: '25%' }}
        >
          <>
            <OrganizationMailingForm orgId={orgId} />
            <div style={{ marginTop: 30 }}></div>
            <FormCancelSubmitButtons
              mode={FormMode.Edit}
              onCancel={() => methods.reset(organizationEmailSettingsState.organizationEmailSettings)}
              isFormValid={isValid}
              isSubmitting={isSubmitting}
              isDisabled={!isDirty}
            />
          </>
        </WithLoading>
      </form>
    </FormProvider>
  );
};
