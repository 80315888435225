import {
  CurrencyCode,
  getTaxDescription,
  InvoiceBalance,
  InvoiceItemTypes,
  Messages,
  moneyWithCurrencyCodeFormatter,
  TaxTypes
} from '@efacity/common';
import {
  smallTableCellSx,
  smallTableContainerSx,
  smallTableHeadCellSx,
  smallTableHeadSx,
  smallTableRowSx,
  smallTableTotalCellSx
} from '@efacity/react-next-sc';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FC } from 'react';

export interface SmallInvoiceDataProps {
  invoiceBalance: InvoiceBalance;
  message?: string;
}

const SmallInvoiceDataTable: FC<SmallInvoiceDataProps> = ({
  invoiceBalance,
  message = Messages.NoInvoiceForRegistration
}) => {
  const getPaddingLeft = (invoiceItem) => {
    if (invoiceItem.invoiceItemType === InvoiceItemTypes.Registration) {
      return '10px';
    } else if (invoiceItem.invoiceItemType === InvoiceItemTypes.AdditionalSessionOption) {
      return '50px';
    } else if (invoiceItem.invoiceItemType === InvoiceItemTypes.Discount) {
      return '50px';
    }
    return '10px';
  };

  const showTaxRows =
    invoiceBalance?.taxType && invoiceBalance.taxType !== TaxTypes.None && invoiceBalance.taxPercentage > 0;

  return invoiceBalance ? (
    <TableContainer sx={smallTableContainerSx}>
      <Table size="small" aria-label="a simple dense table">
        <TableHead sx={smallTableHeadSx}>
          <TableRow sx={smallTableRowSx} data-testid="table-row">
            <TableCell sx={smallTableHeadCellSx} align="left">
              Description
            </TableCell>
            <TableCell sx={{ ...smallTableHeadCellSx, width: '50px' }} align="right">
              Amount
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceBalance.invoiceItems && invoiceBalance.invoiceItems.length > 0 ? (
            invoiceBalance.invoiceItems.map((invoiceItem) => (
              <TableRow
                sx={smallTableRowSx}
                key={`InvoiceBalanceItemRowKey${invoiceItem._id}`}
                data-testid="invoice-item-row"
              >
                <TableCell sx={{ ...smallTableCellSx, paddingLeft: getPaddingLeft(invoiceItem) }}>
                  {invoiceItem.description}
                </TableCell>
                <TableCell sx={{ ...smallTableCellSx, width: '50px' }} align="right">
                  {moneyWithCurrencyCodeFormatter(
                    invoiceItem?.amount ? invoiceItem.amount : 0,
                    invoiceItem?.currencyCode ? invoiceItem.currencyCode : CurrencyCode.USD
                  )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={7}>
                No items...
              </TableCell>
            </TableRow>
          )}
          <TableRow data-testid="sub-total-row">
            <TableCell sx={smallTableTotalCellSx} align="right">
              Sub Total :
            </TableCell>
            <TableCell sx={smallTableTotalCellSx} align="right">
              {moneyWithCurrencyCodeFormatter(invoiceBalance?.amount, invoiceBalance?.currencyCode || CurrencyCode.USD)}
            </TableCell>
          </TableRow>
          {showTaxRows && (
            <TableRow data-testid="tax-row">
              <TableCell sx={smallTableTotalCellSx} align="right">
                {invoiceBalance.taxType ? getTaxDescription(invoiceBalance.taxType, invoiceBalance?.taxPercentage) : ''}{' '}
              </TableCell>
              <TableCell sx={smallTableTotalCellSx} align="right">
                {moneyWithCurrencyCodeFormatter(
                  invoiceBalance?.taxAmount || 0,
                  invoiceBalance?.currencyCode || CurrencyCode.USD
                )}
              </TableCell>
            </TableRow>
          )}
          {showTaxRows && (
            <TableRow data-testid="tax-total-row">
              <TableCell sx={smallTableTotalCellSx} align="right">
                Total :
              </TableCell>
              <TableCell sx={smallTableTotalCellSx} align="right">
                {moneyWithCurrencyCodeFormatter(
                  invoiceBalance?.amountWithTax,
                  invoiceBalance?.currencyCode || CurrencyCode.USD
                )}
              </TableCell>
            </TableRow>
          )}

          {invoiceBalance?.appliedCredit < 0 && (
            <TableRow data-testid="applied-credit-row">
              <TableCell sx={smallTableTotalCellSx} align="right">
                Applied Credit :
              </TableCell>
              <TableCell sx={smallTableTotalCellSx} align="right">
                {moneyWithCurrencyCodeFormatter(
                  invoiceBalance?.appliedCredit ? invoiceBalance.appliedCredit : 0,
                  invoiceBalance?.currencyCode || CurrencyCode.USD
                )}
              </TableCell>
            </TableRow>
          )}
          {invoiceBalance?.paidAmount < 0 && (
            <TableRow data-testid="paid-amount-row">
              <TableCell sx={smallTableTotalCellSx} align="right">
                {invoiceBalance.cardInfo
                  ? `Paid via ${invoiceBalance.cardInfo.cardType} (...${invoiceBalance.cardInfo.last4})`
                  : 'Paid :'}{' '}
                :
              </TableCell>
              <TableCell sx={smallTableTotalCellSx} align="right">
                {moneyWithCurrencyCodeFormatter(
                  invoiceBalance?.paidAmount ? invoiceBalance.paidAmount : 0,
                  invoiceBalance?.currencyCode || CurrencyCode.USD
                )}
              </TableCell>
            </TableRow>
          )}
          {invoiceBalance?.issuedCredit > 0 && (
            <TableRow data-testid="issued-credit-row">
              <TableCell sx={smallTableTotalCellSx} align="right">
                Issued Credit :
              </TableCell>
              <TableCell sx={smallTableTotalCellSx} align="right">
                {moneyWithCurrencyCodeFormatter(
                  invoiceBalance?.issuedCredit,
                  invoiceBalance?.currencyCode || CurrencyCode.USD
                )}
              </TableCell>
            </TableRow>
          )}
          <TableRow data-testid="due-row">
            <TableCell sx={smallTableTotalCellSx} align="right">
              Due :
            </TableCell>
            <TableCell sx={smallTableTotalCellSx} align="right">
              {moneyWithCurrencyCodeFormatter(
                invoiceBalance?.balance ? invoiceBalance.balance : 0,
                invoiceBalance?.currencyCode || CurrencyCode.USD
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <TableContainer sx={smallTableContainerSx}>
      <Table size="small" aria-label="a simple dense table">
        <TableBody>
          <TableRow key={`InvoiceBalanceItemRowKeyError`}>
            <TableCell align="left">
              <Typography color={'error'}>{message}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SmallInvoiceDataTable;
