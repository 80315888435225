import React, { FC, useState } from 'react';
import { TextFieldProps } from '@mui/material';
import { Messages } from '@efacity/common';
import { FormTextInput } from './FormTextInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

export type FormPasswordInputProps = {
  label: string;
  name?: string;
  showHelperText?: boolean;
} & TextFieldProps;

export const FormPasswordInput: FC<FormPasswordInputProps & TextFieldProps> = ({
  showHelperText = true,
  name = 'password',
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormTextInput
      name={name}
      {...props}
      type={showPassword ? 'text' : 'password'}
      data-testid="password"
      size="small"
      helperText={showHelperText && Messages.PasswordShouldHaveDigitsAndLetters}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
              data-testid="password-eye"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};
