import { customTheme } from '@efacity/frontend-shared';
import { AuthContext, AuthState, initialAuthState } from '@efacity/react-next-sc';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routing } from './components/Routes/Routing';
function App() {
  document.title = 'Efacity';
  const [authState, setAuthState] = useState<AuthState>(initialAuthState);

  return (
    <ThemeProvider theme={customTheme}>
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Router>
          <StyledEngineProvider injectFirst>
            <SnackbarProvider maxSnack={20}>
              <>
                <CssBaseline />
                <Routing />
              </>
            </SnackbarProvider>
          </StyledEngineProvider>
        </Router>
      </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;
