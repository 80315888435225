import { apiService, Discount, getMergedFormValues } from '@efacity/frontend-shared';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { initialDiscountFormValues } from '../pages/Discounts/initialDiscountFormValues';
import {
  convertUtcToZonedTime,
  CurrencyCode,
  DiscountType,
  FormMode,
  PaginatedResult,
  randomString
} from '@efacity/common';
import { PaginationOptions } from '../components/Table/Table';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

export interface DiscountsState {
  discounts: Discount[];
  timeZone: string;
  isLoading: boolean;
  total: number;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const useDiscountsLoader = (url: string) => {
  const [discountsState, setDiscountsState] = useState<DiscountsState>({
    discounts: [],
    timeZone: '',
    total: 0,
    isLoading: true
  });
  const snackbarProps = useSnackbar();

  const getDiscounts = useCallback(
    ({ pageIndex, pageSize }: PaginationOptions) => {
      const setDiscounts = ({ data, total, timeZone }: PaginatedResult<Discount[]>, isLoading: boolean) => {
        setDiscountsState({
          discounts: data,
          timeZone: timeZone,
          total,
          isLoading: isLoading
        });
      };

      const showErrorNotification = (message: string) => {
        setDiscounts({ data: [], total: 0 }, false);
        showNotification(snackbarProps, false, message, true);
      };

      apiService.get<PaginatedResult<Discount[]>>(url, { page: pageIndex, perPage: pageSize }).then(
        (result) => {
          return setDiscounts(result.data, false);
        },
        (error) => {
          setDiscountsState((discountsState) => ({
            ...discountsState,
            isLoading: false
          }));
          showErrorNotification(
            (error.response?.data?.message as string) || 'Failed to retrieve Discounts from ' + url
          );
        }
      );
    },
    [snackbarProps, url]
  );

  return [
    {
      discountsState,
      setDiscountsState
    },
    { getDiscounts }
  ];
};

export const useDiscountFormValues = (
  orgId: string,
  discountId: string,
  currency: CurrencyCode,
  formMode: FormMode
) => {
  const snackbarProps = useSnackbar();

  const [discountFormState, setDiscountFormState] = useState({
    discountFormValue: { ...initialDiscountFormValues, currencyCode: currency },
    isLoading: false
  });

  useEffect(() => {
    const getDiscountById = async (orgId: string) => {
      setDiscountFormState({
        discountFormValue: { ...initialDiscountFormValues, currencyCode: currency },
        isLoading: true
      });

      try {
        const { data } = await apiService.get<Discount>(`/org/${orgId}/discount/${discountId}`);
        const discount = { ...data };
        delete discount._id;
        delete discount.createdOn;
        if (discount.typeDefinition.discountType === DiscountType.Percentage) {
          discount.typeDefinition.discountAmount = 0;
        } else if (discount.typeDefinition.discountType === DiscountType.Amount) {
          discount.typeDefinition.discountPercentage = 0;
        }
        discount.expiresOn = convertUtcToZonedTime(new Date(discount.expiresOn), (discount as any).timeZone);

        setDiscountFormState((discountFormState) => ({
          ...discountFormState,
          discountFormValue: getMergedFormValues({ ...initialDiscountFormValues, currencyCode: currency }, discount),
          isLoading: false
        }));
      } catch (error) {
        showNotification(snackbarProps, false, error.message || 'Failed to get Discount info.', true);
        setDiscountFormState({
          discountFormValue: { ...initialDiscountFormValues, currencyCode: currency },
          isLoading: true
        });
      }
    };
    if (formMode === FormMode.Add) {
      setDiscountFormState((discountFormState) => ({
        ...discountFormState,
        discountFormValue: {
          ...initialDiscountFormValues,
          currencyCode: currency,
          code: `Coupon_${randomString(10)}`
        }
      }));
    } else {
      getDiscountById(orgId);
    }
  }, [orgId, snackbarProps, discountId, formMode, currency]);

  return {
    discountFormState
  };
};
