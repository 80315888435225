import React, { FC } from 'react';
import { MenuItem } from '@mui/material';
import { FormInputTextProps, FormTextInput } from './FormTextInput';
import { rolesSelectOptions } from '@efacity/common';

interface RoleSelectorProps {
  name: string;
  onSelect: (value: string) => void;
  dataTestId?: string;
}

export const RoleSelector: FC<RoleSelectorProps & FormInputTextProps> = ({
  name,
  onSelect,
  dataTestId = '',
  ...props
}) => {
  return (
    <FormTextInput
      name={name}
      label="Role"
      data-testid={dataTestId}
      select
      onChange={(event) => onSelect(event.target.value)}
      {...props}
    >
      {rolesSelectOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </FormTextInput>
  );
};
