import { hasJsonStructure, RichTextEditor, ViewItem, viewItemParagraphStyle } from '@efacity/frontend-shared';
import { ActivityTypes } from '@efacity/common';
import React from 'react';

const displayAsParagraph = (value: string) => {
  const parsedValue = hasJsonStructure(value) ? <RichTextEditor readOnly value={JSON.parse(value as string)} /> : value;

  return <p style={viewItemParagraphStyle}>{parsedValue}</p>;
};

export const ActivityDescriptions = ({ activity }) => {
  if (activity.type === ActivityTypes.Enrichment)
    return (
      <>
        <ViewItem title="Description of the session on registration page">
          {displayAsParagraph(activity.longDescription)}
        </ViewItem>
        <ViewItem title="Message for parents before choosing location">
          {displayAsParagraph(activity.descriptionOnSessionSelection)}
        </ViewItem>
      </>
    );

  return (
    <>
      <ViewItem title="Description on card">
        <p style={viewItemParagraphStyle}>{activity.shortDescription}</p>
      </ViewItem>
      <ViewItem title="Description on public page of the session:">
        {displayAsParagraph(activity.longDescription)}
      </ViewItem>
    </>
  );
};
