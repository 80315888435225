import type { CSSProperties } from 'react';
import type {
  OptionsObject,
  SnackbarAction,
  SnackbarKey,
  SnackbarMessage,
  SnackbarOrigin,
  VariantType,
  WithSnackbarProps
} from 'notistack';
import { Button } from '@mui/material';

type EnqueueSnackbar = (
  message: SnackbarMessage,
  options: OptionsObject & { contentprops: { style: CSSProperties } }
) => void;

export enum NOTIFICATION_TYPES {
  error = 'error',
  success = 'success'
}

const notistackAnchor: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'left'
};

const notistackMessageStyle = {
  fontSize: 18
};

const defaultAutoHideDuration = 5000; // ms

export const showNotification = (
  snackbarProps: WithSnackbarProps,
  isSuccess: boolean,
  notificationMessage: string,
  needConfirmation = false,
  confirmationText = 'Dismiss',
  confirmAction?: () => void
) => {
  const action: SnackbarAction = (key: SnackbarKey) => (
    <Button
      variant="outlined"
      color="inherit"
      onClick={() => {
        snackbarProps.closeSnackbar(key);
        if (confirmAction) {
          confirmAction();
        }
      }}
    >
      {confirmationText}
    </Button>
  );
  if (isSuccess) {
    needConfirmation
      ? showNotificationWithVariant(
          notificationMessage,
          NOTIFICATION_TYPES.success,
          snackbarProps.enqueueSnackbar,
          action
        )
      : showNotificationWithVariantWithoutConfirmation(
          notificationMessage,
          NOTIFICATION_TYPES.success,
          snackbarProps.enqueueSnackbar
        );
  } else {
    needConfirmation
      ? showNotificationWithVariant(
          notificationMessage,
          NOTIFICATION_TYPES.error,
          snackbarProps.enqueueSnackbar,
          action
        )
      : showNotificationWithVariantWithoutConfirmation(
          notificationMessage,
          NOTIFICATION_TYPES.error,
          snackbarProps.enqueueSnackbar
        );
  }
};

const showNotificationWithVariantWithoutConfirmation = (
  message: string,
  variant: VariantType,
  enqueueSnackbar: EnqueueSnackbar
) => {
  enqueueSnackbar(message, {
    variant: variant,
    anchorOrigin: notistackAnchor,
    preventDuplicate: false,
    autoHideDuration: defaultAutoHideDuration,
    contentprops: {
      style: notistackMessageStyle
    }
  });
};

const showNotificationWithVariant = (
  message: string,
  variant: VariantType,
  enqueueSnackbar: EnqueueSnackbar,
  action: SnackbarAction
) => {
  enqueueSnackbar(message, {
    variant: variant,
    action,
    anchorOrigin: notistackAnchor,
    preventDuplicate: false,
    autoHideDuration: null,
    contentprops: {
      style: notistackMessageStyle
    }
  });
};
