import { useEffect, useState } from 'react';
import { apiService } from '../../apiService';
import type { User } from '../../interfaces/UserProfile';

export interface StudentsListState {
  data: User[];
  isLoading: boolean;
}
export const emptyStudentsListState = {
  data: [],
  isLoading: false
};

export const useStudentsList = (userId: string) => {
  const [studentsListState, setStudentsListState] = useState<StudentsListState>(emptyStudentsListState);

  useEffect(() => {
    const getUserStudentsList = async () => {
      setStudentsListState((state) => {
        return { ...state, isLoading: true };
      });
      try {
        const { data } = await apiService().get<User[]>(`/customers/${userId}/students`);
        setStudentsListState(() => ({
          data: data,
          isLoading: false
        }));
      } catch (error) {
        setStudentsListState(emptyStudentsListState);
      }
    };
    if (userId) getUserStudentsList();
  }, [userId]);

  return studentsListState;
};
