import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { adminSectionTableFontSize, apiService, Product } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { NavLink, useParams } from 'react-router-dom';
import Table, { EnhancedColumn, PaginationTypes } from '../../components/Table/Table';
import { Messages, moneyWithCurrencyCodeFormatter, nowUTC } from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import { Box, IconButton, Link } from '@mui/material';
import { useSnackbar } from 'notistack';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import { isBefore } from 'date-fns';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import ProductDescriptionLinkWithCopier from '../../components/ProductDescriptionLinkWithCopier/ProductDescriptionLinkWithCopier';
import ProductsLinkWithCopier from '../../components/ProductsLinkWithCopier/ProductsLinkWiithCopier';
import { PageTitle } from '@efacity/react-next-sc';

const ProductsContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const snackbarProps = useSnackbar();

  const [kitsData, setKitsData] = useState({
    data: [],
    isLoading: false
  });

  const getKits = useCallback(async () => {
    try {
      const { data } = await apiService.get<Product[]>(`/org/${orgId}/products`);
      setKitsData((state) => ({ data: data, isLoading: false }));
    } catch (error) {
      setKitsData((state) => ({ ...state, isLoading: false }));
      showNotification(snackbarProps, false, error.response?.data?.message || Messages.FailedGetKits);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getKits();
  }, [getKits]);

  const handleDeleteKit = (kitId: string) => {
    return apiService
      .delete(`/org/${orgId}/product/${kitId}`)
      .then(() => {
        showNotification(snackbarProps, true, Messages.KitDeleted);
        getKits();
      })
      .catch((error) => {
        showNotification(snackbarProps, false, error.response.data.message || Messages.FailedToDeleteKit, true);
      });
  };

  const columns = useMemo(() => {
    const columns: EnhancedColumn<any>[] = [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row, value: name }) => {
          return (
            <Box display="flex" alignItems="center" data-testid="product-name-copier-cell">
              <Link
                component={NavLink}
                to={toPath(PATHS.editProduct, {
                  orgId: orgId,
                  productId: row.original._id
                })}
                underline={'none'}
              >
                {name}
              </Link>
              &nbsp;&nbsp;
              <ProductDescriptionLinkWithCopier orgId={orgId} productId={row.original._id} />
            </Box>
          );
        }
      },
      {
        Header: 'In Stock',
        accessor: 'inventoryQuantity',
        columnStyles: { width: 150, maxWidth: 150 },
        Cell: ({ value }) => <div>{value}</div>
      },
      {
        Header: 'Price',
        accessor: 'price',
        headerStyles: { textAlign: 'right' },
        columnStyles: { textAlign: 'right', width: 135, maxWidth: 135 },
        Cell: ({ row, value: price }) => <>{moneyWithCurrencyCodeFormatter(price, row.original.currencyCode)}</>
      },
      {
        Header: '',
        id: 'actions-cell',
        disableSortBy: true,
        columnStyles: { ...adminSectionTableFontSize, width: 75, maxWidth: 75 },
        Cell: ({ row }) => {
          return (
            <IconButton
              color="primary"
              aria-label="delete product"
              data-testid="delete-product-btn"
              component="span"
              style={smallPublishButtonStyle}
              disabled={isBefore(new Date(row.original.expiresOn), new Date(nowUTC()))}
              onClick={() => handleDeleteKit(row.original._id)}
            >
              <DeleteIcon />
            </IconButton>
          );
        }
      }
    ];

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  return (
    <div>
      <PageTitle
        title="Products"
        addButtonLink={toPath(PATHS.addProduct, { orgId: orgId })}
        button={
          <div style={{ marginTop: 5, marginRight: 15 }}>
            <ProductsLinkWithCopier />
          </div>
        }
      />
      <Table
        columns={columns}
        data={kitsData.data}
        loading={kitsData.isLoading}
        paginationType={PaginationTypes.ClientSide}
        noDataText="No products..."
      />
    </div>
  );
};

export default ProductsContainer;
