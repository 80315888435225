import {
  CountryCode,
  displayMdUpSx,
  displaySmDownSx,
  InvoicePresentation,
  PAYMENT_LIMIT_IN_CENTS,
  PaymentAcceptanceMethod,
  PaymentCard,
  TaxTypes,
  TaxTypesView
} from '@efacity/common';
import { Box, Grid } from '@mui/material';
import { CreatePaymentMethodData } from '@stripe/stripe-js';
import React, { ChangeEvent, FC } from 'react';
import { useStripeWrapper } from '@efacity/frontend-shared';
import PaymentForm, { FormActions } from '../ShoppingCartContainer/PaymentForm/PaymentForm';
import InvoiceMobileTable from './InvoiceTable/InvoiceMobileTable';
import InvoiceTable from './InvoiceTable/InvoiceTable';
import { PageTitle } from '@efacity/react-next-sc';

export interface PaymentDetails {
  paymentMethodId?: string;
  paymentIntent?: string;
  last4?: string | undefined;
  brand?: string | undefined;
  address?: CreatePaymentMethodData['billing_details']['address'];
  isExistingCard?: boolean;
}

interface PaymentStripeFormContainerProps {
  invoice: InvoicePresentation;
  credit: number;
  isLoadingInvoices: boolean;
  stripePublishableKey: string;
  paymentAcceptanceMethod: PaymentAcceptanceMethod;
  processBackendInvoicePayment: (paymentInfo: PaymentDetails, invoiceId: string) => void;
  handleDiscountCodeChanged: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleDeleteAdditionalOption: (sessionId: string, studentId: string, additionalOptionId: string) => void;
  handleDeleteDiscount: (invoiceItemId: string) => void | null;
  handleDeleteRegistration: (registrationId: string, sessionId: string, studentId: string) => void;
  isUpdating: boolean;
  initialCountry: CountryCode | undefined;
  hasAdminRole: boolean;
  onStartAdjustmentModal: (invoiceItemId) => void | null;
  paymentCards: PaymentCard[];
}

const StripePaymentFormContainer: FC<PaymentStripeFormContainerProps> = ({
  invoice,
  credit,
  initialCountry,
  isLoadingInvoices,
  stripePublishableKey,
  paymentAcceptanceMethod,
  processBackendInvoicePayment,
  handleDiscountCodeChanged,
  handleDeleteAdditionalOption,
  handleDeleteDiscount,
  handleDeleteRegistration,
  isUpdating,
  hasAdminRole,
  onStartAdjustmentModal,
  paymentCards
}) => {
  const due = invoice?.balance - credit > PAYMENT_LIMIT_IN_CENTS ? invoice?.balance - credit : 0;
  const showTaxRows = invoice?.taxType && invoice?.taxType !== TaxTypes.None && invoice?.taxPercentage > 0;
  const taxRowDescription = invoice?.taxType
    ? `${TaxTypesView[invoice?.taxType]} (${invoice?.taxPercentage || 0}%) :`
    : '';

  async function handleSubmit(
    fullPaymentInfo: CreatePaymentMethodData,
    userPaymentMethodId: string,
    usedPaymentCard: PaymentCard | null,
    formActions: FormActions
  ) {
    if (due <= PAYMENT_LIMIT_IN_CENTS || paymentAcceptanceMethod === PaymentAcceptanceMethod.Alternative) {
      const zeroPaymentInfo: PaymentDetails = {
        paymentMethodId: '',
        last4: '',
        brand: '',
        address: {},
        isExistingCard: true
      };
      await processBackendInvoicePayment(zeroPaymentInfo, invoice._id);
      formActions.setFormLoading(false);
    } else if (userPaymentMethodId && userPaymentMethodId.length > 0) {
      const paymentInfo: PaymentDetails = {
        paymentMethodId: userPaymentMethodId,
        last4: usedPaymentCard?.last4,
        brand: usedPaymentCard?.brand,
        address: fullPaymentInfo.billing_details.address,
        isExistingCard: true
      };
      await processBackendInvoicePayment(paymentInfo, invoice._id);
      formActions.setFormLoading(false);
    } else {
      const result = await stripe?.createPaymentMethod(fullPaymentInfo);
      if (result?.error) {
        formActions.setFormError(result?.error.message || 'Unable to process payment');
        formActions.setFormLoading(false);
      }
      if (result?.paymentMethod?.id) {
        const paymentInfo: PaymentDetails = {
          paymentMethodId: result.paymentMethod.id,
          last4: result.paymentMethod.card?.last4,
          brand: result.paymentMethod.card?.brand,
          address: fullPaymentInfo.billing_details.address,
          isExistingCard: false
        };
        await processBackendInvoicePayment(paymentInfo, invoice._id);
        formActions.setFormLoading(false);
      }
    }
  }

  const [{ stripe, wrappedForm }] = useStripeWrapper(
    stripePublishableKey,
    isLoadingInvoices,
    <PaymentForm
      handleSubmit={handleSubmit}
      isStripeLoading={false}
      paymentAllowed={!isUpdating}
      initialCountry={initialCountry}
      showPaymentElements={
        due > PAYMENT_LIMIT_IN_CENTS &&
        !(
          paymentAcceptanceMethod === PaymentAcceptanceMethod.Alternative ||
          paymentAcceptanceMethod === PaymentAcceptanceMethod.None
        )
      }
      paymentCards={paymentCards}
    />
  );

  return (
    <div>
      <PageTitle title="Invoice Payment" />
      <Box sx={displayMdUpSx}>
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          <InvoiceTable
            invoice={invoice}
            due={due}
            showTaxRows={showTaxRows}
            taxRowDescription={taxRowDescription}
            credit={credit}
            loading={isLoadingInvoices}
            handleDiscountCodeChanged={handleDiscountCodeChanged}
            handleDeleteAdditionalOption={handleDeleteAdditionalOption}
            handleDeleteDiscount={handleDeleteDiscount}
            handleDeleteRegistration={handleDeleteRegistration}
            isUpdating={isUpdating}
            hasAdminRole={hasAdminRole}
            onStartAdjustmentModal={onStartAdjustmentModal}
          />
        </div>
      </Box>
      <Box sx={displaySmDownSx}>
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          <InvoiceMobileTable
            invoice={invoice}
            due={due}
            showTaxRows={showTaxRows}
            taxRowDescription={taxRowDescription}
            credit={credit}
            loading={isLoadingInvoices}
            handleDiscountCodeChanged={handleDiscountCodeChanged}
            handleDeleteAdditionalOption={handleDeleteAdditionalOption}
            handleDeleteDiscount={handleDeleteDiscount}
            handleDeleteRegistration={handleDeleteRegistration}
            isUpdating={isUpdating}
            hasAdminRole={hasAdminRole}
            onStartAdjustmentModal={onStartAdjustmentModal}
          />
        </div>
      </Box>
      {invoice?.isPaid ? (
        <div style={{ fontSize: 20, color: '#f44336', textAlign: 'center', margin: 30 }}>Invoice already paid.</div>
      ) : (
        <Grid container>
          <Grid item sm={2} md={3} lg={3} />
          <Grid item xs={12} sm={8} md={6} lg={6}>
            {wrappedForm}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default StripePaymentFormContainer;
