import { Types } from 'mongoose';
import { Activity } from './Activity';
import { Session } from './Sessions';
import { User } from './User';

export enum CertificateTypes {
  Instructor = 'instructor',
  Student = 'student'
}

export const certificateTypesMapper = {
  [CertificateTypes.Instructor]: 'Instructor'
};

export interface CertificateInterface {
  _id: string | Types.ObjectId;
  type: CertificateTypes;

  userId: string | Types.ObjectId;
  user?: any | User;

  orgId: string | Types.ObjectId;

  certificationForActivityId: string | Types.ObjectId;
  certificationForActivity?: any | Activity;
  certificatedSessionId?: string | Types.ObjectId;
  certificatedSession?: any | Session;

  certificatedBy: string | Types.ObjectId;
  certificatedOn: Date | string;
  expiresOn: Date | string;
}
