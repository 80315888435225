import { InvoiceItem, InvoiceItemPresentation, InvoiceItemTypes } from './Payments';

export const getDiscountsAmountForRegistration = (registrationId: string, invoiceItems: InvoiceItem[]) => {
  if (invoiceItems?.length > 0) {
    const registrationDiscounts = invoiceItems.filter(
      (invoiceItem: InvoiceItem) =>
        invoiceItem?.registrationId?.toString() === registrationId &&
        invoiceItem.invoiceItemType === InvoiceItemTypes.Discount
    );
    return registrationDiscounts.reduce((accumulator, invoiceItem) => {
      return accumulator + invoiceItem.amount;
    }, 0);
  }
  return 0;
};

export const getAmountForRegistration = (registrationId: string, invoiceItems: InvoiceItem[]) => {
  if (invoiceItems?.length > 0) {
    const registrationItems = invoiceItems.filter(
      (invoiceItem: InvoiceItem) =>
        invoiceItem?.registrationId?.toString() === registrationId &&
        invoiceItem.invoiceItemType !== InvoiceItemTypes.Discount
    );
    return registrationItems.reduce((accumulator, invoiceItem) => {
      return accumulator + invoiceItem.amount;
    }, 0);
  }
  return 0;
};

export const getBalanceForRegistration = (registrationId: string, invoiceItems: InvoiceItem[]) => {
  const amount = getAmountForRegistration(registrationId, invoiceItems);
  const discount = getDiscountsAmountForRegistration(registrationId, invoiceItems);
  const calculatedRegistrationBalance = amount + discount;
  return calculatedRegistrationBalance < 0 ? 0 : calculatedRegistrationBalance;
};

export const getInvoiceFileName = (invoiceId: string) => {
  return `/pdf/invoice/invoice-${invoiceId}.pdf`;
};

export const getPaymentReceiptFileName = (invoiceId: string) => {
  return `/pdf/receipt/receipt-${invoiceId}.pdf`;
};

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const splitInvoiceItemsToRegistrationsAndProducts = (invoiceItems: InvoiceItemPresentation[] = []) => {
  const registrationInvoiceItems: InvoiceItemPresentation[] = [];
  const productInvoiceItems: InvoiceItemPresentation[] = [];

  for (const invoiceItem of invoiceItems) {
    if (invoiceItem.invoiceItemType === InvoiceItemTypes.Product) {
      productInvoiceItems.push(invoiceItem);
    } else {
      registrationInvoiceItems.push(invoiceItem);
    }
  }
  return { registrationInvoiceItems, productInvoiceItems };
};
