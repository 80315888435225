import { Box } from '@mui/material';
import React from 'react';

const centeredFormStyles = {
  formWrapper: {
    maxWidth: '500px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: {
      xs: '30px 20px',
      md: '15px 15px 20px'
    }
  },
  outerContainer: {
    width: '100%',
    padding: '30px 0',
    flexGrow: 1
  }
};

interface CenteredFormContainerProps {
  children: React.ReactNode;
}

export const CenteredFormContainer = ({ children }: CenteredFormContainerProps) => {
  return (
    <Box data-testid="form-container" sx={centeredFormStyles.outerContainer}>
      <Box sx={centeredFormStyles.formWrapper}>{children}</Box>
    </Box>
  );
};
