import React, { useMemo } from 'react';
import { EnhancedColumn } from '../../../components/Table/Table';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import { IconButton, Link } from '@mui/material';
import { smallPublishButtonStyle } from '../../../components/Buttons/SessionPublishButton';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import EmailAddress from '../../../components/EmailAddress/EmailAddress';
import { PhoneNumber } from '@efacity/react-next-sc';

const useOrganizationAdminsTableColumns = (orgId, onDeleteCLick, onEditAdminClick, authenticatedUserId) => {
  return useMemo(() => {
    const columns: EnhancedColumn<any>[] = [
      {
        Header: 'Full Name',
        accessor: 'fullName',
        Cell: ({ value, row, state }) => {
          return (
            <Link
              component="button"
              align={'left'}
              onClick={() =>
                onEditAdminClick(row.original._id, {
                  filters: state.filters,
                  pageIndex: state.pageIndex,
                  pageSize: state.pageSize,
                  sortBy: state.sortBy
                })
              }
              underline={'none'}
            >
              {value}
            </Link>
          );
        }
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ value }) => {
          return <EmailAddress email={value} />;
        }
      },
      {
        Header: 'Phone',
        accessor: 'defaultPhone',
        Cell: ({ value }) => {
          return <PhoneNumber phone={value} />;
        }
      },
      {
        Header: '',
        id: 'actions-cell',
        disableSortBy: true,
        columnStyles: { ...adminSectionTableFontSize, width: 75, maxWidth: 75 },
        Cell: ({ state, row }) => {
          if (row.original._id === authenticatedUserId) return <div />;
          return (
            <IconButton
              color="primary"
              aria-label="delete-instructor"
              data-testid="delete-instructor-btn"
              component="span"
              style={smallPublishButtonStyle}
              onClick={() => onDeleteCLick(row.original._id, row.original.fullName, state)}
            >
              <DeleteIcon />
            </IconButton>
          );
        }
      }
    ];

    return columns;
  }, [onDeleteCLick, onEditAdminClick, authenticatedUserId]);
};

export default useOrganizationAdminsTableColumns;
