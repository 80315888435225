'use client';
import { Roles, adminsTeachersAndIndependentRoles, hasAnyOfRoles, displaySmDownSx } from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import AddIcon from '@mui/icons-material/Add';
import DownArrowIcon from '@mui/icons-material/ArrowDropDown';
import CardGiftcard from '@mui/icons-material/CardGiftcard';
import ExitToApp from '@mui/icons-material/ExitToApp';
import List from '@mui/icons-material/List';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import { Box, Button, IconButton, Menu, MenuItem } from '@mui/material';
import type { FC, MouseEvent } from 'react';
import React, { useState } from 'react';
import type { AuthenticatedUserInfo } from '../../interfaces/Auth';
import { SelectAdminAccessModal } from './SelectAdminAccessModal';
import StudentsNavigationItems from './StudentsNavigationItems';

const rightUpperMenuStyles = {
  menuItem: {
    height: '36px'
  },
  menuItemWithIcon: {
    height: '36px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  } as React.CSSProperties
};

export interface RightUpperMenuProps {
  user?: AuthenticatedUserInfo;
  startRegistrationURL?: string | null;
  onLogoutClick: () => void;
  handleStartSelectAdminAccess?: () => void;
  workingAccessId: string;
  gotoToPath: (path: string) => void;
  goToAdminSection?: (path: string) => void;
}

export const RightUpperMenu: FC<RightUpperMenuProps> = ({
  user,
  startRegistrationURL,
  onLogoutClick,
  workingAccessId,
  gotoToPath,
  goToAdminSection
}) => {
  const [adminAccessSelectModalOpen, setAdminAccessSelectModalOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleMenuToggle = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStartSelectAdminAccess = () => {
    setAdminAccessSelectModalOpen(true);
  };
  const handleCloseSelectAdminAccessModal = () => {
    setAdminAccessSelectModalOpen(false);
  };
  const handleSelectedAdminAccess = async (selectedAccessId: string) => {
    setAdminAccessSelectModalOpen(false);

    const destinationURL = selectedAccessId
      ? toPath(PATHS.registrationsForAdmin, { orgId: selectedAccessId })
      : PATHS.landing;
    if (goToAdminSection) {
      goToAdminSection(destinationURL);
    } else {
      gotoToPath(destinationURL);
    }
  };

  const onNavigateToPage = (path: string) => async (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    gotoToPath(path);
  };

  const isUserHasCustomerRole = hasAnyOfRoles([Roles.Customer], user?.roles || []);
  const isUserHasStudentRole = hasAnyOfRoles([Roles.Student], user?.roles || []);
  const isUserAdminOrTeacher = hasAnyOfRoles(adminsTeachersAndIndependentRoles, user?.roles || []);
  const isMenuOpened = Boolean(anchorEl);

  return (
    <>
      <Button
        id="user-menu-button"
        aria-label="User menu"
        onClick={handleMenuToggle}
        data-testid="menuToggler"
        aria-controls={isMenuOpened ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpened ? 'true' : undefined}
      >
        <Box sx={displaySmDownSx}>
          <PersonOutlineOutlinedIcon color={'primary'} fontSize={'large'} />
        </Box>
        <DownArrowIcon />
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={isMenuOpened}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'user-menu-button'
        }}
        PaperProps={{
          style: {
            width: 270,
            marginTop: 0,
            marginLeft: -10
          }
        }}
      >
        <div data-testid="header-menu">
          {user?.adminAccesses && user?.adminAccesses?.length > 1 && (
            <div key={'rightUpperMenuAdminSection'}>
              <MenuItem
                onClick={handleStartSelectAdminAccess}
                data-testid="admin-organizations-menu-item"
                style={rightUpperMenuStyles.menuItem}
              >
                <div style={rightUpperMenuStyles.menuItemWithIcon}>
                  <div>Change Organization</div>
                  <List color="primary" />
                </div>
              </MenuItem>
              <hr />
            </div>
          )}
          {isUserAdminOrTeacher && (
            <MenuItem
              data-testid="user-certificates-menu-item"
              style={rightUpperMenuStyles.menuItem}
              onClick={onNavigateToPage(PATHS.userCertificates)}
              key={'rightUpperMenuUserCertificatesItem'}
            >
              <div style={rightUpperMenuStyles.menuItemWithIcon}>
                <div>Certificates</div>
                <IconButton
                  color="primary"
                  onClick={onNavigateToPage(PATHS.instructorCertification)}
                  disableRipple
                  disabled={false}
                  data-testid="user-add-certificate-menu-item"
                  style={{ padding: 0 }}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </MenuItem>
          )}
          {isUserHasCustomerRole && (
            <>
              <MenuItem
                data-testid="customer-registrations-menu-item"
                style={rightUpperMenuStyles.menuItem}
                onClick={onNavigateToPage(PATHS.registrations)}
                key={'rightUpperMenuRegistrationsItem'}
              >
                <div style={rightUpperMenuStyles.menuItemWithIcon}>
                  <div>Registrations</div>
                  <IconButton
                    color="primary"
                    onClick={onNavigateToPage(startRegistrationURL ? startRegistrationURL : PATHS.landing)}
                    disableRipple
                    disabled={false}
                    data-testid="customer-add-registration-menu-item"
                    style={{ padding: 0 }}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </MenuItem>
              <MenuItem
                data-testid="customer-registrations-menu-item"
                style={rightUpperMenuStyles.menuItem}
                onClick={onNavigateToPage(PATHS.customerPurchases)}
                key={'rightUpperMenuKitsItem'}
              >
                <div style={rightUpperMenuStyles.menuItemWithIcon}>
                  <div>Purchases</div>
                  <IconButton
                    color="primary"
                    onClick={onNavigateToPage(PATHS.productsLanding)}
                    disableRipple
                    disabled={false}
                    data-testid="customer-add-registration-menu-item"
                    style={{ padding: 0 }}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </MenuItem>
              <MenuItem
                data-testid="customer-students-menu-item"
                style={rightUpperMenuStyles.menuItem}
                onClick={onNavigateToPage(PATHS.students)}
                key={'rightUpperMenuStudentsItem'}
              >
                <div style={rightUpperMenuStyles.menuItemWithIcon}>
                  <div>Students</div>
                  <IconButton
                    color="primary"
                    onClick={onNavigateToPage(PATHS.addStudent)}
                    disableRipple
                    disabled={false}
                    data-testid="customer-add-student-menu-item"
                    style={{ padding: 0 }}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </MenuItem>
              {user && <StudentsNavigationItems userId={user?._id} onNavigateToPage={onNavigateToPage} />}
              {isUserHasCustomerRole && (
                <MenuItem
                  style={rightUpperMenuStyles.menuItem}
                  onClick={onNavigateToPage(PATHS.usersEnrollmentApplications)}
                >
                  Enrollment Applications
                </MenuItem>
              )}
              <MenuItem
                onClick={onNavigateToPage(PATHS.customerCredits)}
                data-testid="customer-settings-menu-item"
                style={rightUpperMenuStyles.menuItem}
                key={'rightUpperMenuUserCreditsItem'}
              >
                <div style={rightUpperMenuStyles.menuItemWithIcon}>
                  <div>Credits</div>
                  <CardGiftcard color="primary" />
                </div>
              </MenuItem>
              <hr />
            </>
          )}
          {isUserHasStudentRole && (
            <MenuItem
              data-testid="customer-registrations-menu-item"
              style={rightUpperMenuStyles.menuItem}
              onClick={onNavigateToPage(toPath(PATHS.studentSessions, { id: user?._id }))}
              key={'rightUpperMenuStudentRegistrationsItem'}
            >
              <div style={rightUpperMenuStyles.menuItemWithIcon}>
                <div>Sessions</div>
                <IconButton
                  color="primary"
                  onClick={onNavigateToPage(startRegistrationURL ? startRegistrationURL : PATHS.landing)}
                  disableRipple
                  disabled={false}
                  data-testid="customer-add-registration-menu-item"
                  style={{ padding: 0 }}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </MenuItem>
          )}
          <MenuItem
            onClick={onNavigateToPage(PATHS.userSettings)}
            data-testid="customer-settings-menu-item"
            style={rightUpperMenuStyles.menuItem}
            key={'rightUpperMenuUserSettingsItem'}
          >
            <div style={rightUpperMenuStyles.menuItemWithIcon}>
              <div>User Settings</div>
              <SettingsOutlined color="primary" />
            </div>
          </MenuItem>
          <hr />
          <MenuItem
            onClick={onLogoutClick}
            data-testid="logout-menu-item"
            style={rightUpperMenuStyles.menuItem}
            key={'rightUpperMenuUserLogoutItem'}
          >
            <div style={rightUpperMenuStyles.menuItemWithIcon}>
              <div>Logout</div>
              <ExitToApp color="primary" />
            </div>
          </MenuItem>
        </div>
      </Menu>
      {user?.adminAccesses && adminAccessSelectModalOpen && (
        <SelectAdminAccessModal
          isOpen={adminAccessSelectModalOpen}
          schoolCenterAccesses={user.adminAccesses}
          workingAccessId={workingAccessId}
          onSelect={handleSelectedAdminAccess}
          onClose={handleCloseSelectAdminAccessModal}
        />
      )}
    </>
  );
};
