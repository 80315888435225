import React, { FC } from 'react';
import { MenuItem } from '@mui/material';
import { connectModelOptions } from '@efacity/common';
import { FormInputTextProps, FormTextInput } from './FormTextInput';

export const ConnectModelSelector: FC<FormInputTextProps> = ({ name, required }) => {
  return (
    <FormTextInput name={name} label="Stripe Connect Model" select required={required}>
      {connectModelOptions.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </FormTextInput>
  );
};
