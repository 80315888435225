import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextFieldProps,
  Theme
} from '@mui/material';
import React from 'react';
import { getNestedValue, PreferredPronoun, preferredPronounViewConnector } from '@efacity/common';
import { Controller, useFormContext } from 'react-hook-form';
import { defaultRHFSetValueOptions } from './FormTextInput';

const textFieldStyle = {
  marginBottom: '8px',
  '& label': {
    color: '#666',
    fontSize: 16
  },
  '& p': {
    position: 'absolute',
    bottom: '-22px',
    marginLeft: '1px',
    whiteSpace: 'noWrap',
    margin: '3px'
  }
};

const groupWrapperStyle: React.CSSProperties = {
  width: '100%',
  position: 'relative',
  marginBottom: '5px',
  marginTop: '12px'
};

export type PronounSelectorProps = {
  name: string;
} & TextFieldProps;

// start point
// https://github.com/mui/material-ui/issues/8581
export const PronounSelector: React.FC<PronounSelectorProps> = ({
  name,
  label = ' Pronoun',
  size,
  color,
  helperText = '',
  inputProps
}) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext();

  const handleChange = (event: SelectChangeEvent) => {
    setValue(name, event.target.value, defaultRHFSetValueOptions);
  };

  const isError = !!getNestedValue(errors, name);
  const bottomText = (getNestedValue(errors, name)?.message as unknown as string) || helperText;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field }) => {
        return (
          <div style={groupWrapperStyle}>
            <FormControl sx={{ m: 0, width: '100%' }}>
              <InputLabel
                shrink
                id="demo-simple-select-helper-label"
                sx={{
                  color: (theme: Theme) => (isError ? theme.palette.error.main : 'text.secondary'),
                  backgroundColor: 'white'
                }}
              >
                {label}
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                displayEmpty
                value={watch(name)}
                input={
                  <OutlinedInput
                    notched
                    {...field}
                    label={<div style={{ width: 45 }}>&nbsp;</div>}
                    fullWidth={true}
                    size={size || 'small'}
                    color={color || 'primary'}
                    sx={textFieldStyle}
                    margin="dense"
                    error={isError}
                  />
                }
                onChange={handleChange}
                inputProps={{
                  ...inputProps
                }}
              >
                {Object.values(PreferredPronoun).map((preferredPronoun, index) => (
                  <MenuItem key={`pronounKey_${preferredPronoun}_${index}`} value={preferredPronoun}>
                    {preferredPronounViewConnector[preferredPronoun]}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                id="standard-weight-helper-text"
                sx={{
                  position: 'absolute',
                  bottom: '-16px',
                  marginLeft: '1px',
                  whiteSpace: 'noWrap',
                  margin: '3px',
                  color: (theme: Theme) => (isError ? theme.palette.error.main : 'inherit')
                }}
              >
                {bottomText ? bottomText : ''}
              </FormHelperText>
            </FormControl>
          </div>
        );
      }}
    />
  );
};
