import { useFetch, UserAgreement } from '@efacity/frontend-shared';
import type { UserSignedAgreement } from '@efacity/common';
import { useMemo, useState } from 'react';

const useUnsignedAgreements = (agreementsFetchUrl: string) => {
  const [{ data: userAgreementsToSign, isLoading }] = useFetch<UserAgreement[]>(agreementsFetchUrl, {
    initialDataState: [],
    initialLoadingState: true
  });
  const [signedAgreements, setSignedAgreements] = useState<UserSignedAgreement[]>([]);

  const allAgreementsViewed = useMemo(() => {
    return signedAgreements.length === userAgreementsToSign.length;
  }, [userAgreementsToSign, signedAgreements]);

  const filterOutAgreementForUser = (userId: string, agreementId: string) => {
    return signedAgreements.filter(
      (signedAgreement) => !(signedAgreement.agreementId === agreementId && signedAgreement.userId === userId)
    );
  };

  const onSignAgreement = async (userId: string, agreementId: string) => {
    const oldSignedAgreements = filterOutAgreementForUser(userId, agreementId);
    setSignedAgreements([...oldSignedAgreements, { userId, agreementId, isSigned: true }]);
  };

  const onDismissAgreement = async (userId: string, agreementId: string) => {
    const oldSignedAgreements = filterOutAgreementForUser(userId, agreementId);
    setSignedAgreements([...oldSignedAgreements, { userId, agreementId, isSigned: false }]);
  };

  const onCloseAgreementsModal = () => {
    // setIsModalOpen(false);
  };

  return [
    {
      userAgreementsToSign,
      isLoadingAgreements: isLoading,
      signedAgreements,
      allAgreementsViewed
    },
    { onCloseAgreementsModal, onSignAgreement, onDismissAgreement }
  ] as const;
};

export default useUnsignedAgreements;
