import React from 'react';
import { LinearProgress, Typography } from '@mui/material';

interface WithLoadingProps {
  isLoading: boolean;
  message: string;
  children?: any;
  style?: React.CSSProperties;
}

export const WithLoading: React.FC<WithLoadingProps> = ({ isLoading, message, children = null, style }) => {
  return isLoading ? (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ margin: 30, ...style }}>
        <Typography color="primary" style={{ fontSize: 18, textAlign: 'center', marginTop: 15, marginBottom: 15 }}>
          {message}
        </Typography>
        <LinearProgress variant="indeterminate" color="primary" data-testid="loadingIndicator" />
      </div>
    </div>
  ) : (
    children
  );
};
