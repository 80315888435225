import { useCallback, useEffect, useState } from 'react';
import { apiService, getMergedFormValues } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { useSnackbar } from 'notistack';
import { isEmptyObject, Messages, FormMode, BaseUser } from '@efacity/common';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { ErrorOption, FieldPath } from 'react-hook-form';
import { initialStudentFormValues, StudentFormValues } from './StudentFormValues';

export const setNeedReturnToRegistration = (requireStudentAge: boolean) => {
  localStorage.setItem('needReturnToRegistration', 'true');
  localStorage.setItem('requireStudentAge', requireStudentAge.toString());
};
export const getNeedReturnToRegistration = () => {
  const needReturnToRegistration = localStorage.getItem('needReturnToRegistration');
  localStorage.removeItem('needReturnToRegistration');
  localStorage.removeItem('requireStudentAge');
  return needReturnToRegistration === 'true';
};
export const getRequireStudentAge = () => {
  const requireStudentAge = localStorage.getItem('requireStudentAge');
  return requireStudentAge === 'true';
};
export const removeRequireStudentAge = () => {
  localStorage.removeItem('requireStudentAge');
};
export interface StudentsState {
  students: BaseUser[];
  isLoading: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const useCustomerStudentsLoader = (customerId: string) => {
  const [studentsState, setStudentsState] = useState<StudentsState>({
    students: [],
    isLoading: true
  });
  const snackbarProps = useSnackbar();

  const getStudents = useCallback(async () => {
    const showErrorNotification = (message: string) => {
      setStudentsState((state) => ({ ...state, students: [], isLoading: false }));
      showNotification(snackbarProps, false, message, true);
    };

    setStudentsState((state) => ({
      ...state,
      isLoading: true
    }));
    try {
      const { data } = await apiService.get<BaseUser[]>(`/customers/${customerId}/students`);
      setStudentsState((state) => ({ ...state, students: data, isLoading: false }));
    } catch (error) {
      showErrorNotification((error.response?.data?.message as string) || Messages.FailedToGetStudents);
    }
  }, [snackbarProps, customerId]);

  return [{ studentsState, setStudentsState }, { getStudents }];
};

export interface StudentState {
  student: StudentFormValues;
  isLoading: boolean;
}

export const useStudentFormValues = (customerId: string, studentId: string, formMode: FormMode) => {
  const [studentState, setStudentState] = useState<StudentState>({
    student: initialStudentFormValues,
    isLoading: true
  });
  const snackbarProps = useSnackbar();

  const processFailedResponse = (
    errorResponse,
    setError: (name: FieldPath<StudentFormValues>, error: ErrorOption, options?: { shouldFocus: boolean }) => void
  ) => {
    if (
      typeof errorResponse?.validationErrors?.location === 'object' &&
      !isEmptyObject(errorResponse.validationErrors.location)
    ) {
      addServerErrors<StudentFormValues>(errorResponse.validationErrors.location, setError);
    } else {
      showNotification(snackbarProps, false, errorResponse?.message || Messages.FailedToUpdateStudent, true);
    }
    return false;
  };

  const updateStudent = async (
    studentId: string,
    formValues: StudentFormValues,
    setError: (name: FieldPath<StudentFormValues>, error: ErrorOption, options?: { shouldFocus: boolean }) => void
  ): Promise<boolean> => {
    try {
      await apiService.patch<BaseUser>(`/customers/${customerId}/student/${studentId}`, formValues);
      return true;
    } catch (error) {
      return processFailedResponse(error.response.data, setError);
    }
  };

  const addStudent = async (
    formValues: StudentFormValues,
    setError: (name: FieldPath<StudentFormValues>, error: ErrorOption, options?: { shouldFocus: boolean }) => void
  ): Promise<boolean> => {
    try {
      await apiService.post<BaseUser>(`/customers/${customerId}/student`, formValues);
      return true;
    } catch (error) {
      return processFailedResponse(error.response.data, setError);
    }
  };

  useEffect(() => {
    const getStudentById = async () => {
      setStudentState((state) => ({ ...state, isLoading: true }));

      try {
        const { data } = await apiService.get<BaseUser>(`/customers/${customerId}/students/${studentId}`);
        const student = { ...data };
        setStudentState((state) => ({
          student: getMergedFormValues(initialStudentFormValues, student),
          isLoading: false
        }));
      } catch (error) {
        showNotification(snackbarProps, false, error.message || 'Failed to get location info.', true);
        setStudentState((state) => ({ ...state, student: initialStudentFormValues, isLoading: false }));
      }
    };

    if (formMode === FormMode.Add) {
      setStudentState((state) => ({ ...state, student: initialStudentFormValues, isLoading: false }));
    } else {
      getStudentById();
    }
  }, [customerId, studentId, snackbarProps, formMode]);

  return [
    { studentState, setStudentState },
    { addStudent, updateStudent }
  ];
};
