import React, { useEffect, useState } from 'react';
import OrganizationAddressesTable from './OrganizationAddressesTable';
import { FormMode } from '@efacity/common';
import { Box } from '@mui/material';
import { NewButton, AddressModal,useAddressesLoader } from '@efacity/frontend-next-shared/org-settings';

const addAddressButtonContainerStyle = {
  marginBottom: 15,
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end'
};

export const defaultAddressModalState = {
  isOpen: false,
  addressId: null,
  mode: FormMode.Add
};

export interface SchoolAddressesContainerProps {
  orgId: string;
}

export const OrganizationAddressesContainer: React.FC<SchoolAddressesContainerProps> = ({ orgId }) => {
  const [addressModalState, setAddressModalState] = useState(defaultAddressModalState);
  const [{ addressesState }, { getAddresses, deleteAddress, updateAddress, addAddress }] = useAddressesLoader(
    `/org/${orgId}/addresses`
  );

  useEffect(() => {
    getAddresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleEditAddress(addressId: string) {
    setAddressModalState({ isOpen: true, addressId, mode: FormMode.Edit });
  }

  function handleAddAddress() {
    setAddressModalState({ isOpen: true, addressId: null, mode: FormMode.Add });
  }

  function handleCloseModal() {
    setAddressModalState(defaultAddressModalState);
  }

  return (
    <div style={{ marginTop: '15px' }}>
      <Box style={addAddressButtonContainerStyle}>
        <NewButton data-testid="addBtn" onClick={() => handleAddAddress()}>
          Add address
        </NewButton>
      </Box>
      {addressModalState.isOpen && (
        <AddressModal
          open={addressModalState.isOpen}
          addressId={addressModalState.addressId}
          mode={addressModalState.mode}
          orgId={orgId}
          orgCountry={addressesState.defaultCountry}
          handleClose={handleCloseModal}
          onAddAddress={addAddress}
          updateAddress={updateAddress}
        />
      )}
      <OrganizationAddressesTable
        addresses={addressesState.addresses}
        isLoading={addressesState.isLoading}
        handleDeleteAddress={deleteAddress}
        orgId={orgId}
        handleEditAddress={handleEditAddress}
      />
    </div>
  );
};

export default OrganizationAddressesContainer;
