import CancelOutlined from '@mui/icons-material/CancelOutlined';
import type { ButtonProps } from '@mui/material';
import { Button } from '@mui/material';
import React from 'react';

const addButtonStyle = {
  width: '171px',
  height: '36px',
  textTransform: 'uppercase'
} as React.CSSProperties;

interface CancelLinkMediumButtonProps {
  onCancel: () => void;
}

export const CancelMediumButton: React.FC<ButtonProps & CancelLinkMediumButtonProps> = ({ onCancel, disabled }) => {
  return (
    <Button
      style={addButtonStyle}
      onClick={onCancel}
      color="primary"
      variant="outlined"
      disabled={disabled}
      data-testid="cancelBtn"
    >
      <CancelOutlined style={{ color: 'primary', marginRight: 20 }} />
      Cancel
    </Button>
  );
};
