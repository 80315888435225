import { Box, BoxProps } from '@mui/material';
import React, { FC } from 'react';
import { drawerWidth } from '../LeftNavigation/LeftNavigation';

const formPageSx = {
  width: {
    xl: `calc(100% - ${drawerWidth}px)`,
    lg: `calc(100% - 150px)`,
    md: '100%'
  }
};

const FormPageContainer: FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box {...rest} sx={formPageSx}>
      {children}
    </Box>
  );
};

export default FormPageContainer;
