import { SimplePaletteColorOptions } from '@mui/material';

export const primaryColor: SimplePaletteColorOptions = {
  light: '#4285F4',
  main: '#2F75C1',
  dark: '#2A517E'
};

export const secondaryColor: SimplePaletteColorOptions = {
  light: '#bce19d',
  main: '#6DB136',
  dark: '#55892A',
  contrastText: '#fff'
};

export const backgroundColor: SimplePaletteColorOptions = {
  light: '#FFFFFF',
  main: '#FAFAFA',
  dark: '#F5F6FA',
  contrastText: '#f5f5f5'
};
