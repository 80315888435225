export enum ValidationErrors {
  ActivityNameError = 'Activity name is required',
  SelectCertificatedActivity = 'Select Activity to be certificated',
  InvalidGrade = 'Invalid grade',
  InvalidAge = 'Age must be positive',
  ToAgeMustGreaterFrom = 'To Age mast be greater then From Age',
  GradeToIsLessThatGradeFrom = '"Grade from" value should be less than "Grade to" value',
  PriceShouldBeNumber = 'Price should be numeric',
  PriceIsRequired = 'Price is required',
  InvalidPriceRange = 'Price should be 0 or more',
  OnlyZeroPriceAllowed = 'Only 0 price allowed',
  ConnectRequired = 'Non zero price allowed only when connected to receive payments!',
  DescriptionIsRequired = 'Description is required',
  FeeIsRequired = 'Fee is required',
  FeeShouldBeInRange = 'Fee should be between 0 and 100%',
  InvalidActivityType = 'Invalid Activity Type',
  InvalidDurationType = 'Invalid Duration Type',
  StudentLevelRequired = 'Student Level required',
  AgreementTypeRequired = 'Agreement type is required',
  InvalidStudentLevelType = 'Invalid Student Level Type',
  InstancesNumberInvalid = 'Activity should have at least 1 instance',
  UniqueCategoryError = 'Categories should be unique',
  DefiniteCategoriesAreNotUnique = 'The following categories are not unique:',
  LevelRequired = 'Activity level is required',
  LevelShouldBeNumeric = 'Activity level should be numeric',
  InvalidActivityLevelRange = 'Activity level should be in range between 1 and 5',
  InvalidEmail = 'Invalid email',
  InvalidEmailsList = 'Invalid emails list',
  NameRequired = 'Name is required',
  PhoneRequired = 'Phone is required',
  AddressRequired = 'Address is required',
  DefaultAddressRequired = 'Default address is required',
  StateRequired = 'State is required',
  CityRequired = 'City is required',
  ZipRequired = 'Zip is required',
  SmtpServerRequired = 'Server is required',
  PortRequired = 'Port is required',
  PortMustPositive = 'Port must be positive',
  SSLRequired = 'SSL is required',
  UserRequired = 'User is required',
  PasswordRequired = 'Password is required'
}
