import { ActivityTypes } from './Activities';
import { getDateInLocalTimeZone } from './DateTime';

export const getSessionDuration = (instancesNumber: number, type: ActivityTypes) => {
  if (instancesNumber === undefined) {
    return '-';
  }
  let duration = instancesNumber.toString();
  if (type === ActivityTypes.Camp) {
    duration = duration.concat(' day');
  } else {
    duration = duration.concat(' lesson');
  }

  if (instancesNumber !== 1) {
    duration = duration.concat('s');
  }

  return duration;
};

export const displaySessionDuration = (numberOfInstances, type) => {
  if (numberOfInstances > 1) return getSessionDuration(numberOfInstances, type);
  return '';
};

export const displaySessionStartDate = (numberOfInstances, startDateTime) => {
  if (numberOfInstances > 1) return 'Starts ' + getDateInLocalTimeZone(startDateTime);
  return getDateInLocalTimeZone(startDateTime);
};
