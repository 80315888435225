import React from 'react';
import { useDiscountsLoader } from '../../hooks/useDiscountsLoader';
import { useSnackbar } from 'notistack';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import Table, { PaginationTypes, TableState } from '../../components/Table/Table';
import { IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { nowUTC } from '@efacity/common';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { apiService, adminSectionTableFontSize } from '@efacity/frontend-shared';
import { isBefore } from 'date-fns';
import useDiscountsTableColumns from './useDiscountsTableColumns';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import { InstanceTemporality } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

const HistoricDiscountsTable: React.FC = () => {
  const snackbarProps = useSnackbar();
  const { orgId } = useParams<OrgIdParamType>();
  const [{ discountsState }, { getDiscounts }] = useDiscountsLoader(
    `/org/${orgId}/discounts/${InstanceTemporality.Current}`
  );
  const columns = useDiscountsTableColumns(orgId, discountsState.timeZone, [
    {
      Header: '',
      id: 'actions-cell',
      disableSortBy: true,
      columnStyles: { ...adminSectionTableFontSize, width: 75, maxWidth: 75 },
      Cell: ({ state, row }) => {
        return (
          <IconButton
            color="primary"
            aria-label="delete discount"
            data-testid="delete-discount-btn"
            component="span"
            style={smallPublishButtonStyle}
            disabled={isBefore(new Date(row.original.expiresOn), new Date(nowUTC()))}
            onClick={() => handleDeleteDiscount(row.original._id, state)}
          >
            <DeleteIcon />
          </IconButton>
        );
      }
    }
  ]);

  function handleDeleteDiscount(discountId: string, { pageIndex, pageSize }: TableState) {
    apiService.delete(`/org/${orgId}/discounts/${discountId}`).then(
      (result: any) => {
        showNotification(snackbarProps, true, result.data.message as string, false);
        getDiscounts({ pageIndex, pageSize });
      },
      (error) => {
        showNotification(snackbarProps, false, error.response.data.message as string, true);
      }
    );
  }

  return (
    <Table
      columns={columns}
      data={discountsState.discounts}
      loading={discountsState.isLoading}
      pageCount={discountsState.total}
      fetchData={getDiscounts}
      paginationType={PaginationTypes.ServerSide}
      noDataText="No current discounts..."
    />
  );
};

export default HistoricDiscountsTable;
