import { useAuth } from '@efacity/react-next-sc';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import OrganizationSettingsForm from './OrganizationSettingsForm';
import {
  OrganizationSettingsWithAdministratorsFormValues,
  initialOrganizationSettingsFormValues, useOrganizationSettingsLoader
} from '@efacity/frontend-next-shared/org-settings';
import { getMergedFormValues, WithLoading, ConfirmationModal } from '@efacity/frontend-shared';

export const OrganizationSettingsFormContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const {
    authState: { user }
  } = useAuth();

  const [{ organizationSettingsState }, { updateOrgSettings, deleteOrgAdmin }] = useOrganizationSettingsLoader(
    orgId,
    `/org/${orgId}/settings`
  );

  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    onConfirm: null
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);
  const onDeleteClick = (adminId: string) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        '<div style="display:flex;justify-content:center">You are about to delete an Admin.</div><br/>' +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      onConfirm: () => onDeleteOrgAdmin(adminId)
    });
  };

  const onFormSubmit = async (
    formValues: OrganizationSettingsWithAdministratorsFormValues,
    setError: (name, error, options?) => void,
    logoImage: Blob,
    mobileLogoImage: Blob
  ) => {
    await updateOrgSettings(formValues, setError, logoImage, mobileLogoImage);
  };

  const onDeleteOrgAdmin = async (adminId: string) => {
    setConfirmationModalState((state) => {
      return { ...state, isOperating: true };
    });
    await deleteOrgAdmin(adminId, `/org/${orgId}/admins/${adminId}`);
    setConfirmationModalState(defaultConfirmationModalState);
  };

  const initialFormValues = useMemo(() => {
    if (organizationSettingsState.organizationSettings) {
      return {
        organizationSettings: getMergedFormValues(
          initialOrganizationSettingsFormValues,
          organizationSettingsState.organizationSettings
        ),
        administrators: []
      };
    }

    return {
      organizationSettings: initialOrganizationSettingsFormValues,
      administrators: []
    };
  }, [organizationSettingsState.organizationSettings]);

  return (
    <div>
      <WithLoading
        isLoading={organizationSettingsState.isLoading}
        message={'Loading organization data...'}
        style={{ width: '25%' }}
      >
        <OrganizationSettingsForm
          orgId={orgId}
          initialFormValues={initialFormValues}
          administrators={organizationSettingsState.administrators}
          authenticatedUser={user}
          loading={organizationSettingsState.isLoading}
          handleSubmit={onFormSubmit}
          deletingOrgAdmin={organizationSettingsState.isLoading}
          onDeleteOrgAdmin={onDeleteClick}
          orgType={organizationSettingsState.organizationSettings?.orgType}
        />
      </WithLoading>
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState(defaultConfirmationModalState)}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
    </div>
  );
};
