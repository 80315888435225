import { ConnectModel, ConnectStatus, OrganizationPaymentStatus, PaymentAcceptanceMethod } from './Stripe';

const STRIPE_PERCENTAGE_FEE = 2.9; //
const STRIPE_ADDITIONAL_FEE = 30; // cents

// https://stripe.com/pricing
export const amountAfterStripeFee = (fullAmount: number) => {
  const stripeDue = (STRIPE_PERCENTAGE_FEE / 100) * fullAmount + STRIPE_ADDITIONAL_FEE;
  const amountWithoutStripeFee = fullAmount - stripeDue;
  return amountWithoutStripeFee < 0 ? 0 : amountWithoutStripeFee;
};

export const getMessageAndActionByConnectStatus = (organizationPaymentStatus: OrganizationPaymentStatus | null) => {
  if (!organizationPaymentStatus) {
    return {
      message: 'No Payment Status Info.',
      allowConnect: false,
      buttonMessage: ''
    };
  }

  if (organizationPaymentStatus.paymentAcceptanceMethod === PaymentAcceptanceMethod.Self) {
    if (organizationPaymentStatus.isPaymentAllowed === false) {
      return {
        message:
          'Your account is not yet connected to your bank to receive payment and schedule paid sessions. Contact Efacity.',
        showButton: true,
        allowConnect: false,
        buttonMessage: ''
      };
    }
    return {
      message: '',
      showButton: false,
      buttonMessage: ''
    };
  }

  if (organizationPaymentStatus.paymentAcceptanceMethod === PaymentAcceptanceMethod.Efacity) {
    if (organizationPaymentStatus.connectModel === ConnectModel.ConnectStandard) {
      switch (organizationPaymentStatus.connectStatus) {
        case ConnectStatus.ConnectedStandard:
          return {
            message: 'You Organization already connected to get Payments via Stripe Connect.',
            showButton: false,
            buttonMessage: '',
            bottomMessage: ''
          };

        case ConnectStatus.Connecting:
          return {
            message: 'You Organization now in connecting via Stripe Connect.',
            showButton: false,
            buttonMessage: '',
            bottomMessage: ''
          };

        case ConnectStatus.NotConnected:
          return {
            message: 'Your account is not yet connected to your bank to receive payment and schedule paid sessions.',
            showButton: true,
            buttonMessage: '',
            bottomMessage: ''
          };
      }
    } else if (organizationPaymentStatus.connectModel === ConnectModel.ConnectExpress) {
      switch (organizationPaymentStatus.connectStatus) {
        case ConnectStatus.Connecting:
          return {
            message: 'You Organization now in connecting via Stripe Connect.',
            showButton: false,
            buttonMessage: '',
            bottomMessage: ''
          };

        case ConnectStatus.NotConnected:
          return {
            message: 'Your account is not yet connected to your bank to receive payment and schedule paid sessions.',
            showButton: true,
            buttonMessage: 'Start Connect',
            bottomMessage: 'You will be redirected to Stripe page to fill out Bank Account information.'
          };

        case ConnectStatus.ConnectingExpress:
          return {
            message: 'Your Organization started Connect to get Payments but not finished it.',
            showButton: true,
            buttonMessage: 'Continue Connect',
            bottomMessage: 'You will be redirected to Stripe page to fill out Bank Account information.'
          };

        case ConnectStatus.ConnectingExpressError:
          return {
            message: 'Your Organization started Connect to get Payments but not finished it.',
            showButton: true,
            buttonMessage: 'Continue Connect',
            bottomMessage: 'You will be redirected to Stripe page to fill out Bank Account information.'
          };

        case ConnectStatus.ConnectingExpressPending:
          return {
            message: 'Stripe is pending to confirm your Bank information. You can check status some time later.',
            showButton: true,
            buttonMessage: 'Check status on Stripe',
            bottomMessage: ''
          };

        case ConnectStatus.ConnectedExpress:
          return {
            message: 'You Organization already connected to get Payments via Stripe Connect.',
            showButton: false,
            buttonMessage: '',
            bottomMessage: ''
          };
      }
    }
  }
  return {
    message: 'No Payment Status Info. Contact Efacity.',
    allowConnect: false,
    buttonMessage: ''
  };
};
