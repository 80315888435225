import { RichTextEditor } from '@efacity/frontend-shared';
import { LightTooltip } from '@efacity/react-next-sc';
import { Box, Checkbox, FormControlLabel, IconButton, Typography } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { ActivityTypes, FormMode, locationNameMarkup } from '@efacity/common';
import { ActivityTypeChecker, FormTextInput } from '@efacity/react-hook-form-mui';
import { yupResolver } from '@hookform/resolvers/yup';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import copy from 'copy-to-clipboard';
import { cloneDeep } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { Descendant } from 'slate';
import { FormCancelSubmitButtons } from '@efacity/frontend-next-shared/forms';
import {
  AgreementBackendValues,
  AgreementFormValues,
  agreementFormValuesValidationSchema,
  initialAgreementFormValues
} from './agreementFormValues';

const notificationSx = (theme) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  marginTop: '10px',
  marginBottom: '10px',
  padding: 0,
  fontWeight: '600'
});
interface AgreementFormProps {
  onSubmit: (
    values: AgreementBackendValues,
    forceTheCreation: boolean,
    setError: (fieldName: keyof AgreementFormValues, error: { type: string; message: string }) => void
  ) => void;
  onCancel: () => void;
  initialValues?: AgreementBackendValues;
  formMode: FormMode;
}

const AgreementForm: FC<AgreementFormProps> = ({ initialValues, formMode, onSubmit, onCancel }) => {
  const mergedValues = useMemo<AgreementFormValues>(() => {
    if (!initialValues) {
      return initialAgreementFormValues;
    }
    return {
      _id: initialValues._id,
      agreementName: initialValues.agreementName,
      activityTypes: initialValues.activityTypes,
      customerMustSignAgreement: initialValues.customerMustSignAgreement,
      agreementRichText: JSON.parse(initialValues.agreementRichText)
    };
  }, [initialValues]);

  const methods = useForm<AgreementFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(agreementFormValuesValidationSchema),
    defaultValues: mergedValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUseNativeValidation: false
  });
  const { isValid, isDirty, isSubmitting } = methods.formState;

  const validateTouchDirtyOptions = {
    shouldDirty: true,
    shouldTouch: true,
    shouldValidate: true
  };

  useEffect(() => {
    methods.reset(mergedValues);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [methods, JSON.stringify(mergedValues)]);

  const onRichTextEditorChange = (value: Descendant[]) => {
    methods.setValue('agreementRichText', value, validateTouchDirtyOptions);
  };

  const isActivityTypeSelected = (activityType: ActivityTypes): boolean => {
    return !!methods.getValues('activityTypes').includes(activityType);
  };

  const isActivityTypeSelectorDisabled = (activityType: ActivityTypes): boolean => {
    const isEnrichmentSelected = isActivityTypeSelected(ActivityTypes.Enrichment);
    if (isEnrichmentSelected) {
      return (
        activityType === ActivityTypes.Camp ||
        activityType === ActivityTypes.Course ||
        activityType === ActivityTypes.Event ||
        activityType === ActivityTypes.InstructorCertification
      );
    } else {
      const isCampSelected = isActivityTypeSelected(ActivityTypes.Camp);
      const isCourseSelected = isActivityTypeSelected(ActivityTypes.Course);
      const isEventSelected = isActivityTypeSelected(ActivityTypes.Event);
      const isInstructorCertificationSelected = isActivityTypeSelected(ActivityTypes.InstructorCertification);
      if (isCampSelected || isCourseSelected || isEventSelected || isInstructorCertificationSelected) {
        return activityType === ActivityTypes.Enrichment;
      }
      return false;
    }
  };

  const onActivityTypeClick = (activityType: ActivityTypes) => {
    let activityTypes = cloneDeep(methods.watch('activityTypes'));

    let updatedActivityTypes = [];
    if (activityType === ActivityTypes.Enrichment && !isActivityTypeSelected(ActivityTypes.Enrichment)) {
      updatedActivityTypes = [ActivityTypes.Enrichment];
      methods.setValue('customerMustSignAgreement', true, validateTouchDirtyOptions);
    } else {
      const isCampSelected = isActivityTypeSelected(ActivityTypes.Camp);
      const isCourseSelected = isActivityTypeSelected(ActivityTypes.Course);
      const isEventSelected = isActivityTypeSelected(ActivityTypes.Event);
      const isInstructorCertificationSelected = isActivityTypeSelected(ActivityTypes.InstructorCertification);
      if (isCampSelected || isCourseSelected || isEventSelected || isInstructorCertificationSelected) {
        activityTypes = activityTypes.filter((item) => {
          return item !== ActivityTypes.Enrichment;
        });
      }
      if (isActivityTypeSelected(activityType)) {
        updatedActivityTypes = activityTypes.filter((item) => {
          return item !== activityType;
        });
      } else {
        activityTypes.push(activityType);
        updatedActivityTypes = activityTypes;
      }
    }

    methods.setValue('activityTypes', updatedActivityTypes, validateTouchDirtyOptions);
  };

  const onSubmitAgreementForm = (formValues: AgreementFormValues) => {
    const agreementBackendValues: AgreementBackendValues = {
      ...cloneDeep(formValues),
      agreementRichText: JSON.stringify(formValues.agreementRichText)
    };
    onSubmit(agreementBackendValues, false, methods.setError);
  };

  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete="off" onSubmit={methods.handleSubmit(onSubmitAgreementForm)}>
        <FormTextInput name="agreementName" label="Agreement name" required />
        {isActivityTypeSelected(ActivityTypes.Enrichment) && (
          <Box sx={notificationSx}>
            {`To put Location name in text add ${locationNameMarkup} markup.`}
            <LightTooltip title={<Typography>Click to copy</Typography>}>
              <span>
                <div style={{ display: 'flex', marginRight: 5 }} data-testid="registration-link-with-copier">
                  <IconButton
                    color="primary"
                    aria-label="copy to clipboard"
                    component="span"
                    style={{ marginTop: -4, padding: 5 }}
                    onClick={() => copy(locationNameMarkup)}
                  >
                    <ContentCopyOutlinedIcon fontSize="small" />
                  </IconButton>
                </div>
              </span>
            </LightTooltip>
          </Box>
        )}
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          <RichTextEditor
            value={mergedValues.agreementRichText}
            onChange={onRichTextEditorChange}
            editorHeight={520}
            placeholder={'Enter agreement text…'}
          />
        </div>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="customerMustSignAgreement"
              checked={methods.watch('customerMustSignAgreement')}
              onChange={(e) => {
                methods.setValue('customerMustSignAgreement', e.target.checked, validateTouchDirtyOptions);
              }}
              disabled={isActivityTypeSelected(ActivityTypes.Enrichment)}
            />
          }
          label="Customer must agree with before registration"
        />
        <hr />

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            marginTop: 15,
            marginBottom: 20
          }}
        >
          <ActivityTypeChecker
            message={'Show for:'}
            onClick={onActivityTypeClick}
            checkItemDisabled={isActivityTypeSelectorDisabled}
            includeEnrollment={true}
          />
        </div>
        <hr />
        <FormCancelSubmitButtons
          mode={formMode}
          onCancel={onCancel}
          isFormValid={isValid && isDirty}
          isSubmitting={isSubmitting}
        />
      </form>
    </FormProvider>
  );
};

export default AgreementForm;
