import React, { FC } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Teacher } from '@efacity/frontend-shared';
import { getInstructorLabel } from '../../pages/SessionsContainer/SessionMainInfoForm/FormInstructorSelector';

const textFieldStyle = {
  marginTop: '11px',
  width: '350px'
};

interface InstructorSelectorProps {
  label: string;
  instructors: Teacher[];
  onChange;
}

const InstructorSelector: FC<InstructorSelectorProps> = ({ label, instructors, onChange }) => {
  return (
    <Autocomplete
      options={instructors}
      getOptionLabel={getInstructorLabel}
      fullWidth
      onChange={onChange}
      style={textFieldStyle}
      renderInput={(params) => <TextField {...params} label={label} id="instructor-selector" size="small" />}
    />
  );
};

export default InstructorSelector;
