import React, { useMemo } from 'react';
import { EnhancedColumn } from '../../components/Table/Table';
import {
  ConnectModel,
  connectModelViewConnector,
  ConnectStatus,
  OrganizationStatus,
  organizationStatusViewConnector,
  OrganizationTransfer,
  paymentPlatformTypeViews
} from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import { Box, IconButton, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ConnectedAccount, Organization, CircularProgressButton } from '@efacity/frontend-shared';
import { groupBy } from 'lodash';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import EditIcon from '@mui/icons-material/Edit';
import { PhoneNumber } from '@efacity/react-next-sc';

const hiddenSmStyles = {
  display: {
    xs: 'none',
    sm: 'table-cell'
  }
};
const alignTopStyles = {
  verticalAlign: 'top'
};

const isActiveTransfer = (transferStripeUserId: string, stripePlatformAccounts: ConnectedAccount[]) => {
  return !!stripePlatformAccounts.find((account) => account.stripeUserId === transferStripeUserId);
};

export const useOrganizationColumns = (
  handleStartAccountModal,
  startOrganizationTransferModal,
  onConnectCLick,
  onDisconnectCLick,
  connectButtonDisabled
) => {
  const columns = useMemo<EnhancedColumn<Organization>[]>(
    () => {
      const columns: EnhancedColumn<Organization>[] = [
        {
          Header: 'Name',
          accessor: 'name',
          Cell: ({ row }) => (
            <Link component={NavLink} to={toPath(PATHS.editSchool, { id: row.original._id })} underline={'none'}>
              {row.original.name}
            </Link>
          ),
          disableFilters: true,
          disableSortBy: true,
          columnSx: alignTopStyles
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ row }) => {
            return (
              <div>
                {row.original?.status
                  ? organizationStatusViewConnector[row.original?.status]
                  : organizationStatusViewConnector[OrganizationStatus.Active]}
              </div>
            );
          },
          disableFilters: true,
          disableSortBy: true,
          headerSx: hiddenSmStyles,
          columnSx: { ...alignTopStyles, hiddenSmStyles }
        },
        {
          Header: 'Admins',
          accessor: 'administrators',
          Cell: ({ row }) => (
            <div>
              {row.original?.administrators.map((admin, index) => (
                <div key={admin._id}>
                  {admin.fullName}
                  {index + 1 < row.original.administrators.length ? ',' : ''}
                </div>
              ))}
            </div>
          ),
          disableFilters: true,
          disableSortBy: true,
          headerSx: hiddenSmStyles,
          columnSx: { ...alignTopStyles, hiddenSmStyles }
        },
        {
          Header: 'Phone',
          accessor: 'phone',
          Cell: ({ row }) => <PhoneNumber phone={row.original.phone} />,
          disableFilters: true,
          disableSortBy: true,
          headerSx: hiddenSmStyles,
          columnSx: { ...alignTopStyles, hiddenSmStyles }
        },
        {
          Header: 'Payment Platform',
          accessor: 'paymentAccountId',
          Cell: ({ row }) => (
            <Link
              component={NavLink}
              to="#"
              onClick={() =>
                handleStartAccountModal(row.original?.paymentAccountId?._id, row.original._id, row.original.name)
              }
              underline={'none'}
            >
              {row.original?.paymentAccountId
                ? `${paymentPlatformTypeViews[row.original.paymentAccountId.paymentPlatformType]}: ${
                    row.original.paymentAccountId.name
                  }`
                : 'Add Account'}
            </Link>
          ),
          disableFilters: true,
          disableSortBy: true,
          headerSx: hiddenSmStyles,
          columnSx: { ...alignTopStyles, hiddenSmStyles }
        },
        {
          Header: 'Transfers',
          accessor: 'organizationTransfers',
          Cell: ({ row }) => (
            <div>
              {row.original?.organizationTransfers?.length > 0
                ? Object.values(groupBy(row.original.organizationTransfers, 'paymentPlatformAccountId')).map(
                    (group, index) => {
                      return (
                        <div key={`organizationTransferCell${index}`}>
                          {index > 0 && <hr style={{ height: 2, marginBottom: 5, marginTop: 5 }} />}
                          {group.map((transfer: OrganizationTransfer) => {
                            return (
                              <div key={`organizationTransferCell${transfer._id}`}>
                                {`${transfer.name}, ${transfer.transferPercentage}%`}
                                {row.original?.connectStatus === ConnectStatus.ConnectedExpress ||
                                isActiveTransfer(
                                  transfer.stripeUserId,
                                  row.original?.paymentAccountId?.stripePlatformAccount?.connectedAccounts || []
                                ) ? (
                                  <div style={{ color: '#2F75C1', display: 'inline' }}> (active)</div>
                                ) : (
                                  <div style={{ color: 'red', display: 'inline' }}> (inactive)</div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      );
                    }
                  )
                : ''}
              {row.original?.paymentAccountId?.stripePlatformAccount?.connectedAccounts?.length > 0 && (
                <Box display="flex" justifyContent="center" style={{ marginTop: 2, marginBottom: 2 }}>
                  <IconButton
                    color="primary"
                    component="span"
                    style={smallPublishButtonStyle}
                    onClick={() => startOrganizationTransferModal(row.original)}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              )}
            </div>
          ),
          disableFilters: true,
          disableSortBy: true,
          headerSx: hiddenSmStyles,
          columnSx: { ...alignTopStyles, hiddenSmStyles }
        },
        {
          Header: 'Connect Model',
          accessor: 'connectModel',
          Cell: ({ row }) => (
            <div>{row.original?.connectModel ? connectModelViewConnector[row.original?.connectModel] : '[n/a]'}</div>
          ),
          disableFilters: true,
          disableSortBy: true,
          headerSx: hiddenSmStyles,
          columnSx: { ...alignTopStyles, hiddenSmStyles }
        },
        {
          Header: 'Efacity Standard Connect',
          accessor: 'connectStatus',
          Cell: ({ row }) => (
            <div style={{ textAlign: 'center' }}>
              {!row.original.parentOrgId && row.original?.connectModel === ConnectModel.ConnectStandard && (
                <CircularProgressButton
                  progress={false}
                  color="primary"
                  onClick={() => {
                    row.original.connectStatus === ConnectStatus.ConnectedStandard
                      ? onDisconnectCLick(row.original._id)
                      : onConnectCLick(row.original._id);
                  }}
                  style={{ ...smallPublishButtonStyle, width: 100 }}
                  data-testid={`connectButtonForEfacity${row.original._id}`}
                  disabled={connectButtonDisabled}
                >
                  {row.original.connectStatus === ConnectStatus.ConnectedStandard ? 'Disconnect' : 'Connect'}
                </CircularProgressButton>
              )}
            </div>
          ),
          disableFilters: true,
          disableSortBy: true,
          headerSx: hiddenSmStyles,
          columnSx: { ...alignTopStyles, hiddenSmStyles }
        }
      ];

      return columns;
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [handleStartAccountModal]
  );

  return columns;
};
