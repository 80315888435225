import { DisplayOption } from './DisplayOption';

export enum TimeOfDay {
  Morning = 'morning',
  Afternoon = 'afternoon',
  Evening = 'evening'
}

export const timeOfDayOptions = [
  new DisplayOption(TimeOfDay.Morning, 'Morning'),
  new DisplayOption(TimeOfDay.Afternoon, 'Afternoon'),
  new DisplayOption(TimeOfDay.Evening, 'Evening')
];
