import { create } from 'zustand';

type Store = {
  isExpanded: boolean;
  setLeftNavExpanded: (isExpanded: boolean) => void;
};

export const useLeftSidebarStore = create<Store>((set) => ({
  isExpanded: false,
  setLeftNavExpanded: (isExpanded) => {
    set(() => ({ isExpanded }));
  }
}));
