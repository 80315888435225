import * as yup from 'yup';
import { ActivityTypes, AgreementOnForm, emptyRichEdit, Messages } from '@efacity/common';
import { Descendant } from 'slate';

export class AgreementFormValues implements AgreementOnForm {
  _id: string;
  agreementName: string;
  agreementRichText: Descendant[];
  customerMustSignAgreement: boolean;
  activityTypes: ActivityTypes[];
}
export class AgreementBackendValues implements AgreementOnForm {
  _id: string;
  agreementName: string;
  agreementRichText: string;
  customerMustSignAgreement: boolean;
  activityTypes: ActivityTypes[];
}

export const agreementFormValuesValidationSchema = yup.object({
  agreementName: yup.string().required(Messages.LocationNameRequired),
  agreementRichText: yup.array(),
  customerMustSignAgreement: yup.boolean(),
  activityTypes: yup.array()
});

export const initialAgreementFormValues = {
  _id: '',
  agreementName: '',
  agreementRichText: emptyRichEdit,
  activityTypes: [],
  customerMustSignAgreement: false
};
