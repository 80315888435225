import React from 'react';
import LinkOff from '@mui/icons-material/LinkOff';
import Button from '@mui/material/Button';

const RemoveLinkButton = ({ onRemoveLink }) => {
  return (
    <Button
      style={{ minWidth: 34, borderRadius: 0, borderBottom: '2px solid transparent' }}
      onMouseDown={event => {
        event.preventDefault();
        onRemoveLink();
      }}
    >
      <LinkOff />
    </Button>
  );
};

export default RemoveLinkButton;
