import React, { FC, useEffect } from 'react';
import { Box, Button, Grid, Theme, Typography } from '@mui/material';
import {
  areSessionsGaveAdditionalOptions,
  FormGroupDivider,
  WithLoading,
  DEFAULT_SESSION_IMAGE
} from '@efacity/frontend-shared';
import { moneyWithCurrencyCodeFormatter, StudentOnSessionRegistrationInfo } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { setRegistrationProgress, useAuth } from '@efacity/react-next-sc';
import { PATHS } from '@efacity/routing';
import Add from '@mui/icons-material/Add';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlined from '@mui/icons-material/ArrowForwardOutlined';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useTypedLocation } from '../../hooks/useTypedLocation';
import { setNeedReturnToRegistration } from '../CustomerStudents/useCustomerStudentsLoader';
import { RegistrationState } from '../PaymentContainer/useInvoicePayment';
import SelectStudentsList from './SelectStudentsList';
import { SessionDurationDetails } from './SessionDurationDetails/SessionDurationDetails';

export const selectStudentsStyles = {
  sessionDescriptionSx: {
    fontSize: '16px',
    color: (theme: Theme) => theme.palette.grey[0],
    margin: '15 0'
  },
  detailsSx: {
    fontSize: '18px',
    lineHeight: '16px',
    color: (theme: Theme) => theme.palette.primary.main,
    margin: '15 0'
  },
  mainInfoContainerSx: {
    flexDirection: {
      xs: 'column-reverse',
      sm: 'row'
    }
  },
  nameSx: {
    color: (theme: Theme) => theme.palette.primary.main,
    fontSize: { xs: '24px', sm: '28px' }
  },
  activityImg: {
    maxWidth: '100%',
    maxHeight: '100%',
    height: 'auto'
  }
};

export interface SelectStudentsToSessionByIndexProps {
  registrationState: RegistrationState;
  registrationActions;
}

const SelectStudentsToSessionByIndex: FC<SelectStudentsToSessionByIndexProps> = ({
  registrationState,
  registrationActions
}) => {
  const {
    isLoadingSessionRegistrations,
    isRegistering,
    sessionIndex,
    sessionsSelectedForRegistration,
    students,
    existedRegistrationsForSession
  } = registrationState;
  const {
    storeSessionIndex,
    addStudentToSessionSelectionToShoppingCart,
    removeStudentFromSessionSelectionFromShoppingCart,
    getExistedRegistrationsForSession
  } = registrationActions;
  const {
    authState: {
      extraAuthInfo: { shoppingCart }
    }
  } = useAuth();

  const location = useTypedLocation<{ sessionId: string | undefined }>();
  const storedSessionId = location?.state?.sessionId || null;
  const selectedSessionId = sessionsSelectedForRegistration[sessionIndex]?._id;
  const shoppingCartHasSessionItemWithoutStudent =
    // remove items with already registered students
    shoppingCart?.shoppingCartRegistrations
      ?.filter(
        (item) =>
          !existedRegistrationsForSession.find(
            (existedRegistration) =>
              existedRegistration.sessionId === item.session._id &&
              existedRegistration.studentId === item.student?._id &&
              existedRegistration.isRegistered === true
          )
      )
      .filter((item) => item.session._id === selectedSessionId && !item?.student)?.length > 0;

  useEffect(() => {
    if (storedSessionId) {
      const sessionIndex = sessionsSelectedForRegistration.findIndex(
        (sessionSelectedForRegistration) => sessionSelectedForRegistration._id === storedSessionId
      );
      if (sessionIndex !== registrationState.sessionIndex) storeSessionIndex(sessionIndex);
    }
    if (location?.state?.sessionId) {
      location.state.sessionId = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionsSelectedForRegistration, storeSessionIndex, storedSessionId]);

  const areSessionsHaveAdditionalOptions = areSessionsGaveAdditionalOptions(sessionsSelectedForRegistration);

  const navigate = useNavigate();
  const snackbarProps = useSnackbar();

  const handleClickRegisterOnSession = async (studentId: string, sessionId: string, isChecked: boolean) => {
    const selection: StudentOnSessionRegistrationInfo = {
      studentId: studentId,
      sessionId: sessionId,
      isRegistered: false,
      isSelectedToRegistration: isChecked
    };
    if (isChecked) {
      const { isError, message } = await addStudentToSessionSelectionToShoppingCart(selection);
      if (isError) {
        showNotification(snackbarProps, false, message, false);
      }
    } else {
      const { isError, message } = await removeStudentFromSessionSelectionFromShoppingCart(selection);
      if (isError) {
        showNotification(snackbarProps, false, message, false);
      }
    }
  };

  useEffect(() => {
    const loadSessionsAndCheckRegistrations = async () => {
      if (
        sessionsSelectedForRegistration &&
        sessionsSelectedForRegistration.length > 0 &&
        students &&
        students.length > 0
      ) {
        const selectedSessionId = sessionsSelectedForRegistration[sessionIndex]._id;
        const { isError, data, message } = await getExistedRegistrationsForSession(selectedSessionId);
        if (!isError) {
          const studentsNotSelectedOnSelectedSession = students
            .filter((student) => data?.find((item) => item.studentId === student._id && item.isRegistered === false))
            .filter((student) =>
              shoppingCart?.shoppingCartRegistrations?.find(
                (item) => item.session._id === selectedSessionId && item?.student?._id !== student._id
              )
            );

          if (shoppingCartHasSessionItemWithoutStudent && studentsNotSelectedOnSelectedSession?.length === 1) {
            await handleClickRegisterOnSession(studentsNotSelectedOnSelectedSession[0]._id, selectedSessionId, true);
          }
        } else {
          showNotification(snackbarProps, false, message, false);
        }
      }
    };
    loadSessionsAndCheckRegistrations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [students, sessionsSelectedForRegistration, sessionIndex]);

  const handlePreviousClicked = () => {
    if (sessionIndex > 0) {
      storeSessionIndex(sessionIndex - 1);
    }
  };

  const handleNextClicked = async () => {
    if (sessionIndex === sessionsSelectedForRegistration.length - 1) {
      if (areSessionsHaveAdditionalOptions) {
        navigate(PATHS.selectAdditionalOptionsByCustomer);
      } else {
        setRegistrationProgress(true);
        navigate(PATHS.cart);
      }
    } else {
      storeSessionIndex(sessionIndex + 1);
    }
  };

  const toggleNeedReturnAndGotoAddStudent = (requireStudentAge: boolean) => {
    setNeedReturnToRegistration(requireStudentAge);
    navigate(PATHS.addStudent);
  };
  const currentSelectedSession = sessionsSelectedForRegistration[sessionIndex];

  return (
    <WithLoading isLoading={isLoadingSessionRegistrations} message={'Loading previous session registrations data...'}>
      <div style={{ flexGrow: 1, padding: '10px' }} data-testid="select-students-by-index">
        <div style={{ margin: 'auto', maxWidth: 800 }}>
          <Grid container sx={selectStudentsStyles.mainInfoContainerSx} spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography sx={selectStudentsStyles.sessionDescriptionSx} variant="h6" gutterBottom>
                Registration for:
              </Typography>
              <Box sx={selectStudentsStyles.nameSx}>{currentSelectedSession.name}</Box>
              <Box sx={selectStudentsStyles.sessionDescriptionSx}>{currentSelectedSession.shortDescription}</Box>
              <SessionDurationDetails session={currentSelectedSession} />
              <Box sx={selectStudentsStyles.detailsSx}>
                Price: {moneyWithCurrencyCodeFormatter(currentSelectedSession.price, currentSelectedSession.currency)}
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <img
                src={currentSelectedSession?.imageFullUrl || DEFAULT_SESSION_IMAGE}
                alt={currentSelectedSession.name}
                style={selectStudentsStyles.activityImg}
              />
            </Grid>
          </Grid>
          <FormGroupDivider marginY={15} />
          <SelectStudentsList
            shoppingCart={shoppingCart}
            students={students}
            existedRegistrationsForSession={existedRegistrationsForSession}
            sessionId={sessionsSelectedForRegistration[sessionIndex]._id}
            isRegistering={isRegistering}
            handleClickRegisterOnSession={handleClickRegisterOnSession}
          />
          <Grid container>
            <Grid item style={{ textAlign: 'left', width: '100%', marginTop: 10, marginBottom: 15 }}>
              <Button
                onClick={() => toggleNeedReturnAndGotoAddStudent(registrationState.requireStudentAge)}
                style={{ width: 168, height: 36 }}
                startIcon={<Add />}
                color="primary"
                variant="outlined"
                disabled={isRegistering}
              >
                Add Student
              </Button>
            </Grid>
          </Grid>
          <FormGroupDivider marginY={15} />
          <WithLoading isLoading={isRegistering} message={'Perform registration...'} />
          <Grid container justifyContent="center">
            {sessionIndex === 0 || isRegistering ? (
              ''
            ) : (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                style={{ textAlign: 'center', width: '100%', marginTop: 15, marginBottom: 15 }}
              >
                <Button
                  onClick={() => handlePreviousClicked()}
                  style={{ width: 220, height: 36 }}
                  startIcon={<ArrowBackOutlined />}
                  color="primary"
                  variant="contained"
                >
                  Previous
                </Button>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              style={{ textAlign: 'center', width: '100%', marginTop: 15, marginBottom: 15 }}
            >
              <div>
                <Button
                  id="registerAndPayButton"
                  onClick={() => handleNextClicked()}
                  style={{ width: 220, height: 36 }}
                  endIcon={<ArrowForwardOutlined />}
                  color="primary"
                  variant="contained"
                  disabled={isRegistering || shoppingCartHasSessionItemWithoutStudent}
                >
                  Continue
                </Button>
                {shoppingCartHasSessionItemWithoutStudent && (
                  <div className="error" style={{ marginTop: 10 }}>
                    Please select at least one student before continuing
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </WithLoading>
  );
};

export default SelectStudentsToSessionByIndex;
