import { MenuItem } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { DiscountClass, DisplayOption, SupportedDiscountClassOptions } from '@efacity/common';
import { FormTextInput } from './FormTextInput';

interface DiscountClassSelectorProps {
  name: string;
  onChange: (value: DiscountClass) => void;
}

export const DiscountClassSelector: React.FC<DiscountClassSelectorProps> = ({ name, onChange }) => {
  const handleDiscountClassChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value as DiscountClass);
  };
  return (
    <FormTextInput
      select
      name={name}
      label="Discount Class"
      data-testid="classDefinition.discountClass"
      onChange={handleDiscountClassChange}
    >
      {Object.values(SupportedDiscountClassOptions).map((discountClassOption: DisplayOption) => (
        <MenuItem key={discountClassOption.value} value={discountClassOption.value}>
          {discountClassOption.displayText}
        </MenuItem>
      ))}
    </FormTextInput>
  );
};
