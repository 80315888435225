import React, { FC } from 'react';
import { moneyWithCurrencyCodeFormatter } from '@efacity/common';
import { Box } from '@mui/material';
import mobileTableStyle from '../stylesheets/mobileTableStyle';
import { DiscountsListProps } from './DiscountsList';

const DiscountsListMobile: FC<DiscountsListProps> = ({ discounts, currency, offsetLeft = 0, colSpan = 1 }) => {
  // const denseTableClasses = mobileTableStyle();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {discounts?.map(discount => {
        return (
          <Box
            padding="10px"
            display="grid"
            gridTemplateColumns="1.6fr 1fr 1fr"
            style={{ borderTop: '1px solid #C4C4C4', width: '100%' }}
            key={discount._id}
          >
            <Box sx={mobileTableStyle.titleSx} gridColumn="1/3">
              {discount.description}
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              {moneyWithCurrencyCodeFormatter(discount.calculatedAmount, currency)}
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default DiscountsListMobile;
