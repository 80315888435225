import type { FC } from 'react';
import type { SxProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import type { OrganizationInformation } from '../../organizationsFetchers';
import { PATHS } from '@efacity/routing';
import { SocialMediaLinks } from './SocialMediaLinks';

const footerStyles = {
  root: {
    maxWidth: '1000px',
    width: '100%',
    margin: 'auto',
    marginBottom: 0,
    padding: { sm: '0 20px', xs: '0 16px' }
  },
  footerSx: {
    paddingTop: 2,
    paddingBottom: 2,
    lineHeight: '16px',
    textAlign: 'center',
    color: 'grey.600',
    width: '100%'
  },
  linkSx: {
    textDecoration: 'none',
    color: 'grey.600',
    display: 'flex',
    alignItems: 'center',
    margin: '8px 32px 8px',
    whiteSpace: 'nowrap',
    '& img': {
      marginRight: 4
    }
  },
  iconSx: {
    color: '#2F75C1',
    marginRight: 4
  },
  contactsSx: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: { md: 18, sm: 16, xs: 14 },
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    alignItems: {
      xs: 'center',
      md: 'auto'
    },
    '& a': {
      marginBottom: {
        xs: 2,
        md: 0
      }
    }
  }
};

export interface FooterProps {
  orgInfo: OrganizationInformation | null;
  sxOverride?: SxProps;
}

export const Footer: FC<FooterProps> = ({ orgInfo, sxOverride }) => {
  const phone = orgInfo?.phone || '1-609-874-7716';
  const email = orgInfo?.contactEmail || orgInfo?.sendFromEmail || 'info@efacity.com';
  const website = orgInfo?.website || PATHS.about;
  const socialMedia = orgInfo?.socialMedia || [];

  return (
    <Box component="footer" sx={{ ...footerStyles.root, ...sxOverride }} data-testid="default-footer">
      <Box sx={footerStyles.footerSx}>
        <Box sx={footerStyles.contactsSx} paddingY="10">
          <Box component="a" href={`tel:${phone}`} sx={footerStyles.linkSx} data-testid="footer-phone">
            <PhoneIcon sx={footerStyles.iconSx} />
            {phone}
          </Box>
          <Box component="a" href={`mailto:${email}`} sx={footerStyles.linkSx} data-testid="footer-email">
            <EmailIcon sx={footerStyles.iconSx} />
            {email}
          </Box>
          <Box component="a" href={website} sx={footerStyles.linkSx} data-testid="footer-website">
            <InfoIcon sx={footerStyles.iconSx} />
            About
          </Box>
        </Box>
        <hr />
        <Box paddingY="10px" position="relative">
          <Typography sx={{ textAlign: { xs: 'left', sm: 'center' } }}>
            {`© ${new Date().getFullYear()}`} Efacity
          </Typography>
          <SocialMediaLinks links={socialMedia} />
        </Box>
      </Box>
    </Box>
  );
};
