export enum Relationship {
  Father = 'father',
  Mother = 'mother',
  Stepfather = 'step-father',
  Stepmother = 'step-mother',
  Grandfather = 'grandfather',
  Grandmother = 'grandmother',
  Guardian = 'guardian',
  FosterParent = 'foster-parent',
  Other = 'other'
}
export const RelationshipViewConnector = {
  [Relationship.Father]: 'Father',
  [Relationship.Mother]: 'Mother',
  [Relationship.Stepfather]: 'Step-father',
  [Relationship.Stepmother]: 'Step-mother',
  [Relationship.Grandfather]: 'Grandfather',
  [Relationship.Grandmother]: 'Grandmother',
  [Relationship.Guardian]: 'Guardian',
  [Relationship.FosterParent]: 'Foster parent',
  [Relationship.Other]: 'Other'
};
export const relationshipSelectOptions = Object.entries(RelationshipViewConnector).map(([value, label]) => {
  return {
    value,
    label
  };
});
