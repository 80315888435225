import { userProfilePictureHeight, userProfilePictureWidth } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { ImageCropper, SubmitMediumButton, apiService, getFormAsFormData, useFetch } from '@efacity/frontend-shared';
import { FormTextInput, addServerErrors } from '@efacity/react-hook-form-mui';
import { useAuth } from '@efacity/react-next-sc';
import { Button, FormHelperText } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const userPublicInfoFormStyle = {
  maxWidth: '500px',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
} as React.CSSProperties;

interface UserPublicInfo {
  profilePictureURL?: string;
  bio?: string;
}

function UserPublicInfoForm() {
  const snackbarProps = useSnackbar();

  const { authState } = useAuth();
  const userId = authState.user._id;
  const [{ data: userData, isLoading: isLoadingUserData }] = useFetch<UserPublicInfo>(`/users/${userId}/profile`, {
    initialDataState: null,
    errorMessage: 'Could not fetch user data'
  });

  const methods = useForm({
    defaultValues: userData
  });
  const { isSubmitting, isValid, isDirty } = methods.formState;

  const [cropError, setCropError] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);

  const onCropFinished = (image: Blob) => {
    const imagePreviewUrl = URL.createObjectURL(image);
    methods.setValue('profilePictureURL', imagePreviewUrl, { shouldDirty: true });
    setCropError(null);
    setProfilePicture(image);
  };

  const onImageCropError = (errorMessage: string) => {
    setCropError(errorMessage);
    methods.setValue('profilePictureURL', '');
  };

  useEffect(
    () => {
      methods.reset(userData);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingUserData]
  );

  const onSubmit = (formValues) => {
    const updateSuccessful = (message: string) => {
      showNotification(snackbarProps, true, message);
    };

    const updateFailure = (error) => {
      const { data: errorData } = error.response;
      if (errorData.validationErrors) {
        addServerErrors<UserPublicInfo>(errorData.validationErrors, methods.setError);
      } else {
        showNotification(snackbarProps, false, errorData.message as string);
      }
    };

    const formDataToSend = getFormAsFormData<UserPublicInfo>(formValues, []);
    if (profilePicture) {
      formDataToSend.append('profilePictureURL', profilePicture);
    }

    return apiService
      .patch<{ user: UserPublicInfo; message: string }>(`/users/${userId}/profile/`, formDataToSend)
      .then(() => {
        updateSuccessful('User updated');
      })
      .catch((error) => {
        updateFailure(error);
      });
  };

  const deleteProfilePicture = () => {
    if (profilePicture) {
      setProfilePicture(null);
    }
    methods.setValue('profilePictureURL', '', { shouldDirty: true });
  };

  const isSubmitDisabled = !(isValid && isDirty);
  const showRemovePictureBtn = profilePicture || methods.getValues('profilePictureURL');

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)} style={userPublicInfoFormStyle}>
        <ImageCropper
          id={'profile-picture'}
          onCropFinished={(croppedImage) => {
            onCropFinished(croppedImage);
          }}
          onError={(errorMessage) => onImageCropError(errorMessage)}
          imageSrc={methods.watch('profilePictureURL')}
          imageHeight={userProfilePictureHeight}
          imageWidth={userProfilePictureWidth}
          previewWidth={120}
          previewHeight={120}
        />
        {cropError && (
          <FormHelperText error style={{ alignSelf: 'flex-start' }}>
            {cropError}
          </FormHelperText>
        )}
        {showRemovePictureBtn && (
          <Button variant="outlined" onClick={deleteProfilePicture}>
            Remove my profile picture
          </Button>
        )}
        <FormTextInput name="bio" label="Public visible bio" multiline minRows={4} />
        <SubmitMediumButton disabled={isSubmitDisabled} isSubmitting={isSubmitting}>
          Save
        </SubmitMediumButton>
      </form>
    </FormProvider>
  );
}

export default UserPublicInfoForm;
