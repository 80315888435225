import React from 'react';
import { Button, CircularProgress } from '@mui/material';

export interface DeleteWithSpinnerIconProps {
  onClick: () => void;
  isRegistering: boolean;
  disabled: boolean;
  buttonText?: string;
  buttonStyle?: React.CSSProperties;
  buttonVariant?: 'text' | 'outlined' | 'contained';
}

export const AddToCartButton: React.FC<DeleteWithSpinnerIconProps> = ({
  onClick,
  isRegistering,
  buttonText = 'Register',
  buttonStyle = { width: 110 },
  buttonVariant = 'contained',
  ...props
}) => {
  return (
    <Button
      variant={buttonVariant}
      color="primary"
      data-testid="addToCartBtn"
      onClick={onClick}
      style={buttonStyle}
      {...props}
    >
      {isRegistering ? <CircularProgress variant="indeterminate" size={20} /> : buttonText}
    </Button>
  );
};
