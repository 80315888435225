import { isDeepEmpty } from '@efacity/common';

export const addServerErrors = <T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any,
  setError: (fieldName: keyof T, error: { type: string; message: string }) => void,
  path = '',
  firstError: string | null = null
): string | null => {
  let currentFirstError = firstError;
  if (isDeepEmpty(errors)) return firstError;

  Object.keys(errors).forEach((key) => {
    const currentError = errors[key];
    const keyPath = path?.length > 0 ? `${path}.${key}` : key;

    if (typeof currentError === 'object') {
      const deepError = addServerErrors(currentError, setError, keyPath, firstError);
      if (!currentFirstError) currentFirstError = deepError;
    } else {
      setError(keyPath as keyof T, {
        type: 'server',
        message: errors[key] as string
      });
      if (!firstError) {
        if (!currentFirstError) currentFirstError = keyPath;
      }
    }
  });

  if (currentFirstError) {
    const element = document.getElementsByName(currentFirstError)[0];
    if (element) {
      window.setTimeout(() => {
        element.focus();
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 0);
    }
  }
  return currentFirstError;
};
