import { User } from '@efacity/common';
import { PageTitle, PhoneNumber, useAuth } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import { Link } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import EmailAddress from '../../components/EmailAddress/EmailAddress';
import Table, { EnhancedColumn, PaginationTypes } from '../../components/Table/Table';
import { useCustomerStudentsLoader } from './useCustomerStudentsLoader';

const CustomerStudentsContainer: React.FC = () => {
  const {
    authState: {
      user: { _id: customerId }
    }
  } = useAuth();
  const [{ studentsState }, { getStudents }] = useCustomerStudentsLoader(customerId);

  useEffect(() => {
    getStudents();
  }, [customerId, getStudents]);

  const tableColumns = useMemo(() => {
    const childrenTableColumns: EnhancedColumn<User>[] = [
      {
        Header: 'Full Name',
        accessor: 'fullName',
        disableFilters: true,
        Cell: ({ row, value }) => {
          return (
            <Link component={NavLink} to={toPath(PATHS.editStudent, { studentId: row.original._id })}>
              {value}
            </Link>
          );
        }
      },
      {
        Header: 'Email',
        accessor: 'email',
        disableFilters: true,
        Cell: ({ value }) => {
          return value ? <EmailAddress email={value} /> : <>-</>;
        }
      },
      {
        Header: 'Phone',
        accessor: 'defaultPhone',
        disableFilters: true,
        Cell: ({ value }) => {
          return <PhoneNumber phone={value} />;
        }
      }
    ];

    return childrenTableColumns;
  }, []);

  return (
    <div>
      <PageTitle title="Students" addButtonLink={undefined} />
      <Table
        data={studentsState.students}
        columns={tableColumns}
        loading={studentsState.isLoading}
        paginationType={PaginationTypes.ClientSide}
      />
    </div>
  );
};

export default CustomerStudentsContainer;
