import { Access, CountryCode, Roles } from '@efacity/common';
import { emptyAddress, emptyDOB } from '@efacity/react-next-sc';

export interface UserFormValues {
  fullName: string;
  callByName: string;
  email: string;
  defaultPhone: string;
  defaultPhoneCountry: string;
  organization?: string;
  dateOfBirth: {
    year: string;
    month: string;
    day: string;
  };
  address: {
    address: string;
    country: string;
    city: string;
    zip: string;
    state: string;
  };
  access?: Access[];
  password?: string;
}

export const initialUserFormValues: UserFormValues = {
  email: '',
  fullName: '',
  callByName: '',
  defaultPhone: '',
  defaultPhoneCountry: CountryCode.USA as string,
  organization: '',
  dateOfBirth: emptyDOB,
  address: emptyAddress,
  access: [
    {
      role: Roles.Customer,
      isDefault: false,
      organization: ''
    }
  ],
  password: ''
};

export const initialTeacherFormValues: UserFormValues = {
  ...initialUserFormValues,
  access: []
};
