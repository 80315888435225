import React, { FC } from 'react';
import { IconButton, Link } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteForever';

interface UploadedFileItemsProps {
  uploadedFiles: string[];
  onDeleteUploadedFile: (i: number) => void | null;
  disabled: boolean;
}

export const UploadedFileItems: FC<UploadedFileItemsProps> = ({
  uploadedFiles = [],
  onDeleteUploadedFile,
  disabled
}) => {
  if (uploadedFiles?.length === 0) return null;
  return (
    <>
      {uploadedFiles.length > 1 ? <div>Uploaded files:</div> : <div>Uploaded file:</div>}
      {uploadedFiles.map((file, i) => (
        <div
          style={{ display: 'flex', justifyContent: 'left', marginLeft: 40, alignItems: 'center' }}
          key={`file-list-${i}`}
        >
          [
          <Link href={file} target="_blank" data-testid="page-link-name" underline={'none'}>
            {uploadedFiles?.length > 1 ? `Preview file ${i + 1}` : 'Preview file'}
          </Link>
          ]
          {onDeleteUploadedFile && (
            <IconButton
              color="primary"
              component="span"
              style={{ marginLeft: 20 }}
              onClick={() => onDeleteUploadedFile(i)}
              disabled={disabled}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      ))}
    </>
  );
};
