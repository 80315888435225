import { Box, Grid, Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import React, { useState } from 'react';
import { ImageCropper, getNestedFormProperty } from '@efacity/frontend-shared';
import {
  CountryCode,
  defaultLogoHeight,
  defaultLogoWidth,
  defaultMobileLogoHeight,
  defaultMobileLogoWidth,
  FormMode
} from '@efacity/common';
import { AddressSelectorWithModal } from './AddressSelector';
import { DefaultAddressForm } from './DefaultAddressForm';
import { useFormContext } from 'react-hook-form';
import {
  CountrySelector,
  CurrencyInput,
  defaultRHFSetValueOptions,
  FormPhoneInput,
  FormTextInput,
  FormTimeZoneSelector
} from '@efacity/react-hook-form-mui';

export const logoBoxSx = {
  schoolLogosContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: {
      xs: 'column',
      sm: 'row'
    }
  },
  logoUploadError: {
    alignSelf: 'flex-start'
  }
};

export interface OrganizationInfoFormProps {
  mode?: FormMode;
  orgId: string;
  pathToFormObject?: string;
  loading: boolean;
  setLogoImgBlob: (logoImg: Blob) => void;
  setMobileLogoImgBlob: (mobileLogoImg: Blob) => void;
}

export const OrganizationBasicForm: React.FC<OrganizationInfoFormProps> = ({
  mode,
  orgId,
  pathToFormObject,
  setLogoImgBlob,
  setMobileLogoImgBlob
}) => {
  const [cropError, setCropError] = useState(null);
  const [mobileCropError, setMobileCropError] = useState(null);

  const methods = useFormContext();

  const onLogoCropFinished = (image: Blob) => {
    const imagePreviewUrl = URL.createObjectURL(image);
    methods.setValue(
      getNestedFormProperty('logoFullUrl', pathToFormObject),
      imagePreviewUrl,
      defaultRHFSetValueOptions
    );
    setCropError(null);
    setLogoImgBlob(image);
  };

  const onLogoCropError = (errorMessage: string) => {
    setCropError(errorMessage);
    methods.setValue(getNestedFormProperty('logoFullUrl', pathToFormObject), '', defaultRHFSetValueOptions);
  };

  const onMobileLogoCropFinished = (image: Blob) => {
    const imagePreviewUrl = URL.createObjectURL(image);
    methods.setValue(
      getNestedFormProperty('mobileLogoFullUrl', pathToFormObject),
      imagePreviewUrl,
      defaultRHFSetValueOptions
    );
    setMobileCropError(null);
    setMobileLogoImgBlob(image);
  };

  const onMobileLogoCropError = (errorMessage: string) => {
    setMobileCropError(errorMessage);
    methods.setValue(getNestedFormProperty('mobileLogoFullUrl', pathToFormObject), '', defaultRHFSetValueOptions);
  };

  return (
    <Grid container spacing={1} style={{ marginBottom: '10' }}>
      <Grid item xs={12} sm={6}>
        <Box sx={logoBoxSx.schoolLogosContainer}>
          <ImageCropper
            id={'logo'}
            onCropFinished={(croppedImage) => {
              onLogoCropFinished(croppedImage);
            }}
            onError={(errorMessage) => onLogoCropError(errorMessage)}
            imageSrc={methods.getValues(getNestedFormProperty('logoFullUrl', pathToFormObject))}
            imageHeight={defaultLogoHeight}
            imageWidth={defaultLogoWidth}
            uploadLabel={'Upload logo'}
          />
          {cropError && (
            <FormHelperText error style={logoBoxSx.logoUploadError}>
              {cropError}
            </FormHelperText>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box>
          <ImageCropper
            id={'mobileLogo'}
            onCropFinished={(croppedImage) => {
              onMobileLogoCropFinished(croppedImage);
            }}
            onError={(errorMessage) => onMobileLogoCropError(errorMessage)}
            imageSrc={methods.getValues(getNestedFormProperty('mobileLogoFullUrl', pathToFormObject))}
            imageHeight={defaultMobileLogoHeight}
            imageWidth={defaultMobileLogoWidth}
            uploadLabel={'Upload mobile logo'}
          />
          {mobileCropError && (
            <FormHelperText error style={logoBoxSx.logoUploadError}>
              {mobileCropError}
            </FormHelperText>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <FormTextInput label="Name" required name={getNestedFormProperty('name', pathToFormObject)} />
      </Grid>
      <Grid item xs={12}>
        <FormTextInput
          label="Description"
          multiline
          minRows={4}
          name={getNestedFormProperty('description', pathToFormObject)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextInput label="Website" name={getNestedFormProperty('website', pathToFormObject)} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextInput
          label="Customer Logout URL"
          name={getNestedFormProperty('customerLogoutUrl', pathToFormObject)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextInput
          label="Contact E-mail"
          name={getNestedFormProperty('contactEmail', pathToFormObject)}
          type="email"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextInput
          name={getNestedFormProperty('additionalNotificationEmails', pathToFormObject)}
          label="Registration Notification Emails (comma separated)"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControlLabel
          control={
            <Checkbox
              checked={methods.watch(getNestedFormProperty('hideNumberOfRegistrations', pathToFormObject))}
              name={getNestedFormProperty('hideNumberOfRegistrations', pathToFormObject)}
              color="primary"
              onChange={() => {
                methods.setValue(
                  getNestedFormProperty('hideNumberOfRegistrations', pathToFormObject),
                  !methods.getValues(getNestedFormProperty('hideNumberOfRegistrations', pathToFormObject)),
                  defaultRHFSetValueOptions
                );
              }}
            />
          }
          label="Hide # of Registrations on External Registration"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={methods.watch(getNestedFormProperty('requireStudentAge', pathToFormObject))}
                name={getNestedFormProperty('requireStudentAge', pathToFormObject)}
                color="primary"
                onChange={() => {
                  methods.setValue(
                    getNestedFormProperty('requireStudentAge', pathToFormObject),
                    !methods.getValues(getNestedFormProperty('requireStudentAge', pathToFormObject)),
                    defaultRHFSetValueOptions
                  );
                }}
              />
            }
            label="Requre Student Age under Registration"
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CountrySelector name={getNestedFormProperty('country', pathToFormObject)} onValueChange={null} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormPhoneInput name={getNestedFormProperty('phone', pathToFormObject)} required validateOnStart={true} />
      </Grid>
      <Grid item xs={12}>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <FormTimeZoneSelector
            name={getNestedFormProperty('timeZone', pathToFormObject)}
            country={methods.getValues(getNestedFormProperty('country', pathToFormObject)) as CountryCode}
            required
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        {mode === FormMode.Edit ? (
          <AddressSelectorWithModal
            name={getNestedFormProperty('defaultAddress._id', pathToFormObject)}
            orgId={orgId}
            country={methods.watch(getNestedFormProperty('country', pathToFormObject))}
          />
        ) : (
          <DefaultAddressForm
            pathToFormObject={getNestedFormProperty('defaultAddress', pathToFormObject)}
            country={methods.watch(getNestedFormProperty('country', pathToFormObject))}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextInput label="EIN" name={getNestedFormProperty('ein', pathToFormObject)} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CurrencyInput label="Default Currency" name={getNestedFormProperty('defaultCurrency', pathToFormObject)} />
      </Grid>
    </Grid>
  );
};
