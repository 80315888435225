import { CurrencyCode, InvoiceItemPresentation, moneyWithCurrencyCodeFormatter } from '@efacity/common';
import React, { FC } from 'react';
import { cellSx, strippedTableRowSx } from '@efacity/frontend-shared';
import DeleteIconButtonWithSpinner from '../../../components/DeleteIconButtonWithSpinner/DeleteIconButtonWithSpinner';
import { TableCell, TableRow } from '@mui/material';

export interface AdditionalOptionInvoiceItemTableRowProps {
  invoiceItem: InvoiceItemPresentation;
  handleDeleteAdditionalOption: (sessionId: string, studentId: string, additionalOptionId: string) => void;
  index: number;
  isUpdating: boolean;
  disabled: boolean;
}

const AdditionalOptionInvoiceItemTableRow: FC<AdditionalOptionInvoiceItemTableRowProps> = ({
  invoiceItem,
  handleDeleteAdditionalOption,
  index,
  isUpdating,
  disabled
}) => {
  return (
    <TableRow sx={strippedTableRowSx}>
      <TableCell sx={cellSx} align="left" colSpan={6} style={{ paddingLeft: 60 }}>
        {invoiceItem.description}
      </TableCell>
      <TableCell sx={cellSx} align="right" style={{ whiteSpace: 'nowrap', width: 120 }} scope="row">
        {moneyWithCurrencyCodeFormatter(invoiceItem.amount, invoiceItem.currencyCode as CurrencyCode)}
      </TableCell>
      <TableCell sx={cellSx} align="center" style={{ width: 80 }}>
        <DeleteIconButtonWithSpinner
          onClick={() =>
            handleDeleteAdditionalOption(invoiceItem.sessionId, invoiceItem.studentId, invoiceItem.additionalOptionId)
          }
          isUpdating={isUpdating}
          data-testid={`deleteIcon${index}`}
          disabled={disabled}
        />
      </TableCell>
    </TableRow>
  );
};

export default AdditionalOptionInvoiceItemTableRow;
