import { CurrencyCode, moneyWithCurrencyCodeFormatter } from '@efacity/common';
import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import mobileTableStyle from '../../../stylesheets/mobileTableStyle';
import { AdditionalOptionInvoiceItemTableRowProps } from './AdditionalOptionInvoiceItemTableRow';
import DeleteIconButtonWithSpinner from '../../../components/DeleteIconButtonWithSpinner/DeleteIconButtonWithSpinner';

const AdditionalOptionInvoiceItemMobileTableRow: FC<AdditionalOptionInvoiceItemTableRowProps> = ({
  invoiceItem,
  handleDeleteAdditionalOption,
  index,
  isUpdating,
  disabled
}) => {
  return (
    <Box sx={mobileTableStyle.rootSx}>
      <Box style={mobileTableStyle.additionalOptionItemRow}>
        <Box padding="10px">
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Additional Option:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>{invoiceItem.description}</Typography>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Amount:
          </Typography>
          <Typography component="div" style={mobileTableStyle.textSm}>
            {moneyWithCurrencyCodeFormatter(invoiceItem.amount, invoiceItem.currencyCode as CurrencyCode)}
          </Typography>
        </Box>
        <Box style={mobileTableStyle.deleteIcon}>
          <DeleteIconButtonWithSpinner
            data-testid={`deleteIcon${index}Mobile`}
            onClick={() =>
              handleDeleteAdditionalOption(invoiceItem.sessionId, invoiceItem.studentId, invoiceItem.additionalOptionId)
            }
            isUpdating={isUpdating}
            disabled={disabled}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AdditionalOptionInvoiceItemMobileTableRow;
