import {
  Address,
  FormMode,
  LocationType,
  Roles,
  VirtualMeeting,
  defaultMobileLogoHeight,
  defaultMobileLogoWidth,
  locationOptions
} from '@efacity/common';
import { ImageCropper, Teacher } from '@efacity/frontend-shared';
import { FormTextInput, defaultRHFSetValueOptions } from '@efacity/react-hook-form-mui';
import { hasFrontendAccessWithRoleForOrganization, useAuth } from '@efacity/react-next-sc';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, FormHelperText, Grid, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ErrorOption, FieldPath, FormProvider, useForm } from 'react-hook-form';
import { FormCancelSubmitButtons } from '@efacity/frontend-next-shared/forms';
import { useTeacherOptions } from '../../hooks/useTeacherOptions';
import { logoBoxSx, AddressSelectorWithModal } from '@efacity/frontend-next-shared/org-settings';
import { getInstructorLabel } from '../SessionsContainer/SessionMainInfoForm/FormInstructorSelector';
import { locationFormValidationSchema } from './LocationFormValidationSchema';
import { useLocationFormValues } from './useLocationsLoader';

export interface LocationFormValues {
  _id?: string;
  type: LocationType;
  name: string;
  virtualMeeting?: VirtualMeeting;
  address?: Address;
  addressId?: string;
  orgId: string;
  defaultInstructor?: string;
  capacity?: number;
  logoFullUrl?: string;
  mobileLogoFullUrl?: string;
}

export interface LocationFormProps {
  mode: FormMode;
  orgId: string;
  locationId?: string;
  handleSubmit: (
    formValues: LocationFormValues,
    setError: (name: FieldPath<LocationFormValues>, error: ErrorOption, options?: { shouldFocus: boolean }) => void,
    mobileLogoImage: Blob
  ) => void;
  handleCancelClick: () => void;
  defaultLocationType?: LocationType;
}

const LocationForm: React.FC<LocationFormProps> = ({
  mode,
  orgId,
  locationId,
  handleSubmit,
  handleCancelClick,
  defaultLocationType
}) => {
  const { locationFormState } = useLocationFormValues(orgId, locationId, mode, defaultLocationType);
  const { teacherState } = useTeacherOptions(`/org/${orgId}/sessions/teachers-options`);
  const [mobileCropError, setMobileCropError] = useState(null);
  const [mobileLogoImgBlob, setMobileLogoImgBlob] = useState(null);

  const {
    authState: { user }
  } = useAuth();
  const isUserIndependentTeacher = hasFrontendAccessWithRoleForOrganization(
    orgId,
    [Roles.IndependentTeacher],
    user.adminAccesses || []
  );

  const methods = useForm<LocationFormValues>({
    resolver: yupResolver(locationFormValidationSchema),
    defaultValues: locationFormState.locationFormValues,
    mode: 'onBlur',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUseNativeValidation: false
  });
  const { isSubmitting, isValid, isDirty } = methods.formState;

  useEffect(
    () => {
      methods.reset(locationFormState.locationFormValues);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(locationFormState.locationFormValues)]
  );

  const onMobileLogoCropFinished = (image: Blob) => {
    const imagePreviewUrl = URL.createObjectURL(image);
    methods.setValue('mobileLogoFullUrl', imagePreviewUrl, { shouldDirty: true });
    setMobileCropError(null);
    setMobileLogoImgBlob(image);
  };

  const onMobileLogoCropError = (errorMessage: string, setFieldValue) => {
    setMobileCropError(errorMessage);
    setFieldValue('mobileLogoFullUrl', '');
  };

  const onSubmit = (formValues: LocationFormValues) => {
    handleSubmit(formValues, methods.setError, mobileLogoImgBlob);
  };

  const getInstructorValue = () => {
    const teacherId = methods.getValues('defaultInstructor');
    if (!teacherId) return null;
    return teacherState.options.find((item) => item?._id === teacherId);
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <FormTextInput select required label="Type" name="type" id="type" data-testid="locationTypeSelect">
          {locationOptions.map((locationType) => (
            <MenuItem key={locationType.value} value={locationType.value}>
              {locationType.label}
            </MenuItem>
          ))}
        </FormTextInput>

        {methods.watch('type') === LocationType.PublicSchool && (
          <Box sx={logoBoxSx.schoolLogosContainer}>
            <ImageCropper
              id={'mobileLogo'}
              onCropFinished={(croppedImage) => {
                onMobileLogoCropFinished(croppedImage);
              }}
              onError={(errorMessage) => onMobileLogoCropError(errorMessage, methods.setValue)}
              imageSrc={methods.getValues('mobileLogoFullUrl')}
              imageHeight={defaultMobileLogoHeight}
              imageWidth={defaultMobileLogoWidth}
              uploadLabel={'Upload mobile logo'}
            />
            {mobileCropError && (
              <FormHelperText error style={logoBoxSx.logoUploadError}>
                {mobileCropError}
              </FormHelperText>
            )}
          </Box>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <FormTextInput
              label={methods.watch('type') === LocationType.Virtual ? 'Meeting name' : 'Name / Room'}
              required
              id="name"
              name="name"
              data-testid="locationNameField"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormTextInput type="number" label="Capacity" name="capacity" data-testid="locationCapacityField" />
          </Grid>
        </Grid>

        {methods.watch('type') === LocationType.Virtual ? (
          <>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <FormTextInput label="Meeting ID" id="virtualMeeting.meetingId" name="virtualMeeting.meetingId" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextInput label="Passcode" id="virtualMeeting.password" name="virtualMeeting.password" />
              </Grid>
            </Grid>
            <FormTextInput label="Connection Link" id="virtualMeeting.link" name="virtualMeeting.link" />
          </>
        ) : (
          <AddressSelectorWithModal name="addressId" selectFieldLabel="Select address *" orgId={orgId} />
        )}

        {!isUserIndependentTeacher && (
          <Autocomplete
            value={getInstructorValue()}
            data-testid="locationDefaultInstructorSelect"
            options={teacherState.options}
            getOptionLabel={getInstructorLabel}
            fullWidth
            onChange={(_, instructor: Teacher) => {
              methods.setValue('defaultInstructor', instructor?._id || '', defaultRHFSetValueOptions);
            }}
            renderInput={(params) => <FormTextInput {...params} name="defaultInstructor" label="Default Instructor" />}
          />
        )}

        <FormCancelSubmitButtons
          isFormValid={isValid && isDirty}
          mode={mode}
          isSubmitting={isSubmitting}
          onCancel={handleCancelClick}
        />
      </form>
    </FormProvider>
  );
};

export default LocationForm;
