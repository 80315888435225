import {
  CountryCode,
  Messages,
  getCountryByCode,
  getFieldIsRequiredMessage,
  getStateOptionsDependingOnCountry,
  isValidUrl,
  isValidZipCode
} from '@efacity/common';
import * as yup from 'yup';

export const isValidZipCodeValidator: yup.TestFunction = (value: unknown, context): boolean => {
  return isValidZipCode(context.parent.country as CountryCode, value as string);
};

export const isValidUrlValidator: yup.TestFunction = (value: unknown): boolean => {
  // This will let user submit form without map link
  if (!value) return true;

  return isValidUrl(value as string);
};

export const addressFormValidationSchema = yup.object({
  country: yup.string().required(Messages.CountryRequired),
  address: yup.string().required(Messages.AddressRequired),
  city: yup.string().required(Messages.CityRequired),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: yup.mixed().test('address', '', function (this: yup.TestContext, fieldValue: any): any {
    const allValuesEmpty = Object.values(this.parent).every((value) => !value);
    if (allValuesEmpty) {
      return true;
    }

    // UK does not have states
    if (this.parent.country === CountryCode.UK) return true;

    if (!fieldValue) {
      const stateLabel = getCountryByCode(this.parent.country).formTitles['state'] || 'State';
      return this.createError({ path: this.path, message: getFieldIsRequiredMessage(stateLabel) });
    }

    const stateOptions = getStateOptionsDependingOnCountry(this.parent.country) || [];
    const isStateValueInOptions =
      fieldValue === '' || stateOptions.some((stateOption) => stateOption.value === fieldValue);

    if (!isStateValueInOptions) {
      return this.createError({ path: this.path, message: Messages.StateInvalid });
    } else {
      return true;
    }
  }),
  zip: yup.string().test('zip', Messages.PostalCodeInvalid, isValidZipCodeValidator),
  mapLink: yup.string().test('mapLink', Messages.MapLinkInvalid, isValidUrlValidator)
});
