import { FC } from 'react';
import { PATHS } from '@efacity/routing';

interface NextPageRedirectProps {
  path?: string;
}

const NEXT_BASE_PATH = process.env['REACT_APP_NEXT_BASE_URL'] || '';

const NextPageRedirect: FC<NextPageRedirectProps> = ({ path = PATHS.landing }) => {
  window.location.href = NEXT_BASE_PATH + path;
  return null;
};

export default NextPageRedirect;
