import { InputAdornment, Typography } from '@mui/material';
import { FormInputTextProps, FormTextInput } from './FormTextInput';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

export type TextFieldWithCounterProps = {
  maxLength: number;
} & FormInputTextProps;

export const TextFieldWithCounter: React.FC<TextFieldWithCounterProps> = ({ maxLength, name, ...otherProps }) => {
  const [counterColor, setCounterColor] = useState('text.secondary');

  const { watch } = useFormContext();
  const currentLength = watch(name).length;

  useEffect(() => {
    if (currentLength === maxLength) setCounterColor('error.main');
    if (currentLength < maxLength) setCounterColor('text.secondary');
  }, [currentLength, maxLength]);

  return (
    <FormTextInput
      name={name}
      {...otherProps}
      inputProps={{ maxLength: maxLength }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Typography
              aria-label="Character counter"
              variant="body1"
              sx={{
                color: counterColor,
                backgroundColor: 'white',
                height: '12px',
                fontSize: '12px',
                position: 'absolute',
                top: '-12px',
                right: '3px',
                zIndex: 1
              }}
            >{`${currentLength} / ${maxLength}`}</Typography>
          </InputAdornment>
        )
      }}
    />
  );
};
