'use client';

import React, { useEffect } from 'react';
import { AuthStatus, OrganizationInformation, useAuth } from '@efacity/react-next-sc';
import { useEnrollmentApplication } from './useEnrollmentApplication';
import { MessageSmallModal, AuthModal } from '@efacity/frontend-shared';
import { EnrollmentFormContainerWithStripe } from './EnrollmentFormContainerWithStripe';
import { EnrollmentView } from './EnrollmentView';
import { mainContentSx, publicSchoolContainer } from './styles';
import { Box } from '@mui/material';
import { PATHS, toPath } from '@efacity/routing';

const enrollmentPageSx = {
  ...publicSchoolContainer,
  '& h1': { fontWeight: 'bold' },
  '& section>p': { marginBottom: 2 }
};

interface EnrollmentFormProps {
  orgInfo: OrganizationInformation;
  enrollmentId: string | undefined;
}

export const EnrollmentFormContainer: React.FC<EnrollmentFormProps> = ({ orgInfo, enrollmentId }) => {
  const orgId = orgInfo._id;
  const { authState, signIn, signUp } = useAuth();
  const isUserAuthenticated = authState?.isAuthenticated === AuthStatus.Authenticated;
  const isUserNotAuthenticated = authState?.isAuthenticated === AuthStatus.Unauthenticated;
  const isCheckingAuth = authState?.isAuthenticated === AuthStatus.Checking;

  const enrollmentApplicationState = useEnrollmentApplication(orgInfo._id, orgInfo.timeZone);
  const {
    enrollmentApplicationFormState: { enrollmentForm, submittedOn, isViewMode },
    getUserEnrollmentApplication
  } = enrollmentApplicationState;

  useEffect(() => {
    if (isUserAuthenticated) getUserEnrollmentApplication(orgId, enrollmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthenticated, orgId, enrollmentId]);

  return (
    <Box data-testid="organizationenrollment-layout-next" sx={enrollmentPageSx}>
      <Box sx={{ ...mainContentSx, marginTop: '10px' }} data-test-id="mainContent">
        {isViewMode ? (
          <EnrollmentView
            enrollmentForm={enrollmentForm}
            timeZone={orgInfo.timeZone || 'America/New_York'}
            submittedOn={submittedOn}
          />
        ) : (
          <EnrollmentFormContainerWithStripe orgInfo={orgInfo} enrollmentApplication={enrollmentApplicationState} />
        )}
        {isCheckingAuth && <MessageSmallModal open={isCheckingAuth} message="Checking auth status..." />}
        {isUserNotAuthenticated && (
          <AuthModal
            open={isUserNotAuthenticated}
            redirectPath={toPath(PATHS.enrollment, { orgId: orgId })}
            signIn={signIn}
            signUp={signUp}
            authenticatedUrl={'/'}
          />
        )}
      </Box>
    </Box>
  );
};
