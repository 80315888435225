import React, { useMemo, useState } from 'react';
import { Button, IconButton, Link, Tooltip } from '@mui/material';
import { EnhancedColumn } from '../../components/Table/Table';
import {
  getInvoiceFileName,
  getPaymentReceiptFileName,
  moneyWithCurrencyCodeFormatter,
  PurchaseStatus,
  purchaseStatusViewConnector
} from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import DateColumnFilter from '../../components/Table/filters/DateColumnFilter';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { UserProductPurchasesExtended } from './useCustomerProductPurchases';
import PurchaseStatusFilter from '../ProductPurchases/PurchaseStatusFilter';
import ColoredPurchaseStatusDate from '../ProductPurchases/ColoredPurchaseStatusDate';

const marginTopCellStyles = { verticalAlign: 'top', paddingTop: 10 };

export const useCustomerProductPurchasesColumns = () => {
  return useMemo(() => {
    const columns: EnhancedColumn<UserProductPurchasesExtended>[] = [
      {
        Header: 'Product',
        accessor: 'productName',
        disableFilters: false,
        columnStyles: marginTopCellStyles,
        Cell: ({ value, row }) => {
          return row.original?.orgId && row.original?.productId ? (
            <Link
              component={Button}
              onClick={() => {
                window.location.href = toPath(PATHS.publicProductDescription, {
                  orgId: row.original.orgId,
                  productId: row.original.productId
                });
              }}
              underline="none"
              style={{ textTransform: 'none' }}
            >
              {value}
            </Link>
          ) : (
            <div>{value}</div>
          );
        }
      },
      {
        Header: 'Seller',
        accessor: 'organizationName',
        disableFilters: false,
        columnStyles: marginTopCellStyles,
        Cell: ({ value }) => {
          return <div>{value}</div>;
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: false,
        columnStyles: { ...marginTopCellStyles, width: 280, maxWidth: 280 },
        Cell: ({ value, row }) => {
          const [isExpanded, setIsExpanded] = useState(false);
          return (
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              {isExpanded ? (
                <div style={{ width: '100%' }}>
                  {row.original?.orderedAt && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.orderedAt}
                      timeZone={row.original.organization?.timeZone}
                      purchaseStatus={PurchaseStatus.Ordered}
                    />
                  )}
                  {row.original?.paidAt && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.paidAt}
                      timeZone={row.original.organization?.timeZone}
                      purchaseStatus={PurchaseStatus.Paid}
                    />
                  )}
                  {row.original?.shippedAt && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.shippedAt}
                      timeZone={row.original.organization?.timeZone}
                      purchaseStatus={PurchaseStatus.Shipped}
                    />
                  )}
                  {(row.original?.deliveryConfirmedByCustomerAt || row.original?.deliveryConfirmedByAdminAt) && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.deliveryConfirmedByCustomerAt || row.original?.deliveryConfirmedByAdminAt}
                      timeZone={row.original.organization?.timeZone}
                      purchaseStatus={PurchaseStatus.Delivered}
                    />
                  )}
                  {row.original?.cancelledAt && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.cancelledAt}
                      timeZone={row.original.organization?.timeZone}
                      purchaseStatus={PurchaseStatus.Cancelled}
                    />
                  )}
                </div>
              ) : (
                <div style={{ width: '100%' }}>{purchaseStatusViewConnector[value]}</div>
              )}
              <div>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setIsExpanded(!isExpanded)}
                  data-testid="collapseButton"
                  style={{ marginTop: -10 }}
                >
                  {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
            </div>
          );
        },
        Filter: PurchaseStatusFilter
      },
      {
        Header: 'Status Updated',
        accessor: 'updatedAt',
        disableFilters: false,
        columnStyles: { ...marginTopCellStyles, width: 190, maxWidth: 190 },
        Cell: ({ value, row }) => (
          <div>
            <ColoredPurchaseStatusDate
              dateTime={value}
              timeZone={row.original.organization?.timeZone}
              purchaseStatus={row.original.status}
              showStatus={false}
            />
          </div>
        ),
        Filter: DateColumnFilter
      },
      {
        Header: 'Balance',
        disableFilters: false,
        headerStyles: { textAlign: 'right', paddingRight: 15 },
        columnStyles: { ...marginTopCellStyles, textAlign: 'right', width: 115, maxWidth: 115 },
        Cell: ({ value, row }) => {
          return (
            <div>
              {moneyWithCurrencyCodeFormatter(
                row.original.invoiceBalance?.balance ? row.original?.invoiceBalance?.balance : 0,
                row.original?.invoiceBalance?.currencyCode
              )}
              <span {...row.getToggleRowExpandedProps()}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  data-testid="collapseButton"
                  style={{ marginTop: -10 }}
                >
                  {row.isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </span>
            </div>
          );
        }
      },
      {
        Header: 'Invoice',
        disableFilters: false,
        columnStyles: { ...marginTopCellStyles, width: 65, maxWidth: 65 },
        Cell: ({ value, row }) => {
          return (
            <div>
              {row.original.invoiceBalance ? (
                row.original.invoiceBalance?.isPaid ? (
                  <Tooltip title="Download Payment Receipt">
                    <Link href={getPaymentReceiptFileName(row.original.invoiceBalance?._id)}>Receipt</Link>
                  </Tooltip>
                ) : (
                  <Tooltip title="Download Invoice">
                    <Link href={getInvoiceFileName(row.original.invoiceBalance?._id)}>Invoice</Link>
                  </Tooltip>
                )
              ) : (
                ''
              )}
            </div>
          );
        }
      }
    ];

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
