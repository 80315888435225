// https://en.wikipedia.org/wiki/English_honorifics
export enum Honorific {
  Mr = 'mr',
  Miss = 'miss',
  Mrs = 'mrs',
  Ms = 'ms',
  Mx = 'mx',
  Dr = 'dr',
  Prof = 'prof'
}
export const HonorificsViewConnector = {
  [Honorific.Mr]: 'Mr.',
  [Honorific.Miss]: 'Miss.',
  [Honorific.Mrs]: 'Mrs.',
  [Honorific.Ms]: 'Ms.',
  [Honorific.Mx]: 'Mx.',
  [Honorific.Dr]: 'Dr.',
  [Honorific.Prof]: 'Prof.'
};
export const honorificSelectOptions = Object.entries(HonorificsViewConnector).map(([value, label]) => {
  return {
    value,
    label
  };
});
