import React from 'react';
import { Box, Typography } from '@mui/material';

export interface UserInfoContainerProps {
  title: string;
  children: React.ReactNode;
}

const UserInfoContainer: React.FC<UserInfoContainerProps> = ({ title, children }) => {
  return (
    <Box>
      <Typography variant="h6" align="center" gutterBottom>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default UserInfoContainer;
