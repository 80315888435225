import { CountryCode } from '@efacity/common';
import { FormPasswordInput, FormPhoneInput, FormTextInput } from '@efacity/react-hook-form-mui';
import { SignUpFormValues } from '@efacity/react-next-sc';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { FC } from 'react';
import { ErrorOption, FieldPath, FormProvider, useForm } from 'react-hook-form';
import { signUpFormValidationSchema } from './signUpFormValidationSchema';
import { CircularProgressButton } from '../../buttons/CircularProgressButton';

export interface SignUpFormProps {
  onSignUp: (
    values: SignUpFormValues,
    setError: (name: FieldPath<SignUpFormValues>, error: ErrorOption, options?: { shouldFocus: boolean }) => void
  ) => void;
  submitBtnLabel?: string;
}

const defaultSignUpFormValues = {
  fullName: '',
  defaultPhone: '',
  defaultPhoneCountry: CountryCode.USA,
  email: '',
  password: '',
  recaptchaToken: ''
};

export const SignUpForm: FC<SignUpFormProps> = ({ onSignUp, submitBtnLabel = 'Sign up' }) => {
  const methods = useForm<SignUpFormValues>({
    resolver: yupResolver(signUpFormValidationSchema),
    mode: 'onBlur',
    defaultValues: defaultSignUpFormValues
  });
  const { isValid, isDirty, isSubmitting } = methods.formState;

  const onSubmit = (values) => {
    onSignUp(values, methods.setError);
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <FormTextInput name="email" type="email" label="Email Address (will be used for login)" required autoFocus />
        <FormTextInput name="fullName" label="Your Full Name" required />
        <FormPhoneInput name="defaultPhone" required validateOnStart={false} />
        <FormPasswordInput autoComplete="new-password" label="Password" id="password" data-testid="password" required />

        <Box display="flex" justifyContent="center" style={{ marginTop: '40px' }}>
          <CircularProgressButton
            data-testid="submit"
            progress={isSubmitting}
            disabled={!isValid || !isDirty || isSubmitting}
            color="primary"
          >
            {submitBtnLabel}
          </CircularProgressButton>
        </Box>
      </form>
    </FormProvider>
  );
};
