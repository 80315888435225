import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const MustAgreeCheckedIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props}>
      <path d="M19 1H5C3.9 1 3.01 1.9 3.01 3L3 15.93C3 16.62 3.35 17.23 3.88 17.59L12 23L20.11 17.59C20.64 17.23 20.99 16.62 20.99 15.93L21 3C21 1.9 20.1 1 19 1ZM10 16L5 11L6.41 9.59L10 13.17L17.59 5.58L19 7L10 16Z" />
    </SvgIcon>
  );
};

export default MustAgreeCheckedIcon;
