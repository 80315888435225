import React from 'react';
import { WithLoading, apiService, getFormAsFormData } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { FormMode, JobPayBy, Media, Messages } from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useJobPostingLoader } from './useJobPostingLoader';
import { JobPostingBackendValues, JobPostingFormValues } from './jobPostingFormValues';
import JobPostingForm from './JobPostingForm';
import { cloneDeep } from 'lodash';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { addMinutes } from 'date-fns';
import { PageTitle } from '@efacity/react-next-sc';

export interface JobFormContainerProps {
  mode: FormMode;
}

export type JobIdParamType = Partial<{
  jobId: string;
  orgId: string;
}>;

const JobPostingFormContainer: React.FC<JobFormContainerProps> = ({ mode }) => {
  const navigate = useNavigate();
  const snackbarProps = useSnackbar();
  const { orgId, jobId } = useParams<JobIdParamType>();
  const { jobState } = useJobPostingLoader(orgId, jobId, mode);

  const handleCancelClick = () => {
    navigate(toPath(PATHS.jobs, { orgId: orgId }));
  };

  const submitJobForm = async (
    values: JobPostingFormValues,
    setError: (fieldName: keyof JobPostingFormValues, error: { type: string; message: string }) => void
  ) => {
    const blobImages = [];
    const mediaWithLinks = [];
    values?.media?.forEach((mediaItem: Media) => {
      if (mediaItem.blob) {
        return blobImages.push(mediaItem.blob);
      }
      return mediaWithLinks.push(mediaItem.link);
    });

    const backendJobValues: JobPostingBackendValues = {
      ...cloneDeep(values),
      description: JSON.stringify(values.description),
      media: mediaWithLinks,
      expiresOn: addMinutes(values.expiresOn, -new Date(values.expiresOn).getTimezoneOffset())
    };

    if (values.jobPayBy === JobPayBy.Amount) {
      backendJobValues.maximumRange = backendJobValues.minimumRange;
    }

    const jobFormDataToSend = getFormAsFormData<JobPostingBackendValues>(backendJobValues, []);
    if (blobImages?.length > 0) {
      blobImages.forEach((blob: Blob, index: number) => {
        jobFormDataToSend.append(`blobMedia`, blob);
      });
    }

    const actionSuccessful = () => {
      navigate(toPath(PATHS.jobs, { orgId: orgId }));
    };

    const actionFailure = (errorResponse, message: string) => {
      if (errorResponse.validationErrors) {
        addServerErrors<JobPostingFormValues>(errorResponse.validationErrors, setError);
      } else {
        showNotification(snackbarProps, false, message, true);
      }
    };

    if (mode === FormMode.Edit) {
      return apiService
        .patch(`/org/${orgId}/job/${jobId}`, jobFormDataToSend)
        .then(() => {
          actionSuccessful();
        })
        .catch((error) => {
          actionFailure(error.response.data, error.response.data.message || Messages.FailedToUpdateJob);
        });
    } else {
      return apiService
        .post<{ message: string }>(`/org/${orgId}/job`, jobFormDataToSend)
        .then(() => actionSuccessful())
        .catch((error) => {
          actionFailure(error.response.data, error.response.data.message || Messages.FailedToCreateJob);
        });
    }
  };

  return (
    <div>
      <PageTitle title={mode === FormMode.Edit ? 'Edit Job' : 'Add Job'} underlined />
      <WithLoading isLoading={jobState.isLoading} message={'Loading job settings...'} style={{ width: '25%' }}>
        <JobPostingForm
          initialFormValues={jobState.jobFormValues}
          handleSubmit={submitJobForm}
          handleCancel={handleCancelClick}
          formMode={mode}
        />
      </WithLoading>
    </div>
  );
};

export default JobPostingFormContainer;
