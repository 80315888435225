import { Types } from 'mongoose';

export enum LoginStatus {
  SUCCESS = 'success',
  FAILED = 'failed'
}
export const loginStatusViewConnector = {
  [LoginStatus.SUCCESS]: 'Success',
  [LoginStatus.FAILED]: 'Failed'
};

export interface LoginAuditInterface {
  _id: string | Types.ObjectId;
  login: string;
  loginTimestamp: string | Date;
  fromIpAddress: string;
  status: LoginStatus;
  failureReason?: string;
}
