import React from 'react';
import { useDiscountsLoader } from '../../hooks/useDiscountsLoader';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import Table, { PaginationTypes } from '../../components/Table/Table';
import { useParams } from 'react-router-dom';
import useDiscountsTableColumns from './useDiscountsTableColumns';

const ExpiredDiscountsTable: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const [{ discountsState }, { getDiscounts }] = useDiscountsLoader(`/org/${orgId}/discounts/expired`);
  const columns = useDiscountsTableColumns(orgId, discountsState.timeZone);

  return (
    <Table
      columns={columns}
      data={discountsState.discounts}
      loading={discountsState.isLoading}
      pageCount={discountsState.total}
      fetchData={getDiscounts}
      paginationType={PaginationTypes.ServerSide}
    />
  );
};

export default ExpiredDiscountsTable;
