import { Box, IconButton, Link, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { cellSx, SessionCapacityRange, SessionScheduleTable, strippedTableRowSx } from '@efacity/frontend-shared';
import {
  getSessionDuration,
  moneyWithCurrencyCodeFormatter,
  getSessionEndDate,
  getStudentLevelDiapason,
  StudentLevel,
  getSessionDatesDiapasonInLocalTimeZone
} from '@efacity/common';
import { Session, mapSessionInstancesToTimestamps } from '@efacity/react-next-sc';

const sessionNameSx = {
  color: 'primary.dark',
  '&:hover': {
    cursor: 'pointer'
  }
};

interface SessionInfoRowProps {
  session: Session;
  studentId: string;
  onSessionSelected;
  isRegistering: boolean;
}

const SessionInfoRow: React.FC<SessionInfoRowProps> = ({ session, studentId, onSessionSelected, isRegistering }) => {
  const [open, setOpen] = React.useState(false);

  const alreadyRegisteredOnSession = session.registrations.find(
    (registration) => (registration.registeredStudent as any as string) === studentId
  );

  return (
    <React.Fragment>
      <TableRow sx={strippedTableRowSx}>
        <TableCell sx={{ ...cellSx, ...sessionNameSx }} align="left">
          {isRegistering ? (
            <>
              {alreadyRegisteredOnSession && <Typography color="error">(already registered)</Typography>}
              <Typography color="primary">{session.name}</Typography>
            </>
          ) : alreadyRegisteredOnSession ? (
            <>
              <Typography color="error">(already registered)</Typography>
              <Typography color="primary">{session.name}</Typography>
            </>
          ) : (
            <Link
              onClick={() => onSessionSelected(session._id)}
              underline={'none'}
              data-testid={`sessionNameLink${session._id}`}
            >
              {session.name}
            </Link>
          )}
        </TableCell>
        <TableCell sx={cellSx} align="left">
          {session?.studentLevel ? getStudentLevelDiapason(session.studentLevel as StudentLevel) : '-'}
        </TableCell>
        <TableCell sx={cellSx} align="left">
          <Box>
            {session?.schedule?.startDateTime
              ? session?.schedule?.instances?.length > 0
                ? `${getSessionDatesDiapasonInLocalTimeZone(
                    session.schedule.startDateTime,
                    getSessionEndDate(mapSessionInstancesToTimestamps(session.schedule.instances))
                  )}`
                : `${getSessionDatesDiapasonInLocalTimeZone(
                    session.schedule.startDateTime,
                    session.schedule.endDateTime
                  )}`
              : 'n/a'}
          </Box>
        </TableCell>
        <TableCell sx={cellSx} align="left">
          <div style={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'space-between' }}>
            {session.schedule?.instances ? getSessionDuration(session.schedule.instances.length, session.type) : '-'}
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              data-testid="collapseButton"
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </div>
        </TableCell>
        <TableCell sx={cellSx} align="left">
          {moneyWithCurrencyCodeFormatter(session.price, session.currency)}
        </TableCell>
        <TableCell sx={cellSx} align="left">
          <SessionCapacityRange capacity={session.capacity} registrationsAmount={session.registrations?.length} />
        </TableCell>
      </TableRow>
      {open && (
        <TableRow sx={strippedTableRowSx} key={`sessionsListTable${session._id}ScheduleRow`}>
          <TableCell data-testid="session-view-schedule" style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <SessionScheduleTable schedule={session.schedule} />
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default SessionInfoRow;
