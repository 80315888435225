import React, { FC, ReactElement } from 'react';
import { Button, ButtonProps } from '@mui/material';

interface ButtonWithIconProps {
  icon: ReactElement;
}

const buttonWithIconSx = {
  color: '#666666',
  backgroundColor: '#ffffff',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  alignSelf: 'center',
  textTransform: 'initial',
  '& svg': {
    maxWidth: '24px',
    marginRight: '10px'
  },
  '& img': {
    maxWidth: '24px',
    marginRight: '10px'
  }
};

export const ButtonWithIcon: FC<ButtonProps & ButtonWithIconProps> = ({ children, icon, ...props }) => {
  return (
    <Button {...props} sx={buttonWithIconSx}>
      {icon}
      {children}
    </Button>
  );
};
