import { CurrencyCode, getCurrencyDisplayByStringCode, moneyWithCurrencyCodeFormatter } from '@efacity/common';
import { HorizontalField } from '@efacity/frontend-shared';
import { CurrencyFormat } from '@efacity/react-hook-form-mui';
import { CancelMediumButton, ModalDialogTitle } from '@efacity/react-next-sc';
import SaveIcon from '@mui/icons-material/Check';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputAdornment,
  Theme
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { FC, useState } from 'react';

const mainTextSx = {
  fontSize: 18,
  color: (theme: Theme) => theme.palette.primary.main,
  paddingTop: '1px',
  paddingBottom: '1px'
};

export interface InvoiceItemAdjustmentModalProps {
  adjustmentModalState;
  handleClose: () => void;
  onAdjust: (invoiceItemId: string, newAmount: number) => void;
}

const InvoiceItemAdjustmentModal: FC<InvoiceItemAdjustmentModalProps> = ({
  adjustmentModalState,
  handleClose,
  onAdjust
}) => {
  const invoiceItem = adjustmentModalState.invoiceItem;
  const disabled = adjustmentModalState.isAdjusting;
  const oldAmount = invoiceItem.amount;

  const [newAmount, setNewAmount] = useState(oldAmount);

  const handleAmountChanged = (amount) => {
    setNewAmount(amount.target.value);
  };

  const onSubmitAdjustment = () => {
    onAdjust(invoiceItem.invoiceItemId, newAmount);
  };

  return (
    <Dialog
      open={adjustmentModalState.open}
      onClose={handleClose}
      aria-labelledby="Add address modal"
      maxWidth="xs"
      fullWidth
    >
      <ModalDialogTitle text="Adjust Item Amount" onClose={handleClose} />

      <DialogContent>
        <HorizontalField justifyContent="center" wrapOnSmallScreen={false} style={{ paddingBottom: 15, width: '100%' }}>
          <Box sx={mainTextSx}>{`${invoiceItem?.description}`}</Box>
        </HorizontalField>
        <HorizontalField justifyContent="space-between" wrapOnSmallScreen={false} style={{ padding: 0, width: '100%' }}>
          <Box sx={mainTextSx}>Current amount:</Box>
          <Box sx={mainTextSx}>
            {moneyWithCurrencyCodeFormatter(invoiceItem.amount, invoiceItem.currencyCode as CurrencyCode)}
          </Box>
        </HorizontalField>
        <HorizontalField justifyContent="space-between" wrapOnSmallScreen={false} style={{ padding: 0, width: '100%' }}>
          <Box sx={mainTextSx}>Enter new amount:</Box>
          <div>
            <FormControl>
              <TextField
                id={'invoice-item-adjustment-new-amount'}
                defaultValue={oldAmount / 100}
                type="text"
                variant="outlined"
                size="small"
                error={false}
                helperText={''}
                fullWidth
                disabled={disabled}
                onChange={handleAmountChanged}
                inputProps={{
                  style: { textAlign: 'right', width: 70 },
                  id: 'adjustment-new-amount',
                  'data-testid': 'adjustment-new-amount'
                }}
                InputProps={{
                  inputComponent: CurrencyFormat,
                  startAdornment: (
                    <InputAdornment position="start" data-testid="currency-icon">
                      {getCurrencyDisplayByStringCode(invoiceItem.currencyCode as CurrencyCode)}
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </div>
        </HorizontalField>
      </DialogContent>

      <DialogActions>
        <HorizontalField justifyContent="center" wrapOnSmallScreen={false} style={{ padding: 9, width: '100%' }}>
          <CancelMediumButton onCancel={handleClose} disabled={disabled} />
          <Button
            style={{
              width: 171,
              fontSize: 14,
              textTransform: 'uppercase'
            }}
            onClick={onSubmitAdjustment}
            color="primary"
            variant="contained"
            data-testid="submit-adjustment-amount"
            disabled={disabled || oldAmount < newAmount}
          >
            {adjustmentModalState.isAdjusting ? (
              <CircularProgress variant="indeterminate" size={20} style={{ marginRight: 10, color: 'white' }} />
            ) : (
              <SaveIcon style={{ marginRight: 10 }} />
            )}
            Submit
          </Button>
        </HorizontalField>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceItemAdjustmentModal;
