import { getTimeDiapasonWithZone, getDateInLocalTimeZone } from '@efacity/common';
import {
  EfacitySessionSchedule,
  smallTableCellSx,
  smallTableContainerSx,
  smallTableHeadCellSx,
  smallTableHeadSx,
  smallTableRowSx
} from '@efacity/react-next-sc';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FC } from 'react';
import { Client } from 'react-hydration-provider';

export interface SessionScheduleTableProps {
  schedule: EfacitySessionSchedule;
}

export const SessionScheduleTable: FC<SessionScheduleTableProps> = ({ schedule }) => {
  if (!schedule || schedule?.instances?.length <= 0) return null;

  if (schedule.instances?.length > 10) return null;

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <TableContainer sx={smallTableContainerSx} style={{ maxWidth: '500px' }}>
      <Table size="small" aria-label="session schedule">
        <TableHead sx={smallTableHeadSx}>
          <TableRow>
            <TableCell sx={smallTableHeadCellSx}>Date</TableCell>
            <TableCell sx={smallTableHeadCellSx}>
              <Client>Time ({timezone})</Client>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedule.instances.map((instance, index) => (
            <TableRow sx={smallTableRowSx} key={`scheduleTableRow${instance._id}Key${index}`}>
              <TableCell sx={smallTableCellSx} align="right">
                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                  <span>
                    <Client>{getDateInLocalTimeZone(instance.startDateTime)}</Client>
                  </span>
                </div>
              </TableCell>
              <TableCell sx={smallTableCellSx} align="right">
                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                  <span>
                    <Client>
                      {getTimeDiapasonWithZone(instance.startDateTime, instance.durationInMinutes, timezone, false)}
                    </Client>
                  </span>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
