import {
  CountryCode,
  CurrencyCode,
  getStateOptionsDependingOnCountry,
  JobPostingInterface,
  JobLocationType,
  JobOpenPositions,
  JobPayBy,
  JobRate,
  JobSchedule,
  JobType,
  Media,
  Messages,
  emptyRichEdit,
  maxComputerDate
} from '@efacity/common';
import * as yup from 'yup';
import { Descendant } from 'slate';
import { isNumber } from 'lodash';

export class JobPostingFormValues implements Partial<JobPostingInterface> {
  _id: string;
  orgId: any;

  title: string;
  description: Descendant[];

  locationType: JobLocationType;
  city: string;
  state: string;
  country: string;

  jobType: JobType;
  jobSchedule: JobSchedule;
  jobOpenPositions: JobOpenPositions;
  jobPayBy: JobPayBy;
  currency: CurrencyCode;
  minimumRange: number;
  maximumRange: number;
  jobRate: JobRate;

  media: Media[];

  expiresOn: Date;
  timeZone: string;
}
export class JobPostingBackendValues implements Partial<JobPostingInterface> {
  _id: string;
  orgId: any;

  title: string;
  description: string;

  locationType: JobLocationType;
  city: string;
  state: string;
  country: string;

  jobType: JobType;
  jobSchedule: JobSchedule;
  jobOpenPositions: JobOpenPositions;
  jobPayBy: JobPayBy;
  currency: CurrencyCode;
  minimumRange: number;
  maximumRange: number;
  jobRate: JobRate;

  media: string[];

  expiresOn: Date;
  timeZone: string;
}

export const jobPostingInitialFormValues = {
  _id: '',
  orgId: '',

  title: '',
  description: emptyRichEdit,

  locationType: JobLocationType.InPerson,
  city: '',
  state: '',
  country: CountryCode.USA,

  jobType: JobType.FullTime,
  jobSchedule: JobSchedule.AfterSchool,
  jobOpenPositions: JobOpenPositions.One,
  jobPayBy: JobPayBy.Range,
  currency: CurrencyCode.USD,
  minimumRange: 1000,
  maximumRange: 2000,
  jobRate: JobRate.PerHour,

  media: [],

  expiresOn: maxComputerDate,
  timeZone: 'America/New_York'
};

export const jobPostingFormValidationSchema = yup.object({
  title: yup.string().required(Messages.TitleRequired),
  description: yup.array().test('description-test', null, function (this: yup.TestContext, description): any {
    if (description?.length > 0) {
      return true;
    }
    return this.createError({
      path: 'description',
      message: Messages.DescriptionRequired
    });
  }),
  locationType: yup.string().required(Messages.LocationTypeRequired),
  city: yup.string().required(Messages.CityRequired),
  state: yup.mixed().test('state', function (this: yup.TestContext, fieldValue: any): any {
    if (this.parent.country === CountryCode.UK) {
      return true;
    }
    if (!fieldValue) {
      return this.createError({ path: '', message: Messages.StateRequired });
    }

    const stateOptions = getStateOptionsDependingOnCountry(this.parent.country) || [];
    const isStateValueInOptions =
      fieldValue === '' || stateOptions.some(stateOption => stateOption.value === fieldValue);
    if (!isStateValueInOptions) {
      return this.createError({ path: this.path, message: Messages.StateInvalid });
    } else {
      return true;
    }
  }),
  country: yup.string().required(Messages.CountryRequired),
  jobType: yup.string().required(Messages.JobTypeRequired),
  jobSchedule: yup.string().required(Messages.JobScheduleRequired),
  jobOpenPositions: yup.string().required(Messages.JobOpenPositionsRequired),
  jobPayBy: yup.string().required(Messages.JobPayByRequired),
  minimumRange: yup.mixed().test('state', function (this: yup.TestContext, minimumRange: any): any {
    if (isNumber(minimumRange)) {
      if (minimumRange < 0) {
        return this.createError({ path: this.path, message: Messages.NonNegativeExpected });
      }
      return true;
    } else {
      return this.createError({ path: this.path, message: Messages.MinimumRangeRequired });
    }
  }),
  maximumRange: yup.mixed().test('state', function (this: yup.TestContext, maximumRange: any): any {
    if (isNumber(maximumRange)) {
      if (maximumRange < 0) {
        return this.createError({ path: this.path, message: Messages.NonNegativeExpected });
      }
      return true;
    } else {
      return this.createError({ path: this.path, message: Messages.MinimumRangeRequired });
    }
  }),
  jobRate: yup.string().required(Messages.JobRateRequired),
  expiresOn: yup.date()
});
