import { DisplayOption } from './DisplayOption';

export enum ReviewStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected'
}

export class ReviewStatusOptions {
  static readonly PENDING = new DisplayOption(ReviewStatus.Pending, 'Pending');
  static readonly APPROVED = new DisplayOption(ReviewStatus.Approved, 'Approved');
  static readonly REJECTED = new DisplayOption(ReviewStatus.Rejected, 'Rejected');
}
