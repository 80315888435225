import React, { FC } from 'react';
import { useTheme } from '@mui/material';
import { PATHS, toPath } from '@efacity/routing';

export interface OrgAdminPaymentLinkProps {
  orgId: string;
}

export const OrgAdminPaymentLink: FC<OrgAdminPaymentLinkProps> = ({ orgId }) => {
  const theme = useTheme();
  return (
    <div style={{ marginTop: 10, marginLeft: 3, fontSize: 16, fontWeight: 700 }}>
      <a
        href={`${toPath(PATHS.organizationSettings, { orgId: orgId })}/payments`}
        target="_blank"
        rel="noreferrer"
        style={{ color: theme.palette.primary.main }}
      >
        Connect Payment
      </a>
    </div>
  );
};
