import { CurrencyCode, PaymentPlatformType } from '@efacity/common';
import * as yup from 'yup';
import { PaymentPlatformValues } from '@efacity/common';

export const initialPaymentPlatformFormValues: PaymentPlatformValues = {
  name: '',
  paymentPlatformType: PaymentPlatformType.Stripe,
  stripePlatformAccount: {
    currencyCode: CurrencyCode.USD,
    isEfacity: false,
    stripeSecretKey: '',
    stripePublishableKey: '',
    stripeClientId: ''
  }
};

export const paymentPlatformFormValuesValidationSchema = yup.object().shape({
  name: yup.string().required('Payment Platform name is required'),
  paymentPlatformType: yup.string().required('Platform type is required'),
  stripePlatformAccount: yup.object().shape({
    currencyCode: yup.string().required('Select Currency'),
    isEfacity: yup.boolean().required(),
    stripeSecretKey: yup.string().required('Secret Key is required'),
    stripePublishableKey: yup.string().required('Publishable Key is required'),
    stripeClientId: yup.string()
  })
});

export const organizationTransfersFormValuesValidationSchema = yup.object().shape({
  transfers: yup.array().of(
    yup.object().shape({
      stripeUserId: yup.string().required(),
      name: yup.string().when(['isSelected'], (isSelected, schema) => {
        if (isSelected) {
          return schema.required('Transfer name is required');
        }
      }),
      transferPercentage: yup.number().when(['isSelected'], (isSelected, schema) => {
        if (isSelected) {
          return schema.min(1, 'Must be greater than 0').max(100, 'Must be less or equal than 100');
        }
      }),
      isSelected: yup.boolean().required()
    })
  )
});
