import { Button } from '@mui/material';
import PlusIcon from '@mui/icons-material/Add';

export const AddAdminButton = ({ onClick }) => {
  return (
    <Button
      onClick={onClick()}
      variant="contained"
      color="primary"
      style={{ marginLeft: 'auto' }}
      data-testid="addAdminButton"
    >
      <PlusIcon /> Add Admin
    </Button>
  );
};
