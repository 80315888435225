import React, { useMemo } from 'react';
import { EnhancedColumn } from '../../../components/Table/Table';
import LinkedCentersExpandableList from './LinkedCentersExpandableList';
import { IconButton, Link, Typography } from '@mui/material';
import { Filters } from 'react-table';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import { smallPublishButtonStyle } from '../../../components/Buttons/SessionPublishButton';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import EmailAddress from '../../../components/EmailAddress/EmailAddress';
import { PhoneNumber } from '@efacity/react-next-sc';

const checkIfCenterMatchesFilter = (filters: Filters<any>, centers): boolean => {
  if (filters.length === 0 || centers.length === 0) {
    return false;
  }
  const centersFilter = filters.find((filter) => filter.id === 'centers');
  if (!centersFilter) {
    return false;
  }
  const centerMatch = centers.find((center) => center.name.toLowerCase().includes(centersFilter.value));
  return !!centerMatch;
};

const useCenterAdminsTableColumns = (
  orgId: string,
  hasSchoolAdminAccess: boolean,
  onEditCenterAdminClick,
  onDeleteCenterAdminCLick,
  authenticatedUserId
): EnhancedColumn<any>[] => {
  const centersNameColumn = useMemo(() => {
    return {
      Header: 'Center',
      accessor: 'centers',
      Cell: ({ value: centers, row, state }) => {
        const shouldExpand = checkIfCenterMatchesFilter(state.filters, centers);
        return centers && centers.length > 0 ? (
          <LinkedCentersExpandableList
            userId={row.original._id}
            userFullName={row.original.fullName}
            centers={centers}
            initiallyExpanded={shouldExpand}
            tableState={state}
            onDeleteCenterAdminCLick={onDeleteCenterAdminCLick}
          />
        ) : (
          <Typography>-</Typography>
        );
      }
    };
  }, [onDeleteCenterAdminCLick]);

  const deleteCenterAdminColumn = useMemo(() => {
    return {
      Header: '',
      id: 'actions-cell',
      disableSortBy: true,
      columnStyles: { ...adminSectionTableFontSize, width: 75, maxWidth: 75 },
      Cell: ({ state, row }) => {
        if (row.original._id === authenticatedUserId) return <div />;
        return (
          <IconButton
            color="primary"
            aria-label="delete-instructor"
            data-testid="delete-instructor-btn"
            component="span"
            style={smallPublishButtonStyle}
            onClick={() => onDeleteCenterAdminCLick(orgId, row.original._id, row.original.fullName, state)}
          >
            <DeleteIcon />
          </IconButton>
        );
      }
    };
  }, [orgId, onDeleteCenterAdminCLick, authenticatedUserId]);

  const baseColumns: EnhancedColumn<any>[] = useMemo(() => {
    return [
      {
        Header: 'Full Name',
        accessor: 'fullName',
        Cell: ({ value, row, state }) => {
          return (
            <Link
              component="button"
              align={'left'}
              onClick={() =>
                onEditCenterAdminClick(row.original?.centers?.[0]?._id || '', row.original._id, {
                  filters: state.filters,
                  pageIndex: state.pageIndex,
                  pageSize: state.pageSize,
                  sortBy: state.sortBy
                })
              }
              underline={'none'}
            >
              {value}
            </Link>
          );
        }
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ value }) => {
          return <EmailAddress email={value} />;
        }
      },
      {
        Header: 'Phone',
        accessor: 'defaultPhone',
        Cell: ({ value }) => {
          return <PhoneNumber phone={value} />;
        }
      }
    ];
  }, [onEditCenterAdminClick]);

  return useMemo(() => {
    return hasSchoolAdminAccess ? [...baseColumns, centersNameColumn] : [...baseColumns, deleteCenterAdminColumn];
  }, [hasSchoolAdminAccess, baseColumns, centersNameColumn, deleteCenterAdminColumn]);
};

export default useCenterAdminsTableColumns;
