export enum Roles {
  SiteAdmin = 'siteAdmin',
  SchoolAdmin = 'schoolAdmin',
  CenterAdmin = 'centerAdmin',
  SchoolTeacher = 'schoolTeacher',
  CenterTeacher = 'centerTeacher',
  IndependentTeacher = 'independentTeacher',
  Student = 'student',
  Customer = 'customer'
}

export const rolesViewConnector = {
  [Roles.SiteAdmin]: 'Site Admin',
  [Roles.SchoolAdmin]: 'School Admin',
  [Roles.CenterAdmin]: 'Center Admin',
  [Roles.SchoolTeacher]: 'School Teacher',
  [Roles.CenterTeacher]: 'Center Teacher',
  [Roles.IndependentTeacher]: 'Independent Teacher',
  [Roles.Student]: 'Student',
  [Roles.Customer]: 'Customer'
};

export const rolesSelectOptions = Object.entries(rolesViewConnector).map(([value, label]) => {
  return {
    value,
    label
  };
});

export const hasAnyOfRoles = (allowedRoles: Roles[] = [], userRoles: Roles[] = []) => {
  if (allowedRoles.length > 0 && userRoles.length > 0) {
    return allowedRoles.filter((x) => userRoles.includes(x)).length > 0;
  } else {
    return false;
  }
};

export const hasRole = (allowedRoles: Roles[] = [], userRole: Roles) => {
  if (allowedRoles.length > 0 && userRole) {
    return allowedRoles.includes(userRole);
  } else {
    return false;
  }
};

export const getRolesView = (roles: Roles[]) => {
  return roles.map((role) => rolesViewConnector[role]);
};

export const customerStudentRoles = [Roles.Customer, Roles.Student];
export const adminRoles = [Roles.SchoolAdmin, Roles.CenterAdmin];
export const adminAndIndependentRoles = [Roles.SchoolAdmin, Roles.CenterAdmin, Roles.IndependentTeacher];
export const teacherRoles = [Roles.SchoolTeacher, Roles.CenterTeacher];
export const adminAndTeachersRoles = [Roles.SchoolAdmin, Roles.SchoolTeacher, Roles.CenterAdmin, Roles.CenterTeacher];
export const adminsTeachersAndIndependentRoles = [
  Roles.SchoolAdmin,
  Roles.SchoolTeacher,
  Roles.CenterAdmin,
  Roles.CenterTeacher,
  Roles.IndependentTeacher
];
