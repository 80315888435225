export enum Gender {
  Male = 'male',
  Female = 'female'
}
export const GenderViewConnector = {
  [Gender.Male]: 'Male',
  [Gender.Female]: 'Female'
};
export const genderSelectOptions = Object.entries(GenderViewConnector).map(([value, label]) => {
  return {
    value,
    label
  };
});
