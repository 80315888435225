import React, { FC } from 'react';
import { MenuItem } from '@mui/material';
import { OrganizationIdName } from '@efacity/common';
import { FormTextInput } from './FormTextInput';

interface OrganizationProps {
  name: string;
  required?: boolean;
  organizationOptions: OrganizationIdName[];
  onSelect: (value: string) => void;
  disabled: boolean;
  dataTestId?: string;
}

export const OrganizationSelector: FC<OrganizationProps> = ({
  name,
  required,
  organizationOptions,
  onSelect,
  disabled,
  dataTestId = ''
}) => {
  return (
    <FormTextInput
      name={name}
      label="Organization"
      data-testid={dataTestId}
      required={required}
      select
      onChange={(event) => onSelect(event.target.value)}
      disabled={disabled}
    >
      <MenuItem value="">None</MenuItem>
      {organizationOptions.map((option) => (
        <MenuItem key={option._id} value={option._id}>
          {option.name}
        </MenuItem>
      ))}
    </FormTextInput>
  );
};
