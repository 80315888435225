import { Box } from '@mui/material';
import React from 'react';
import { FormGroupTitle } from '@efacity/frontend-next-shared/forms/server';
import { useAddressFormValues } from '../hooks/useAddressesLoader';
import { AddressForm } from './AddressForm';
import { CountryCode, FormMode } from '@efacity/common';

interface DefaultAddressFormProps {
  pathToFormObject?: string;
  country?: CountryCode;
}

export const DefaultAddressForm: React.FC<DefaultAddressFormProps> = ({ pathToFormObject, country }) => {
  const { addressFormState } = useAddressFormValues(FormMode.Add);

  if (addressFormState.isLoading) {
    /* eslint-disable-next-line react/jsx-no-useless-fragment */
    return <></>;
  }

  return (
    <Box>
      <FormGroupTitle>Default address</FormGroupTitle>
      <AddressForm pathToFormObject={pathToFormObject} country={country} />
    </Box>
  );
};
