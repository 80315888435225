import { adminAndIndependentRoles } from '@efacity/common';
import { hasFrontendAccessWithRoleForOrganization, PageTitle, useAuth } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import PageLinkWithCopier from '../../components/PageLinkWithCopier/PageLinkWithCopier';
import PageNavigationTabs from '../../components/PageNavigatonTabs/PageNavigationTabs';
import { useMatchRoutes } from '../../hooks/useMatchRoutes';

const SessionsListContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const {
    authState: { user }
  } = useAuth();

  const allowHandleSession = hasFrontendAccessWithRoleForOrganization(
    orgId,
    adminAndIndependentRoles,
    user?.adminAccesses || []
  );

  const tabsList = useMemo(() => {
    const baseTabs = [
      {
        label: 'Current',
        link: 'current',
        disabled: false
      },
      {
        label: 'Completed',
        link: 'completed',
        disabled: false
      },
      {
        label: 'Canceled',
        link: 'canceled',
        disabled: false
      }
    ];
    if (allowHandleSession)
      return [
        ...baseTabs,
        { label: 'Tags', link: 'tags', disabled: false },
        { label: 'Additional options', link: 'additionalOptions', disabled: false }
      ];
    return baseTabs;
  }, [allowHandleSession]);

  const isCurrentSessionsTab = useMatchRoutes([PATHS.sessionsCurrent]);

  const displayAddBtn = allowHandleSession && isCurrentSessionsTab;

  return (
    <Box>
      <PageTitle title="Sessions" addButtonLink={displayAddBtn ? toPath(PATHS.addSession, { orgId: orgId }) : null} />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 10 }}>
        <div style={{ marginRight: 15 }}>Landing:</div>
        <PageLinkWithCopier
          name="Main"
          path={toPath(PATHS.orgLanding, { orgId: orgId })}
          tooltipText="Copy Landing page link to share"
        />
        <PageLinkWithCopier
          name="Certification"
          path={toPath(PATHS.instructorCertificationInOrganization, { orgId: orgId })}
          tooltipText="Copy Certification Landing page link to share"
        />
      </div>
      <PageNavigationTabs tabs={tabsList} />
      <Box paddingY={0}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default SessionsListContainer;
