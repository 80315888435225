import React, { FC } from 'react';
import {
  AdditionalSessionOption,
  getStudentsForSession,
  isAdditionalOptionSelectedForStudent,
  moneyWithCurrencyCodeFormatter,
  StudentSessionId,
  StudentSessionOptionId
} from '@efacity/common';
import { Session as EfacitySession } from '@efacity/react-next-sc';
import { RegistrationInfoModal } from '../SelectStudentsForSessions/RegistrationInfoModal/RegistrationInfoModal';
import { WithLoading, FormGroupDivider, isSessionHasAdditionalOptions } from '@efacity/frontend-shared';
import { Box, Button, FormControlLabel, Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlined from '@mui/icons-material/ArrowForwardOutlined';
import { selectStudentsStyles } from '../SelectStudentsForSessions/SelectStudentsToSessionByIndex';
import { RegistrationInfoModalState } from '../PaymentContainer/useInvoicePayment';

interface SelectAdditionalOptionsProps {
  isLoading: boolean;
  isRegistering: boolean;
  sessionsWithAdditionalOptions: EfacitySession[];
  allStudentsForSessionIds: StudentSessionId[];
  studentSessionAdditionalOptionIds: StudentSessionOptionId[];
  selectAdditionalOptionForStudentClicked: any;
  selectStudentsClicked: () => void | null;
  gotoPaymentClicked: () => void;
  isInvoicePaid: boolean;
  registrationInfoModal: RegistrationInfoModalState;
}

const SelectAdditionalSessionOptions: FC<SelectAdditionalOptionsProps> = ({
  isLoading,
  isRegistering,
  sessionsWithAdditionalOptions,
  allStudentsForSessionIds,
  studentSessionAdditionalOptionIds,
  selectAdditionalOptionForStudentClicked,
  selectStudentsClicked,
  gotoPaymentClicked,
  isInvoicePaid,
  registrationInfoModal
}) => {
  return (
    <div>
      <WithLoading isLoading={isLoading} message={'Loading data to start registrations...'}>
        <div style={{ flexGrow: 1 }}>
          <div style={{ margin: 'auto', maxWidth: 800 }}>
            <Box sx={selectStudentsStyles.detailsSx}>Select Additional Options:</Box>
            <FormGroupDivider marginY={15} />
            {sessionsWithAdditionalOptions.map((sessionWithAdditionalOption: EfacitySession) => {
              const sessionHasAdditionalOptions = isSessionHasAdditionalOptions(sessionWithAdditionalOption);
              const studentsForSession = getStudentsForSession(
                sessionWithAdditionalOption._id,
                allStudentsForSessionIds
              );
              const additionalOptionsForSession = sessionWithAdditionalOption.additionalSessionOptions;

              return (
                sessionHasAdditionalOptions &&
                studentsForSession &&
                studentsForSession.length > 0 && (
                  <div key={`sessionSelection${sessionWithAdditionalOption._id}`}>
                    <Box sx={selectStudentsStyles.nameSx} style={{ marginTop: 20, marginBottom: 20 }}>
                      {`${sessionWithAdditionalOption.name}:`}
                    </Box>
                    {additionalOptionsForSession.map((additionalOption: AdditionalSessionOption) => {
                      return (
                        <div
                          key={`additionalSessionOption${sessionWithAdditionalOption._id}ForSession${additionalOption._id}`}
                        >
                          <div>
                            {studentsForSession.map((student: StudentSessionId, index: number) => {
                              return (
                                <div
                                  key={`additionalSessionOption${sessionWithAdditionalOption._id}ForSession${additionalOption._id}ForStudent${student.studentId}`}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={isAdditionalOptionSelectedForStudent(
                                          student.studentId,
                                          sessionWithAdditionalOption._id,
                                          additionalOption._id,
                                          studentSessionAdditionalOptionIds
                                        )}
                                        onClick={(event: any) =>
                                          selectAdditionalOptionForStudentClicked(
                                            sessionWithAdditionalOption._id,
                                            additionalOption._id,
                                            student.studentId,
                                            event.target.checked
                                          )
                                        }
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        data-testid={`additionalOptionCheckBox${index}`}
                                        disabled={isInvoicePaid || isRegistering}
                                      />
                                    }
                                    label={
                                      <div style={{ fontSize: 18, fontWeight: 'bold' }}>
                                        {' '}
                                        {`${additionalOption.name} for ${
                                          student.studentName
                                        }, ${moneyWithCurrencyCodeFormatter(
                                          additionalOption.price,
                                          additionalOption.currencyCode
                                        )}`}
                                      </div>
                                    }
                                    aria-errormessage={'test'}
                                  />
                                </div>
                              );
                            })}
                            <FormGroupDivider marginY={15} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )
              );
            })}
            {isInvoicePaid && (
              <div style={{ fontSize: 20, color: '#f44336', textAlign: 'center', margin: 30 }}>
                This Invoice cannot be edited as it already paid.
              </div>
            )}
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                style={{ textAlign: 'center', width: '100%', marginTop: 15, marginBottom: 15 }}
              >
                {selectStudentsClicked && (
                  <Button
                    onClick={selectStudentsClicked}
                    style={{ width: 220, height: 36 }}
                    startIcon={<ArrowBackOutlined />}
                    color="primary"
                    variant="contained"
                    disabled={isLoading || isRegistering || isInvoicePaid}
                  >
                    Select Student
                  </Button>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                style={{ textAlign: 'center', width: '100%', marginTop: 15, marginBottom: 15 }}
              >
                <Button
                  id="registerAndPayButton"
                  onClick={gotoPaymentClicked}
                  style={{ width: 220, height: 36 }}
                  endIcon={<ArrowForwardOutlined />}
                  color="primary"
                  variant="contained"
                  disabled={isLoading || isRegistering || isInvoicePaid}
                  data-testid="registerAndPayBtn"
                >
                  Register and Pay
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </WithLoading>
      <RegistrationInfoModal registrationInfoModal={registrationInfoModal} />
    </div>
  );
};

export default SelectAdditionalSessionOptions;
