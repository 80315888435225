import { AuthUserResponse } from '@efacity/react-next-sc';
import { PATHS } from '@efacity/routing';
import axios, { AxiosRequestConfig } from 'axios';

// for Next.js to see the variable in the client (browser), it must be prefixed with NEXT_PUBLIC
// Read more on https://nextjs.org/docs/basic-features/environment-variables
// If we are on the client of Next.js, we should redirect all API calls from base '/'
// But if on server of Next.js, we should use the full URL of backend such as http://test-api.efacity.com/

const nextBaseURL = typeof window === 'undefined' ? process.env['NEXT_PUBLIC_API_PATH'] : undefined;
const reactBaseURL = process.env['REACT_APP_API_BASE_URL'];

export const baseURL = nextBaseURL || reactBaseURL || '';
export const rootPath = `${baseURL}/api/`;
export const axiosInstance = axios.create({
  baseURL: `${rootPath}`,
  withCredentials: true
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const signInFullPath = `${window.location.origin}${PATHS.auth}/${PATHS.signIn}`;
    const isSignInPage = window.location.href === signInFullPath;
    if ((error.response.status === 401 || error.response.status === 403) && !isSignInPage) {
      try {
        // https://stackoverflow.com/questions/61224287/how-to-force-axios-to-not-cache-in-get-requests
        const { data } = await apiService.get<AuthUserResponse>(`/users/me?cb=${Date.now()}`);
        const { user } = data;

        if (!user) {
          // Force to reload page should call <AuthChecker /> in <Routing />
          // It stores current location, performs login and redirect back on it
          // eslint-disable-next-line no-self-assign
          window.location.href = window.location.href;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Error in interceptors when get /users/me', e);
      }
    }
    return Promise.reject(error);
  }
);

export class ApiService {
  get<Resp, P = any>(url: string, params?: P, headers?: any, paramsSerializer?: any) {
    return axiosInstance.get<Resp>(url, { params, paramsSerializer, headers });
  }

  patch<R>(url: string, data: any) {
    return axiosInstance.patch<R>(url, data);
  }

  post<R>(url: string, data: any, config?: AxiosRequestConfig<any> | undefined) {
    return axiosInstance.post<R>(url, data, config);
  }

  delete<R>(url: string) {
    return axiosInstance.delete<R>(url);
  }
}

export const apiService = new ApiService();
