import React, { FC, ReactElement } from 'react';
import { Box } from '@mui/material';

interface TableColumnWithIconProps {
  icon: ReactElement;
  children: React.ReactNode;
}

export const TableCellWithIcon: FC<TableColumnWithIconProps> = ({ children, icon }) => {
  return (
    <Box display="flex" alignItems="center" whiteSpace="nowrap">
      <span style={{ marginRight: 5 }}>{icon}</span>
      {children}
    </Box>
  );
};
