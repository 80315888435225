import * as React from 'react';

export const smallButtonHeight = {
  height: 30
};
export const mediumButtonHeight = {
  height: 36
};

export const smallButtonFontSize = {
  fontSize: 12
};
export const mediumButtonFontSize = {
  fontSize: 16
};

export const mediumCircularProgressButtonStyle: React.CSSProperties = {
  width: 60,
  textTransform: 'uppercase',
  marginLeft: 5,
  marginRight: 5,
  ...mediumButtonHeight,
  ...mediumButtonFontSize
};

export const smallCircularProgressButtonStyle: React.CSSProperties = {
  width: 60,
  textTransform: 'uppercase',
  marginLeft: 5,
  marginRight: 5,
  padding: 0,
  ...smallButtonHeight,
  ...smallButtonFontSize
};
