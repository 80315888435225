import { Session } from './Sessions';
import { UserRegistrationData } from './Payments';
import { nowBeforeEndOfDayOfDate } from './DateTime';

export interface StudentOnSessionRegistrationInfo {
  studentId: string | null;
  sessionId: string;
  isRegistered: boolean;
  isSelectedToRegistration: boolean;
}

export interface Registration {
  _id: string;
  invoiceId: string;
  student: UserRegistrationData;
  session: Session;
  status: RegistrationStatus;
}

export enum RegistrationStatus {
  Selected = 'selected',
  Registered = 'registered',
  RegisteredAndPaid = 'registeredAndPaid',
  Unregistered = 'unregistered'
}

export const getStatusText = (status: RegistrationStatus) => {
  const matchers = {
    [RegistrationStatus.Selected]: 'Selected',
    [RegistrationStatus.Registered]: 'Registered',
    [RegistrationStatus.RegisteredAndPaid]: 'Paid',
    [RegistrationStatus.Unregistered]: 'Cancelled'
  };
  return matchers[status] || '';
};

export const checkIsAllowedToCancelRegistrationByCustomer = (
  lastCancellationDate: Date,
  registrationStatus: RegistrationStatus
) => {
  return (
    (nowBeforeEndOfDayOfDate(lastCancellationDate) && registrationStatus === RegistrationStatus.RegisteredAndPaid) ||
    registrationStatus === RegistrationStatus.Selected ||
    registrationStatus === RegistrationStatus.Registered
  );
};

export const checkIsAllowedToPayRegistrationByCustomer = (
  registrationStatus: RegistrationStatus,
  invoiceId: string | null
) => {
  return (
    registrationStatus !== RegistrationStatus.RegisteredAndPaid &&
    registrationStatus !== RegistrationStatus.Unregistered &&
    invoiceId
  );
};

export interface EnrichmentRegistrationFormValues {
  parentName: string;
  studentFirstName: string;
  studentLastName: string;
  studentId: string;
  gradeInSeptemberNumeric: number;
  phone: string;
  phoneCountry: string;
  studentGrade: string;
  email: string;
  relationToStudent: string;
  medicalInformation?: string;
  emergencyInstructions: string;
  releaseOfLiability: boolean;
}
