import React, { FC, useState } from 'react';
import { Button, CircularProgress, Grid } from '@mui/material';
import { CardElement, useElements } from '@stripe/react-stripe-js';
import { CreatePaymentMethodData } from '@stripe/stripe-js';
import { CountryCode, findPaymentCardByPaymentMethodId, PaymentCard } from '@efacity/common';
import { cartPaymentFormValidationSchema } from './CartPaymentFormValidationSchema';
import { FieldError, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PaymentFormElements, CartPaymentFormValues } from '@efacity/frontend-shared';

const submitBtnStyle = {
  marginTop: '15px',
  marginBottom: '15px',
  minWidth: '155px',
  minHeight: '36px'
};

export interface FormActions {
  setFormError: Function;
  setFormLoading: Function;
  setRHFFormErrors: (name: string, error: FieldError, options?: { shouldFocus?: boolean }) => void;
}

export interface PaymentFormProps {
  handleSubmit: (
    paymentInfo: CreatePaymentMethodData,
    userPaymentMethodId: string,
    usedPaymentCard: PaymentCard | null,
    formActions: FormActions
  ) => Promise<void>;
  initialCountry: CountryCode | undefined;
  isStripeLoading: boolean;
  paymentAllowed: boolean;
  showPaymentElements: boolean;
  paymentCards: PaymentCard[];
}

const PaymentForm: FC<PaymentFormProps> = ({
  handleSubmit,
  isStripeLoading,
  paymentAllowed,
  initialCountry,
  showPaymentElements,
  paymentCards
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formError, setFormError] = useState<string | undefined>('');
  const [isFormLoading, setFormLoading] = useState(false);

  const elements = useElements();

  const blankBillingDetails = {
    name: '',
    address: {
      line1: '',
      city: '',
      country: initialCountry || CountryCode.USA,
      state: '',
      postal_code: ''
    },
    userPaymentMethodId: paymentCards && paymentCards.length > 0 ? paymentCards[0].paymentCardId : ''
  };

  const methods = useForm<CartPaymentFormValues>({
    resolver: yupResolver(cartPaymentFormValidationSchema),
    defaultValues: blankBillingDetails,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUseNativeValidation: false,
    mode: 'onTouched'
  });
  const { isValid } = methods.formState;

  const onSubmit = (formValues: CartPaymentFormValues) => {
    if (!showPaymentElements) {
      setFormLoading(true);
      handleSubmit(
        {
          type: 'card',
          card: { token: '' },
          billing_details: blankBillingDetails
        },
        '',
        null,
        {
          setFormError,
          setFormLoading,
          setRHFFormErrors: methods.setError
        }
      );
      return;
    }
    setFormLoading(true);
    if (isStripeLoading || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return setFormError('Loading Stripe...');
    }
    const cardElement = elements?.getElement(CardElement);
    if (!cardElement && !formValues.userPaymentMethodId && !isStripeLoading) {
      setFormLoading(false);
      return setFormError('Unable to process payment');
    }
    const usedCard =
      formValues.userPaymentMethodId && formValues.userPaymentMethodId.length > 0
        ? findPaymentCardByPaymentMethodId(paymentCards, formValues.userPaymentMethodId)
        : null;
    const billingDetails = { ...formValues };
    const userPaymentMethodId = billingDetails.userPaymentMethodId;
    delete billingDetails.userPaymentMethodId;

    handleSubmit(
      {
        type: 'card',
        card: cardElement,
        billing_details: billingDetails
      },
      userPaymentMethodId,
      usedCard,
      {
        setFormError,
        setFormLoading,
        setRHFFormErrors: methods.setError
      }
    );
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        {showPaymentElements && <PaymentFormElements paymentCards={paymentCards} />}
        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={submitBtnStyle}
            variant="contained"
            color="primary"
            disabled={isFormLoading || isStripeLoading || (!isValid && showPaymentElements) || !paymentAllowed}
            data-testid="submitBtn"
            onClick={() => onSubmit(methods.getValues())}
          >
            {isFormLoading ? (
              <CircularProgress color="primary" size={20} />
            ) : showPaymentElements ? (
              `Submit payment`
            ) : (
              'Submit'
            )}
          </Button>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default PaymentForm;
