import React from 'react';

const mobileTableStyle = {
  rootSx: {
    borderBottom: `1px solid`,
    borderTop: `1px solid`,
    borderColor: 'primary.dark',
    '&:first-student': {
      borderTop: `1px solid`,
      borderColor: 'primary.dark'
    }
  },
  row: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    padding: '10px'
  },
  invoiceItemRow: {
    display: 'grid',
    gridTemplateColumns: '1.6fr 1fr 1fr',
    padding: '10px'
  },
  additionalOptionItemRow: {
    display: 'grid',
    gridTemplateColumns: '2.4fr 0.6fr 0.4fr',
    padding: '0px'
  },
  mobileTwoColumnRow: {
    display: 'grid',
    gridTemplateColumns: '2.4fr 0.9fr',
    padding: '10px'
  },
  column: {
    //  marginRight: theme.spacing(2)
    backgroundColor: 'red'
  },
  divider: {
    //  border: `1px solid ${theme.palette.primary.dark}`
    backgroundColor: 'red'
  },
  titleSx: {
    color: 'primary.dark',
    fontSize: '14px'
  },
  sessionNameRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)'
  },
  textSm: {
    fontSize: '16px'
  },
  deleteIcon: { textAlign: 'end', display: 'flex', justifyContent: 'flex-end' } as React.CSSProperties,
  linkCellSx: {
    color: 'primary.main',
    '&:hover': {
      cursor: 'pointer'
    }
  }
};

export default mobileTableStyle;
