import { LinearProgress, Typography } from '@mui/material';

export const LinearProgressWithMessage = ({ message }) => {
  return (
    <div style={{ margin: 30, display: 'block' }} key={`${message}key`}>
      <Typography color="primary" style={{ fontSize: 18, textAlign: 'center', margin: 30, display: 'block' }}>
        {message}
      </Typography>
      <div style={{ width: '50%', margin: 'auto' }}>
        <LinearProgress variant="indeterminate" color="primary" data-testid="loadingIndicator" />
      </div>
    </div>
  );
};
