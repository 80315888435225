import { Activity, FormMode, Roles } from '@efacity/common';
import { hasFrontendAccessWithRoleForOrganization, PageTitle, useAuth } from '@efacity/react-next-sc';
import { Box, Skeleton } from '@mui/material';
import React from 'react';
import { useFetch, WithLoading } from '@efacity/frontend-shared';
import { Route, Routes, useParams } from 'react-router-dom';
import { IdOrgIdParamTypes } from '@efacity/frontend-next-shared/utils';
import ActivityRegistrationLinkWithCopier from '../../../components/ActivityRegistrationLinkWithCopier/ActivityRegistrationLinkWithCopier';
import PageNavigationTabs from '../../../components/PageNavigatonTabs/PageNavigationTabs';
import { useTypedLocation } from '../../../hooks/useTypedLocation';
import ActivityFormContainer from '../ActivityForm/ActivityFormContainer';
import { ActivityView } from '../ActivityView/ActivityView';
import ActivityCertificatesTable from './ActivityCertificatesTable';

const getPageTitle = (
  allowHandleActivity: boolean,
  mode: FormMode,
  activityName: string,
  isUserIndependentTeacher: boolean
) => {
  if (!allowHandleActivity) {
    if (isUserIndependentTeacher) return 'View Course';
    return 'View Activity';
  }

  if (mode === FormMode.Add) {
    if (isUserIndependentTeacher) return 'Add Course';
    return 'Add Activity';
  }

  return activityName;
};

export interface ActivityWithCertificatesContainerProps {
  mode: FormMode;
}

const ActivityWithCertificatesContainer: React.FC<ActivityWithCertificatesContainerProps> = ({ mode }) => {
  const { orgId, id: activityId } = useParams<IdOrgIdParamTypes>();
  const location = useTypedLocation<{ isCloning: boolean; activityId: string }>();
  const workingActivityId = activityId || location.state?.activityId;
  const activityTab = {
    label: 'main',
    link: '',
    end: true
  };
  const certificatesTab = {
    label: 'certificates',
    link: 'certificates'
  };

  const tabsList = mode === FormMode.Edit ? [activityTab, certificatesTab] : [activityTab];

  const {
    authState: {
      user,
      extraAuthInfo: {
        center: { allowedCreateActivity }
      }
    }
  } = useAuth();
  const isUserIndependentTeacher = hasFrontendAccessWithRoleForOrganization(
    orgId,
    [Roles.IndependentTeacher],
    user.adminAccesses || []
  );
  const [{ data: activity, isLoading: isLoadingActivity }] = useFetch<Activity>(
    `/org/${orgId}/activity/${workingActivityId}`,
    {
      initialDataState: null,
      errorMessage: 'Could not fetch activity',
      shouldFetch: !!workingActivityId
    }
  );

  const isCloningActivity = location.state?.isCloning;
  const allowHandleActivity = allowedCreateActivity && (activity?.owner?.orgId === orgId || mode === FormMode.Add);
  const showNavigationTabs = allowHandleActivity && !isUserIndependentTeacher;

  let linkCopier;
  if (isLoadingActivity) {
    linkCopier = <Skeleton width={110} height={36} variant="rectangular" data-testid="actionBtnLoader" />;
  } else {
    linkCopier = allowHandleActivity && (
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <ActivityRegistrationLinkWithCopier
          isEnabled={!!activityId}
          activityId={activityId}
          orgId={orgId}
          activityType={activity?.type}
        />
      </Box>
    );
  }

  const title = getPageTitle(allowHandleActivity, mode, activity?.name, isUserIndependentTeacher);

  if (!allowHandleActivity)
    return (
      <WithLoading isLoading={isLoadingActivity} message={'Loading activity data...'} style={{ width: '25%' }}>
        <ActivityView activity={activity} />
      </WithLoading>
    );

  return (
    <Box>
      <PageTitle title={title} underlined button={linkCopier} />

      {showNavigationTabs && <PageNavigationTabs tabs={tabsList} />}

      <Routes>
        <Route
          path="/"
          element={
            <ActivityFormContainer
              mode={mode}
              activity={activity}
              isLoadingActivity={isLoadingActivity}
              allowHandleActivity={allowHandleActivity}
              isCloningActivity={isCloningActivity}
            />
          }
        />
        <Route path="certificates" element={<ActivityCertificatesTable activityName={activity?.name || ''} />} />
      </Routes>
    </Box>
  );
};

export default ActivityWithCertificatesContainer;
