export const tableContainerSx = {
  border: `1px solid`,
  borderColor: 'primary.dark',
  marginBottom: '15px',
  marginTop: '15px'
};

export const tableHeadSx = {
  height: 2,
  borderBottom: `1px solid`,
  borderColor: 'primary.dark'
};

export const tableHeadDividerSx = {
  height: 1,
  margin: 0,
  padding: 0,
  borderTop: `1px solid`,
  borderBottom: `0px solid`,
  borderColor: 'primary.dark'
};

export const tableHeadCellSx = {
  color: 'primary.dark',
  fontWeight: 'bold',
  paddingLeft: '8px',
  paddingRight: '8px'
};

export const cellSx = {
  borderBottom: 'unset',
  fontSize: '16px',
  paddingLeft: '8px',
  paddingRight: '8px'
};

export const totalCellSx = {
  fontSize: 18,
  color: 'primary.main',
  fontWeight: 'bold',
  borderBottom: 'none',
  paddingTop: '1px',
  paddingBottom: '1px'
};

export const strippedTableRowSx = {
  height: 41,
  '&:nth-of-type(odd)': {
    backgroundColor: 'action.hover'
  },
  '&:not(:last-child)': {
    borderBottom: '1px solid primary.dark'
  }
};
