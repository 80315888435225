import React from 'react';
import { Button, CircularProgress, Dialog, DialogContent, DialogProps, Typography } from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Check';
import { HorizontalField } from '../HorizontalField/HorizontalField';

const confirmationModalStyles = {
  button: {
    width: '150px',
    height: '32px',
    textTransform: 'uppercase',
    fontSize: 14
  } as React.CSSProperties,
  nameContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexGrow: 1,
    marginRight: 0
  }
};

export interface ConfirmationModalProps extends DialogProps {
  open: boolean;
  isOperating: boolean;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  isOperating,
  message,
  onClose,
  onConfirm
}) => {
  return (
    <Dialog open={open} maxWidth="xs" fullWidth data-testid="confirmation-modal" disableEscapeKeyDown>
      <DialogContent>
        <Typography variant={'h6'} color="error">
          <div
            style={{ marginTop: 10, marginBottom: 20, fontSize: 18 }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </Typography>
        <hr style={{ height: 2, marginBottom: 15 }} />
        <HorizontalField justifyContent="center" wrapOnSmallScreen={false} style={{ padding: 9, width: '100%' }}>
          <Button
            style={confirmationModalStyles.button}
            onClick={onClose}
            color="primary"
            variant="outlined"
            disabled={isOperating}
            aria-label="Cancel"
          >
            <CancelOutlined style={{ color: 'primary', marginRight: 10 }} />
            Cancel
          </Button>
          <Button
            style={confirmationModalStyles.button}
            color="primary"
            variant="contained"
            onClick={onConfirm}
            disabled={isOperating}
            data-testid="confirmConflictButton"
            aria-label="Confirm"
          >
            {isOperating ? (
              <CircularProgress variant="indeterminate" size={20} color={'primary'} data-testid="progress-in-button" />
            ) : (
              <>
                <SaveIcon style={{ marginRight: 10 }} />
                Confirm
              </>
            )}
          </Button>
        </HorizontalField>
      </DialogContent>
    </Dialog>
  );
};
