import { ActivityTypes, OwnerInterface } from '@efacity/common';
import { CopyLinkFlatIcon } from '@efacity/frontend-shared';
import { LightTooltip } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import { IconButton, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import React from 'react';

export interface SessionRegistrationLinkWithCopierProps {
  isEnabled: boolean;
  sessionId: string;
  owner: OwnerInterface | null;
  type: ActivityTypes;
  activityId: string;
  locationId: string;
}

const SessionRegistrationLinkWithCopier: React.FC<SessionRegistrationLinkWithCopierProps> = ({
  isEnabled,
  sessionId,
  owner,
  type,
  activityId,
  locationId
}) => {
  const handleCopyRegistrationClick = (sessionId: string, owner: OwnerInterface) => {
    const registrationPageLink =
      type === ActivityTypes.Enrichment
        ? `/org/${owner.orgId._id}/enrichment/${activityId}/registration?location=${locationId}`
        : toPath(PATHS.sessionRegistration, { sessionId: sessionId });
    copy(`${window.location.origin}${registrationPageLink}`);
  };

  return isEnabled && owner ? (
    <LightTooltip title={<Typography>Copy Session Link to share</Typography>}>
      <span>
        <div style={{ display: 'flex', marginRight: 5 }} data-testid="registration-link-with-copier">
          [
          <IconButton
            color="primary"
            aria-label="copy registration link"
            component="span"
            style={{ marginTop: -4, padding: 5 }}
            onClick={() => handleCopyRegistrationClick(sessionId, owner)}
          >
            <CopyLinkFlatIcon fontSize="small" />
          </IconButton>
          ]
        </div>
      </span>
    </LightTooltip>
  ) : null;
};

export default SessionRegistrationLinkWithCopier;
