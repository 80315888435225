import { type Instance } from './interfaces/Session';
import {
  monthDayYearDateFormat,
  getDateInLocalTimeZone,
  getSessionEndDate,
  getWeekDayNames,
  isIntlSupported,
  mapSessionInstancesToTimestampsInTimeZone,
  type ScheduleInstance
} from '@efacity/common';
import { format } from 'date-fns';

export const mapSessionInstancesToTimestamps = (instancesList: Instance[]) => {
  return instancesList.map((instance) => new Date(instance.startDateTime).getTime());
};

export const getInstancesEndDateInUserTimeZone = (instances: ScheduleInstance[] = []) => {
  let timeZone = '';
  if (isIntlSupported) {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  if (instances?.length > 0) {
    const sessionEndDate = getSessionEndDate(mapSessionInstancesToTimestampsInTimeZone(instances, timeZone));
    return format(sessionEndDate, monthDayYearDateFormat);
  }
  return '';
};

export const getDaysOfWeekFromInstances = (instances: Instance[]) => {
  const instancesTimeStamps = instances
    .map((instance) => new Date(instance.startDateTime).getTime())
    .sort(function (a, b) {
      return a - b;
    });

  return getWeekDayNames(instancesTimeStamps);
};

export const getSessionEndDateInLocalTimeZone = (instances: Instance[] = []) => {
  if (instances.length > 0) {
    return getDateInLocalTimeZone(getSessionEndDate(mapSessionInstancesToTimestamps(instances)));
  }
  return 'N/A';
};
