import React from 'react';
import { IconButton } from '@mui/material';
import ImagePreview from '../ImagePreview/ImagePreview';
import { Media, thumbMediaHeight, thumbMediaWidth, isImage } from '@efacity/common';
import { VideoPreview } from '@efacity/react-next-sc';
import CloseIcon from '@mui/icons-material/Close';

const deleteBtnStyle = (theme) => ({
  color: theme.palette.grey[500]
});

export interface MediaListProps {
  media: Media[];
  onDeleteMedia: (index: number) => void;
}

export const MediaList: React.FC<MediaListProps> = ({ media, onDeleteMedia }) => {
  return media?.length > 0 ? (
    <div
      style={{
        marginTop: 0,
        marginBottom: 5,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}
    >
      {media.map((mediaItem, index) => (
        <div key={`mediaListKey${index}`} style={{ padding: 10 }}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <IconButton
              aria-label="delete-btn"
              onClick={() => onDeleteMedia(index)}
              sx={(theme) => deleteBtnStyle(theme)}
              style={{ marginRight: -20 }}
              data-testid={`delete-btn-${index}`}
            >
              <CloseIcon />
            </IconButton>
          </div>
          {isImage(mediaItem.link) || !!mediaItem.blob ? (
            <ImagePreview
              src={mediaItem.link}
              imageWidth={thumbMediaWidth}
              imageHeight={thumbMediaHeight}
              index={index}
            />
          ) : (
            <VideoPreview
              src={mediaItem.link}
              frameWidth={thumbMediaWidth}
              frameHeight={thumbMediaHeight}
              index={index}
            />
          )}
        </div>
      ))}
    </div>
  ) : (
    <div
      style={{
        fontSize: 18,
        fontWeight: 500,
        display: 'flex',
        justifyContent: 'center',
        fontStyle: 'italic',
        width: '100%'
      }}
    >
      No selected media
    </div>
  );
};
