import { PATHS } from '@efacity/routing';
import type { AuthState, AuthenticatedUserInfo } from '../../interfaces/Auth';
import { AuthStatus } from '../../interfaces/Auth';
import { CountryCode } from '@efacity/common';

export const emptyAddress = {
  address: '',
  country: '',
  city: '',
  zip: '',
  state: ''
};
export const emptyDOB = {
  day: '',
  month: '',
  year: ''
};

export const nonAuthenticatedUserInfo = {
  fullName: '',
  callByName: '',
  email: '',
  defaultPhone: '',
  defaultPhoneCountry: CountryCode.USA,
  roles: [],
  schoolId: '',
  _id: '',
  googleId: '',
  dateOfBirth: emptyDOB,
  address: emptyAddress,
  access: [],
  adminAccesses: []
} as AuthenticatedUserInfo;

export const emptyExtraAuthInfo = {
  shoppingCart: null,
  startRegistrationURL: PATHS.landing,
  center: {
    allowedCreateActivity: false
  },
  message: ''
};

export const initialAuthState: AuthState = {
  isAuthenticated: AuthStatus.Checking,
  user: nonAuthenticatedUserInfo,
  extraAuthInfo: emptyExtraAuthInfo,
  workingAccessId: undefined,
  workingOrganizationName: undefined,
  loading: false,
  isQueryingAccessUrl: false,
  isLogout: false
};
