import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogProps, Typography } from '@mui/material';
import { apiService } from '@efacity/frontend-shared';
import { useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import ContentLoadingIndicator from './ContentLoadingIndicator';
import StudentContactInfo from './StudentContactInfo';
import { Messages, StudentContactInfoResponse } from '@efacity/common';
import { useSnackbar } from 'notistack';
import { ModalDialogTitle } from '@efacity/react-next-sc';

export interface StudentContactInfoModalProps extends DialogProps {
  studentId: string;
  onClose: () => void;
}

const emptyStudentWithCustomers = {
  student: null,
  customers: []
};

const StudentContactInfoModal: React.FC<StudentContactInfoModalProps> = ({ open, onClose, studentId }) => {
  const { orgId } = useParams<OrgIdParamType>();
  const [studentWitCustomers, setStudentWitCustomers] = useState<StudentContactInfoResponse>(emptyStudentWithCustomers);
  const [error, setError] = useState(false);
  const [loadingState, setLoadingState] = useState('initial');
  const snackbarProps = useSnackbar();

  useEffect(() => {
    const getStudentInfo = async () => {
      setLoadingState('loading');
      try {
        const { data } = await apiService.get<StudentContactInfoResponse>(
          `org/${orgId}/student/${studentId}/contact-info`
        );
        setStudentWitCustomers(data);
        setLoadingState('loaded');
      } catch (error) {
        setError(error?.response?.message || Messages.CannotGetAttendeeContactInfo);
        setLoadingState('error');
      }
    };
    getStudentInfo();
  }, [snackbarProps, orgId, studentId]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <ModalDialogTitle text="Contact info" onClose={onClose} />
      <DialogContent>
        {loadingState === 'loaded' && studentWitCustomers && (
          <StudentContactInfo studentWithCustomers={studentWitCustomers} />
        )}
        {['loading', 'initial'].includes(loadingState) && <ContentLoadingIndicator />}
        {loadingState === 'error' && error && <Typography color="error">{error}</Typography>}
      </DialogContent>
    </Dialog>
  );
};

export default StudentContactInfoModal;
