//TODO duplicate of apps/frontend-next/components/styles/styles.ts

// add as const typescript signature
// https://stackoverflow.com/questions/46710747/type-string-is-not-assignable-to-type-inherit-initial-unset-fixe
export const publicSchoolContainer = {
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center'
} as const;

export const mainContentSx = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: `calc(100vh - 120px)`,
  width: { xs: '100%', md: '90%' },
  maxWidth: '1200px',
  marginTop: '80px',
  marginBottom: '40px',
  paddingLeft: '5px',
  paddingRight: '5px'
};

export const submitButtonStyle = {
  width: 171,
  height: 36,
  textTransform: 'uppercase',
  marginTop: 5,
  marginBottom: 5
} as const;

export const releaseOfLiability = { color: 'primary.dark', fontSize: 16, fontFamily: 'Roboto', fontWeight: 500 };
