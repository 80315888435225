import { MutableRefObject, useEffect } from 'react';

export const useBodyScrollLock = (
  shouldBlockScroll: boolean,
  elementToLock?: MutableRefObject<any>
) => {
  useEffect(() => {
    const lockElementStyle = 'overflow: hidden';
    const element = elementToLock?.current || document.body;

    if (shouldBlockScroll) {
      // Prevent scrolling
      element.style = lockElementStyle;
    }
    // Get original body overflow/MainLayout.spec.tsx
    const originalStyle = 'auto';
    // Re-enable scrolling when component unmounts
    return () => {
      element.style.overflow = originalStyle;
    };
  }, [elementToLock, shouldBlockScroll]); // Empty array ensures effect is only run on mount and unmount
};
