import React, { FC } from 'react';
import { Box } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { NoImagePreview } from '@efacity/react-next-sc';

export interface ImagePreviewProps {
  src: string | null | undefined;
  imageWidth: number;
  imageHeight: number;
  index?: number | string;
  onClick?: (link: string) => void;
  showPlayIcon?: boolean;
  isSelected?: boolean;
  borderWidth?: number;
}

export const ImagePreview: FC<ImagePreviewProps> = ({
  src,
  imageHeight,
  imageWidth,
  index = 0,
  onClick = null,
  showPlayIcon = false,
  isSelected = false
}) => {
  const onImageClick = (src: string) => {
    if (onClick) {
      onClick(src);
    }
  };

  return src && src.length > 0 ? (
    <Box
      sx={(theme) => ({
        position: 'relative',
        maxHeight: imageHeight,
        maxWidth: imageWidth,
        padding: '1px',
        margin: 0,
        border: isSelected ? `2px solid ${theme.palette.primary.light}` : ''
      })}
    >
      {showPlayIcon && (
        <PlayCircleIcon
          color={'primary'}
          fontSize={'large'}
          style={{ position: 'absolute', right: imageWidth - 40, bottom: imageHeight - 40 }}
        />
      )}
      <img
        src={src}
        alt={`media-preview-${index}`}
        style={{ objectFit: 'contain', width: '100%' }}
        data-testid={`media-preview-${index}`}
        onClick={() => onImageClick(src)}
      />
    </Box>
  ) : (
    <NoImagePreview imageHeight={imageHeight} imageWidth={imageWidth} />
  );
};

export default ImagePreview;
