import React, { FC } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent } from '@mui/material';
import { ModalDialogTitle } from '@efacity/react-next-sc';

const mobileModalViewSizesStyle = {
  width: '100%',
  maxWidth: 600
};
export interface PaymentDoneModalState {
  isOpen: boolean;
  isError: boolean;
  message: string;
  onClose: any;
  title?: string;
}
export const paymentDoneModalInitialState: PaymentDoneModalState = {
  isOpen: false,
  isError: false,
  message: '',
  onClose: null,
  title: 'Payment'
};

export interface PaymentDoneModalProps {
  paymentDoneModalState: PaymentDoneModalState;
}
const paymentDoneModalStyles = {
  root: {
    margin: 0,
    fontSize: 20
  }
};

export const PaymentDoneModal: FC<PaymentDoneModalProps> = ({ paymentDoneModalState }) => {
  const { isOpen, message, isError, onClose } = paymentDoneModalState;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={(event, reason) => {
        // disableBackdropClick has been deprecated
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      disableEscapeKeyDown
      fullWidth
      maxWidth={'xs'}
    >
      <div style={mobileModalViewSizesStyle}>
        <ModalDialogTitle text={paymentDoneModalState?.title || 'Payment'} isError={isError} showUnderline={false} />
        <DialogContent dividers>
          <div style={paymentDoneModalStyles.root}>
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
