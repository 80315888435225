import { applicationStatusSelectOptions } from '@efacity/common';
import { MenuItem, TextField } from '@mui/material';
import { FilterProps, Renderer } from 'react-table';

export const EnrollmentApplicationStatusFilter: Renderer<FilterProps<any>> = ({ column }) => {
  const { filterValue, setFilter } = column;

  const handleValueChange = (e) => {
    const { value } = e.target;
    setFilter(() => (value ? value : undefined));
  };

  return (
    <TextField
      select
      type="text"
      variant="outlined"
      color="primary"
      margin="dense"
      fullWidth
      onChange={handleValueChange}
      value={filterValue || ''}
      sx={{ m: 0 }}
      inputProps={{ sx: { padding: '0' } }}
      SelectProps={{ sx: { padding: 0.62, fontSize: '14px' } }}
    >
      <MenuItem key={'none'} value="">
        Any
      </MenuItem>
      {Object.values(applicationStatusSelectOptions).map((locationTypeOption) => (
        <MenuItem key={locationTypeOption.value} value={locationTypeOption.value}>
          {locationTypeOption.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
