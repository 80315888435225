import {
  type AdminAccess,
  adminAndIndependentRoles,
  adminRoles,
  adminsTeachersAndIndependentRoles,
  hasAnyOfRoles,
  hasRole,
  Roles
} from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import type { ReactElement } from 'react';
import { MenuItemType } from './interfaces/LeftNavigation';

export interface PlainNavigationItemConfig {
  type: MenuItemType.ITEM;
  label: string;
  baseLink: string;
  visibleFor: Roles[];
  addButtonBaseLink?: string;
  addButtonLabel?: string;
  matchPaths?: string[];
  shouldCheckAccessId?: boolean;
  addButtonDisabled?: boolean;
}

export interface NavigationItemWithRenderConfig<T = Record<string, unknown>> {
  type: MenuItemType.ITEM;
  visibleFor: Roles[];
  props?: T;
  renderNavigationItem: (props: T, onMenuItemClick: () => void) => ReactElement;
}

export interface NavigationItemDivider {
  type: MenuItemType.DIVIDER;
  visibleFor: Roles[];
}

export type NavigationItemConfig = PlainNavigationItemConfig | NavigationItemWithRenderConfig | NavigationItemDivider;

export interface PlainNavigationItem {
  type: MenuItemType.ITEM;
  label: string;
  link: string;
  matchPaths?: string[];
  addButtonLink?: string;
  addButtonLabel?: string;
  addButtonDisabled?: boolean;
  props?: never; // https://github.com/microsoft/TypeScript/issues/12815
  renderNavigationItem?: never;
}

export interface NavigationItemWithRender {
  type: MenuItemType.ITEM;
  label?: never;
  link?: never;
  matchPaths?: never[];
  addButtonLink?: never;
  addButtonDisabled?: never;
  props?: NavigationItemWithRenderConfig['props'];
  renderNavigationItem: NavigationItemWithRenderConfig['renderNavigationItem'];
}

export type NavigationItem = PlainNavigationItem | NavigationItemWithRender | NavigationItemDivider;

// https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
const isNavigationItemConfigWithRender = (
  navigationItemConfig: NavigationItemConfig
): navigationItemConfig is NavigationItemWithRenderConfig => {
  return (navigationItemConfig as NavigationItemWithRenderConfig).renderNavigationItem !== undefined;
};

const isPlainNavigationItemConfig = (
  navigationItemConfig: NavigationItemConfig
): navigationItemConfig is PlainNavigationItemConfig => {
  return (navigationItemConfig as NavigationItemWithRenderConfig).renderNavigationItem === undefined;
};

export const filterNavigationItemByUserAccess = (
  navigationItemConfig: NavigationItemConfig,
  workingAccessId: string,
  access: AdminAccess[] = [],
  roles: Roles[] = []
) => {
  if (isPlainNavigationItemConfig(navigationItemConfig) && navigationItemConfig.shouldCheckAccessId) {
    return hasFrontendAccessWithRoleForOrganization(workingAccessId, navigationItemConfig.visibleFor, access);
  } else {
    return hasAnyOfRoles(navigationItemConfig.visibleFor, roles);
  }
};

export const hasFrontendAccessWithRoleForOrganization = (
  orgId: string,
  allowedRoles: Roles[] = [],
  accesses: AdminAccess[] = []
): boolean => {
  if (allowedRoles.length > 0 && accesses.length > 0) {
    const foundAccess = accesses.find((access: AdminAccess) => {
      return checkIfFrontendAccessForOrganization(access, orgId) && hasRole(allowedRoles, access.role);
    });
    return !!foundAccess;
  } else {
    return false;
  }
};

const checkIfFrontendAccessForOrganization = (access: AdminAccess, orgId: string) => {
  return access.orgId === orgId;
};

export const isPlainNavigationItem = (navigationItem: NavigationItem): navigationItem is PlainNavigationItem => {
  return navigationItem.type === 'item' && !navigationItem.renderNavigationItem;
};
export const mapNavigationItemConfigToNavigationItem = (
  navigationItemConfig: NavigationItemConfig,
  workingAccessId: string
) => {
  if (isNavigationItemConfigWithRender(navigationItemConfig) && navigationItemConfig.renderNavigationItem) {
    return {
      props: navigationItemConfig.props,
      renderNavigationItem: navigationItemConfig.renderNavigationItem
    } as NavigationItemWithRender;
  }

  if (
    isPlainNavigationItemConfig(navigationItemConfig) &&
    navigationItemConfig.shouldCheckAccessId &&
    workingAccessId
  ) {
    return {
      type: navigationItemConfig.type,
      label: navigationItemConfig.label,
      matchPaths: navigationItemConfig.matchPaths,
      link: toPath(navigationItemConfig.baseLink, { orgId: workingAccessId }),
      addButtonLink:
        navigationItemConfig.addButtonBaseLink &&
        toPath(navigationItemConfig.addButtonBaseLink, { orgId: workingAccessId }),
      addButtonDisabled: navigationItemConfig.addButtonDisabled,
      addButtonLabel: navigationItemConfig.addButtonLabel
    };
  }

  if (isPlainNavigationItemConfig(navigationItemConfig)) {
    return {
      type: navigationItemConfig.type,
      label: navigationItemConfig.label,
      matchPaths: navigationItemConfig.matchPaths,
      link: navigationItemConfig.baseLink,
      addButtonLink: navigationItemConfig.addButtonBaseLink && navigationItemConfig.addButtonBaseLink,
      addButtonLabel: navigationItemConfig.addButtonLabel
    };
  }
  return undefined;
};

export const getNavigationItems = (
  navigationItemsConfig: NavigationItemConfig[],
  workingAccessId: string,
  access?: AdminAccess[],
  roles?: Roles[]
): NavigationItem[] => {
  // filter navigation items depending on allowed roles
  // map filtered items
  return navigationItemsConfig
    .filter((navigationItemConfig) =>
      filterNavigationItemByUserAccess(navigationItemConfig, workingAccessId, access, roles)
    )
    .map((navigationItemConfig) => mapNavigationItemConfigToNavigationItem(navigationItemConfig, workingAccessId))
    .filter((navigationItem) => !!navigationItem) as NavigationItem[];
};

export const getNavigationItemsBase = ({
  allowedCreateSession,
  allowedCreateActivity,
  enrollmentRequired,
  userId
}: {
  allowedCreateSession: boolean;
  allowedCreateActivity: boolean;
  enrollmentRequired: boolean;
  userId?: string;
}): NavigationItemConfig[] => {
  const DIVIDER_ITEM: NavigationItemDivider = {
    type: MenuItemType.DIVIDER,
    visibleFor: adminAndIndependentRoles
  };
  const ENROLLMENT_ITEM: PlainNavigationItemConfig = {
    type: MenuItemType.ITEM,
    label: 'Enrollments',
    baseLink: PATHS.studentEnrollments,
    visibleFor: adminAndIndependentRoles,
    shouldCheckAccessId: true,
    matchPaths: [PATHS.organizationStudents]
  };
  const navigationItemsArray: NavigationItemConfig[] = [
    {
      type: MenuItemType.ITEM,
      label: 'Sessions',
      baseLink: PATHS.sessions,
      addButtonBaseLink: PATHS.addSession,
      visibleFor: adminsTeachersAndIndependentRoles,
      shouldCheckAccessId: true,
      addButtonDisabled: !allowedCreateSession,
      matchPaths: [PATHS.session, PATHS.sessions, PATHS.addSession, PATHS.sessionStudents]
    },
    {
      type: MenuItemType.ITEM,
      label: 'Attendance',
      baseLink: PATHS.attendance,
      visibleFor: adminsTeachersAndIndependentRoles,
      shouldCheckAccessId: true
    },
    {
      type: MenuItemType.ITEM,
      label: 'Registrations',
      baseLink: PATHS.registrationsForAdmin,
      visibleFor: adminAndIndependentRoles,
      shouldCheckAccessId: true,
      matchPaths: [PATHS.registrationsForAdmin]
    },
    {
      type: MenuItemType.ITEM,
      label: 'Payments',
      baseLink: PATHS.payments,
      visibleFor: adminAndIndependentRoles,
      shouldCheckAccessId: true,
      matchPaths: [PATHS.payments]
    },
    DIVIDER_ITEM,
    {
      type: MenuItemType.ITEM,
      label: 'Courses',
      baseLink: PATHS.courses,
      addButtonBaseLink: PATHS.addActivity,
      visibleFor: adminAndIndependentRoles,
      shouldCheckAccessId: true,
      addButtonDisabled: !allowedCreateActivity,
      matchPaths: [PATHS.courses, PATHS.addActivity, PATHS.editActivity]
    },
    {
      type: MenuItemType.ITEM,
      label: 'Activities',
      baseLink: PATHS.activities,
      addButtonBaseLink: PATHS.addActivity,
      visibleFor: adminRoles,
      shouldCheckAccessId: true,
      addButtonDisabled: !allowedCreateActivity,
      matchPaths: [PATHS.activities, PATHS.addActivity, PATHS.editActivity]
    },
    {
      type: MenuItemType.ITEM,
      label: 'Discounts',
      baseLink: PATHS.discounts,
      addButtonBaseLink: PATHS.addDiscount,
      visibleFor: adminAndIndependentRoles,
      shouldCheckAccessId: true,
      matchPaths: [PATHS.discounts, PATHS.addDiscount, PATHS.editDiscount]
    },
    DIVIDER_ITEM,
    {
      type: MenuItemType.ITEM,
      label: 'Products',
      baseLink: PATHS.products,
      addButtonBaseLink: PATHS.addProduct,
      visibleFor: adminAndIndependentRoles,
      shouldCheckAccessId: true,
      matchPaths: [PATHS.products, PATHS.addProduct, PATHS.editProduct]
    },
    {
      type: MenuItemType.ITEM,
      label: 'Orders',
      baseLink: PATHS.productPurchases,
      visibleFor: adminAndIndependentRoles,
      shouldCheckAccessId: true,
      matchPaths: [PATHS.productPurchases]
    },
    {
      type: MenuItemType.DIVIDER,
      visibleFor: adminAndIndependentRoles
    },
    {
      type: MenuItemType.ITEM,
      label: 'My Sessions',
      baseLink: userId ? toPath(PATHS.studentSessions, { id: userId }) : PATHS.studentSessions,
      visibleFor: [Roles.Student]
    },
    {
      type: MenuItemType.ITEM,
      label: 'Users',
      baseLink: PATHS.siteUsers,
      addButtonBaseLink: PATHS.siteAdminAddUser,
      addButtonLabel: 'Add a user',
      visibleFor: [Roles.SiteAdmin],
      shouldCheckAccessId: false,
      matchPaths: [PATHS.siteUsers, PATHS.siteAdminAddUser, PATHS.siteAdminEditUser]
    },
    {
      type: MenuItemType.ITEM,
      label: 'Customers',
      baseLink: PATHS.organizationCustomers,
      visibleFor: adminAndIndependentRoles,
      shouldCheckAccessId: true,
      matchPaths: [PATHS.organizationCustomers]
    },
    {
      type: MenuItemType.ITEM,
      label: 'Customer Credits',
      baseLink: PATHS.organizationCredits,
      visibleFor: adminAndIndependentRoles,
      shouldCheckAccessId: true,
      matchPaths: [PATHS.organizationCredits]
    },
    {
      type: MenuItemType.ITEM,
      label: 'Students',
      baseLink: PATHS.organizationStudents,
      visibleFor: adminAndIndependentRoles,
      shouldCheckAccessId: true,
      matchPaths: [PATHS.organizationStudents]
    },
    DIVIDER_ITEM,
    {
      type: MenuItemType.ITEM,
      label: 'Job Posting',
      baseLink: PATHS.jobs,
      addButtonBaseLink: PATHS.addJob,
      visibleFor: adminRoles,
      shouldCheckAccessId: true,
      matchPaths: [PATHS.jobs, PATHS.addJob, PATHS.editJob]
    },
    DIVIDER_ITEM,
    {
      type: MenuItemType.ITEM,
      label: 'Centers',
      baseLink: PATHS.centers,
      addButtonBaseLink: PATHS.addCenter,
      visibleFor: [Roles.SchoolAdmin],
      shouldCheckAccessId: true,
      matchPaths: [PATHS.centers, PATHS.addCenter, PATHS.editCenter]
    },
    {
      type: MenuItemType.ITEM,
      label: 'Instructors',
      baseLink: PATHS.instructors,
      addButtonBaseLink: PATHS.addInstructor,
      visibleFor: adminRoles,
      shouldCheckAccessId: true,
      addButtonDisabled: !allowedCreateActivity,
      matchPaths: [PATHS.instructors, PATHS.addInstructor, PATHS.editInstructor]
    },
    {
      type: MenuItemType.ITEM,
      label: 'Certificates',
      baseLink: PATHS.certificates,
      visibleFor: adminRoles,
      shouldCheckAccessId: true,
      matchPaths: [PATHS.certificates]
    },
    {
      type: MenuItemType.ITEM,
      label: 'Messages',
      baseLink: PATHS.messages,
      visibleFor: adminAndIndependentRoles,
      shouldCheckAccessId: true,
      matchPaths: [PATHS.messages]
    },
    {
      type: MenuItemType.ITEM,
      label: 'Organizations',
      baseLink: PATHS.schools,
      addButtonBaseLink: PATHS.addSchool,
      addButtonLabel: 'Add an organization',
      visibleFor: [Roles.SiteAdmin],
      matchPaths: [PATHS.schools, PATHS.addSchool]
    },
    {
      type: MenuItemType.ITEM,
      label: 'Organization Settings',
      baseLink: PATHS.organizationSettings,
      visibleFor: adminAndIndependentRoles,
      shouldCheckAccessId: true,
      matchPaths: [PATHS.organizationSettings]
    },
    {
      type: MenuItemType.ITEM,
      label: 'Payment Platforms',
      baseLink: PATHS.paymentPlatforms,
      addButtonBaseLink: PATHS.addPaymentPlatform,
      addButtonLabel: 'Add a payment platform',
      visibleFor: [Roles.SiteAdmin],
      shouldCheckAccessId: false,
      matchPaths: [PATHS.paymentPlatforms, PATHS.addPaymentPlatform, PATHS.editPaymentPlatform]
    },
    {
      type: MenuItemType.ITEM,
      label: 'Login Audit',
      baseLink: PATHS.loginAudit,
      visibleFor: [Roles.SiteAdmin],
      shouldCheckAccessId: false,
      matchPaths: [PATHS.loginAudit]
    },
    {
      type: MenuItemType.ITEM,
      label: 'Reviews',
      baseLink: PATHS.reviews,
      visibleFor: [Roles.SiteAdmin],
      shouldCheckAccessId: false,
      matchPaths: [PATHS.reviews]
    }
  ];
  if (enrollmentRequired) navigationItemsArray.splice(17, 0, ENROLLMENT_ITEM);
  return navigationItemsArray;
};
