import { AdditionalSessionOption } from './AdditionalSessionOption';
import { ScheduleInstance } from './Sessions';
import { Student } from './Student';
import { ActivityTypes } from './Activities';
import { PATHS, toPath } from '@efacity/routing';
import { Location } from './Location';

export interface Attendance {
  _id: string;
  orgId: string;
  timeZone: string;
  sessionId: string;
  type: ActivityTypes;
  timeOfDay?: string;
  instanceId: string;
  instance: ScheduleInstance;
  locationId: string;
  location: Location;
  studentId: string;
  customerId: string | null;
  status: AttendanceStatus;
  signInOn?: string;
  signInSignature?: string;
  signInBy?: string;
  signInByName?: string;
  signOutOn?: Date;
  signOutSignature?: string;
  signOutBy?: string;
  signOutByName?: string;
  deletedAt?: string;
  sessionName: string;
  studentFullName: string;
  instructorId: string;
  instructorFullName: string;
  secondaryInstructorId?: string;
  secondaryInstructorFullName?: string;
}

export enum AttendanceStatus {
  Absent = 'absent',
  Present = 'present',
  Registered = 'registered',
  Unregistered = 'unregistered',
  NotSpecified = 'notSpecified',
  Expired = 'expired'
}

export interface AttendanceFilters {
  fromDate: any;
  toDate: any;
}

export const attendanceOptions = [
  {
    value: AttendanceStatus.Present,
    label: 'Present'
  },
  {
    value: AttendanceStatus.Absent,
    label: 'Absent'
  },
  {
    value: 'all',
    label: 'All'
  }
];

export interface AttendanceExtended extends Attendance {
  additionalOptions: Partial<AdditionalSessionOption>[];
  student: Partial<Student>;
}

export enum AttendanceSigningType {
  SignIn = 'signIn',
  SignOut = 'signOut'
}

export interface PublicAttendanceInterface {
  hash?: string;
  type?: ActivityTypes;
  locationId?: string;
  sessionId?: string;
}

export interface PublicAttendanceFilters {
  type?: ActivityTypes;
  locationId?: string;
  sessionId?: string;
  fromDate: Date | string;
  toDate: Date | string;
}

export const getPublicAttendanceRedirect = (orgId: string, hash: string): string => {
  return toPath(PATHS.publicAttendance, {
    orgId: orgId,
    hash: hash
  });
};
