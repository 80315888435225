import {
  AddLinkMediumButton,
  adminSectionTableFontSize,
  apiService,
  ConfirmationModal,
  CircularProgressButton
} from '@efacity/frontend-shared';
import { LightTooltip, PageTitle } from '@efacity/react-next-sc';
import React, { useMemo, useState } from 'react';
import { Messages, Roles, nowUTC } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { PhoneNumber } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { IconButton, Link, Typography } from '@mui/material';
import { isBefore } from 'date-fns';
import { useSnackbar } from 'notistack';
import { NavLink, useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import EmailAddress from '../../components/EmailAddress/EmailAddress';
import Table, { EnhancedColumn, FetchDataOptions, PaginationTypes } from '../../components/Table/Table';
import AddInstructorLinkCopier from './AddInstructorLinkCopier';
import { useInstructorsLoader } from './useInstructorsLoader';

const InstructorsContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const snackbarProps = useSnackbar();
  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    onConfirm: null
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);
  const [isActivating, setIsActivating] = useState(false);

  const onDeleteCLick = (userId: string, fetchDataOptions: FetchDataOptions) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        '<div style="display:flex;justify-content:center">You are about to delete Instructor.</div><br/>' +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      onConfirm: () => deleteInstructor(userId, fetchDataOptions)
    });
  };

  const activateInstructor = async (userId: string, fetchDataOptions: FetchDataOptions) => {
    setIsActivating(true);

    try {
      await apiService.patch<{ message: string }>(`/users/${orgId}/teacher/${userId}/activate`, {});
      setIsActivating(false);
      getInstructors(fetchDataOptions);
      showNotification(snackbarProps, true, Messages.InstructorActivated, false);
      setConfirmationModalState(defaultConfirmationModalState);
    } catch {
      setIsActivating(false);
      showNotification(snackbarProps, false, Messages.FailedActivateInstructor, true);
      setConfirmationModalState(defaultConfirmationModalState);
    }
  };

  const deleteInstructor = async (userId: string, fetchDataOptions: FetchDataOptions) => {
    setConfirmationModalState((state) => {
      return { ...state, isOperating: true };
    });
    try {
      await apiService.delete<{ message: string }>(`/users/${orgId}/teacher/${userId}`);
      getInstructors(fetchDataOptions);
      showNotification(snackbarProps, true, Messages.InstructorDeleted, false);
      setConfirmationModalState(defaultConfirmationModalState);
    } catch {
      showNotification(snackbarProps, false, Messages.FailedDeleteInstructor, true);
      setConfirmationModalState(defaultConfirmationModalState);
    }
  };

  const {
    instructorsState: { instructors, total, isLoading },
    getInstructors
  } = useInstructorsLoader(`/org/${orgId}/users/teachers`);

  const columns = useMemo((): EnhancedColumn<any>[] => {
    return [
      {
        Header: 'Full Name',
        accessor: 'fullName',
        columnStyles: {
          verticalAlign: 'top',
          paddingTop: 10
        },
        Cell: ({ row }) => {
          return (
            <Link
              component={NavLink}
              to={toPath(PATHS.editInstructor, { orgId: orgId, id: row.original._id })}
              underline={'none'}
            >
              {row.original.fullName}
            </Link>
          );
        }
      },
      {
        Header: 'Phone',
        accessor: 'defaultPhone',
        columnStyles: {
          verticalAlign: 'top',
          paddingTop: 10
        },
        Cell: ({ value }) => {
          return <PhoneNumber phone={value} />;
        }
      },
      {
        Header: 'Email',
        accessor: 'email',
        columnStyles: {
          verticalAlign: 'top',
          paddingTop: 10
        },
        Cell: ({ value }) => {
          return <EmailAddress email={value} />;
        }
      },
      {
        Header: '',
        id: 'actions-cell',
        disableSortBy: true,
        columnStyles: { ...adminSectionTableFontSize, width: 80, maxWidth: 80 },
        Cell: ({ state, row }) => {
          const organizationAccess = row.original?.access?.find(
            (item) =>
              item.organization === orgId && (item.role === Roles.SchoolTeacher || item.role === Roles.CenterTeacher)
          );
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} data-testid="actions-cell">
              {!organizationAccess?.isActive && (
                <LightTooltip title={<Typography>Activate Instructor</Typography>}>
                  <span>
                    <div style={{ display: 'flex', marginRight: 5 }} data-testid="registration-link-with-copier">
                      <CircularProgressButton
                        progress={isActivating}
                        color="primary"
                        onClick={() => activateInstructor(row.original._id, state)}
                        style={{ ...smallPublishButtonStyle, width: 80 }}
                        data-testid="activate-instructor-btn"
                      >
                        Activate
                      </CircularProgressButton>
                    </div>
                  </span>
                </LightTooltip>
              )}
              <IconButton
                color="primary"
                aria-label="delete-instructor"
                data-testid="delete-instructor-btn"
                component="span"
                style={smallPublishButtonStyle}
                disabled={isBefore(new Date(row.original.expiresOn), new Date(nowUTC()))}
                onClick={() => onDeleteCLick(row.original._id, state)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          );
        }
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  return (
    <div>
      <PageTitle
        title="Instructors"
        addButtonLink={null}
        button={
          <div style={{ display: 'flex' }}>
            <AddInstructorLinkCopier orgId={orgId} />
            <AddLinkMediumButton link={toPath(PATHS.addInstructor, { orgId: orgId })} data-testid="pageTitleBtn" />
          </div>
        }
      />
      <Table
        loading={isLoading}
        columns={columns}
        data={instructors}
        pageCount={total}
        fetchData={getInstructors}
        paginationType={PaginationTypes.ServerSide}
      />
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState(defaultConfirmationModalState)}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
    </div>
  );
};

export default InstructorsContainer;
