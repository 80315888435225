import { Roles } from '@efacity/common';

export function setRegistrationProgress(isRegistering = false) {
  if (isRegistering) {
    localStorage.setItem('userRole', Roles.Customer);
    localStorage.setItem('isSessionRegistrationInProgress', '1');
  } else {
    localStorage.removeItem('userRole');
    localStorage.removeItem('isSessionRegistrationInProgress');
  }
}
export function setKitPurchaseProgress(isPurchasing = false) {
  if (isPurchasing) {
    localStorage.setItem('userRole', Roles.Customer);
    localStorage.setItem('isIsKitPurchaseInProgress', '1');
  } else {
    localStorage.removeItem('userRole');
    localStorage.removeItem('isIsKitPurchaseInProgress');
  }
}

export function getShoppingProgress() {
  return {
    sessionRegistrationInProgress: !!localStorage.getItem('isSessionRegistrationInProgress'),
    kitPurchaseInProgress: !!localStorage.getItem('isIsKitPurchaseInProgress'),
    role: localStorage.getItem('userRole')
  };
}
