import React, { FC } from 'react';
import { MenuItem } from '@mui/material';
import { JobOpenPositionsSelectOptions } from '@efacity/common';
import { FormInputTextProps, FormTextInput } from './FormTextInput';

export const JobPostingOpenPositionsSelector: FC<FormInputTextProps> = ({ name, ...props }) => {
  return (
    <FormTextInput name={name} label="Open Positions" select {...props}>
      {JobOpenPositionsSelectOptions.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </FormTextInput>
  );
};
