import { CenterAdminForSchool } from '@efacity/common';
import { Button } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import CenterNameWithDeleteButton from './CenterNameWithDeleteButton';

export interface LinkedCentersExpandableListProps {
  userId: string;
  userFullName: string;
  centers: CenterAdminForSchool['centers'];
  numberOfVisibleItems?: number;
  initiallyExpanded?: boolean;
  tableState: any;
  onDeleteCenterAdminCLick;
}

const showMoreBtnStyle = {
  textTransform: 'none',
  padding: 0
} as React.CSSProperties;

const LinkedCentersExpandableList: FC<LinkedCentersExpandableListProps> = ({
  userId,
  userFullName,
  centers,
  numberOfVisibleItems = 3,
  initiallyExpanded = false,
  tableState,
  onDeleteCenterAdminCLick
}) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(initiallyExpanded);
  }, [initiallyExpanded]);

  const onToggleListExpansion = () => {
    setOpen(!isOpen);
  };

  return (
    <div>
      {centers.slice(0, numberOfVisibleItems).map((center, index) => (
        <CenterNameWithDeleteButton
          userId={userId}
          userFullName={userFullName}
          centerId={center._id}
          centerName={center.name}
          tableState={tableState}
          onDeleteCenterAdminCLick={onDeleteCenterAdminCLick}
          key={`LinkedCentersExpandableList${center._id}_index${index}`}
        />
      ))}
      {isOpen &&
        centers
          .slice(numberOfVisibleItems)
          .map((center, index) => (
            <CenterNameWithDeleteButton
              userId={userId}
              userFullName={userFullName}
              centerId={center._id}
              centerName={center.name}
              tableState={tableState}
              onDeleteCenterAdminCLick={onDeleteCenterAdminCLick}
              key={`LinkedCentersExpandableList${center._id}_index${index}_2`}
            />
          ))}
      {centers.length > numberOfVisibleItems && (
        <Button
          color="inherit"
          size="small"
          variant="outlined"
          style={showMoreBtnStyle}
          onClick={onToggleListExpansion}
        >
          {isOpen ? 'Show less...' : 'Show more...'}
        </Button>
      )}
    </div>
  );
};

export default LinkedCentersExpandableList;
