import { apiService } from '@efacity/frontend-shared';
import { useCallback, useState } from 'react';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { useSnackbar } from 'notistack';
import { Messages, ReviewInfo, ReviewStatus } from '@efacity/common';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';
import { FetchDataOptions, initialFetchDataOptions } from '../../components/Table/Table';
import removeDateOffsetFromFilters from '../../utils/removeDateOffsetFromFilters';

export interface ReviewsResponse {
  data: ReviewInfo[];
  total?: number;
}

export interface ReviewsState {
  reviews: ReviewInfo[];
  total: number;
  isLoading: boolean;
  processingReviewId: string | null;
}

export const useSiteAdminReviewsLoader = (url: string) => {
  const [reviewsState, setReviewsState] = useState<ReviewsState>({
    reviews: [],
    total: 0,
    isLoading: true,
    processingReviewId: null
  });
  const snackbarProps = useSnackbar();
  const [fetchDataOptions, setFetchDataOptions] = useState<FetchDataOptions>(initialFetchDataOptions);

  const getReviews = useCallback(
    ({ pageIndex, pageSize, filters, sortBy }: FetchDataOptions) => {
      const setReviews = ({ data, total }: ReviewsResponse, isLoading: boolean) => {
        setReviewsState((prevState) => ({ ...prevState, reviews: data, total: total, isLoading: isLoading }));
      };

      const showErrorNotification = (message: string) => {
        setReviews({ data: [], total: 0 }, false);
        showNotification(snackbarProps, false, message, true);
      };

      setReviewsState((prevState) => ({ ...prevState, isLoading: true }));

      apiService
        .get<ReviewsResponse>(url, {
          page: pageIndex,
          perPage: pageSize,
          ...getFiltersQueryParameters(filters),
          sortBy: mapReactTableSortToApiSort(sortBy)
        })
        .then(
          (result) => {
            return setReviews(result.data, false);
          },
          (error) => {
            setReviewsState((reviewsState) => ({
              ...reviewsState,
              isLoading: false
            }));
            showErrorNotification((error.response?.data?.message as string) || Messages.FailedGetReviews);
          }
        );
    },
    [snackbarProps, url]
  );

  const setFiltersAndGetReviews = useCallback(
    async ({ pageIndex, pageSize, filters, sortBy }: FetchDataOptions) => {
      const dateFilters = ['reviewedOn'];
      removeDateOffsetFromFilters(filters, dateFilters);
      setFetchDataOptions({ pageIndex, pageSize, filters, sortBy });
      getReviews({ pageIndex, pageSize, filters, sortBy });
    },
    [getReviews]
  );

  const changeReviewStatus = async (reviewId: string, newStatus: ReviewStatus) => {
    const patchURL = `/reviews/${reviewId}/status`;
    setReviewsState((prevState) => ({ ...prevState, processingReviewId: reviewId }));
    try {
      const { data } = await apiService.patch<{ status: ReviewStatus }>(patchURL, { status: newStatus });
      setReviewsState((prevState) => {
        const updatedReviews = prevState.reviews.map((review) => {
          if (review._id === reviewId) {
            review.status = data.status;
          }
          return review;
        });
        return { ...prevState, reviews: updatedReviews, processingReviewId: null };
      });
    } catch (errorResponse) {
      showNotification(snackbarProps, false, errorResponse.message || Messages.FailedUpdateReviewStatus, true);
    }
    setReviewsState((prevState) => ({ ...prevState, processingReviewId: null }));
  };

  const approveAllPendingReviews = async () => {
    const patchURL = `/reviews/approve`;
    try {
      await apiService.patch(patchURL, {});
    } catch (errorResponse) {
      showNotification(snackbarProps, false, errorResponse.message || Messages.FailedUpdateReviewStatus, true);
    }
    getReviews(fetchDataOptions);
  };

  return { reviewsState, setFiltersAndGetReviews, changeReviewStatus, approveAllPendingReviews };
};
