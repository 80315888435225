import RocketIcon from '@mui/icons-material/RocketLaunch';
import { LightTooltip } from './LightTooltip';

export const BranchTooltip = ({ branchName, isTestMode }: { branchName: string | undefined; isTestMode: boolean }) => {
  if (!isTestMode) return null;

  return (
    <LightTooltip disableFocusListener title={branchName} style={{ marginLeft: '10px', marginRight: '10px' }}>
      <RocketIcon color="primary" />
    </LightTooltip>
  );
};
