import type { User } from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import { CircularProgress, MenuItem } from '@mui/material';
import type { MouseEvent } from 'react';
import React from 'react';
import { useStudentsList } from './useStudentsList';

interface StudentsNavigationItemProps {
  userId: string;
  onNavigateToPage: (path: string) => (e: MouseEvent) => Promise<void>;
}

const StudentsNavigationItems: React.FC<StudentsNavigationItemProps> = ({ userId, onNavigateToPage }) => {
  const { data: studentsList, isLoading } = useStudentsList(userId);

  return (
    <div>
      {isLoading ? (
        <MenuItem data-testid="logoutBtn" style={{ height: 36, display: 'flex', justifyContent: 'space-between' }}>
          <div>Loading students...</div>
          <CircularProgress size={18} style={{ marginRight: 3 }} />
        </MenuItem>
      ) : studentsList?.length > 0 ? (
        studentsList.map((student: User) => (
          <MenuItem
            onClick={onNavigateToPage(toPath(PATHS.editStudent, { studentId: student._id }))}
            data-testid="logoutBtn"
            style={{ height: 36, display: 'flex', justifyContent: 'space-between' }}
            key={`studentNavigationItem${student._id}`}
          >
            <div style={{ marginLeft: 20 }}>{student.fullName}</div>
          </MenuItem>
        ))
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default StudentsNavigationItems;
