import * as yup from 'yup';
import {
  CountryCode,
  getCountryByCode,
  getFieldIsRequiredMessage,
  getStateOptionsDependingOnCountry,
  Messages
} from '@efacity/common';

export const cartPaymentFormValidationSchema = yup.object().shape({
  name: yup.string().when('userPaymentMethodId', {
    is: (userPaymentMethodId) => !(userPaymentMethodId && userPaymentMethodId.length > 0),
    then: yup.string().required('This is a required field')
  }),
  address: yup.object().when('userPaymentMethodId', {
    is: (userPaymentMethodId) => !(userPaymentMethodId && userPaymentMethodId.length > 0),
    then: yup.object().shape({
      line1: yup.string().when('country', {
        is: (country) => country !== CountryCode.USA,
        then: yup.string().required('This is a required field')
      }),
      city: yup.string().when('country', {
        is: (country) => country !== CountryCode.USA,
        then: yup.string().required('This is a required field')
      }),
      country: yup.string().required('This is a required field'),
      state: yup.mixed().test('address', null, function (this: yup.TestContext, fieldValue: any): any {
        const allValuesEmpty = Object.values(this.parent).every((value) => !value);
        if (allValuesEmpty) {
          return true;
        }

        // UK does not have states
        if (this.parent.country === CountryCode.UK) return true;

        //For the USA, state is not required
        if (this.parent.country === CountryCode.USA) return true;

        if (!fieldValue) {
          const stateLabel = getCountryByCode(this.parent.country).formTitles['state'] || 'State';
          return this.createError({ path: this.path, message: getFieldIsRequiredMessage(stateLabel) });
        }

        const stateOptions = getStateOptionsDependingOnCountry(this.parent.country) || [];
        const isStateValueInOptions =
          fieldValue === '' || stateOptions.some((stateOption) => stateOption.value === fieldValue);

        if (!isStateValueInOptions) {
          return this.createError({ path: this.path, message: Messages.StateInvalid });
        } else {
          return true;
        }
      }),
      postal_code: yup.string().required('This is a required field')
    })
  })
});
