import { set } from 'date-fns';
import { convertUtcToZonedTime } from '@efacity/common';
import addMinutes from 'date-fns/addMinutes';

export interface SessionFormInstance {
  _id?: string;
  isUpdated?: boolean;
  isDeleted?: boolean;
  startDateTime: Date;
  endDateTime: Date;
  duration: Duration;
}

export interface SessionScheduleFormValues {
  startDateTime: Date;
  endDateTime: Date;
  numberOfInstances: number;
  duration: Duration;
  daysOfWeek: number[];
  instances: SessionFormInstance[];
  deadlineForCustomerRegistration: Date;
  lastCancellationDate: Date;
}

export const defaultSessionScheduleFormValues = (timeZone: string): SessionScheduleFormValues => {
  const initialDate = set(convertUtcToZonedTime(new Date(), timeZone), { hours: 8, minutes: 0 });

  return {
    startDateTime: initialDate,
    endDateTime: addMinutes(initialDate, 60),
    numberOfInstances: 1,
    duration: {
      hours: 1,
      minutes: 0
    },
    daysOfWeek: [initialDate.getDay()],
    instances: [],
    deadlineForCustomerRegistration: initialDate,
    lastCancellationDate: initialDate
  };
};
