import { FormTextInput } from '@efacity/react-hook-form-mui';
import { EmailFormValues } from '@efacity/react-next-sc';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { CircularProgressButton } from '@efacity/frontend-shared';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Email Adress is invalid').required('Email Adress is required')
});

interface EmailFormProps {
  onSendResetPasswordEmail: (values: EmailFormValues) => void;
  initialEmailValue: string | undefined;
}

export const EmailForm: FC<EmailFormProps> = ({ onSendResetPasswordEmail, initialEmailValue }) => {
  const methods = useForm<EmailFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: { email: initialEmailValue }
  });
  const { isValid, isSubmitting } = methods.formState;

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSendResetPasswordEmail)}>
        <FormTextInput id="email" name="email" type="email" label="Email Address" />
        <Box display={'flex'} justifyContent={'center'} style={{ marginTop: 25 }}>
          <CircularProgressButton progress={isSubmitting} disabled={!isValid || isSubmitting} color="primary">
            Send reset Instructions
          </CircularProgressButton>
        </Box>
      </form>
    </FormProvider>
  );
};
