import React from 'react';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { useParams } from 'react-router-dom';
import { useProductPurchases } from './useProductPurchases';
import Table, { PaginationTypes } from '../../components/Table/Table';
import { useProductPurchasesColumns } from './useProductPurchasesColumns';
import { Row } from 'react-table';
import { UserProductPurchasesExtended } from '../CustomerProductPurchases/useCustomerProductPurchases';
import { Box } from '@mui/material';
import SmallInvoiceDataTable from '../../components/SmallInvoiceDataTable/SmallInvoiceDataTable';
import { Messages } from '@efacity/common';
import { PageTitle } from '@efacity/react-next-sc';

const ProductPurchasesContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const { productPurchasesState, getProductPurchases, changeStatus } = useProductPurchases(orgId);
  const columns = useProductPurchasesColumns(
    orgId,
    productPurchasesState.timeZone,
    changeStatus,
    productPurchasesState.processingPurchaseId
  );

  const renderInvoiceBalanceTable = (row: Row<UserProductPurchasesExtended>) => {
    return (
      <td colSpan={columns.length - 1}>
        <Box margin={1}>
          <SmallInvoiceDataTable
            invoiceBalance={row.original?.invoiceBalance}
            message={Messages.NoInvoiceForPurchase}
          />
        </Box>
      </td>
    );
  };

  return (
    <div>
      <PageTitle title="Product Purchases" />
      <Table
        columns={columns}
        fetchData={getProductPurchases}
        data={productPurchasesState.productPurchases}
        pageCount={productPurchasesState.total}
        loading={productPurchasesState.isLoading}
        paginationType={PaginationTypes.ServerSide}
        renderRowSubComponent={renderInvoiceBalanceTable}
        noDataText="No purchased products..."
      />
    </div>
  );
};

export default ProductPurchasesContainer;
