export enum Custody {
  Yes = 'yes',
  No = 'no'
}
export const CustodyViewConnector = {
  [Custody.Yes]: 'Yes',
  [Custody.No]: 'No'
};
export const custodySelectOptions = Object.entries(CustodyViewConnector).map(([value, label]) => {
  return {
    value,
    label
  };
});
