import React from 'react';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import Table, { PaginationTypes } from '../../components/Table/Table';
import { useParams } from 'react-router-dom';
import { InstanceTemporality } from '@efacity/common';
import useTagsTableColumns from './useTagsTableColumns';
import { useTagsLoader } from '../../hooks/useTagsLoader';

export interface TagsTableProps {
  temporality: InstanceTemporality;
}

const TagsTable: React.FC<TagsTableProps> = ({ temporality }) => {
  const { orgId } = useParams<OrgIdParamType>();
  const [{ tagsState }, { getTags }] = useTagsLoader(`/org/${orgId}/tags/${temporality}`);
  const columns = useTagsTableColumns(orgId, []);

  return (
    <Table
      columns={columns}
      data={tagsState.tags}
      loading={tagsState.isLoading}
      pageCount={tagsState.total}
      fetchData={getTags}
      paginationType={PaginationTypes.ServerSide}
      noDataText={`No ${temporality} tags...`}
    />
  );
};

export default TagsTable;
