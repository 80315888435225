import { MobileDatePicker } from '@mui/x-date-pickers';
import React, { FC, useRef } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { monthDayYearDateFormat } from '@efacity/common';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface CommonDatePickerProps {
  value: Date | string | undefined;
  onChange?: any;
  id?: string;
  name?: string;
  dataTestId?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}

export const CommonDatePicker: FC<CommonDatePickerProps> = ({
  value,
  onChange,
  id = '',
  name = '',
  dataTestId = '',
  label = '',
  error = false,
  helperText = '',
  disabled = false
}) => {
  const dateInputRef = useRef<HTMLInputElement>(null);
  const handleIconClicked = () => {
    dateInputRef.current?.click();
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'start' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          data-testid={dataTestId}
          format={monthDayYearDateFormat}
          value={value}
          onChange={onChange}
          closeOnSelect
          inputRef={dateInputRef}
          disabled={disabled}
          slotProps={{
            textField: {
              variant: 'outlined',
              fullWidth: true,
              size: 'small',
              id,
              name,
              label,
              error,
              helperText,
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="select date" size="small" onClick={handleIconClicked} edge="end">
                      <CalendarMonthIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            }
          }}
        />
      </LocalizationProvider>
    </div>
  );
};
