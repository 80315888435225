import * as yup from 'yup';
import {
  AgeStudentLevelTypeValue,
  DurationType,
  isGradeNumericValue,
  LocationType,
  Messages,
  nonNegativeAmountValidator,
  SchoolGradeStudentLevelTypeValue,
  studentLevelTypeValues,
  ValidationErrors
} from '@efacity/common';

export const isValidStudentLevelType: yup.TestFunction = (studentLevelType: string): boolean => {
  return !!studentLevelTypeValues.find((type) => type === studentLevelType);
};

export const sessionMainInfoValidator = (amountOfExistingRegistrations: number) => {
  return yup.object().shape({
    activityId: yup.string().nullable(),
    name: yup.string().required(Messages.NameRequired),
    locationId: yup
      .string()
      .nullable()
      .test('locationId', null, function (this: yup.TestContext): any {
        if (this.parent?.durationType === DurationType.selfPaced) {
          return this.schema.isType(null);
        }
        return this.schema.min(1, Messages.LocationRequired).required(Messages.LocationRequired);
      }),
    inPerson: yup.object().when('location', {
      is: (value) => value === LocationType.InPerson,
      then: yup.object().shape({
        room: yup.string().required(Messages.InPersonRoomRequired)
      }),
      otherwise: yup.object()
    }),
    studentLevel: yup.object().shape({
      type: yup.string().test('type', ValidationErrors.InvalidStudentLevelType, isValidStudentLevelType),
      fromSchoolGradeNumeric: yup
        .mixed()
        .required(ValidationErrors.InvalidGrade)
        .test('fromSchoolGradeNumeric', null, function (this: yup.TestContext, fromSchoolGradeNumeric: any): any {
          if (this.parent?.type === SchoolGradeStudentLevelTypeValue) {
            if (isGradeNumericValue(fromSchoolGradeNumeric)) {
              return true;
            }
            return this.createError({
              path: this.path,
              message: ValidationErrors.InvalidGrade
            });
          }
          return true;
        }),
      toSchoolGradeNumeric: yup
        .mixed()
        .test('toSchoolGradeNumeric', null, function (this: yup.TestContext, toSchoolGradeNumeric: any): any {
          if (this.parent?.type === SchoolGradeStudentLevelTypeValue) {
            if (isGradeNumericValue(toSchoolGradeNumeric)) {
              return true;
            }
            return this.createError({
              path: this.path,
              message: ValidationErrors.InvalidGrade
            });
          }
          return true;
        }),
      fromAge: yup.mixed().test('fromAge', null, function (this: yup.TestContext, fromAge: any): any {
        if (this.parent?.type && this.parent.type === AgeStudentLevelTypeValue) {
          if (fromAge > 0) {
            return true;
          }
          return this.createError({
            path: 'studentLevel.fromAge',
            message: ValidationErrors.InvalidAge
          });
        }
        return true;
      }),
      toAge: yup.mixed().test('toAge', null, function (this: yup.TestContext, toAge: any): any {
        if (this.parent?.type && this.parent.type === AgeStudentLevelTypeValue) {
          if (toAge > 0) {
            return true;
          }
          return this.createError({
            path: 'studentLevel.toAge',
            message: ValidationErrors.InvalidAge
          });
        }
        return true;
      })
    }),
    teacherId: yup.string(),
    secondaryTeacherId: yup.string().optional(),
    price: yup.string().test('test-name', 'Non-negative real expected', nonNegativeAmountValidator),
    currency: yup.string().required('Select currency'),
    capacity: yup
      .number()
      .required(Messages.SessionCapacityRequired)
      .min(1, Messages.MinCapacityError)
      .test(
        'capacity_to_existed_registration_validation',
        `Capacity cannot be less than registrations amount (${amountOfExistingRegistrations})`,
        function (value) {
          return amountOfExistingRegistrations ? value >= amountOfExistingRegistrations : true;
        }
      )
  });
};
