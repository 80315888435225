export enum SchoolType {
  PublicSchool = 'publicSchoolType',
  PrivateSchool = 'privateSchoolType',
  HomeSchool = 'homeSchoolType',
  Other = 'other'
}
export const SchoolTypeViewConnector = {
  [SchoolType.PublicSchool]: 'Public School',
  [SchoolType.PrivateSchool]: 'Private School',
  [SchoolType.HomeSchool]: 'Home School',
  [SchoolType.Other]: 'Other'
};
export const schoolTypeSelectOptions = Object.entries(SchoolTypeViewConnector).map(([value, label]) => {
  return {
    value,
    label
  };
});
