export enum ApplicationStatus {
  Draft = 'draft',
  Submitted = 'submitted',
  UnderReview = 'underReview',
  ChangesRequested = 'changesRequested',
  Approved = 'approved'
}
export const ApplicationStatusConnector = {
  [ApplicationStatus.Draft]: 'Draft',
  [ApplicationStatus.Submitted]: 'Submitted',
  [ApplicationStatus.UnderReview]: 'Under review',
  [ApplicationStatus.ChangesRequested]: 'Additional information requested',
  [ApplicationStatus.Approved]: 'Approved'
};
export const applicationStatusSelectOptions = Object.entries(ApplicationStatusConnector).map(([value, label]) => {
  return {
    value,
    label
  };
});
