'use client';
import React from 'react';
import Link from 'next/link';
import { sharedFormSx } from '@efacity/frontend-next-shared/forms/server';
import { FormTextInput, FormPhoneInput } from '@efacity/react-hook-form-mui';
import { Box, Grid } from '@mui/material';
import { FormGroupDivider } from '@efacity/frontend-shared';

export interface UserProfileProps {
  allowChangeEmail?: boolean;
  allowHandleDOB?: boolean;
  showPasswordLink?: boolean;
  showPasswordDivider?: boolean;
}

export const UserProfile: React.FC<UserProfileProps> = ({
  allowChangeEmail = true,
  showPasswordLink = true,
  showPasswordDivider = true
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} data-testid="updateUserProfileForm">
        <FormTextInput label="Email (used for login)" name="email" disabled={!allowChangeEmail} />
      </Grid>
      <Grid item xs={12} data-testid="updateUserProfileForm">
        <FormTextInput label="Full Name" name="fullName" />
      </Grid>
      <Grid item xs={12} data-testid="updateUserProfileForm">
        <FormPhoneInput name="defaultPhone" label="Phone" required={true} disabled={false} />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }} data-testid="updateUserProfileForm">
        {showPasswordDivider && <FormGroupDivider marginY={20} />}
        {showPasswordLink && (
          <h4 style={{ marginTop: 10, marginBottom: 0 }}>
            Password
            <Box component={Link} href="/auth/reset-password" sx={{ ...sharedFormSx.link, marginLeft: 1 }}>
              change
            </Box>
          </h4>
        )}
      </Grid>
    </Grid>
  );
};
