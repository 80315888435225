import { Access } from '@efacity/common';

export class Administrator {
  constructor(public fullName: string, public email: string) {}
}

export class AdministratorFullInfo extends Administrator {
  constructor(
    public override fullName: string,
    public override email: string,
    public _id: string,
    public access: Access[],
    public callByName?: string
  ) {
    super(fullName, email);
  }
}
