import type { FC, ReactNode, CSSProperties } from 'react';
import { Box } from '@mui/material';
import { efacityOrgContacts } from '../organizationsFetchers';
import type { OrganizationInformation } from '../organizationsFetchers';
import { Footer } from './footer/footer';

const centeredContainer = {
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'space-between',
  justifyItems: 'start',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '0px',
  marginBottom: '0px',
  flexDirection: 'column',
  maxWidth: '1000px',
  minHeight: `100vh`,
  paddingLeft: '5px',
  paddingRight: '5px',
  paddingTop: '5px',
  paddingBottom: '5px'
} as const;
const logoStyle = {
  maxWidth: '160px',
  width: '100%',
  height: 'auto'
};

export interface ContainerWithOrganizationFooterProps {
  children: ReactNode;
  orgInfo?: OrganizationInformation;
  containerStyleOverrides?: CSSProperties;
}

export const ContainerWithLogoAndOrganizationFooter: FC<ContainerWithOrganizationFooterProps> = ({
  children,
  orgInfo = efacityOrgContacts,
  containerStyleOverrides = {}
}) => {
  return (
    <Box data-testid="simple-container-with-log-footer" style={{ ...centeredContainer, ...containerStyleOverrides }}>
      <div style={{ margin: '10px', textAlign: 'center' }} data-testid="no-match">
        <a href={'/'}>
          <img src={'/assets/images/EfacityLogoWhite_256_70.svg'} style={logoStyle} alt="Efacity Logo" />
        </a>
      </div>
      <div style={{ marginTop: '0px', marginBottom: 'auto' }}>{children}</div>
      <Footer
        orgInfo={orgInfo}
        sxOverride={{ marginTop: '15px', fontSize: 20, textAlign: 'center', fontWeight: '500' }}
      />
    </Box>
  );
};
