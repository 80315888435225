import React, { useMemo } from 'react';
import { EnhancedColumn } from '../../components/Table/Table';
import { adminSectionTableFontSize, LoginAudit } from '@efacity/frontend-shared';
import { loginStatusViewConnector } from '@efacity/common';
import LoginAuditStatusFilter from './LoginAuditStatusFilter';
import DateCell from '../../components/DateCell';
import DateColumnFilter from '../../components/Table/filters/DateColumnFilter';

export const useLoginAuditColumns = () => {
  return useMemo(() => {
    const columns: EnhancedColumn<LoginAudit>[] = [
      {
        Header: 'Login',
        accessor: 'login',
        disableFilters: false,
        disableSortBy: false,
        Cell: ({ value }) => <div>{value}</div>
      },
      {
        Header: 'Time',
        accessor: 'loginTimestamp',
        disableFilters: false,
        disableSortBy: false,
        headerStyles: { width: 120, maxWidth: 120 },
        Cell: ({ value }) => (
          <div>
            <DateCell
              dateTime={new Date(value)}
              yearOnNewLine={false}
              wrap={true}
              style={{ ...adminSectionTableFontSize }}
            />
          </div>
        ),
        Filter: DateColumnFilter
      },
      {
        Header: 'IP Address',
        accessor: 'fromIpAddress',
        disableFilters: false,
        disableSortBy: false,
        headerStyles: { width: 120, maxWidth: 120 },
        Cell: ({ value }) => <div>{value}</div>
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: false,
        disableSortBy: false,
        headerStyles: { width: 120, maxWidth: 120 },
        Cell: ({ value }) => <div>{loginStatusViewConnector[value]}</div>,
        Filter: LoginAuditStatusFilter
      },
      {
        Header: 'Failure Reason',
        accessor: 'failureReason',
        disableFilters: false,
        disableSortBy: false,
        Cell: ({ value }) => <div>{value}</div>
      }
    ];

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
