import { getCountryNameByCode, supportedCountryCodes } from '@efacity/common';
import { getCountryFlag } from '@efacity/react-next-sc';
import { MenuItem } from '@mui/material';
import { FC } from 'react';
import { FormInputTextProps, FormTextInput } from './FormTextInput';

export const CountrySelector: FC<FormInputTextProps> = ({ name, onValueChange, ...props }) => {
  return (
    <FormTextInput
      name={name}
      label="Country"
      select
      {...props}
      onValueChange={onValueChange}
      data-testid="country-select"
    >
      {supportedCountryCodes.map((country) => {
        const CountryFlag = getCountryFlag(country);
        const countryName = getCountryNameByCode(country);

        return CountryFlag ? (
          <MenuItem key={country} value={country}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CountryFlag style={{ width: 20, height: 20 }} aria-label={countryName} />
              <span style={{ marginLeft: 8 }}>{countryName}</span>
            </div>
          </MenuItem>
        ) : null;
      })}
    </FormTextInput>
  );
};
