import { useCallback, useEffect, useState } from 'react';
import { apiService, getMergedFormValues } from '@efacity/frontend-shared';
import { useSnackbar } from 'notistack';
import { Address, CountryCode, isEmptyObject, Messages, FormMode, generateShortAddress } from '@efacity/common';
import { AddressFormValues } from '../components/AddressForm';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

const sortByAddressesByName = (address1: Address, address2: Address) => {
  return generateShortAddress(address1) > generateShortAddress(address2) ? 1 : -1;
};

export interface AddressesState {
  addresses: Address[];
  defaultCountry?: CountryCode;
  isLoading: boolean;
}

export const initialAddressFormValues: AddressFormValues = {
  _id: '',
  country: CountryCode.USA,
  address: '',
  city: '',
  state: '',
  zip: '',
  mapLink: ''
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const useAddressesLoader = (url: string) => {
  const [addressesState, setAddressesState] = useState<AddressesState>({
    addresses: [],
    defaultCountry: null,
    isLoading: true
  });
  const snackbarProps = useSnackbar();

  const getAddresses = useCallback(() => {
    const setAddresses = (addresses: Address[], isLoading: boolean, defaultCountry: CountryCode = null) => {
      setAddressesState({
        addresses: addresses.sort(sortByAddressesByName),
        isLoading,
        defaultCountry
      });
    };

    const showErrorNotification = (message: string) => {
      setAddresses([], false);
      showNotification(snackbarProps, false, message || 'Error', true);
    };

    apiService.get<{ defaultCountry: CountryCode; addresses: Address[] }>(url).then(
      (result) => {
        return setAddresses(result.data.addresses, false, result.data.defaultCountry);
      },
      (error) => {
        setAddressesState((addressesState) => ({
          ...addressesState,
          isLoading: false
        }));
        showErrorNotification(error.response?.data?.message || 'Failed to get addresses');
      }
    );
  }, [snackbarProps, url]);

  const deleteAddress = (addressId: string) => {
    return apiService
      .delete<{ message: string }>(`${url}/${addressId}`)
      .then(({ data }) => {
        getAddresses();
      })
      .catch((error) => {
        showNotification(
          snackbarProps,
          false,
          error.response?.data?.message || Messages.FailedDeleteOrganizationAddress,
          true
        );
      });
  };

  const updateAddress = async (
    formValues: AddressFormValues,
    setError: (name, error, options?) => void,
    addressId: string,
    handleCloseModal: () => void
  ) => {
    const address = { ...formValues };
    return apiService
      .patch<{ message: string }>(`${url}/${addressId}`, address)
      .then(({ data }) => {
        getAddresses();
        handleCloseModal();
      })
      .catch((error) => {
        const errorResponse = error.response.data;
        if (typeof errorResponse.validationErrors === 'object' && !isEmptyObject(errorResponse.validationErrors)) {
          addServerErrors<AddressFormValues>(errorResponse.validationErrors, setError);
        } else {
          showNotification(
            snackbarProps,
            false,
            errorResponse.message || Messages.FailedUpdateOrganizationAddress,
            true
          );
        }
      });
  };

  const addAddress = async (
    formValues: AddressFormValues,
    setError: (name, error, options?) => void,
    handleCloseModal: () => void
  ) => {
    const address = { ...formValues };
    return apiService
      .post<{ data: Address; message: string }>(url, address)
      .then(({ data }) => {
        const newAddress = data.data;
        getAddresses();
        handleCloseModal();
        return newAddress;
      })
      .catch((error) => {
        const errorResponse = error.response.data;
        if (typeof errorResponse.validationErrors === 'object' && !isEmptyObject(errorResponse.validationErrors)) {
          addServerErrors<AddressFormValues>(errorResponse.validationErrors, setError);
        } else {
          showNotification(
            snackbarProps,
            false,
            errorResponse.message || Messages.FailedCreateOrganizationAddress,
            true
          );
        }
      });
  };

  return [
    {
      addressesState,
      setAddressesState
    },
    { getAddresses, deleteAddress, updateAddress, addAddress }
  ];
};

export const useAddressFormValues = (formMode: FormMode, orgId: string = null, addressId: string = null) => {
  const snackbarProps = useSnackbar();

  const [addressFormState, setAddressFormState] = useState({
    addressFormValues: initialAddressFormValues,
    isLoading: false
  });

  useEffect(() => {
    const getAddressById = async (orgId: string) => {
      setAddressFormState({
        addressFormValues: initialAddressFormValues,
        isLoading: true
      });

      try {
        const { data } = await apiService.get<Address>(`/org/${orgId}/addresses/${addressId}`);
        const address = { ...data };

        setAddressFormState((addressFormState) => ({
          ...addressFormState,
          addressFormValues: getMergedFormValues(initialAddressFormValues, address),
          isLoading: false
        }));
      } catch (error) {
        showNotification(snackbarProps, false, error.message || 'Failed to get address info', true);
        setAddressFormState({
          addressFormValues: initialAddressFormValues,
          isLoading: true
        });
      }
    };

    if (formMode === FormMode.Add) {
      setAddressFormState((addressFormState) => ({
        ...addressFormState,
        addressFormValues: initialAddressFormValues
      }));
    } else {
      getAddressById(orgId);
    }
  }, [orgId, snackbarProps, addressId, formMode]);

  return {
    addressFormState: addressFormState
  };
};
