import React, { useMemo } from 'react';
import SubjectWithMessageCell from './SubjectWithMessageCell';
import MessageStatusFilter from './MessageStatusFilter';
import SendIcon from '@mui/icons-material/Send';
import { CircularProgress, useTheme } from '@mui/material';
import { EnhancedColumn } from '../../components/Table/Table';
import { adminSectionTableFontSize, Message } from '@efacity/frontend-shared';
import DateCell from '../../components/DateCell';
import { convertUtcToZonedTime, SendStatuses, sendStatusViewConnector } from '@efacity/common';
import DateColumnFilter from '../../components/Table/filters/DateColumnFilter';
import { IconButton } from '@mui/material';

const marginTopCellStyles = { verticalAlign: 'top', paddingTop: 10 };

export const useMessagesColumns = (
  orgId: string,
  timeZone: string,
  resendMessage: (messageId: string) => void,
  processingMessageId: string | null
) => {
  const theme = useTheme();

  const messageStatusColor = (status: SendStatuses) => {
    return status === SendStatuses.Sent
      ? theme.palette.primary.main
      : status === SendStatuses.Failed
      ? theme.palette.error.main
      : '';
  };

  return useMemo(() => {
    const columns: EnhancedColumn<Message>[] = [
      {
        Header: 'To',
        accessor: 'recipientEmail',
        disableFilters: false,
        columnStyles: marginTopCellStyles,
        Cell: ({ value }) => <div>{value}</div>
      },
      {
        Header: 'Subject / Text',
        accessor: 'subject',
        disableFilters: false,
        columnStyles: marginTopCellStyles,
        Cell: ({ value: subject, row }) => <SubjectWithMessageCell subject={subject} body={row.original.body} />
      },
      {
        Header: 'Created On',
        accessor: 'createdOn',
        disableFilters: false,
        headerStyles: {
          maxWidth: 120,
          width: 120
        },
        columnStyles: marginTopCellStyles,
        Cell: ({ value }) => (
          <div>
            {value ? (
              <DateCell
                dateTime={convertUtcToZonedTime(value, timeZone)}
                yearOnNewLine={false}
                wrap={true}
                style={{ ...adminSectionTableFontSize }}
              />
            ) : (
              '[n/a]'
            )}
          </div>
        ),
        Filter: DateColumnFilter
      },
      {
        Header: 'Sent On',
        accessor: 'sentOn',
        disableFilters: false,
        headerStyles: {
          maxWidth: 120,
          width: 120
        },
        columnStyles: marginTopCellStyles,
        Cell: ({ value }) => (
          <div>
            {value ? (
              <DateCell
                dateTime={convertUtcToZonedTime(value, timeZone)}
                yearOnNewLine={false}
                wrap={true}
                style={{ ...adminSectionTableFontSize }}
              />
            ) : (
              '[n/a]'
            )}
          </div>
        ),
        Filter: DateColumnFilter
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: false,
        columnStyles: marginTopCellStyles,
        headerStyles: { width: 76, maxWidth: 76 },
        Cell: ({ value }) => (
          <div style={{ color: `${messageStatusColor(value)}` }}> {sendStatusViewConnector[value]}</div>
        ),
        Filter: MessageStatusFilter
      },
      {
        id: 'registration-actions-option',
        disableFilters: false,
        columnStyles: { verticalAlign: 'top', paddingTop: 5 },
        headerStyles: { width: 40, maxWidth: 40 },
        Cell: ({ row }) =>
          row.original?.status === SendStatuses.Failed &&
          row.original._id && (
            <IconButton
              color="primary"
              aria-label="copy session link"
              component="span"
              disabled={!!processingMessageId}
              onClick={() => resendMessage(row.original._id)}
            >
              {processingMessageId === row.original._id ? (
                <CircularProgress variant="indeterminate" size={20} color="primary" data-testid="progress-in-button" />
              ) : (
                <SendIcon fontSize="small" />
              )}
            </IconButton>
          )
      }
    ];

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, theme, timeZone, processingMessageId]);
};
