export const smallTableContainerSx = {
  border: '1px solid',
  borderColor: 'primary.dark',
  marginBottom: '15px',
  marginTop: '15px',
  fontSize: 14
};

export const smallTableHeadSx = {
  borderBottom: '1px solid',
  borderColor: 'primary.dark',
  fontSize: 14
};

export const smallTableRowSx = {
  '&:nth-of-type(odd)': {
    backgroundColor: 'action.hover'
  },
  '&:not(:last-child)': {
    borderBottom: `1px solid`,
    borderColor: 'primary.dark'
  },
  marginTop: '5px',
  marginBottom: '5px'
};

export const smallTableHeadCellSx = {
  color: 'primary.dark',
  borderBottom: '2px solid',
  borderColor: 'primary.dark',
  fontWeight: 'bold',
  fontSize: 14
};

export const smallTableCellSx = {
  borderBottom: 'unset',
  fontSize: '14px'
};

export const smallTableTotalCellSx = {
  fontSize: 14,
  color: 'primary.main',
  fontWeight: 'bold',
  borderBottom: 'none',
  paddingTop: '2px',
  paddingBottom: '2px'
};
