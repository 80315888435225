import { Box } from '@mui/material';
import {
  DurationType,
  getSessionDuration,
  getSessionEndDate,
  getSessionDatesDiapasonInLocalTimeZone,
  getTimeDiapasonWithLocalTimeZone
} from '@efacity/common';
import { mapSessionInstancesToTimestamps } from '@efacity/react-next-sc';
import React from 'react';
import { selectStudentsStyles } from '../SelectStudentsToSessionByIndex';

export const SessionDurationDetails = ({ session }) => {
  if (!session) return null;
  if (session.durationType === DurationType.selfPaced)
    return <Box sx={selectStudentsStyles.detailsSx}>Duration: self-paced</Box>;
  if (!session.schedule?.instances?.length) return null;

  return (
    <>
      {session?.schedule?.startDateTime && (
        <Box sx={selectStudentsStyles.detailsSx}>
          {`${getSessionDatesDiapasonInLocalTimeZone(
            session.schedule.startDateTime,
            getSessionEndDate(mapSessionInstancesToTimestamps(session.schedule.instances))
          )}`}
        </Box>
      )}
      <Box sx={selectStudentsStyles.detailsSx}>
        Duration: {getSessionDuration(session?.schedule?.instances?.length, session.type)}
      </Box>
      <Box sx={selectStudentsStyles.detailsSx}>
        {getTimeDiapasonWithLocalTimeZone(session.schedule.startDateTime, session.schedule.durationInMinutes)}
      </Box>
    </>
  );
};
