import type { Theme } from '@mui/material';
import { Box, DialogTitle, IconButton, Divider } from '@mui/material';
import type { ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const dialogTitleStyle = {
  margin: 0,
  paddingBottom: 1,
  paddingTop: 1,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'primary.dark'
};

const closeBtnStyle = (theme: Theme) => ({
  color: theme.palette.grey[500]
});

export interface ModalDialogTitleProps {
  text: ReactNode;
  onClose?: (additionalArgs?: unknown) => void;
  isError?: boolean;
  showUnderline?: boolean;
}

export const ModalDialogTitle = ({ text, onClose, isError = false, showUnderline = true }: ModalDialogTitleProps) => {
  const titleSx = isError ? { ...dialogTitleStyle, color: 'error.main' } : dialogTitleStyle;

  return (
    <DialogTitle component="h6">
      <Box sx={titleSx}>
        {text}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={(theme) => closeBtnStyle(theme)}
            data-testid="modal-dialog-title-close"
          >
            <CloseIcon />
          </IconButton>
        ) : (
          ''
        )}
      </Box>
      {showUnderline && <Divider sx={{ background: (theme: Theme) => theme.palette.grey[500] }} />}
    </DialogTitle>
  );
};
