import { getShoppingProgress, setKitPurchaseProgress, setRegistrationProgress } from '@efacity/react-next-sc';
import { PATHS } from '@efacity/routing';

export const clearPurchaseStateAndGetAfterPaymentRedirect = () => {
  const { sessionRegistrationInProgress, kitPurchaseInProgress } = getShoppingProgress();
  setRegistrationProgress(false);
  setKitPurchaseProgress(false);

  return sessionRegistrationInProgress
    ? PATHS.registrations
    : kitPurchaseInProgress
    ? PATHS.customerPurchases
    : PATHS.registrations;
};
