import Cancel from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import { IconButtonProps } from '@mui/material';
import React, { FC } from 'react';

const CancelIconButton: FC<IconButtonProps> = props => {
  return (
    <IconButton
      color="primary"
      aria-label="cancel registration"
      data-testid="cancel-registration"
      component="span"
      {...props}
    >
      <Cancel />
    </IconButton>
  );
};

export default CancelIconButton;
