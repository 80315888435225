import { MenuItem, Select } from '@mui/material';
import React, { FC } from 'react';
import { FilterProps } from 'react-table';

interface SelectColumnFilterProps {
  columnFilterProps: FilterProps<any>;
  options: { value: string; label: string }[];
}

const SelectColumnFilter: FC<SelectColumnFilterProps> = ({ columnFilterProps, options }) => {
  const { filterValue = '', setFilter } = columnFilterProps.column;

  const handleChange = e => {
    setFilter(e.target.value);
  };

  return (
    <Select
      value={filterValue}
      onChange={handleChange}
      variant="outlined"
      size="small"
      displayEmpty
      style={{ marginTop: 8, marginBottom: 4 }}
    >
      <MenuItem value="">Not selected...</MenuItem>
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectColumnFilter;
