import React, { useEffect, useState } from 'react';
import { FormCancelSubmitButtons } from '@efacity/frontend-next-shared/forms';
import { editOrganizationFormValidationSchema } from '../../SiteAdminOrganizationsContainer/organizationFormValidationSchema';
import { AdministratorFullInfo } from '@efacity/frontend-shared';
import { OwnerType, FormMode } from '@efacity/common';
import {
  OrganizationSettingsFormGroup,
  OrganizationSettingsWithAdministratorsFormValues,
  canDeleteCenterAdmin,
  canDeleteSchoolAdmin
} from '@efacity/frontend-next-shared/org-settings';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthenticatedUserInfo } from '@efacity/react-next-sc';

export interface OrganizationSettingsFormProps {
  orgId?: string;
  initialFormValues: OrganizationSettingsWithAdministratorsFormValues;
  administrators: AdministratorFullInfo[];
  authenticatedUser: AuthenticatedUserInfo;
  loading: boolean;
  handleSubmit: (
    formValues: OrganizationSettingsWithAdministratorsFormValues,
    setError: (name, error, options?) => void,
    logoImage: Blob,
    mobileLogoImage: Blob
  ) => void;
  orgType: OwnerType;
  deletingOrgAdmin?: boolean;
  onDeleteOrgAdmin?: (adminId: string) => void;
}

export const OrganizationSettingsForm: React.FC<OrganizationSettingsFormProps> = ({
  orgId,
  initialFormValues,
  loading,
  handleSubmit,
  authenticatedUser,
  administrators,
  orgType,
  onDeleteOrgAdmin,
  deletingOrgAdmin
}) => {
  const pathToFormObject = 'organizationSettings';

  const [logoImgBlob, setLogoImgBlob] = useState(null);
  const [mobileLogoImgBlob, setMobileLogoImgBlob] = useState(null);

  const methods = useForm<OrganizationSettingsWithAdministratorsFormValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(editOrganizationFormValidationSchema(pathToFormObject, orgId)),
    mode: 'onBlur'
  });
  const { isDirty, isValid, isSubmitting } = methods.formState;

  useEffect(() => {
    methods.reset(initialFormValues);
  }, [methods, initialFormValues]);

  const handleDeleteAdmin = async (adminId: string) => {
    if (onDeleteOrgAdmin) {
      onDeleteOrgAdmin(adminId);
    }
  };

  const onSubmit = (formValues: OrganizationSettingsWithAdministratorsFormValues) => {
    handleSubmit(formValues, methods.setError, logoImgBlob, mobileLogoImgBlob);
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <OrganizationSettingsFormGroup
          orgId={orgId}
          authenticatedUser={authenticatedUser}
          mode={FormMode.Edit}
          setLogoImgBlob={setLogoImgBlob}
          setMobileLogoImgBlob={setMobileLogoImgBlob}
          loading={loading}
          deletingOrgAdmin={deletingOrgAdmin}
          administrators={administrators}
          handleDeleteAdmin={handleDeleteAdmin}
          canDeleteAdmin={(admin) =>
            admin._id !== authenticatedUser._id &&
            (orgType === OwnerType.School ? canDeleteSchoolAdmin : canDeleteCenterAdmin)(
              admin,
              authenticatedUser,
              orgId
            )
          }
          pathToFormObject={pathToFormObject}
        />
        <FormCancelSubmitButtons
          mode={FormMode.Edit}
          onCancel={() => methods.reset(initialFormValues)}
          isFormValid={isValid}
          isSubmitting={isSubmitting}
          isDisabled={!isDirty}
        />
      </form>
    </FormProvider>
  );
};

export default OrganizationSettingsForm;
