import React, { FC } from 'react';
import { Box, Collapse, IconButton, Link, TableCell, TableRow, Tooltip } from '@mui/material';
import {
  CustomerRegistration,
  getAmountForRegistration,
  getBalanceForRegistration,
  getDateFormattedForUserTimeZone,
  getInvoiceFileName,
  getPaymentReceiptFileName,
  moneyWithCurrencyCodeFormatter,
  RegistrationStatus
} from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import ColorRegistrationStatus from '../../../components/ColorRegistrationStatus';
import { NavLink } from 'react-router-dom';
import { SessionTimeCell } from '@efacity/frontend-shared';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SmallInvoiceDataTable from '../../../components/SmallInvoiceDataTable/SmallInvoiceDataTable';
import RegistrationActionButtons from './RegistrationActionButtons';

const actionsCellStyle = {
  maxWidth: 165,
  display: 'grid',
  gridTemplateAreas: `"pay cancel"
         "review review"`,
  gap: '5px',
  padding: '0px'
};

export interface RegistrationRowProps {
  registration: CustomerRegistration;
  handleCancelRegistration;
  isCancelling: boolean;
  openReviewModal: (CustomerRegistration) => void;
}

const CustomerRegistrationDesktopRow: FC<RegistrationRowProps> = ({
  registration,
  handleCancelRegistration,
  isCancelling,
  openReviewModal
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow data-testid="customer-registration-row">
        <TableCell>
          <Link
            component={NavLink}
            to={toPath(PATHS.sessionRegistration, { sessionId: registration.sessionId ? registration.sessionId : '' })}
            underline="none"
            style={{ color: registration.status === RegistrationStatus.Unregistered && 'red' }}
          >
            {registration.sessionName}
          </Link>
        </TableCell>
        <TableCell>{registration.studentName}</TableCell>
        <TableCell>
          {registration.schedule?.startDateTime
            ? getDateFormattedForUserTimeZone(registration.schedule.startDateTime)
            : '-'}
        </TableCell>
        <TableCell>
          {registration.schedule?.startDateTime ? (
            <SessionTimeCell sessionSchedule={registration.schedule} displayDate={false} displayTimeZone={false} />
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell align="right">
          {moneyWithCurrencyCodeFormatter(
            getAmountForRegistration(registration._id, registration?.invoiceBalance?.invoiceItems || []),
            registration.invoiceBalance?.currencyCode || registration.currencyCode
          )}
        </TableCell>
        <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
          {moneyWithCurrencyCodeFormatter(
            registration.status === RegistrationStatus.RegisteredAndPaid ||
              registration.status === RegistrationStatus.Unregistered
              ? 0
              : getBalanceForRegistration(registration._id, registration?.invoiceBalance?.invoiceItems || []),
            registration.invoiceBalance?.currencyCode || registration.currencyCode
          )}
          <span style={{ display: 'inline-block', minWidth: '35px' }}>
            {registration.status !== RegistrationStatus.Unregistered && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
                data-testid="collapseButton"
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </span>
        </TableCell>
        <TableCell>
          <ColorRegistrationStatus registrationStatus={registration.status} />
        </TableCell>
        <TableCell>
          {registration?.invoiceId ? (
            registration?.invoiceBalance?.isPaid ? (
              <Tooltip title="Download Payment Receipt">
                <Link href={getPaymentReceiptFileName(registration?.invoiceBalance?._id)}>Receipt</Link>
              </Tooltip>
            ) : registration.status === RegistrationStatus.Registered ? (
              <Tooltip title="Download Invoice">
                <Link href={getInvoiceFileName(registration?.invoiceBalance?._id)}>Invoice</Link>
              </Tooltip>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </TableCell>
        <TableCell>
          <div style={actionsCellStyle}>
            <RegistrationActionButtons
              registration={registration}
              openReviewModal={openReviewModal}
              isCancelling={isCancelling}
              handleCancelRegistration={handleCancelRegistration}
            />
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <SmallInvoiceDataTable invoiceBalance={registration.invoiceBalance} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CustomerRegistrationDesktopRow;
