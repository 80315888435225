import React from 'react';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';

interface CurrencyFormatProps extends NumberFormatProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: (instance: NumberFormat<any> | null) => void;
}

export const CurrencyFormat = React.forwardRef(function NumberFormatCustom(props: CurrencyFormatProps, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: NumberFormatValues) => {
        onChange({
          target: {
            name: props.name,
            value: (values.floatValue || 0) * 100
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any);
      }}
      decimalScale={2}
      thousandSeparator
      isNumericString
    />
  );
});
