import { Address } from './Address';

export interface VirtualMeeting {
  meetingId?: string;
  password?: string;
  link: string;
}

export enum LocationType {
  Virtual = 'virtual',
  InPerson = 'inPerson',
  PublicSchool = 'publicSchool'
}

export const inPersonLocation = {
  label: 'In-Person',
  value: LocationType.InPerson
};
export const virtualLocation = {
  label: 'Virtual',
  value: LocationType.Virtual
};
export const publicSchoolLocation = {
  label: 'Public School',
  value: LocationType.PublicSchool
};

export const locationOptions = [virtualLocation, inPersonLocation, publicSchoolLocation];

export const locationWithoutPublicOptions = [
  {
    label: 'Virtual',
    value: LocationType.Virtual
  },
  {
    label: 'In-Person',
    value: LocationType.InPerson
  }
];

export interface Location {
  _id?: string;
  type: LocationType;
  name: string;
  virtualMeeting?: VirtualMeeting;
  address?: Address;
  orgId: string;
  defaultInstructor?: string;
  capacity?: number;
  createdAt?: string;
  createdBy?: string;
}
