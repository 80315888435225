import React, { FC } from 'react';
import { HorizontalField } from '@efacity/frontend-shared';
import { CancelOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Check';

const buttonStyle = {
  width: 150,
  height: 32,
  textTransform: 'uppercase',
  fontSize: 14
} as React.CSSProperties;

interface CancelSubmitButtonsProps {
  onCancel: () => void;
  onSubmit: () => void;
  disabled?: boolean;
}

export const CancelSubmitButtons: FC<CancelSubmitButtonsProps> = ({ onCancel, onSubmit, disabled }) => {
  return (
    <HorizontalField justifyContent="center" wrapOnSmallScreen={false} style={{ padding: 9, width: '100%' }}>
      <Button style={buttonStyle} onClick={onCancel} color="primary" variant="outlined">
        <CancelOutlined style={{ color: 'primary', marginRight: 10 }} />
        Cancel
      </Button>
      <Button
        style={buttonStyle}
        color="primary"
        variant="contained"
        disabled={disabled}
        onClick={onSubmit}
        data-testid="discountConflictSubmitButton"
      >
        <SaveIcon style={{ marginRight: 10 }} />
        Submit
      </Button>
    </HorizontalField>
  );
};
