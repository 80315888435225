import { Messages } from '@efacity/common';
import { PATHS } from '@efacity/routing';
import { apiService, CircularProgressButton } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { LinearProgress, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormTitle } from '../../components/AuthForm/FormTitle/FormTitle';

export const EmailValidation = () => {
  const navigate = useNavigate();
  const snackbarProps = useSnackbar();
  // TODO: CHECK IT
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  const [{ message, isError, email }, setValidationMessage] = useState({
    message: 'Start validation...',
    isError: false,
    email: ''
  });

  const [isSendingEmail, setIsSendingEmail] = useState(false);

  useEffect(() => {
    if (uuid) {
      setValidationMessage({ message: 'Validating email...', isError: false, email: '' });
      const verifyEmail = async () => {
        try {
          await apiService.post(`auth/email-validation`, { uuid: uuid });
          setValidationMessage({ message: Messages.EmailValidationSuccessful, isError: false, email: '' });
          showNotification(snackbarProps, true, Messages.EmailValidationSuccessful, false);

          return navigate(`${PATHS.auth}/${PATHS.signIn}`);
        } catch (error) {
          setValidationMessage({
            message: error.response?.data?.message || Messages.EmailValidationFailed,
            isError: true,
            email: error.response?.data?.email
          });
        }
      };

      verifyEmail();
    } else {
      setValidationMessage({ message: 'No validation code.', isError: true, email: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resendValidationEmail = async () => {
    setIsSendingEmail(true);
    try {
      await apiService.post(`auth/send-email-validation`, { email: email });
      return navigate(`${PATHS.checkEmail}/?messageId=1`);
    } catch (error) {
      setValidationMessage((state) => ({
        message: error.response?.data || Messages.EmailValidationFailed,
        isError: true,
        email: state.email
      }));
      setIsSendingEmail(false);
    }
  };

  const gotoAuthPage = () => {
    navigate(`${PATHS.auth}/${PATHS.signUp}`);
  };

  return (
    <div>
      <FormTitle withBorder={false} title="Email Validation" />
      <div style={{ margin: 10 }}>
        <Typography
          color={isError ? 'error' : 'primary'}
          style={{ fontSize: 18, textAlign: 'center', margin: 30, marginTop: 0 }}
        >
          {message}
        </Typography>
        {isError && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {email?.length > 0 ? (
              <CircularProgressButton
                progress={isSendingEmail}
                color={'primary'}
                onClick={resendValidationEmail}
                style={{ width: 222 }}
              >
                Resend validation email
              </CircularProgressButton>
            ) : (
              <CircularProgressButton progress={false} color={'primary'} onClick={gotoAuthPage} style={{ width: 222 }}>
                Signup page
              </CircularProgressButton>
            )}
          </div>
        )}

        {!isError && (
          <div style={{ width: '50%', margin: 'auto' }}>
            <LinearProgress variant="indeterminate" color="primary" data-testid="loadingIndicator" />
          </div>
        )}
      </div>
    </div>
  );
};
