import { apiService } from '@efacity/frontend-shared';
import { GoogleAnalytics, GtagInvoice } from '@efacity/google-analytics';

export const registerInvoicePaymentGtag = async (url: string) => {
  try {
    const { data: gtagInvoice } = await apiService.get<GtagInvoice>(url);
    GoogleAnalytics.invoicePurchaseEvent(gtagInvoice);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting invoice data for Google Analytics:', error.message);
  }
};
