import { Messages } from '@efacity/common';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { FetchDataOptions, initialFetchDataOptions } from '../../components/Table/Table';
import { apiService, LoginAudit } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';
import removeDateOffsetFromFilters from '../../utils/removeDateOffsetFromFilters';

export interface LoginAuditState {
  loginAudits: LoginAudit[];
  total: number;
  activeUsers: number;
  isLoading: boolean;
  fetchDataOptions: FetchDataOptions;
}
export const defaultLoginAuditState: LoginAuditState = {
  loginAudits: [],
  total: 0,
  activeUsers: 0,
  isLoading: false,
  fetchDataOptions: initialFetchDataOptions
};
export const useLoginAudit = () => {
  const snackbarProps = useSnackbar();

  const [loginAuditState, setLoginAuditState] = useState<LoginAuditState>(defaultLoginAuditState);

  const getLoginAudits = useCallback(
    async (fetchDataOptions: FetchDataOptions): Promise<void> => {
      setLoginAuditState((prevState) => ({
        ...prevState,
        isLoading: true,
        fetchDataOptions: { ...fetchDataOptions }
      }));
      const { pageIndex, pageSize, filters, sortBy } = fetchDataOptions;
      const dateFilters = ['loginTimestamp'];
      removeDateOffsetFromFilters(filters, dateFilters);

      try {
        const { data } = await apiService.get<{ data: LoginAudit[]; total: number; activeUsers: number }>(
          `/login-audit`,
          {
            page: pageIndex,
            perPage: pageSize,
            ...getFiltersQueryParameters(filters),
            sortBy: mapReactTableSortToApiSort(sortBy)
          }
        );

        setLoginAuditState((prevState) => ({
          ...prevState,
          loginAudits: data.data,
          total: data.total,
          activeUsers: data.activeUsers,
          isLoading: false
        }));
      } catch (error) {
        setLoginAuditState((state) => ({ ...state, isLoading: false }));
        showNotification(snackbarProps, false, error.response?.data?.message || Messages.FailedGetLoginAudit);
      }
    },
    [snackbarProps]
  );

  return { loginAuditState, getLoginAudits };
};
