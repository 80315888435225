import { Checkbox, FormControlLabel } from '@mui/material';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ActivityIdName } from '@efacity/frontend-shared';
import TextField from '@mui/material/TextField';
import { useFormContext } from 'react-hook-form';
import { defaultRHFSetValueOptions } from '@efacity/react-hook-form-mui';

interface ActivityAllowedToCentersFormProps {
  allActivities: ActivityIdName[];
  showSelectedActivities: boolean;
  setShowSelectedActivities: (state: boolean) => void;
  setShowSelectedActivitiesDisabled: (state: boolean) => void;
}

const ActivitiesAllowedToCenterForm: FC<ActivityAllowedToCentersFormProps> = ({
  allActivities,
  showSelectedActivities,
  setShowSelectedActivities,
  setShowSelectedActivitiesDisabled
}) => {
  const methods = useFormContext();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredActivities, setFilteredActivities] = useState(allActivities);

  const fieldName = 'allowedActivities';
  const allowedActivitiesArray = methods.getValues(fieldName);

  const filterActivities = () => {
    let filtered = allActivities;

    if (showSelectedActivities && allowedActivitiesArray) {
      filtered = filtered.filter(activity => allowedActivitiesArray.indexOf(activity._id) !== -1);
    }

    if (searchQuery && searchQuery.length > 0) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(activity => activity.name.toLowerCase().includes(query));
    }

    if (filtered?.length === 0 && showSelectedActivities) {
      setShowSelectedActivities(false);
    }

    setShowSelectedActivitiesDisabled(allowedActivitiesArray.length === 0);

    setFilteredActivities(filtered);
  };

  useEffect(() => {
    filterActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, showSelectedActivities, allActivities, allowedActivitiesArray]);

  const handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const areAllActivitiesSelected = filteredActivities.every((activityIdName: ActivityIdName) =>
    allowedActivitiesArray.includes(activityIdName._id)
  );

  const handleAllActivitiesSelect = () => {
    areAllActivitiesSelected
      ? methods.setValue(fieldName, [], defaultRHFSetValueOptions)
      : methods.setValue(
          fieldName,
          filteredActivities.map((activity: ActivityIdName) => activity._id),
          defaultRHFSetValueOptions
        );
  };

  const toggleActivity = activityId => {
    const index = allowedActivitiesArray.indexOf(activityId);
    if (index > -1) {
      //remove an option if it already exists in the array
      allowedActivitiesArray.splice(index, 1);
      methods.setValue(fieldName, allowedActivitiesArray, defaultRHFSetValueOptions);
    } else {
      methods.setValue(fieldName, [...allowedActivitiesArray, activityId], defaultRHFSetValueOptions);
    }
  };

  return (
    <div>
      <TextField
        id="search-activity-query"
        size="small"
        label="Search activities"
        variant="outlined"
        style={{ marginBottom: 6, marginTop: 8 }}
        value={searchQuery}
        onChange={handleSearchQueryChange}
        fullWidth
      />
      {filteredActivities && filteredActivities.length ? (
        <>
          {!showSelectedActivities && (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={areAllActivitiesSelected}
                    onChange={handleAllActivitiesSelect}
                    name="selectAllActivities"
                    color="primary"
                  />
                }
                label="All Activities"
              />
            </div>
          )}
          <div style={{ ...(!showSelectedActivities && { paddingLeft: 20 }), paddingRight: 20 }}>
            {filteredActivities.map((activity: ActivityIdName, index: number) => {
              return (
                <div key={`activityIdNameIndex${index}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={`${activity._id}`}
                        checked={allowedActivitiesArray.indexOf(activity._id) > -1}
                        onClick={() => toggleActivity(activity._id)}
                        name={`allowedActivity${activity._id}`}
                        color="primary"
                      />
                    }
                    label={activity.name}
                  />
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div style={{ marginBottom: 6, marginTop: 8 }}>Nothing found</div>
      )}
    </div>
  );
};

export default ActivitiesAllowedToCenterForm;
