import { Button, ButtonProps, CircularProgress } from '@mui/material';
import React from 'react';

export interface CircularProgressButtonProps {
  progress: boolean;
  color: 'inherit' | 'primary' | 'secondary';
  disabled?: boolean;
  id?: string;
  component?: any;
}

export const CircularProgressButton: React.FC<CircularProgressButtonProps & ButtonProps> = ({
  progress,
  children,
  color,
  disabled,
  ...props
}) => {
  return (
    <Button type="submit" variant="contained" color={color} disabled={disabled || progress} {...props}>
      {progress ? (
        <CircularProgress variant="indeterminate" size={20} color={color} data-testid="progress-in-button" />
      ) : (
        <span>{children}</span>
      )}
    </Button>
  );
};
