import { LightTooltip } from '@efacity/react-next-sc';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import { Box, IconButton, Link, Typography } from '@mui/material';
import React from 'react';

interface NameLinkWithNoteIconProps {
  name: string;
  onNameClick;
  onNoteClick;
  fontSize?: number;
}

export const NameLinkWithNoteIcon: React.FC<NameLinkWithNoteIconProps> = ({
  name,
  onNameClick,
  onNoteClick,
  fontSize = 14
}) => {
  return (
    <Box display="flex" alignItems="center" data-testid="activity-name-copier-cell">
      <Link component="button" align={'left'} onClick={onNameClick} underline={'none'} style={{ fontSize: fontSize }}>
        {name}
      </Link>
      &nbsp;&nbsp;
      <LightTooltip title={<Typography>Add Note for {name}</Typography>}>
        <IconButton
          color="primary"
          aria-label="copy to clipboard"
          component="span"
          style={{ marginTop: -4, padding: 5 }}
          onClick={onNoteClick}
        >
          <NoteAltOutlinedIcon fontSize="medium" />
        </IconButton>
      </LightTooltip>
    </Box>
  );
};
