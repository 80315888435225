import { LightTooltip } from '@efacity/react-next-sc';
import { FC } from 'react';

import { Activity } from '@efacity/common';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import CopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import { FetchDataOptions } from '../../components/Table/Table';

export interface ActivitiesTableActionsCellProps {
  activity: Activity;
  orgId: string;
  onClone: (activityId: string) => void;
  onShare: (activity: Activity, fetchDataOptions: FetchDataOptions) => void;
  onDelete: (activityId: string, name: string, fetchDataOptions: FetchDataOptions) => void;
  fetchDataOptions: FetchDataOptions;
  allowedShareActivity: boolean;
}

const ActivitiesTableActionsCell: FC<ActivitiesTableActionsCellProps> = ({
  activity,
  orgId,
  onClone,
  onShare,
  onDelete,
  fetchDataOptions,
  allowedShareActivity
}) => {
  const allowAction = activity.owner.orgId === orgId;

  if (!allowAction) return null;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      data-testid="activity-actions"
      style={{ width: allowedShareActivity ? 165 : 70, maxWidth: allowedShareActivity ? 165 : 70 }}
    >
      {allowedShareActivity && (
        <LightTooltip title={<Typography>Share Activity with others</Typography>}>
          <span>
            <div style={{ display: 'flex', marginRight: 5 }}>
              <Button
                color="primary"
                variant={'contained'}
                onClick={() => {
                  onShare(activity, fetchDataOptions);
                }}
                style={{ ...smallPublishButtonStyle, width: 70 }}
                data-testid="share-btn"
              >
                Share
              </Button>
            </div>
          </span>
        </LightTooltip>
      )}

      <LightTooltip title={<Typography>Clone existing activity</Typography>}>
        <span>
          <IconButton
            onClick={() => onClone(activity._id)}
            color="primary"
            data-testid="clone-btn"
            style={{ padding: 0, marginLeft: 5, marginRight: 5 }}
          >
            <CopyOutlinedIcon />
          </IconButton>
        </span>
      </LightTooltip>

      <IconButton
        color="primary"
        aria-label="delete course"
        data-testid="delete-course-btn"
        component="span"
        style={{ ...smallPublishButtonStyle, width: 30, marginRight: 10, marginLeft: 0 }}
        onClick={() => onDelete(activity._id, activity.name, fetchDataOptions)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default ActivitiesTableActionsCell;
