import { SessionStatus } from '@efacity/common';
import { smallCircularProgressButtonStyle, CircularProgressButton } from '@efacity/frontend-shared';
import { Session as EfacitySession, LightTooltip } from '@efacity/react-next-sc';
import Cancel from '@mui/icons-material/Cancel';
import CopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import RestoreIcon from '@mui/icons-material/Restore';
import { Box, IconButton, Typography } from '@mui/material';
import { Dispatch, FC } from 'react';
import { Row } from 'react-table';
import SessionPublishButton, { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';

export interface SessionsTableActionsCellProps {
  row: Row<EfacitySession>;
  onCloneSession: (session: EfacitySession) => void;
  handleRestoreSession: (sessionId: string) => void;
  onShowCancellationModal: (sessionId: string, setSessionRowUnderUpdate?: Dispatch<string>) => void;
  onSessionStatusChanged: (
    sessionId: string,
    setSessionRowUnderUpdate: Dispatch<string>,
    newStatus: SessionStatus
  ) => void;
}
export interface SessionsTableActionsCellState {
  isLoading: boolean;
}

const SessionsTableActionsCell: FC<SessionsTableActionsCellProps> = ({
  row,
  onCloneSession,
  handleRestoreSession,
  onSessionStatusChanged,
  onShowCancellationModal
}) => {
  const { original: session, setState: setRowState } = row;

  // TODO: Find better way
  const rowState = row.state as any as SessionsTableActionsCellState;
  const isSessionCancelled = !!session.cancelledBy;

  return (
    <Box display="flex" alignItems="center" data-testid="actions-cell" style={{ width: 185, maxWidth: 185 }}>
      <LightTooltip
        title={
          <>
            <Typography>Clone existing Session</Typography>
            <Typography variant="caption">(session start date will be set to current date)</Typography>
            <Typography variant="caption">
              (published session will be cloned with <span style={{ fontWeight: 'bold' }}>unpublished</span> status)
            </Typography>
          </>
        }
      >
        <span>
          <IconButton
            onClick={() => onCloneSession(session)}
            color="primary"
            data-testid="clone-btn"
            style={{ padding: 0, marginLeft: 5, marginRight: 5 }}
          >
            <CopyOutlinedIcon />
          </IconButton>
        </span>
      </LightTooltip>

      <SessionPublishButton
        onClick={(sessionStatus: SessionStatus) => {
          onSessionStatusChanged(session._id, setRowState, sessionStatus);
        }}
        disabled={isSessionCancelled}
        progress={rowState.isLoading}
        sessionStatus={session.status}
        style={smallPublishButtonStyle}
      />
      {session.cancelledAt ? (
        <LightTooltip title={<Typography>Restore Session</Typography>}>
          <span>
            <CircularProgressButton
              onClick={() => {
                handleRestoreSession(session._id);
              }}
              variant="contained"
              color="primary"
              progress={rowState.isLoading}
              data-testid="restoreButtonId"
              style={smallCircularProgressButtonStyle}
            >
              <RestoreIcon />
            </CircularProgressButton>
          </span>
        </LightTooltip>
      ) : (
        <LightTooltip title={<Typography>Cancel Session</Typography>}>
          <span>
            <CircularProgressButton
              onClick={() => {
                onShowCancellationModal(session._id);
              }}
              variant="contained"
              color="primary"
              progress={rowState.isLoading}
              disabled={isSessionCancelled}
              data-testid="cancelButtonId"
              style={smallCircularProgressButtonStyle}
            >
              <Cancel />
            </CircularProgressButton>
          </span>
        </LightTooltip>
      )}
    </Box>
  );
};

export default SessionsTableActionsCell;
