import { Autocomplete, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { HorizontalField } from '@efacity/frontend-shared';
import { AddressModal } from './AddressModal';
import { useAddressesLoader } from '../hooks/useAddressesLoader';
import { defaultAddressModalState } from './OrganizationAddressesContainer';
import { CountryCode, generateShortAddress, FormMode, Address } from '@efacity/common';
import type { AddressFormValues } from './AddressForm';
import { Controller, useFormContext } from 'react-hook-form';
import { FormTextInput } from '@efacity/react-hook-form-mui';

const newAddressBtnStyle = {
  minWidth: '185px'
};

interface AddressFormProps {
  name: string;
  orgId: string;
  selectFieldLabel?: string;
  country?: CountryCode;
  errorMessage?: string;
}

export const AddressSelectorWithModal: React.FC<AddressFormProps> = ({
  name,
  orgId,
  selectFieldLabel = 'Select default address',
  country
}) => {
  const [addressModalState, setAddressModalState] = useState(defaultAddressModalState);
  const [{ addressesState }, { getAddresses, addAddress }] = useAddressesLoader(`/org/${orgId}/addresses`);

  useEffect(() => {
    getAddresses();
  }, [getAddresses]);

  const methods = useFormContext();

  function handleStartAddAddress() {
    setAddressModalState({ isOpen: true, addressId: null, mode: FormMode.Add });
  }
  function handleCloseAddAddressModal() {
    setAddressModalState(defaultAddressModalState);
  }
  async function handleAddAddress(
    formValues: AddressFormValues,
    setError: (name, error, options?) => void,
    handleCloseModal: () => void
  ) {
    const addResult = await addAddress(formValues, setError, handleCloseModal);
    if (addResult) {
      methods.setValue(name, addResult._id, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
    }
  }

  if (addressesState.isLoading) {
    return null;
  }

  const addressId = methods.watch(name);
  const address = addressesState.addresses.find((item) => item._id === addressId);
  const errorMessage = methods.formState.errors[name]?.message as string;
  const showErrorMessage = !!errorMessage;

  return (
    <>
      <HorizontalField>
        <Controller
          control={methods.control}
          name={name}
          render={({ field: { onChange, onBlur } }) => (
            <Autocomplete
              id={name}
              value={address || null}
              options={addressesState.addresses}
              getOptionLabel={generateShortAddress}
              fullWidth
              onChange={(_, address: Address) => {
                onChange(address?._id || '');
              }}
              renderInput={(params) => (
                <FormTextInput
                  {...params}
                  label={selectFieldLabel}
                  name={name}
                  error={showErrorMessage}
                  data-testid="addressesSelect"
                  onBlur={onBlur}
                  helperText={errorMessage}
                />
              )}
            />
          )}
        />
        <Button
          startIcon={<AddIcon style={{ marginLeft: 10 }} />}
          color="primary"
          variant="contained"
          style={newAddressBtnStyle}
          onClick={handleStartAddAddress}
          data-testid="createNewAddress"
        >
          <div style={{ paddingRight: 10 }}>New address</div>
        </Button>
      </HorizontalField>

      {addressModalState.isOpen && (
        <AddressModal
          open={addressModalState.isOpen}
          addressId={addressModalState.addressId}
          mode={FormMode.Add}
          orgId={orgId}
          orgCountry={country || addressesState.defaultCountry}
          handleClose={handleCloseAddAddressModal}
          onAddAddress={handleAddAddress}
        />
      )}
    </>
  );
};
