import React, { useState } from 'react';
import { CustomerRegistration } from '@efacity/common';
import { Card, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { tableStyles } from '../../../stylesheets/tableStyle';
import CustomerRegistrationDesktopRow from './CustomerRegistrationDesktopRow';
import ReviewModal from '../../../components/ReviewModal';

export interface RegistrationsProps {
  registrations: CustomerRegistration[];
  isLoading: boolean;
  isCancelling: boolean;
  handleCancelRegistration;
}

const CustomerRegistrationsDesktopTable: React.FC<RegistrationsProps> = ({
  registrations,
  isLoading,
  isCancelling,
  handleCancelRegistration
}) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [reviewModalRegistration, setReviewModalRegistration] = useState(null);

  const openReviewModal = (registration) => {
    setReviewModalOpen(true);
    setReviewModalRegistration(registration);
  };
  const closeReviewModal = () => {
    setReviewModalOpen(false);
  };

  return (
    <>
      <TableContainer component={Card} sx={tableStyles.tableContainer}>
        <Table sx={tableStyles.table} size="small">
          <TableHead style={tableStyles.tableHead}>
            <TableRow>
              <TableCell sx={tableStyles.tableHeaderCellSx}>Session</TableCell>
              <TableCell sx={tableStyles.tableHeaderCellSx}>Student</TableCell>
              <TableCell sx={tableStyles.tableHeaderCellSx}>Start Date</TableCell>
              <TableCell sx={tableStyles.tableHeaderCellSx}>Time ({timezone})</TableCell>
              <TableCell align="right" sx={tableStyles.tableHeaderCellSx}>
                Price
              </TableCell>
              <TableCell align="right" sx={{ ...tableStyles.tableHeaderCellSx, paddingRight: '51px' }}>
                Balance
              </TableCell>
              <TableCell sx={tableStyles.tableHeaderCellSx}>Status</TableCell>
              <TableCell sx={tableStyles.tableHeaderCellSx}></TableCell>
              <TableCell style={{ maxWidth: 75 }} sx={tableStyles.tableHeaderCellSx}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  <LinearProgress variant="indeterminate" color="primary" data-testid="loadingIndicator" />
                </TableCell>
              </TableRow>
            )}
            {registrations.length > 0 &&
              !isLoading &&
              registrations.map((registration) => (
                <CustomerRegistrationDesktopRow
                  registration={registration}
                  key={`${registration._id}`}
                  handleCancelRegistration={handleCancelRegistration}
                  isCancelling={isCancelling}
                  openReviewModal={openReviewModal}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {reviewModalOpen && (
        <ReviewModal isOpen={reviewModalOpen} registration={reviewModalRegistration} onClose={closeReviewModal} />
      )}
    </>
  );
};

export default CustomerRegistrationsDesktopTable;
