import {
  CurrencyCode,
  formatSessionWeekDays,
  getEndTime,
  InvoiceItemPresentation,
  formatDateToMonthDayYearInLocale,
  formatTimeToHhMmNonBreakableString,
  moneyWithCurrencyCodeFormatter
} from '@efacity/common';
import CalendarIcon from '@mui/icons-material/Event';
import { TableCell, TableRow, Box } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import React, { FC } from 'react';
import { cellSx, strippedTableRowSx, TableCellWithIcon } from '@efacity/frontend-shared';
import DeleteIconButtonWithSpinner from '../../../components/DeleteIconButtonWithSpinner/DeleteIconButtonWithSpinner';

export const linkSx = {
  color: 'primary.main',
  '&:hover': {
    cursor: 'pointer'
  }
};

export interface RegistrationInvoiceItemTableRowProps {
  invoiceItem: InvoiceItemPresentation;
  handleDeleteRegistration: (registrationId: string, sessionId: string, studentId: string) => void;
  index: number;
  isUpdating: boolean;
  disabled: boolean;
  hasAdminRole: boolean;
  onStartAdjustmentModal: (invoiceItem: InvoiceItemPresentation) => void | null;
}

const RegistrationInvoiceItemTableRow: FC<RegistrationInvoiceItemTableRowProps> = ({
  invoiceItem,
  handleDeleteRegistration,
  index,
  isUpdating,
  disabled,
  hasAdminRole,
  onStartAdjustmentModal
}) => {
  return (
    <TableRow sx={strippedTableRowSx} data-testid="registration-invoice-item-row">
      <TableCell sx={cellSx} align="left">
        {invoiceItem.sessionName}
      </TableCell>
      <TableCell sx={cellSx} align="left">
        {invoiceItem.studentName}
      </TableCell>
      <TableCell sx={cellSx} align="center" style={{ whiteSpace: 'nowrap', width: 166 }} scope="row">
        {invoiceItem?.startDate ? (
          <TableCellWithIcon icon={<CalendarIcon />}>
            {formatDateToMonthDayYearInLocale(invoiceItem.startDate)}
          </TableCellWithIcon>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell sx={cellSx} align="center" style={{ width: 137 }} component="th" scope="row">
        {formatSessionWeekDays(invoiceItem.weekDays)}
      </TableCell>
      <TableCell sx={cellSx} align="center" style={{ whiteSpace: 'nowrap', width: 137 }} scope="row">
        {invoiceItem.startDate ? (
          <TableCellWithIcon icon={<AccessTimeIcon />}>
            {`${formatTimeToHhMmNonBreakableString(invoiceItem.startDate)} - ${getEndTime(
              invoiceItem.instanceDuration,
              invoiceItem.startDate
            )}`}
          </TableCellWithIcon>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell sx={cellSx} align="center" style={{ width: 98 }} component="th" scope="row">
        {invoiceItem.sessionDuration}
      </TableCell>
      <TableCell sx={cellSx} align="right" style={{ whiteSpace: 'nowrap', width: 120 }} scope="row">
        {hasAdminRole ? (
          <Box sx={linkSx} onClick={() => onStartAdjustmentModal(invoiceItem)} data-testid="edit-amount-link">
            {moneyWithCurrencyCodeFormatter(invoiceItem.amount, invoiceItem.currencyCode as CurrencyCode)}
          </Box>
        ) : (
          moneyWithCurrencyCodeFormatter(invoiceItem.amount, invoiceItem.currencyCode as CurrencyCode)
        )}
      </TableCell>
      <TableCell sx={cellSx} align="center" style={{ width: 80 }}>
        <DeleteIconButtonWithSpinner
          onClick={() =>
            handleDeleteRegistration(invoiceItem.registrationId, invoiceItem.sessionId, invoiceItem.studentId)
          }
          isUpdating={isUpdating}
          data-testid={`deleteIcon${index}`}
          disabled={disabled}
        />
      </TableCell>
    </TableRow>
  );
};

export default RegistrationInvoiceItemTableRow;
