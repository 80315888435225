import React, { FC } from 'react';
import { CurrencyCode, moneyWithCurrencyCodeFormatter, ShoppingCartRegistration } from '@efacity/common';
import { cellSx, strippedTableRowSx } from '@efacity/frontend-shared';
import { TableCell, TableRow } from '@mui/material';

export interface DiscountsListProps {
  discounts: ShoppingCartRegistration['discounts'];
  currency: CurrencyCode;
  offsetLeft?: number;
  colSpan?: number;
}

const DiscountsList: FC<DiscountsListProps> = ({ discounts, currency, offsetLeft = 0, colSpan = 1 }) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {discounts?.map((discount) => {
        return (
          <TableRow sx={strippedTableRowSx} key={discount._id}>
            {offsetLeft > 0 && Array.from(Array(offsetLeft)).map((_, index) => <TableCell sx={cellSx} key={index} />)}
            <TableCell sx={cellSx} align="left" style={{ paddingLeft: 60 }} colSpan={colSpan}>
              {discount.description}
            </TableCell>
            <TableCell sx={cellSx} align="right" style={{ whiteSpace: 'nowrap', width: 120 }} scope="row">
              {moneyWithCurrencyCodeFormatter(discount.calculatedAmount, currency)}
            </TableCell>
            <TableCell sx={cellSx} />
          </TableRow>
        );
      })}
    </>
  );
};

export default DiscountsList;
