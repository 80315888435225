import { Box, Typography } from '@mui/material';
import type { ReactNode } from 'react';

export interface CardCornerLabelProps {
  children: ReactNode;
  background?: string;
}

export const CardCornerLabel = ({ children, background }: CardCornerLabelProps) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '15px',
        left: '-34px',
        width: '140px',
        height: '40px',
        transform: 'rotate(-45deg)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        backgroundColor: background ?? 'grey.100'
      }}
      data-testid="card-corner"
    >
      <Typography
        align="center"
        sx={{
          color: '#fff',
          fontWeight: 'bold',
          fontSize: 18
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};
