import type { FC } from 'react';
import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';

export const FormGroupTitle: FC<TypographyProps> = ({ children, ...props }) => {
  return (
    <Typography variant="h5" gutterBottom style={{ whiteSpace: 'nowrap' }} {...props}>
      {children}
    </Typography>
  );
};
