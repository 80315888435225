import { Descendant, Node } from 'slate';

export const locationNameMarkup = '<%= locationName%>';
export const emptyRichEdit = [{ children: [{ text: '' }] }] as Descendant[];

export const stringToRichEdit = (str) => {
  return [{ children: [{ text: str }] }] as Descendant[];
};

export const richEditContainsText = (richEdit) => {
  for (const paragraph of richEdit) {
    if (paragraph.text) return true;
    for (const child of paragraph.children) {
      if (child.text) {
        return true;
      }
    }
  }
  return false;
};

export const serializeRichEditValue = (nodes: Descendant[]) => {
  return nodes.map((n) => Node.string(n)).join('\n');
};
