import {
  ConnectStatus,
  OrganizationStatus,
  organizationStatusViewConnector,
  paymentPlatformTypeViews
} from '@efacity/common';
import type { Organization } from '@efacity/frontend-shared';
import { PhoneNumber } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import { LinkCell } from '@efacity/table';
import { Button, Typography } from '@mui/material';
import type { TableOptions } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { OrganizationTransferCell } from '../components/OrganizationTransferCell';

export interface UseSchoolsTableColumnDefsArgs {
  openDisconnectEfacityModal: (organizationId: string) => void;
  openStripeStandardConnectModal: (organizationId: string) => void;
  openSelectAccountModal: (
    organizationId: string,
    orgName: string,
    currentSelectedAccount?: { _id: string; name: string }
  ) => void;
}

const columnHelper = createColumnHelper<Organization>();
export const useSchoolsTableColumnDefs = ({
  openDisconnectEfacityModal,
  openStripeStandardConnectModal,
  openSelectAccountModal
}: UseSchoolsTableColumnDefsArgs): TableOptions<Organization>['columns'] => {
  return [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: ({ row }) => {
        return <LinkCell href={toPath(PATHS.editSchool, { id: row.original._id })} label={row.original.name} />;
      }
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: ({ getValue }) => {
        const status = getValue();

        return status
          ? organizationStatusViewConnector[status as OrganizationStatus]
          : organizationStatusViewConnector[OrganizationStatus.Active];
      }
    }),
    columnHelper.accessor('administrators', {
      id: 'admins',
      header: 'Admins',
      cell: ({ getValue }) =>
        getValue<Organization['administrators']>().map(({ fullName, _id }, index, arr) => (
          <Typography paragraph key={_id} fontSize={14}>
            {fullName}
            {index + 1 < arr.length ? ',' : ''}
          </Typography>
        ))
    }),
    columnHelper.accessor('phone', {
      header: 'Phone',
      cell: ({ getValue }) => <PhoneNumber phone={getValue()} />
    }),
    columnHelper.accessor('paymentAccountId', {
      header: 'Payment Platform',
      cell: ({ row }) => {
        return (
          <Button
            variant="text"
            sx={{
              display: 'inline',
              textAlign: 'unset',
              textTransform: 'unset',
              fontWeight: 'normal',
              '&:hover': {
                background: 'unset'
              }
            }}
            disableElevation
            disableRipple
            onClick={() =>
              openSelectAccountModal(
                row.original._id,
                row.original.name,
                row.original.paymentAccountId
                  ? { _id: row.original?.paymentAccountId._id, name: row.original?.paymentAccountId.name }
                  : undefined
              )
            }
          >
            {row.original?.paymentAccountId
              ? `${paymentPlatformTypeViews[row.original.paymentAccountId.paymentPlatformType]}: ${
                  row.original.paymentAccountId.name
                }`
              : 'Add Account'}
          </Button>
        );
      }
    }),
    columnHelper.accessor('organizationTransfers', {
      header: 'Transfers',
      cell: ({ row }) => <OrganizationTransferCell data={row.original} />
    }),
    columnHelper.accessor('connectStatus', {
      header: 'Efacity Connect',
      cell: ({ row }) => {
        return !row.original.parentOrgId ? (
          row.original.connectStatus === ConnectStatus.ConnectedStandard ? (
            <Button
              variant="contained"
              size="small"
              sx={{ width: 100 }}
              onClick={() => openDisconnectEfacityModal(row.original._id)}
            >
              Disconnect
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              sx={{ width: 100 }}
              onClick={() => openStripeStandardConnectModal(row.original._id)}
            >
              Connect
            </Button>
          )
        ) : null;
      }
    })
  ];
};
