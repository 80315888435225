import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { WithLoading } from '../WithLoading/WithLoading';

export const useStripeWrapper = (publishableKey: string, isLoadingKey: boolean, formToWrap: ReactElement) => {
  const [stripe, setStripe] = useState(null);
  const [isLoadingStripe, setIsLoadingStripe] = useState(false);

  useEffect(() => {
    // when publishableKey changes, get new stripe object
    // set it and then pass to Elements
    const getStripe = async () => {
      try {
        const stripe = await loadStripe(publishableKey);
        setStripe(stripe);
      } catch (error) {
        // TODO: Convert to a proper logger
        // eslint-disable-next-line no-console
        console.log('ERR', error);
      } finally {
        setIsLoadingStripe(false);
      }
    };

    if (publishableKey) {
      setIsLoadingStripe(true);
      getStripe();
    }
  }, [publishableKey]);

  // takes component of a form or form part that needs to be wrapped
  // with Elements
  // passes stripe and wraps form with Elements context
  // when stripe object is changed or isLoadingStripe is changed
  // rerender this
  const wrappedForm = useMemo(() => {
    return (
      <WithLoading isLoading={isLoadingStripe || isLoadingKey} message={'Loading Stripe...'} style={{ width: '25%' }}>
        {publishableKey && stripe ? (
          <Elements stripe={stripe}>{formToWrap}</Elements>
        ) : (
          <div style={{ fontSize: 20, color: '#f44336', textAlign: 'center', margin: 30 }}>
            Failed to load Stripe info. Unable to process payment.
          </div>
        )}
      </WithLoading>
    );
  }, [formToWrap, isLoadingStripe, stripe, publishableKey, isLoadingKey]);

  // stripe we are getting from loadStripe
  // seems to be the same stripe we are getting with useStripe()
  // but because we want to wrap only part of the page
  // and don't want to wrap entire page
  // but still want to use stripe on this page
  // we are returning it here
  return [{ wrappedForm, stripe }];
};
