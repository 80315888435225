import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Table, { PaginationTypes } from '../../../components/Table/Table';
import { InstanceActivity, Messages } from '@efacity/common';
import {
  adminSectionTableFontSize,
  apiService,
  ConfirmationModal,
  CircularProgressButton
} from '@efacity/frontend-shared';
import { usePaymentPlatformsLoader } from './usePaymentPlatformsLoader';
import usePaymentPlatformsColumns from '../usePaymentPlatformsColumns';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { smallPublishButtonStyle } from '../../../components/Buttons/SessionPublishButton';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import StartConnectModal from '../../../components/StartConnectModal/StartConnectModal';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

export interface PaymentPlatformsTableProps {
  instanceActivity: InstanceActivity;
}

const PaymentPlatformsTable: React.FC<PaymentPlatformsTableProps> = ({ instanceActivity }) => {
  const snackbarProps = useSnackbar();
  const [{ paymentPlatformsState }, { getPaymentPlatforms }] = usePaymentPlatformsLoader(
    `/paymentPlatforms/${instanceActivity}`
  );

  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    name: '',
    orgId: null,
    transferId: null,
    onConfirm: null
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);

  const defaultConnectModalState = {
    isOpen: false,
    isConnect: true,
    isOperating: false,
    id: null,
    stripeUserId: null,
    message: '',
    connectName: ''
  };
  const [connectModalState, setConnectModalState] = useState(defaultConnectModalState);

  // Connect between paymet platfroms temporary disabled
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onConnectCLick = (paymentPlatformId: string, name: string) => {
    setConnectModalState({
      isOpen: true,
      isConnect: true,
      isOperating: false,
      id: paymentPlatformId,
      stripeUserId: null,
      message: `You are about to Connect to ${name} Stripe Account.<br/>Please, login to Stripe account to be connected before click "Start" button.`,
      connectName: ''
    });
  };

  const onDisconnectCLick = (paymentPlatformId: string, stripeUserId: string) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        'You are about to Disconnect account from this Payment Platform.<br/>' +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      name: '',
      orgId: paymentPlatformId,
      transferId: stripeUserId,
      onConfirm: performStandartStripeDisconnect
    });
  };

  const performStandardStripeConnect = () => {
    localStorage.setItem('paymentPlatformId', connectModalState.id);
    localStorage.setItem('connectName', connectModalState.connectName);
    setConnectModalState({ ...connectModalState, isOperating: true });
    apiService.post(`/stripe/connect/forPlatform/${connectModalState.id}`, {}).then(
      (result: any) => {
        window.location.href = result.data;
      },
      (error) => {
        showNotification(snackbarProps, false, error.response.data.message as string, true);
      }
    );
  };

  const performStandartStripeDisconnect = (currentConfirmationModalState) => {
    setConfirmationModalState({ ...currentConfirmationModalState, isOperating: true });
    apiService
      .post(
        `/stripe/connect/forPlatform/${currentConfirmationModalState.orgId}/disconnect/${currentConfirmationModalState.transferId}`,
        {}
      )
      .then(
        (result: any) => {
          setConfirmationModalState({ ...defaultConfirmationModalState });
          getPaymentPlatforms();
          showNotification(snackbarProps, true, result.data.message as string, false);
        },
        (error) => {
          setConfirmationModalState({ ...currentConfirmationModalState, isOperating: false });
          showNotification(snackbarProps, false, error.response.data.message as string, true);
        }
      );
  };

  const handleDeleteStripeAccountClicked = (accountId: string, name: string) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        `You are about to delete ${name} Payment Platform<br>` +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      name: '',
      orgId: accountId,
      transferId: null,
      onConfirm: handleDeleteStripeAccount
    });
  };

  const handleDeleteStripeAccount = (currentConfirmationModalState) => {
    setConfirmationModalState({ ...currentConfirmationModalState, isOperating: true });
    apiService.delete(`/paymentPlatforms/account/${currentConfirmationModalState.orgId}`).then(
      (result: any) => {
        setConfirmationModalState({ ...defaultConfirmationModalState });
        showNotification(snackbarProps, true, (result.data.message as string) || Messages.PaymentAccountDeleted, false);
        getPaymentPlatforms();
      },
      (error) => {
        setConfirmationModalState({ ...currentConfirmationModalState, isOperating: false });
        showNotification(
          snackbarProps,
          false,
          (error.response.data.message as string) || Messages.FailedDeletePaymentAccount,
          true
        );
      }
    );
  };

  const additionalColumns =
    instanceActivity === InstanceActivity.active
      ? [
          {
            Header: 'Connected Accounts',
            accessor: 'stripePlatformAccount',
            disableFilters: true,
            Cell: ({ row }) => {
              return row.original?.stripePlatformAccount?.connectedAccounts?.length > 0
                ? row.original.stripePlatformAccount.connectedAccounts.map((connectedAccount, index) => {
                    return (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        style={{ marginTop: 2, marginBottom: 2 }}
                        key={`connectedAccountsLine${index}`}
                      >
                        <div>{connectedAccount.name}</div>
                        <div>
                          <CircularProgressButton
                            progress={false}
                            color="primary"
                            onClick={() => onDisconnectCLick(row.original._id, connectedAccount.stripeUserId)}
                            style={{ ...smallPublishButtonStyle, width: 100 }}
                            data-testid={`disconnectButtonForPaymentPlatform${row.original._id}`}
                          >
                            Disconnect
                          </CircularProgressButton>
                        </div>
                      </Box>
                    );
                  })
                : '-';
            }
          },
          {
            Header: '',
            id: 'actions-cell',
            disableSortBy: true,
            columnStyles: { ...adminSectionTableFontSize, width: 75, maxWidth: 75 },
            Cell: ({ state, row }) => {
              return (
                <IconButton
                  color="primary"
                  aria-label="delete discount"
                  data-testid="delete-discount-btn"
                  component="span"
                  style={smallPublishButtonStyle}
                  onClick={() => handleDeleteStripeAccountClicked(row.original._id, row.original.name)}
                >
                  <DeleteIcon />
                </IconButton>
              );
            }
          }
        ]
      : [];
  const columns = usePaymentPlatformsColumns(additionalColumns);
  useEffect(() => {
    getPaymentPlatforms();
  }, [instanceActivity, getPaymentPlatforms]);

  return (
    <>
      <Table
        columns={columns}
        data={paymentPlatformsState.paymentPlatforms}
        loading={paymentPlatformsState.isLoading}
        paginationType={PaginationTypes.ClientSide}
        noDataText={
          instanceActivity === InstanceActivity.active
            ? 'No current Stripe Accounts...'
            : 'No deleted Stripe Accounts...'
        }
      />{' '}
      {connectModalState.isOpen && (
        <StartConnectModal
          open={connectModalState.isOpen}
          onClose={() => setConnectModalState({ ...defaultConnectModalState, isOpen: false })}
          onStart={performStandardStripeConnect}
          message={connectModalState.message}
          connectName={connectModalState.connectName}
          onNameChanged={(event) => setConnectModalState({ ...connectModalState, connectName: event.target.value })}
        />
      )}
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState({ ...defaultConfirmationModalState })}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
    </>
  );
};

export default PaymentPlatformsTable;
