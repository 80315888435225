import { Roles, getLoggedInUserHomePagePathIfAny, hasAnyOfRoles } from '@efacity/common';
import { apiService } from '@efacity/frontend-shared';
import {
  AuthStatus,
  REDIRECT_ITEM_NAME,
  getShoppingProgress,
  setKitPurchaseProgress,
  setRegistrationProgress,
  useAuth,
  useLocalStorage
} from '@efacity/react-next-sc';
import { PATHS, shouldUseReactRouter } from '@efacity/routing';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useGoogleAuth = (isAuthenticated?: AuthStatus) => {
  const { signInWithGoogle } = useAuth();
  const [signInUrl, setSignInUrl] = useState('');
  const { search } = useLocation();
  const navigate = useNavigate();
  const snackbarProps = useSnackbar();
  const { getItem, removeItem } = useLocalStorage();

  useEffect(() => {
    const getUrl = async () => {
      try {
        const { data } = await apiService.get<{ googleSigninUrl: string }>('/auth/google-signin-url');
        setSignInUrl(data.googleSigninUrl);
      } catch (error) {
        // TODO: what else should happened in addition to printing that we failed to get signInUrl?
        // eslint-disable-next-line no-console
        console.error('Failed to get signInUrl: ', error);
      }
    };

    getUrl();
  }, []);

  // track when url was changed
  // the code parameter indicates that we were redirected
  // from Google Sign In page
  // and should send this code to our backend api
  useEffect(() => {
    const signIn = async () => {
      const { loginSuccess, roles, access, userId } = await signInWithGoogle(code as string);
      if (!loginSuccess) {
        return navigate('/');
      }
      if (loginSuccess && roles) {
        const { sessionRegistrationInProgress, kitPurchaseInProgress } = getShoppingProgress();
        if (hasAnyOfRoles(roles, [Roles.Customer]) && sessionRegistrationInProgress) {
          removeItem('from');
          return navigate(PATHS.selectStudentsForSessions);
        }
        if (hasAnyOfRoles(roles, [Roles.Customer]) && kitPurchaseInProgress) {
          removeItem('from');
          return navigate(PATHS.cart);
        }

        // If user is redirected to login from a page, then redirect to that page
        const fromRedirect = getItem(REDIRECT_ITEM_NAME);
        if (fromRedirect) {
          removeItem(REDIRECT_ITEM_NAME);
          return (window.location.href = fromRedirect);
        }

        setRegistrationProgress(false);
        setKitPurchaseProgress(false);
        const loggedInUserHomePage = getLoggedInUserHomePagePathIfAny(access, userId);
        const useReactRouter = shouldUseReactRouter(loggedInUserHomePage);
        if (useReactRouter) {
          return navigate(loggedInUserHomePage);
        } else {
          return (window.location.href = loggedInUserHomePage);
        }
      }
    };
    const { code } = queryString.parse(search);

    if (code && isAuthenticated !== AuthStatus.Authenticated) {
      signIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, navigate, isAuthenticated, snackbarProps]);

  return [signInUrl];
};
