import { EnhancedColumn } from '../../components/Table/Table';
import React, { useMemo } from 'react';
import { adminSectionTableFontSize, LocationMarkerIcon, ZoomIcon } from '@efacity/frontend-shared';
import { NavLink } from 'react-router-dom';
import { PATHS, toPath } from '@efacity/routing';
import { generateShortAddress, getSessionLocationLabelByValue, LocationType } from '@efacity/common';
import { Link } from '@mui/material';

const useLocationsTableColumns = (orgId: string, additionalColumns: EnhancedColumn<any>[] = []) => {
  return useMemo(() => {
    const columns: EnhancedColumn<any>[] = [
      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: false,
        columnStyles: { ...adminSectionTableFontSize },
        Cell: ({ row, value }) => {
          return (
            <Link
              component={NavLink}
              to={toPath(PATHS.editLocation, { orgId: orgId, locationId: row.original._id })}
              underline={'none'}
            >
              {value}
            </Link>
          );
        }
      },
      {
        Header: 'Type',
        accessor: 'type',
        disableFilters: false,
        columnStyles: { ...adminSectionTableFontSize },
        Cell: ({ row, value }) => <>{getSessionLocationLabelByValue(value)}</>
      },
      {
        Header: 'Capacity',
        accessor: 'capacity',
        disableFilters: true,
        Cell: ({ value }) => {
          return value || 0;
        }
      },
      {
        Header: 'Address',
        accessor: 'address',
        disableFilters: true,
        Cell: ({ value }) => <div>{value?.address ? generateShortAddress(value) : 'Virtual'}</div>
      },
      {
        Header: 'Link',
        disableFilters: true,
        Cell: ({ row }) => {
          const link =
            row.original.type === LocationType.InPerson || row.original.type === LocationType.PublicSchool
              ? row.original?.address?.mapLink
              : row.original?.virtualMeeting?.link;

          if (!link) return <div></div>;

          return (
            <a href={link} target="_blank" rel="noreferrer">
              {row.original.type === LocationType.InPerson || row.original.type === LocationType.PublicSchool ? (
                <LocationMarkerIcon />
              ) : (
                <ZoomIcon />
              )}
            </a>
          );
        }
      },
      ...additionalColumns
    ];

    return columns;
  }, [orgId, additionalColumns]);
};

export default useLocationsTableColumns;
