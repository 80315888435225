import React, { useState } from 'react';
import { Button, CircularProgress, Dialog, DialogContent, DialogProps, TextField } from '@mui/material';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/Check';
import VideocamIcon from '@mui/icons-material/Videocam';
import { HorizontalField } from '../HorizontalField/HorizontalField';
import { getYoutubeVideUrlByCompactUrl, getYoutubeId } from '@efacity/common';
import { ModalDialogTitle } from '@efacity/react-next-sc';

const buttonStyle = {
  width: 150,
  height: 32,
  textTransform: 'uppercase',
  fontSize: 14
};
export interface AddMediaUrlModalProps extends DialogProps {
  open: boolean;
  isOperating: boolean;
  onClose: () => void;
  onConfirm: (videoUrl: string) => void;
}

const emptyVideoUrlState = {
  videoUrl: '',
  correctUrl: false
};

const AddMediaUrlModal: React.FC<AddMediaUrlModalProps> = ({ open, isOperating, onClose, onConfirm }) => {
  const [videoUrlState, setVideoUrlState] = useState(emptyVideoUrlState);
  const videoUrlChanged = (event) => {
    const newVideoUrl = event.target.value;
    const videoId = getYoutubeId(newVideoUrl);
    const newVideUrlState = {
      videoUrl: newVideoUrl,
      correctUrl: videoId?.length > 0 && (newVideoUrl.indexOf('youtube') > -1 || newVideoUrl.indexOf('youtu.be') > -1)
    };
    setVideoUrlState(newVideUrlState);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth data-testid="confirmation-modal">
      <ModalDialogTitle text="Add YouTube video URL" onClose={onClose} />
      <DialogContent>
        <TextField
          id={'video-url-input'}
          value={videoUrlState.videoUrl}
          autoFocus
          type="text"
          variant="outlined"
          size="small"
          error={videoUrlState.videoUrl?.length > 0 && !videoUrlState.correctUrl}
          fullWidth
          style={{ marginTop: 10, marginBottom: 20 }}
          disabled={isOperating}
          onChange={videoUrlChanged}
          InputProps={{
            startAdornment: (
              <VideocamIcon data-testid="video-icon" color={'primary'} style={{ marginRight: 10 }}></VideocamIcon>
            )
          }}
          inputProps={{
            'data-testid': 'video-url-input'
          }}
        />
        <HorizontalField justifyContent="center" wrapOnSmallScreen={false} style={{ padding: 9, width: '100%' }}>
          <Button
            sx={buttonStyle}
            onClick={onClose}
            color="primary"
            variant="outlined"
            disabled={isOperating}
            aria-label="Cancel"
          >
            <CancelOutlined style={{ color: 'primary', marginRight: 10 }} />
            Cancel
          </Button>
          <Button
            sx={buttonStyle}
            color="primary"
            variant="contained"
            onClick={() => onConfirm(getYoutubeVideUrlByCompactUrl(videoUrlState.videoUrl))}
            disabled={isOperating || !videoUrlState.correctUrl}
            data-testid="add-video-button"
            aria-label="Confirm"
          >
            {isOperating ? (
              <CircularProgress variant="indeterminate" size={20} color={'primary'} data-testid="progress-in-button" />
            ) : (
              <>
                <SaveIcon style={{ marginRight: 10 }} />
                Add video
              </>
            )}
          </Button>
        </HorizontalField>
      </DialogContent>
    </Dialog>
  );
};

export default AddMediaUrlModal;
