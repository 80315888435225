import { MenuItem } from '@mui/material';
import React from 'react';
import { FormInputTextProps, FormTextInput } from './FormTextInput';

export const AgeSelector: React.FC<FormInputTextProps> = ({ name, ...props }) => {
  return (
    <FormTextInput select name={name} {...props}>
      <MenuItem key={`ageSelectorKey3`} value={3}>
        {'0-3'}
      </MenuItem>{' '}
      <MenuItem key={`ageSelectorKey4`} value={4}>
        {'4'}
      </MenuItem>
      <MenuItem key={`ageSelectorKey5`} value={5}>
        {'5'}
      </MenuItem>
      <MenuItem key={`ageSelectorKey6`} value={6}>
        {'6'}
      </MenuItem>
      <MenuItem key={`ageSelectorKey7`} value={7}>
        {'7'}
      </MenuItem>
      <MenuItem key={`ageSelectorKey8`} value={8}>
        {'8'}
      </MenuItem>
      <MenuItem key={`ageSelectorKey9`} value={9}>
        {'9'}
      </MenuItem>
      <MenuItem key={`ageSelectorKey10`} value={10}>
        {'10'}
      </MenuItem>
      <MenuItem key={`ageSelectorKey11`} value={11}>
        {'11'}
      </MenuItem>
      <MenuItem key={`ageSelectorKey12`} value={12}>
        {'12'}
      </MenuItem>
      <MenuItem key={`ageSelectorKey13`} value={13}>
        {'13'}
      </MenuItem>
      <MenuItem key={`ageSelectorKey14`} value={14}>
        {'14'}
      </MenuItem>
      <MenuItem key={`ageSelectorKey15`} value={15}>
        {'15'}
      </MenuItem>
      <MenuItem key={`ageSelectorKey16`} value={16}>
        {'16'}
      </MenuItem>
      <MenuItem key={`ageSelectorKey17`} value={17}>
        {'Adult'}
      </MenuItem>
    </FormTextInput>
  );
};
