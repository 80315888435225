import { Checkbox, FormControlLabel, Grid, Paper } from '@mui/material';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { CenterIdName } from '@efacity/frontend-shared';
import TextField from '@mui/material/TextField';
import { useFormContext } from 'react-hook-form';
import { defaultRHFSetValueOptions } from '@efacity/react-hook-form-mui';
import { OwnerType } from '@efacity/common';

interface SharedWithOrganizationsFilteredArrayProps {
  ownerType: OwnerType;
  centers: CenterIdName[];
  disabled: boolean;
}

const SharedWithOrganizationsForm: FC<SharedWithOrganizationsFilteredArrayProps> = ({
  ownerType,
  centers,
  disabled
}) => {
  const name = 'sharedWith';

  const methods = useFormContext();
  const values = methods.watch(name);

  const [filteredOrganizations, setFilteredOrganizations] = useState(centers);
  const [disableShowSelectedCheckbox, setDisableShowSelectedCheckbox] = useState(values?.length === 0);
  const [searchQuery, setSearchQuery] = useState('');
  const [hideAllOrganizationsCheckbox, setHideAllOrganizationsCheckbox] = useState(true);

  const filterCenters = () => {
    let filtered = centers;

    if (hideAllOrganizationsCheckbox && values) {
      filtered = filtered.filter((organization) => values.indexOf(organization._id) !== -1);
    }

    if (searchQuery && searchQuery.length > 0) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter((organization) => organization.name.toLowerCase().includes(query));
    }

    // If not centers found and show selected checkbox active, then disable it
    if (filtered.length === 0 && hideAllOrganizationsCheckbox) {
      setHideAllOrganizationsCheckbox(false);
    }

    // If no centers selected, disable show only selected checkbox
    setDisableShowSelectedCheckbox(values?.length === 0);

    setFilteredOrganizations(filtered);
  };

  useEffect(
    () => {
      filterCenters();
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [searchQuery, hideAllOrganizationsCheckbox, centers]
  );

  useEffect(() => {
    setDisableShowSelectedCheckbox(values?.length === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const areAllCentersSelected = filteredOrganizations.every((organizationIdName: CenterIdName) =>
    values.includes(organizationIdName._id)
  );

  const handleAllCentersSelect = () => {
    areAllCentersSelected
      ? methods.setValue('sharedWith', [], defaultRHFSetValueOptions)
      : methods.setValue(
          'sharedWith',
          filteredOrganizations.map((organization: CenterIdName) => organization._id),
          defaultRHFSetValueOptions
        );
  };

  const toggleSelectedCentersShow = () => {
    setHideAllOrganizationsCheckbox((prev) => !prev);
  };

  const toggleCenter = (centerId) => {
    const chosenCentersArray = methods.getValues(name);
    const index = chosenCentersArray.indexOf(centerId);
    if (index > -1) {
      //remove an option if it already exists in the array
      chosenCentersArray.splice(index, 1);
      methods.setValue(name, chosenCentersArray, defaultRHFSetValueOptions);
    } else {
      methods.setValue(name, [...chosenCentersArray, centerId], defaultRHFSetValueOptions);
    }
  };

  return (
    <Grid container>
      {ownerType === OwnerType.School && (
        <React.Fragment>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6} style={{ marginTop: 8, fontSize: '18' }}>
                Shared with:
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', justifyContent: 'right' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hideAllOrganizationsCheckbox}
                      onChange={toggleSelectedCentersShow}
                      disabled={disableShowSelectedCheckbox || disabled}
                      color="primary"
                    />
                  }
                  label="Show selected"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="search-organization-input"
              size="small"
              label="Search center"
              variant="outlined"
              style={{ width: '100%', marginBottom: 15, marginTop: 10, marginRight: 0 }}
              value={searchQuery}
              onChange={handleSearchQueryChange}
              fullWidth
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} style={{ marginRight: 0 }}>
            <Paper
              style={{
                width: '100%',
                maxHeight: 176,
                paddingLeft: 15,
                paddingRight: 15,
                marginRight: 0,
                overflowY: 'scroll'
              }}
            >
              <div>
                {filteredOrganizations && filteredOrganizations.length ? (
                  <>
                    {!hideAllOrganizationsCheckbox && (
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={areAllCentersSelected}
                              onChange={handleAllCentersSelect}
                              color="primary"
                              disabled={disabled}
                            />
                          }
                          label="All Centers"
                        />
                      </div>
                    )}
                    <div style={{ ...(!hideAllOrganizationsCheckbox && { paddingLeft: 20 }), paddingRight: 20 }}>
                      {filteredOrganizations.map((center: CenterIdName, index) => {
                        return (
                          <div key={`organizationNameIndex${index}`}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={`${center._id}`}
                                  checked={values.indexOf(center._id) > -1}
                                  onClick={(_) => {
                                    toggleCenter(center._id);
                                  }}
                                  name={`sharedWith${center._id}`}
                                  color="primary"
                                  disabled={disabled}
                                />
                              }
                              label={center.name}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <div style={{ marginBottom: 6, marginTop: 8 }}>No organizations</div>
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 15, marginBottom: 15 }}>
            <hr />
          </Grid>
        </React.Fragment>
      )}
      {ownerType !== OwnerType.Center && (
        <>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  value={methods.watch('shareWithAllOrganizations')}
                  checked={methods.watch('shareWithAllOrganizations')}
                  onClick={(_) =>
                    methods.setValue(
                      'shareWithAllOrganizations',
                      !methods.watch('shareWithAllOrganizations'),
                      defaultRHFSetValueOptions
                    )
                  }
                  name="shareWithAllOrganizations"
                  color="primary"
                  disabled={disabled}
                />
              }
              label="Share with all Organizations"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  value={methods.watch('certificateRequired')}
                  checked={methods.watch('certificateRequired')}
                  onClick={(_) =>
                    methods.setValue(
                      'certificateRequired',
                      !methods.watch('certificateRequired'),
                      defaultRHFSetValueOptions
                    )
                  }
                  name="certificateRequired"
                  color="primary"
                  disabled={disabled}
                />
              }
              label="Certification required"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default SharedWithOrganizationsForm;
