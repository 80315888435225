import React, { FC } from 'react';
import { InputLabel, MenuItem, Box } from '@mui/material';
import { getMonthDays, getMonthOptions, getStudentBirthYearOptions } from '@efacity/common';
import { defaultRHFSetValueOptions, FormTextInput } from './FormTextInput';
import { useFormContext } from 'react-hook-form';

interface DateOfBirthSelectorProps {
  label?: string;
  name?: string;
  onDobChanged?: ((dobDate: Date) => void) | null;
}

export const DateOfBirthSelector: FC<DateOfBirthSelectorProps> = ({
  label = 'Date of birth',
  name = 'dateOfBirth',
  onDobChanged = null
}) => {
  const methods = useFormContext();

  const getDobDate = () => {
    const month = methods.watch(`${name}.month`);
    const day = methods.watch(`${name}.day`);
    const year = methods.watch(`${name}.year`);
    return new Date(year, month, day);
  };

  const handleDobChangedIfAny = () => {
    const dobDate = getDobDate();
    if (onDobChanged && dobDate) {
      onDobChanged(dobDate);
    }
  };

  const onYearChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    methods.setValue(`${name}.year`, event.target.value, defaultRHFSetValueOptions);
    handleDobChangedIfAny();
  };
  const onMonthChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    methods.setValue(`${name}.month`, event.target.value, defaultRHFSetValueOptions);
    handleDobChangedIfAny();
  };
  const onDayChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    methods.setValue(`${name}.day`, event.target.value, defaultRHFSetValueOptions);
    handleDobChangedIfAny();
  };

  const monthOptions = getMonthOptions();
  const daysOptions = getMonthDays();
  const childBirthYearOptions = getStudentBirthYearOptions();
  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' } }}>
        <FormTextInput select name={`${name}.year`} label="Year" onChange={onYearChanged}>
          <MenuItem value="">None</MenuItem>
          {childBirthYearOptions.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </FormTextInput>
        <FormTextInput select name={`${name}.month`} label="Month" onChange={onMonthChanged}>
          <MenuItem value="">None</MenuItem>
          {monthOptions.map((month) => (
            <MenuItem key={month.value} value={month.value}>
              {month.label}
            </MenuItem>
          ))}
        </FormTextInput>
        <FormTextInput select name={`${name}.day`} label="Day" onChange={onDayChanged}>
          <MenuItem value="">None</MenuItem>
          {daysOptions.map((day) => (
            <MenuItem key={day} value={day}>
              {day + 1}
            </MenuItem>
          ))}
        </FormTextInput>
      </Box>
    </>
  );
};
