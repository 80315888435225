import { ConnectModel, ConnectStatus, OrganizationStatus } from '@efacity/common';
import { Organization } from '../models/Organization';

const mockSchool = new Organization(
  'testId',
  OrganizationStatus.Active,
  'testParentId',
  'test name',
  'America/New_York',
  [
    { _id: 'test admin id', email: 'test@gmail.com', fullName: 'school', callByName: 'admin' },
    { _id: 'test admin id 2', email: 'test2@gmail.com', fullName: 'school', callByName: 'admin2' }
  ],
  false,
  ConnectModel.ConnectStandard,
  ConnectStatus.NotConnected,
  []
);

export { mockSchool };
