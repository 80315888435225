import {
  CurrencyCode,
  InvoiceItemPresentation,
  InvoiceItemTypes,
  moneyWithCurrencyCodeFormatter
} from '@efacity/common';
import React, { FC } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { cellSx, strippedTableRowSx } from '@efacity/frontend-shared';
import DeleteIconButtonWithSpinner from '../../../components/DeleteIconButtonWithSpinner/DeleteIconButtonWithSpinner';

export interface DiscountInvoiceItemTableRowProps {
  invoiceItem: InvoiceItemPresentation;
  handleDeleteDiscount: (invoiceItemId: string) => void | null;
  isUpdating: boolean;
  disabled: boolean;
  index: number;
}

const DiscountInvoiceItemTableRow: FC<DiscountInvoiceItemTableRowProps> = ({
  invoiceItem,
  handleDeleteDiscount,
  isUpdating,
  disabled,
  index
}) => {
  const handleDeleteDiscountIfAny = () => {
    if (handleDeleteDiscount) {
      handleDeleteDiscount(invoiceItem.invoiceItemId);
    }
  };

  return (
    <TableRow sx={strippedTableRowSx}>
      <TableCell sx={cellSx} align="left" colSpan={6} style={{ paddingLeft: 60 }}>
        {invoiceItem.description}
      </TableCell>
      <TableCell sx={cellSx} align="right" style={{ whiteSpace: 'nowrap', width: 120 }} scope="row">
        {moneyWithCurrencyCodeFormatter(invoiceItem.amount, invoiceItem.currencyCode as CurrencyCode)}
      </TableCell>
      <TableCell sx={cellSx} align="center" style={{ width: 80 }}>
        {invoiceItem.invoiceItemType === InvoiceItemTypes.Discount && invoiceItem.appliedAutomatically === false && (
          <DeleteIconButtonWithSpinner
            onClick={handleDeleteDiscountIfAny}
            isUpdating={isUpdating}
            data-testid={`deleteIcon${index}`}
            disabled={disabled}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default DiscountInvoiceItemTableRow;
