import { Filters } from 'react-table';
import { FetchDataOptions } from '../components/Table/Table';

export const getFiltersQueryParameters = (filters: Filters<any>) => {
  return filters.reduce((acc, { id, value }) => {
    acc[id] = value;
    return acc;
  }, {});
};

export const mapReactTableSortToApiSort = (sortItems: FetchDataOptions['sortBy']) => {
  return sortItems.map(sortItem => {
    return `${sortItem.id}.${sortItem.desc ? 'desc' : 'asc'}`;
  });
};
