import { Button, CircularProgress, Dialog, DialogContent, Grid } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import SaveIcon from '@mui/icons-material/Check';
import { apiService, CommonDatePicker, HorizontalField, Teacher } from '@efacity/frontend-shared';
import { useTeacherOptions } from '../../../hooks/useTeacherOptions';
import AddIcon from '@mui/icons-material/Add';
import InstructorSelector from '../../../components/InstructorSelector/InstructorSelector';
import { maxComputerDate, Messages } from '@efacity/common';
import { useSnackbar } from 'notistack';
import { ModalDialogTitle } from '@efacity/react-next-sc';
import AddInstructorModal from '../../../components/AddInstructorModal/AddInstructorModal';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

const certificateInstructorModalSx = {
  startButton: {
    width: '190px',
    height: '32px',
    textTransform: 'uppercase',
    fontSize: 14
  } as React.CSSProperties,
  newTeacherBtn: {
    width: '200px',
    marginBottom: '8px',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    marginTop: '18px',
    height: '38px'
  } as React.CSSProperties
};

export interface CertificateInstructorModalProps {
  open: boolean;
  orgId: string;
  activityId: string;
  activityName: string;
  closeCertificationModal: Function;
  userId?: string;
  userName?: string;
}

const CertificateInstructorModal: FC<CertificateInstructorModalProps> = ({
  open,
  orgId,
  activityId,
  activityName,
  closeCertificationModal,
  userId,
  userName
}) => {
  const snackbarProps = useSnackbar();

  const { teacherState, addTeacherToOptions } = useTeacherOptions(
    `/org/${orgId}/activities/${activityId}/non-certificated`
  );

  const [teacherModalOpen, setTeacherModalOpen] = useState(false);
  const [certificateState, setCertificateState] = useState({
    instructorId: '',
    expiresOn: maxComputerDate,
    isAdding: false
  });

  useEffect(() => {
    if (!certificateState.instructorId && teacherState.options?.length > 0) {
      setCertificateState((state) => ({ ...state, instructorId: teacherState.options[0]._id }));
    }
  }, [certificateState.instructorId, teacherState.options]);

  const onClose = () => {
    closeCertificationModal(false);
  };

  const onStartCertificate = async () => {
    setCertificateState((state) => ({ ...state, isAdding: true }));
    try {
      await apiService.post(
        `/org/${orgId}/activities/${activityId}/add-certificate`,
        {},
        {
          params: {
            instructorId: userId ? userId : certificateState.instructorId,
            expiresOn: certificateState.expiresOn
          }
        }
      );
      setCertificateState((state) => ({ ...state, isAdding: false }));
      closeCertificationModal(true);
    } catch (error) {
      showNotification(snackbarProps, false, error.response?.data?.message || Messages.CannotLoadRecaptcha);
      setCertificateState((state) => ({ ...state, isAdding: false }));
    }
  };
  const isOperating = false;

  const onInstructorSelect = (event, instructor) => {
    setCertificateState((state) => ({ ...state, instructorId: instructor._id }));
  };
  const handleValidToChanged = (date: Date) => {
    setCertificateState((state) => ({ ...state, expiresOn: date }));
  };
  const addAndSelectTeacher = (teacher: Teacher) => {
    addTeacherToOptions(teacher);
    setCertificateState((state) => ({ ...state, instructorId: teacher._id }));
  };

  const showNewInstructorModal = () => {
    setTeacherModalOpen(true);
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} maxWidth="sm">
        <ModalDialogTitle text={`Add Certificate for '${activityName}'`} onClose={onClose} />
        <DialogContent>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '0 2px',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 10,
                marginBottom: 10
              }}
            >
              {userId ? (
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', fontSize: 20 }}>
                  <div>Instructor:</div>
                  <div style={{ marginLeft: 15, fontWeight: 500 }}>{`${userName}`}</div>
                </div>
              ) : (
                <>
                  <InstructorSelector
                    instructors={teacherState.options}
                    onChange={onInstructorSelect}
                    label="Instructor"
                  />
                  <Button
                    startIcon={<AddIcon />}
                    color="primary"
                    variant="contained"
                    style={certificateInstructorModalSx.newTeacherBtn}
                    onClick={showNewInstructorModal}
                    disabled={false}
                  >
                    New Instructor
                  </Button>
                </>
              )}
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 10 }}>
              <CommonDatePicker
                value={certificateState.expiresOn}
                onChange={handleValidToChanged}
                id="toDateFilter"
                label="To Date"
                dataTestId={'datePicker'}
              />
            </Grid>
          </Grid>
          <HorizontalField justifyContent="center" wrapOnSmallScreen={false} style={{ padding: 9, width: '100%' }}>
            <Button
              style={certificateInstructorModalSx.startButton}
              color="primary"
              variant="contained"
              onClick={onStartCertificate}
              disabled={isOperating || !certificateState.instructorId || certificateState.isAdding}
              data-testid="add-certificate-button"
              aria-label="Confirm"
            >
              {isOperating ? (
                <CircularProgress
                  variant="indeterminate"
                  size={20}
                  color={'primary'}
                  data-testid="progress-certificate-button"
                />
              ) : (
                <>
                  <SaveIcon style={{ marginRight: 10 }} />
                  Confirm
                </>
              )}
            </Button>
          </HorizontalField>
        </DialogContent>
      </Dialog>
      <AddInstructorModal
        open={teacherModalOpen}
        orgId={orgId}
        toggleModalVisible={setTeacherModalOpen}
        instructorOptions={teacherState.options}
        addTeacherOption={addAndSelectTeacher}
        setTeacherFieldValue={null}
        fieldName={null}
      />
    </div>
  );
};

export default CertificateInstructorModal;
