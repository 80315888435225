import React, { FC, useState } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';
import { StripeCombinedField } from '../Stripe/StripeCombinedField';
import { CountryCode, PaymentCard, isCountryHasStates } from '@efacity/common';
import { CountrySelector, FormTextInput, PaymentCardSelector } from '@efacity/react-hook-form-mui';
import { StateSelector } from '@efacity/react-hook-form-mui';
import { useFormContext } from 'react-hook-form';
import { getNestedFormProperty } from '../../utils/formHelpers';

export interface PaymentFormElementsProps {
  paymentCards: PaymentCard[];
  pathToObject?: string;
}

export const PaymentFormElements: FC<PaymentFormElementsProps> = ({ paymentCards, pathToObject = '' }) => {
  const methods = useFormContext();
  const [formError, setFormError] = useState<string | undefined>('');

  const triggerStateValidation = async () => {
    await methods.trigger(getNestedFormProperty('address.country', pathToObject));
    await methods.trigger(getNestedFormProperty('address.state', pathToObject));
  };

  const handleStripeInputChange = (event: StripeCardNumberElementChangeEvent) => {
    setFormError(event.error?.message);
  };

  const countryHasState = isCountryHasStates(
    methods.watch(getNestedFormProperty('address.country', pathToObject) as CountryCode)
  );

  return (
    <Grid container style={{ marginTop: 20 }}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            Payments are processed securely through{' '}
            <Link href="https://stripe.com" target="_blank">
              Stripe
            </Link>
          </Grid>
        </Grid>
      </Grid>
      {paymentCards && paymentCards.length > 0 && (
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Grid container>
            <Grid item xs={12}>
              <PaymentCardSelector paymentCards={paymentCards} pathToObject={pathToObject} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {!(
        methods.getValues(getNestedFormProperty('userPaymentMethodId', pathToObject)) &&
        methods.getValues(getNestedFormProperty('userPaymentMethodId', pathToObject)).length > 0
      ) && (
        <Grid item xs={12} style={{ marginTop: 15, marginBottom: 15 }}>
          <Grid container>
            <Grid item xs={12}>
              <StripeCombinedField
                onChange={handleStripeInputChange}
                error={Boolean(formError)}
                labelErrorMessage={formError}
                style={{ marginBottom: 20 }}
                data-testid="stripeCombinedFields"
              />
              <FormTextInput name={getNestedFormProperty('name', pathToObject)} label="Name on card" required />
              <Typography variant="body1" style={{ fontWeight: 'bold', marginTop: '15px' }}>
                Billing address
              </Typography>
              <CountrySelector
                name={getNestedFormProperty('address.country', pathToObject)}
                onValueChange={triggerStateValidation}
                required={methods.getValues(getNestedFormProperty('address.country', pathToObject)) !== CountryCode.USA}
              />
              <FormTextInput
                name={getNestedFormProperty('address.line1', pathToObject)}
                label="Address"
                required={methods.getValues(getNestedFormProperty('address.country', pathToObject)) !== CountryCode.USA}
                placeholder="street, PO Box, or company name"
              />
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={countryHasState ? 6 : 9}>
                  <FormTextInput
                    name={getNestedFormProperty('address.city', pathToObject)}
                    label="City"
                    required={methods.getValues('address.country') !== CountryCode.USA}
                  />
                </Grid>
                {countryHasState && (
                  <Grid item xs={6} sm={6} md={3}>
                    <StateSelector
                      name={getNestedFormProperty('address.state', pathToObject)}
                      countryCode={methods.watch(getNestedFormProperty('address.country', pathToObject)) as CountryCode}
                      required={
                        methods.getValues(getNestedFormProperty('address.country', pathToObject)) !== CountryCode.USA
                      }
                      onValueChange={triggerStateValidation}
                    />
                  </Grid>
                )}
                <Grid item xs={countryHasState ? 6 : 12} sm={countryHasState ? 6 : 12} md={3}>
                  <FormTextInput
                    name={getNestedFormProperty('address.postal_code', pathToObject)}
                    label="Zip"
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
