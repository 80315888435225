import { PATHS } from '@efacity/routing';
import { apiService } from './apiService';
import type { SocialMediaLink } from './components/footer/SocialMediaLinks';

export interface OrganizationInformation {
  _id?: string;
  name?: string;
  logoFullUrl?: string;
  mobileLogoFullUrl?: string;
  contactEmail?: string;
  timeZone?: string;
  phone?: string;
  website?: string;
  sendFromEmail?: string;
  hideNumberOfRegistrations?: boolean;
  socialMedia?: SocialMediaLink[];
  enrollmentRequired: boolean;
}

export const efacityOrgContacts: OrganizationInformation = {
  contactEmail: 'info@efacity.com',
  phone: '1-609-874-7716',
  website: PATHS.about,
  enrollmentRequired: false,
  socialMedia: [
    {
      title: 'Facebook',
      imageSrc: '/assets/frontend-shared/social-media/2023_Facebook_icon.svg',
      link: 'https://www.facebook.com/efacity/'
    },
    {
      title: 'X (Twitter)',
      imageSrc: '/assets/frontend-shared/social-media/X_logo_2023.svg',
      link: 'https://twitter.com/efacity'
    },
    {
      title: 'Instagram',
      imageSrc: '/assets/frontend-shared/social-media/Instagram_logo_2022.svg',
      link: 'https://www.instagram.com/efacityhq/'
    }
  ]
};

export const fetchOrganizationInformation = (orgId: string) => {
  return apiService().get<OrganizationInformation>(`/org/${orgId}/logos`);
};
