import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { apiService } from '@efacity/frontend-shared';
import { useSnackbar } from 'notistack';
import { Messages } from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { ConfirmConnectPrompt } from './ConfirmConnectPrompt';

export const StandardConnectConfirmationForCenter: React.FC = () => {
  const { search } = useLocation();
  const snackbarProps = useSnackbar();
  const navigate = useNavigate();
  const { code } = queryString.parse(search);

  const orgId = localStorage.getItem('connectOrgId');
  localStorage.removeItem('connectOrgId');
  const centerId = localStorage.getItem('centerId');
  localStorage.removeItem('connectCenterId');
  const connectName = localStorage.getItem('connectName');
  localStorage.removeItem('connectName');

  useEffect(() => {
    const returnToApplication = (orgId: string | undefined) => {
      const redirectPath = orgId ? toPath(PATHS.centers, { orgId: orgId }) : PATHS.landing;
      navigate(redirectPath);
    };

    if (code && orgId && centerId && connectName) {
      const connectData = {
        orgId: orgId,
        centerId: centerId,
        name: connectName,
        code: code
      };

      apiService.post(`/stripe/callbackForCenter`, connectData).then(
        (result: any) => {
          showNotification(snackbarProps, true, result.data.message as string, false);
          returnToApplication(orgId);
        },
        (error) => {
          showNotification(snackbarProps, false, error.response.data.message as string, true);
          returnToApplication(orgId);
        }
      );
    } else {
      showNotification(snackbarProps, false, Messages.FailedStripeStandardConnect, true);
      returnToApplication(orgId);
    }
  });

  return <ConfirmConnectPrompt />;
};
