import { DiscountClassDefinition, DiscountTypeDefinition } from '../../models/Discount';
import { discountCardSx } from './discountCardStyles';
import { discountClassConnector } from './discountClassConnector';
import HeaderCircleIcon from './HeaderCircleIcon';
import { PercentageIcon } from './PercentageIcon';
import { FC, useMemo } from 'react';
import { CurrencyCode, DiscountType, moneyWithCurrencyCodeFormatter, truncate } from '@efacity/common';
import { AmountIcon } from './AmountIcon';
import { Client } from 'react-hydration-provider';
import { Box } from '@mui/material';

export interface DiscountCardProps {
  discount: {
    descriptionForCustomer: string;
    typeDefinition: DiscountTypeDefinition;
    currencyCode?: CurrencyCode;
    classDefinition: DiscountClassDefinition;
  };
  isDemo?: boolean;
}
export const DiscountCard: FC<DiscountCardProps> = ({ discount, isDemo }) => {
  const cardCalculatedConfig = useMemo(() => {
    return discountClassConnector[discount.classDefinition.discountClass];
  }, [discount.classDefinition.discountClass]);

  const discountMoneyFormat = (amount, currencyCode) => {
    return '-' + moneyWithCurrencyCodeFormatter(amount, currencyCode, { trimTrailingZeroCents: true });
  };

  const amountIconDisplayAmount = isDemo ? (
    discountMoneyFormat(discount.typeDefinition.discountAmount, discount.currencyCode)
  ) : (
    <Client>{discountMoneyFormat(discount.typeDefinition.discountAmount, discount.currencyCode)}</Client>
  );

  return (
    <div style={discountCardSx.cardWrapper}>
      <div style={discountCardSx.cardMain}>
        <Box component="header" sx={discountCardSx.cardHeaderSx} style={{ background: cardCalculatedConfig.headerBg }}>
          <h4>{cardCalculatedConfig.title}</h4>
          <HeaderCircleIcon icon={cardCalculatedConfig.headerIcon} />
        </Box>
        <div style={discountCardSx.cardBody}>
          <p
            style={{
              width: '75%',
              margin: 0
            }}
          >
            {truncate(discount.descriptionForCustomer, 100)}
          </p>
          {discount.typeDefinition.discountType === DiscountType.Percentage ? (
            <PercentageIcon amount={discount.typeDefinition.discountPercentage} />
          ) : (
            <AmountIcon>{amountIconDisplayAmount}</AmountIcon>
          )}
        </div>
      </div>
    </div>
  );
};
