import { CopyLinkFlatIcon } from '@efacity/frontend-shared';
import { LightTooltip } from '@efacity/react-next-sc';
import { IconButton, Link, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import React from 'react';

export interface PageLinkWithCopierProps {
  name: string;
  path: string;
  tooltipText: string;
}

const PageLinkWithCopier: React.FC<PageLinkWithCopierProps> = ({ path, name, tooltipText }) => {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', justifyItems: 'start', marginLeft: 10 }}
      data-testid="page-link-with-copier"
    >
      <Link href={path} target="_blank" data-testid="page-link-name" underline={'none'}>
        {name}&nbsp;
      </Link>
      <LightTooltip title={<Typography>{tooltipText}</Typography>}>
        <span>
          <div style={{ display: 'flex', marginRight: 15, marginTop: 3 }}>
            [
            <IconButton
              color="primary"
              aria-label="page-link-copier"
              component="span"
              style={{ marginTop: -4, padding: 5 }}
              onClick={() => copy(`${window.location.origin}${path}`)}
            >
              <CopyLinkFlatIcon fontSize="small" />
            </IconButton>
            ]
          </div>
        </span>
      </LightTooltip>
    </div>
  );
};

export default PageLinkWithCopier;
