import { Box } from '@mui/material';
import React, { FC } from 'react';
import TextField from '@mui/material/TextField';
import { CouponDiscountInputAsRowProps } from './CouponDiscountInputAsRow';

const inputSx = {
  title: theme => ({
    color: theme.palette.primary.dark,
    fontSize: '14px'
  })
};

const CouponDiscountMobileInput: FC<CouponDiscountInputAsRowProps> = ({ handleDiscountCodeChanged }) => {
  return (
    <Box my={2} style={{ marginTop: 30 }}>
      <Box>
        <Box fontWeight="bold" sx={inputSx.title}>
          Discount Code:
        </Box>
      </Box>
      <Box textAlign="end">
        <TextField name="discountCode" variant="outlined" size="small" fullWidth onChange={handleDiscountCodeChanged} />
      </Box>
    </Box>
  );
};

export default CouponDiscountMobileInput;
