import type { TooltipProps } from '@mui/material';
import { Tooltip } from '@mui/material';

export const LightTooltip = (props: TooltipProps) => {
  return (
    <Tooltip
      {...props}
      componentsProps={{
        tooltip: {
          sx: {
            padding: '4px 8px',
            backgroundColor: '#fff',
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: 4,
            fontSize: 12
          }
        }
      }}
    />
  );
};
