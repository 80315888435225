import { MenuItem } from '@mui/material';
import React from 'react';
import { ActivityTypes, ActivityTypesOptions, DisplayOption } from '@efacity/common';
import { FormInputTextProps, FormTextInput } from './FormTextInput';

export const ActivityTypeSelector: React.FC<FormInputTextProps & { includeCertification?: boolean }> = ({
  name,
  includeCertification = true,
  ...props
}) => {
  const activityTypesOptions = includeCertification
    ? Object.values(ActivityTypesOptions).filter(
        (activityTypeOption: DisplayOption) => activityTypeOption.value !== ActivityTypes.Enrollment
      )
    : Object.values(ActivityTypesOptions).filter(
        (activityTypeOption: DisplayOption) =>
          activityTypeOption.value !== ActivityTypes.InstructorCertification &&
          activityTypeOption.value !== ActivityTypes.Enrollment
      );

  return (
    <FormTextInput select name={name} required={true} label="Type" data-testid="activity-type-selector" {...props}>
      {activityTypesOptions.map((activityTypeOption: DisplayOption) => (
        <MenuItem key={activityTypeOption.value} value={activityTypeOption.value}>
          {activityTypeOption.displayText}
        </MenuItem>
      ))}
    </FormTextInput>
  );
};
