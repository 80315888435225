import React from 'react';
import { CircularProgress, Typography } from '@mui/material';

const efacityLogo = '/assets/images/EfacityLogoWhite_256_70.svg';

const logoStyle = {
  maxWidth: 160,
  width: '100%',
  height: 'auto'
};

interface EfacityActionLogoProps {
  message: string;
  logo?: string;
}

const EfacityActionLogo: React.FC<EfacityActionLogoProps> = ({ message, logo = efacityLogo }) => {
  return (
    <div style={{ margin: 20, textAlign: 'center' }}>
      <img src={efacityLogo} style={logoStyle} alt="Efacity Logo" />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: 50,
          marginBottom: 15,
          justifyContent: 'center'
        }}
      >
        <CircularProgress size={20} color="primary" />
        <Typography
          component="h3"
          color="primary"
          style={{ fontSize: 20, textAlign: 'center', marginLeft: 15, marginTop: 5 }}
        >
          {`${message}`}
        </Typography>
      </div>
    </div>
  );
};
export { EfacityActionLogo };
