import React from 'react';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { useParams } from 'react-router-dom';
import { useMessages } from './useMessages';
import Table, { PaginationTypes } from '../../components/Table/Table';
import { useMessagesColumns } from './useMessagesColumns';
import { PageTitle } from '@efacity/react-next-sc';

const MessagesContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const { messagesState, getMessages, resendMessage } = useMessages(orgId);
  const columns = useMessagesColumns(orgId, messagesState.timeZone, resendMessage, messagesState.processingMessageId);

  return (
    <div>
      <PageTitle title="Messages" />
      <Table
        columns={columns}
        fetchData={getMessages}
        data={messagesState.messages}
        pageCount={messagesState.total}
        loading={messagesState.isLoading}
        paginationType={PaginationTypes.ServerSide}
        noDataText="No messages..."
      />
    </div>
  );
};

export default MessagesContainer;
