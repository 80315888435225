import { ActivityTypes, convertMinutesToDuration } from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import addDays from 'date-fns/addDays';
import addMinutes from 'date-fns/addMinutes';
import isEqual from 'date-fns/isEqual';
import set from 'date-fns/set';
import subDays from 'date-fns/subDays';
import { Session as EfacitySession } from '@efacity/react-next-sc';
import { SessionFormInstance, SessionScheduleFormValues } from '../interfaces/SessionFormValues';

type ScheduleInformation = Pick<SessionScheduleFormValues, 'startDateTime' | 'numberOfInstances'> & {
  duration: number;
};
export type FormSessionInstance = Pick<SessionScheduleFormValues, 'duration'> & {
  startDateTime: Date;
  endDateTime: Date;
};

export const getNextSessionInstance = (
  offsetForNextInstance: number,
  lastSessionInstanceDate: Date,
  startDateTime: Date,
  duration: Duration
): SessionFormInstance => {
  const date = addDays(lastSessionInstanceDate, offsetForNextInstance);
  const nextStartDateTime = set(startDateTime, {
    month: date.getMonth(),
    year: date.getFullYear(),
    date: date.getDate()
  });
  const durationInMinutes = duration.hours * 60 + duration.minutes;
  return {
    duration: duration,
    startDateTime: nextStartDateTime,
    endDateTime: addMinutes(nextStartDateTime, durationInMinutes)
  };
};
/*
 * get number of days to add to the next session instance
 * e.g. when last schedule day is mon and availableDaysWeeks are [1, 2, 3]
 * offset is
 * */
const weekDaysNumber = 7;
export const getOffsetForNextInstance = (availableDaysWeeks: number[], lastInstanceDay: number, add = 0) => {
  let day = lastInstanceDay;
  let daysToAdd = add;
  const maxAvailableDayNumber = Math.max(...availableDaysWeeks);
  // if day is not bigger than max day number in availableDaysWeeks add to it
  if (day < maxAvailableDayNumber) {
    day += 1;
    daysToAdd++;
    // if day is the same as max day number in availableDaysWeeks reset function
  } else if (day === maxAvailableDayNumber) {
    day = 0;
    daysToAdd += weekDaysNumber - maxAvailableDayNumber;
    // if lastInstanceDay is bigger than  max day number in availableDaysWeeks (e.g. when custom date was selected)
    // go to the first (minimal) day in availableDaysWeeks)
  } else {
    daysToAdd += -(day - weekDaysNumber - Math.min(...availableDaysWeeks));
    day = Math.min(...availableDaysWeeks);
  }
  // number of days to add to initial lastInstanceDay
  if (availableDaysWeeks.includes(day)) {
    // create
    return daysToAdd;
  } else {
    return getOffsetForNextInstance(availableDaysWeeks, day, daysToAdd);
  }
};
//TODO: Finish with holidays
export function getLessonsArray(scheduleInformation: ScheduleInformation, daysOfWeek: number[]) {
  const { numberOfInstances, startDateTime, duration } = scheduleInformation;
  return constructLessonsArrayHelper(daysOfWeek, startDateTime, numberOfInstances, duration);
}

function constructLessonsArrayHelper(
  daysOfWeek: number[],
  startDateTime: Date,
  numberOfInstances: number,
  durationInMinutes: number
) {
  const lessons = [];
  const dayAgoStartDate = subDays(startDateTime, 1);
  let dayOfWeekHelper = {
    dayOfWeekPointer: dayAgoStartDate.getDay(),
    shiftedDays: 0
  };
  dayOfWeekHelper = shiftLessonDaySkipHoliday(dayAgoStartDate, dayOfWeekHelper, daysOfWeek);

  for (let i = 0; i < numberOfInstances; i += 1) {
    const nextDate = addDays(dayAgoStartDate, dayOfWeekHelper.shiftedDays);
    const newLesson = constructLesson(nextDate, durationInMinutes);
    lessons.push(newLesson);
    dayOfWeekHelper = shiftLessonDaySkipHoliday(dayAgoStartDate, dayOfWeekHelper, daysOfWeek);
  }

  return lessons;
}

function constructLesson(startDateTime: Date, durationInMinutes: number): FormSessionInstance {
  return {
    startDateTime: startDateTime,
    duration: convertMinutesToDuration(durationInMinutes),
    endDateTime: addMinutes(startDateTime, durationInMinutes)
  };
}

function shiftLessonDaySkipHoliday(startDate: Date, dayOfWeekHelper: any, daysOfWeek: number[], holidays = []) {
  let newDaysOfWeekHelper = dayOfWeekHelper;
  let nextDate;
  do {
    newDaysOfWeekHelper = shiftLessonDay(newDaysOfWeekHelper, daysOfWeek);
    nextDate = addDays(startDate, newDaysOfWeekHelper.shiftedDays);
  } while (isHoliday(nextDate, holidays));
  return newDaysOfWeekHelper;
}

function isHoliday(date: Date, holidays: any[] = []) {
  for (const holiday of holidays) {
    const holidayDate = new Date(holiday);
    return isEqual(holidayDate, date);
  }
  return false;
}

function shiftLessonDay(dayOfWeekHelper: any, daysOfWeek: number[]) {
  let { dayOfWeekPointer, shiftedDays } = dayOfWeekHelper;
  do {
    dayOfWeekPointer = shiftDayOfWeekPointer(dayOfWeekPointer);
    shiftedDays += 1;
  } while (!getWeekDay(dayOfWeekPointer, daysOfWeek));
  return { dayOfWeekPointer, shiftedDays };
}

function getWeekDay(dayOfWeekPointer: number, daysOfWeek: number[]) {
  const foundIndex = daysOfWeek.findIndex((dayOfWeek) => dayOfWeek === dayOfWeekPointer);
  return foundIndex > -1;
}

function shiftDayOfWeekPointer(number: number) {
  if (number < 0 || number >= 6) {
    return 0;
  }
  return number + 1;
}

export function getRegistrationLnkForActivityDependingOnActivityAndOwnerType(
  activityId: string,
  orgId: string,
  activityType: ActivityTypes = ActivityTypes.Course
) {
  return activityType === ActivityTypes.Enrichment
    ? toPath(PATHS.selectPublicSchool, { orgId: orgId, activityId: activityId })
    : toPath(PATHS.orgActivityWithSessions, {
        orgId: orgId,
        activityType: activityType.toString(),
        activityId: activityId
      });
}

export const areSessionsGaveAdditionalOptions = (sessions: EfacitySession[]) => {
  return !!sessions.find((session) => isSessionHasAdditionalOptions(session));
};

export const isSessionHasAdditionalOptions = (session: EfacitySession) => {
  return session.additionalSessionOptions && session.additionalSessionOptions.length > 0;
};
