import { matchRoutes, useLocation } from 'react-router-dom';

/* 
  checks if any of given matchPaths
  includes current path
*/
export const useMatchRoutes = (matchPaths: string[]) => {
  const location = useLocation();
  return !!matchRoutes(
    matchPaths.map(path => ({ path: path })),
    location
  );
};
