import React from 'react';
import { Link } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const EmailAddress = ({ email, mobileView = false }) => {
  if (!email) return null;

  return (
    <Link href={`mailto:${email}`} data-testid="email-link" underline={'none'}>
      {mobileView ? <MailOutlineIcon data-testid="email-icon" /> : email}
    </Link>
  );
};

export default EmailAddress;
