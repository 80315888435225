import React, { FC } from 'react';
import { Box, IconButton, Theme } from '@mui/material';
import ShrinkIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ExpandIcon from '@mui/icons-material/ArrowForwardIosOutlined';

const customDrawerStyles = {
  rootSx: {
    position: 'fixed',
    left: 0,
    height: 'calc(100% - 70px)',
    width: '260px',
    background: '#F5F6FA',
    zIndex: 10,
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: '2px 0px 10px 0px rgba(0, 0, 0, 0.39)',
    padding: '44px 0 10px',
    display: 'flex',
    flexDirection: 'column',
    transition: (theme: Theme) =>
      theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
  },
  controlButtonSx: {
    position: 'absolute',
    right: '-8px',
    top: '5px',
    display: { xs: 'none', md: 'block' },
    '& svg': {
      transition: 'all 0.4s',
      fill: (theme: Theme) => theme.palette.grey['600']
    },
    '&:hover': {
      background: 'none',
      '& svg': {
        fill: (theme: Theme) => theme.palette.primary.main
      }
    }
  },
  drawerShiftSx: {
    transition: (theme: Theme) =>
      theme.transitions.create('left', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen
      }),
    left: { md: '-235px', xs: '-260px' }
  }
};

export interface CustomDrawerProps {
  isExpanded: boolean;
  onExpand: () => void;
  onMinimize: () => void;
  children: React.ReactNode;
}

export const CustomDrawer: FC<CustomDrawerProps> = ({ children, isExpanded, onExpand, onMinimize }) => {
  const handleTogglerClick = () => {
    isExpanded ? onMinimize() : onExpand();
  };

  return (
    <Box
      sx={{ ...customDrawerStyles.rootSx, ...(isExpanded ? {} : customDrawerStyles.drawerShiftSx) }}
      data-testid="drawer"
      aria-expanded={isExpanded}
      component="nav"
    >
      <Box height="100%" flexDirection="column" display="flex">
        <IconButton
          sx={customDrawerStyles.controlButtonSx}
          disableRipple
          onClick={handleTogglerClick}
          aria-label={isExpanded ? 'Shrink menu' : 'Expand menu'}
          data-testid="toggle-menu-btn"
        >
          {isExpanded ? (
            <ShrinkIcon data-testid="shrink-icon" color="primary" fontSize="small" />
          ) : (
            <ExpandIcon data-testid="expand-icon" color="primary" fontSize="small" />
          )}
        </IconButton>
        {children}
      </Box>
    </Box>
  );
};
