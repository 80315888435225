import { MailingServer, SMTPSettings } from '@efacity/common';
import { defaultSmtpSettings } from '@efacity/frontend-next-shared/org-settings';

export interface OrganizationEmail {
  sendFromEmail: string;
  mailingServer: MailingServer;
  smtpSettings?: SMTPSettings;
}

export const organizationEmailSettingsInitialFormValues: OrganizationEmail = {
  sendFromEmail: '',
  mailingServer: MailingServer.awsSes,
  smtpSettings: defaultSmtpSettings
};
