import {
  ActivityTypes,
  CurrencyCode,
  DurationType,
  Grades,
  OwnerInterface,
  OwnerType,
  RegistrationResponse,
  Roles,
  SchoolGradeStudentLevelTypeValue,
  SessionStatus
} from '@efacity/common';
import { Session, SessionState, SessionWithLocationAndTags } from '@efacity/react-next-sc';
import { faradayLocation, mendeleevLocation, teslaLocation, virtual1Location } from './mockLocationsFixtures';

export const steamWorksSchoolId = '5ea6de9f2f37a246506d4a85';

export const efacityOwner: OwnerInterface = {
  ownerType: OwnerType.Efacity
};
export const steamWorksSchoolOwner: OwnerInterface = {
  ownerType: OwnerType.School,
  orgId: steamWorksSchoolId
};

const fixedDate = new Date(2020, 10, 12, 10, 10, 0, 0);

export const efacitySessions: Session[] = [
  {
    _id: '1',
    name: 'SCRATCH - Making Games!',
    type: ActivityTypes.Course,
    durationType: DurationType.Fixed,
    activityId: 'activityId',
    createdAt: fixedDate,
    createdBy: 'userId',
    owner: efacityOwner,
    teacherId: 'teacherId',
    sessionState: SessionState.Active,
    status: SessionStatus.Published,
    imageFullUrl: '/assets/images/scratchCourseImage_314x171.jpg',
    studentLevel: {
      type: SchoolGradeStudentLevelTypeValue,
      fromSchoolGradeNumeric: Grades.GRADE_1.sortValue,
      toSchoolGradeNumeric: Grades.GRADE_2.sortValue
    },
    location: mendeleevLocation,
    capacity: 3,
    price: 1000,
    currency: CurrencyCode.USD,
    shortDescription: `Learn to code with SCRATCH by MIT.
    Use colorful drag-and-drop coding blocks to create awesome
    animations and classic arcade games such as pong and...`,
    longDescription: `Learn to code with SCRATCH by MIT.
    Use colorful drag-and-drop coding blocks to create awesome
    animations and classic arcade games such as pong and...`,
    schedule: {
      startDateTime: fixedDate,
      endDateTime: fixedDate,
      durationInMinutes: 45,
      numberOfInstances: 1,
      instances: [
        {
          startDateTime: fixedDate,
          durationInMinutes: 45
        }
      ],
      deadlineForCustomerRegistration: fixedDate,
      lastCancellationDate: fixedDate
    },
    timeZone: 'America/New_York',
    organizationName: 'Jersey SW test center',
    registrations: [
      {
        _id: '6075a651a0cb99c793d8d5e6'
      },
      {
        _id: '6075a656ab7d4f9ca88995cf'
      },
      {
        _id: '6075a65c9d633940f29080e5'
      }
    ] as RegistrationResponse[]
    //time: '11:00 AM - 12:00 PM, Monday-Friday, 10 classes, Starts April 13th'
  },
  {
    _id: '2',
    name: 'Python & ART',
    type: ActivityTypes.Course,
    durationType: DurationType.Fixed,
    activityId: 'activityId',
    createdAt: fixedDate,
    createdBy: 'userId',
    owner: efacityOwner,
    teacherId: 'teacherId',
    sessionState: SessionState.Declined,
    status: SessionStatus.Published,
    imageFullUrl: '/assets/images/pythonCourseImage_314x171.jpg',
    studentLevel: {
      type: SchoolGradeStudentLevelTypeValue,
      fromSchoolGradeNumeric: Grades.GRADE_1.sortValue,
      toSchoolGradeNumeric: Grades.GRADE_2.sortValue
    },
    location: faradayLocation,
    capacity: 3,
    price: 1100,
    currency: CurrencyCode.USD,
    shortDescription: `Learn Python from home! This Python course introduces students Grades
    3-5 to the basic fundamental principles of programming in a step-by-step structured manner...`,
    longDescription: `Learn Python from home! This Python course introduces students Grades
    3-5 to the basic fundamental principles of programming in a step-by-step structured manner...`,
    schedule: {
      startDateTime: fixedDate,
      endDateTime: fixedDate,
      durationInMinutes: 45,
      numberOfInstances: 1,
      instances: [
        {
          startDateTime: fixedDate,
          durationInMinutes: 45
        }
      ],
      deadlineForCustomerRegistration: fixedDate,
      lastCancellationDate: fixedDate
    },
    //time: '11:00 AM - 12:00 PM, Monday-Friday, 10 classes, Starts April 13th'
    timeZone: 'America/New_York',
    organizationName: 'Jersey SW test center'
  }
];

//TODO: remove this when we have api ready
export const mockSessionsFixtures: Session[] = [
  {
    _id: '1',
    name: 'SCRATCH - Making Games!',
    type: ActivityTypes.Course,
    durationType: DurationType.Fixed,
    activityId: 'activityId',
    createdAt: fixedDate,
    createdBy: 'userId',
    owner: efacityOwner,
    teacherId: 'teacherId',
    location: mendeleevLocation,
    sessionState: SessionState.Active,
    status: SessionStatus.Published,
    imageFullUrl: '/assets/images/scratchCourseImage_314x171.jpg',
    studentLevel: {
      type: SchoolGradeStudentLevelTypeValue,
      fromSchoolGradeNumeric: Grades.GRADE_1.sortValue,
      toSchoolGradeNumeric: Grades.GRADE_2.sortValue
    },
    capacity: 3,
    price: 1000,
    currency: CurrencyCode.USD,
    shortDescription: `Learn to code with SCRATCH by MIT.
    Use colorful drag-and-drop coding blocks to create awesome
    animations and classic arcade games such as pong and...`,
    longDescription: `Learn to code with SCRATCH by MIT.
    Use colorful drag-and-drop coding blocks to create awesome
    animations and classic arcade games such as pong and...`,
    schedule: {
      startDateTime: fixedDate,
      endDateTime: fixedDate,
      durationInMinutes: 45,
      numberOfInstances: 1,
      instances: [
        {
          startDateTime: fixedDate,
          durationInMinutes: 45
        }
      ],
      deadlineForCustomerRegistration: fixedDate,
      lastCancellationDate: fixedDate
    },
    timeZone: 'America/New_York',
    organizationName: 'Jersey SW test center'
    //time: '11:00 AM - 12:00 PM, Monday-Friday, 10 classes, Starts April 13th'
  },
  {
    _id: '2',
    name: 'Python & ART',
    type: ActivityTypes.Course,
    durationType: DurationType.Fixed,
    activityId: 'activityId',
    createdAt: fixedDate,
    createdBy: 'userId',
    owner: efacityOwner,
    teacherId: 'teacherId',
    sessionState: SessionState.Active,
    status: SessionStatus.Published,
    imageFullUrl: '/assets/images/pythonCourseImage_314x171.jpg',
    studentLevel: {
      type: SchoolGradeStudentLevelTypeValue,
      fromSchoolGradeNumeric: Grades.GRADE_1.sortValue,
      toSchoolGradeNumeric: Grades.GRADE_2.sortValue
    },
    location: virtual1Location,
    capacity: 3,
    price: 1100,
    currency: CurrencyCode.USD,
    shortDescription: `Learn Python from home! This Python course introduces students Grades
    3-5 to the basic fundamental principles of programming in a step-by-step structured manner...`,
    longDescription: `Learn Python from home! This Python course introduces students Grades
    3-5 to the basic fundamental principles of programming in a step-by-step structured manner...`,
    schedule: {
      startDateTime: fixedDate,
      endDateTime: fixedDate,
      durationInMinutes: 45,
      numberOfInstances: 1,
      instances: [
        {
          startDateTime: fixedDate,
          durationInMinutes: 45
        }
      ],
      deadlineForCustomerRegistration: fixedDate,
      lastCancellationDate: fixedDate
    },
    timeZone: 'America/New_York',
    organizationName: 'Jersey SW test center'
    //time: '11:00 AM - 12:00 PM, Monday-Friday, 10 classes, Starts April 13th'
  },
  {
    _id: '3',
    name: '3D Printing & CAD',
    type: ActivityTypes.Course,
    durationType: DurationType.Fixed,
    activityId: 'activityId',
    createdAt: fixedDate,
    createdBy: 'userId',
    owner: efacityOwner,
    teacherId: 'teacherId',
    sessionState: SessionState.Active,
    status: SessionStatus.Published,
    imageFullUrl: '/assets/images/printingCourseImage_314x171.jpg',
    studentLevel: {
      type: SchoolGradeStudentLevelTypeValue,
      fromSchoolGradeNumeric: Grades.GRADE_1.sortValue,
      toSchoolGradeNumeric: Grades.GRADE_2.sortValue
    },
    location: virtual1Location,
    capacity: 3,
    price: 1000,
    currency: CurrencyCode.USD,
    shortDescription: `Learn the art of 3D Printing and Computer Aided Design! Develop design`,
    longDescription: `Learn the art of 3D Printing and Computer Aided Design! Develop design`,
    schedule: {
      startDateTime: fixedDate,
      endDateTime: fixedDate,
      durationInMinutes: 45,
      numberOfInstances: 1,
      instances: [
        {
          startDateTime: fixedDate,
          durationInMinutes: 45
        }
      ],
      deadlineForCustomerRegistration: fixedDate,
      lastCancellationDate: fixedDate
    },
    timeZone: 'America/New_York',
    organizationName: 'Jersey SW test center'
    //time: '11:00 AM - 12:00 PM, Monday-Friday, 10 classes, Starts April 13th'
  },
  {
    _id: '4',
    name: '3D Printing & CAD',
    type: ActivityTypes.Course,
    durationType: DurationType.Fixed,
    activityId: 'activityId',
    createdAt: fixedDate,
    createdBy: 'userId',
    owner: efacityOwner,
    teacherId: 'teacherId',
    sessionState: SessionState.Active,
    status: SessionStatus.Published,
    imageFullUrl: '/assets/images/printingCourseImage_314x171.jpg',
    studentLevel: {
      type: SchoolGradeStudentLevelTypeValue,
      fromSchoolGradeNumeric: Grades.GRADE_1.sortValue,
      toSchoolGradeNumeric: Grades.GRADE_2.sortValue
    },
    location: mendeleevLocation,
    capacity: 3,
    price: 1000,
    currency: CurrencyCode.USD,
    shortDescription: `Learn the art of 3D Printing and Computer Aided Design! Develop design`,
    longDescription: `Learn the art of 3D Printing and Computer Aided Design! Develop design`,
    schedule: {
      startDateTime: fixedDate,
      endDateTime: fixedDate,
      durationInMinutes: 45,
      numberOfInstances: 1,
      instances: [
        {
          startDateTime: fixedDate,
          durationInMinutes: 45
        }
      ],
      deadlineForCustomerRegistration: fixedDate,
      lastCancellationDate: fixedDate
    },
    timeZone: 'America/New_York',
    organizationName: 'Jersey SW test center'
    //time: '11:00 AM - 12:00 PM, Monday-Friday, 10 classes, Starts April 13th'
  },
  {
    _id: '5',
    name: '3D Printing & CAD',
    type: ActivityTypes.Course,
    durationType: DurationType.Fixed,
    activityId: 'activityId',
    createdAt: fixedDate,
    createdBy: 'userId',
    owner: efacityOwner,
    teacherId: 'teacherId',
    sessionState: SessionState.Active,
    status: SessionStatus.Published,
    imageFullUrl: '/assets/images/printingCourseImage_314x171.jpg',
    studentLevel: {
      type: SchoolGradeStudentLevelTypeValue,
      fromSchoolGradeNumeric: Grades.GRADE_1.sortValue,
      toSchoolGradeNumeric: Grades.GRADE_2.sortValue
    },
    location: faradayLocation,
    capacity: 3,
    price: 1000,
    currency: CurrencyCode.USD,
    shortDescription: `Learn the art of 3D Printing and Computer Aided Design! Develop design`,
    longDescription: `Learn the art of 3D Printing and Computer Aided Design! Develop design`,
    schedule: {
      startDateTime: fixedDate,
      endDateTime: fixedDate,
      durationInMinutes: 45,
      numberOfInstances: 1,
      instances: [
        {
          startDateTime: fixedDate,
          durationInMinutes: 45
        }
      ],
      deadlineForCustomerRegistration: fixedDate,
      lastCancellationDate: fixedDate
    },
    timeZone: 'America/New_York',
    organizationName: 'Jersey SW test center'
    //time: '11:00 AM - 12:00 PM, Monday-Friday, 10 classes, Starts April 13th'
  },
  {
    _id: '6',
    name: '3D Printing & CAD',
    type: ActivityTypes.Course,
    durationType: DurationType.Fixed,
    activityId: 'activityId',
    createdAt: fixedDate,
    createdBy: 'userId',
    owner: efacityOwner,
    teacherId: 'teacherId',
    sessionState: SessionState.Active,
    status: SessionStatus.Published,
    imageFullUrl: '/assets/images/printingCourseImage_314x171.jpg',
    studentLevel: {
      type: SchoolGradeStudentLevelTypeValue,
      fromSchoolGradeNumeric: Grades.GRADE_1.sortValue,
      toSchoolGradeNumeric: Grades.GRADE_2.sortValue
    },
    location: faradayLocation,
    capacity: 3,
    price: 1000,
    currency: CurrencyCode.USD,
    shortDescription: `Learn the art of 3D Printing and Computer Aided Design! Develop design`,
    longDescription: `Learn the art of 3D Printing and Computer Aided Design! Develop design`,
    schedule: {
      startDateTime: fixedDate,
      endDateTime: fixedDate,
      durationInMinutes: 45,
      numberOfInstances: 1,
      instances: [
        {
          startDateTime: fixedDate,
          durationInMinutes: 45
        }
      ],
      deadlineForCustomerRegistration: fixedDate,
      lastCancellationDate: fixedDate
    },
    timeZone: 'America/New_York',
    organizationName: 'Jersey SW test center'
    //time: '11:00 AM - 12:00 PM, Monday-Friday, 10 classes, Starts April 13th'
  },
  {
    _id: '7',
    name: '3D Printing & CAD',
    type: ActivityTypes.Course,
    durationType: DurationType.Fixed,
    activityId: 'activityId',
    createdAt: fixedDate,
    createdBy: 'userId',
    owner: efacityOwner,
    teacherId: 'teacherId',
    sessionState: SessionState.Active,
    status: SessionStatus.Published,
    imageFullUrl: '/assets/images/printingCourseImage_314x171.jpg',
    studentLevel: {
      type: SchoolGradeStudentLevelTypeValue,
      fromSchoolGradeNumeric: Grades.GRADE_1.sortValue,
      toSchoolGradeNumeric: Grades.GRADE_2.sortValue
    },
    location: faradayLocation,
    capacity: 3,
    price: 1000,
    currency: CurrencyCode.USD,
    shortDescription: `Learn the art of 3D Printing and Computer Aided Design! Develop design`,
    longDescription: `Learn the art of 3D Printing and Computer Aided Design! Develop design`,
    schedule: {
      startDateTime: fixedDate,
      endDateTime: fixedDate,
      durationInMinutes: 45,
      numberOfInstances: 1,
      instances: [
        {
          startDateTime: fixedDate,
          durationInMinutes: 45
        }
      ],
      deadlineForCustomerRegistration: fixedDate,
      lastCancellationDate: fixedDate
    },
    timeZone: 'America/New_York',
    organizationName: 'Jersey SW test center'
    //time: '11:00 AM - 12:00 PM, Monday-Friday, 10 classes, Starts April 13th'
  }
];

export const steamworksSessions: Session[] = [
  {
    _id: '1',
    name: 'SCRATCH - Making Games Camp!',
    type: ActivityTypes.Camp,
    durationType: DurationType.Fixed,
    activityId: 'activityId',
    createdAt: fixedDate,
    createdBy: 'userId',
    owner: steamWorksSchoolOwner,
    teacherId: 'teacherId',
    sessionState: SessionState.Active,
    status: SessionStatus.Published,
    imageFullUrl: '/assets/images/scratchCourseImage_314x171.jpg',
    studentLevel: {
      type: SchoolGradeStudentLevelTypeValue,
      fromSchoolGradeNumeric: Grades.GRADE_1.sortValue,
      toSchoolGradeNumeric: Grades.GRADE_2.sortValue
    },
    location: virtual1Location,
    capacity: 3,
    price: 1000,
    currency: CurrencyCode.USD,
    shortDescription: `Learn to code with SCRATCH by MIT.
    Use colorful drag-and-drop coding blocks to create awesome
    animations and classic arcade games such as pong and...`,
    longDescription: `Learn to code with SCRATCH by MIT.
    Use colorful drag-and-drop coding blocks to create awesome
    animations and classic arcade games such as pong and...`,
    schedule: {
      startDateTime: fixedDate,
      endDateTime: fixedDate,
      durationInMinutes: 45,
      numberOfInstances: 1,
      instances: [
        {
          startDateTime: fixedDate,
          durationInMinutes: 45
        }
      ],
      deadlineForCustomerRegistration: fixedDate,
      lastCancellationDate: fixedDate
    },
    timeZone: 'America/New_York',
    organizationName: 'Jersey SW test center'
    //time: '11:00 AM - 12:00 PM, Monday-Friday, 10 classes, Starts April 13th'
  },
  {
    _id: '2',
    name: 'Python & ART',
    type: ActivityTypes.Course,
    durationType: DurationType.Fixed,
    activityId: 'activityId',
    createdAt: fixedDate,
    createdBy: 'userId',
    owner: steamWorksSchoolOwner,
    teacherId: 'teacherId',
    sessionState: SessionState.Active,
    status: SessionStatus.Published,
    imageFullUrl: '/assets/images/pythonCourseImage_314x171.jpg',
    studentLevel: {
      type: SchoolGradeStudentLevelTypeValue,
      fromSchoolGradeNumeric: Grades.GRADE_1.sortValue,
      toSchoolGradeNumeric: Grades.GRADE_2.sortValue
    },
    location: mendeleevLocation,
    capacity: 3,
    price: 1100,
    currency: CurrencyCode.USD,
    shortDescription: `Learn Python from home! This Python course introduces students Grades
    3-5 to the basic fundamental principles of programming in a step-by-step structured manner...`,
    longDescription: `Learn Python from home! This Python course introduces students Grades
    3-5 to the basic fundamental principles of programming in a step-by-step structured manner...`,
    schedule: {
      startDateTime: fixedDate,
      endDateTime: fixedDate,
      durationInMinutes: 45,
      numberOfInstances: 1,
      instances: [
        {
          startDateTime: fixedDate,
          durationInMinutes: 45
        }
      ],
      deadlineForCustomerRegistration: fixedDate,
      lastCancellationDate: fixedDate
    },
    timeZone: 'America/New_York',
    organizationName: 'Jersey SW test center'
    //time: '11:00 AM - 12:00 PM, Monday-Friday, 10 classes, Starts April 13th'
  }
];

export const sessionsWithoutSchedule: SessionWithLocationAndTags[] = [
  {
    _id: '5fb242ee1fc78a37264b27ec',
    price: 30000,
    capacity: 1,
    shortDescription: '',
    longDescription: '',
    location: teslaLocation,
    currency: CurrencyCode.USD,
    activityId: '5ee31a1f707484c05311d322',
    name: 'Angular advanced Activity hey ho',
    type: ActivityTypes.Course,
    durationType: DurationType.Fixed,
    studentLevel: {
      type: SchoolGradeStudentLevelTypeValue,
      fromSchoolGradeNumeric: Grades.GRADE_2.sortValue,
      toSchoolGradeNumeric: Grades.GRADE_3.sortValue
    },
    teacherId: '5e95e4bf8dbba44fdeed366d',
    owner: { orgId: '5ea6de9f2f37a246506d4a85', ownerType: OwnerType.School },
    status: SessionStatus.Private,
    createdBy: '5e95e4a58dbba44fdee9365c',
    createdAt: new Date(),
    sessionState: SessionState.Suspended,
    teacher: {
      _id: '5e95e4bf8dbba44fdeed366d',
      email: 'school.teacher@gmail.com',
      fullName: 'School Teacher',
      callByName: 'Teacher',
      createdAt: '2020-11-16T09:10:33.218Z',
      updatedAt: '2020-11-16T09:10:33.218Z',
      roles: [Roles.SchoolTeacher]
    },
    timeZone: 'America/New_York',
    organizationName: 'Jersey SW test center'
  }
];
