import { Box, Typography } from '@mui/material';
import { Skeleton } from '@mui/material';
import React from 'react';
import contactInfoModalSx from './contactInfoModalStyles';

const ContentLoadingIndicator = () => {
  return (
    <Box data-testid="loader-skeleton">
      <Typography variant="h6" align="center" gutterBottom>
        Attendee Information
      </Typography>
      <Box sx={contactInfoModalSx.attendeeTable}>
        <Box>
          <Skeleton height={24} />
          <Skeleton height={24} />
        </Box>
        <Box>
          <Skeleton height={24} />
          <Skeleton height={24} />
        </Box>
        <Box>
          <Skeleton height={24} />
          <Skeleton height={24} />
        </Box>
      </Box>
    </Box>
  );
};

export default ContentLoadingIndicator;
