import React, { FC, useState } from 'react';
import { Autocomplete, Button } from '@mui/material';
import { Teacher } from '@efacity/frontend-shared';
import { useFormContext } from 'react-hook-form';
import { defaultRHFSetValueOptions, FormTextInput } from '@efacity/react-hook-form-mui';
import AddIcon from '@mui/icons-material/Add';
import AddInstructorModal from '../../../components/AddInstructorModal/AddInstructorModal';
import sessionFormSx from './sessionFormSx';
import { useTeacherOptions } from '../../../hooks/useTeacherOptions';
import { SessionMainInfoFormValues } from './SessionFormValues';

const instructorSelectSx = {
  marginBottom: '15px',
  '& fieldset': {
    borderRadius: '2px',
    borderWidth: '1px !important'
  }
};

export const getInstructorLabel = (instructor) => {
  let label = `${instructor.fullName} ${instructor.email}`;
  if (instructor?.certificates?.length > 0) label += ' (certificated)';
  return label;
};

const sortInstructorsByName = (instructor1: Teacher, instructor2: Teacher) => {
  return instructor1.fullName > instructor2.fullName ? 1 : -1;
};

interface FormInstructorSelectorProps {
  name: keyof SessionMainInfoFormValues;
  label: string;
  disabled: boolean;
  orgId: string;
  sessionId: string | null;
  workingActivityId: string;
  showAddButton?: boolean;
}

const FormInstructorSelector: FC<FormInstructorSelectorProps> = ({
  name,
  label,
  disabled,
  orgId,
  sessionId = null,
  workingActivityId,
  showAddButton = true
}) => {
  const [teacherModalOpen, setTeacherModalOpen] = useState(false);
  const { teacherState, addTeacherToOptions } = useTeacherOptions(
    `/org/${orgId}/sessions/teachers-options`,
    workingActivityId,
    sessionId
  );
  const { setValue, getValues } = useFormContext();

  const instructors = teacherState.options;
  const teacherId = getValues(name);
  const teacher = instructors.find((item) => item?._id === teacherId);

  return (
    <>
      <Autocomplete
        id="select-instructor"
        value={teacher || null}
        options={instructors.sort(sortInstructorsByName)}
        getOptionLabel={getInstructorLabel}
        disabled={disabled}
        fullWidth
        onChange={(_, instructor: Teacher) => {
          setValue(name, instructor?._id || '', defaultRHFSetValueOptions);
        }}
        renderInput={(params) => <FormTextInput {...params} name={name} label={label} sx={instructorSelectSx} />}
      />
      {showAddButton && (
        <Button
          startIcon={<AddIcon style={{ marginLeft: 10 }} />}
          color="primary"
          variant="contained"
          sx={{ ...sessionFormSx.newTeacherBtn, marginTop: '-11px' }}
          onClick={() => setTeacherModalOpen(true)}
          disabled={disabled}
        >
          <div style={{ paddingRight: 10 }}>new instructor</div>
        </Button>
      )}
      {teacherModalOpen && (
        <AddInstructorModal
          open={teacherModalOpen}
          orgId={orgId}
          toggleModalVisible={setTeacherModalOpen}
          instructorOptions={teacherState.options}
          addTeacherOption={addTeacherToOptions}
          setTeacherFieldValue={setValue}
          fieldName={name}
        />
      )}
    </>
  );
};

export default FormInstructorSelector;
