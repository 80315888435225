import React from 'react';
import { Button, Dialog, DialogContent, DialogProps } from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Check';
import { HorizontalField } from '@efacity/frontend-shared';
import { ModalDialogTitle } from '@efacity/react-next-sc';
import { TextField } from '@mui/material';
import { isEmail } from 'class-validator';
import { groupWrapperStyle, textFieldStyle } from '@efacity/react-hook-form-mui';

const confirmationModalStyles = {
  button: {
    width: '150px',
    height: '32px',
    textTransform: 'uppercase',
    fontSize: 14
  } as React.CSSProperties,
  nameContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexGrow: 1,
    marginRight: 0
  }
};

export interface SendFromEmailModalProps extends DialogProps {
  open: boolean;
  email: string;
  onClose: () => void;
  onConfirmEmail: (newEmail: string) => void;
}

const SendFromEmailModal: React.FC<SendFromEmailModalProps> = ({ open, email, onClose, onConfirmEmail }) => {
  const [newEmail, setNewEmail] = React.useState(email);
  const emailChanged = (event) => {
    email = event.target.value;
    setNewEmail(email);
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth data-testid="confirmation-modal" disableEscapeKeyDown>
      <ModalDialogTitle text="Change email" onClose={onClose} />
      <DialogContent>
        <hr style={{ height: 2, marginTop: -5, marginBottom: 0 }} />
        <div style={{ ...groupWrapperStyle, marginBottom: 20 }}>
          <TextField
            size="small"
            label="Email"
            variant="outlined"
            fullWidth
            onChange={emailChanged}
            value={newEmail}
            inputProps={{
              'data-testid': 'send-from-email-input'
            }}
            helperText={isEmail(newEmail) ? '' : 'Invalid email'}
            error={!isEmail(newEmail)}
            sx={textFieldStyle}
          />
        </div>

        <hr style={{ height: 2, marginBottom: 15 }} />
        <HorizontalField justifyContent="center" wrapOnSmallScreen={false} style={{ padding: 9, width: '100%' }}>
          <Button
            style={confirmationModalStyles.button}
            onClick={onClose}
            color="primary"
            variant="outlined"
            disabled={!isEmail(newEmail)}
            aria-label="Cancel"
          >
            <CancelOutlined style={{ color: 'primary', marginRight: 10 }} />
            Cancel
          </Button>
          <Button
            style={confirmationModalStyles.button}
            color="primary"
            variant="contained"
            onClick={() => onConfirmEmail(newEmail)}
            disabled={!isEmail(newEmail)}
            data-testid="confirm-send-from-email-button"
            aria-label="Confirm"
          >
            <>
              <SaveIcon style={{ marginRight: 10 }} />
              Confirm
            </>
          </Button>
        </HorizontalField>
      </DialogContent>
    </Dialog>
  );
};

export default SendFromEmailModal;
