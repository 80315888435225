import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import SaveIcon from '@mui/icons-material/Check';

const submitButtonStyle = {
  width: '171px',
  height: '36px',
  textTransform: 'uppercase'
} as React.CSSProperties;

interface SubmitMediumButtonProps {
  disabled: boolean;
  isSubmitting: boolean;
  children: React.ReactNode;
}

export const SubmitMediumButton: React.FC<SubmitMediumButtonProps> = ({
  disabled,
  isSubmitting,
  children
}) => {
  return (
    <Button
      style={submitButtonStyle}
      type="submit"
      color="primary"
      variant="contained"
      data-testid="submitBtn"
      disabled={disabled}
    >
      {isSubmitting ? (
        <CircularProgress
          variant="indeterminate"
          data-testid="loader"
          size={20}
          style={{ marginRight: 20, color: 'white' }}
        />
      ) : (
        <SaveIcon style={{ marginRight: 20 }} />
      )}
      {children}
    </Button>
  );
};
