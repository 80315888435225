import { PATHS, toPath } from '@efacity/routing';
import React from 'react';
import { useParams } from 'react-router-dom';
import { IdOrgIdParamTypes } from '@efacity/frontend-next-shared/utils';
import { Outlet } from 'react-router-dom';
import { PageSubtitle } from '../../components/PageSubtitle/PageSubtitle';
import { useMatchRoutes } from '../../hooks/useMatchRoutes';

const AdditionalSessionOptionsContainer: React.FC = () => {
  const { orgId } = useParams<IdOrgIdParamTypes>();

  const addButtonLink = orgId ? toPath(PATHS.addAdditionalOption, { orgId: orgId }) : PATHS.addAdditionalOption;

  const tabs = [
    {
      label: 'Current',
      to: 'current',
      matchPaths: [PATHS.additionalOptionsCurrent]
    },
    { label: 'Expired', to: 'expired', matchPaths: [PATHS.additionalOptionsExpired] }
  ];

  const isCurrentTab = useMatchRoutes([PATHS.additionalOptionsCurrent]);

  return (
    <div style={{ marginTop: '15px' }}>
      <PageSubtitle tabs={tabs} addBtnLink={isCurrentTab ? addButtonLink : null} />
      <Outlet />
    </div>
  );
};

export default AdditionalSessionOptionsContainer;
