import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import ArrowForward from '@mui/icons-material/ArrowForward';

export interface ExportButtonProps {
  isExporting: boolean;
  color: 'inherit' | 'primary' | 'secondary';
  onClick: () => void;
}

export const ExportButton: React.FC<ExportButtonProps> = ({ isExporting, onClick }) => {
  return (
    <Button
      type="submit"
      variant="contained"
      color={'primary'}
      disabled={isExporting}
      onClick={onClick}
      style={{ width: 120, height: 37 }}
      startIcon={!isExporting && <ArrowForward />}
      data-testid="start-export-submit-btn"
    >
      {isExporting ? (
        <CircularProgress variant="indeterminate" size={20} data-testid="progress-in-button" />
      ) : (
        <span>Export</span>
      )}
    </Button>
  );
};
