import {
  ShoppingCartIconWithTable,
  setKitPurchaseProgress,
  setRegistrationProgress,
  useAuth
} from '@efacity/react-next-sc';
import { PATHS } from '@efacity/routing';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const ShoppingCartIconContainer: FC = () => {
  const [isClearing, setIsClearing] = useState(false);
  const {
    authState: {
      extraAuthInfo: { shoppingCart, startRegistrationURL }
    },
    clearShoppingCartOnServer
  } = useAuth();
  const navigate = useNavigate();

  const clearShoppingCart = async () => {
    setIsClearing(true);
    await clearShoppingCartOnServer();
    setRegistrationProgress(false);
    setKitPurchaseProgress(false);
    setIsClearing(false);
    window.location.href = startRegistrationURL ? startRegistrationURL : PATHS.landing;
  };

  const gotoShoppingCartPage = () => {
    navigate(PATHS.cart);
  };

  return (
    <ShoppingCartIconWithTable
      shoppingCart={shoppingCart}
      isClearing={isClearing}
      clearShoppingCart={clearShoppingCart}
      gotoShoppingCartPage={gotoShoppingCartPage}
    />
  );
};
