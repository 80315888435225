import { SessionStatus } from '@efacity/common';
import {
  mediumButtonFontSize,
  mediumButtonHeight,
  smallButtonFontSize,
  smallButtonHeight
} from '@efacity/frontend-shared';
import { LightTooltip } from '@efacity/react-next-sc';
import Lock from '@mui/icons-material/Lock';
import Public from '@mui/icons-material/Public';
import { Button, ButtonProps, CircularProgress, Typography } from '@mui/material';
import React from 'react';

export const mediumPublishButtonStyle: React.CSSProperties = {
  width: 60,
  textTransform: 'uppercase',
  marginLeft: 5,
  marginRight: 5,
  ...mediumButtonHeight,
  ...mediumButtonFontSize
};

export const smallPublishButtonStyle: React.CSSProperties = {
  width: 60,
  textTransform: 'uppercase',
  marginLeft: 5,
  marginRight: 5,
  padding: 0,
  ...smallButtonHeight,
  ...smallButtonFontSize
};

interface SessionPublishButtonProps {
  onClick: (sessionStatus: SessionStatus) => void;
  disabled: boolean;
  progress: boolean;
  sessionStatus: SessionStatus;
}

const SessionPublishButton: React.FC<Omit<ButtonProps, 'onClick'> & SessionPublishButtonProps> = ({
  onClick,
  disabled,
  progress,
  sessionStatus,
  ...props
}) => {
  return (
    <Button
      color="primary"
      variant="contained"
      data-testid="publishButtonId"
      onClick={() => onClick(sessionStatus === SessionStatus.Private ? SessionStatus.Published : SessionStatus.Private)}
      disabled={disabled || progress}
      {...props}
    >
      {progress ? (
        <CircularProgress variant="indeterminate" size={20} color={'primary'} data-testid="progress-in-button" />
      ) : (
        <LightTooltip
          title={
            <Typography>
              {sessionStatus === SessionStatus.Private ? 'Switch to public' : 'Switch to private'}
            </Typography>
          }
        >
          <span>{sessionStatus === SessionStatus.Private ? <Lock /> : <Public />}</span>
        </LightTooltip>
      )}
    </Button>
  );
};

export default SessionPublishButton;
