import React from 'react';
import { Box, Typography } from '@mui/material';

const formTitleSx = {
  googleFormWrapper: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  googleFormTitle: {
    textAlign: 'center',
    color: '#777777'
  },
  border: {
    borderBottom: '1px solid #C4C4C4'
  }
};

export interface FormTitleProps {
  title: string;
  withBorder: boolean;
}

export const FormTitle: React.FC<FormTitleProps> = ({ title, withBorder }) => {
  return (
    <Box sx={{ ...formTitleSx.googleFormWrapper, ...(withBorder && formTitleSx.border) }}>
      <Typography variant="h5" sx={formTitleSx.googleFormTitle}>
        {title}
      </Typography>
    </Box>
  );
};
