import { ActivityTypes, ShoppingCartRegistration } from '@efacity/common';
import { GtagInvoiceItem } from './GtagInvoiceItem';

export const getShoppingCartRegistrationsForGtag = (shoppingCartRegistrations: ShoppingCartRegistration[]) => {
  const gtagItems: GtagInvoiceItem[] = [];
  shoppingCartRegistrations.forEach((item) => {
    const mainItem: GtagInvoiceItem = {
      item_id: item.session._id,
      item_brand: item.session.orgName,
      item_name: item.session.name,
      item_category: 'Sessions',
      item_category2: item.session.type as ActivityTypes,
      price: item.session.price / 100
    };

    let additionalOptionsItems: GtagInvoiceItem[] = [];
    if (item?.additionalSessionOptions?.length !== undefined && item.additionalSessionOptions.length > 0) {
      additionalOptionsItems = item.additionalSessionOptions.map((additionalOption) => ({
        item_id: additionalOption._id,
        item_brand: item.session.orgName,
        item_name: additionalOption.name,
        item_category: 'Additional session options',
        price: additionalOption.price / 100
      }));
    }
    gtagItems.push(mainItem, ...additionalOptionsItems);
  });

  return gtagItems;
};
