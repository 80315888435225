import { currencies } from '@efacity/common';
import { MenuItem } from '@mui/material';
import React from 'react';
import { FormTextInput } from './FormTextInput';

interface CurrencyProps {
  name: string;
  label: string;
  disabled?: boolean;
}

export const CurrencyInput: React.FC<CurrencyProps> = ({ name, label, disabled = false, ...props }: CurrencyProps) => {
  return (
    <FormTextInput select name={name} label={label} disabled={disabled} {...props}>
      {currencies.map((currency) => (
        <MenuItem key={currency.currencyCode} value={currency.currencyCode}>
          {`${currency.currencyCode}, ${currency.country}`}
        </MenuItem>
      ))}
    </FormTextInput>
  );
};
