import { Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import { formatDateWithFormatter, formatTimeToHhMmNonBreakableString, monthDayDateFormat } from '@efacity/common';
import { isDate } from 'date-fns';

export interface DateCellProps {
  dateTime: Date | string;
  textColor?: TypographyProps['color'];
  showDate?: boolean;
  showTime?: boolean;
  wrap?: boolean;
  yearOnNewLine?: boolean;
  style?: React.CSSProperties;
}

const DateCell: FC<DateCellProps> = ({
  dateTime,
  textColor = 'initial',
  yearOnNewLine = true,
  showDate = true,
  showTime = true,
  wrap = false,
  style
}) => {
  return isDate(dateTime) ? (
    <Typography color={textColor} style={{ ...style }}>
      <span style={{ whiteSpace: wrap ? 'initial' : 'nowrap' }}>
        {showDate && `${formatDateWithFormatter(dateTime, monthDayDateFormat)},`}
      </span>{' '}
      {yearOnNewLine && <br />}
      {formatDateWithFormatter(dateTime, 'yyyy')}
      <span style={{ whiteSpace: wrap ? 'initial' : 'nowrap' }}>
        {showTime && `, ${formatTimeToHhMmNonBreakableString(dateTime)}`}
      </span>
    </Typography>
  ) : (
    <Typography style={{ ...adminSectionTableFontSize }}>[n/a]</Typography>
  );
};

export default DateCell;
