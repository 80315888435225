import { useEffect, useState } from 'react';
import { apiService } from '@efacity/frontend-shared';
import { ActivityOption } from '@efacity/frontend-shared';

export interface ActivityState {
  options: ActivityOption[];
  loading: boolean;
}

export const useActivityOptions = (accessId: string) => {
  const [activityState, setActivityState] = useState<ActivityState>({
    options: [],
    loading: true
  });

  useEffect(() => {
    const getActivitiesOptions = async (orgId: string) => {
      const { data } = await apiService.get<ActivityOption[]>(`/org/${orgId}/activities/enabledForSessions`);
      setActivityState(() => ({
        options: data,
        loading: false
      }));
    };
    getActivitiesOptions(accessId);
  }, [accessId]);

  return {
    activityState: activityState,
    setActivityState: setActivityState
  };
};
