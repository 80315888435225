export const randomString = (
  length,
  charSet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
) => {
  let randomString = '';
  for (let i = 0, n = charSet.length; i < length; ++i) {
    randomString += charSet.charAt(Math.floor(Math.random() * n));
  }
  return randomString;
};
