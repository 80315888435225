import { Types } from 'mongoose';
import { CurrencyCode } from './Currencies';
import { InvoiceBalance } from './Payments';

export class ProductPurchaseInterface {
  _id?: Types.ObjectId | string;
  orgId?: Types.ObjectId | string;

  price: number;
  currencyCode: CurrencyCode;

  productId: Types.ObjectId | string;
  productName: string;
  customerId: Types.ObjectId | string;
  customerName: string;
  organizationName: string;
  invoiceItemId: Types.ObjectId | string;

  status: PurchaseStatus;
  updatedAt: Date;
  orderedAt: Date;
  paidAt?: Date;
  shippedAt?: Date;
  deliveryConfirmedByAdminAt?: Date;
  deliveryConfirmedByCustomerAt?: Date;
  cancelledAt?: Date;

  shippedByAdminId?: Types.ObjectId | string;
  deliveryConfirmedByAdminId?: Types.ObjectId | string;
  deliveryConfirmedByCustomerId?: Types.ObjectId | string;
  cancelledByAdminId?: Types.ObjectId | string;

  /*product?: ProductInterface;
  invoice?: Invoice;
  customer?: Customer;
  organization?: Organization;*/
  invoiceBalance?: InvoiceBalance | null;
}

export enum PurchaseStatus {
  Ordered = 'ordered',
  Paid = 'paid',
  Shipped = 'shipped',
  Delivered = 'delivered',
  Cancelled = 'cancelled'
}

export const purchaseStatusViewConnector = {
  [PurchaseStatus.Ordered]: 'Ordered',
  [PurchaseStatus.Paid]: 'Paid',
  [PurchaseStatus.Shipped]: 'Shipped',
  [PurchaseStatus.Delivered]: 'Delivered',
  [PurchaseStatus.Cancelled]: 'Cancelled'
};
