import { adminSectionTableFontSize } from '../styles/adminSectionTableStyles';
import { Typography } from '@mui/material';
import React, { FC } from 'react';

export interface SessionCapacityRangeProps {
  registrationsAmount?: number;
  capacity?: number;
}

export const SessionCapacityRange: FC<SessionCapacityRangeProps> = ({
  registrationsAmount,
  capacity
}) => {
  const isFull = registrationsAmount >= capacity;
  return registrationsAmount >= 0 && capacity >= 0 ? (
    <Typography color={isFull ? 'error' : 'inherit'} style={adminSectionTableFontSize}>
      {registrationsAmount} / {capacity}
    </Typography>
  ) : null;
};
