import React, { FC } from 'react';
import {
  isStudentSelectedOnSessionInShoppingCart,
  ShoppingCart,
  StudentOnSessionRegistrationInfo,
  User
} from '@efacity/common';
import SelectStudentCheckbox from './SelectStudentCheckBox';

export interface SelectStudentsListProps {
  shoppingCart: ShoppingCart;
  students: User[];
  existedRegistrationsForSession: StudentOnSessionRegistrationInfo[];
  sessionId: string;
  isRegistering: boolean;
  handleClickRegisterOnSession: (studentId: string, sessionId: string, isChecked: boolean) => void;
}

const SelectStudentsList: FC<SelectStudentsListProps> = ({
  shoppingCart,
  students,
  existedRegistrationsForSession,
  sessionId,
  isRegistering,
  handleClickRegisterOnSession
}) => {
  return (
    <>
      {existedRegistrationsForSession.map((registrationInfo: StudentOnSessionRegistrationInfo) => {
        const student: User = students.find((student: User) => student._id === registrationInfo.studentId);

        return (
          <SelectStudentCheckbox
            key={`selectStudent${student._id}OnSession${sessionId}Key`}
            sessionId={sessionId}
            student={student}
            registrationInfo={registrationInfo}
            isStudentSelectedOnSessionInShoppingCart={isStudentSelectedOnSessionInShoppingCart(
              shoppingCart,
              student._id,
              sessionId
            )}
            isRegistering={isRegistering}
            handleClickRegisterOnSession={handleClickRegisterOnSession}
          />
        );
      })}
    </>
  );
};

export default SelectStudentsList;
