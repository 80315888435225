export const headerHeight = 70;
export const defaultLogoWidth = 225;
export const defaultLogoHeight = 50;
export const defaultMobileLogoWidth = 60;
export const defaultMobileLogoHeight = 60;

export const headerSmallLogoStyle = {
  maxWidth: defaultMobileLogoWidth,
  maxHeight: defaultMobileLogoHeight
};
export const headerLogoStyle = {
  maxWidth: defaultLogoWidth,
  maxHeight: defaultLogoHeight
};

export const defaultImageWidth = 358;
export const defaultImageHeight = 358;

export const userProfilePictureWidth = 360;
export const userProfilePictureHeight = 360;
export const thumbMediaWidth = 330;
export const newThumbMediaWidth = 375;
export const thumbMediaHeight = 330;

export const productThumbMediaWidth = 300;
export const productThumbMediaHeight = 300;

export const mediumThumbMediaWidth = 150;
export const mediumThumbMediaHeight = 150;
export const smallThumbMediaWidth = 120; //100;
export const smallThumbMediaHeight = 120; //100;

export const acceptableMimeTypes = 'image/x-png,image/jpeg,image/svg+xml,image/webp';
export const allowedFileSizeInBytes = Math.pow(2, 20);

export interface Media {
  link?: string;
  blob?: Blob;
  previewUrl?: string;
}

export const isImage = (name: string) => {
  return /\.(gif|jpg|jpeg|tiff|png|webp)$/i.test(name);
};

export const getYoutubeId = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

export const getYoutubeVideUrlByCompactUrl = (url: string) => {
  return `https://www.youtube.com/embed/${getYoutubeId(url)}`;
};

export const getYoutubeThumbImageUrl = (url: string) => {
  return `https://img.youtube.com/vi/${getYoutubeId(url)}/0.jpg`;
};

export const displaySmUpSx = {
  display: { xs: 'none', sm: 'block' }
};
export const displayXsSx = {
  display: { xs: 'block', sm: 'none' }
};

export const displayMdUpSx = {
  display: { xs: 'none', sm: 'none', md: 'block' }
};
export const displaySmDownSx = {
  display: { sm: 'block', md: 'none' }
};
