import React, { useMemo, useState } from 'react';
import Table, { EnhancedColumn, PaginationTypes } from '../../components/Table/Table';
import { useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { moneyWithCurrencyCodeFormatter } from '@efacity/common';
import { Collapse, IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CreditsToRefundTable from './CreditsToRefundTable';
import EmailAddress from '../../components/EmailAddress/EmailAddress';
import { useOrganizationCreditsLoader } from './useOrganizationCreditsLoader';
import { PageTitle } from '@efacity/react-next-sc';

const OrganizationCredits: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const {
    organizationCreditsState: { list: creditsWithCustomers, total, isLoading },
    getOrganizationCredits
  } = useOrganizationCreditsLoader(`/org/${orgId}/credits`);

  const columns = useMemo((): EnhancedColumn<any>[] => {
    return [
      {
        Header: 'Full Name',
        accessor: 'creditFor.fullName',
        columnStyles: {
          verticalAlign: 'top',
          paddingTop: 10
        }
      },
      {
        Header: 'Email',
        accessor: 'creditFor.email',
        columnStyles: {
          verticalAlign: 'top',
          paddingTop: 10
        },
        Cell: ({ value }) => {
          return <EmailAddress email={value} />;
        }
      },
      {
        Header: 'Credit',
        columnStyles: {
          textAlign: 'right'
        },
        accessor: (data) => moneyWithCurrencyCodeFormatter(data.amount, data.currencyCode),
        Cell: ({ row, value: name }) => {
          const [open, setOpen] = useState(false);
          return (
            <>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <div style={{ paddingTop: 5, paddingRight: row?.original?.creditFromPayments?.length > 0 ? 10 : 40 }}>
                  {moneyWithCurrencyCodeFormatter(row.original.amount, row.original.currencyCode)}
                </div>
                {row?.original?.creditFromPayments?.length > 0 && (
                  <div>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpen(!open)}
                      data-testid="collapseButton"
                    >
                      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </div>
                )}
              </div>
              <Collapse in={open} style={{ display: 'block' }}>
                <CreditsToRefundTable
                  orgId={orgId}
                  creditId={row.original?._id}
                  creditFromPayments={row?.original?.creditFromPayments || []}
                  currencyCode={row.original.currencyCode}
                  timeZone={row.original.timeZone}
                  allowRefund={true}
                />
              </Collapse>
            </>
          );
        },
        disableFilters: true
      }
    ];
  }, [orgId]);

  return (
    <div>
      <PageTitle title="Credits" />
      <Table
        loading={isLoading}
        columns={columns}
        data={creditsWithCustomers}
        pageCount={total}
        fetchData={getOrganizationCredits}
        paginationType={PaginationTypes.ServerSide}
      />
    </div>
  );
};

export default OrganizationCredits;
