import { trimStringIfAny } from '../lib/trimString';

export const splitFullName = (fullName: string) => {
  if (fullName?.length > 0) {
    const lastSpaceIndex = fullName.trim().lastIndexOf(' ');
    const maybeFirstName = trimStringIfAny(fullName.substring(0, lastSpaceIndex));
    const mayBeLastName = trimStringIfAny(fullName.substring(lastSpaceIndex, fullName.length));

    const result = { firstName: '', lastName: '' };
    if (maybeFirstName) {
      result.firstName = maybeFirstName;
      result.lastName = mayBeLastName;
    }
    // no first name
    else {
      result.firstName = mayBeLastName;
    }
    return result;
  }
  return { firstName: '', lastName: '' };
};

export const splitFullNameWithNA = (fullName: string) => {
  const { firstName, lastName } = splitFullName(fullName);
  return {
    firstName: firstName || '[n/a]',
    lastName: lastName || '[n/a]'
  };
};

export const joinNames = (firstName: string, lastName: string) => {
  let result = '';
  if (firstName) result += firstName.trim();
  if (firstName && lastName) result += ' ';
  if (lastName) result += lastName.trim();
  return result;
};
