import { addMinutes } from 'date-fns';
import { Filters } from 'react-table';

const removeDateOffsetFromFilters = (allFilters: Filters<any>, dateFilterIds: string[]) => {
  for (const filter of allFilters) {
    if (dateFilterIds.includes(filter.id)) {
      const dateOffset = new Date(filter.value).getTimezoneOffset();
      filter.value = addMinutes(filter.value, -dateOffset);
    }
  }
};
export default removeDateOffsetFromFilters;
