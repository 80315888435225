import { Button } from '@mui/material';
import {
  checkIsAllowedToCancelRegistrationByCustomer,
  checkIsAllowedToPayRegistrationByCustomer,
  maxComputerDate,
  nowUTC,
  PaymentAcceptanceMethod,
  RegistrationStatus
} from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import React from 'react';
import { RegistrationRowProps } from './CustomerRegistrationDesktopRow';
import { smallButtonHeight } from '@efacity/frontend-shared';

const reviewBtnStyle = {
  gridArea: 'review',
  minHeight: '30px',
  padding: '0 16px',
  textAlign: 'center'
} as React.CSSProperties;

const RegistrationActionButtons = ({
  registration,
  handleCancelRegistration,
  isCancelling,
  openReviewModal
}: RegistrationRowProps) => {
  const showPayButton =
    registration.paymentAcceptanceMethod !== PaymentAcceptanceMethod.Alternative &&
    checkIsAllowedToPayRegistrationByCustomer(registration.status, registration.invoiceId);
  const isAllowedToCancel = checkIsAllowedToCancelRegistrationByCustomer(
    registration?.schedule?.lastCancellationDate || registration?.schedule?.startDateTime || maxComputerDate,
    registration.status
  );

  const showAddReviewBtn =
    (registration.status === RegistrationStatus.Registered ||
      registration.status === RegistrationStatus.RegisteredAndPaid) &&
    new Date(registration.schedule?.endDateTime) < nowUTC() &&
    !registration.reviewStars;
  const showSeeReviewBtn = !!registration.reviewStars;

  return (
    <>
      {showPayButton && (
        <Button
          data-testid="pay-for-registration-btn"
          variant="contained"
          href={toPath(PATHS.payment, { invoiceId: registration?.invoiceId })}
          style={{ gridArea: 'pay', ...smallButtonHeight }}
        >
          Pay
        </Button>
      )}
      {isAllowedToCancel && (
        <Button
          aria-label="cancel registration"
          data-testid="cancel-registration"
          variant="outlined"
          disabled={isCancelling}
          onClick={() => handleCancelRegistration(registration._id)}
          style={{ gridArea: 'cancel', ...smallButtonHeight }}
        >
          Cancel
        </Button>
      )}
      {showAddReviewBtn && (
        <Button
          variant="contained"
          href={toPath(PATHS.reviewSession, {
            sessionId: registration?.sessionId,
            registrationId: registration._id
          })}
          style={reviewBtnStyle}
        >
          Leave review
        </Button>
      )}
      {showSeeReviewBtn && (
        <Button variant="outlined" onClick={() => openReviewModal(registration)} fullWidth style={reviewBtnStyle}>
          see review
        </Button>
      )}
    </>
  );
};

export default RegistrationActionButtons;
