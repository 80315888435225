import React, { FC, useEffect, useState } from 'react';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  CircularProgress,
  FilterOptionsState,
  TextField,
  TextFieldProps
} from '@mui/material';

export interface IOption {
  _id?: string;
  name: string;
  isCreated?: boolean;
}

export interface CreatableAutocompleteProps {
  initialOptions: IOption[];
  value: IOption[];
  onChange: (value: any[]) => void;
  errorMessage?: string;
  touched?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  inputStyle?: object;
  isLoadingOptions?: boolean;
  disabled: boolean;
}

const sortOptionsByName = (a, b) => {
  return a.name > b.name ? 1 : -1;
};

// May be aan overhead to create this abstraction
// but will be easier to change ths select to select from other libraries like react-select
// which may be better alternative
export const CreatableAutocomplete: FC<CreatableAutocompleteProps & TextFieldProps> = ({
  value,
  // options that came from api.
  // As we create some more options they will be stored inside this component's state
  initialOptions,
  onChange,
  errorMessage,
  inputStyle,
  isLoadingOptions,
  disabled,
  label
}) => {
  const [inputTextValue, setInputTextValue] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions([...initialOptions]);
  }, [initialOptions]);

  const getUpdatedValue = (selectedValues: IOption[], selectedOption: IOption) => {
    const selectedValueIndex = selectedValues.findIndex((selectedValue) => selectedValue.name === selectedOption.name);
    return [
      ...selectedValues.slice(0, selectedValueIndex),
      { name: inputTextValue },
      ...selectedValues.slice(selectedValueIndex + 1)
    ];
  };

  const addCreatedOptionToOptionsList = (createdOption: IOption) => {
    if (!options.find((option) => option.name === createdOption.name)) {
      setOptions([...options, createdOption]);
    }
  };

  const handleRemoveOption = (removedOption: IOption) => {
    if (!removedOption) return;
    const optionToRemoveIndex = options.findIndex((option) => removedOption.name === option.name);
    if (options[optionToRemoveIndex]?.isCreated) {
      const updatedOptions = [...options.slice(0, optionToRemoveIndex), ...options.slice(optionToRemoveIndex + 1)];
      setOptions(updatedOptions);
    }
  };

  const handleAutocompleteChange = (
    _: React.SyntheticEvent,
    value: IOption[],
    reason: AutocompleteChangeReason,
    changeDetails: AutocompleteChangeDetails<IOption> | undefined
  ) => {
    if (changeDetails?.option.isCreated && reason === 'selectOption') {
      addCreatedOptionToOptionsList({
        name: inputTextValue,
        isCreated: true
      });
      const updatedValue = getUpdatedValue(value, changeDetails?.option);
      onChange(updatedValue);
    } else {
      onChange(value);
    }
    // if we are trying to remove created option
    // make sure it is removed from options dropdown too
    if (reason === 'removeOption' && changeDetails?.option) {
      handleRemoveOption(changeDetails.option);
    }
  };

  const filterOptions = (options: IOption[], state: FilterOptionsState<IOption>) => {
    const filtered = options
      .filter((option) => {
        return !value.find((selectedOption) => {
          return selectedOption.name === option.name;
        });
      })
      .filter((option) => {
        return option?.name?.toLowerCase().includes(state.inputValue?.toLowerCase());
      });

    // Suggest the creation of a new value if none of the options match entered text
    // and no selected options match this text
    const optionsContainEnteredText = !!options.find((filteredOption) => filteredOption.name === state.inputValue);

    // (may be better to generate random id's for created options)
    const valuesContainEnteredText = !!value.find((filteredOption) => filteredOption.name === state.inputValue);

    if (state.inputValue.trim()?.length > 0 && !optionsContainEnteredText && !valuesContainEnteredText) {
      filtered.push({
        name: `Add "${state.inputValue}"`,
        isCreated: true
      });
    }

    return filtered;
  };

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      options={options.sort(sortOptionsByName)}
      ChipProps={{
        size: 'small'
      }}
      filterOptions={filterOptions}
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => {
        return option.name === value.name;
      }}
      value={value}
      getOptionLabel={(option) => {
        return option?.name;
      }}
      onChange={handleAutocompleteChange}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          value={inputTextValue}
          onChange={(e) => {
            setInputTextValue(e.target.value);
          }}
          error={!!errorMessage}
          helperText={!!errorMessage && errorMessage}
          style={inputStyle}
          size="small"
          InputProps={{
            ...params.InputProps,
            style: { borderRadius: 2, borderWidth: '1px !important' },
            endAdornment: (
              <React.Fragment>
                {isLoadingOptions ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
          fullWidth
        />
      )}
    />
  );
};
