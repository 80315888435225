import { MenuItem } from '@mui/material';
import React from 'react';
import { activityLevels } from '@efacity/common';
import { FormInputTextProps, FormTextInput } from './FormTextInput';

export const ActivityLevelSelector: React.FC<FormInputTextProps> = ({ name, ...props }) => {
  return (
    <FormTextInput select name={name} label="Select activity level" {...props}>
      {activityLevels.map((activityLevel) => (
        <MenuItem key={activityLevel.value} value={activityLevel.value}>
          {activityLevel.displayText}
        </MenuItem>
      ))}
    </FormTextInput>
  );
};
