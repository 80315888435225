import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import React from 'react';
import { useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { nowUTC, Location } from '@efacity/common';
import { IconButton } from '@mui/material';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import { isBefore } from 'date-fns';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import Table, { PaginationTypes } from '../../components/Table/Table';
import useLocationsTableColumns from './useLocationsTableColumns';

export interface CurrentLocationsTableProps {
  handleDeleteLocation: (locationId: string) => void;
  locations: Location[];
  isLoading: boolean;
}

const LocationsTable: React.FC<CurrentLocationsTableProps> = ({ handleDeleteLocation, locations, isLoading }) => {
  const { orgId } = useParams<OrgIdParamType>();

  const columns = useLocationsTableColumns(orgId, [
    {
      Header: '',
      id: 'actions-cell',
      disableSortBy: true,
      columnStyles: { ...adminSectionTableFontSize, width: 75, maxWidth: 75 },
      Cell: ({ state, row }) => {
        return (
          <IconButton
            color="primary"
            aria-label="delete location"
            data-testid="delete-location-btn"
            component="span"
            style={smallPublishButtonStyle}
            disabled={isBefore(new Date(row.original.expiresOn), new Date(nowUTC()))}
            onClick={() => handleDeleteLocation(row.original._id)}
          >
            <DeleteIcon />
          </IconButton>
        );
      }
    }
  ]);

  return (
    <Table
      columns={columns}
      data={locations}
      loading={isLoading}
      paginationType={PaginationTypes.ClientSide}
      noDataText="No locations..."
    />
  );
};

export default LocationsTable;
