import { Button, Box } from '@mui/material';
import { navigationTabsStyles } from './PageNavigationTabs';

export interface ContentTabsProps {
  currentTab: string;
  setTab: (tab: string) => void;
  tabs: Record<string, { label: string; content: React.ReactNode }>;
}

/**
 * To navigate between tabs that belong to the same URL
 */
export const ContentTabs = ({ tabs, currentTab, setTab }: ContentTabsProps) => {
  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        {Object.getOwnPropertyNames(tabs).map((key) => (
          <Button
            sx={currentTab === key ? navigationTabsStyles.activeTabStyle : navigationTabsStyles.tabStyle}
            onClick={() => setTab(key)}
            key={key}
          >
            {tabs[key].label}
          </Button>
        ))}
      </Box>

      {tabs[currentTab].content}
    </>
  );
};
