import { Box, Theme } from '@mui/material';
import React from 'react';
import { CustomDrawer } from '@efacity/frontend-shared';
import NavigationItemWithAddButton from './NavigationItemWithAddButton';
import NavigationItem from './NavigationItem';
import { useLeftNavigationItems } from './useLeftNavigationItems';
import { AuthenticatedUserInfo, MenuItemType } from '@efacity/react-next-sc';

export const drawerWidth = 360;

const leftNavigationStyles = {
  drawerContentSx: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    padding: (theme: Theme) => theme.spacing(1),
    overflow: 'auto'
  }
};

export interface LeftNavigationProps {
  user: AuthenticatedUserInfo;
  isDrawerExpanded: boolean;
  allowedCreateActivity: boolean;
  allowedCreateSession: boolean;
  enrollmentRequired: boolean;
  setDrawerExpanded: Function;
  startRegistrationURL: string | null;
  workingAccessId: string | null;
  onMenuItemClick: any;
}

const LeftNavigation: React.FC<LeftNavigationProps> = ({
  user,
  isDrawerExpanded,
  allowedCreateActivity,
  allowedCreateSession,
  enrollmentRequired,
  setDrawerExpanded,
  startRegistrationURL,
  workingAccessId,
  onMenuItemClick
}) => {
  const navigationItems = useLeftNavigationItems(
    workingAccessId,
    allowedCreateActivity,
    allowedCreateSession,
    enrollmentRequired,
    startRegistrationURL,
    user
  );

  return (
    <CustomDrawer
      isExpanded={isDrawerExpanded}
      onExpand={() => {
        setDrawerExpanded(true);
      }}
      onMinimize={() => {
        setDrawerExpanded(false);
      }}
    >
      <Box sx={leftNavigationStyles.drawerContentSx}>
        <Box>
          {navigationItems.map((navigationItem, index) => {
            if (navigationItem.type === MenuItemType.DIVIDER) {
              return <hr key={index} style={{ marginTop: 2, marginBottom: 2 }} />;
            }
            if (navigationItem.renderNavigationItem) {
              return navigationItem.renderNavigationItem(navigationItem.props, onMenuItemClick);
            }
            if (navigationItem.addButtonLink) {
              return (
                <NavigationItemWithAddButton
                  key={index}
                  addButtonLink={navigationItem.addButtonLink}
                  label={navigationItem.label}
                  link={navigationItem.link}
                  matchPaths={navigationItem.matchPaths}
                  addButtonDisabled={navigationItem.addButtonDisabled}
                  onMenuItemClick={onMenuItemClick}
                />
              );
            }
            return (
              <NavigationItem
                key={index}
                label={navigationItem.label}
                link={navigationItem.link}
                onMenuItemClick={onMenuItemClick}
              />
            );
          })}
        </Box>
      </Box>
    </CustomDrawer>
  );
};

export default LeftNavigation;
