import { Button, ButtonGroup, CircularProgress, ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import React, { FC } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { NavLink } from 'react-router-dom';
import { getInvoiceFileName, getPaymentReceiptFileName } from '@efacity/common';
import { smallButtonFontSize, smallButtonHeight } from '@efacity/frontend-shared';

const smallActionButtonStyle = {
  width: 100,
  textTransform: 'uppercase',
  padding: 10,
  ...smallButtonHeight,
  ...smallButtonFontSize
};

export interface RegistrationActionButtonsProps {
  payPath: string | null;
  onOpenNotesModal;
  onCancel;
  cancelDisabled: boolean;
  onSwitch;
  disableActionButtons?: boolean;
  isSubmitting?: boolean;
  invoiceId: string;
  isPaid: boolean;
  customerId: string;
  name: string;
  orgId: string;
}

const RegistrationActionsButton: FC<RegistrationActionButtonsProps> = ({
  payPath,
  onOpenNotesModal,
  onCancel,
  cancelDisabled,
  onSwitch,
  disableActionButtons = false,
  isSubmitting = false,
  invoiceId,
  isPaid,
  customerId,
  name,
  orgId
}) => {
  const [openButtonPopper, setButtonPopperOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setButtonPopperOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setButtonPopperOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
        style={{ marginRight: 0 }}
      >
        {payPath ? (
          <Button
            style={{
              ...smallActionButtonStyle,
              textTransform: 'uppercase',
              width: 60,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
            }}
            component={NavLink}
            to={payPath}
            color="primary"
            variant="contained"
            data-testid="submitPayBtn"
          >
            Pay
          </Button>
        ) : (
          <Button
            style={{ width: 60, textTransform: 'uppercase', padding: 0, ...smallButtonHeight, ...smallButtonFontSize }}
            color="primary"
            variant="contained"
            data-testid="submitPayBtn"
            disabled={true}
          >
            Pay
          </Button>
        )}
        <Button
          style={{
            width: 20,
            ...smallButtonHeight,
            ...smallButtonFontSize,
            paddingLeft: 8,
            paddingRight: 8,
            marginLeft: 0,
            marginRight: 0
          }}
          color="primary"
          size="small"
          aria-controls={openButtonPopper ? 'split-button-menu' : undefined}
          aria-expanded={openButtonPopper ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          data-testid="submitBtnExpander"
          onClick={handleToggle}
          disabled={disableActionButtons}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={openButtonPopper}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 10 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <ButtonGroup orientation="vertical" color="primary" aria-label="vertical primary button group">
                  <Button
                    style={{
                      ...smallActionButtonStyle,
                      textTransform: 'uppercase',
                      justifyContent: 'flex-start'
                    }}
                    color="primary"
                    variant="contained"
                    data-testid="submitBtn"
                    onClick={() =>
                      onOpenNotesModal(customerId, name, `/org/${orgId}/users/customers/${customerId}/notes`)
                    }
                    disabled={disableActionButtons}
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        variant="indeterminate"
                        data-testid="loader"
                        size={20}
                        style={{ marginRight: 10, color: 'white' }}
                      />
                    ) : (
                      'Note'
                    )}
                  </Button>

                  <Button
                    style={{
                      ...smallActionButtonStyle,
                      textTransform: 'uppercase',
                      justifyContent: 'flex-start'
                    }}
                    onClick={onCancel}
                    color="primary"
                    variant="contained"
                    data-testid="submitAndPayBtn"
                    disabled={disableActionButtons || cancelDisabled}
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        variant="indeterminate"
                        data-testid="loader"
                        size={20}
                        style={{ marginRight: 10, color: 'white' }}
                      />
                    ) : (
                      'Cancel'
                    )}
                  </Button>
                  <Button
                    style={{
                      ...smallActionButtonStyle,
                      textTransform: 'uppercase',
                      justifyContent: 'flex-start'
                    }}
                    onClick={onSwitch}
                    color="primary"
                    variant="contained"
                    data-testid="submitAndPayBtn"
                    disabled={disableActionButtons || cancelDisabled}
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        variant="indeterminate"
                        data-testid="loader"
                        size={20}
                        style={{ marginRight: 10, color: 'white' }}
                      />
                    ) : (
                      'Switch'
                    )}
                  </Button>
                  {invoiceId && (
                    <Button
                      style={{
                        ...smallActionButtonStyle,
                        textTransform: 'uppercase',
                        justifyContent: 'flex-start'
                      }}
                      color="primary"
                      variant="contained"
                      data-testid="downloadInvoiceBtn"
                      href={getInvoiceFileName(invoiceId)}
                    >
                      Invoice
                    </Button>
                  )}
                  {invoiceId && isPaid && (
                    <Button
                      style={{
                        ...smallActionButtonStyle,
                        textTransform: 'uppercase',
                        justifyContent: 'flex-start'
                      }}
                      color="primary"
                      variant="contained"
                      data-testid="downloadReceiptBtn"
                      href={getPaymentReceiptFileName(invoiceId)}
                    >
                      Receipt
                    </Button>
                  )}
                </ButtonGroup>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default RegistrationActionsButton;
