import { CurrencyCode } from './Currencies';
import { plainTimeZones } from './TimeZones';
import {
  AustralianStateOptions,
  CanadianProvincesOptions,
  StateOption,
  UsStatesOptions,
  IndiaStateOptions,
  EgyptGovernorateOptions
} from './states';

interface Country {
  code: CountryCode;
  name: string;
  abbreviation: string;
  currency: CurrencyCode;
  timeZones: string[];
  states: StateOption[];
  formTitles: Record<string, string>;
}

export enum CountryCode {
  USA = 'US',
  Canada = 'CA',
  Australia = 'AU',
  UK = 'GB',
  India = 'IN',
  Egypt = 'EG'
}

export const convertPhoneNumberToInternationalFormat = (
  phoneNumber: string,
  country: CountryCode = CountryCode.USA
) => {
  const digits = phoneNumber.replace(/\D/g, '');
  switch (country) {
    case CountryCode.USA:
      return `+1${digits}`;
    case CountryCode.Canada:
      return `+1${digits}`;
    case CountryCode.Australia:
      return `+61${digits}`;
    case CountryCode.UK:
      return `+44${digits}`;
    case CountryCode.India:
      return `+91${digits}`;
    case CountryCode.Egypt:
      return `+20${digits}`;
    default:
      return `+1${digits}`;
  }
};

export const Australia: Country = {
  code: CountryCode.Australia,
  name: 'Australia',
  abbreviation: 'AU',
  currency: CurrencyCode.AUD,
  timeZones: [
    'Australia/Lord_Howe',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla'
  ],
  states: AustralianStateOptions,
  formTitles: { city: 'Suburb / Town / City', zip: 'Postal code', adjective: 'Australian' }
};

export const Canada: Country = {
  code: CountryCode.Canada,
  name: 'Canada',
  abbreviation: 'CA',
  currency: CurrencyCode.CAD,
  timeZones: [
    'Canada/Atlantic',
    'Canada/Central',
    'Canada/Eastern',
    'Canada/Mountain',
    'Canada/Newfoundland',
    'Canada/Pacific',
    'Canada/Saskatchewan',
    'Canada/Yukon'
  ],
  states: CanadianProvincesOptions,
  formTitles: { city: 'City', state: 'Province', zip: 'Postal code', adjective: 'Canadian' }
};

export const USA: Country = {
  code: CountryCode.USA,
  name: 'United States of America',
  abbreviation: 'US',
  currency: CurrencyCode.USD,
  timeZones: plainTimeZones.filter((zone) => zone.indexOf('America') > -1),
  states: UsStatesOptions,
  formTitles: { city: 'City', zip: 'Zip code', adjective: 'USA' }
};

export const UnitedKingdom: Country = {
  code: CountryCode.UK,
  name: 'United Kingdom',
  abbreviation: 'UK',
  currency: CurrencyCode.GBP,
  timeZones: ['Europe/London'],
  states: [],
  formTitles: { city: 'Town/City', zip: 'Postcode' }
};

export const India: Country = {
  code: CountryCode.India,
  name: 'India',
  abbreviation: 'IN',
  currency: CurrencyCode.INR,
  timeZones: ['Asia/Kolkata'],
  states: IndiaStateOptions,
  formTitles: { address: 'Address including District', zip: 'Postcode' }
};

export const Egypt: Country = {
  code: CountryCode.Egypt,
  name: 'Egypt',
  abbreviation: 'EG',
  currency: CurrencyCode.EGP,
  timeZones: ['Africa/Cairo'],
  states: EgyptGovernorateOptions,
  formTitles: { state: 'Governorate', zip: 'Postcode' }
};

export const countries: Country[] = [Australia, Canada, USA, UnitedKingdom, India, Egypt];
export const supportedCountryCodes: CountryCode[] = [Australia, Canada, USA, UnitedKingdom, India, Egypt].map(
  (country) => country.code
);

export const getCountryByCode = (country: CountryCode): Country => {
  const foundCountry = countries.find((item: Country) => item.code === country);
  if (foundCountry) {
    return foundCountry;
  } else {
    return USA;
  }
};

export const getCountryNameByCode = (country: CountryCode): string => {
  const foundCountry = countries.find((item: Country) => item.code === country);
  if (foundCountry) {
    return foundCountry.name;
  } else {
    return USA.name;
  }
};

export const getCountryTimezonesByCode = (country: CountryCode): string[] => {
  const foundCountry = countries.find((item: Country) => item.code === country);
  if (foundCountry) {
    return foundCountry.timeZones || plainTimeZones;
  } else {
    return plainTimeZones;
  }
};

const countriesCodesWithoutStates = [CountryCode.UK];

export const isCountryHasStates = (countryCode: CountryCode): boolean => {
  return !countriesCodesWithoutStates.find((code) => code === countryCode);
};

export const getStateOptionsDependingOnCountry = (countryCode: CountryCode) => {
  const country = getCountryByCode(countryCode);
  return country?.states || [];
};
