export enum PreferredPronoun {
  notSpecified = '',
  male = 'Male (him)',
  female = 'Female (her)',
  neutral = 'Neutral (they)',
  other = 'Other'
}

export const preferredPronounViewConnector = {
  [PreferredPronoun.notSpecified]: 'Not specified',
  [PreferredPronoun.male]: 'Male (him)',
  [PreferredPronoun.female]: 'Female (her)',
  [PreferredPronoun.neutral]: 'Neutral (they)',
  [PreferredPronoun.other]: 'Other'
};

export const isPreferredPronounValue = (mayBePreferredPronoun: string) => {
  const foundCurrencies = Object.values(PreferredPronoun).filter(
    (preferredPronoun) => preferredPronoun === mayBePreferredPronoun
  );
  return foundCurrencies && foundCurrencies.length > 0;
};
