import React, { FC } from 'react';
import { MenuItem } from '@mui/material';
import { FormInputTextProps, FormTextInput } from './FormTextInput';
import { DiscountTypeOptions, DisplayOption } from '@efacity/common';

interface DiscountTypeSelectorProps{
  allowedTypes:typeof DiscountTypeOptions
}

export const DiscountTypeSelector: FC<DiscountTypeSelectorProps&FormInputTextProps> = ({ name, allowedTypes=DiscountTypeOptions, ...props }) => {
  return (
    <FormTextInput name={name} label="Amount / Percentage" select disabled={allowedTypes.length===1} {...props}>
      {Object.values(allowedTypes).map((discountTypeOption: DisplayOption) => (
        <MenuItem key={discountTypeOption.value} value={discountTypeOption.value}>
          {discountTypeOption.displayText}
        </MenuItem>
      ))}
    </FormTextInput>
  );
};
