import React, { FC, ReactElement } from 'react';
import { Address } from '@efacity/common';
import { Box, Typography } from '@mui/material';
import { FormGroupTitle } from '@efacity/frontend-next-shared/forms/server';

export interface NonEditableAddressProps {
  address: Address;
  title?: string;
  hint?: ReactElement;
}

const NonEditableAddress: FC<NonEditableAddressProps> = ({ address, title, hint }) => {
  return (
    <Box>
      <FormGroupTitle>{title}</FormGroupTitle>
      {hint}
      <Box>
        <Typography>{address.address}</Typography>
        <Typography>{`${address.city + ', ' || ''}${address.state + ' ' || ''}${address.zip || ''}`}</Typography>
        <Typography>{address.country}</Typography>
      </Box>
      <hr style={{ height: '2', width: 'auto', marginTop: 10, marginBottom: 20 }} />
    </Box>
  );
};

export default NonEditableAddress;
