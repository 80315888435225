import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { FC } from 'react';

export const LocationMarkerIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M9.77345 12.5859L7.19729 16.5683L4.73903 17.6203C3.29064 15.9609 1.74381 13.9922 1.01251 11.5031L2.48439 8.76563L4.85157 7.66406C4.80943 7.91719 4.78126 8.17031 4.78126 8.4375C4.78126 10.7578 6.67965 12.6563 9.00001 12.6563C9.2672 12.6563 9.52032 12.6281 9.77345 12.5859Z"
        fill="#FFDA2D"
      />
      <path
        d="M13.1489 9.20668C13.1911 8.95496 13.2187 8.70324 13.2187 8.43746C13.2187 6.11903 11.3231 4.22153 9.00516 4.21871H9C8.73281 4.21871 8.47969 4.24684 8.22656 4.28903L11.9672 0.548401C14.2312 1.39215 16.0453 3.20621 16.8891 5.47028L13.1484 9.2109C13.1484 9.20949 13.1489 9.20809 13.1489 9.20668Z"
        fill="#80AEF8"
      />
      <path
        d="M16.8891 5.47028L15.6094 7.96871L13.1484 9.2109C13.1484 9.20949 13.1489 9.20809 13.1489 9.20668C13.1911 8.95496 13.2187 8.70324 13.2187 8.43746C13.2187 6.11903 11.3231 4.22153 9.00515 4.21871H9.0014V3.51418L9.84375 1.64059L11.9672 0.548401C14.2312 1.39215 16.0453 3.20621 16.8891 5.47028Z"
        fill="#4086F4"
      />
      <path
        d="M16.889 5.47028L13.1484 9.21085C12.8249 10.9125 11.475 12.2624 9.77337 12.5859H9.77333L5.7012 16.658L4.73895 17.6202C4.97806 17.8874 5.18895 18.1265 5.414 18.3796C6.96092 20.1375 8.29686 21.7499 8.29686 23.2968C8.29686 23.6905 8.60619 24 8.99998 24C9.39378 24 9.70311 23.6905 9.70311 23.2968C9.70311 21.7499 11.039 20.1375 12.5859 18.3797C14.7516 15.9468 17.4375 12.9093 17.4375 8.43746C17.4375 7.39684 17.2547 6.3984 16.889 5.47028V5.47028Z"
        fill="#59C36A"
      />
      <path
        d="M16.8891 5.47028L13.1485 9.21081V9.21085C12.825 10.9125 11.4751 12.2624 9.77345 12.5859C9.7734 12.5859 9.7734 12.5859 9.77335 12.5859L9.00146 13.3577V23.9997C9.39456 23.9988 9.70314 23.69 9.70314 23.2968C9.70314 21.7499 11.0391 20.1375 12.5859 18.3797C14.7516 15.9468 17.4375 12.9093 17.4375 8.43746C17.4375 7.39684 17.2547 6.3984 16.8891 5.47028V5.47028Z"
        fill="#00A66C"
      />
      <path
        d="M9.00146 13.3579L9.77345 12.5859C9.52084 12.628 9.26818 12.6559 9.00146 12.6561V13.3579Z"
        fill="#FDBF00"
      />
      <path
        d="M11.9672 0.548437L8.22659 4.28906C7.36878 4.45781 6.60936 4.86558 6.01878 5.4563L3.84379 4.54688L3.03754 2.475C4.55633 0.942141 6.6657 0 9.00003 0C10.0407 0 11.0391 0.182812 11.9672 0.548437Z"
        fill="#4086F4"
      />
      <path
        d="M9.00146 3.51415L11.9672 0.548419C11.0395 0.182935 10.0416 0.00030957 9.00151 0.00012207V3.51415H9.00146Z"
        fill="#4175DF"
      />
      <path
        d="M6.01875 5.45629C5.42817 6.04691 5.02027 6.80624 4.85156 7.6641L1.0125 11.5032C0.73125 10.561 0.5625 9.54847 0.5625 8.43754C0.5625 6.10311 1.50464 3.99374 3.0375 2.47504L6.01875 5.45629Z"
        fill="#F03800"
      />
    </SvgIcon>
  );
};
