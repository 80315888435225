'use client';
import React, { useEffect, useState } from 'react';
import { FormCancelSubmitButtons } from '@efacity/frontend-next-shared/forms';
import { canDeleteSchoolAdmin } from '@efacity/frontend-next-shared/org-settings';
import {
  siteAdminAddOrganizationFormValidationSchema,
  siteAdminEditOrganizationFormValidationSchema,
  OrganizationSettingsFormGroup
} from '@efacity/frontend-next-shared/org-settings';
import type { AuthenticatedUserInfo } from '@efacity/react-next-sc';
import type { OrganizationSettingsWithAdministratorsFormValues } from '@efacity/frontend-next-shared/org-settings';
import type { AdministratorInfo } from '@efacity/common';
import { FormMode } from '@efacity/common';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';

export interface OrganizationFormProps {
  initialFormValues: OrganizationSettingsWithAdministratorsFormValues;
  authenticatedUser: AuthenticatedUserInfo;
  mode: FormMode;
  orgId?: string;
  handleSubmit: (
    formValues: OrganizationSettingsWithAdministratorsFormValues,
    setError: (name, error, options?) => void,
    logoImage: Blob,
    mobileLogoImage: Blob
  ) => void;
  administrators?: AdministratorInfo[];
  deletingOrgAdmin?: boolean;
  onDeleteOrgAdmin?: (adminId: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCancel: (reset: any, initialFormValues) => void;
  loading?: boolean;
}

export const SiteAdminOrganizationForm: React.FC<OrganizationFormProps> = ({
  mode,
  initialFormValues,
  orgId,
  handleSubmit,
  administrators,
  onDeleteOrgAdmin,
  deletingOrgAdmin,
  authenticatedUser,
  onCancel,
  loading
}) => {
  const pathToFormObject = 'organizationSettings';

  const [logoImgBlob, setLogoImgBlob] = useState(null);
  const [mobileLogoImgBlob, setMobileLogoImgBlob] = useState(null);

  const validationSchema =
    mode === FormMode.Edit
      ? siteAdminEditOrganizationFormValidationSchema(pathToFormObject, orgId)
      : siteAdminAddOrganizationFormValidationSchema(pathToFormObject);

  const methods = useForm<OrganizationSettingsWithAdministratorsFormValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const { isDirty, isSubmitting, errors } = methods.formState;
  const isValid = isEmpty(errors);

  useEffect(() => {
    methods.reset(initialFormValues);
  }, [methods, initialFormValues]);

  const handleDeleteAdmin = async (adminId: string) => {
    if (onDeleteOrgAdmin) {
      onDeleteOrgAdmin(adminId);
    }
  };

  const onSubmit = (formValues: OrganizationSettingsWithAdministratorsFormValues) => {
    handleSubmit(formValues, methods.setError, logoImgBlob, mobileLogoImgBlob);
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <OrganizationSettingsFormGroup
          orgId={orgId}
          authenticatedUser={authenticatedUser}
          isSiteAdmin={true}
          mode={mode}
          setLogoImgBlob={setLogoImgBlob}
          setMobileLogoImgBlob={setMobileLogoImgBlob}
          loading={loading}
          deletingOrgAdmin={deletingOrgAdmin}
          administrators={administrators}
          handleDeleteAdmin={handleDeleteAdmin}
          canDeleteAdmin={canDeleteSchoolAdmin}
          pathToFormObject={pathToFormObject}
        />
        <FormCancelSubmitButtons
          mode={mode}
          onCancel={() => onCancel(methods.reset, initialFormValues)}
          isFormValid={isValid}
          isSubmitting={isSubmitting}
          isDisabled={!isDirty}
        />
      </form>
    </FormProvider>
  );
};
