import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { FC } from 'react';

export const CopyLinkFlatIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0)">
        <path d="M22.6839 3.45114C21.84 2.6066 20.6949 2.13227 19.501 2.13267C18.3076 2.12986 17.1625 2.60404 16.3204 3.44969L10.5101 9.26009C9.36046 10.4115 8.92097 12.0933 9.36036 13.6599C9.48901 14.116 9.96304 14.3815 10.4191 14.2528C10.8752 14.1242 11.1406 13.6501 11.012 13.194C10.7411 12.2252 11.0129 11.1856 11.7233 10.4733L17.5336 4.66379C18.6199 3.57723 20.3814 3.57703 21.468 4.66339C22.5545 5.7497 22.5547 7.51118 21.4684 8.59774L15.6581 14.4081C15.2773 14.7894 14.7937 15.0514 14.2664 15.1623C13.8025 15.2589 13.5047 15.7132 13.6013 16.1772C13.6843 16.5761 14.0365 16.8615 14.444 16.8603C14.5039 16.8603 14.5638 16.854 14.6224 16.8414C15.4746 16.6617 16.256 16.2378 16.8713 15.6213L22.6817 9.81184C24.4387 8.05599 24.4397 5.20825 22.6839 3.45114Z" />
        <path d="M14.6122 10.3635C14.4835 9.90738 14.0095 9.64194 13.5534 9.77059C13.0974 9.89924 12.8319 10.3733 12.9606 10.8293C13.2314 11.7982 12.9597 12.8378 12.2493 13.55L6.43889 19.3604C5.35258 20.447 3.59109 20.4472 2.50453 19.3608C1.41797 18.2745 1.41777 16.5131 2.50408 15.4265L8.31449 9.61525C8.69466 9.23407 9.17768 8.972 9.70445 8.86105C10.1691 8.76809 10.4705 8.31608 10.3775 7.85146C10.2846 7.38683 9.83255 7.08544 9.36792 7.1784C9.36224 7.17955 9.35661 7.18071 9.35098 7.18197C8.49864 7.36189 7.71699 7.7858 7.10129 8.40205L1.29088 14.2124C-0.452401 15.9826 -0.430582 18.8308 1.3396 20.5741C3.09047 22.2983 5.90096 22.2985 7.65209 20.5745L13.4624 14.7632C14.6121 13.6118 15.0516 11.93 14.6122 10.3635Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
