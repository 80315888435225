import React from 'react';
import { useSiteAdminReviewsLoader } from './useSiteAdminReviewsLoader';
import Table, { PaginationTypes } from '../../components/Table/Table';
import { Button } from '@mui/material';
import useSiteAdminReviewsColumns from './useSiteAdminReviewsColumns';
import { ReviewStatus } from '@efacity/common';
import { PageTitle } from '@efacity/react-next-sc';

const SiteAdminReviewsContainer: React.FC = () => {
  const {
    reviewsState: { reviews, total, isLoading, processingReviewId },
    setFiltersAndGetReviews,
    changeReviewStatus,
    approveAllPendingReviews
  } = useSiteAdminReviewsLoader('/reviews/sessions');

  const columns = useSiteAdminReviewsColumns(changeReviewStatus, processingReviewId);

  const approveAllPendingButton = (
    <Button variant="contained" onClick={approveAllPendingReviews}>
      Approve all pending
    </Button>
  );

  return (
    <div>
      <PageTitle title="Reviews" button={approveAllPendingButton} />

      <Table
        loading={isLoading}
        columns={columns}
        data={reviews}
        pageCount={total}
        fetchData={setFiltersAndGetReviews}
        paginationType={PaginationTypes.ServerSide}
        initialSortBy={[{ id: 'stars', desc: false }]}
        initialFilters={[{ id: 'status', value: ReviewStatus.Pending }]}
      />
    </div>
  );
};

export default SiteAdminReviewsContainer;
