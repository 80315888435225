import { FormPasswordInput, FormTextInput } from '@efacity/react-hook-form-mui';
import { SignInFormValues } from '@efacity/react-next-sc';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CircularProgressButton } from '../../buttons/CircularProgressButton';

export const validationSchema = yup.object().shape({
  email: yup.string().nullable().email('Email Address is invalid').required('Email Address is required'),
  password: yup.string().nullable().min(8, 'Password must be at least 8 characters').required('Password is required')
});

interface SignInFormProps {
  onSignIn: (values: SignInFormValues, resetForm: () => void) => void;
  email?: string;
}

export const SignInForm: React.FC<SignInFormProps> = ({ onSignIn, email = '' }) => {
  const methods = useForm<SignInFormValues>({
    resolver: yupResolver(validationSchema),
    // null as an initial value seems to solve the problem of MUI not detecting change on browser
    // login/password autofill and not shrinking label, causing  label and field value to visually overlap
    // see https://stackoverflow.com/questions/50955603/react-material-ui-label-overlaps-with-text
    defaultValues: { email: email || null, password: null },
    mode: 'onBlur',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUseNativeValidation: false
  });

  const { isValid, isDirty, isSubmitting } = methods.formState;

  const onSubmit = (values) => {
    onSignIn(values, methods.reset);
  };

  const resetPasswordLink = methods.watch('email')
    ? `/auth/reset-password?email=${methods.watch('email')}`
    : '/auth/reset-password';

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <FormTextInput name="email" type="email" label="Email Address" autoFocus />
        <FormPasswordInput label="Password" autoComplete="current-password" showHelperText={false} />

        <Box display="flex" flexDirection="column" alignItems="center">
          <a
            style={{
              marginTop: 15,
              marginBottom: 25,
              fontWeight: 500,
              color: 'rgb(47, 117, 193)',
              textDecoration: 'underline'
            }}
            href={resetPasswordLink}
          >
            Forgot password?
          </a>
          <CircularProgressButton
            data-testid="submit"
            progress={isSubmitting}
            disabled={!isValid || !isDirty || isSubmitting}
            color="primary"
          >
            Log in
          </CircularProgressButton>
        </Box>
      </form>
    </FormProvider>
  );
};
