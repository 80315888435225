import { CurrencyCode, InvoiceItemTypes, moneyWithCurrencyCodeFormatter } from '@efacity/common';
import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import mobileTableStyle from '../../../stylesheets/mobileTableStyle';
import { DiscountInvoiceItemTableRowProps } from './DiscountInvoiceItemTableRow';
import DeleteIconButtonWithSpinner from '../../../components/DeleteIconButtonWithSpinner/DeleteIconButtonWithSpinner';

const DiscountInvoiceItemMobileTableRow: FC<DiscountInvoiceItemTableRowProps> = ({
  invoiceItem,
  handleDeleteDiscount,
  isUpdating,
  disabled,
  index
}) => {
  const handleDeleteDiscountIfAny = () => {
    if (handleDeleteDiscount) {
      handleDeleteDiscount(invoiceItem.invoiceItemId);
    }
  };

  return (
    <Box sx={mobileTableStyle.rootSx}>
      <Box style={mobileTableStyle.additionalOptionItemRow}>
        <Box padding="10px">
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Discount:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>{invoiceItem.description}</Typography>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" style={mobileTableStyle.titleSx}>
            Amount:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>
            {moneyWithCurrencyCodeFormatter(invoiceItem.amount, invoiceItem.currencyCode as CurrencyCode)}
          </Typography>
        </Box>
        <Box style={mobileTableStyle.deleteIcon}>
          {invoiceItem.invoiceItemType === InvoiceItemTypes.Discount && invoiceItem.appliedAutomatically === false && (
            <DeleteIconButtonWithSpinner
              onClick={handleDeleteDiscountIfAny}
              isUpdating={isUpdating}
              data-testid={`deleteIcon${index}Mobile`}
              disabled={disabled}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DiscountInvoiceItemMobileTableRow;
