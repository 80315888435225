import { LightTooltip } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import LinkOutlined from '@mui/icons-material/LinkOutlined';
import { IconButton, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import React from 'react';

export interface AddInstructorLinkCopierProps {
  orgId: string;
}

const AddInstructorLinkCopier: React.FC<AddInstructorLinkCopierProps> = ({ orgId }) => {
  const handleCopyRegistrationClick = () => {
    const addInstructorLinkLink = toPath(PATHS.instructorRegistration, { orgId: orgId });
    copy(`${window.location.origin}${addInstructorLinkLink}`);
  };

  return (
    <LightTooltip title={<Typography>Copy Instructor Registration Link to share</Typography>}>
      <span>
        <div style={{ display: 'flex', marginRight: 15, marginTop: 5 }} data-testid="registration-link-with-copier">
          [
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            style={{ marginTop: -4, padding: 5 }}
            onClick={handleCopyRegistrationClick}
          >
            <LinkOutlined fontSize="medium" />
          </IconButton>
          ]
        </div>
      </span>
    </LightTooltip>
  );
};

export default AddInstructorLinkCopier;
