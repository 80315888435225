import React, { FC } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import NotesTableWithAddForm from '../NotesTableWithAddForm/NotesTableWithAddForm';
import { ModalDialogTitle } from '@efacity/react-next-sc';

export interface NotesModalProps {
  orgId: string;
  customerId: string;
  name: string;
  url: string;
  onClose: (reloadData: boolean) => void;
}

const NotesModal: FC<NotesModalProps> = ({ customerId, name, url, onClose }) => {
  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Dialog open={!!customerId} onClose={onClose} maxWidth="sm" fullWidth>
      <ModalDialogTitle text={`Notes for ${name}`} onClose={handleCancel} />
      <DialogContent style={{ minHeight: 200 }}>
        <NotesTableWithAddForm url={url} />
      </DialogContent>
    </Dialog>
  );
};

export default NotesModal;
