import React, { useCallback, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { PATHS, toPath } from '@efacity/routing';
import { adminSectionTableFontSize, EnrollmentApplicationFrontend, ConfirmationModal } from '@efacity/frontend-shared';
import { PageTitle } from '@efacity/react-next-sc';
import Table, {
  EnhancedColumn,
  FetchDataOptions,
  initialFetchDataOptions,
  PaginationTypes
} from '../../components/Table/Table';
import { useEnrollmentsLoader } from './useEnrollmentsLoader';
import { IconButton, Link } from '@mui/material';
import {
  ApplicationStatus,
  ApplicationStatusConnector,
  convertUtcToZonedTime,
  joinNames,
  moneyWithCurrencyCodeFormatter,
  nowUTC,
  User,
  formatStringToMonthDayYearDate
} from '@efacity/common';
import { EnrollmentApplicationStatusFilter } from '../../components/ColumnFilters/EnrollmentApplicationStatusFilter';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import { isBefore } from 'date-fns';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import PageLinkWithCopier from '../../components/PageLinkWithCopier/PageLinkWithCopier';

const EnrollmentsContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const [fetchOptions, setFetchOptions] = useState<FetchDataOptions>(initialFetchDataOptions);

  const {
    enrollmentsState: { enrollments, total, timeZone, isLoading },
    getEnrollments,
    deleteEnrollmentApplication
  } = useEnrollmentsLoader(orgId);
  const storeFetchOptionsAndGetOrganizations = useCallback(
    (fetchDataOptions: FetchDataOptions) => {
      setFetchOptions(fetchDataOptions);
      getEnrollments(fetchDataOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getEnrollments, setFetchOptions]
  );

  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    onConfirm: null
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);

  const onDeleteEnrollmentCLick = (enrollmentId: string, name: string, fetchDataOptions: FetchDataOptions) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        `You are about to delete Enrollment Application from ${name}.<br/>` +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      onConfirm: () => handleDeleteEnrollmentApplication(enrollmentId, fetchDataOptions)
    });
  };

  const handleDeleteEnrollmentApplication = async (enrollmentId: string, fetchOptions: FetchDataOptions) => {
    setConfirmationModalState((state) => {
      return { ...state, isOperating: true };
    });
    await deleteEnrollmentApplication(enrollmentId);
    await getEnrollments(fetchOptions);
    setConfirmationModalState({ ...defaultConfirmationModalState });
  };

  const columns: EnhancedColumn<EnrollmentApplicationFrontend & { user: User }>[] = [
    {
      Header: 'User Name',
      id: 'user.fullName',
      accessor: (data) => {
        return data.user.fullName;
      },
      Cell: ({ row }) => row.original.user.fullName,
      disableFilters: false,
      disableSortBy: false
    },
    {
      Header: 'User Email',
      id: 'user.email',
      accessor: (data) => {
        return data.user.email;
      },
      Cell: ({ row }) => row.original.user.email,
      disableFilters: false,
      disableSortBy: false
    },
    {
      Header: 'Student Name',
      id: 'enrollmentForm.student.firstName',
      accessor: (data) => {
        return data.enrollmentForm.student.firstName;
      },
      Cell: ({ row }) => (
        <Link
          component={NavLink}
          to={toPath(PATHS.studentEnrollment, {
            orgId: orgId,
            enrollmentId: row.original._id
          })}
          underline={'none'}
        >
          {row.original.enrollmentForm.student?.firstName?.length > 0 ||
          row.original.enrollmentForm.student?.lastName?.length > 0
            ? joinNames(row.original.enrollmentForm.student.firstName, row.original.enrollmentForm.student.lastName)
            : '[no - name]'}
        </Link>
      ),
      disableFilters: true,
      disableSortBy: true
    },
    {
      Header: 'Submitted On',
      accessor: 'submittedOn',
      disableFilters: true,
      Cell: ({ value }) =>
        value ? formatStringToMonthDayYearDate(convertUtcToZonedTime(value, timeZone).toISOString()) : '-'
    },
    {
      Header: 'Approved On',
      accessor: 'approvedAt',
      disableFilters: true,
      Cell: ({ value }) =>
        value ? formatStringToMonthDayYearDate(convertUtcToZonedTime(value, timeZone).toISOString()) : '-'
    },
    {
      Header: 'Price',
      accessor: 'price',
      disableFilters: true,
      columnStyles: { textAlign: 'right', width: 115, maxWidth: 115 },
      headerStyles: { textAlign: 'right', paddingRight: 15 },
      Cell: ({ value, row }) => moneyWithCurrencyCodeFormatter(value, row.original.currencyCode)
    },
    {
      Header: 'Status',
      accessor: 'applicationStatus',
      disableFilters: false,
      columnStyles: { width: 160, maxWidth: 160 },
      Cell: ({ value }) => ApplicationStatusConnector[value],
      Filter: EnrollmentApplicationStatusFilter
    },
    {
      Header: '',
      id: 'actions-cell',
      disableSortBy: true,
      columnStyles: { ...adminSectionTableFontSize, width: 75, maxWidth: 75 },
      Cell: ({ row }) =>
        row.original.applicationStatus !== ApplicationStatus.Approved ? (
          <IconButton
            color="primary"
            aria-label="delete application"
            component="span"
            style={smallPublishButtonStyle}
            disabled={isBefore(new Date(row.original.expiresOn), new Date(nowUTC()))}
            onClick={() =>
              onDeleteEnrollmentCLick(
                row.original._id,
                joinNames(
                  row.original.enrollmentForm.customer.firstName,
                  row.original.enrollmentForm.customer.lastName
                ),
                fetchOptions
              )
            }
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <> </>
        )
    }
  ];

  return (
    <div>
      <PageTitle title="Enrollment Applications" />
      <PageLinkWithCopier
        name="Enrollment page"
        path={toPath(PATHS.enrollment, { orgId: orgId })}
        tooltipText="Copy Enrollment page link to share"
      />
      <Table
        columns={columns}
        data={enrollments}
        loading={isLoading}
        fetchData={storeFetchOptionsAndGetOrganizations}
        pageCount={total}
        paginationType={PaginationTypes.ServerSide}
        initialSortBy={[{ id: 'name', desc: false }]}
        noDataText={`No enrollments...`}
      />
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState({ ...defaultConfirmationModalState })}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
    </div>
  );
};

export default EnrollmentsContainer;
