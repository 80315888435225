import React, { useMemo } from 'react';
import { HorizontalField } from '@efacity/frontend-shared';
import { FormMode } from '@efacity/common';
import { Button, CircularProgress } from '@mui/material';
import { Check as SaveIcon, CancelOutlined } from '@mui/icons-material';

export const buttonStyle = {
  width: 171,
  height: 36,
  textTransform: 'uppercase',
  marginTop: 5,
  marginBottom: 5
} as React.CSSProperties;

interface FormCancelSubmitButtonsProps {
  mode: FormMode;
  submitBtnText?: string;
  onCancel: () => void;
  isFormValid: boolean;
  isSubmitting: boolean;
  isDisabled?: boolean;
  showCancel?: boolean;
  submitBtnType?: 'submit' | 'button';
  onSubmit?: () => void;
}

export const FormCancelSubmitButtons = ({
  submitBtnText,
  mode,
  onCancel,
  isFormValid,
  isSubmitting,
  isDisabled = false,
  showCancel = true,
  submitBtnType = 'submit',
  onSubmit
}: FormCancelSubmitButtonsProps) => {
  const submitButtonLabel = useMemo(() => {
    if (submitBtnText) {
      return submitBtnText;
    }
    if (mode === FormMode.Edit) {
      return 'Save';
    }
    return 'Create';
  }, [mode, submitBtnText]);

  return (
    <HorizontalField justifyContent="center" wrapOnSmallScreen={false} style={{ marginTop: 15 }}>
      {showCancel && (
        <Button
          style={buttonStyle}
          onClick={onCancel}
          color="primary"
          variant="outlined"
          disabled={isSubmitting}
          startIcon={<CancelOutlined />}
        >
          Cancel
        </Button>
      )}
      <Button
        style={buttonStyle}
        type={submitBtnType}
        color="primary"
        variant="contained"
        disabled={!isFormValid || isSubmitting || isDisabled}
        onClick={onSubmit}
        startIcon={
          isSubmitting ? (
            <CircularProgress variant="indeterminate" size={20} style={{ marginRight: 20, color: 'white' }} />
          ) : (
            <SaveIcon />
          )
        }
      >
        {submitButtonLabel}
      </Button>
    </HorizontalField>
  );
};
