export enum InstanceTemporality {
  Historic = 'historic',
  Current = 'current',
  Upcoming = 'upcoming',
  Canceled = 'canceled',
  Expired = 'expired',
  Completed = 'completed'
}

export enum InstanceActivity {
  active = 'active',
  deleted = 'deleted'
}
