import React, { useMemo } from 'react';
import { EnhancedColumn } from '../../components/Table/Table';
import DateColumnFilter from '../../components/Table/filters/DateColumnFilter';
import { IconButton, Link, useMediaQuery, useTheme } from '@mui/material';
import DateCell from '../../components/DateCell';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import { PATHS, toPath } from '@efacity/routing';
import {
  convertUtcToZonedTime,
  getAmountForRegistration,
  getBalanceForRegistration,
  getDiscountsAmountForRegistration,
  moneyWithCurrencyCodeFormatter,
  RegistrationStatus
} from '@efacity/common';
import EmailAddress from '../../components/EmailAddress/EmailAddress';
import RegistrationActionsButton from './RegistrationActionsButton/RegistrationActionsButton';
import { NameLinkWithNoteIcon } from '../../components/NameLinkWithNoteIcon/NameLinkWithNoteIcon';
import { StudentWithCustomerInfoTabs } from '../../components/EditStudentWithCustomerInfoModal/EditStudentWithCustomerInfoModal';
import MustAgreeUnCheckedIcon from '../CustomerAgreements/MustAgreeUnCheckedIcon';

export const useOrganizationRegistrationsColumns = (
  onOpenCancellationModal,
  onOpenNotesModal,
  orgId: string,
  registrationCancelling,
  setStudentIdUnderEdit,
  setCustomerUnderEdit
) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  return useMemo((): EnhancedColumn<any>[] => {
    return [
      {
        Header: 'Session',
        accessor: 'efacitySessionId.name',
        Cell: ({ row }) => {
          return (
            <>
              {row.original.status === RegistrationStatus.Unregistered && (
                <div style={{ color: 'red' }}>(cancelled)</div>
              )}
              {row.original.efacitySessionId.name}
            </>
          );
        }
      },
      {
        Header: 'Starts On',
        accessor: 'efacitySessionId.schedule.startDateTime',
        Cell: ({ row, value }) => {
          if (value) {
            return (
              <DateCell
                dateTime={value ? convertUtcToZonedTime(value, row.original.efacitySessionId?.timeZone) : undefined}
                yearOnNewLine={false}
                showTime={false}
                wrap={true}
                style={{ ...adminSectionTableFontSize }}
              />
            );
          }
          return <>-</>;
        },
        Filter: DateColumnFilter
      },
      {
        Header: 'Ends On',
        accessor: 'efacitySessionId.schedule.endDateTime',
        Cell: ({ row, value }) => {
          if (value) {
            return (
              <DateCell
                dateTime={convertUtcToZonedTime(value, row.original.efacitySessionId?.timeZone)}
                yearOnNewLine={false}
                showTime={false}
                wrap={true}
                style={{ ...adminSectionTableFontSize }}
              />
            );
          }
          return <>-</>;
        },
        Filter: DateColumnFilter
      },
      {
        Header: 'Student',
        accessor: 'registeredStudentFullName',
        Cell: ({ value, row, state }) => {
          return (
            <NameLinkWithNoteIcon
              name={row.original.registeredStudentFullName}
              onNameClick={() =>
                setStudentIdUnderEdit({
                  studentId: row.original.registeredStudent,
                  studentName: row.original.registeredStudentFullName,
                  fetchDataOptions: {
                    filters: state.filters,
                    pageIndex: state.pageIndex,
                    pageSize: state.pageSize,
                    sortBy: state.sortBy
                  },
                  tab: StudentWithCustomerInfoTabs.MAIN
                })
              }
              onNoteClick={() =>
                onOpenNotesModal(
                  row.original.registeredStudent,
                  row.original.registeredStudentFullName,
                  `/org/${orgId}/users/students/${row.original.registeredStudent}/notes`
                )
              }
            />
          );
        }
      },
      {
        Header: 'Agreements',
        Cell: ({ row, state }) => {
          return (
            <IconButton
              onClick={() => {
                setStudentIdUnderEdit({
                  studentId: row.original.registeredStudent,
                  studentName: row.original.registeredStudentFullName,
                  fetchDataOptions: {
                    filters: state.filters,
                    pageIndex: state.pageIndex,
                    pageSize: state.pageSize,
                    sortBy: state.sortBy
                  },
                  tab: StudentWithCustomerInfoTabs.AGREEMENTS
                });
              }}
            >
              <MustAgreeUnCheckedIcon color="primary" />
            </IconButton>
          );
        }
      },
      {
        Header: 'Customer',
        accessor: 'customerFullName',
        Cell: ({ value, row, state }) => {
          return (
            <Link
              component="button"
              align={'left'}
              underline={'none'}
              onClick={() =>
                setCustomerUnderEdit({
                  customerId: row.original.customerId,
                  fetchDataOptions: {
                    filters: state.filters,
                    pageIndex: state.pageIndex,
                    pageSize: state.pageSize,
                    sortBy: state.sortBy
                  }
                })
              }
            >
              {value}
            </Link>
          );
        }
      },
      {
        Header: 'Email',
        accessor: 'customerEmail',
        Cell: ({ value }) => {
          if (value) {
            return <EmailAddress email={value} mobileView={mobileView} />;
          }
          return <>-</>;
        }
      },
      {
        Header: 'Registered On',
        accessor: 'registeredAt',
        Cell: ({ row, value }) => {
          if (value) {
            return (
              <DateCell
                dateTime={convertUtcToZonedTime(value, row.original.efacitySessionId?.timeZone)}
                yearOnNewLine={false}
                wrap={true}
                style={{ ...adminSectionTableFontSize }}
              />
            );
          }
          return <>-</>;
        },
        Filter: DateColumnFilter
      },
      {
        Header: 'Amount',
        accessor: 'price',
        columnStyles: {
          textAlign: 'right'
        },
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              {moneyWithCurrencyCodeFormatter(
                getAmountForRegistration(row.original._id, row.original?.invoiceBalance?.invoiceItems || []),
                row.original.currencyCode
              )}
            </>
          );
        }
      },
      {
        Header: 'Discount',
        accessor: 'discount',
        columnStyles: {
          textAlign: 'right'
        },
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              {moneyWithCurrencyCodeFormatter(
                getDiscountsAmountForRegistration(row.original._id, row.original?.invoiceBalance?.invoiceItems || []),
                row.original.currencyCode
              )}
            </>
          );
        }
      },
      {
        Header: 'Balance',
        accessor: 'balance',
        columnStyles: {
          textAlign: 'right'
        },
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              {moneyWithCurrencyCodeFormatter(
                row.original.status === RegistrationStatus.RegisteredAndPaid
                  ? 0
                  : getBalanceForRegistration(row.original._id, row.original?.invoiceBalance?.invoiceItems || []),
                row.original.currencyCode
              )}
            </>
          );
        }
      },
      {
        id: 'registration-actions-option',
        columnStyles: { width: 110, maxWidth: 110, paddingRight: 5, paddingLeft: 5 },
        headerStyles: { width: 110, maxWidth: 110 },
        Cell: ({ row, state }) => {
          return (
            <RegistrationActionsButton
              payPath={
                row.original?.invoiceBalance?._id &&
                !(
                  row.original.status === RegistrationStatus.RegisteredAndPaid ||
                  row.original.status === RegistrationStatus.Unregistered
                )
                  ? toPath(PATHS.invoice, { orgId: orgId, invoiceId: row.original?.invoiceBalance._id })
                  : null
              }
              onOpenNotesModal={onOpenNotesModal}
              onCancel={() =>
                onOpenCancellationModal(
                  row.original._id,
                  row.original.registeredStudent,
                  row.original.efacitySessionId.name,
                  false,
                  '',
                  {
                    filters: state.filters,
                    pageIndex: state.pageIndex,
                    pageSize: state.pageSize,
                    sortBy: state.sortBy
                  }
                )
              }
              cancelDisabled={row.original.status === RegistrationStatus.Unregistered}
              onSwitch={() =>
                onOpenCancellationModal(
                  row.original._id,
                  row.original.registeredStudent,
                  row.original.efacitySessionId.name,
                  true,
                  moneyWithCurrencyCodeFormatter(
                    row.original.cancellationBalance,
                    row.original.invoiceBalance?.currencyCode
                  ),
                  {
                    filters: state.filters,
                    pageIndex: state.pageIndex,
                    pageSize: state.pageSize,
                    sortBy: state.sortBy
                  }
                )
              }
              disableActionButtons={registrationCancelling}
              invoiceId={row.original?.invoiceBalance._id}
              isPaid={row.original?.invoiceBalance.isPaid}
              customerId={row.original.customerId}
              name={row.original?.customerFullName || '[no name]'}
              orgId={orgId}
            />
          );
        }
      }
    ];
  }, [
    orgId,
    onOpenCancellationModal,
    registrationCancelling,
    mobileView,
    setStudentIdUnderEdit,
    setCustomerUnderEdit,
    onOpenNotesModal
  ]);
};
