import { injectGtagScript } from './injectReactGtagScript';
import { getShoppingCartRegistrationsForGtag } from './getShoppingCartRegistrationsForGtag';
import { ShoppingCartRegistration } from '@efacity/common';
import { GtagInvoice } from './GtagInvoiceItem';

interface gtagShoppingEventParameters {
  eventName: 'begin_checkout' | 'purchase';
  shoppingCartRegistrations: ShoppingCartRegistration[];
  shoppingCartTotal: number;
  tax: number;
}
export class GoogleAnalytics {
  static gtag: (...args: unknown[]) => void;

  static init(_gtag: (...args: unknown[]) => void) {
    GoogleAnalytics.gtag = _gtag;

    injectGtagScript();

    GoogleAnalytics.gtag('js', new Date());
    GoogleAnalytics.gtag('config', process.env['REACT_APP_GA_MEASUREMENT_ID']);
  }

  static shoppingCartEvent({
    eventName,
    shoppingCartRegistrations,
    shoppingCartTotal,
    tax
  }: gtagShoppingEventParameters) {
    if (shoppingCartRegistrations.length === 0) return;

    const items = getShoppingCartRegistrationsForGtag(shoppingCartRegistrations);
    const currency = shoppingCartRegistrations[0].session.currencyCode;

    GoogleAnalytics.gtag('event', eventName, {
      currency: currency,
      value: shoppingCartTotal / 100,
      items: items,
      tax: tax / 100
    });
  }

  static invoicePurchaseEvent = (gtagInvoice: GtagInvoice) => {
    GoogleAnalytics.gtag('event', 'purchase', gtagInvoice);
  };
}
