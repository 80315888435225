import React from 'react';
import Table, { PaginationTypes } from '../../../components/Table/Table';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import { IconButton } from '@mui/material';
import { smallPublishButtonStyle } from '../../../components/Buttons/SessionPublishButton';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import useAddressesTableColumns from './useAddressesTableColumns';
import { Address } from '@efacity/common';

export interface SchoolAddressesTableProps {
  addresses: Address[];
  isLoading: boolean;
  orgId: string;
  handleDeleteAddress: (addressId: string) => void;
  handleEditAddress: (addressId: string) => void;
}

const OrganizationAddressesTable: React.FC<SchoolAddressesTableProps> = ({
  addresses,
  isLoading,
  orgId,
  handleDeleteAddress,
  handleEditAddress
}) => {
  const columns = useAddressesTableColumns(orgId, handleEditAddress, [
    {
      Header: '',
      id: 'actions-cell',
      disableSortBy: true,
      columnStyles: { ...adminSectionTableFontSize, width: 75, maxWidth: 75 },
      Cell: ({ row }) => {
        return (
          <IconButton
            color="primary"
            aria-label="delete address"
            data-testid="delete-address-btn"
            component="span"
            style={smallPublishButtonStyle}
            onClick={() => handleDeleteAddress(row.original._id)}
          >
            <DeleteIcon />
          </IconButton>
        );
      }
    }
  ]);

  return (
    <Table
      columns={columns}
      data={addresses}
      loading={isLoading}
      paginationType={PaginationTypes.ClientSide}
      noDataText="No addresses..."
    />
  );
};

export default OrganizationAddressesTable;
