import { useEffect, useState } from 'react';
import { apiService, Teacher } from '@efacity/frontend-shared';

export interface TeacherState {
  options: Teacher[];
  loading: boolean;
  activityId?: string;
}

export const useTeacherOptions = (url: string, activityId = null, sessionId = null) => {
  const [teacherState, setTeacherState] = useState<TeacherState>({
    options: [],
    loading: false
  });

  useEffect(() => {
    const getSessionTeacherOptions = async () => {
      setTeacherState(state => ({
        ...state,
        loading: true
      }));
      const params = activityId ? { activityId: activityId, sessionId: sessionId } : null;

      const { data } = await apiService.get<Teacher[]>(url, params);

      setTeacherState(() => ({
        loading: false,
        options: data
      }));
    };
    getSessionTeacherOptions();
  }, [url, activityId, sessionId]);

  const addTeacherToOptions = (teacher: Teacher) => {
    setTeacherState({
      ...teacherState,
      options: [...teacherState.options, teacher]
    });
  };

  return {
    teacherState,
    setTeacherState,
    addTeacherToOptions
  };
};
