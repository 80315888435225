import { navigationItemActiveStyle, navigationItemStyle, spinnerStyle } from '@efacity/frontend-shared';
import { Box, CircularProgress, IconButton, List, ListItemButton, ListItemText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useMatchRoutes } from '../../hooks/useMatchRoutes';
import { VerticalDivider } from '@efacity/react-next-sc';
import { navigateToPage } from '../../utils/navigateToPage';

interface NavigationItemWithAddButtonProps {
  label: string;
  link: string;
  addButtonLink: string;
  addButtonDisabled?: boolean;
  matchPaths: string[];
  isLoading?: boolean;
  onMenuItemClick?: any;
}

const NavigationItemWithAddButton: React.FC<NavigationItemWithAddButtonProps> = ({
  label,
  link,
  addButtonLink,
  addButtonDisabled,
  matchPaths,
  isLoading = false,
  onMenuItemClick
}) => {
  const navigate = useNavigate();
  const isLinkActive = useMatchRoutes(matchPaths);

  const onNavigateToAddPage = (path: string) => (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    navigateToPage(navigate)(path);
    onMenuItemClick();
  };

  return (
    <List component="nav" style={{ padding: 0 }}>
      <Box display="flex" key={'ActivitiesNavigationLinkKey'}>
        <ListItemButton
          component={NavLink}
          onClick={onMenuItemClick}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          style={({ isActive }) =>
            isActive || isLinkActive ? { ...navigationItemActiveStyle, ...navigationItemStyle } : navigationItemStyle
          }
          to={link}
        >
          <ListItemText primary={label} style={{ margin: 0 }} />
          {!addButtonDisabled && (
            <>
              <VerticalDivider />
              {isLoading ? (
                <CircularProgress size={24} style={spinnerStyle} />
              ) : (
                <IconButton color="primary" onClick={onNavigateToAddPage(addButtonLink)} disableRipple aria-label="add">
                  <AddIcon />
                </IconButton>
              )}
            </>
          )}
        </ListItemButton>
      </Box>
    </List>
  );
};

export default NavigationItemWithAddButton;
