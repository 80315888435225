import { EnhancedColumn } from '../../../components/Table/Table';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from '@mui/material';

const useAddressesTableColumns = (
  orgId: string,
  handleEditAddress: (addressId: string) => void,
  additionalColumns: EnhancedColumn<any>[] = []
) => {
  return useMemo(() => {
    const headerColumn: EnhancedColumn<any> = {
      Header: 'Address',
      accessor: 'address',
      disableFilters: true,
      columnStyles: { ...adminSectionTableFontSize },
      Cell: ({ row, value }) => {
        return (
          <Link component={NavLink} to={'#'} onClick={() => handleEditAddress(row.original._id)} underline={'none'}>
            {value}
          </Link>
        );
      }
    };
    const countryColumn: EnhancedColumn<any> = {
      Header: 'Country',
      accessor: 'country',
      disableFilters: true,
      Cell: ({ value }) => {
        return value;
      }
    };
    const cityColumn: EnhancedColumn<any> = {
      Header: 'City',
      accessor: 'city',
      disableFilters: true,
      Cell: ({ value }) => {
        return value;
      }
    };
    const stateColumn: EnhancedColumn<any> = {
      Header: 'State',
      accessor: 'state',
      disableFilters: true,
      Cell: ({ value }) => {
        return value || '';
      }
    };
    const zipColumn: EnhancedColumn<any> = {
      Header: 'Zip',
      accessor: 'zip',
      disableFilters: true,
      Cell: ({ value }) => {
        return value;
      }
    };
    const columns: EnhancedColumn<any>[] = [
      headerColumn,
      countryColumn,
      cityColumn,
      stateColumn,
      zipColumn,
      ...additionalColumns
    ];
    return columns;
  }, [additionalColumns, handleEditAddress]);
};

export default useAddressesTableColumns;
