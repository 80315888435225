import { passwordValidator, CircularProgressButton } from '@efacity/frontend-shared';
import { FormPasswordInput } from '@efacity/react-hook-form-mui';
import { ResetPasswordFormValues } from '@efacity/react-next-sc';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  password: passwordValidator()
});

interface RenewPasswordFormProps {
  onResetPassword: (values: ResetPasswordFormValues, resetForm: any) => void;
}

export const ResetPasswordForm: FC<RenewPasswordFormProps> = ({ onResetPassword }) => {
  const methods = useForm<ResetPasswordFormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema)
  });
  const { isSubmitting, isValid } = methods.formState;
  const onSubmit = (values) => {
    onResetPassword(values, methods.reset);
  };
  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <FormPasswordInput label="Your new password" />
        <Box display={'flex'} justifyContent={'center'} style={{ marginTop: 25 }}>
          <CircularProgressButton progress={isSubmitting} disabled={!isValid || isSubmitting} color="primary">
            Reset password
          </CircularProgressButton>
        </Box>
      </form>
    </FormProvider>
  );
};
