import React, { FC } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { PaymentPlatformSmallInfo } from '@efacity/frontend-shared';
import { CurrencyCode, PaymentPlatformType } from '@efacity/common';

interface PaymentPlatformSelectorProps {
  selectedPlatformId: string;
  paymentPlatforms: PaymentPlatformSmallInfo[];
  onSelect;
}

const emptyPaymentPlatform: PaymentPlatformSmallInfo = {
  _id: '',
  name: 'None',
  paymentPlatformType: PaymentPlatformType.Stripe,
  stripePlatformAccount: {
    currencyCode: CurrencyCode.USD,
    isEfacity: false,
    stripeSecretKey: '',
    stripePublishableKey: ''
  },
  stripeExpressAccount: {
    account: null,
    currencyCode: CurrencyCode.USD,
    isConnectingNow: false,
    _id: ''
  }
};

const PaymentPlatformSelector: FC<PaymentPlatformSelectorProps> = ({
  selectedPlatformId,
  paymentPlatforms,
  onSelect
}) => {
  const platformsWithNone = [emptyPaymentPlatform, ...paymentPlatforms];
  const selectedPlatform = platformsWithNone.find(
    (account: PaymentPlatformSmallInfo) => account._id === selectedPlatformId
  );
  const value = selectedPlatform ? selectedPlatform : emptyPaymentPlatform;

  return (
    <div style={{ width: 390, marginTop: 15, marginBottom: 35 }} data-testid="payment-platform-selector">
      <Autocomplete
        id="select-organization"
        value={value}
        onChange={(_, data: PaymentPlatformSmallInfo) => onSelect(data?._id || null)}
        options={platformsWithNone}
        noOptionsText="None"
        size="small"
        fullWidth={true}
        getOptionLabel={(account: PaymentPlatformSmallInfo) => {
          return account?._id?.length > 0
            ? `${account.name} (${
                account.stripePlatformAccount?.currencyCode || account.stripeExpressAccount?.currencyCode
              })`
            : account.name;
        }}
        isOptionEqualToValue={(option: any, value: any) => {
          return value._id === selectedPlatformId;
        }}
        clearOnBlur={true}
        clearOnEscape={true}
        renderInput={(params) => <TextField {...params} variant="outlined" size="small" fullWidth />}
      />
    </div>
  );
};

export default PaymentPlatformSelector;
