import React from 'react';
import { Box, Button, Dialog, DialogContent, DialogProps, TextField, Typography } from '@mui/material';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/Check';
import { HorizontalField } from '@efacity/frontend-shared';
import { ModalDialogTitle } from '@efacity/react-next-sc';

const connectModalStyles = {
  button: {
    width: '150px',
    height: '32px',
    textTransform: 'uppercase',
    fontSize: 14
  } as React.CSSProperties,
  nameContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexGrow: 1,
    marginRight: 0
  }
};

export interface StartConnectModalProps extends DialogProps {
  open: boolean;
  message: string;
  onClose: () => void;
  onStart: () => void;
  connectName: string;
  onNameChanged;
}

const StartConnectModal: React.FC<StartConnectModalProps> = ({
  open,
  message,
  connectName,
  onClose,
  onStart,
  onNameChanged
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth data-testid="start-connect-modal">
      <ModalDialogTitle text="Stripe Standard Connect" onClose={onClose} />
      <DialogContent>
        <hr style={{ height: 2, marginTop: -5 }} />
        <Typography variant={'h6'} color="error">
          <div
            style={{ marginTop: 10, marginBottom: 20, fontSize: 18 }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </Typography>
        <Box style={connectModalStyles.nameContainer} data-testid="connectNameInputBox">
          <TextField
            name="connectNameInput"
            id="connectName"
            value={connectName}
            onChange={onNameChanged}
            fullWidth
            size="small"
            color="primary"
            variant="outlined"
            label="Connect Name"
            inputProps={{
              'data-testid': 'connectNameInput'
            }}
          />
        </Box>
        <hr style={{ height: 2, marginBottom: 15 }} />
        <HorizontalField justifyContent="center" wrapOnSmallScreen={false} style={{ padding: 9, width: '100%' }}>
          <Button
            style={connectModalStyles.button}
            onClick={onClose}
            color="primary"
            variant="outlined"
            data-testid="start-connect-cancel-button"
          >
            <CancelOutlined style={{ color: 'primary', marginRight: 10 }} />
            Cancel
          </Button>
          <Button
            style={connectModalStyles.button}
            color="primary"
            variant="contained"
            onClick={onStart}
            data-testid="start-connect-button"
            disabled={connectName?.length < 1}
          >
            <SaveIcon style={{ marginRight: 10 }} />
            Start
          </Button>
        </HorizontalField>
      </DialogContent>
    </Dialog>
  );
};

export default StartConnectModal;
