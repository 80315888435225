import { EnhancedColumn } from '../../components/Table/Table';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import { PATHS, toPath } from '@efacity/routing';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from '@mui/material';

const usePaymentPlatformsColumns = (additionalColumns: EnhancedColumn<any>[] = []) => {
  return useMemo(() => {
    const columns: EnhancedColumn<any>[] = [
      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,
        columnStyles: { ...adminSectionTableFontSize },
        Cell: ({ row, value }) => {
          return (
            <Link
              component={NavLink}
              to={toPath(PATHS.editPaymentPlatform, { id: row.original._id })}
              underline={'none'}
            >
              {value}
            </Link>
          );
        }
      },
      {
        Header: 'Currency',
        accessor: 'currencyCode',
        disableFilters: true,
        Cell: ({ row }) => {
          return row.original.stripePlatformAccount.currencyCode;
        }
      },
      {
        Header: 'Efacity Default',
        accessor: 'isEfacity',
        disableFilters: true,
        Cell: ({ row }) => {
          return row.original?.stripePlatformAccount?.isEfacity ? 'Yes' : '';
        }
      },
      ...additionalColumns
    ];

    return columns;
  }, [additionalColumns]);
};

export default usePaymentPlatformsColumns;
