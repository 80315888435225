import * as yup from 'yup';
import { isListOfEmails, MailingServer, Messages, TaxTypes, ValidationErrors } from '@efacity/common';
import { addressFormValidationSchema } from '@efacity/frontend-next-shared/org-settings';
import { requiredPhoneValidator } from '@efacity/frontend-shared';

const centerSettingsValidationSchema = (path: string) => {
  return {
    name: yup.string().required('Center name is required!'),
    description: yup.string(),
    timeZone: yup.string().required('Select TimeZone'),
    ein: yup.string(),
    website: yup.string().optional().url(Messages.URLRequired),
    hideNumberOfRegistrations: yup.boolean(),
    validateUserOnRegistration: yup.boolean(),
    customerLogoutUrl: yup.string().optional().url(Messages.URLRequired),
    sendFromEmail: yup.string(),
    phone: requiredPhoneValidator(`${path}.phone`),
    phoneCountry: yup.string().required(),
    businessTaxId: yup.string(),
    currency: yup.string(),
    schoolFee: yup
      .number()
      .min(0, Messages.SchoolFeeInvalidRange)
      .max(100, Messages.SchoolFeeInvalidRange)
      .required(Messages.SchoolFeeIsRequired),
    accountHolderFirstName: yup.string(),
    accountHolderLastName: yup.string(),
    birthDate: yup.string(),
    socialSecurity: yup.string(),
    accountNumber: yup.string(),
    routingNumber: yup.string(),
    taxType: yup.string(),
    taxPercentage: yup.number().when(['taxType'], (taxType, schema) => {
      if (taxType && taxType !== TaxTypes.None) {
        return schema.min(1, Messages.WrongTaxPercentage).max(99, Messages.WrongTaxPercentage);
      }
    }),
    additionalNotificationEmails: yup
      .string()
      .test('additionalNotificationEmails', null, function (this: yup.TestContext, fieldValue: any): any {
        if (fieldValue?.length > 0) {
          if (isListOfEmails(fieldValue)) {
            return true;
          }
          const { path, createError } = this;
          return createError({
            path: path,
            message: ValidationErrors.InvalidEmailsList
          });
        }
        return true;
      }),
    additionalNotificationEmailsToPrimarySchool: yup
      .string()
      .test(
        'additionalNotificationEmailsToPrimarySchool',
        null,
        function (this: yup.TestContext, fieldValue: any): any {
          if (fieldValue?.length > 0) {
            if (isListOfEmails(fieldValue)) {
              return true;
            }
            const { path, createError } = this;
            return createError({
              path: path,
              message: ValidationErrors.InvalidEmailsList
            });
          }
          return true;
        }
      ),
    smtpSettings: yup.object().when('mailingServer', {
      is: MailingServer.smtp,
      then: yup.object().shape({
        server: yup.string().required(ValidationErrors.SmtpServerRequired),
        port: yup.number().required(ValidationErrors.PortRequired),
        ssl: yup.boolean().required(ValidationErrors.SSLRequired),
        user: yup.string().required(ValidationErrors.UserRequired),
        password: yup.string().optional()
      })
    })
  };
};

export const addCenterFormValidationSchema = (path: string) =>
  yup.object().shape({
    centerSettings: yup.object().shape({
      ...centerSettingsValidationSchema(path),
      defaultAddress: addressFormValidationSchema
    }),
    administrators: yup
      .array()
      .of(
        yup.object().shape(
          {
            fullName: yup.string().when(['email'], {
              is: (email) => !!email?.length,
              then: yup.string().required('Full Name is required')
            }),
            callByName: yup.string(),
            email: yup.string().when(['fullName'], {
              is: (fullName) => fullName?.length,
              then: yup.string().email(Messages.InvalidEmail).required('Email is required')
            })
          },
          [['fullName', 'email']]
        )
      )
      .required()
      .min(0),
    allowedActivities: yup.array()
  });

export const editCenterFormValidationSchema = (path: string) =>
  yup.object().shape({
    centerSettings: yup.object().shape({
      ...centerSettingsValidationSchema(path),
      defaultAddress: yup.object().shape({
        _id: yup.string().required(Messages.DefaultAddressRequired)
      })
    }),
    administrators: yup.array().of(
      yup.object().shape({
        fullName: yup.string().required('Full Name is required'),
        callByName: yup.string()
      })
    ),
    allowedActivities: yup.array()
  });
