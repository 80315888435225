import React, { FC } from 'react';
import { MenuItem } from '@mui/material';
import { CountryCode, getStateOptionsDependingOnCountry } from '@efacity/common';
import { FormInputTextProps, FormTextInput } from './FormTextInput';

interface StateSelectorProps {
  countryCode: CountryCode;
}

export const StateSelector: FC<StateSelectorProps & FormInputTextProps> = ({
  name,
  countryCode,
  label = 'State',
  ...props
}) => {
  const stateOptions = getStateOptionsDependingOnCountry(countryCode) || [];

  return (
    <FormTextInput
      data-testid="stateSelect"
      name={name}
      label={label}
      required={stateOptions?.length > 0}
      select
      {...props}
    >
      <MenuItem value="">None</MenuItem>
      {stateOptions.map((stateOption, index) => (
        <MenuItem value={stateOption.value} key={index}>
          {stateOption.label}
        </MenuItem>
      ))}
    </FormTextInput>
  );
};
