import React from 'react';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';
import { SENTRY_IGNORE_ERRORS } from '@efacity/common';
import { gtag } from './gtag';
import { GoogleAnalytics } from '@efacity/google-analytics';

const REACT_APP_ENVIRONMENT = process.env['REACT_APP_ENVIRONMENT'] || 'dev';

if (REACT_APP_ENVIRONMENT === 'production') {
  const SENTRY_DSN =
    process.env['SENTRY_DSN'] ||
    process.env['REACT_APP_SENTRY_DSN'] ||
    'https://6d6a242468274782a670d898cfbd0264@o187274.ingest.sentry.io/5972868';
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: REACT_APP_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],

    ignoreErrors: SENTRY_IGNORE_ERRORS,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });

  const logRocketAppId = process.env['REACT_APP_LOG_ROCKET_APP_ID'] || 'jarzeg/efacity-dev';
  LogRocket.init(logRocketAppId);
  // eslint-disable-next-line no-console
  console.log('LogRocket Initialized for ' + logRocketAppId);
  setupLogRocketReact(LogRocket);
} else {
  // eslint-disable-next-line no-console
  console.log('Logging is disabled');
}

GoogleAnalytics.init(gtag);

const container = document.getElementById('app');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
