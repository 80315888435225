import {
  Agreement,
  ApplicationStatus,
  convertUtcToZonedTime,
  CountryCode,
  CurrencyCode,
  enrollmentFormInitialValues,
  EnrollmentFormValues,
  isDate,
  Messages,
  PAYMENT_LIMIT_IN_CENTS,
  PaymentAcceptanceMethod,
  PaymentCard,
  User,
  ValidationType
} from '@efacity/common';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import {
  apiService,
  EnrollmentApplicationFrontend,
  getFormAsFormData,
  getMergedFormValues,
  paymentDoneModalInitialState,
  PaymentDoneModalState
} from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { cloneDeep } from 'lodash';
import { useRouter } from 'next/navigation';
import { PATHS, toPath } from '@efacity/routing';
import { zonedTimeToUtc } from 'date-fns-tz';

export interface UserAgreement {
  user: Partial<User>;
  agreement: Agreement;
}

export interface AgreementsModalState {
  isOpen: boolean;
  onSignAgreement: (userId: string, agreementId: string) => void;
  onClose: () => void;
  agreements: Agreement[];
  unsignedAgreements: UserAgreement[];
  allAgreementsSigned: boolean;
}
export const agreementsModalInitialState: AgreementsModalState = {
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSignAgreement: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
  agreements: [],
  unsignedAgreements: [],
  allAgreementsSigned: false
};

export type EnrollmentApplicationFormState = {
  _id: string | null;
  isLoading: boolean;
  enrollmentForm: EnrollmentFormValues;
  mergedFormValues: EnrollmentFormValues;
  isViewMode: boolean;
  submittedOn: string | undefined;
  paymentRequired: boolean;
  price: number;
  currency: CurrencyCode;
  stripePublishableKey: string | null;
  paymentAcceptanceMethod: PaymentAcceptanceMethod;
  paymentCards: PaymentCard[];
  defaultCountry: CountryCode | null;
  paymentDoneModal: PaymentDoneModalState;
  agreementsModal: AgreementsModalState;
  message?: string;
  applicationStatus: ApplicationStatus;
  comment?: string;
};
export const initialEnrollmentApplicationFormState = {
  _id: null,
  isLoading: true,
  enrollmentForm: enrollmentFormInitialValues,
  mergedFormValues: enrollmentFormInitialValues,
  isViewMode: false,
  submittedOn: undefined,
  paymentRequired: false,
  price: 0,
  currency: CurrencyCode.USD,
  stripePublishableKey: null,
  paymentAcceptanceMethod: PaymentAcceptanceMethod.Efacity,
  defaultCountry: null,
  paymentCards: [],
  paymentDoneModal: paymentDoneModalInitialState,
  agreementsModal: agreementsModalInitialState,
  applicationStatus: ApplicationStatus.Draft
};
export interface EnrollmentResponse {
  userEnrollment: EnrollmentApplicationFrontend;
  agreements: Agreement[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationErrors: any;
  stripePublishableKey: string | null;
  paymentAcceptanceMethod: PaymentAcceptanceMethod;
  paymentCards: PaymentCard[];
  defaultCountry: CountryCode | null;
  message: string;
}

export const useEnrollmentApplication = (orgId: string, timeZone: string) => {
  const router = useRouter();
  const snackbarProps = useSnackbar();
  const [currentYearReportCard, setCurrentYearReportCard] = useState<File[]>([]);
  const [iqTest, setIqTest] = useState<File[]>([]);
  const [teacherRecommendations, setTeacherRecommendations] = useState<File[]>([]);
  const [optionalSupplemental, setOptionalSupplemental] = useState<File[]>([]);

  const [enrollmentApplicationFormState, setEnrollmentApplicationFormState] = useState<EnrollmentApplicationFormState>(
    initialEnrollmentApplicationFormState
  );
  const justClosePaymentDoneModal = () => {
    setEnrollmentApplicationFormState((state) => ({
      ...state,
      paymentDoneModal: {
        ...state.paymentDoneModal,
        isOpen: false
      }
    }));
  };
  const closePaymentDoneModalAndGotoCustomerSection = () => {
    setEnrollmentApplicationFormState((state) => ({
      ...state,
      paymentDoneModal: {
        ...state.paymentDoneModal,
        isOpen: false
      }
    }));
    router.push(toPath(PATHS.usersEnrollmentApplications));
  };

  const dateFieldsParser = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dateOfBirth: (apiValues: any, blankFormValues: any) => {
      return isDate(apiValues.dateOfBirth)
        ? convertUtcToZonedTime(apiValues.dateOfBirth, timeZone)
        : blankFormValues.dateOfBirth;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    enrolledFrom: (apiValues: any, blankFormValues: any) => {
      return isDate(apiValues.enrolledFrom)
        ? convertUtcToZonedTime(apiValues.enrolledFrom, timeZone)
        : blankFormValues.enrolledFrom;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    enrolledTo: (apiValues: any, blankFormValues: any) => {
      return isDate(apiValues.enrolledTo)
        ? convertUtcToZonedTime(apiValues.enrolledTo, timeZone)
        : blankFormValues.enrolledTo;
    }
  };

  const mergeEnrollmentValuesForTimeZone = (apiValues: EnrollmentFormValues, paymentCards: PaymentCard[]) => {
    if (!apiValues['payment']) apiValues['payment'] = enrollmentFormInitialValues.payment;
    if (paymentCards?.length > 0 && !apiValues?.payment?.userPaymentMethodId) {
      apiValues['payment']['userPaymentMethodId'] = paymentCards[0].paymentCardId;
    }
    const mergedValues = getMergedFormValues<EnrollmentFormValues, EnrollmentFormValues>(
      enrollmentFormInitialValues,
      apiValues,
      [],
      dateFieldsParser
    );
    return mergedValues;
  };

  const getEnrollmentFormValuesWithFiles = (
    enrollmentFormValues: EnrollmentFormValues,
    currentYearReportCard: File[],
    iqTest: File[],
    teacherRecommendations: File[],
    optionalSupplemental: File[]
  ) => {
    const valuesFromForm = cloneDeep(enrollmentFormValues);
    if (enrollmentFormValues?.student?.dateOfBirth)
      valuesFromForm.student.dateOfBirth = zonedTimeToUtc(enrollmentFormValues.student.dateOfBirth, timeZone);
    if (enrollmentFormValues?.student?.currentSchool?.enrolledFrom)
      valuesFromForm.student.currentSchool.enrolledFrom = zonedTimeToUtc(
        valuesFromForm.student.currentSchool.enrolledFrom,
        timeZone
      );

    const enrollmentForm = getFormAsFormData<Partial<EnrollmentFormValues>>(valuesFromForm, []);
    if (currentYearReportCard) {
      currentYearReportCard.forEach((file) => {
        enrollmentForm.append('currentYearReportCard', file);
      });
    }
    if (iqTest) {
      iqTest.forEach((file) => {
        enrollmentForm.append('iqTest', file);
      });
    }
    if (teacherRecommendations) {
      teacherRecommendations.forEach((file) => {
        enrollmentForm.append('teacherRecommendations', file);
      });
    }
    if (optionalSupplemental) {
      optionalSupplemental.forEach((file) => {
        enrollmentForm.append('optionalSupplemental', file);
      });
    }

    return enrollmentForm;
  };

  const getUserEnrollmentApplication = async (orgId: string | undefined, enrollmentId: string | undefined) => {
    setEnrollmentApplicationFormState((state) => ({ ...state, isLoading: true }));

    try {
      const enrollmentApplicationUrl = enrollmentId
        ? `/enrollments/${orgId}/actual-enrollment?enrollmentId=${enrollmentId}`
        : `/enrollments/${orgId}/actual-enrollment`;
      const { data } = await apiService.get<EnrollmentResponse>(enrollmentApplicationUrl);

      const paymentRequired: boolean =
        data.userEnrollment.price > PAYMENT_LIMIT_IN_CENTS &&
        !(
          data.paymentAcceptanceMethod === PaymentAcceptanceMethod.Alternative ||
          data.paymentAcceptanceMethod === PaymentAcceptanceMethod.None
        ) &&
        !!data.stripePublishableKey &&
        data.userEnrollment.applicationStatus === ApplicationStatus.Draft &&
        !data.userEnrollment.isPaid;

      const isViewMode =
        data.userEnrollment.applicationStatus !== ApplicationStatus.Draft &&
        data.userEnrollment.applicationStatus !== ApplicationStatus.ChangesRequested;

      setEnrollmentApplicationFormState((state) => ({
        ...state,
        _id: data.userEnrollment._id,
        enrollmentForm: data.userEnrollment.enrollmentForm,
        mergedFormValues: mergeEnrollmentValuesForTimeZone(data.userEnrollment.enrollmentForm, data.paymentCards),
        isViewMode: isViewMode,
        submittedOn: data.userEnrollment.submittedOn,
        paymentRequired: paymentRequired,
        price: data.userEnrollment.price,
        currency: data.userEnrollment.currencyCode,
        stripePublishableKey: data.stripePublishableKey,
        paymentAcceptanceMethod: data.paymentAcceptanceMethod,
        defaultCountry: data.defaultCountry,
        paymentCards: data.paymentCards,
        agreementsModal: {
          ...state.agreementsModal,
          agreements: data.agreements
        },
        applicationStatus: data.userEnrollment.applicationStatus,
        comment: data.userEnrollment.comment,
        isLoading: false
      }));
    } catch (error) {
      showNotification(snackbarProps, false, error.message || Messages.FailedGetEnrollmentApplication, true);
      setEnrollmentApplicationFormState((state) => ({ ...state, isLoading: false }));
    }
  };

  const saveEnrollmentApplication = async (enrollmentFormValues: EnrollmentFormValues) => {
    setEnrollmentApplicationFormState((state) => ({ ...state, isLoading: true }));

    try {
      const enrollmentForm = getEnrollmentFormValuesWithFiles(
        enrollmentFormValues,
        currentYearReportCard,
        iqTest,
        teacherRecommendations,
        optionalSupplemental
      );
      const { data } = await apiService.patch<EnrollmentResponse>(
        `/enrollments/${orgId}/enrollment/${enrollmentApplicationFormState._id}`,
        enrollmentForm
      );
      setEnrollmentApplicationFormState((state) => ({
        ...state,
        isLoading: false
      }));
      setCurrentYearReportCard([]);
      setIqTest([]);
      setTeacherRecommendations([]);
      setOptionalSupplemental([]);
      setEnrollmentApplicationFormState((state) => ({
        ...state,
        mergedFormValues: mergeEnrollmentValuesForTimeZone(data.userEnrollment.enrollmentForm, data.paymentCards),
        isLoading: false
      }));

      showNotification(snackbarProps, true, Messages.EnrollmentUpdated);
    } catch (error) {
      showNotification(snackbarProps, false, error.message || Messages.FailedUpdateEnrollment, true);
      setEnrollmentApplicationFormState((state) => ({ ...state, isLoading: false }));
    }
  };

  const submitEnrollmentApplication = async (values: EnrollmentFormValues) => {
    try {
      const enrollmentForm = getEnrollmentFormValuesWithFiles(
        values,
        currentYearReportCard,
        iqTest,
        teacherRecommendations,
        optionalSupplemental
      );

      const { data } = await apiService.post<{
        validationType: ValidationType;
        isError: boolean;
        message: string;
        linkToPaymentReceiptPDF: string;
        redirectUrl: string;
      }>(`/enrollments/${orgId}/enrollment/${enrollmentApplicationFormState._id}`, enrollmentForm);
      const enrollmentPayment = cloneDeep(data);

      if (enrollmentPayment.redirectUrl) {
        return { ...enrollmentPayment, isError: false, validationErrors: {} };
      }

      if (data?.linkToPaymentReceiptPDF) {
        window.open(data.linkToPaymentReceiptPDF);
      }
      setEnrollmentApplicationFormState((state) => ({
        ...state,
        paymentDoneModal: {
          isOpen: true,
          isError: false,
          message:
            'Thank you for your application.</br>We have received your information and will get in touch shortly.',
          onClose: closePaymentDoneModalAndGotoCustomerSection,
          title: 'Enrollment Application'
        }
      }));
      return { ...enrollmentPayment, isError: false, validationErrors: false };
    } catch (error) {
      if (error?.response?.data?.message) {
        setEnrollmentApplicationFormState((state) => ({
          ...state,
          paymentDoneModal: {
            isOpen: true,
            isError: true,
            message: error?.response?.data?.message,
            onClose: justClosePaymentDoneModal,
            title: 'Enrollment Application'
          }
        }));
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return { ...error.response.data, isError: true, validationErrors: error?.response?.data?.validationErrors || {} };
    }
  };

  const startSignAgreementModal = (userAgreement: UserAgreement) => {
    setEnrollmentApplicationFormState((state) => ({
      ...state,
      agreementsModal: {
        ...state.agreementsModal,
        isOpen: true,
        unsignedAgreements: [userAgreement],
        allAgreementsSigned: false
      }
    }));
  };

  const openAgreementsModal = () => {
    setEnrollmentApplicationFormState((state) => ({
      ...state,
      agreementsModal: {
        ...state.agreementsModal,
        isOpen: true
      }
    }));
  };
  const closeAgreementsModal = () => {
    setEnrollmentApplicationFormState((state) => ({
      ...state,
      agreementsModal: {
        ...state.agreementsModal,
        isOpen: false
      }
    }));
  };

  return {
    enrollmentApplicationFormState,
    setEnrollmentApplicationFormState,
    getUserEnrollmentApplication,
    saveEnrollmentApplication,
    submitEnrollmentApplication,
    currentYearReportCard,
    setCurrentYearReportCard,
    iqTest,
    setIqTest,
    teacherRecommendations,
    setTeacherRecommendations,
    optionalSupplemental,
    setOptionalSupplemental,
    openAgreementsModal,
    closeAgreementsModal,
    startSignAgreementModal
  };
};
