import { PageTitle, useAuth } from '@efacity/react-next-sc';
import { Box } from '@mui/material';
import React from 'react';
import { Row } from 'react-table';
import SmallInvoiceDataTable from '../../components/SmallInvoiceDataTable/SmallInvoiceDataTable';
import Table, { PaginationTypes } from '../../components/Table/Table';
import { useCustomerProductPurchases, UserProductPurchasesExtended } from './useCustomerProductPurchases';
import { useCustomerProductPurchasesColumns } from './useCustomerProductPurchasesColumns';
import { Messages } from '@efacity/common';

const CustomerProductPurchasesContainer: React.FC = () => {
  const {
    authState: {
      user: { _id }
    }
  } = useAuth();
  const { getCustomerProductPurchases, userProductPurchasesState } = useCustomerProductPurchases(_id);
  const columns = useCustomerProductPurchasesColumns();

  const renderInvoiceBalanceTable = (row: Row<UserProductPurchasesExtended>) => {
    return (
      <td colSpan={columns.length - 1}>
        <Box margin={1}>
          <SmallInvoiceDataTable
            invoiceBalance={row.original?.invoiceBalance}
            message={Messages.NoInvoiceForPurchase}
          />
        </Box>
      </td>
    );
  };

  return (
    <div>
      <PageTitle title="Purchases" addButtonLink={undefined} />
      <Table
        data={userProductPurchasesState.productPurchases}
        fetchData={getCustomerProductPurchases}
        columns={columns}
        pageCount={userProductPurchasesState.total}
        loading={userProductPurchasesState.isLoading}
        paginationType={PaginationTypes.ServerSide}
        renderRowSubComponent={renderInvoiceBalanceTable}
        noDataText="No purchasedd products..."
      />
    </div>
  );
};

export default CustomerProductPurchasesContainer;
