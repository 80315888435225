import React, { FC, useState } from 'react';
import { ClickAwayListener, Fade, Tooltip } from '@mui/material';
import DrawIcon from '@mui/icons-material/Draw';

const tooltipWidth = 200;

export const SignatureWithTooltip: FC<{ signatureUrl: string | null }> = ({ signatureUrl }) => {
  const [openTooltip, setOpenTooltipValue] = useState(false);

  return signatureUrl ? (
    <ClickAwayListener onClickAway={() => setOpenTooltipValue(false)}>
      <Tooltip
        onClose={() => setOpenTooltipValue(false)}
        open={openTooltip}
        title={
          <img
            src={signatureUrl}
            alt="customer signature"
            style={{ maxWidth: tooltipWidth - 4, backgroundColor: 'white', padding: 4 }}
          />
        }
        placement="left"
        arrow
        PopperProps={{
          disablePortal: true
        }}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        componentsProps={{ tooltip: { style: { maxWidth: tooltipWidth, padding: '2px' } } }}
      >
        <DrawIcon color="primary" onClick={() => setOpenTooltipValue(!openTooltip)} />
      </Tooltip>
    </ClickAwayListener>
  ) : (
    <> </>
  );
};
