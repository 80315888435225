export const racesOptions = [
  'American Indian or Alaska Native',
  'Asian',
  'Black or African American',
  'Hispanic or Latino',
  'Mixed Race',
  'Native Hawaiian or Other Pacific Islander',
  'Other',
  'Prefer not to answer',
  'White / Caucasian'
];
