import * as yup from 'yup';
import { Messages } from '@efacity/common';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

export const requiredPhoneValidator = (fieldName: string) => {
  return yup.string().test(fieldName, null, function (this: yup.TestContext, fieldValue: string) {
    if (!fieldValue) {
      return this.createError({ path: fieldName, message: Messages.PhoneRequired });
    }
    // Best to have consistent validation for phone on frontend and backend
    // isPossiblePhoneNumber is preferred way, but on backend, more robust checks could be performed
    // https://gitlab.com/catamphetamine/react-phone-number-input#isvalidphonenumbervalue-string-boolean
    if (!isPossiblePhoneNumber(fieldValue)) {
      return this.createError({ path: fieldName, message: Messages.InvalidPhoneNumber });
    }
    return true;
  });
};

export const optionalPhoneValidator = (fieldName: string) => {
  return yup
    .string()
    .optional()
    .test(fieldName, null, function (this: yup.TestContext, fieldValue: string) {
      if (!fieldValue) {
        return true;
      }
      // Best to have consistent validation for phone on frontend and backend
      // isPossiblePhoneNumber is preferred way, but on backend, more robust checks could be performed
      // https://gitlab.com/catamphetamine/react-phone-number-input#isvalidphonenumbervalue-string-boolean
      if (!isPossiblePhoneNumber(fieldValue)) {
        return this.createError({ path: fieldName, message: Messages.InvalidPhoneNumber });
      }
      return true;
    });
};
