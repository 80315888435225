import { convertValidationErrorsIntoObject } from './convertValidationErrorsIntoObject';
import { trimObjectStringValues } from '../lib/validators';
import { validationResult } from 'express-validator';

export const jsonObjectValidator = async (jsonObject, validators) => {
  const req = {
    body: {}
  };
  req.body = trimObjectStringValues(jsonObject);
  await Promise.all(validators.map((validator) => validator.run(req)));
  const errors = validationResult(req);

  if (!errors.isEmpty()) {
    const errorsObject = convertValidationErrorsIntoObject(errors.array({ onlyFirstError: true }));
    return errorsObject;
  } else {
    return {};
  }
};
