import {
  CurrencyCode,
  InvoiceItemPresentation,
  InvoiceItemTypes,
  moneyWithCurrencyCodeFormatter
} from '@efacity/common';
import React, { FC } from 'react';
import { cellSx, strippedTableRowSx } from '@efacity/frontend-shared';
import DeleteIconButtonWithSpinner from '../../../components/DeleteIconButtonWithSpinner/DeleteIconButtonWithSpinner';
import { TableCell, TableRow } from '@mui/material';

export interface ProductInvoiceItemTableRowProps {
  invoiceItem: InvoiceItemPresentation;
  handleDeleteProduct: (invoiceItemId: string) => void | null;
  isUpdating: boolean;
  disabled: boolean;
  index: number;
}

const ProductInvoiceItemTableRow: FC<ProductInvoiceItemTableRowProps> = ({
  invoiceItem,
  handleDeleteProduct,
  isUpdating,
  disabled,
  index
}) => {
  const handleDeleteProductIfAny = () => {
    if (handleDeleteProduct) {
      handleDeleteProduct(invoiceItem.invoiceItemId);
    }
  };

  return (
    <TableRow sx={strippedTableRowSx}>
      <TableCell sx={cellSx} align="left" colSpan={6}>
        {invoiceItem.description}
      </TableCell>
      <TableCell sx={cellSx} align="right" style={{ whiteSpace: 'nowrap', width: 120 }} scope="row">
        {moneyWithCurrencyCodeFormatter(invoiceItem.amount, invoiceItem.currencyCode as CurrencyCode)}
      </TableCell>
      <TableCell sx={cellSx} align="center" style={{ width: 80 }}>
        {invoiceItem.invoiceItemType === InvoiceItemTypes.Discount && invoiceItem.appliedAutomatically === false && (
          <DeleteIconButtonWithSpinner
            onClick={handleDeleteProductIfAny}
            isUpdating={isUpdating}
            data-testid={`deleteIcon${index}`}
            disabled={disabled}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default ProductInvoiceItemTableRow;
