import { activityTypesArray, CurrencyCode, DiscountClass, DiscountType } from '@efacity/common';
import { DiscountFormValues } from '@efacity/frontend-shared';
import { addDays } from 'date-fns';

export const initialDiscountFormValues: DiscountFormValues = {
  code: '',
  expiresOn: addDays(new Date(), 30),
  description: 'coupon discount',
  descriptionForCustomer: 'coupon discount',
  typeDefinition: {
    discountType: DiscountType.Amount,
    discountPercentage: 0,
    discountAmount: 0
  },
  currencyCode: CurrencyCode.USD,
  classDefinition: {
    discountClass: DiscountClass.Coupon,
    consecutiveSessionsNumber: 2,
    fullDayOnly: true,
    marginOfDays: 7
  },
  applyToAllSessionsInShoppingCart: false,
  activityTypes: activityTypesArray
};

export const defaultSiblingDiscountDescriptionForCustomer =
  'Each additional student will receive the sibling discount for the same session when purchased together.';
