import { getRolesView, rolesSelectOptions, User, formatStringToMonthDayYearDate } from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import { LinkCell, SelectColumnFilter, TextColumnFilter } from '@efacity/table';
import { createColumnHelper, TableOptions } from '@tanstack/react-table';
import { EmailAddressCell } from '../components/EmailAddressCell';
import { useMemo } from 'react';

const columnHelper = createColumnHelper<User>();

export const useUsersTableColumnDefs = (): TableOptions<User>['columns'] => {
  return useMemo(
    () => [
      columnHelper.accessor('fullName', {
        header: 'Name',
        cell: ({ row, getValue }) => {
          return (
            <LinkCell
              href={toPath(PATHS.siteAdminEditUser.replace('admin', 'site-admin'), { userId: row.original._id })}
              label={getValue()}
            />
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('email', {
        header: 'Email',
        cell: ({ getValue }) => {
          return <EmailAddressCell email={getValue()} />;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('roles', {
        header: 'Roles',
        cell: ({ getValue }) => {
          const roles = getValue();
          return roles.length > 0 ? <>{getRolesView(roles).join(', ')}</> : <>-</>;
        },
        meta: {
          filter: (column) => <SelectColumnFilter column={column} options={rolesSelectOptions} />
        },
        enableSorting: false
      }),
      columnHelper.accessor('createdAt', {
        header: 'Date joined',
        cell: ({ getValue }) => {
          const createdAt = getValue();
          return createdAt ? formatStringToMonthDayYearDate(createdAt) : '';
        },
        enableColumnFilter: false
      })
    ],
    []
  );
};
