import Dialog from '@mui/material/Dialog';
import { Button, CircularProgress, DialogContent, TextField, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Check';
import { useState } from 'react';

export const RequestChangesModal = ({ isOpen, onClose, onSubmit, enrollmentId }) => {
  const [isOperating, setIsOperating] = useState(false);
  const [message, setMessage] = useState('');

  const submitAndClose = async () => {
    setIsOperating(true);

    await onSubmit(enrollmentId, message);

    setIsOperating(false);
    setMessage('');
    onClose();
  };

  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
        <Typography>Please specify what changes need to be made to this application: </Typography>
        <TextField value={message} onChange={(e) => setMessage(e.target.value)} sx={{ width: '100%' }} />
        <Button
          color="primary"
          variant="contained"
          onClick={submitAndClose}
          disabled={isOperating || !message}
          aria-label="Confirm"
          sx={{ width: '150px', height: '36px' }}
        >
          {isOperating ? (
            <CircularProgress variant="indeterminate" size={20} color={'primary'} data-testid="progress-in-button" />
          ) : (
            <>
              <SaveIcon style={{ marginRight: 10 }} />
              Confirm
            </>
          )}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
