import * as yup from 'yup';
import { Messages } from '@efacity/common';
import { passwordValidator } from '../../../validators/passwordValidator';
import { requiredPhoneValidator } from '../../PhoneField/phoneValidators';

export const signUpFormValidationSchema = yup.object().shape({
  email: yup.string().email('Email Address is invalid').required('Email Address is required'),
  password: passwordValidator(),
  fullName: yup.string().required(Messages.NameRequired),
  defaultPhone: requiredPhoneValidator('defaultPhone'),
  defaultPhoneCountry: yup.string().required()
});
