import * as yup from 'yup';
import { Messages } from '@efacity/common';
import { optionalPhoneValidator } from '@efacity/frontend-shared';

export const studentFormValidationSchema = (requireStudentAge = false) => {
  const fieldsValidator = {
    fullName: yup.string().required(Messages.StudentNameRequired),
    email: yup.string().email(Messages.InvalidEmail),
    defaultPhone: yup
      .string()
      .optional()
      .when('defaultPhone', {
        is: (value) => value?.length,
        then: optionalPhoneValidator('defaultPhone')
      }), //optionalPhoneValidator('defaultPhone'),
    studentId: yup
      .string()
      .optional()
      .when('studentId', {
        is: (value) => value?.length,
        then: (rule) => rule.min(3, Messages.StudentIdLength).max(20, Messages.StudentIdLength)
      }),
    grade: yup.string(),
    medicalInformation: yup.string().optional(),
    emergencyInstructions: yup.string().optional(),
    tShirtSize: yup.string().optional()
  };

  if (requireStudentAge)
    fieldsValidator['age'] = yup.number().typeError(Messages.AgeRequired).required(Messages.AgeRequired);

  return yup.object().shape(fieldsValidator, [
    ['studentId', 'studentId'],
    ['defaultPhone', 'defaultPhone']
  ]);
};
