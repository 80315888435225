import React, { FC } from 'react';
import { TextField } from '@mui/material';
import { FilterProps } from 'react-table';

const CustomFilterColumn: FC<FilterProps<any>> = ({ column, gotoPage }) => {
  const handleFilterChange = (e) => {
    column.setFilter(e.target.value);
    gotoPage(0);
  };

  return (
    <div>
      <TextField
        variant="outlined"
        size="small"
        placeholder={`Search by ${column.Header}`}
        value={column.filterValue || ''}
        onChange={handleFilterChange}
        inputProps={{
          style: { fontSize: 14, margin: 0, padding: 5 }
        }}
      />
    </div>
  );
};

export default CustomFilterColumn;
