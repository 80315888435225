import React from 'react';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { useParams } from 'react-router-dom';
import Table, { PaginationTypes } from '../../components/Table/Table';
import { usePayments } from './usePayments';
import { usePaymentsColumns } from './usePaymentsColumns';
import { Row } from 'react-table';
import { Box } from '@mui/material';
import SmallInvoiceDataTable from '../../components/SmallInvoiceDataTable/SmallInvoiceDataTable';
import { InvoiceBalance } from '@efacity/common';
import SmallInvoicePaymentsTable from '../../components/SmallInvoicePaymentsTable/SmallInvoicePaymentsTable';
import { PageTitle } from '@efacity/react-next-sc';

const PaymentsContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const { paymentsState, getPayments } = usePayments(orgId);
  const columns = usePaymentsColumns(orgId, paymentsState.timeZone);

  const renderInvoiceBalanceTable = (row: Row<InvoiceBalance>) => {
    return row.state['showBalance'] ? (
      <td colSpan={columns.length - 1}>
        <Box margin={1}>
          <SmallInvoiceDataTable invoiceBalance={row.original} />
        </Box>
      </td>
    ) : (
      <td colSpan={columns.length - 1}>
        <Box margin={1}>
          <SmallInvoicePaymentsTable orgId={orgId} invoiceBalance={row.original} timeZone={paymentsState.timeZone} />
        </Box>
      </td>
    );
  };

  return (
    <div>
      <PageTitle title="Payments" />
      <Table
        columns={columns}
        fetchData={getPayments}
        data={paymentsState.payments}
        pageCount={paymentsState.total}
        loading={paymentsState.isLoading}
        paginationType={PaginationTypes.ServerSide}
        renderRowSubComponent={renderInvoiceBalanceTable}
        noDataText="No payments..."
      />
    </div>
  );
};

export default PaymentsContainer;
