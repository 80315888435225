import { apiService } from './apiService';

export const orgNameValidationAPI = async (name: string, orgId?: string) => {
  return new Promise((resolves, rejects) => {
    try {
      resolves(apiService.get('/organizations/check-name', { name: name, orgId: orgId }));
    } catch (e) {
      rejects(e);
    }
  });
};
