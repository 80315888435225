import { addDays, set, subDays } from 'date-fns';
import { nowUTC } from '../lib/DateTime';

export const maxComputerDate = new Date(2037, 11, 31, 23, 59, 59);
export const maxComputerDateMilliseconds = maxComputerDate.getMilliseconds();
export const date2020_06_16Date = new Date(2020, 6, 16);
export const tomorrow = addDays(nowUTC(), 1);
export const tomorrowMorning = set(addDays(nowUTC(), 1), {
  hours: 11,
  minutes: 0,
  seconds: 0
});
export const afterTomorrowEvening = set(addDays(nowUTC(), 2), {
  hours: 23,
  minutes: 30,
  seconds: 0
});
export const afterTomorrowMorning = set(afterTomorrowEvening, {
  hours: 11,
  minutes: 0,
  seconds: 0
});

export const threeDaysAheadEvening = set(addDays(nowUTC(), 3), {
  hours: 17,
  minutes: 0,
  seconds: 0
});
export const fiveDaysAheadEvening = set(addDays(nowUTC(), 5), {
  hours: 17,
  minutes: 0,
  seconds: 0
});
export const threeDaysAheadMorning = set(addDays(nowUTC(), 3), {
  hours: 11,
  minutes: 0,
  seconds: 0
});

export const date2020_05_16Date = new Date(2020, 5, 16, 13, 20);
export const date2020_05_18Date = new Date(2020, 5, 18, 13, 20);
export const date2020_05_20Date = new Date(2020, 5, 20, 13, 20);
export const date2020_05_22Date = new Date(2020, 5, 22, 13, 20);

export const date2020_05_16 = date2020_05_16Date;
export const date2020_04_16Date = new Date(2020, 4, 16);
export const date2020_04_16 = date2020_04_16Date;
export const date2020_06_16 = date2020_06_16Date.toISOString();
export const date2020_04_14Date = new Date(2020, 4, 14);
export const date2020_04_14 = new Date(Date.UTC(2020, 4, 14));
export const today = nowUTC();
export const yesterday = subDays(today, 1);
