import React from 'react';
import Table, { PaginationTypes } from '../../components/Table/Table';
import { useLoginAuditColumns } from './useLoginAuditColumns';
import { useLoginAudit } from './useLoginAudit';
import { useTheme } from '@mui/material';
import { PageTitle } from '@efacity/react-next-sc';

const LoginAuditContainer: React.FC = () => {
  const theme = useTheme();
  const { loginAuditState, getLoginAudits } = useLoginAudit();
  const columns = useLoginAuditColumns();

  return (
    <div>
      <PageTitle title="Login Audit" />
      <div style={{ fontSize: 20, marginBottom: 15, color: theme.palette.primary.main }}>
        Active users in last 30 days: {loginAuditState.activeUsers}
      </div>
      <Table
        columns={columns}
        fetchData={getLoginAudits}
        data={loginAuditState.loginAudits}
        pageCount={loginAuditState.total}
        loading={loginAuditState.isLoading}
        paginationType={PaginationTypes.ServerSide}
        noDataText="No login audit data..."
      />
    </div>
  );
};

export default LoginAuditContainer;
