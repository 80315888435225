import {
  AdditionalSessionOption,
  convertUtcToZonedTime,
  CurrencyCode,
  DurationType,
  formatSessionWeekDays,
  getEndTime,
  moneyWithCurrencyCodeFormatter,
  ShoppingCartItemSession,
  ShoppingCartItemStudent,
  ShoppingCartRegistration,
  formatStartToEndDateInTimeZone,
  formatTimeToHhMmNonBreakableString
} from '@efacity/common';
import { Button, TableCell, TableRow } from '@mui/material';
import CalendarIcon from '@mui/icons-material/Event';
import React, { FC } from 'react';
import { cellSx, strippedTableRowSx, TableCellWithIcon } from '@efacity/frontend-shared';
import { PATHS } from '@efacity/routing';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Link } from 'react-router-dom';
import DiscountsList from '../../../components/DiscountsList';
import DeleteIconButtonWithSpinner from '../../../components/DeleteIconButtonWithSpinner/DeleteIconButtonWithSpinner';

export interface ShoppingCartRegistrationTableProps {
  session: ShoppingCartItemSession;
  timeZone: string;
  student: ShoppingCartItemStudent | null;
  discounts: ShoppingCartRegistration['discounts'];
  additionalOptions: AdditionalSessionOption[] | null;
  handleDeleteShoppingCartItem: (studentId: string, sessionId: string) => void;
  handleDeleteAdditionalOption: (studentId: string, sessionId: string, additionalOptionId: string) => void;
  isUpdating: boolean;
  showScheduleRelatedColumns: boolean;
}

const ShoppingCartRegistrationAsTableRows: FC<ShoppingCartRegistrationTableProps> = ({
  session,
  timeZone,
  discounts = [],
  student,
  additionalOptions,
  handleDeleteShoppingCartItem,
  handleDeleteAdditionalOption,
  isUpdating,
  showScheduleRelatedColumns
}) => {
  return (
    <>
      <TableRow sx={strippedTableRowSx} data-testid="shopping-cart-item-row">
        <TableCell sx={cellSx} align="left">
          {session.name}
        </TableCell>
        <TableCell sx={cellSx} align="left">
          {student ? (
            student.name
          ) : (
            <Button
              component={Link}
              to={{ pathname: PATHS.selectStudentsForSessions }}
              state={{ sessionId: session._id }}
              color="primary"
              variant="contained"
            >
              Select Student
            </Button>
          )}
        </TableCell>
        <ScheduleCells showScheduleRelatedColumns={showScheduleRelatedColumns} session={session} timeZone={timeZone} />
        <TableCell sx={{ ...cellSx, whiteSpace: 'nowrap', width: '120px' }} align="right" scope="row">
          {moneyWithCurrencyCodeFormatter(session.price, session.currencyCode as CurrencyCode)}
        </TableCell>
        <TableCell sx={{ ...cellSx, width: '80px' }} align="center" component="th" scope="row">
          <DeleteIconButtonWithSpinner
            onClick={() => handleDeleteShoppingCartItem(student?._id ? student._id : null, session._id)}
            isUpdating={isUpdating}
          />
        </TableCell>
      </TableRow>
      {discounts?.length > 0 && (
        <DiscountsList discounts={discounts} currency={session.currencyCode as CurrencyCode} colSpan={6} />
      )}
      {additionalOptions &&
        additionalOptions.length > 0 &&
        additionalOptions.map((additionalOption: AdditionalSessionOption, index: number) => (
          <TableRow
            sx={strippedTableRowSx}
            key={`additionalOptionKey${index}session${session._id}AdditionalOption${additionalOption._id}`}
          >
            <TableCell sx={{ ...cellSx, paddingLeft: '60px' }} align="left" colSpan={6}>
              {additionalOption.name}
            </TableCell>
            <TableCell sx={{ ...cellSx, whiteSpace: 'nowrap', width: '120px' }} align="right" scope="row">
              {moneyWithCurrencyCodeFormatter(additionalOption.price, additionalOption.currencyCode)}
            </TableCell>
            <TableCell sx={{ ...cellSx, width: '80px' }} align="center" style={{}} component="th" scope="row">
              <DeleteIconButtonWithSpinner
                onClick={() => handleDeleteAdditionalOption(student._id, session._id, additionalOption._id)}
                isUpdating={isUpdating}
                aria-label="delete-additional-option"
              />
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};

const ScheduleCells = ({ session, showScheduleRelatedColumns, timeZone }) => {
  if (session.durationType === DurationType.selfPaced)
    return (
      <>
        {showScheduleRelatedColumns && <TableCell colSpan={3} />}
        <TableCell sx={cellSx}>self-paced</TableCell>
      </>
    );
  return (
    <>
      <TableCell sx={{ ...cellSx, whiteSpace: 'nowrap', width: '200px' }} align="left" scope="row">
        {session.startDate && session.endDate ? (
          <TableCellWithIcon icon={<CalendarIcon />}>
            {formatStartToEndDateInTimeZone(session.startDate, session.endDate, timeZone)}
          </TableCellWithIcon>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell sx={{ ...cellSx, width: '137px' }} align="left" component="th" scope="row">
        {session.weekDays?.length ? formatSessionWeekDays(session.weekDays) : '-'}
      </TableCell>
      <TableCell sx={{ ...cellSx, whiteSpace: 'nowrap', width: '137px' }} align="left" scope="row">
        {session.startDate ? (
          <TableCellWithIcon icon={<ScheduleIcon />}>
            {`${formatTimeToHhMmNonBreakableString(
              convertUtcToZonedTime(new Date(session.startDate), timeZone)
            )} - ${getEndTime(session.instanceDuration, convertUtcToZonedTime(new Date(session.startDate), timeZone))}`}
          </TableCellWithIcon>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell sx={{ ...cellSx, width: '98px' }} align="left" component="th" scope="row">
        {session.duration || '-'}
      </TableCell>
    </>
  );
};

export default ShoppingCartRegistrationAsTableRows;
