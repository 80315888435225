import {
  LinearProgress,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  TableContainer
} from '@mui/material';
import React from 'react';
import { tableContainerSx, tableHeadCellSx, tableHeadSx } from '@efacity/frontend-shared';
import SessionInfoRow from './SessionInfoRow';
import { Session } from '@efacity/react-next-sc';

interface SessionsListTableProps {
  sessions: Session[];
  studentId: string;
  isLoading: boolean;
  onSessionSelected;
  isRegistering: boolean;
}

const SessionsListTable: React.FC<SessionsListTableProps> = ({
  sessions,
  studentId,
  isLoading,
  onSessionSelected,
  isRegistering
}) => {
  return sessions?.length > 0 ? (
    <TableContainer sx={tableContainerSx}>
      <Table size="small" aria-label="a simple dense table">
        <TableHead sx={tableHeadSx}>
          <TableRow>
            <TableCell sx={tableHeadCellSx} style={{ minWidth: 120 }}>
              Name
            </TableCell>
            <TableCell sx={tableHeadCellSx} align="left">
              Grade/ Age
            </TableCell>
            <TableCell sx={tableHeadCellSx} align="left" style={{ minWidth: 120 }}>
              Date
            </TableCell>
            <TableCell sx={tableHeadCellSx} align="left">
              Duration
            </TableCell>
            <TableCell sx={tableHeadCellSx} align="left">
              Price
            </TableCell>
            <TableCell sx={tableHeadCellSx} align="left">
              Capacity
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell align="center" colSpan={3}>
                <LinearProgress variant="indeterminate" color="primary" data-testid="loadingIndicator" />
              </TableCell>
            </TableRow>
          ) : (
            sessions.map((session) => (
              <SessionInfoRow
                session={session}
                studentId={studentId}
                onSessionSelected={onSessionSelected}
                key={`sessionsToRegistrationListKey${session._id}`}
                isRegistering={isRegistering}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Typography>No Sessions for registration</Typography>
  );
};

export default SessionsListTable;
