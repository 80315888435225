import { CustomerWithCredits, moneyWithCurrencyCodeFormatter } from '@efacity/common';
import { PageTitle, useAuth } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, IconButton, Link } from '@mui/material';
import React, { useMemo, useState } from 'react';
import Table, { EnhancedColumn, PaginationTypes } from '../../components/Table/Table';
import CreditsToRefundTable from '../OrganizationCredits/CreditsToRefundTable';
import { useUserCredits } from './useUserCredits';

const CustomerCreditsContainer: React.FC = () => {
  const {
    authState: {
      user: { _id }
    }
  } = useAuth();
  const userCreditsState = useUserCredits(_id);

  const tableColumns = useMemo(() => {
    const childrenTableColumns: EnhancedColumn<CustomerWithCredits>[] = [
      {
        Header: 'Organization',
        disableFilters: true,
        columnStyles: {
          verticalAlign: 'top',
          paddingTop: 10
        },
        Cell: ({ row }) => {
          return (
            <Link href={toPath(PATHS.orgLanding, { orgId: row.original.organization._id })} underline={'none'}>
              {row.original.organization.name}
            </Link>
          );
        }
      },
      {
        Header: 'Credit',
        disableFilters: true,
        columnStyles: {
          textAlign: 'right'
        },
        headerStyles: {
          textAlign: 'right',
          paddingRight: 75
        },
        Cell: ({ row }) => {
          const [open, setOpen] = useState(false);
          return (
            <>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <div style={{ paddingTop: 5, paddingRight: row?.original?.creditFromPayments?.length > 0 ? 10 : 40 }}>
                  {moneyWithCurrencyCodeFormatter(row.original.credit.amount, row.original.credit.currencyCode)}
                </div>
                <div>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                    data-testid="collapseButton"
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </div>
              </div>
              <Collapse in={open} style={{ display: 'block' }}>
                <CreditsToRefundTable
                  orgId={row.original.organization._id}
                  creditId={row.original?.credit?._id}
                  creditFromPayments={row?.original?.credit?.creditFromPayments || []}
                  currencyCode={row.original.credit?.currencyCode}
                  timeZone={row.original.organization.timeZone}
                  allowRefund={false}
                />
              </Collapse>
            </>
          );
        }
      }
    ];

    return childrenTableColumns;
  }, []);

  return (
    <div>
      <PageTitle title="Credits" addButtonLink={undefined} underlined />
      <Table
        data={userCreditsState.data}
        columns={tableColumns}
        loading={userCreditsState.isLoading}
        paginationType={PaginationTypes.ClientSide}
      />
    </div>
  );
};

export default CustomerCreditsContainer;
