import { EnrollmentApplicationFrontend } from '@efacity/frontend-shared';
import { ApplicationStatus } from '@efacity/common';
import { Alert } from '@mui/material';

export const RequestChangesMsg = ({
  enrollmentApplication
}: {
  enrollmentApplication: Pick<EnrollmentApplicationFrontend, 'applicationStatus' | 'comment'>;
}) => {
  if (enrollmentApplication?.applicationStatus === ApplicationStatus.ChangesRequested) {
    return (
      <Alert severity="info" sx={{ width: '100%', my: 1 }}>
        Additional info requested: {enrollmentApplication?.comment}
      </Alert>
    );
  }
  return null;
};
