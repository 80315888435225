import {
  defaultLogoHeight,
  defaultLogoWidth,
  defaultMobileLogoHeight,
  defaultMobileLogoWidth,
  displayMdUpSx,
  displaySmDownSx
} from '@efacity/common';
import { Box } from '@mui/material';
import Link from 'next/link';

const strongLogoSizeStyle = {
  maxHeight: defaultLogoHeight,
  minHeight: defaultLogoHeight,
  maxWidth: defaultLogoWidth,
  minWidth: defaultLogoWidth
};
const strongLogoMobileSizeStyle = {
  maxHeight: defaultMobileLogoHeight,
  minHeight: defaultMobileLogoHeight,
  maxWidth: defaultMobileLogoWidth,
  minWidth: defaultMobileLogoWidth
};

const efacityLogo = '/assets/images/EfacityLogoWhite_256_70.svg';
const efacityLogoDensed = '/assets/images/efacityLogoDensed_54x45.svg';

interface HeaderLogoProps {
  orgLogoDesktop?: string;
  orgLogoMobile?: string;
  orgName?: string;
  logoRedirectPath: string;
}

export default function HeaderLogo({ orgName, orgLogoDesktop, orgLogoMobile, logoRedirectPath }: HeaderLogoProps) {
  const altText = orgName ? `${orgName} Logo` : 'Efacity Logo';
  return (
    <>
      <Box sx={{ ...displayMdUpSx, ...strongLogoSizeStyle, overflow: 'hidden' }}>
        <Link href={logoRedirectPath} style={{ display: 'block' }}>
          <img
            src={orgLogoDesktop || efacityLogo}
            alt={altText}
            data-testid="main-layout-header-next-logo"
            style={{ ...strongLogoSizeStyle, margin: 0, padding: 0 }}
          />
        </Link>
      </Box>
      <Box sx={{ ...displaySmDownSx, ...strongLogoMobileSizeStyle, overflow: 'hidden' }}>
        <Link href={logoRedirectPath} style={{ display: 'block' }}>
          <img
            src={orgLogoMobile || efacityLogoDensed}
            alt={altText}
            data-testid="main-layout-header-next-logo"
            style={{ ...strongLogoMobileSizeStyle, margin: 0, padding: 0 }}
          />
        </Link>
      </Box>
    </>
  );
}
