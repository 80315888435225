import React, { ReactElement } from 'react';
import { Box, Theme } from '@mui/material';
import MainLayoutHeader from '../MainLayout/MainLayoutHeader';
import { efacityOrgContacts, Footer } from '@efacity/react-next-sc';
import { efacityLogoFullUrl, efacityMobileLogoFullUrl } from '../../hooks/useOrganizationRegionsOptions';
import { contentSx } from '../MainLayout/MainLayout';

const mainContentSx = (theme: Theme) => ({
  ...contentSx(theme),
  marginLeft: '0px',
  minHeight: `calc(100vh - 120px)`,
  display: 'flex',
  alignContent: 'space-between',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '30px'
  }
});

interface LandingWrapperProps {
  children: any;
  footer?: ReactElement;
  logoFullUrl?: string;
  mobileLogoFullUrl?: string;
}

export const LandingWrapper: React.FC<LandingWrapperProps> = ({
  children,
  footer = <Footer orgInfo={efacityOrgContacts} />,
  logoFullUrl = efacityLogoFullUrl,
  mobileLogoFullUrl = efacityMobileLogoFullUrl
}) => {
  return (
    <div data-testid="home-landing-page">
      <MainLayoutHeader handleDrawerToggle={null} logoFullUrl={logoFullUrl} mobileLogoFullUrl={mobileLogoFullUrl} />
      <Box sx={mainContentSx}>
        {children}
        {footer}
      </Box>
    </div>
  );
};
