import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/Check';
import {
  apiService,
  ConnectedAccount,
  HorizontalField,
  organizationTransfersFormValuesValidationSchema
} from '@efacity/frontend-shared';
import { Messages, OrganizationTransfer, OrganizationTransferFormValue } from '@efacity/common';
import { useSnackbar } from 'notistack';
import { ModalDialogTitle } from '@efacity/react-next-sc';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { defaultRHFSetValueOptions, FormTextInput } from '@efacity/react-hook-form-mui';
import { yupResolver } from '@hookform/resolvers/yup';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

const connectModalSx = {
  cancelButton: {
    width: 150,
    height: 32,
    textTransform: 'uppercase',
    fontSize: 14
  },
  startButton: {
    width: 150,
    height: 32,
    textTransform: 'uppercase',
    fontSize: 14
  },
  table: {
    tableLayout: 'auto'
  },
  tableHead: {
    background: '#F5F6FA'
  },
  tableContainer: {
    tableLayout: 'auto',
    borderRadius: 1
  }
};

export interface OrganizationTransfersModalModalProps {
  open: boolean;
  orgId: string;
  schoolName: string;
  connectedAccounts: ConnectedAccount[];
  organizationTransfers: OrganizationTransfer[];
  onClose: (reloadData: boolean) => void;
}

export const DEFAULT_ORGANIZATION_TRANSFER_PERCENTAGE = 10;

const OrganizationTransfersModal: React.FC<OrganizationTransfersModalModalProps> = ({
  open,
  orgId,
  schoolName,
  connectedAccounts,
  organizationTransfers,
  onClose
}) => {
  const snackbarProps = useSnackbar();
  const [isOperating, setIsOperating] = useState(false);

  const initialOrganizationTransfersValues: OrganizationTransferFormValue[] = connectedAccounts.map((account) => {
    const existedOrganizationTransfer = organizationTransfers.find(
      (transfer) => transfer.stripeUserId === account.stripeUserId
    );
    return {
      _id: existedOrganizationTransfer ? existedOrganizationTransfer._id : '',
      stripeUserId: existedOrganizationTransfer ? existedOrganizationTransfer.stripeUserId : account.stripeUserId,
      transferPercentage: existedOrganizationTransfer
        ? existedOrganizationTransfer.transferPercentage
        : DEFAULT_ORGANIZATION_TRANSFER_PERCENTAGE,
      name: existedOrganizationTransfer ? existedOrganizationTransfer.name : `From ${schoolName} to ...`,
      toAccountName: account.name,
      isSelected: !!existedOrganizationTransfer
    };
  });

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: { transfers: initialOrganizationTransfersValues },
    resolver: yupResolver(organizationTransfersFormValuesValidationSchema)
  });
  const { isDirty, isSubmitting } = methods.formState;

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'transfers'
  });

  const handleSubmit = async (formValues: { transfers: OrganizationTransferFormValue[] }) => {
    const selectedOrgTransfers = formValues.transfers.filter((transfer) => transfer.isSelected === true);
    setIsOperating(true);

    apiService.patch(`/paymentPlatforms/org/${orgId}/transfers/update`, selectedOrgTransfers).then(
      (result: any) => {
        showNotification(
          snackbarProps,
          true,
          (result.data.message as string) || Messages.OrganizationTransfersUpdated,
          false
        );
        setIsOperating(false);
        onClose(true);
      },
      (error) => {
        setIsOperating(false);
        showNotification(
          snackbarProps,
          false,
          (error.response.data.message as string) || Messages.FailedUpdateOrganizationTransfer,
          true
        );
      }
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <ModalDialogTitle text={`Manage Organization Transfer for ${schoolName}`} onClose={() => onClose(false)} />
      <DialogContent style={{ width: '100%' }}>
        <FormProvider {...methods}>
          <form noValidate onSubmit={methods.handleSubmit(handleSubmit)}>
            {connectedAccounts?.length > 0 ? (
              <TableContainer component={Card} sx={connectModalSx.tableContainer}>
                <Table sx={connectModalSx.table} size="small">
                  <TableHead style={connectModalSx.tableHead}>
                    <TableRow>
                      <TableCell>Destination Account Name</TableCell>
                      <TableCell>Destination Account Signature</TableCell>
                      <TableCell>Transfer Name *</TableCell>
                      <TableCell>Percentage *</TableCell>
                      <TableCell>Is Selected</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.map((organizationTransfer, index) => (
                      <TableRow key={organizationTransfer.id}>
                        <TableCell>{methods.getValues(`transfers`)[index].toAccountName}</TableCell>
                        <TableCell>{methods.getValues(`transfers`)[index].stripeUserId}</TableCell>
                        <TableCell style={{ minWidth: 300 }}>
                          <FormTextInput
                            name={`transfers.${index}.name`}
                            placeholder={'Transfer name'}
                            disabled={isOperating}
                          />
                        </TableCell>
                        <TableCell>
                          <FormTextInput
                            name={`transfers.${index}.transferPercentage`}
                            placeholder={'Transfer percentage'}
                            type="number"
                            InputProps={{ inputProps: { min: 1, max: 100 } }}
                            disabled={isOperating}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            name={`transfers.${index}.isSelected`}
                            checked={methods.watch('transfers')[index].isSelected === true}
                            color="primary"
                            onChange={(event) => {
                              methods.setValue(
                                `transfers.${index}.isSelected`,
                                event.target.checked,
                                defaultRHFSetValueOptions
                              );
                            }}
                            disabled={isOperating}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                No connected accounts in this Organization
              </div>
            )}
            <HorizontalField
              justifyContent="center"
              wrapOnSmallScreen={false}
              style={{ marginTop: 15, padding: 9, width: '100%' }}
            >
              <Button
                sx={connectModalSx.cancelButton}
                onClick={() => onClose(false)}
                color="primary"
                variant="outlined"
                disabled={isOperating}
                aria-label="cancel"
              >
                <CancelOutlined style={{ color: 'primary', marginRight: 10 }} />
                Cancel
              </Button>
              <Button
                sx={connectModalSx.startButton}
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting || !isDirty || connectedAccounts.length === 0 || isOperating}
                data-testid="startConnectButton"
                aria-label="save"
              >
                {isOperating ? (
                  <CircularProgress
                    variant="indeterminate"
                    size={20}
                    color={'primary'}
                    data-testid="progress-in-button"
                  />
                ) : (
                  <>
                    <SaveIcon style={{ marginRight: 10 }} />
                    Save
                  </>
                )}
              </Button>
            </HorizontalField>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default OrganizationTransfersModal;
