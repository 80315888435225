import * as Yup from 'yup';
import * as yup from 'yup';
import {
  ActivityTypes,
  AgeStudentLevelTypeValue,
  isGradeNumericValue,
  Messages,
  nonNegativeAmountValidator,
  richEditContainsText,
  SchoolGradeStudentLevelTypeValue,
  ValidationErrors
} from '@efacity/common';
import { isValidStudentLevelType } from '../../SessionsContainer/SessionMainInfoForm/sessionMainInfoValidator';
import { apiService } from '@efacity/frontend-shared';

const activityNameValidationAPI = async (name: string, orgId: string, activityId?: string) => {
  return new Promise((resolves, rejects) => {
    try {
      resolves(apiService.get('/activities/check-name', { name: name, orgId: orgId, activityId: activityId }));
    } catch (e) {
      rejects(e);
    }
  });
};

export const activityFormValidationSchema = (orgId: string, activityId: string) => {
  return Yup.object().shape({
    name: yup
      .string()
      .required(Messages.ActivityNameRequired)
      .test('activity_name_async_validation', Messages.ActivityNameNotUnique, function (value) {
        if (document.activeElement.getAttribute('name') === 'name') {
          return activityNameValidationAPI(value, orgId, activityId)
            .then((res) => {
              return true;
            })
            .catch((e) => {
              return false;
            });
        }
        return true;
      }),
    shortDescription: yup
      .string()
      .test('shortDescription-test', null, function (this: yup.TestContext, shortDescription: string): any {
        if (this.parent?.type !== ActivityTypes.Enrichment) {
          if (shortDescription?.length > 0) {
            return true;
          }
          return this.createError({
            path: 'shortDescription',
            message: Messages.DescriptionRequired
          });
        }
        return true;
      }),
    longDescription: Yup.array().test(
      'longDescription-test',
      null,
      function (this: yup.TestContext, longDescription): any {
        if (richEditContainsText(longDescription)) {
          return true;
        }
        return this.createError({
          path: 'longDescription',
          message: Messages.DescriptionRequired
        });
      }
    ),
    descriptionOnSessionSelection: yup
      .array()
      .test(
        'descriptionOnSessionSelection-test',
        null,
        function (this: yup.TestContext, descriptionOnSessionSelection): any {
          if (this.parent?.activityType === ActivityTypes.Enrichment) {
            if (richEditContainsText(descriptionOnSessionSelection)) {
              return true;
            }
            return this.createError({
              path: 'descriptionOnSessionSelection',
              message: Messages.DescriptionRequired
            });
          }
          return true;
        }
      ),
    teacherInstructions: Yup.string(),
    numberOfInstances: Yup.number()
      .integer('Must be an integer number')
      .typeError('Number of Instances must be a valid number')
      .required('Number of Instances is required'),
    studentLevel: yup.object().shape({
      type: yup.string().test('type', ValidationErrors.InvalidStudentLevelType, isValidStudentLevelType),
      fromSchoolGradeNumeric: yup
        .mixed()
        .test('fromSchoolGradeNumeric', null, function (this: yup.TestContext, fromSchoolGradeNumeric: any): any {
          if (this.parent?.type && this.parent.type === SchoolGradeStudentLevelTypeValue) {
            if (isGradeNumericValue(fromSchoolGradeNumeric)) {
              return true;
            }
            return this.createError({
              path: 'studentLevel.fromSchoolGradeNumeric',
              message: ValidationErrors.InvalidGrade
            });
          }
          return true;
        }),
      toSchoolGradeNumeric: yup
        .mixed()
        .test('toSchoolGradeNumeric', null, function (this: yup.TestContext, toSchoolGradeNumeric: any): any {
          if (this.parent?.type && this.parent.type === SchoolGradeStudentLevelTypeValue) {
            if (isGradeNumericValue(toSchoolGradeNumeric)) {
              return true;
            }
            return this.createError({
              path: 'studentLevel.toSchoolGradeNumeric',
              message: ValidationErrors.InvalidGrade
            });
          }
          return true;
        }),
      fromAge: yup.mixed().test('fromAge', null, function (this: yup.TestContext, fromAge: any): any {
        if (this.parent?.type && this.parent.type === AgeStudentLevelTypeValue) {
          if (fromAge > 0) {
            return true;
          }
          return this.createError({
            path: 'studentLevel.fromAge',
            message: ValidationErrors.InvalidAge
          });
        }
        return true;
      }),
      toAge: yup.mixed().test('toAge', null, function (this: yup.TestContext, toAge: any): any {
        if (this.parent?.type && this.parent.type === AgeStudentLevelTypeValue) {
          if (toAge > 0) {
            return true;
          }
          return this.createError({
            path: 'studentLevel.toAge',
            message: ValidationErrors.InvalidAge
          });
        }
        return true;
      })
    }),
    defaultSessionPrice: Yup.string().test('test-name', ValidationErrors.InvalidPriceRange, nonNegativeAmountValidator),
    defaultSessionCurrency: Yup.string().required('Currency is required'),
    fee: Yup.number().min(0).max(100).required('Fee is required'),
    sharedWith: Yup.array()
  });
};
