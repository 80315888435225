import { DisplayOption } from './DisplayOption';
import { OwnerInterface } from './OwnerInterface';
import { CurrencyCode } from './Currencies';

export interface DiscountInterface {
  _id: string;
  code?: string;
  createdOn: Date | string;
  expiresOn: Date | string;
  description?: string;
  descriptionForCustomer: string;
  typeDefinition: DiscountTypeDefinitionInterface;
  currencyCode?: CurrencyCode;
  owner: OwnerInterface;
  classDefinition: DiscountClassDefinitionInterface;
  calculatedAmount: number;
}

export enum DiscountClass {
  Coupon = 'coupon',
  Sibling = 'sibling',
  FullDay = 'fullDay',
  EarlyBird = 'earlyBird',
  Consecutive = 'consecutive'
}

export class DiscountClassOptions {
  static readonly COUPON = new DisplayOption(DiscountClass.Coupon, 'Coupon');
  static readonly SIBLING = new DisplayOption(DiscountClass.Sibling, 'Sibling');
  static readonly FULLDAY = new DisplayOption(DiscountClass.FullDay, 'FullDay');
  static readonly EARLYBIRD = new DisplayOption(DiscountClass.EarlyBird, 'EarlyBird');
  static readonly Consecutive = new DisplayOption(DiscountClass.Consecutive, 'Consecutive');
}

export const SupportedDiscountClassOptions = [
  DiscountClassOptions.COUPON,
  DiscountClassOptions.SIBLING,
  DiscountClassOptions.FULLDAY
];

export interface DiscountClassDefinitionInterface {
  discountClass: DiscountClass;
  consecutiveSessionsNumber?: number;
  fullDayOnly?: boolean;
  marginOfDays?: number;
}

export const isDiscountClassOption = (value) => {
  return !!Object.values(DiscountClassOptions).find(
    (discountClassDisplayOption: DisplayOption) => discountClassDisplayOption.value === value
  );
};

export const getDiscountClassOptionByValue = (value) => {
  return Object.values(DiscountClassOptions).find(
    (discountClassDisplayOption: DisplayOption) => discountClassDisplayOption.value === value
  );
};

export const getDiscountClassDisplayTextByValue = (value) => {
  const displayClass = Object.values(DiscountClassOptions).find(
    (discountClassDisplayOption: DisplayOption) => discountClassDisplayOption.value === value
  );
  return displayClass ? displayClass.displayText : '-';
};

export enum DiscountType {
  Amount = 'amount',
  Percentage = 'percentage'
}

export const amountDiscountTypeOption =new DisplayOption(DiscountType.Amount, 'Amount')
export const percentageDiscountTypeOption =new DisplayOption(DiscountType.Percentage, 'Percentage')

export const DiscountTypeOptions = [amountDiscountTypeOption,percentageDiscountTypeOption]

export const isDiscountTypeOption = (value) => {
  return !!Object.values(DiscountTypeOptions).find(
    (discountTypeDisplayOption: DisplayOption) => discountTypeDisplayOption.value === value
  );
};

export interface DiscountTypeDefinitionInterface {
  discountType: DiscountType;
  discountPercentage?: number;
  discountAmount?: number;
}
