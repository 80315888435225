import { CurrencyCode, moneyWithCurrencyCodeFormatter, ShoppingCartProduct } from '@efacity/common';
import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import mobileTableStyle from '../../../stylesheets/mobileTableStyle';
import DeleteIconButtonWithSpinner from '../../../components/DeleteIconButtonWithSpinner/DeleteIconButtonWithSpinner';

export interface ShoppingCartProductTableProps {
  shoppingCartProduct: ShoppingCartProduct;
  timeZone: string;
  handleDeleteShoppingCartProduct: (productId: string) => void;
  isUpdating: boolean;
}

const ShoppingCartProductMobileTableRows: FC<ShoppingCartProductTableProps> = ({
  shoppingCartProduct,
  timeZone,
  handleDeleteShoppingCartProduct,
  isUpdating
}) => {
  return (
    <Box
      sx={mobileTableStyle.rootSx}
      data-testid={`ShoppingCartProductMobileTableRows_${shoppingCartProduct.product.name}`}
    >
      <Box style={{ ...mobileTableStyle.sessionNameRow, borderBottom: '1px solid #C4C4C4' }}>
        <Box>
          <Typography sx={mobileTableStyle.titleSx}>Kit</Typography>
          <Box fontWeight="bold" sx={mobileTableStyle.titleSx}>
            {shoppingCartProduct.product.name}
          </Box>
        </Box>
        <Box style={mobileTableStyle.deleteIcon}>
          <DeleteIconButtonWithSpinner
            onClick={() => handleDeleteShoppingCartProduct(shoppingCartProduct.product._id.toString())}
            isUpdating={isUpdating}
          />
        </Box>
      </Box>
      <Box style={mobileTableStyle.mobileTwoColumnRow}>
        <Box>
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Description:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>{shoppingCartProduct.product.name}</Typography>
        </Box>
        <Box>
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Price:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>
            {moneyWithCurrencyCodeFormatter(
              shoppingCartProduct.product.price,
              shoppingCartProduct.product.currencyCode as CurrencyCode
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ShoppingCartProductMobileTableRows;
