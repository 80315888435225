import React from 'react';
import { viewItemParagraphStyle } from './viewItemParagraphStyle';
import Box from '@mui/material/Box';
/***
 @param title to display before children
 @param children
 @param isParagraph starts children on a new line and styles with reading-friendly width
 @param isOptional do not display the title if content is empty
 */
export const ViewItem = ({
  title,
  children,
  isParagraph = false,
  isOptional = false
}: {
  title: string;
  children: React.ReactNode;
  isParagraph?: boolean;
  isOptional?: boolean;
}) => {
  if (isOptional && !children) return null;

  const displayChildren = isParagraph ? <p style={viewItemParagraphStyle}>{children}</p> : children;
  const displayTitle = title.match(/.*[.?!:;]$/) ? title : title + ':';

  const isError = !children;
  return (
    <Box style={{ color: '#333', padding: '15px 10px', ...(isError && { backgroundColor: '#ffcccc' }) }}>
      <span style={{ color: '#34495E', fontSize: '14px', marginRight: '24px' }}>{displayTitle}</span>
      {displayChildren}
    </Box>
  );
};
