import { apiService, EnrollmentApplicationFrontend } from '@efacity/frontend-shared';
import { useCallback, useState } from 'react';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { useSnackbar } from 'notistack';
import { Messages } from '@efacity/common';
import { FetchDataOptions } from '../../components/Table/Table';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';

export type EnrollmentsResponse = {
  data: EnrollmentApplicationFrontend[];
  timeZone: string;
  total?: number;
};

export interface EnrollmentsState {
  enrollments: EnrollmentApplicationFrontend[];
  enrollmentApplication: EnrollmentApplicationFrontend | null;
  total: number;
  timeZone: string;
  isLoading: boolean;
  isUpdating: boolean;
}
export const defaultEnrollmentsState: EnrollmentsState = {
  enrollments: [],
  enrollmentApplication: null,
  total: 0,
  timeZone: 'America/New_York',
  isLoading: false,
  isUpdating: false
};

export const useEnrollmentsLoader = (orgId: string, loadEnrollmentsList = true) => {
  const [enrollmentsState, setEnrollmentsState] = useState<EnrollmentsState>(defaultEnrollmentsState);
  const snackbarProps = useSnackbar();

  const getEnrollmentsList = async ({ pageIndex, pageSize, filters, sortBy }: FetchDataOptions) => {
    try {
      const { data } = await apiService.get<EnrollmentsResponse>(`/org/${orgId}/enrollments`, {
        page: pageIndex,
        perPage: pageSize,
        ...getFiltersQueryParameters(filters),
        sortBy: mapReactTableSortToApiSort(sortBy)
      });

      setEnrollmentsState((currentState) => ({
        ...currentState,
        enrollments: data.data,
        timeZone: data.timeZone,
        total: data.total,
        isLoading: false
      }));
    } catch (error) {
      setEnrollmentsState((currentState) => ({
        ...currentState,
        isLoading: false
      }));
      showNotification(
        snackbarProps,
        false,
        (error.response?.data?.message as string) || Messages.FailedGetEnrollmentApplications,
        true
      );
    }
  };

  const getEnrollments = useCallback(
    async (fetchDataOptions: FetchDataOptions) => {
      if (loadEnrollmentsList) await getEnrollmentsList(fetchDataOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [snackbarProps, orgId]
  );

  const getEnrollmentApplication = async (enrollmentId: string) => {
    setEnrollmentsState((currentState) => ({
      ...currentState,
      isLoading: true
    }));
    try {
      const { data } = await apiService.get<{ enrollmentApplication: EnrollmentApplicationFrontend; timeZone: string }>(
        `/enrollments/${orgId}/application/${enrollmentId}`
      );
      setEnrollmentsState((currentState) => ({
        ...currentState,
        enrollmentApplication: data.enrollmentApplication,
        timeZone: data.timeZone,
        isLoading: false
      }));
    } catch (error) {
      setEnrollmentsState((currentState) => ({
        ...currentState,
        enrollmentApplication: null,
        isLoading: false
      }));
      showNotification(
        snackbarProps,
        false,
        (error.response?.data?.message as string) || Messages.FailedGetEnrollmentApplication,
        true
      );
    }
  };

  const approveEnrollmentApplication = async (enrollmentId: string) => {
    setEnrollmentsState((currentState) => ({
      ...currentState,
      isUpdating: true
    }));
    try {
      await apiService.patch(`/enrollments/${orgId}/application/${enrollmentId}/approve`, {});
    } catch (error) {
      showNotification(
        snackbarProps,
        false,
        (error.response?.data?.message as string) || Messages.FailedApproveEnrollment,
        true
      );
    } finally {
      setEnrollmentsState((currentState) => ({
        ...currentState,
        isUpdating: false
      }));
    }
  };

  const deleteEnrollmentApplication = async (enrollmentId: string) => {
    setEnrollmentsState((currentState) => ({
      ...currentState,
      isUpdating: true
    }));
    try {
      await apiService.delete(`/enrollments/${orgId}/enrollment/${enrollmentId}`);
    } catch (error) {
      showNotification(
        snackbarProps,
        false,
        (error.response?.data?.message as string) || Messages.FailedDeleteEnrollment,
        true
      );
    } finally {
      setEnrollmentsState((currentState) => ({
        ...currentState,
        isUpdating: false
      }));
    }
  };

  const requestChangesToEnrollmentApplication = async (enrollmentId: string, comment: string) => {
    setEnrollmentsState((currentState) => ({
      ...currentState,
      isUpdating: true
    }));
    try {
      const formData = new FormData();
      formData.append('comment', comment);
      await apiService.patch(`/enrollments/${orgId}/application/${enrollmentId}/request-changes`, formData);
    } catch (error) {
      showNotification(
        snackbarProps,
        false,
        (error.response?.data?.message as string) || Messages.FailedRequestChangesToEnrollment,
        true
      );
    } finally {
      setEnrollmentsState((currentState) => ({
        ...currentState,
        isUpdating: false
      }));
    }
  };
  return {
    enrollmentsState,
    getEnrollments,
    getEnrollmentApplication,
    approveEnrollmentApplication,
    deleteEnrollmentApplication,
    requestChangesToEnrollmentApplication
  };
};
