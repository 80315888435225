import {
  CountryCode,
  Messages,
  PaymentAcceptanceMethod,
  PaymentCard,
  StudentOnSessionRegistrationInfo,
  UserSignedAgreement
} from '@efacity/common';
import { initialRegistrationState } from '../PaymentContainer/useInvoicePayment';
import { paymentDoneModalInitialState, PaymentDoneModalState, apiService } from '@efacity/frontend-shared';
import { useState } from 'react';
import { PaymentDetails } from '../PaymentContainer/StripePaymentFormContainer';
import { useAuth } from '@efacity/react-next-sc';

export interface ShoppingCartPaymentsState {
  credit: number | null;
  isLoading: boolean;
  isUpdating: boolean;
  stripePublishableKey: string | null;
  paymentAcceptanceMethod: PaymentAcceptanceMethod;
  paymentCards: PaymentCard[];
  defaultCountry: CountryCode | null;
  paymentDoneModal: PaymentDoneModalState;
  message?: string;
}

export const initialShoppingCartPaymentsState: ShoppingCartPaymentsState = {
  credit: null,
  isLoading: false,
  isUpdating: false,
  stripePublishableKey: null,
  paymentAcceptanceMethod: PaymentAcceptanceMethod.Efacity,
  defaultCountry: null,
  paymentCards: [],
  paymentDoneModal: paymentDoneModalInitialState
};

export const useShoppingCartPayment = () => {
  const [shoppingCartPaymentsState, setShoppingPaymentsState] = useState(initialShoppingCartPaymentsState);
  const [registrationState, setRegistrationState] = useState(initialRegistrationState);
  const { authState, setAuthState } = useAuth();

  const getShoppingCart = async () => {
    setShoppingPaymentsState({
      ...shoppingCartPaymentsState,
      isLoading: true
    });
    try {
      const { data } = await apiService.get<any>('/payments/shoppingCart');

      setAuthState({
        ...authState,
        extraAuthInfo: {
          ...authState.extraAuthInfo,
          shoppingCart: data.shoppingCart,
          startRegistrationURL: data.startRegistrationURL,
          message: ''
        }
      });
      setShoppingPaymentsState({
        ...shoppingCartPaymentsState,
        stripePublishableKey: data.stripePublishableKey,
        paymentAcceptanceMethod: data.paymentAcceptanceMethod,
        paymentCards: data.paymentCards,
        credit: data?.credit > 0 ? data.credit : 0,
        isLoading: false
      });
      return true;
    } catch (error) {
      const data = error.response.data;
      setAuthState({
        ...authState,
        extraAuthInfo: {
          ...authState.extraAuthInfo,
          shoppingCart: data.shoppingCart,
          startRegistrationURL: data.startRegistrationURL,
          message: ''
        }
      });
      setShoppingPaymentsState({
        ...shoppingCartPaymentsState,
        stripePublishableKey: data.stripePublishableKey,
        paymentAcceptanceMethod: data.paymentAcceptanceMethod,
        paymentCards: data.paymentCards,
        credit: data?.credit > 0 ? data.credit : 0,
        isLoading: false
      });
      return false;
    }
  };

  const verifyCouponDiscountForShoppingCart = async (code: string) => {
    setShoppingPaymentsState({
      ...shoppingCartPaymentsState,
      isUpdating: true
    });
    try {
      const { data } = await apiService.post<any>('/discounts/coupon/verify', { code });
      setAuthState({
        ...authState,
        extraAuthInfo: {
          ...authState.extraAuthInfo,
          shoppingCart: data.shoppingCart,
          message: ''
        }
      });
      setShoppingPaymentsState({
        ...shoppingCartPaymentsState,
        isUpdating: false
      });
      return true;
    } catch (error) {
      const data = error.response.data;
      setAuthState({
        ...authState,
        extraAuthInfo: {
          ...authState.extraAuthInfo,
          shoppingCart: data.shoppingCart,
          startRegistrationURL: data.startRegistrationURL,
          message: data?.message || ''
        }
      });
      setShoppingPaymentsState({
        ...shoppingCartPaymentsState,
        isUpdating: false
      });
      return false;
    }
  };

  const toggleAdditionalOptionSelection = async (
    studentId: string,
    sessionId: string,
    additionalOptionId: string,
    isSelected: boolean
  ) => {
    setRegistrationState((state) => ({ ...state, isRegistering: true }));
    try {
      const { data } = await apiService.post<any>(
        `/shopping-cart/options/${studentId}/${sessionId}/${additionalOptionId}/${isSelected}`,
        {}
      );
      setAuthState({
        ...authState,
        extraAuthInfo: {
          ...authState.extraAuthInfo,
          shoppingCart: data.shoppingCart,
          message: ''
        }
      });
      setRegistrationState((state) => ({ ...state, isRegistering: false }));
      return true;
    } catch (error) {
      const data = error.response.data;
      setAuthState({
        ...authState,
        extraAuthInfo: {
          ...authState.extraAuthInfo,
          shoppingCart: data.shoppingCart,
          startRegistrationURL: data.startRegistrationURL,
          message: data?.message || ''
        }
      });
      setRegistrationState((state) => ({ ...state, isRegistering: false }));
      return false;
    }
  };

  const removeSessionSelectionFromShoppingCart = async (selection: StudentOnSessionRegistrationInfo) => {
    setRegistrationState((state) => ({ ...state, isRegistering: true }));
    try {
      const { data } = await apiService.post<any>('/shopping-cart/removeSessionSelection', selection);
      setAuthState({
        ...authState,
        extraAuthInfo: {
          ...authState.extraAuthInfo,
          shoppingCart: data.shoppingCart,
          message: ''
        }
      });
      setRegistrationState((state) => ({ ...state, isRegistering: false }));
      return true;
    } catch (error) {
      const data = error.response.data;
      setAuthState({
        ...authState,
        extraAuthInfo: {
          ...authState.extraAuthInfo,
          shoppingCart: data.shoppingCart,
          startRegistrationURL: data.startRegistrationURL,
          message: data?.message || ''
        }
      });
      setRegistrationState((state) => ({ ...state, isRegistering: false }));
      return false;
    }
  };

  const removeStudentWithSessionSelectionFromShoppingCart = async (selection: StudentOnSessionRegistrationInfo) => {
    setRegistrationState((state) => ({ ...state, isRegistering: true }));
    try {
      const { data } = await apiService.post<any>('/shopping-cart/removeStudentWithSessionSelection', selection);
      setAuthState({
        ...authState,
        extraAuthInfo: {
          ...authState.extraAuthInfo,
          shoppingCart: data.shoppingCart,
          message: ''
        }
      });
      setRegistrationState((state) => ({ ...state, isRegistering: false }));
      return true;
    } catch (error) {
      const data = error.response.data;
      setAuthState({
        ...authState,
        extraAuthInfo: {
          ...authState.extraAuthInfo,
          shoppingCart: data.shoppingCart,
          startRegistrationURL: data.startRegistrationURL,
          message: data?.message || ''
        }
      });
      setRegistrationState((state) => ({ ...state, isRegistering: false }));
      return false;
    }
  };

  const processShoppingCartPayment = async (
    paymentInfo: PaymentDetails & { signedAgreements: UserSignedAgreement[] }
  ) => {
    try {
      setShoppingPaymentsState((state) => ({ ...state, isUpdating: true }));
      const { data } = await apiService.post<any>('/payments/shoppingCart', paymentInfo);
      const payment = { ...data };

      setShoppingPaymentsState((state) => ({ ...state, isUpdating: false }));
      return { ...payment, isError: false };
    } catch (error) {
      setShoppingPaymentsState((state) => ({ ...state, isUpdating: false }));
      return { ...error.response.data, isError: true };
    }
  };

  const querySessionsToRegisterOn = async () => {
    setRegistrationState((state) => ({ ...state, isLoadingSessions: true, existedRegistrationsForSession: [] }));
    try {
      const { data } = await apiService.get<any>(`/shopping-cart/sessions`);
      setRegistrationState((state) => ({
        ...state,
        isLoadingSessions: false,
        sessionIndex: 0,
        sessionsSelectedForRegistration: data.sessions,
        startRegistrationURL: data.startRegistrationURL,
        students: data.students,
        requireStudentAge: data.requireStudentAge || false
      }));
      return {
        isError: false,
        sessions: data.sessions,
        startRegistrationURL: data.startRegistrationURL,
        students: data.students,
        requireStudentAge: data.requireStudentAge || false
      };
    } catch (error) {
      setRegistrationState((state) => ({
        ...state,
        isLoadingSessions: false,
        sessionIndex: 0,
        sessionsSelectedForRegistration: [],
        startRegistrationURL: null
      }));
      return { isError: true, message: error?.response?.data?.message || Messages.FailedGetSessionsRegisterOn };
    }
  };

  const addStudentToSessionSelectionToShoppingCart = async (selection: StudentOnSessionRegistrationInfo) => {
    setRegistrationState((state) => ({ ...state, isRegistering: true }));
    try {
      const { data } = await apiService.post<any>('/shopping-cart/addSelection', selection);
      setAuthState({
        ...authState,
        extraAuthInfo: {
          ...authState.extraAuthInfo,
          shoppingCart: data.shoppingCart,
          message: ''
        }
      });
      setRegistrationState((state) => ({ ...state, isRegistering: false }));
      return { isError: false };
    } catch (error) {
      const data = error.response.data;
      setRegistrationState((state) => ({ ...state, isRegistering: false }));
      return { isError: true, message: data?.message || '' };
    }
  };

  const removeStudentFromSessionSelectionFromShoppingCart = async (selection: StudentOnSessionRegistrationInfo) => {
    setRegistrationState((state) => ({ ...state, isRegistering: true }));
    try {
      const { data } = await apiService.post<any>('/shopping-cart/removeStudentFromSessionSelection', selection);
      setAuthState({
        ...authState,
        extraAuthInfo: {
          ...authState.extraAuthInfo,
          shoppingCart: data.shoppingCart,
          message: ''
        }
      });
      setRegistrationState((state) => ({ ...state, isRegistering: false }));
      return { isError: false };
    } catch (error) {
      const data = error.response.data;
      setRegistrationState((state) => ({ ...state, isRegistering: false }));
      return { isError: true, message: data?.message || '' };
    }
  };

  const removeProductFromShoppingCart = async (productId: string) => {
    setRegistrationState((state) => ({ ...state, isRegistering: true }));
    try {
      const { data } = await apiService.post<any>('/shopping-cart/removeProduct', { productId });
      setAuthState({
        ...authState,
        extraAuthInfo: {
          ...authState.extraAuthInfo,
          shoppingCart: data.shoppingCart,
          message: ''
        }
      });
      setRegistrationState((state) => ({ ...state, isRegistering: false }));
      return true;
    } catch (error) {
      const data = error.response.data;
      setAuthState({
        ...authState,
        extraAuthInfo: {
          ...authState.extraAuthInfo,
          shoppingCart: data.shoppingCart,
          startRegistrationURL: data.startRegistrationURL,
          message: data?.message || ''
        }
      });
      setRegistrationState((state) => ({ ...state, isRegistering: false }));
      return false;
    }
  };

  const getExistedRegistrationsForSession = async (sessionId: string) => {
    setRegistrationState((state) => ({
      ...state,
      isLoadingSessionRegistrations: true,
      existedRegistrationsForSession: []
    }));
    try {
      const { data } = await apiService.get<any>(`/registrations/sessions/${sessionId}`);
      setRegistrationState((state) => ({
        ...state,
        isLoadingSessionRegistrations: false,
        existedRegistrationsForSession: data
      }));
      return { isError: false, data: data };
    } catch (error) {
      const data = error.response?.data;
      setRegistrationState((state) => ({
        ...state,
        isLoadingSessionRegistrations: false,
        existedRegistrationsForSession: []
      }));
      return { isError: true, message: data?.message || '' };
    }
  };

  const storeSessionIndex = (index: number) => {
    setRegistrationState((state) => ({ ...state, sessionIndex: index }));
  };

  return [
    {
      shoppingCartPaymentsState,
      registrationState
    },
    {
      paymentActions: {
        processShoppingCartPayment,
        setShoppingPaymentsState,
        getShoppingCart,
        verifyCouponDiscountForShoppingCart
      },
      registrationActions: {
        setRegistrationState,
        toggleAdditionalOptionSelection,
        removeSessionSelectionFromShoppingCart,
        removeStudentWithSessionSelectionFromShoppingCart,
        querySessionsToRegisterOn,
        storeSessionIndex,
        addStudentToSessionSelectionToShoppingCart,
        removeStudentFromSessionSelectionFromShoppingCart,
        getExistedRegistrationsForSession,
        removeProductFromShoppingCart
      }
    }
  ] as const;
};
