'use client';
import { useState, type FC, type MouseEventHandler } from 'react';
import type { SxProps, Theme } from '@mui/material';
import { Box, Button, CircularProgress, IconButton, Popover, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import type {
  ShoppingCart,
  ShoppingCart as ShoppingCartInterface,
  ShoppingCartProduct,
  ShoppingCartRegistration
} from '@efacity/common';
import { isShoppingCartEmpty } from '@efacity/common';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { ShoppingCartSmallTable } from './ShoppingCartSmallTable';

const tooltipSx = {
  backgroundColor: '#f5f5f9',
  color: 'rgba(0, 0, 0, 0.87)',
  maxWidth: '220px',
  fontSize: '10px',
  border: '1px solid #dadde9'
};
const arrowSx = {
  fontSize: 16,
  width: 17,
  '&::before': {
    backgroundColor: '#f5f5f9',
    boxSizing: 'border-box',
    border: '1px solid #dadde9'
  }
};
const buttonSx: SxProps<Theme> = {
  width: 150,
  height: 36,
  textTransform: 'none',
  marginTop: '15px',
  marginLeft: '5px',
  marginRight: '5px'
};

const getShoppingCartItemsWithDescription = (shoppingCart: ShoppingCartInterface) => {
  if (shoppingCart) {
    const items: string[] = [];
    shoppingCart?.shoppingCartRegistrations?.forEach((shoppingCartRegistration: ShoppingCartRegistration) => {
      if (shoppingCartRegistration.student) {
        const item = `${items.length + 1}. ${shoppingCartRegistration.session.name} for ${
          shoppingCartRegistration.student.name
        }`;
        items.push(item);
      } else {
        const item = `${items.length + 1}. ${shoppingCartRegistration.session.name} (student not selected)`;
        items.push(item);
      }
    });
    shoppingCart?.shoppingCartProducts?.forEach((shoppingCarProduct: ShoppingCartProduct) => {
      const item = `${items.length + 1}. ${shoppingCarProduct.product.name}`;
      items.push(item);
    });
    return [...items];
  } else {
    return [];
  }
};

export interface ShoppingCartIconWithTableProps {
  shoppingCart: ShoppingCart;
  isClearing: boolean;
  clearShoppingCart: () => void;
  gotoShoppingCartPage: () => void;
}

export const ShoppingCartIconWithTable: FC<ShoppingCartIconWithTableProps> = ({
  shoppingCart,
  isClearing,
  clearShoppingCart,
  gotoShoppingCartPage
}) => {
  const shoppingCartItems = getShoppingCartItemsWithDescription(shoppingCart);
  const shoppingCartItemsNumber = shoppingCartItems?.length ? shoppingCartItems.length : 0;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isPopoverOpen = Boolean(anchorEl);
  const id = isPopoverOpen ? 'simple-popover' : undefined;
  const isButtonsDisabled = isClearing || shoppingCartItemsNumber === 0;

  const onCartClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const gotoShoppingCartPageClick = () => {
    onPopoverClose();
    gotoShoppingCartPage();
  };

  const onPopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    // TODO: data-testid should not be used. Use aria label instead
    <div data-testid="shopping-cart-icon">
      <Tooltip
        placement="left"
        arrow
        title={
          shoppingCartItemsNumber > 0 ? (
            shoppingCartItems.map((item: string, index: number) => (
              <Typography color="inherit" key={`shoppingCartItem${index}`}>
                {item}
              </Typography>
            ))
          ) : (
            <Typography color="inherit">No items</Typography>
          )
        }
        componentsProps={{ tooltip: { sx: tooltipSx }, arrow: { sx: arrowSx } }}
      >
        <IconButton
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            cursor: 'pointer',
            fontSize: 44,
            position: 'unset',
            marginRight: '15px',
            marginLeft: '5px',
            '&:hover': {
              background: 'none'
            },
            ...(shoppingCartItemsNumber === 0
              ? {
                  cursor: 'not-allowed',
                  pointerEvents: 'none',
                  '& > div': {
                    backgroundColor: (theme) => theme.palette.grey['300']
                  },
                  '& > svg': {
                    fill: (theme) => theme.palette.grey['300']
                  }
                }
              : {})
          }}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          aria-label={`Shopping cart (${shoppingCartItemsNumber} ${shoppingCartItemsNumber <= 1 ? 'item' : 'items'})`}
          onClick={onCartClick}
        >
          <ShoppingCartIcon color="primary" fontSize="inherit" data-testid="shoppingCartIcon" />
          {shoppingCartItemsNumber > 0 && (
            <Box
              component="span"
              sx={{
                background: '#ff4040',
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                color: '#ffffff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '30px',
                marginLeft: '-30px',
                fontWeight: 'bold',
                fontSize: 14
              }}
            >
              {shoppingCartItemsNumber}
            </Box>
          )}
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={onPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box p={1} style={{ width: '100%', maxWidth: 400 }}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton aria-label="Close products info popover" onClick={onPopoverClose}>
              <CancelOutlined />
            </IconButton>
          </Box>
          <div style={{ marginTop: 15 }}>
            {isShoppingCartEmpty(shoppingCart) ? (
              <Typography color="inherit" align="center" variant="h5">
                No items
              </Typography>
            ) : (
              <ShoppingCartSmallTable shoppingCart={shoppingCart} />
            )}
          </div>
          <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              sx={buttonSx}
              color="primary"
              data-testid="clearButton"
              variant="contained"
              disabled={isButtonsDisabled}
              onClick={clearShoppingCart}
            >
              {isClearing ? (
                <CircularProgress
                  size={18}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    top: '50%',
                    left: '50%',
                    marginTop: 0,
                    marginLeft: 0,
                    marginRight: 10
                  }}
                />
              ) : (
                <DeleteIcon style={{ marginRight: 10 }} />
              )}
              Clear
            </Button>
            <Button
              sx={buttonSx}
              color="primary"
              variant="contained"
              disabled={isButtonsDisabled}
              onClick={gotoShoppingCartPageClick}
            >
              Shopping Cart
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};
