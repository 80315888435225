import React from 'react';

export const discountCardSx = {
  cardWrapper: {
    display: 'flex'
  },
  cardMain: {
    width: '300px',
    height: '180px',
    boxShadow: '0 4px 20px rgba(10, 152, 209, 0.2)',
    borderRadius: '20px',
    fontFamily: 'Roboto, sans-serif',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  } as React.CSSProperties,
  cardHeaderSx: {
    background: 'linear-gradient(22.27deg, #7752a0 3.23%, #ace7ff 100%)',
    textTransform: 'uppercase',
    color: '#ffffff',
    position: 'relative',
    padding: '17px',
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    overflow: 'hidden',
    height: '35%',
    display: 'flex',
    alignItems: 'center'
  },
  cardBody: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 18px',
    color: '#666666'
  },
  bodyIcon: {
    position: 'absolute',
    right: '-40px',
    bottom: '-30px'
  }
};
