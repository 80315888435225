import { Box, IconButton, Typography } from '@mui/material';
import RemoveIcon from '@mui/icons-material/HighlightOff';
import React, { FC } from 'react';
import { AdministratorFullInfo } from '@efacity/frontend-shared';

export interface AdministratorsListProps {
  canDeleteAdmin: (admin: AdministratorFullInfo) => boolean;
  administratorsList?: AdministratorFullInfo[];
  onDeleteAdministrator: (adminId: string) => void;
  loading?: boolean;
}

const adminsListSx = {
  administratorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #C4C4C4',
    borderRadius: '2px',
    padding: '0 8px',
    minHeight: '50px'
  },
  adminInfoWrapper: {
    width: '100%',
    flexDirection: {
      xs: 'column',
      sm: 'row'
    }
  },
  email: {
    display: 'flex',
    flexGrow: 1,
    width: '50%'
  }
};

export const AdministratorsList: FC<AdministratorsListProps> = ({
  administratorsList = [],
  onDeleteAdministrator,
  canDeleteAdmin,
  loading
}) => {
  return (
    <Box marginY={2}>
      {administratorsList?.map((administrator, index) => (
        <Box
          display="flex"
          key={`administrator${administrator._id}index${index}Key`}
          style={adminsListSx.administratorContainer}
          data-testid="administratorFieldsGroup"
        >
          <Box display="flex" sx={adminsListSx.adminInfoWrapper}>
            <Box flexGrow="1" width="50%">
              <Typography variant="body2">{administrator.fullName}</Typography>
            </Box>
            <Box style={adminsListSx.email}>
              <Typography variant="body2">{administrator.email}</Typography>
            </Box>
          </Box>

          {administratorsList?.length > 1 && (
            <Box style={{ display: 'flex', justifyContent: 'flex-end', minWidth: 48 }} data-testid="hi">
              {canDeleteAdmin(administrator) && (
                <IconButton
                  data-testid="deleteAdminButton"
                  aria-label="Delete administrator"
                  disabled={loading}
                  onClick={() => onDeleteAdministrator(administrator._id)}
                >
                  <RemoveIcon />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};
