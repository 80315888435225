import { MenuItem } from '@mui/material';
import React from 'react';
import { DisplayOption, DurationTypeOptions } from '@efacity/common';
import { FormInputTextProps, FormTextInput } from './FormTextInput';

export const DurationTypeSelector: React.FC<FormInputTextProps> = ({ name, ...props }) => {
  return (
    <FormTextInput select name={name} required={true} label="Schedule type" {...props}>
      {Object.values(DurationTypeOptions).map((durationTypeDisplayOption: DisplayOption) => (
        <MenuItem key={durationTypeDisplayOption.value} value={durationTypeDisplayOption.value}>
          {durationTypeDisplayOption.displayText}
        </MenuItem>
      ))}
    </FormTextInput>
  );
};
