import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { tableStyles } from '../../stylesheets/tableStyle';
import { SessionRegistrationWithSessionAndEducator } from '@efacity/frontend-shared';
import SessionLocationCell from '../../components/SessionLocationCell/SessionLocationCell';
import { getDateInLocalTimeZone, formatStringToMonthDayYearDate, getInstancesEndDateInTimeZone } from '@efacity/common';
import { getDaysOfWeekFromInstances } from '@efacity/react-next-sc';

const StudentRegistrationsTable: React.FC<{ registrations: SessionRegistrationWithSessionAndEducator[] }> = ({
  registrations = []
}) => {
  return (
    registrations &&
    registrations?.length > 0 && (
      <TableContainer component={Paper} sx={tableStyles.tableContainer}>
        <Table size="small" aria-label="a simple dense table" sx={tableStyles.table}>
          <TableHead sx={tableStyles.tableHead}>
            <TableRow>
              <TableCell>Session</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Starts On</TableCell>
              <TableCell>Ends On</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Instructor</TableCell>
              <TableCell>Days of Week</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {registrations.map((registration: SessionRegistrationWithSessionAndEducator, index: number) => (
              <TableRow key={`studentRegistrationsListTableKey${index}`} hover={true}>
                <TableCell>
                  {registration.efacitySession.name} - {registration.efacitySession.section}
                </TableCell>
                <TableCell>
                  <Typography style={{ whiteSpace: 'nowrap' }}>
                    {registration.efacitySession.schedule?.startDateTime
                      ? getDateInLocalTimeZone(registration.efacitySession.schedule.startDateTime)
                      : 'N/A'}
                  </Typography>
                </TableCell>
                <TableCell>
                  {' '}
                  <Typography style={{ whiteSpace: 'nowrap' }}>
                    {registration.efacitySession.schedule?.startDateTime
                      ? formatStringToMonthDayYearDate(
                          new Date(registration.efacitySession.schedule.startDateTime).toISOString()
                        )
                      : 'N/A'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography style={{ whiteSpace: 'nowrap' }}>
                    {registration.efacitySession.schedule?.instances
                      ? getInstancesEndDateInTimeZone(
                          registration.efacitySession?.schedule?.instances,
                          registration.efacitySession.timeZone
                        )
                      : 'N/A'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <SessionLocationCell session={registration.efacitySession} />
                </TableCell>
                <TableCell>{registration.efacitySession.teacher?.fullName || 'N/A'}</TableCell>
                <TableCell>
                  {getDaysOfWeekFromInstances(registration.efacitySession.schedule?.instances).join(', ')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
};

export default StudentRegistrationsTable;
