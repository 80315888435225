const adminSectionCellPadding = {
  padding: 5
};
const adminSectionCellMargin = {
  margin: 5
};

export const adminSectionTableFontSize = {
  fontSize: 14
};

export const adminSectionTableRowHeight = {
  height: 36
};

export const adminSectionTableRowDefaultStyle = {
  ...adminSectionCellMargin,
  ...adminSectionCellPadding,
  ...adminSectionTableFontSize
};

//Apply to columnStyles to remove extra space inside a column
export const minimalWidthToFitContent = {
  width: '1px'
};
