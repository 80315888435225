interface RectangleDimensions {
  width: number;
  height: number;
}

const DEFAULT_THRESHOLD = 0.05;

export const haveSameProportions = (a: RectangleDimensions, b: RectangleDimensions, threshold = DEFAULT_THRESHOLD) => {
  const aspectRatio = (obj: RectangleDimensions) => {
    return Math.max(obj.width / obj.height, obj.height / obj.width);
  };

  const aspectRatioA = aspectRatio(a);
  const aspectRatioB = aspectRatio(b);

  return Math.abs(aspectRatioA - aspectRatioB) < threshold;
};
