export const sharedFormSx = {
  formGroup: {
    marginBottom: '10px'
  },
  stateSelect: {
    marginBottom: '10px'
  },
  inlineField: {
    marginRight: { md: '15px', xs: 0 },
    width: {
      xs: '100%',
      md: 'auto'
    }
  },
  inlineInputsGroup: {
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    marginRight: {
      sm: 0
    }
  },
  formContainer: {
    padding: 0
  },
  smallField: {
    width: {
      xs: '100%',
      md: '130px'
    }
  },
  emailInput: {
    flexGrow: 1,
    width: {
      xs: '100%',
      md: 'auto'
    }
  },
  deleteButtonIcon: {
    display: {
      xs: 'none',
      md: 'block'
    }
  },
  link: {
    color: 'primary.dark',
    fontWeight: 'bold'
  }
};
