import React from 'react';
import { Box, BoxProps } from '@mui/material';

interface HorizontalFieldProps {
  wrapOnSmallScreen?: boolean;
}

export const HorizontalField: React.FC<HorizontalFieldProps & BoxProps> = ({
  children,
  wrapOnSmallScreen = true,
  ...rest
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& > *:not(:last-child)': {
          marginRight: { xs: 0, sm: '5px' }
        },
        flexDirection: {
          xs: 'column',
          sm: 'row'
        }
      }}
      style={wrapOnSmallScreen ? {} : { flexDirection: 'unset' }}
      {...rest}
    >
      {children}
    </Box>
  );
};
