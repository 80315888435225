const fixedDate = new Date(2020, 10, 12, 10, 10, 0, 0);

export const efacitySession: any = {
  price: 1000,
  currency: 'USD',
  capacity: 10,
  shortDescription: 'Angular Advanced',
  longDescription: 'Angular Advanced',
  location: 'inPerson',
  zoom: null,
  inPerson: {
    room: 'test room'
  },
  status: 'published',
  sendCalendarInvites: false,
  _id: '5ee31949a064e2794b5a2d33',
  activityId: '5ee31a1f707484c05311d322',
  timeOfDay: 'afternoon',
  name: 'Angular Advanced',
  type: 'camp',
  section: 'Programming',
  descriptionHeading: 'Angular advanced',
  room: 'online',
  owner: {
    orgId: {
      _id: '5ea8de9f2f37a246506d4a85',
      name: 'Jersey SW test center',
      timeZone: 'America/New_York',
      id: '5ea8de9f2f37a246506d4a85',
      hideNumberOfRegistrations: false
    },
    ownerType: 'centerOwner'
  },
  studentLevel: {
    type: 'schoolGradeStudentLevelType',
    fromSchoolGrade: 'grade1',
    toSchoolGrade: 'grade2'
  },
  teacherId: '5e95e4bf8dbba44fdee9368d',
  createdBy: '6195e4bf8dbba44fdee9369d',
  createdAt: fixedDate,
  updatedAt: fixedDate,
  sessionState: 'PROVISIONED',
  googleClassroom: null,
  publishedAt: fixedDate,
  schedule: {
    instances: [
      {
        _id: '5e95e5148dbba44fdee93663',
        startDateTime: fixedDate,
        durationInMinutes: 60
      }
    ],
    startDateTime: fixedDate,
    endDateTime: fixedDate,
    durationInMinutes: 60,
    numberOfInstances: 1,
    lastCancellationDate: fixedDate
  },
  teacher: '5e95e4bf8dbba44fdee9368d',
  startDateTime: fixedDate,
  endDateTime: fixedDate,
  duration: '1 day',
  weekDays: ['Tue'],
  registrations: [
    {
      _id: '5ef45ffefefcef21aa70e9ad',
      efacitySessionId: '5ee31949a064e2794b5a2d33',
      id: '5ef45ffefefcef21aa70e9ad'
    }
  ],
  id: '5ee31949a064e2794b5a2d33',
  imageFullUrl:
    'https://s3.amazonaws.com/auto.testing.efacity.com/curriculumImages/angular_curriculum_1600X840.png'
};
