import React from 'react';

export const sessionCardStyles = {
  card: {
    minHeight: 285,
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  } as React.CSSProperties,
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  } as React.CSSProperties,
  sessionNameBox: {
    height: 50,
    overflow: 'hidden'
  } as React.CSSProperties,
  locationGradeBox: {
    height: 32,
    display: 'flex',
    justifyContent: 'space-between'
  } as React.CSSProperties,
  aStyle: {
    textDecoration: 'none'
  } as React.CSSProperties,
  sessionNameSx: {
    lineHeight: 1.2,
    fontSize: 18,
    fontFamily: 'Roboto',
    fontWeight: 700,
    color: (theme) => theme.palette.primary.dark,
    height: '44px',
    overflow: 'hidden'
  },
  sessionDescription: {
    height: 110,
    overflow: 'hidden'
  } as React.CSSProperties,
  organizationDescriptionSx: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginBottom: '20px',
    '&>a': {
      textDecoration: 'none',
      '&>p': {
        fontWeight: 500,
        color: (theme) => theme.palette.primary.dark
      },
      '&:hover>p': {
        color: (theme) => theme.palette.primary.main
      }
    }
  },
  locationDescriptionSx: {
    display: 'flex',
    minHeight: 28,
    color: (theme) => theme.palette.primary.dark
  },
  sessionActions: {
    justifyContent: 'space-between',
    padding: '16px',
    paddingTop: '0px'
  } as React.CSSProperties,
  gradeSx: {
    whiteSpace: 'nowrap',
    color: (theme) => theme.palette.primary.dark
  },
  rating: { display: 'flex', fontSize: 14 }
};
