import { useFormContext } from 'react-hook-form';
import React, { FC } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { getNestedValue, isValidDate, nowUTC, time24HourFormat } from '@efacity/common';
import { groupWrapperStyle, textFieldStyle } from './FormTextInput';
import { parse } from 'date-fns';
import { format } from 'date-fns-tz';

// ? Type of Props the FormInput will receive
export type FormTimePickerProps = {
  name: string;
  label: string;
  timeZone: string;
  value: Date;
  onValueChange: (newDate: Date, isError: boolean) => void;
} & TextFieldProps;

export const FormTimePicker: FC<FormTimePickerProps> = ({
  name,
  label,
  timeZone,
  value,
  size,
  color,
  helperText = '',
  onValueChange,
  inputProps,
  ...otherProps
}) => {
  const {
    formState: { errors }
  } = useFormContext();

  const handleTimeChanged = (event) => {
    const newTimeString = event.target.value;

    const newTimeValue = parse(newTimeString, time24HourFormat, nowUTC());

    if (isValidDate(newTimeValue)) {
      // set date from incoming value as time picker correct returns only time
      const valueWithInstanceDate = new Date(
        value.getFullYear(),
        value.getMonth(),
        value.getDate(),
        newTimeValue.getHours(),
        newTimeValue.getMinutes(),
        0
      );
      onValueChange(valueWithInstanceDate, false);
    } else {
      onValueChange(newTimeValue, true);
    }
  };

  return (
    <div style={{ ...groupWrapperStyle }}>
      <TextField
        variant="outlined"
        fullWidth={true}
        size={size || 'small'}
        color={color || 'primary'}
        sx={textFieldStyle}
        {...otherProps}
        value={format(value, time24HourFormat)}
        error={!!getNestedValue(errors, name)}
        helperText={(getNestedValue(errors, name)?.message as unknown as string) || helperText}
        label={`${label} (${timeZone})`}
        type="time"
        inputProps={{
          name: `${name}`,
          'data-testid': `${name}-form-text-input`,
          onChange: handleTimeChanged,
          ...inputProps
        }}
      />
    </div>
  );
};
