import Divider from '@mui/material/Divider';
import React, { FC } from 'react';
import type { Theme } from '@mui/material';

interface FormGroupDividerProps {
  marginY?: number;
}

export const FormGroupDivider: FC<FormGroupDividerProps> = ({ marginY = 0 }) => {
  return <Divider sx={{ background: (theme: Theme) => theme.palette.grey[500], my: `${marginY}px` }} />;
};
