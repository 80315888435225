import { Button } from '@mui/material';
import AddCircleOutlined from '@mui/icons-material/AddCircleOutlined';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { shouldUseReactRouter } from '@efacity/routing';

interface AddLinkMediumButtonProps {
  link: string;
  text?: string;
  disabled?: boolean;
}

export const AddLinkMediumButton: React.FC<AddLinkMediumButtonProps> = ({ link, text = 'New', disabled = false }) => {
  const navigate = useNavigate();

  const onAddClick = () => {
    const useReactRouter = shouldUseReactRouter(link);
    if (useReactRouter) {
      navigate(link);
    } else {
      window.location.href = link;
    }
  };

  return (
    <Button
      style={{ width: 120, height: 36, textTransform: 'uppercase', marginLeft: 'auto' }}
      onClick={onAddClick}
      color="primary"
      variant="contained"
      disabled={disabled}
      data-testid="addBtn"
      startIcon={<AddCircleOutlined style={{ color: 'white', marginRight: 5 }} />}
    >
      {text}
    </Button>
  );
};
