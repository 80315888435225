import React from 'react';
import { PurchaseStatus, purchaseStatusViewConnector } from '@efacity/common';
import { MenuItem, TextField } from '@mui/material';
import { FilterProps, Renderer } from 'react-table';

export const PurchaseStatusFilter: Renderer<FilterProps<any>> = ({ column }) => {
  const { filterValue, setFilter } = column;

  const handleValueChange = (e) => {
    const { value } = e.target;
    setFilter(() => (value ? value : undefined));
  };

  return (
    <TextField
      select
      type="text"
      variant="outlined"
      color="primary"
      margin="dense"
      fullWidth
      onChange={handleValueChange}
      value={filterValue || ''}
      sx={{ m: 0 }}
      inputProps={{ sx: { padding: '0' } }}
      SelectProps={{ sx: { padding: 0.62, fontSize: '14px' } }}
    >
      <MenuItem key={'none'} value="">
        Any
      </MenuItem>
      {Object.values(PurchaseStatus).map((purchaseStatus) => (
        <MenuItem key={purchaseStatus} value={purchaseStatus}>
          {purchaseStatusViewConnector[purchaseStatus]}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default PurchaseStatusFilter;
