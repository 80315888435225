import React, { FC } from 'react';
import { getStatusText, Registration, RegistrationStatus as RegistrationStatusEnum } from '@efacity/common';
import { Box } from '@mui/material';
import { blue, green } from '@mui/material/colors';

interface RegistrationStatusProps {
  registrationStatus: Registration['status'];
}

const colors = {
  [RegistrationStatusEnum.Registered]: blue['400'],
  [RegistrationStatusEnum.RegisteredAndPaid]: green['400'],
  [RegistrationStatusEnum.Selected]: '#000',
  [RegistrationStatusEnum.Unregistered]: 'red'
};

const ColorRegistrationStatus: FC<RegistrationStatusProps> = ({ registrationStatus }) => {
  return <Box color={colors[registrationStatus] || 'inherit'}>{getStatusText(registrationStatus)}</Box>;
};

export default ColorRegistrationStatus;
