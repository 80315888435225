import React from 'react';
import PageNavigationTabs from '../../components/PageNavigatonTabs/PageNavigationTabs';
import { Outlet } from 'react-router-dom';
import { PageTitle } from '@efacity/react-next-sc';

function UserSettings() {
  const tabs = [
    {
      label: 'Main',
      link: 'main'
    },
    {
      label: 'Public',
      link: 'public'
    }
  ];

  return (
    <>
      <PageTitle title="User Settings" />
      <PageNavigationTabs tabs={tabs} />
      <Outlet />
    </>
  );
}

export default UserSettings;
