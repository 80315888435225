import { OwnerInterface } from './OwnerInterface';
import { Types } from 'mongoose';

export enum SendStatuses {
  Queued = 'queued',
  Sent = 'sent',
  Failed = 'failed',
  NotSent = 'notSent'
}
export const sendStatusViewConnector = {
  [SendStatuses.Queued]: 'Queued',
  [SendStatuses.Sent]: 'Sent',
  [SendStatuses.Failed]: 'Failed',
  [SendStatuses.NotSent]: 'Remote'
};

export interface MailMessageStatus {
  mailStatus: SendStatuses;
  message: string;
}

export enum MessageTypes {
  Email = 'email'
}

export interface FileAttachment {
  filename: string;
  path: string;
  encoding: string;
  contentType: string;
}
export interface HtmlAttachment {
  contentType: string;
  content: any;
}

export interface MessageInterface {
  _id: string | Types.ObjectId;

  recipientEmail: string;
  ccEmails: string[];
  senderEmail?: string;
  subject: string;
  body: string;
  attachments: FileAttachment[];
  owner: OwnerInterface;
  createdOn: string | Date;
  sentOn?: string | Date;
  status: SendStatuses;
  type: MessageTypes;
  error?: string;
}
