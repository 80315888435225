import { shouldUseReactRouter } from '@efacity/routing';
export const navigateToPage = (navigate: (path: string) => void) => {
  return (path: string) => {
    const useReactRouter = shouldUseReactRouter(path);
    if (useReactRouter) {
      navigate(path);
    } else {
      window.location.href = path;
    }
  };
};
