import React, { FC } from 'react';
import { IdOrgIdParamTypes } from '@efacity/frontend-next-shared/utils';
import { useParams } from 'react-router-dom';
import Table, { PaginationTypes } from '../../../components/Table/Table';
import { useSessionOptionsTableColumns } from '../useSessionOptionsTableColumns';
import { useAdditionalSessionOptionsLoader } from '../../../hooks/useAdditionalSessionOptionLoader';

const ExpiredSessionOptionsTable: FC = () => {
  const { orgId } = useParams<IdOrgIdParamTypes>();
  const columns = useSessionOptionsTableColumns(orgId);
  const { additionalSessionOptionsState } = useAdditionalSessionOptionsLoader(
    `/org/${orgId}/additionalOptions/expired`
  );

  return (
    <Table
      columns={columns}
      data={additionalSessionOptionsState.data}
      loading={additionalSessionOptionsState.isLoading}
      paginationType={PaginationTypes.ClientSide}
      noDataText="No expired additional options..."
    />
  );
};

export default ExpiredSessionOptionsTable;
