const contactInfoModalSx = {
  list: theme => ({
    '& li:first-student': {
      borderTop: `1px solid ${theme.palette.grey['300']}`
    },
    '& li': {
      borderBottom: `1px solid ${theme.palette.grey['300']}`
    }
  }),
  attendeeTable: {
    display: 'grid',
    gridTemplateColumns: '0.6fr 1fr 0.6fr',
    '& > div': {
      paddingRight: theme => theme.spacing(2)
    }
  },
  columnTitle: {
    fontWeight: 'bold',
    color: theme => theme.palette.grey['700']
  }
};

export default contactInfoModalSx;
