import {
  AdditionalSessionOption,
  InvoiceItemPresentation,
  Messages,
  ShoppingCartRegistration,
  StudentSessionId,
  StudentSessionOptionId,
  User,
  getAllStudentSessionIdsFromShoppingCartRegistrations
} from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { Session as EfacitySession, useAuth } from '@efacity/react-next-sc';
import { PATHS } from '@efacity/routing';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setNeedReturnToRegistration } from '../CustomerStudents/useCustomerStudentsLoader';
import { registrationInfoModalInitialState } from '../PaymentContainer/useInvoicePayment';
import { useShoppingCartPayment } from '../ShoppingCartContainer/useShoppingCartPayment';
import SelectAdditionalSessionOptions from './SelectAdditionalSessionOptions';
const SelectAdditionalOptionsByCustomer = () => {
  const {
    authState: {
      extraAuthInfo: { shoppingCart }
    }
  } = useAuth();
  const [{ registrationState }, { registrationActions }] = useShoppingCartPayment();
  const { setRegistrationState, toggleAdditionalOptionSelection, querySessionsToRegisterOn } = registrationActions;
  const { startRegistrationURL, isLoadingSessions, isRegistering, sessionsSelectedForRegistration } = registrationState;

  const sessionsWithAdditionalOptions = sessionsSelectedForRegistration.filter(
    (session) => session.additionalSessionOptions && session.additionalSessionOptions.length > 0
  );

  const navigate = useNavigate();
  const snackbarProps = useSnackbar();

  const closeModalAndGotoRegistrations = useCallback(
    (storedRedirectUrl: string) => {
      setRegistrationState((state) => ({ ...state, registrationInfoModal: { ...registrationInfoModalInitialState } }));
      if (storedRedirectUrl) {
        navigate(storedRedirectUrl);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );

  const gotoStartRegistrationsIfAny = useCallback(() => {
    const redirectPath = startRegistrationURL ? startRegistrationURL : PATHS.landing;
    window.location.href = redirectPath;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startRegistrationURL]);

  const handleSelectAdditionalOptionForStudentClicked = (
    sessionId: string,
    additionalOptionId: string,
    studentId: string,
    isChecked: boolean
  ) => {
    toggleAdditionalOptionSelection(studentId, sessionId, additionalOptionId, isChecked);
  };

  const handleGotoPaymentClicked = async () => {
    navigate(PATHS.cart);
  };

  const handleSelectStudentsClicked = () => {
    navigate(PATHS.selectStudentsForSessions);
  };

  useEffect(() => {
    const loadSessionsAndValidate = async () => {
      const toggleNeedReturnAndGotoAddStudent = () => {
        setNeedReturnToRegistration(registrationState.requireStudentAge);
        navigate(PATHS.addStudent);
      };

      const validateIncomingSessionsAndStudent = (
        sessions: EfacitySession[],
        startRegistrationURL: string,
        students: User[]
      ) => {
        if (!(sessions && sessions.length > 0)) {
          setRegistrationState((state) => ({
            ...state,
            registrationInfoModal: {
              ...state.registrationInfoModal,
              isOpen: true,
              isError: false,
              message: Messages.ToStartNeedRegistrations,
              onClose: closeModalAndGotoRegistrations,
              redirectUrl: startRegistrationURL
            }
          }));
        } else if (!(students && students.length > 0)) {
          toggleNeedReturnAndGotoAddStudent();
        }
      };

      const { isError, sessions, startRegistrationURL, students, message } = await querySessionsToRegisterOn();
      if (isError) {
        showNotification(snackbarProps, false, message, true, 'Ok');
        gotoStartRegistrationsIfAny();
      } else {
        validateIncomingSessionsAndStudent(sessions, startRegistrationURL, students);
      }
    };
    loadSessionsAndValidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, closeModalAndGotoRegistrations, gotoStartRegistrationsIfAny, snackbarProps]);

  const studentSessionOptionIds = mapShoppingCartRegistrationsToStudentSessionOptionId(
    shoppingCart && shoppingCart?.shoppingCartRegistrations ? shoppingCart.shoppingCartRegistrations : []
  );

  return (
    <SelectAdditionalSessionOptions
      isLoading={isLoadingSessions}
      isRegistering={isRegistering}
      sessionsWithAdditionalOptions={sessionsWithAdditionalOptions}
      allStudentsForSessionIds={getAllStudentSessionIdsFromShoppingCartRegistrations(
        shoppingCart && shoppingCart?.shoppingCartRegistrations ? shoppingCart.shoppingCartRegistrations : []
      )}
      studentSessionAdditionalOptionIds={studentSessionOptionIds}
      selectAdditionalOptionForStudentClicked={handleSelectAdditionalOptionForStudentClicked}
      selectStudentsClicked={handleSelectStudentsClicked}
      gotoPaymentClicked={handleGotoPaymentClicked}
      isInvoicePaid={false}
      registrationInfoModal={registrationState.registrationInfoModal}
    />
  );
};

export default SelectAdditionalOptionsByCustomer;

export const mapShoppingCartRegistrationsToStudentSessionOptionId = (
  shoppingCartRegistrations: ShoppingCartRegistration[]
) => {
  const studentSessionOptionIds: StudentSessionOptionId[] = [];
  shoppingCartRegistrations.forEach((shoppingCartItem: ShoppingCartRegistration) => {
    if (shoppingCartItem.additionalSessionOptions && shoppingCartItem.additionalSessionOptions.length > 0) {
      shoppingCartItem.additionalSessionOptions.forEach((additionalSessionOption: AdditionalSessionOption) => {
        const studentSessionOptionId: StudentSessionOptionId = {
          studentId: shoppingCartItem.student._id,
          sessionId: shoppingCartItem.session._id,
          additionalOptionId: additionalSessionOption._id
        };

        studentSessionOptionIds.push(studentSessionOptionId);
      });
    }
  });
  return [...studentSessionOptionIds];
};

export const mapInvoiceItemsToStudentSessionOptionId = (invoiceItems: InvoiceItemPresentation[]) => {
  const studentSessionOptionIds: StudentSessionOptionId[] = [];
  if (invoiceItems && invoiceItems.length > 0) {
    invoiceItems.forEach((invoiceItem: InvoiceItemPresentation) => {
      if (invoiceItem.additionalOptionId) {
        const studentSessionOptionId: StudentSessionOptionId = {
          studentId: invoiceItem.studentId,
          sessionId: invoiceItem.sessionId,
          additionalOptionId: invoiceItem.additionalOptionId
        };
        studentSessionOptionIds.push(studentSessionOptionId);
      }
    });
  }

  return [...studentSessionOptionIds];
};

export const getAllStudentSessionIdsFromInvoiceItems = (invoiceItems): StudentSessionId[] => {
  if (invoiceItems && invoiceItems.length > 0) {
    return invoiceItems.reduce((studentSessionIds: StudentSessionId[], invoiceItem) => {
      if (invoiceItem.registrationId) {
        const fountStudentSession = studentSessionIds.find(
          (studentSessionId: StudentSessionId) =>
            studentSessionId.studentId === invoiceItem.studentId && studentSessionId.sessionId === invoiceItem.sessionId
        );
        if (!fountStudentSession) {
          studentSessionIds.push({
            studentId: invoiceItem.studentId,
            studentName: invoiceItem.studentName,
            sessionId: invoiceItem.sessionId
          });
        }
      }
      return studentSessionIds;
    }, []);
  }
  return [];
};
