import * as Yup from 'yup';
import { DiscountType, isDiscountTypeOption, Messages } from '@efacity/common';

export const discountFormValuesValidationSchema = Yup.object().shape({
  code: Yup.string().required('Code is required'),
  expiresOn: Yup.date().required('Expiration Date is required'),
  description: Yup.string(),
  descriptionForCustomer: Yup.string().required(Messages.CustomerDescriptionRequired),
  typeDefinition: Yup.object().shape({
    discountType: Yup.string().required('Select Discount Type'),
    discountPercentage: Yup.number()
      .typeError(Messages.ValueMustBeNumber)
      .when('discountType', (discountType, schema) => {
        if (isDiscountTypeOption(discountType) && DiscountType.Percentage === discountType) {
          return schema
            .required(Messages.DiscountPercentageRequired)
            .min(0, Messages.WrongPercentageRange)
            .max(100, Messages.WrongPercentageRange);
        } else {
          return schema;
        }
      }),
    discountAmount: Yup.number()
      .typeError(Messages.ValueMustBeNumber)
      .when('discountType', (discountType, schema) => {
        if (isDiscountTypeOption(discountType) && discountType === DiscountType.Amount) {
          return schema.required(Messages.DiscountAmountRequired);
        } else {
          return schema;
        }
      })
  }),
  currencyCode: Yup.string().when('discountType', (discountType, schema) => {
    if (isDiscountTypeOption(discountType) && DiscountType.Amount === discountType) {
      return schema.required(Messages.SelectCurrency);
    } else {
      return schema;
    }
  }),
  classDefinition: Yup.object().shape({
    discountClass: Yup.string().required('Select Discount Class'),
    consecutiveSessionsNumber: Yup.number(),
    fullDayOnly: Yup.boolean(),
    marginOfDays: Yup.string()
  }),
  applyToAllSessionsInShoppingCart: Yup.boolean()
});
