import { MobileDatePicker } from '@mui/x-date-pickers';
import React, { FC, useRef } from 'react';
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { textFieldStyle } from './FormTextInput';
import { Controller, useFormContext } from 'react-hook-form';
import { monthDayYearDateFormat, getNestedValue } from '@efacity/common';

export type FormDatePickerProps = {
  name: string;
  onValueChange?: (date: Date | null) => void;
} & TextFieldProps;

export const FormDatePicker: FC<FormDatePickerProps> = ({
  name,
  size,
  color,
  helperText = '',
  onValueChange = null,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const dateInputRef = useRef<HTMLInputElement>(null);
  const handleIconClicked = () => {
    dateInputRef.current?.click();
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field: { value, onChange, ...restField } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              {...restField}
              format={monthDayYearDateFormat}
              value={value}
              closeOnSelect
              inputRef={dateInputRef}
              disabled={false}
              onChange={(date) => {
                onChange(date);
                onValueChange && onValueChange(date);
              }}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  fullWidth: true,
                  inputProps: {
                    name: `${name}`,
                    'data-testid': `${name}-date-input`
                  },
                  size: size || 'small',
                  color: color || 'primary',
                  sx: textFieldStyle,
                  error: !!getNestedValue(errors, name),
                  helperText: (getNestedValue(errors, name)?.message as unknown as string) || helperText,
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="select date" size="small" onClick={handleIconClicked} edge="end">
                          <CalendarMonthIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    )
                  },
                  ...otherProps
                }
              }}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};
