import React, { ChangeEvent, FC, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogProps,
  Divider,
  FormControlLabel,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Info from '@mui/icons-material/Info';
import { RichTextEditor } from '../RichTextEditor/RichTextEditor';
import { Agreement, User } from '@efacity/common';
import { ModalDialogTitle } from '@efacity/react-next-sc';

const dialogContentSx = {
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingBottom: '12px',
  paddingTop: '12px'
};

export interface UserAgreement {
  user: Partial<User>;
  agreement: Agreement;
}

interface UnsignedCustomerAgreementsModalDialogProps extends DialogProps {
  onClose: () => void;
  unsignedAgreements: UserAgreement[];
  onSignAgreement: (userId: string, agreementId: string) => Promise<void>;
  onDismissAgreement: (userId: string, agreementId: string) => Promise<void>;
  defaultSignState?: boolean;
}

export const UnsignedCustomerAgreementsModal: FC<UnsignedCustomerAgreementsModalDialogProps> = ({
  unsignedAgreements,
  open,
  onClose,
  onSignAgreement,
  onDismissAgreement
}) => {
  const theme = useTheme();
  const fullScreenModal = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentAgreementToShow, setCurrentAgreementToShow] = useState(0);
  const [isSigned, setIsSigned] = useState(false);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.checked) {
      return setIsSigned(false);
    }
    setIsSigned(true);
  };

  const handleSignAgreement = async () => {
    if (isSigned) {
      await onSignAgreement(
        unsignedAgreements[currentAgreementToShow].user._id,
        unsignedAgreements[currentAgreementToShow].agreement._id
      );

      // if user has not agreed to all agreements in the list
      if (currentAgreementToShow + 1 < unsignedAgreements.length) {
        setCurrentAgreementToShow((currentAgreementIndex) => currentAgreementIndex + 1);
      }
      setIsSigned(false);
    }
  };

  const handleDismissAgreement = async () => {
    await onDismissAgreement(
      unsignedAgreements[currentAgreementToShow].user._id,
      unsignedAgreements[currentAgreementToShow].agreement._id
    );

    // if user has not agreed to all agreements in the list
    if (currentAgreementToShow + 1 < unsignedAgreements.length) {
      setCurrentAgreementToShow((currentAgreementIndex) => currentAgreementIndex + 1);
      setIsSigned(false);
    }
  };

  const currentAgreementRichText = JSON.parse(
    unsignedAgreements[currentAgreementToShow].agreement.agreementRichText as string
  );

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        // disableBackdropClick has been deprecated
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullScreen={fullScreenModal}
    >
      <ModalDialogTitle
        text={
          unsignedAgreements[currentAgreementToShow]?.user?.fullName
            ? `${unsignedAgreements[currentAgreementToShow].agreement.agreementName} for ${unsignedAgreements[currentAgreementToShow].user.fullName}`
            : `${unsignedAgreements[currentAgreementToShow].agreement.agreementName}`
        }
      />
      <DialogContent sx={dialogContentSx}>
        <Box display="flex">
          <Info style={{ marginRight: 10 }} color="primary" />
          <Typography color="primary">Please read the agreement below in order to proceed.</Typography>
        </Box>
        <RichTextEditor key={currentAgreementToShow} value={currentAgreementRichText} readOnly />
        <Divider />
        <FormControlLabel
          control={<Checkbox checked={isSigned} onChange={handleChange} name="agree" color="primary" />}
          label="I agree"
        />
        <Box display="flex" justifyContent="center">
          <Button
            data-testid="submit-sign-agreement"
            type="button"
            color="primary"
            variant="contained"
            disabled={!isSigned}
            onClick={handleSignAgreement}
            style={{ width: 110 }}
          >
            Sign
          </Button>
          {!unsignedAgreements[currentAgreementToShow].agreement.customerMustSignAgreement && (
            <Button
              data-testid="submit-sign-agreement"
              type="button"
              color="primary"
              variant="contained"
              onClick={handleDismissAgreement}
              style={{ width: 110, marginLeft: 90 }}
              disabled={isSigned}
            >
              Decline
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
