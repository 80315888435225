import React, { FC } from 'react';
import {
  convertUtcToZonedTime,
  isDate,
  PurchaseStatus,
  purchaseStatusViewConnector,
  formatDateWithFormatter,
  monthDayYearDateFormatHoursFormat
} from '@efacity/common';
import { useTheme } from '@mui/material';

export interface ColoredDateTimeProps {
  dateTime: Date | string;
  timeZone: string;
  purchaseStatus?: PurchaseStatus;
  showStatus?: boolean;
}

const ColoredPurchaseStatusDate: FC<ColoredDateTimeProps> = ({
  dateTime,
  timeZone,
  purchaseStatus = PurchaseStatus.Ordered,
  showStatus = true
}) => {
  const theme = useTheme();

  const purchaseStatusColor = (status: PurchaseStatus) => {
    return status === PurchaseStatus.Ordered
      ? theme.palette.secondary.main
      : status === PurchaseStatus.Paid
      ? theme.palette.secondary.dark
      : status === PurchaseStatus.Shipped
      ? theme.palette.info.main
      : status === PurchaseStatus.Delivered
      ? theme.palette.primary.dark
      : status === PurchaseStatus.Cancelled
      ? theme.palette.error.main
      : '';
  };

  return isDate(dateTime) ? (
    <div style={{ color: purchaseStatusColor(purchaseStatus) }}>
      {showStatus
        ? `${purchaseStatusViewConnector[purchaseStatus]} at ${formatDateWithFormatter(
            convertUtcToZonedTime(dateTime, timeZone),
            monthDayYearDateFormatHoursFormat
          )}`
        : `${formatDateWithFormatter(convertUtcToZonedTime(dateTime, timeZone), monthDayYearDateFormatHoursFormat)}`}
    </div>
  ) : (
    '[n/a]'
  );
};

export default ColoredPurchaseStatusDate;
