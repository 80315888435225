import {
  AdditionalSessionOption,
  convertUtcToZonedTime,
  CurrencyCode,
  DurationType,
  formatSessionWeekDays,
  getEndTime,
  moneyWithCurrencyCodeFormatter,
  formatStartToEndDateInTimeZone,
  formatTimeToHhMmNonBreakableString
} from '@efacity/common';
import { Box, Button, Typography } from '@mui/material';
import React, { FC } from 'react';
import mobileTableStyle from '../../../stylesheets/mobileTableStyle';
import { ShoppingCartRegistrationTableProps } from './ShoppingCartRegistrationAsTableRows';
import { Link } from 'react-router-dom';
import { PATHS } from '@efacity/routing';
import DeleteIconButtonWithSpinner from '../../../components/DeleteIconButtonWithSpinner/DeleteIconButtonWithSpinner';
import DiscountsListMobile from '../../../components/DiscountsListMobile';

const ShoppingCartRegistrationMobileTableRows: FC<ShoppingCartRegistrationTableProps> = ({
  session,
  timeZone,
  discounts,
  student,
  additionalOptions,
  handleDeleteShoppingCartItem,
  handleDeleteAdditionalOption,
  isUpdating
}) => {
  const isSelfPacedSession = session.durationType === DurationType.selfPaced;
  let sessionDuration = '-';
  if (isSelfPacedSession) sessionDuration = 'self-paced';
  else if (session.duration) {
    sessionDuration = session.duration;
  }
  return (
    <Box sx={mobileTableStyle.rootSx} data-testid={`ShoppingCartItemMobileTableRows_${session.name}`}>
      <Box style={{ ...mobileTableStyle.sessionNameRow, borderBottom: '1px solid #C4C4C4' }}>
        <Box>
          <Typography sx={mobileTableStyle.titleSx}>Session</Typography>
          <Box fontWeight="bold" sx={mobileTableStyle.titleSx}>
            {session.name}
          </Box>
        </Box>
        <Box style={mobileTableStyle.deleteIcon}>
          <DeleteIconButtonWithSpinner
            onClick={() => handleDeleteShoppingCartItem(student?._id ? student._id : null, session._id)}
            isUpdating={isUpdating}
          />
        </Box>
      </Box>

      <Box style={mobileTableStyle.invoiceItemRow}>
        <Box>
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Student:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>
            {student && student.name ? (
              student.name
            ) : (
              <Button
                component={Link}
                to={PATHS.selectStudentsForSessions}
                color="primary"
                variant="contained"
                style={{ fontSize: 12, paddingLeft: 5, paddingRight: 5, marginLeft: 0, marginRight: 10 }}
              >
                Select Student
              </Button>
            )}
          </Typography>
        </Box>
      </Box>

      <Box style={mobileTableStyle.mobileTwoColumnRow}>
        <Box>
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Dates:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>
            {isSelfPacedSession ? '-' : formatStartToEndDateInTimeZone(session.startDate, session.endDate, timeZone)}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Duration:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>{sessionDuration}</Typography>
        </Box>
      </Box>

      <Box style={mobileTableStyle.invoiceItemRow}>
        <Box>
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Time:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>
            {isSelfPacedSession
              ? '-'
              : `${formatTimeToHhMmNonBreakableString(
                  convertUtcToZonedTime(new Date(session.startDate), timeZone)
                )} - ${getEndTime(
                  session.instanceDuration,
                  convertUtcToZonedTime(new Date(session.startDate), timeZone)
                )}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Days:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>
            {isSelfPacedSession ? '-' : formatSessionWeekDays(session.weekDays)}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Price:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>
            {moneyWithCurrencyCodeFormatter(session.price, session.currencyCode as CurrencyCode)}
          </Typography>
        </Box>
      </Box>
      <DiscountsListMobile discounts={discounts} currency={session.currencyCode as CurrencyCode} />
      {additionalOptions &&
        additionalOptions.length > 0 &&
        additionalOptions.map((additionalOption: AdditionalSessionOption, index: number) => (
          <Box
            style={{ ...mobileTableStyle.additionalOptionItemRow, borderTop: '1px solid #C4C4C4' }}
            key={`additionalOptionKey${index}session${session._id}AdditionalOption${additionalOption._id}`}
          >
            <Box padding="10px">
              <Typography variant="h6" sx={mobileTableStyle.titleSx}>
                Additional Option:
              </Typography>
              <Typography style={mobileTableStyle.textSm}>{additionalOption.name}</Typography>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              {moneyWithCurrencyCodeFormatter(additionalOption.price, additionalOption.currencyCode)}
            </Box>
            <Box style={mobileTableStyle.deleteIcon}>
              <DeleteIconButtonWithSpinner
                onClick={() => handleDeleteAdditionalOption(student._id, session._id, additionalOption._id)}
                isUpdating={isUpdating}
              />
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default ShoppingCartRegistrationMobileTableRows;
