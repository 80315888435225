import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  CountryCode,
  InvoicePresentation,
  Messages,
  PaymentAcceptanceMethod,
  PaymentCard,
  StudentOnSessionRegistrationInfo,
  User
} from '@efacity/common';
import { apiService, paymentDoneModalInitialState, PaymentDoneModalState } from '@efacity/frontend-shared';
import { Session as EfacitySession } from '@efacity/react-next-sc';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

export interface PaymentsState {
  invoice: InvoicePresentation | null;
  credit: number | null;
  isLoadingInvoices: boolean;
  isUpdatingInvoice: boolean;
  stripePublishableKey: string | null;
  paymentAcceptanceMethod: PaymentAcceptanceMethod;
  paymentCards: PaymentCard[];
  defaultCountry: CountryCode | null;
  paymentDoneModal: PaymentDoneModalState;
  message?: string;
}
export const initialPaymentsState: PaymentsState = {
  invoice: null,
  credit: null,
  isLoadingInvoices: false,
  isUpdatingInvoice: false,
  stripePublishableKey: null,
  paymentAcceptanceMethod: PaymentAcceptanceMethod.Efacity,
  defaultCountry: null,
  paymentCards: [],
  paymentDoneModal: paymentDoneModalInitialState
};

export interface RegistrationInfoModalState {
  isOpen: boolean;
  isError: boolean;
  message: string;
  onClose: any;
  redirectUrl: string | null;
}
export interface RegistrationState {
  sessionsSelectedForRegistration?: EfacitySession[] | null;
  existedRegistrationsForSession: StudentOnSessionRegistrationInfo[];
  possibleRegistrations: StudentOnSessionRegistrationInfo[];
  students: User[];
  requireStudentAge: boolean;
  sessionIndex: number;
  startRegistrationURL: string | null;
  isLoadingSessions: boolean;
  isLoadingSessionRegistrations: boolean;
  isLoading: boolean;
  isRegistering: boolean;
  filter: string;
  registrationInfoModal: RegistrationInfoModalState;
}

export const registrationInfoModalInitialState = {
  isOpen: false,
  isError: false,
  message: '',
  onClose: null,
  redirectUrl: null
};
export const initialRegistrationState: RegistrationState = {
  sessionsSelectedForRegistration: [],
  existedRegistrationsForSession: [],
  possibleRegistrations: [],
  students: [],
  requireStudentAge: false,
  sessionIndex: 0,
  startRegistrationURL: null,
  isLoadingSessions: false,
  isLoadingSessionRegistrations: true,
  isLoading: false,
  isRegistering: false,
  filter: '',
  registrationInfoModal: registrationInfoModalInitialState
};

export const useInvoicePayment = (url: string) => {
  const snackbarProps = useSnackbar();
  const [paymentState, setPaymentState] = useState(initialPaymentsState);
  const [registrationState, setRegistrationState] = useState(initialRegistrationState);

  useEffect(() => {
    const getInvoice = async () => {
      try {
        setPaymentState((state) => ({ ...state, isLoadingInvoices: true }));
        const { data } = await apiService.get<PaymentsState>(url);
        const payment = { ...data };

        setPaymentState((state) => ({
          ...state,
          invoice: payment.invoice,
          stripePublishableKey: payment.stripePublishableKey,
          paymentAcceptanceMethod: payment.paymentAcceptanceMethod,
          credit: payment?.credit > 0 ? payment.credit : 0,
          paymentCards: payment.paymentCards,
          defaultCountry: payment.defaultCountry,
          isLoadingInvoices: false
        }));
      } catch (error) {
        showNotification(snackbarProps, false, error.response?.data?.message || Messages.FailedGetInvoice, true);
        setPaymentState(initialPaymentsState);
      }
    };

    getInvoice();
  }, [url, snackbarProps, setPaymentState]);

  const processServerSideInvoicePayment = async (url: string, paymentData: any) => {
    try {
      setPaymentState((state) => ({ ...state, isUpdatingInvoice: true }));
      const { data } = await apiService.post<any>(url, paymentData);
      const payment = { ...data };
      setPaymentState((state) => ({
        ...state,
        invoice: payment,
        isUpdatingInvoice: false
      }));
      return { ...payment, isError: false };
    } catch (error) {
      setPaymentState((state) => ({ ...state, isUpdatingInvoice: false }));
      return { ...error.response.data, isError: true };
    }
  };

  const verifyCouponDiscountForInvoice = async (url: string, code: string) => {
    try {
      setPaymentState((state) => ({ ...state, isUpdatingInvoice: true }));
      const { data } = await apiService.post<InvoicePresentation>(url, { code });
      const payment = { ...data };
      setPaymentState((state) => ({
        ...state,
        invoice: payment,
        isUpdatingInvoice: false
      }));
    } catch (error) {
      setPaymentState((state) => ({ ...state, isUpdatingInvoice: false }));
    }
  };

  const deleteDiscount = async (url: string) => {
    try {
      setPaymentState((state) => ({ ...state, isUpdatingInvoice: true }));
      const { data } = await apiService.post<InvoicePresentation>(url, {});
      const payment = { ...data };
      setPaymentState((state) => ({
        ...state,
        invoice: payment,
        isUpdatingInvoice: false
      }));
    } catch (error) {
      showNotification(
        snackbarProps,
        false,
        error.response?.data?.message || Messages.FailedSwitchOptionRegistrationReload,
        true
      );
      setPaymentState((state) => ({ ...state, isUpdatingInvoice: false }));
    }
  };

  const adjustInvoice = async (url: string, newAmount: number) => {
    try {
      setPaymentState((state) => ({ ...state, isUpdatingInvoice: true }));
      const { data } = await apiService.post<InvoicePresentation>(url, { newAmount });
      const payment = { ...data };
      setPaymentState((state) => ({
        ...state,
        invoice: payment,
        isUpdatingInvoice: false
      }));
      return true;
    } catch (error) {
      showNotification(snackbarProps, false, error.response?.data?.message || Messages.FailedUpdateInvoice, true);
      setPaymentState((state) => ({ ...state, isUpdatingInvoice: false }));
      return false;
    }
  };

  const switchAdditionalOptionSelectionToInvoiceRegistration = async (url: string) => {
    try {
      setPaymentState((state) => ({ ...state, isUpdatingInvoice: true }));
      const { data } = await apiService.post<{ invoice: InvoicePresentation }>(url, {});
      const payment = { ...data };
      setPaymentState((state) => ({
        ...state,
        invoice: payment.invoice,
        isUpdatingInvoice: false
      }));
    } catch (error) {
      showNotification(
        snackbarProps,
        false,
        error.response?.data?.message || Messages.FailedSwitchOptionRegistrationReload,
        true
      );
      setPaymentState((state) => ({ ...state, isUpdatingInvoice: false }));
    }
  };

  const deleteRegistrationFromInvoice = async (url: string) => {
    try {
      setPaymentState((state) => ({ ...state, isUpdatingInvoice: true }));
      const { data } = await apiService.post<{ invoice: InvoicePresentation }>(url, {});
      const payment = { ...data };
      setPaymentState((state) => ({
        ...state,
        invoice: payment.invoice,
        isUpdatingInvoice: false
      }));
      return payment;
    } catch (error) {
      showNotification(
        snackbarProps,
        false,
        error.response?.data?.message || Messages.FailedDeleteRegistrationReload,
        true
      );
      setPaymentState((state) => ({ ...state, isUpdatingInvoice: false }));
      return null;
    }
  };

  const querySessionsFromInvoiceToRegisterOn = async (url: string) => {
    try {
      setRegistrationState((state) => ({ ...state, isLoadingSessions: true, existedRegistrationsForSession: [] }));
      const { data } = await apiService.get<any>(url);
      setRegistrationState((state) => ({
        ...state,
        isLoadingSessions: false,
        sessionIndex: 0,
        sessionsSelectedForRegistration: data.sessions,
        startRegistrationURL: data.startRegistrationURL,
        students: data.students,
        requireStudentAge: data.requireStudentAge || false
      }));
      return { isError: false, ...data };
    } catch (error) {
      setRegistrationState((state) => ({
        ...state,
        isLoadingSessions: false,
        startRegistrationURL: null
      }));
      return { isError: true, message: error.response?.data?.message || Messages.FailedGetSessions };
    }
  };

  return [
    { paymentState, registrationState },
    {
      paymentActions: {
        setPaymentState,
        processServerSideInvoicePayment,
        verifyCouponDiscountForInvoice,
        adjustInvoice
      },
      registrationActions: {
        setRegistrationState,
        deleteDiscount,
        switchAdditionalOptionSelectionToInvoiceRegistration,
        deleteRegistrationFromInvoice,
        querySessionsFromInvoiceToRegisterOn
      }
    }
  ] as const;
};
