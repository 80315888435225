import { DisplayOption } from './DisplayOption';
import { LocationType, Location, locationOptions, locationWithoutPublicOptions } from './Location';
import { ActivityTypes, DurationType, StudentLevelOptional } from './Activities';
import { AgeStudentLevelTypeValue, mapAgeToSchoolGrade, SchoolGradeStudentLevelTypeValue } from './StudentLevel';
import { getGradeByNumericValue } from './Grades';

export interface ScheduleInstance {
  _id?: any;
  startDateTime: Date | string;
  durationInMinutes: number;
  deletedAt?: Date;
}
export interface SessionSchedule {
  startDateTime?: any;
  endDateTime?: Date | string;
  durationInMinutes?: number;
  numberOfInstances?: number;
  instances: ScheduleInstance[];
  deadlineForCustomerRegistration?: Date;
  lastCancellationDate?: Date;
}

export interface Session {
  _id: string;
  activityId: string;
  name: string;
  studentLevel: StudentLevelOptional;
  price: number;
  capacity?: number;
  section?: string;
  descriptionHeading?: string;
  location?: Location;
  locationId?: string;
  locationDisplayName?: string;
  locationDisplayLink?: string;
  description?: string;
  room?: string;
  createdAt: Date;
  updatedAt?: Date;
  enrollmentCode?: string;
  sessionState: any;
  googleClassroom?: any;
  status: SessionStatus;
  publishedAt?: Date;
  schedule?: SessionSchedule;
  currency?: string;
  imageFullUrl?: string;
  timeZone: string;
  organizationName: string;
  type: ActivityTypes;
  durationType: DurationType;
  timeOfDay?: string;
}

export interface SessionWithNameAndSelection {
  _id: string;
  name: string;
  isSelected: boolean;
}

export enum SessionExportOption {
  sessions = 'sessions',
  sessionsWithStudents = 'sessionsWithStudents',
  sessionsWithStudentsAndCustomers = 'sessionsWithStudentsAndCustomers'
}

export const getSessionLocationLabelByValue = (value: LocationType) => {
  const result = locationOptions.find((item) => item.value === value);
  if (result) {
    return result.label;
  }
  return value;
};

export const sessionLocationFiltersOptions = locationOptions.map(
  (option) => new DisplayOption(option.value, option.label)
);

export const locationWithoutPublicFiltersOptions = locationWithoutPublicOptions.map(
  (option) => new DisplayOption(option.value, option.label)
);

export enum SessionStatus {
  Private = 'private',
  Published = 'published'
}

export const getSessionStatusByValue = (sessionStatusText: string): SessionStatus => {
  const foundStatus = Object.keys(SessionStatus).find((key) => SessionStatus[key] === sessionStatusText);
  if (foundStatus) {
    return SessionStatus[foundStatus];
  }
  return SessionStatus.Private;
};

export const mapScheduleInstancesToInstancesIds = (scheduleInstances: ScheduleInstance[]) => {
  return scheduleInstances.map((scheduleInstance) => scheduleInstance._id);
};

export const sortScheduleInstances = (scheduleInstances: ScheduleInstance[]) => {
  return scheduleInstances.slice().sort((a, b) => {
    const aStartDateDateTime = new Date(a.startDateTime);
    const bStartDateDateTime = new Date(b.startDateTime);
    if (aStartDateDateTime.getTime() > bStartDateDateTime.getTime()) return 1;
    if (aStartDateDateTime.getTime() < bStartDateDateTime.getTime()) return -1;
    return 0;
  });
};

export const sortSessionOptions = [
  {
    value: 'startingSoon.asc',
    displayText: 'Starting soon'
  },
  {
    value: 'rating.desc',
    displayText: 'Highest rated'
  },
  {
    value: 'price.asc',
    displayText: 'Price (from lowest)'
  },
  {
    value: 'price.desc',
    displayText: 'Price (from highest)'
  },
  {
    value: 'studentLevel.fromAge.asc',
    displayText: 'Grade (from lowest)'
  },
  {
    value: 'studentLevel.fromAge.desc',
    displayText: 'Grade (from highest)'
  }
];

export const getLastActiveSessionByGradeSortValue = (gradeSortValue: number, sessions: Session[]): Session | null => {
  const sessionsWithSelectedGrade = sessions.filter((session) => {
    const studentLevel = session.studentLevel;

    let startGrade;
    let endGrade;
    if (studentLevel.type === AgeStudentLevelTypeValue) {
      startGrade = mapAgeToSchoolGrade(studentLevel.fromAge);
      endGrade = mapAgeToSchoolGrade(studentLevel.toAge);
    }
    if (studentLevel.type === SchoolGradeStudentLevelTypeValue) {
      startGrade = getGradeByNumericValue(studentLevel.fromSchoolGradeNumeric);
      endGrade = getGradeByNumericValue(studentLevel.toSchoolGradeNumeric);
    }

    return gradeSortValue >= startGrade.sortValue && gradeSortValue <= endGrade.sortValue;
  });

  if (sessionsWithSelectedGrade?.length > 0) {
    const sessionWithGradeSortedByStartTime = sessionsWithSelectedGrade.sort(
      (session) =>
        -1 *
        (session?.schedule?.startDateTime ? new Date(session.schedule.startDateTime).getTime() : new Date().getTime())
    );
    return sessionWithGradeSortedByStartTime[0];
  }
  return null;
};
