interface Currency {
  country: string;
  currencyCode: string;
  displayText: string;
}
export enum CurrencyCode {
  CAD = 'CAD',
  USD = 'USD',
  AUD = 'AUD',
  GBP = 'GBP',
  EUR = 'EUR',
  INR = 'INR',
  EGP = 'EGP'
}

export const USD: Currency = {
  country: 'United States of America',
  currencyCode: CurrencyCode.USD,
  displayText: '$'
};

export const CAD: Currency = {
  country: 'Canada',
  currencyCode: CurrencyCode.CAD,
  displayText: 'CA$'
};

export const AUD: Currency = {
  country: 'Australia',
  currencyCode: CurrencyCode.AUD,
  displayText: 'AU$'
};

export const GBP: Currency = {
  country: 'United Kingdom',
  currencyCode: CurrencyCode.GBP,
  displayText: '£'
};

export const EUR: Currency = {
  country: 'European Union',
  currencyCode: CurrencyCode.EUR,
  displayText: '€'
};

export const INR: Currency = {
  country: 'India',
  currencyCode: CurrencyCode.INR,
  displayText: '₹'
};

export const EGP: Currency = {
  country: 'Egypt',
  currencyCode: CurrencyCode.EGP,
  displayText: 'E£'
};

export const currencies = [USD, CAD, AUD, GBP, EUR, INR, EGP];

export const getCurrencyByCode = (currencyCode: CurrencyCode) => {
  const foundCurrencies = currencies.filter((currency) => currency.currencyCode === currencyCode);
  if (foundCurrencies && foundCurrencies.length > 0) {
    return foundCurrencies[0];
  } else {
    return USD;
  }
};

export const getCurrencyByStringCode = (currencyCode: string) => {
  const foundCurrency = currencies.find((currency) => currency.currencyCode === currencyCode);
  if (foundCurrency) {
    return foundCurrency;
  } else {
    return USD;
  }
};

export const isCurrencyCode = (currencyCode: string) => {
  const foundCurrencies = currencies.filter((currency) => currency.currencyCode === currencyCode);
  return foundCurrencies && foundCurrencies.length > 0;
};

export const getCurrencyDisplayByStringCode = (currencyCode: CurrencyCode) => {
  return getCurrencyByStringCode(currencyCode).displayText;
};

interface MoneyWithCurrencyCodeFormatterOptions {
  trimTrailingZeroCents: boolean;
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
export const moneyWithCurrencyCodeFormatter = (
  amountInCents = 0,
  currencyCode = CurrencyCode.USD,
  options: MoneyWithCurrencyCodeFormatterOptions = { trimTrailingZeroCents: false }
) => {
  const nonZeroAmount = Math.abs(amountInCents) < 0.005 ? 0 : amountInCents;
  const decimalAmount = nonZeroAmount / 100;

  const formatOptions = {
    currency: getCurrencyByCode(currencyCode).currencyCode,
    //currencyDisplay: 'narrowSymbol', // TODO - this options allow show currency symbol
    style: 'currency',
    maximumFractionDigits: 2
  } as Intl.NumberFormatOptions;

  if (options.trimTrailingZeroCents === true && (decimalAmount === 0 || decimalAmount % 1 === 0)) {
    formatOptions.maximumFractionDigits = 0;
    // to avoid MaximumFractionDigits value out of range
    formatOptions.minimumFractionDigits = 0;
  }

  return new Intl.NumberFormat('en-US', formatOptions).format(decimalAmount);
};
