import { CustomerInfo } from './CustomerInfo';
import React, { FC, useEffect } from 'react';
import { mapSchoolGradeToAge, Messages } from '@efacity/common';
import { Box, Grid } from '@mui/material';
import { FormGroupDivider, apiService, SubmitMediumButton } from '@efacity/frontend-shared';
import { studentFormValidationSchema } from '../../pages/CustomerStudents/StudentFormValidationSchema';
import { StudentFormValues } from '../../pages/CustomerStudents/StudentFormValues';
import { useStudentFormValues } from './useStudentFormState';
import { useSnackbar } from 'notistack';
import EmailAddress from '../EmailAddress/EmailAddress';
import { FormProvider, useForm } from 'react-hook-form';
import {
  addServerErrors,
  AgeSelector,
  defaultRHFSetValueOptions,
  FormGradeSelector,
  FormPhoneInput,
  FormTextInput,
  PronounSelector,
  TShirtSizeSelector
} from '@efacity/react-hook-form-mui';
import { yupResolver } from '@hookform/resolvers/yup';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

interface EditStudentCustomerFormProps {
  orgId: string;
  studentId: string;
  allowFullEditStudent?: boolean;
  onClose: (reloadData: boolean) => void;
}

export const EditStudentWithCustomerInfoForm: FC<EditStudentCustomerFormProps> = ({
  allowFullEditStudent,
  orgId,
  studentId,
  onClose
}) => {
  const snackbarProps = useSnackbar();
  const { studentFormState } = useStudentFormValues(orgId, studentId);

  const methods = useForm<StudentFormValues>({
    defaultValues: studentFormState.studentFormValues,
    resolver: yupResolver(studentFormValidationSchema(studentFormState.requireStudentAge)),
    mode: 'onBlur'
  });
  const { isValid, isDirty, isSubmitting } = methods.formState;

  useEffect(
    () => {
      methods.reset(studentFormState.studentFormValues);
    } /* eslint-disable-next-line react-hooks/exhaustive-deps */,
    [studentFormState.isLoading]
  );

  const handleSubmit = async (formValues: StudentFormValues) => {
    const patchURL = allowFullEditStudent
      ? `/org/${orgId}/users/students/${studentId}`
      : `/org/${orgId}/users/students/${studentId}/studentId`;
    try {
      await apiService.patch<string>(patchURL, formValues);
      onClose(true);
    } catch (errorResponse) {
      if (errorResponse.validationErrors) {
        addServerErrors<StudentFormValues>(errorResponse.validationErrors, methods.setError);
      } else {
        showNotification(snackbarProps, false, errorResponse.message || Messages.FailedToUpdateStudent, true);
      }
    }
  };

  const onGradeChanged = (gradeSortValue: number) => {
    methods.setValue('age', mapSchoolGradeToAge(gradeSortValue), defaultRHFSetValueOptions);
  };

  useEffect(() => {
    methods.trigger();
  }, [methods, studentFormState.studentFormValues]);

  return (
    <>
      <FormProvider {...methods}>
        <form noValidate onSubmit={methods.handleSubmit(handleSubmit)}>
          <div style={{ marginTop: 0, marginBottom: 10, fontSize: 18 }}>
            <EmailAddress email={studentFormState.studentFormValues.email} />
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormTextInput
                name="fullName"
                label="Full Name"
                disabled={isSubmitting || !allowFullEditStudent}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextInput name="email" label="E-mail" disabled={isSubmitting || !allowFullEditStudent} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormPhoneInput name="phone" label="Phone" validateOnStart={false} />
            </Grid>
            <Grid item xs={12}>
              <FormTextInput name="studentId" label="Student ID" disabled={isSubmitting} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <PronounSelector
                name="preferredPronoun"
                label="Preferred Pronoun"
                inputProps={{ disabled: isSubmitting || !allowFullEditStudent }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGradeSelector
                name="gradeInSeptemberNumeric"
                label={`Grade starts in September ${new Date().getUTCFullYear()}`}
                disabled={isSubmitting || !allowFullEditStudent}
                onGradeChanged={onGradeChanged}
              />
            </Grid>
            {studentFormState.requireStudentAge && (
              <Grid item xs={12} sm={6} md={4}>
                <AgeSelector name="age" label="Age *" disabled={isSubmitting} />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <TShirtSizeSelector
                name="tShirtSize"
                label="T-Shirt Size"
                disabled={isSubmitting || !allowFullEditStudent}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextInput
                name="medicalInformation"
                label="Medical Information"
                disabled={isSubmitting || !allowFullEditStudent}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextInput
                name="emergencyInstructions"
                label="Emergency Instructions"
                disabled={isSubmitting || !allowFullEditStudent}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" style={{ marginTop: 5 }}>
            <SubmitMediumButton disabled={!isDirty || !isValid} isSubmitting={isSubmitting}>
              Save
            </SubmitMediumButton>
          </Box>
        </form>
      </FormProvider>
      <FormGroupDivider marginY={20} />
      {studentFormState.customers?.length > 0 &&
        studentFormState.customers.map((customer, index) => {
          return <CustomerInfo customer={customer} key={`editStudentModalCustomer${index}Info`} />;
        })}
    </>
  );
};
