import React from 'react';
import { NavLink } from 'react-router-dom';
import { customTheme } from '@efacity/frontend-shared';
import { Box } from '@mui/material';

const tabContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1
} as React.CSSProperties;
const activeTabStyle = {
  fontSize: 18,
  color: customTheme.palette.primary.dark,
  position: 'relative',
  textTransform: 'uppercase',
  borderBottom: '4px solid',
  borderColor: customTheme.palette.primary.light
} as React.CSSProperties;
const tabStyle = {
  fontSize: 18,
  textTransform: 'uppercase',
  color: '#C4C4C4'
} as React.CSSProperties;

const hoverSx = {
  '&:hover': {
    color: 'primary.light'
  },
  fontSize: {
    lg: 18,
    md: 18,
    sm: 16,
    xs: 16
  }
};

export const AuthTabs: React.FC = () => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={tabContainerStyle}>
        <NavLink
          data-testid="signup-link"
          to="/auth/sign-up"
          style={({ isActive }) => (isActive ? activeTabStyle : tabStyle)}
        >
          <Box sx={hoverSx}>Sign Up</Box>
        </NavLink>
      </div>
      <div style={tabContainerStyle}>
        <NavLink
          data-testid="signin-link"
          to="/auth/sign-in"
          style={({ isActive }) => (isActive ? activeTabStyle : tabStyle)}
        >
          <Box sx={hoverSx}>Log In</Box>
        </NavLink>
      </div>
    </div>
  );
};
