import { Types } from 'mongoose';
import { CurrencyCode, moneyWithCurrencyCodeFormatter } from './Currencies';
import { Media } from './media';
import { Descendant } from 'slate';

export interface JobPostingInterface {
  _id: string | Types.ObjectId;
  // Prisma map _id to id
  id?: string;
  orgId: any;

  title: string;
  description: string | Descendant[];

  locationType: JobLocationType;
  city: string;
  state: string;
  country: string;

  jobType: JobType;
  jobSchedule: JobSchedule;
  jobOpenPositions: JobOpenPositions;
  jobPayBy: JobPayBy;
  currency: CurrencyCode;
  minimumRange: number;
  maximumRange: number;
  jobRate: JobRate;

  media?: string[] | Media[];

  expiresOn: Date | string;
  timeZone: string;

  createdBy?: any;
  createdAt?: Date | string;

  deletedBy?: any;
  deletedAt?: Date | string;
}

export const jobPostingAddress = (job: JobPostingInterface): string => {
  return `${job.city}, ${job.state}, ${job.country}`;
};

// Remote, In-person, Hybrid
export enum JobLocationType {
  Remote = 'remote',
  InPerson = 'inPerson',
  Hybrid = 'hybrid'
}
export const jobLocationTypeViewConnector = {
  [JobLocationType.Remote]: 'Remote',
  [JobLocationType.InPerson]: 'In-Person',
  [JobLocationType.Hybrid]: 'Hybrid'
};
export const jobLocationTypeSelectOptions = Object.entries(jobLocationTypeViewConnector).map(([value, label]) => {
  return {
    value,
    label
  };
});

// Full-time, Part-time, Contract, Temporary, Internship
export enum JobType {
  PartTime = 'partTime',
  FullTime = 'fullTime',
  Contract = 'contract',
  Temporary = 'temporary',
  Internship = 'internship'
}
export const jobTypesViewConnector = {
  [JobType.PartTime]: 'Part-time',
  [JobType.FullTime]: 'Full-time',
  [JobType.Contract]: 'Contract',
  [JobType.Temporary]: 'Temporary',
  [JobType.Internship]: 'Internship'
};
export const jobTypeSelectOptions = Object.entries(jobTypesViewConnector).map(([value, label]) => {
  return {
    value,
    label
  };
});

// After school, Monday to Friday, Weekend availability, Day shift, 4-hour shift, 8-hour shift, 10-hour shift
export enum JobSchedule {
  AfterSchool = 'afterSchool',
  MondayToFriday = 'mondayToFriday',
  WeekendAvailability = 'weekendAvailability',
  DayShift = 'DayShift',
  FourHourShift = 'fourHourShift',
  EightHourShift = 'eightHourShift',
  TenHourShift = 'tenHourShift'
}
export const jobScheduleViewConnector = {
  [JobSchedule.AfterSchool]: 'After School',
  [JobSchedule.MondayToFriday]: 'Monday to Friday',
  [JobSchedule.WeekendAvailability]: 'Weekend availability',
  [JobSchedule.DayShift]: 'Day Shift',
  [JobSchedule.FourHourShift]: '4-hour Shift',
  [JobSchedule.EightHourShift]: '8-hour Shift',
  [JobSchedule.TenHourShift]: '10-hour shift'
};
export const jobScheduleSelectOptions = Object.entries(jobScheduleViewConnector).map(([value, label]) => {
  return {
    value,
    label
  };
});

//  1, 2, 3, 4, 5, Ongoing
export enum JobOpenPositions {
  One = 'one',
  Two = 'two',
  Three = 'three',
  Four = 'four',
  Five = 'five',
  Ongoing = 'ongoing'
}
export const JobOpenPositionsViewConnector = {
  [JobOpenPositions.One]: '1',
  [JobOpenPositions.Two]: '2',
  [JobOpenPositions.Three]: '3',
  [JobOpenPositions.Four]: '4',
  [JobOpenPositions.Five]: '5',
  [JobOpenPositions.Ongoing]: 'Ongoing'
};
export const JobOpenPositionsSelectOptions = Object.entries(JobOpenPositionsViewConnector).map(([value, label]) => {
  return {
    value,
    label
  };
});

export enum JobPayBy {
  Range = 'range',
  Amount = 'amount'
}
export const jobPayByViewConnector = {
  [JobPayBy.Range]: 'Range',
  [JobPayBy.Amount]: 'Amount'
};
export const jobPayBySelectOptions = Object.entries(jobPayByViewConnector).map(([value, label]) => {
  return {
    value,
    label
  };
});
export const jobPayString = (jobPosting: JobPostingInterface): string => {
  return jobPosting.jobPayBy === JobPayBy.Amount
    ? `${moneyWithCurrencyCodeFormatter(jobPosting.maximumRange || 0, jobPosting.currency || CurrencyCode.USD)} ${
        jobRateViewConnector[jobPosting.jobRate]
      }`
    : `${moneyWithCurrencyCodeFormatter(
        jobPosting.minimumRange || 0,
        jobPosting.currency || CurrencyCode.USD
      )}  - ${moneyWithCurrencyCodeFormatter(jobPosting.maximumRange || 0, jobPosting.currency || CurrencyCode.USD)} ${
        jobRateViewConnector[jobPosting.jobRate]
      }`;
};

// per hour, per day, per week, per month, per year
export enum JobRate {
  PerHour = 'perHour',
  PerDay = 'perDay',
  PerWeek = 'perWeek',
  PerMonth = 'perMonth',
  PerYear = 'perYear'
}
export const jobRateViewConnector = {
  [JobRate.PerHour]: 'Per Hour',
  [JobRate.PerDay]: 'Per Day',
  [JobRate.PerWeek]: 'Per Week',
  [JobRate.PerMonth]: 'Per Month',
  [JobRate.PerYear]: 'Per Year'
};
export const jobRateSelectOptions = Object.entries(jobRateViewConnector).map(([value, label]) => {
  return {
    value,
    label
  };
});

export const sanitizeJobPayBy = (job: Partial<JobPostingInterface>) => {
  if (job.jobPayBy === JobPayBy.Amount) {
    job.maximumRange = job.minimumRange;
  }
  return job;
};
