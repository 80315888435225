import { Box, Button, type SxProps, type Theme, Typography } from '@mui/material';
import Link from 'next/link';
import { AddCircleOutlined } from '@mui/icons-material';
import { type ReactElement, type ReactNode } from 'react';

interface PageTitleProps {
  title: string | ReactElement;
  addButtonLink?: string;
  dataTestId?: string;
  button?: ReactNode;
  underlined?: boolean;
  titleSx?: SxProps<Theme>;
}
export const PageTitle = ({
  title,
  addButtonLink,
  dataTestId,
  button,
  underlined = false,
  titleSx = {}
}: PageTitleProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 'bold',
        marginTop: '10px',
        marginBottom: '10px',
        borderBottom: underlined ? '1px solid #C4C4C4' : ''
      }}
      data-testid={dataTestId}
    >
      <Typography variant="h1" sx={{ fontSize: 30, fontWeight: 'bold', ...titleSx }} data-testid="title-text">
        {title}
      </Typography>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {button}
        {addButtonLink && (
          <Button
            variant="contained"
            component={Link}
            aria-label="title-new-button"
            data-testid="addBtn"
            startIcon={<AddCircleOutlined style={{ color: 'white', marginRight: 5 }} />}
            href={addButtonLink}
          >
            New
          </Button>
        )}
      </div>
    </Box>
  );
};
