import { Gender } from './Gender';
import { Relationship } from './Relationship';
import { CountryCode } from './Countries';
import { Honorific } from './Honorifics';
import { SchoolType } from './SchoolType';
import { CreatePaymentMethodData } from '@stripe/stripe-js';
import { racesOptions } from './Race';

export interface PaymentDetails {
  paymentMethodId?: string;
  paymentIntent?: string;
  last4?: string | undefined;
  brand?: string | undefined;
  address?: CreatePaymentMethodData['billing_details']['address'];
  isExistingCard?: boolean;
}

export interface DetailedAddress {
  country: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export interface Parent {
  honorific: Honorific | '';
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phoneCountry: string;
  gender: Gender | '';
  relationship: Relationship | '';
  professionalBackground?: string;
  employer?: string;
  custody: string;
  address?: DetailedAddress;
  isStudentsAddress: boolean;
}

export interface EnrollmentFormValues {
  customer: Parent & {
    signature: string;
  };
  secondParent?: Parent;
  student: {
    firstName: string;
    middleName: string;
    lastName: string;
    suffix: string;
    gender: Gender | '';
    dateOfBirth: Date;
    race: string[];
    address: DetailedAddress;
    homeLanguage?: string;
    currentSchool: {
      schoolType?: string;
      name: string;
      state: string;
      enrolledFrom: Date;
    };
    grade?: number | string;
    specialAptitudes: string;
    weaknesses: string;
    hobby?: string;
    correction: string;
    schoolCollaboration: string;
    specialSupports: string;
    specialConcerns?: string;
    plan504: boolean;
    medicalInformation?: string;
    emergencyInstructions?: string;
  };
  currentYearReportCard: (string | File)[];
  iqTest: (string | File)[];
  teacherRecommendations: (string | File)[];
  optionalSupplemental: (string | File)[];
  payment?: {
    name: string;
    address: {
      line1: string;
      city: string;
      state: string;
      postal_code: string;
      country: string;
    };
    userPaymentMethodId?: string;
  };
  paymentDetails?: PaymentDetails;
  signedAgreements: { agreementId: string; isSigned: boolean }[];
}

const defaultAddressValues: DetailedAddress = {
  country: CountryCode.USA,
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: ''
};

export const defaultParentValues: Parent = {
  honorific: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  phoneCountry: CountryCode.USA,
  gender: '',
  relationship: '',
  professionalBackground: '',
  employer: '',
  custody: '',
  address: defaultAddressValues,
  isStudentsAddress: true
};

export const enrollmentFormInitialValues: EnrollmentFormValues = {
  customer: {
    ...defaultParentValues,
    signature: ''
  },
  secondParent: null,
  student: {
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    gender: '',
    dateOfBirth: null,
    race: [racesOptions[7]],
    address: defaultAddressValues,
    homeLanguage: '',
    currentSchool: {
      schoolType: SchoolType.PublicSchool,
      name: '',
      state: '',
      enrolledFrom: null
    },
    grade: '',
    specialAptitudes: '',
    weaknesses: '',
    hobby: '',
    correction: '',
    schoolCollaboration: '',
    specialSupports: '',
    specialConcerns: '',
    plan504: false,
    medicalInformation: '',
    emergencyInstructions: ''
  },
  currentYearReportCard: [],
  iqTest: [],
  teacherRecommendations: [],
  optionalSupplemental: [],
  payment: {
    name: '',
    address: {
      line1: '',
      city: '',
      state: '',
      postal_code: '',
      country: CountryCode.USA
    },
    userPaymentMethodId: ''
  },
  signedAgreements: []
};
