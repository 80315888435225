const sessionFormSx = {
  newTeacherBtn: {
    minWidth: '185px',
    marginBottom: '8px',
    width: {
      xs: '100%',
      sm: 'auto'
    },
    whiteSpace: 'nowrap'
  },
  capacityHint: {
    '& p': {
      position: 'inherit',
      whiteSpace: 'pre-wrap',
      margin: 0,
      bottom: 0
    }
  }
};
export default sessionFormSx;
