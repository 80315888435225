import React, { useCallback, useMemo, useState } from 'react';
import { adminSectionTableFontSize, apiService, Product } from '@efacity/frontend-shared';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { NavLink, useParams } from 'react-router-dom';
import Table, {
  EnhancedColumn,
  FetchDataOptions,
  initialFetchDataOptions,
  PaginationTypes
} from '../../components/Table/Table';
import {
  convertUtcToZonedTime,
  jobLocationTypeViewConnector,
  jobTypesViewConnector,
  Messages,
  nowUTC,
  formatDateToMonthDayYearInLocale
} from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import { Box, IconButton, Link } from '@mui/material';
import { useSnackbar } from 'notistack';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import { isBefore } from 'date-fns';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import JobPostingLinkWithCopier from '../../components/JobPostingLinkWithCopier/JobPostingLinkWithCopier';
import JobPostingsLinkWithCopier from '../../components/JobPostingsLinkWithCopier/JobPostingsLinkWithCopier';
import DateColumnFilter from '../../components/Table/filters/DateColumnFilter';
import removeDateOffsetFromFilters from '../../utils/removeDateOffsetFromFilters';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';
import { cloneDeep } from 'lodash';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { PageTitle } from '@efacity/react-next-sc';

const JobPostingsContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const snackbarProps = useSnackbar();

  const [fetchDataOptions, setFetchDataOptions] = useState<FetchDataOptions>(initialFetchDataOptions);

  const [jobsData, setJobsData] = useState({
    data: [],
    total: 0,
    isLoading: false
  });

  const getJobs = useCallback(async (fetchDataOptions: FetchDataOptions) => {
    try {
      // clone options, otherwise timeshifting will change it
      const clonedFetchDataOptions = cloneDeep(fetchDataOptions);
      setFetchDataOptions(clonedFetchDataOptions);
      const { filters, pageIndex, pageSize, sortBy } = clonedFetchDataOptions;
      const dateFilters = ['expiresOn'];
      removeDateOffsetFromFilters(filters, dateFilters);
      const { data } = await apiService.get<{ data: Product[]; total: number }>(`/org/${orgId}/jobs`, {
        page: pageIndex,
        perPage: pageSize,
        ...getFiltersQueryParameters(filters),
        sortBy: mapReactTableSortToApiSort(sortBy || [])
      });
      setJobsData((state) => ({ data: data.data, total: data.total, isLoading: false }));
    } catch (error) {
      setJobsData((state) => ({ ...state, isLoading: false }));
      showNotification(snackbarProps, false, error.response?.data?.message || Messages.FailedGetJobPostings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteKit = (jobId: string) => {
    return apiService
      .delete(`/org/${orgId}/job/${jobId}`)
      .then(() => {
        showNotification(snackbarProps, true, Messages.JobDeleted);
        getJobs(fetchDataOptions);
      })
      .catch((error) => {
        showNotification(snackbarProps, false, error.response.data.message || Messages.FailedToDeleteJob, true);
      });
  };

  const columns = useMemo(() => {
    const columns: EnhancedColumn<any>[] = [
      {
        Header: 'Title',
        accessor: 'title',
        disableSortBy: false,
        Cell: ({ row, value }) => {
          return (
            <Box display="flex" alignItems="center" data-testid="job-posting-name-copier-cell">
              <Link
                component={NavLink}
                to={toPath(PATHS.editJob, {
                  orgId: orgId,
                  jobId: row.original._id
                })}
                underline={'none'}
              >
                {value}
              </Link>
              &nbsp;&nbsp;
              <JobPostingLinkWithCopier orgId={orgId} jobPostingId={row.original._id} />
            </Box>
          );
        }
      },
      {
        Header: 'Location Type',
        accessor: 'locationType',
        Cell: ({ value }) => jobLocationTypeViewConnector[value],
        disableSortBy: true,
        disableFilters: true
      },
      {
        Header: 'Type',
        accessor: 'jobType',
        Cell: ({ value }) => jobTypesViewConnector[value],
        disableSortBy: true,
        disableFilters: true
      },
      {
        Header: 'Expires On',
        accessor: 'expiresOn',
        disableSortBy: false,
        Cell: ({ value, row }) => (
          <>{formatDateToMonthDayYearInLocale(convertUtcToZonedTime(new Date(value), row.original.timeZone))}</>
        ),
        Filter: DateColumnFilter
      },
      {
        Header: '',
        id: 'actions-cell',
        disableSortBy: true,
        disableFilters: true,
        columnStyles: { ...adminSectionTableFontSize, width: 75, maxWidth: 75 },
        Cell: ({ row }) => {
          return (
            <IconButton
              color="primary"
              aria-label="delete product"
              data-testid="delete-product-btn"
              component="span"
              style={smallPublishButtonStyle}
              disabled={isBefore(new Date(row.original.expiresOn), new Date(nowUTC()))}
              onClick={() => handleDeleteKit(row.original._id)}
            >
              <DeleteIcon />
            </IconButton>
          );
        }
      }
    ];

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  return (
    <div>
      <PageTitle
        title="Job Posting"
        addButtonLink={`/admin/${orgId}/job`}
        button={
          <div style={{ marginTop: 5, marginRight: 15 }}>
            <JobPostingsLinkWithCopier orgId={orgId} />
          </div>
        }
      />
      <Table
        columns={columns}
        data={jobsData.data}
        fetchData={getJobs}
        loading={jobsData.isLoading}
        paginationType={PaginationTypes.ServerSide}
        pageCount={jobsData.total}
        noDataText="No job postings..."
      />
    </div>
  );
};

export default JobPostingsContainer;
