import { Roles, getLoggedInUserHomePagePathIfAny, hasAnyOfRoles } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import {
  REDIRECT_ITEM_NAME,
  SignInFormValues,
  getShoppingProgress,
  setKitPurchaseProgress,
  setRegistrationProgress,
  useAuth,
  useLocalStorage
} from '@efacity/react-next-sc';
import { PATHS, shouldUseReactRouter } from '@efacity/routing';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SignInForm, ButtonWithIcon } from '@efacity/frontend-shared';

interface SignInPageProps {
  signInUrl: string;
}

export const SignInPage: FC<SignInPageProps> = ({ signInUrl }) => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const snackbarProps = useSnackbar();
  const { search } = useLocation();
  const { getItem, removeItem } = useLocalStorage();
  const { email } = queryString.parse(search);
  document.title = 'Log In | Efacity';
  const onSignIn = async (formValues: SignInFormValues, resetForm: () => void) => {
    const { loginSuccess, roles, access, error, userId } = await signIn(formValues);

    if (loginSuccess && roles) {
      resetForm();

      const { sessionRegistrationInProgress, kitPurchaseInProgress } = getShoppingProgress();
      if (hasAnyOfRoles(roles, [Roles.Customer]) && sessionRegistrationInProgress) {
        removeItem(REDIRECT_ITEM_NAME);
        return navigate(PATHS.selectStudentsForSessions);
      }
      if (hasAnyOfRoles(roles, [Roles.Customer]) && kitPurchaseInProgress) {
        removeItem(REDIRECT_ITEM_NAME);
        return navigate(PATHS.cart);
      }

      // If user is redirected to login from a page, then redirect to that page
      const fromRedirect = getItem(REDIRECT_ITEM_NAME);
      if (fromRedirect) {
        removeItem(REDIRECT_ITEM_NAME);
        return (window.location.href = fromRedirect);
      }

      setRegistrationProgress(false);
      setKitPurchaseProgress(false);
      const loggedInUserHomePage = getLoggedInUserHomePagePathIfAny(access, userId);
      const useReactRouter = shouldUseReactRouter(loggedInUserHomePage);
      if (useReactRouter) {
        navigate(loggedInUserHomePage);
      } else {
        window.location.href = loggedInUserHomePage;
      }
    }
    if (error) {
      showNotification(snackbarProps, false, error, true);
    }
  };

  return (
    <>
      <br />
      <ButtonWithIcon
        href={signInUrl}
        icon={<img src="/assets/images/GoogleLogo_30_30.svg" alt="Google logo icon" />}
        fullWidth
      >
        Log in with Google
      </ButtonWithIcon>
      <hr style={{ width: '100%', marginTop: 20 }} />
      <div style={{ textAlign: 'center' }}>Or</div>
      <SignInForm onSignIn={onSignIn} email={email as string} />
    </>
  );
};
