import React, { FC, useMemo } from 'react';
import { requiredPhoneValidator, Teacher } from '@efacity/frontend-shared';
import { FormCancelSubmitButtons } from '@efacity/frontend-next-shared/forms';
import { CountryCode, FormMode, Messages } from '@efacity/common';
import * as yup from 'yup';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormPhoneInput, FormTextInput } from '@efacity/react-hook-form-mui';

const instructorValidationSchema = yup.object({
  email: yup.string().required(Messages.EmailRequired),
  fullName: yup.string().required(Messages.NameRequired),
  defaultPhone: requiredPhoneValidator('defaultPhone'),
  defaultPhoneCountry: yup.string().required()
});

interface InstructorFormProps {
  onSubmit: (
    values: InstructorFormValues,
    setValue: (fieldName: keyof InstructorFormValues, value: any) => void,
    setError: (fieldName: keyof InstructorFormValues, value: any) => void
  ) => void;
  onClose: () => void;
  instructorOptions: Teacher[];
  addingTeacher: boolean;
}

export interface InstructorFormValues {
  fullName: string;
  email: string;
  defaultPhone: string;
  defaultPhoneCountry: CountryCode;
  forceAddTeacher: boolean;
}

export const defaultInstructorFormValues: InstructorFormValues = {
  email: '',
  fullName: '',
  defaultPhone: '',
  defaultPhoneCountry: CountryCode.USA,
  forceAddTeacher: false
};

const InstructorForm: FC<InstructorFormProps> = ({ onSubmit, onClose, instructorOptions, addingTeacher }) => {
  /* This will be updated when we are in edit mode */
  const initialValues = useMemo<InstructorFormValues>(() => {
    return defaultInstructorFormValues;
  }, []);

  const methods = useForm<InstructorFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(instructorValidationSchema),
    defaultValues: initialValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUseNativeValidation: false
  });
  const { isSubmitting, isValid } = methods.formState;

  const handleInstructorFormSubmit = () => {
    onSubmit(methods.getValues(), methods.setValue, methods.setError);
  };

  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete="off">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormTextInput name="fullName" id="fullName" label="Full Name" required />
          </Grid>
          <Grid item xs={12}>
            <FormTextInput
              name="email"
              id="email"
              label="Email"
              required
              onChange={(event) => {
                if (
                  instructorOptions.findIndex((instructorOption) => instructorOption.email === event.target.value) > -1
                ) {
                  methods.setValue('forceAddTeacher', true);
                } else {
                  methods.setValue('forceAddTeacher', false);
                }
                methods.setValue('email', event.target.value);
              }}
              data-testid="instructor-email"
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 5 }}>
            <FormPhoneInput name="defaultPhone" required={true} />
          </Grid>
          {methods.watch('forceAddTeacher') && (
            <p style={{ color: '#f44336', fontSize: 16, marginTop: 10, marginBottom: 20 }}>
              {Messages.UserExistsShouldAddTeacher}
            </p>
          )}
        </Grid>

        <FormCancelSubmitButtons
          mode={FormMode.Add}
          onCancel={onClose}
          isFormValid={isValid}
          isSubmitting={isSubmitting || addingTeacher}
          submitBtnType="button"
          onSubmit={handleInstructorFormSubmit}
        />
      </form>
    </FormProvider>
  );
};

export default InstructorForm;
