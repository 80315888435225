import { Controller, useFormContext } from 'react-hook-form';
import React, { FC, useEffect } from 'react';
import { TextFieldProps } from '@mui/material';
import { getCountryCallingCode } from 'react-phone-number-input';
import { CountryCode } from 'libphonenumber-js';
import PhoneInput from 'react-phone-number-input/input';
import { PhoneInputComponent } from './PhoneInputComponent';
import { getNestedValue } from '@efacity/common';
import { textFieldSx } from './fomTextInputStyle';
import { defaultRHFSetValueOptions } from './FormTextInput';

export type FormPhoneInputProps = {
  name: string;
  label?: string;
  validateOnStart?: boolean;
  allowChangeCountry?: boolean;
} & TextFieldProps;

export const FormPhoneInput: FC<FormPhoneInputProps> = ({
  name,
  label,
  validateOnStart = true,
  allowChangeCountry = true,
  ...props
}) => {
  const countryFieldName = `${name}Country`;
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    trigger
  } = useFormContext();
  useEffect(() => {
    const validatePhoneNumber = async () => {
      await trigger(name);
    };
    if (validateOnStart) validatePhoneNumber();
  }, [name, trigger, validateOnStart]);

  const handleCountryChange = async (newCountry: string) => {
    const oldCountry = getValues(countryFieldName);
    const oldCountryCode = getCountryCallingCode(oldCountry);
    const newCountryCode = getCountryCallingCode(newCountry as CountryCode);

    const oldPhoneValue = getValues(name);
    const newPhoneValue = oldPhoneValue.replace(oldCountryCode, newCountryCode);
    setValue(name, newPhoneValue, defaultRHFSetValueOptions);
    setValue(countryFieldName, newCountry, defaultRHFSetValueOptions);
    await trigger([name, countryFieldName]);
  };

  const handlePhoneChange = async (phoneNumber: string) => {
    const countryCode = getCountryCallingCode(getValues(countryFieldName));

    // undefined when only country code is left
    const isOnlyCountryCodeLeft = !phoneNumber || phoneNumber === `+${countryCode}`;
    if (isOnlyCountryCodeLeft) {
      setValue(name, '', { shouldDirty: true });
      await trigger(name);
      return;
    }

    setValue(name, phoneNumber, { shouldDirty: true });
    await trigger(name);
  };

  const handlePhoneBlur = async () => {
    await trigger(name); // revalidate and set touched
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (
    <Controller
      control={control}
      name={name}
      render={(phoneField) => (
        <Controller
          control={control}
          name={countryFieldName}
          render={(countryField) => (
            <PhoneInput
              name={name}
              id={name}
              label={label}
              value={phoneField.field.value}
              defaultCountry="US"
              country={countryField.field.value as CountryCode}
              selectedCountry={countryField.field.value as CountryCode}
              withCountryCallingCode
              international
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              inputComponent={PhoneInputComponent}
              isErrorShown={!!getNestedValue(errors, name)}
              errorMessage={(getNestedValue(errors, name)?.message as unknown as string) || ''}
              sx={textFieldSx.textField}
              {...props}
              allowChangeCountry={allowChangeCountry}
              onChange={handlePhoneChange}
              onBlur={handlePhoneBlur}
              onCountryChange={handleCountryChange}
            />
          )}
        />
      )}
    />
  );
};
