import { CurrencyCode, moneyWithCurrencyCodeFormatter } from '@efacity/common';
import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import mobileTableStyle from '../../../stylesheets/mobileTableStyle';
import { ProductInvoiceItemTableRowProps } from './ProductInvoiceItemTableRow';
import DeleteIconButtonWithSpinner from '../../../components/DeleteIconButtonWithSpinner/DeleteIconButtonWithSpinner';

const ProductInvoiceItemMobileTableRow: FC<ProductInvoiceItemTableRowProps> = ({
  invoiceItem,
  handleDeleteProduct,
  isUpdating,
  disabled,
  index
}) => {
  const handleDeleteProductIfAny = () => {
    if (handleDeleteProduct) {
      handleDeleteProduct(invoiceItem.invoiceItemId);
    }
  };

  return (
    <Box sx={mobileTableStyle.rootSx}>
      <Box style={{ ...mobileTableStyle.sessionNameRow, borderBottom: '1px solid #C4C4C4' }}>
        <Box>
          <Typography sx={mobileTableStyle.titleSx}>Kit</Typography>
          <Box fontWeight="bold" sx={mobileTableStyle.titleSx}>
            {invoiceItem.description}
          </Box>
        </Box>
        <Box style={mobileTableStyle.deleteIcon}>
          <DeleteIconButtonWithSpinner
            onClick={handleDeleteProductIfAny}
            isUpdating={isUpdating}
            data-testid={`deleteIcon${index}Mobile`}
            disabled={true}
          />
        </Box>
      </Box>
      <Box style={mobileTableStyle.mobileTwoColumnRow}>
        <Box>
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Description:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>{invoiceItem.description}</Typography>
        </Box>
        <Box>
          <Typography variant="h6" sx={mobileTableStyle.titleSx}>
            Amount:
          </Typography>
          <Typography style={mobileTableStyle.textSm}>
            {moneyWithCurrencyCodeFormatter(invoiceItem.amount, invoiceItem.currencyCode as CurrencyCode)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductInvoiceItemMobileTableRow;
