import React, { useCallback, useEffect } from 'react';
import { Session as EfacitySession } from '@efacity/react-next-sc';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Messages } from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import SelectAdditionalSessionOptions from './SelectAdditionalSessionOptions';
import { OrgIdInvoiceIdParamType } from '@efacity/frontend-next-shared/utils';
import {
  getAllStudentSessionIdsFromInvoiceItems,
  mapInvoiceItemsToStudentSessionOptionId
} from './SelectAdditionalOptionsByCustomer';
import { useInvoicePayment } from '../PaymentContainer/useInvoicePayment';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

const SelectAdditionalOptionsByAdministrator = () => {
  const { orgId, invoiceId } = useParams<OrgIdInvoiceIdParamType>();
  const [{ paymentState, registrationState }, { registrationActions }] = useInvoicePayment(
    `/org/${orgId}/payments/${invoiceId}`
  );
  const { invoice } = paymentState;
  const { sessionsSelectedForRegistration, isLoadingSessions, isRegistering } = registrationState;

  const {
    setRegistrationState,
    querySessionsFromInvoiceToRegisterOn,
    switchAdditionalOptionSelectionToInvoiceRegistration
  } = registrationActions;

  const sessionsWithAdditionalOptions = sessionsSelectedForRegistration.filter(
    (session) => session.additionalSessionOptions && session.additionalSessionOptions.length > 0
  );

  const navigate = useNavigate();
  const snackbarProps = useSnackbar();

  const gotoSessionsPage = useCallback(() => {
    navigate(toPath(PATHS.sessions, { orgId: orgId }));
  }, [navigate, orgId]);

  const handleSelectAdditionalOptionForStudentClicked = async (
    sessionId: string,
    additionalOptionId: string,
    studentId: string,
    isChecked: boolean
  ) => {
    await switchAdditionalOptionSelectionToInvoiceRegistration(
      `/org/${orgId}/registrations/invoice/${invoiceId}/session/${sessionId}/student/${studentId}/additionalOption/${additionalOptionId}/${isChecked}`
    );
  };

  const handleGotoPaymentClicked = async () => {
    navigate(toPath(PATHS.invoice, { orgId: orgId, invoiceId: invoiceId }));
  };

  useEffect(() => {
    const querySessionsAndValidate = async () => {
      const validateIncomingSessionsAndStudent = async (sessions: EfacitySession[]) => {
        if (!(sessions && sessions.length > 0)) {
          setRegistrationState((state) => ({
            ...state,
            registrationInfoModal: {
              ...state.registrationInfoModal,
              isOpen: true,
              message: Messages.ToStartNeedRegistrations,
              onOkClicked: gotoSessionsPage
            }
          }));
        }
      };

      const { isError, sessions, message } = await querySessionsFromInvoiceToRegisterOn(
        `/org/${orgId}/registrations/invoice/${invoiceId}/sessions`
      );
      if (isError) {
        showNotification(snackbarProps, false, message || Messages.FailedGetSessionsRegisterOn, true, 'Ok');
        return gotoSessionsPage();
      }
      await validateIncomingSessionsAndStudent(sessions);
    };
    querySessionsAndValidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gotoSessionsPage, snackbarProps, orgId, invoiceId]);

  const studentSessionOptionIds = mapInvoiceItemsToStudentSessionOptionId(
    invoice?.invoiceItems ? invoice.invoiceItems : []
  );
  const allStudentsForSessionIds = getAllStudentSessionIdsFromInvoiceItems(
    invoice?.invoiceItems ? invoice.invoiceItems : []
  );
  return (
    <SelectAdditionalSessionOptions
      isLoading={isLoadingSessions}
      isRegistering={isRegistering}
      sessionsWithAdditionalOptions={sessionsWithAdditionalOptions}
      allStudentsForSessionIds={allStudentsForSessionIds}
      studentSessionAdditionalOptionIds={studentSessionOptionIds}
      selectAdditionalOptionForStudentClicked={handleSelectAdditionalOptionForStudentClicked}
      selectStudentsClicked={null}
      gotoPaymentClicked={handleGotoPaymentClicked}
      isInvoicePaid={invoice?.isPaid}
      registrationInfoModal={registrationState.registrationInfoModal}
    />
  );
};

export default SelectAdditionalOptionsByAdministrator;
