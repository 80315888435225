import * as yup from 'yup';
import { Messages, mostUsedWeakPasswords } from '@efacity/common';

export const passwordValidator = () =>
  yup
    .string()
    .required(Messages.PasswordRequired)
    .min(8, Messages.PasswordIsTooShort)
    .matches(/^(?=.*?[a-z|A-Z])(?=.*?[0-9]).{8,}$/, Messages.PasswordShouldHaveDigitsAndLetters)
    .test({
      name: 'weak-password',
      message: Messages.PasswordIsWeak,
      test: (value: string) => !mostUsedWeakPasswords.includes(value)
    });
