import { useAuth } from '@efacity/react-next-sc';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CenteredFormContainer } from '@efacity/frontend-next-shared/forms/server';
import CustomerStudentForm from './CustomerStudentForm';
import { studentFormValidationSchema } from './StudentFormValidationSchema';
import { StudentFormValues } from './StudentFormValues';
import {
  getNeedReturnToRegistration,
  getRequireStudentAge,
  removeRequireStudentAge,
  useStudentFormValues
} from './useCustomerStudentsLoader';
import { cloneDeep } from 'lodash';
import { useForm } from 'react-hook-form';
import { FormMode } from '@efacity/common';
import { PATHS } from '@efacity/routing';
import { WithLoading } from '@efacity/frontend-shared';

export interface StudentFormProps {
  mode: FormMode;
}

type ParamTypes = Partial<{
  studentId: string;
}>;

const CustomerStudentContainer: React.FC<StudentFormProps> = ({ mode }) => {
  const { studentId } = useParams<ParamTypes>();
  const {
    authState: {
      user: { _id: customerId }
    }
  } = useAuth();
  const [{ studentState }, { addStudent, updateStudent }] = useStudentFormValues(customerId, studentId, mode);
  const navigate = useNavigate();
  const requireStudentAge = getRequireStudentAge();

  const getStudentAndGotoStudentsPageIfAny = () => {
    removeRequireStudentAge();
    const needReturnToRegistrations = getNeedReturnToRegistration();
    if (needReturnToRegistrations) {
      navigate(PATHS.selectStudentsForSessions);
    } else {
      navigate(PATHS.students);
    }
  };

  const methods = useForm<StudentFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(studentFormValidationSchema(requireStudentAge)),
    defaultValues: studentState.student,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUseNativeValidation: false
  });

  const handleSubmit = async (formValues: StudentFormValues) => {
    if (mode === FormMode.Edit) {
      await updateStudent(studentId, formValues, methods.setError);
    } else {
      await addStudent(formValues, methods.setError);
    }
    getStudentAndGotoStudentsPageIfAny();
  };

  const handleCancel = () => {
    if (getNeedReturnToRegistration()) {
      return navigate(PATHS.selectStudentsForSessions);
    }
    navigate(PATHS.students);
  };

  return (
    <CenteredFormContainer>
      <Box marginBottom={4}>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          {mode === FormMode.Edit ? 'Edit Student' : 'Add Student'}
          <hr style={{ height: '2', width: 'auto', margin: 0 }} />
        </Typography>
      </Box>
      <WithLoading isLoading={studentState.isLoading} message={'Loading student info...'} style={{ width: '50%' }}>
        <CustomerStudentForm
          mode={mode}
          initialFormValues={cloneDeep(studentState.student)}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          isCancelSubmitDisabled={studentState.isLoading}
          requireStudentAge={requireStudentAge}
        />
      </WithLoading>
    </CenteredFormContainer>
  );
};

export default CustomerStudentContainer;
