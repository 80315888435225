import React, { useMemo } from 'react';
import { EnhancedColumn } from '../../components/Table/Table';
import { NavLink } from 'react-router-dom';
import { Link } from '@mui/material';
import {
  AdditionalSessionOption,
  moneyWithCurrencyCodeFormatter,
  formatStringToMonthDayYearDate
} from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import MoneyRangeColumnFilter from '../../components/Table/filters/MoneyRangeColumnFilter';

export const useSessionOptionsTableColumns = (
  accessId: string,
  additionalColumns?: EnhancedColumn<AdditionalSessionOption>[]
) => {
  return useMemo(() => {
    const columns: EnhancedColumn<AdditionalSessionOption>[] = [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row, value: name }) => {
          return (
            <Link
              component={NavLink}
              to={toPath(PATHS.editAdditionalOption, {
                orgId: accessId,
                additionalOptionId: row.original._id
              })}
              underline={'none'}
            >
              {name}
            </Link>
          );
        }
      },
      {
        Header: 'Description',
        accessor: 'description'
      },
      {
        Header: 'Available until',
        accessor: 'expiresOn',
        disableFilters: true,
        Cell: ({ value: expiresOn }) => <>{formatStringToMonthDayYearDate(new Date(expiresOn).toISOString())}</>
      },
      {
        Header: 'Price',
        accessor: 'price',
        Filter: MoneyRangeColumnFilter,
        filter: 'between',
        headerStyles: {
          textAlign: 'right'
        },
        columnStyles: {
          textAlign: 'right'
        },
        Cell: ({ row, value: price }) => <>{moneyWithCurrencyCodeFormatter(price, row.original.currencyCode)}</>
      }
    ];

    if (additionalColumns) {
      return [...columns, ...additionalColumns];
    }
    return columns;
  }, [accessId, additionalColumns]);
};
