import React from 'react';
import { FormHelperText, Grid } from '@mui/material';
import { FormTextInput, CountrySelector, StateSelector } from '@efacity/react-hook-form-mui';
import { useFormContext } from 'react-hook-form';
import { CountryCode, isCountryHasStates } from '@efacity/common';

export const AddressForm = () => {
  const { watch, trigger } = useFormContext();
  const addressValues = watch('address');

  const triggerAddressValidation = async () => {
    await trigger('address');
  };

  const shouldRequireAddressFields = !!Object.values<string>(addressValues).some((value: string) => value?.length > 0);

  return (
    <Grid container spacing={1} data-testid="addressForm">
      <Grid item xs={12}>
        <h4 style={{ marginTop: 10, marginBottom: 0 }}>Address</h4>
        <FormHelperText component="span"> (Either fill in all fields or omit them)</FormHelperText>
      </Grid>

      <Grid item xs={12}>
        <CountrySelector
          name="address.country"
          label="Country"
          required={shouldRequireAddressFields}
          onValueChange={triggerAddressValidation}
        />
      </Grid>

      <Grid item xs={12}>
        <FormTextInput
          name="address.address"
          label="Address"
          placeholder="Street, PO Box, or company name"
          required={shouldRequireAddressFields}
          onValueChange={triggerAddressValidation}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormTextInput
          name="address.city"
          label="City"
          required={shouldRequireAddressFields}
          onValueChange={triggerAddressValidation}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        {isCountryHasStates(addressValues?.country as CountryCode) && (
          <StateSelector
            name="address.state"
            required={shouldRequireAddressFields}
            countryCode={addressValues.country as CountryCode}
            onValueChange={triggerAddressValidation}
          />
        )}
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormTextInput
          name="address.zip"
          label="Zip"
          required={shouldRequireAddressFields}
          onValueChange={triggerAddressValidation}
        />
      </Grid>
    </Grid>
  );
};
