import React, { useMemo } from 'react';
import { EnhancedColumn } from '../../components/Table/Table';
import { Typography } from '@mui/material';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import EmailAddress from '../../components/EmailAddress/EmailAddress';
import { NameLinkWithNoteIcon } from '../../components/NameLinkWithNoteIcon/NameLinkWithNoteIcon';

const useStudentsTableColumns = (onEditStudentInfo, onOpenNotesModal) => {
  return useMemo(() => {
    const columns: EnhancedColumn<any>[] = [
      {
        Header: 'Full Name',
        accessor: 'fullName',
        Cell: ({ value, row, state }) => {
          return (
            <NameLinkWithNoteIcon
              name={row.original.fullName}
              onNameClick={() =>
                onEditStudentInfo({
                  studentId: row.original._id,
                  studentName: value,
                  fetchDataOptions: {
                    filters: state.filters,
                    pageIndex: state.pageIndex,
                    pageSize: state.pageSize,
                    sortBy: state.sortBy
                  }
                })
              }
              onNoteClick={() => onOpenNotesModal(row.original._id, row.original.fullName)}
            />
          );
        }
      },
      {
        Header: 'Student Email',
        accessor: 'email',
        Cell: ({ value }) => {
          return <EmailAddress email={value} />;
        }
      },
      {
        Header: 'Customer',
        accessor: 'customers.fullName',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          return row.original?.customers?.length > 0 ? (
            row.original.customers.map((customer, index) => {
              return (
                <div key={`customerForStudent${row.original._id}Customer${customer._id}Index${index}Info`}>
                  <Typography style={{ ...adminSectionTableFontSize }}>{customer.fullName}</Typography>

                  {customer.email && (
                    <Typography style={{ ...adminSectionTableFontSize }}>
                      (<EmailAddress email={customer.email} />)
                    </Typography>
                  )}
                </div>
              );
            })
          ) : (
            <Typography>-</Typography>
          );
        }
      }
    ];

    return columns;
  }, [onEditStudentInfo, onOpenNotesModal]);
};

export default useStudentsTableColumns;
