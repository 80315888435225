import { SessionRegistrationWithSessionAndEducator, useFetch } from '@efacity/frontend-shared';
import { PageTitle, useAuth } from '@efacity/react-next-sc';
import { PATHS } from '@efacity/routing';
import { CircularProgress, Typography } from '@mui/material';
import React from 'react';
import StudentRegistrationsTable from './StudentRegistrationsTable';

const studentContainerSx = {
  noSessions: {
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    fontSize: '20px',
    lineHeight: '23px',
    textTransform: 'uppercase',
    margin: '20 0',
    textAlign: 'center'
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30
  }
};

const StudentRegistrationsContainer: React.FC = () => {
  const {
    authState: {
      user: { _id }
    }
  } = useAuth();

  const [registrationsState] = useFetch<SessionRegistrationWithSessionAndEducator[]>(`student/${_id}/sessions`, {
    initialDataState: []
  });

  return (
    <div>
      <PageTitle title="Sessions" addButtonLink={PATHS.landing} />
      {registrationsState.isLoading ? (
        <div style={studentContainerSx.spinner}>
          <CircularProgress color="primary" size={20} data-testid="loading-sessions" />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Typography color="primary">Loading sessions list...</Typography>
        </div>
      ) : registrationsState?.data && registrationsState?.data?.length > 0 ? (
        <div>
          <StudentRegistrationsTable registrations={registrationsState.data} />
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Typography sx={studentContainerSx.noSessions}>No subscribed sessions</Typography>
        </div>
      )}
    </div>
  );
};

export default StudentRegistrationsContainer;
